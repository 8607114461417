/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useConfigCatClient } from 'configcat-react'
import { useIsMounted } from 'usehooks-ts'

import { isMobile } from '@/utils/common.js'
import { useCurrentUser } from '../../Context/CurrentUser'
import lattusApiClient from '../../../lattusApi'
import { useApiFetch } from '@/api'
import { useAssessmentsApi } from '@/api/assessmentsApi.js'
import { FeatureFlags, FeatureFlagDefaults } from '@/config/ConfigEnums'

//Theme
import { Row, Col } from 'react-bootstrap'
import CardContainer from '../../../elemets/CardContainer'
import CardTitle from '../../../elemets/CardTitle'
import { createUseStyles } from 'react-jss'
import colors, { headings } from '../../../theme/theme2'
import { CircleCheck, CircleXmark, CircleInfo } from '../../Icons'
import Tooltip2 from './Tooltip'
import Styled from 'styled-components'
import {
  ASSESSMENT_NAMES,
  ASSESSMENT_PATHS,
  getAssessmentIdByName,
  matchingAssessmentEnabled,
  onboardingAssessmentEnabled,
  relaceAssessmentEnabled,
} from '../../Assessments/assessment-utils'
import { useGroups } from '@/groups/GroupsContext'

const TooltipTarget = Styled.div``

const useStyles = createUseStyles({
  title: {
    padding: 0,
  },
  container: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
  },
  mobile: {
    paddingTop: 0,
    minHeight: 'unset',
  },
  listSpacing: {
    paddingBottom: 6,
    paddingRight: 6,
    color: colors.grey8,
    fontWeight: 500,
    textDecorationLine: 'underline',
  },
  listLastItem: {
    paddingBottom: 0,
    paddingRight: 6,
    color: colors.grey8,
    fontWeight: 500,
    textDecorationLine: 'underline',
  },
  // listItem: {

  // },
  icon: {
    '& i': {
      fontSize: 15,
      color: colors.grey6,
      width: 15,
      height: 15,
      backgroundColor: colors.white,
      borderRadius: 50,
      marginRight: 8,
      marginTop: 4,
    },
  },
  circleCheck: {
    '& i': {
      fontSize: 15,
      color: colors.primary,
      cursor: 'auto',
      marginTop: 0,
    },
  },
  circleXmark: {
    '& i': {
      fontSize: 15,
      color: colors.grey2,
      cursor: 'auto',
      marginTop: 0,
    },
  },
  circleInfo: {
    '& i': {
      marginTop: 0,
      fontSize: 15,
      color: colors.grey2,
    },
  },
  // actionButton: {
  //   width: '100%!important',
  // },
  textMain: {
    ...headings,
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.dark,
  },
  textSubtitle: {
    ...headings,
    fontSize: 14,
    fontWeight: 'normal',
    color: colors.grey2,
  },
  // tooltipPosition: {
  //   right: -450,
  //   top: -80,
  // },
  // tooltipPositionMobile: {
  //   top: -240,
  // },
})

const FirstStepsCard = ({ conversations, conversations2 }) => {
  const classes = useStyles()
  let arrow = 'right'
  if (isMobile()) {
    arrow = 'top'
  }
  const isMounted = useIsMounted()
  const { currentUser } = useCurrentUser()
  const { listAvailability } = lattusApiClient
  const [times, setTimes] = useState(false)
  const configCatClient = useConfigCatClient()
  const [assessmentsFlags, setAssessmentsFlags] = useState(null)
  const [onboardingSurveyCompleted, setOnboardingSurveyCompleted] =
    useState(false)
  const [relaceAssessmentCompleted, setRelaceAssessmentCompleted] =
    useState(false)
  const [{ data: onboardingSurveyData }, doFetch] = useAssessmentsApi()
  const [matchingSurveyCompleted, setMatchingSurveyCompleted] = useState(false)
  const [{ data: matchingSurveyData }, doFetch2] = useAssessmentsApi()
  const [{ data: relaceSurveyData }, doFetch3] = useAssessmentsApi()
  const { active } = useGroups()
  const { id: groupId } = active || {}

  // Set ConfigCat flags
  useEffect(() => {
    if (!groupId || !isMounted()) {
      return
    }

    if (!assessmentsFlags && configCatClient && isMounted()) {
      configCatClient
        .getValueDetailsAsync(
          FeatureFlags.assessmentsAll,
          FeatureFlagDefaults.assessmentsAll,
        )
        .then((v) => {
          if (!isMounted()) {
            return
          }

          try {
            const enabledFor =
              v && v.value.length > 2 ? JSON.parse(v.value.trim()) : []
            setAssessmentsFlags(enabledFor)
          } catch (e) {
            if (isMounted()) {
              setAssessmentsFlags(
                JSON.parse(FeatureFlagDefaults.assessmentsAll),
              )
            }
          }
        })
    }
  }, [configCatClient, groupId])

  // Check for completed Assessments
  useEffect(() => {
    if (!isMounted()) return
    if (currentUser?.id && groupId) {
      const assessmentId = getAssessmentIdByName(ASSESSMENT_NAMES.onboarding)
      const fakeResponseId = 'response1'
      const path = `/v1/assessments/${assessmentId}/responses/${fakeResponseId}`
      const queryParams = `?userId=${currentUser.id}&groupId=${groupId}`
      const endpoint = `${path}${queryParams}`
      doFetch({
        endpoint,
        method: 'GET',
      })
    }
  }, [currentUser, groupId])

  useEffect(() => {
    if (isMounted()) {
      setOnboardingSurveyCompleted(
        !!onboardingSurveyData && !!onboardingSurveyData.data,
      )
    }
  }, [onboardingSurveyData])

  function matchingAssessmentPath(currentUser) {
    if (!currentUser?.groups || !groupId) return ''
    const flags = currentUser.groups.find((g) => g.id === groupId).flags
    return flags.matching ? ASSESSMENT_PATHS[flags.matching] : ''
  }

  function matchingAssessmentName(currentUser) {
    if (!currentUser?.groups || !groupId) return ''
    const flags = currentUser.groups.find((g) => g.id === groupId).flags
    return flags.matching ? ASSESSMENT_NAMES[flags.matching] : ''
  }

  useEffect(() => {
    if (currentUser?.id && !matchingSurveyData && groupId && isMounted()) {
      // Matching Assessment
      const assessmentId = getAssessmentIdByName(
        matchingAssessmentName(currentUser),
      )
      const fakeResponseId = 'response1'
      const path = `/v1/assessments/${assessmentId}/responses/${fakeResponseId}`
      const queryParams = `?userId=${currentUser.id}&groupId=${groupId}`
      const endpoint = `${path}${queryParams}`
      doFetch2({
        endpoint,
        method: 'GET',
      })
    }
  }, [currentUser, groupId])

  useEffect(() => {
    if (isMounted()) {
      setMatchingSurveyCompleted(
        !!matchingSurveyData && !!matchingSurveyData.data,
      )
    }
  }, [matchingSurveyData])

  useEffect(() => {
    if (
      currentUser?.id &&
      currentUser.groups &&
      groupId &&
      !relaceSurveyData &&
      isMounted()
    ) {
      const assessmentId = getAssessmentIdByName(ASSESSMENT_NAMES.relace)
      const fakeResponseId = 'response1'
      const path = `/v1/assessments/${assessmentId}/responses/${fakeResponseId}`
      const queryParams = `?userId=${currentUser.id}&groupId=${groupId}`
      const endpoint = `${path}${queryParams}`
      doFetch3({
        endpoint,
        method: 'GET',
      })
    }
  }, [currentUser, groupId])

  useEffect(() => {
    if (!!relaceSurveyData && !!relaceSurveyData.data && isMounted()) {
      setRelaceAssessmentCompleted(true)
    }
  }, [relaceSurveyData])

  useEffect(() => {
    if (currentUser?.id && currentUser.groups && !times) {
      async function fetchMyAPI() {
        await listAvailability({
          query: {
            count: 1000,
          },
        }).then((response) => {
          if (isMounted()) {
            setTimes(response.length > 0)
          }
        })
      }
      fetchMyAPI()
    }
  }, [listAvailability])
  let topicsLength = ''
  let bio = ''
  let education = ''
  let experience = ''
  let profilePic = ''
  let isTopics = false
  let completedConversation = false
  let completeProfile = false
  let availability = false
  const integrations = JSON.parse(
    JSON.stringify(useApiFetch('getIntegrations')),
  ).data
  let integrated = false
  profilePic = useApiFetch('getCurrentProfile').data

  let fiveConvos = false
  let fiveTopics = false
  let nextScheduled = false

  if (currentUser?.id && profilePic) {
    topicsLength = JSON.parse(JSON.stringify(currentUser)).topics.length
    bio = JSON.parse(JSON.stringify(currentUser)).bio
    education = JSON.parse(JSON.stringify(currentUser)).education
    experience = JSON.parse(JSON.stringify(currentUser)).experience
    if (topicsLength > 1) {
      isTopics = true
    }
    if (
      bio &&
      bio.length > 0 &&
      education &&
      education.length > 0 &&
      experience &&
      experience.length > 0
    ) {
      completeProfile = true
    }
    if (conversations) {
      if (conversations.length > 0) {
        completedConversation = true
      }
    }
    if (conversations2) {
      if (conversations2.length > 0) {
        completedConversation = true
      }
    }

    if (times) {
      availability = true
    }

    integrated = false
    if (integrations && integrations.length > 0) {
      integrations.forEach(function (i) {
        if (i.type === 'calendar') {
          integrated = true
        }
      })
    }
  }

  if (conversations && conversations2) {
    if (conversations.length + conversations2.length >= 2) {
      nextScheduled = true
    }
  } else if (conversations) {
    if (conversations.length >= 2) {
      nextScheduled = true
    }
  } else if (conversations2) {
    if (conversations2.length >= 2) {
      nextScheduled = true
    }
  }

  if (conversations2 && conversations2.length >= 5) {
    fiveConvos = true
  }
  if (topicsLength >= 5) {
    fiveTopics = true
  }

  const isOnboardingAssessmentEnabled = onboardingAssessmentEnabled(
    groupId,
    assessmentsFlags,
  )
  const isMatchingAssessmentEnabled = matchingAssessmentEnabled(
    groupId,
    assessmentsFlags,
  )
  const isRelaceEnabled = relaceAssessmentEnabled(groupId, assessmentsFlags)

  const firstStepsDone = isFirstStepsDone(
    isOnboardingAssessmentEnabled,
    isMatchingAssessmentEnabled,
    isRelaceEnabled,
  )

  function isFirstStepsDone(
    onboardingAssessmentEnabled,
    matchingAssessmentEnabled,
    relaceAssessmentEnabled,
  ) {
    const reqs = [
      isTopics,
      completeProfile,
      completedConversation,
      availability,
      integrated,
    ]

    if (onboardingAssessmentEnabled) {
      reqs.push(onboardingSurveyCompleted)
    }

    if (matchingAssessmentEnabled) {
      reqs.push(matchingSurveyCompleted)
    }

    if (relaceAssessmentEnabled) {
      reqs.push(relaceAssessmentCompleted)
    }

    return reqs.every((r) => r === true)
  }

  const cardTitle = !firstStepsDone ? 'First Steps' : 'Next Steps'

  const header = (
    <>
      {
        <CardTitle
          className={`${classes.title} ${isMobile() ? 'pt-3' : ''}`}
          title={`${cardTitle}`}
        />
      }
    </>
  )

  return (
    <CardContainer
      data-testid="firststeps-card-container"
      className={`${classes.container} ${isMobile() ? classes.mobile : ''}`}
      header={header}
      headerDivider={true}
    >
      {!firstStepsDone ? (
        <div className={`${classes.icon} d-flex justify-content-left`}>
          <Col>
            <Row
              className={`${classes.listItem}`}
              data-testid="firststeps-selecttopics"
            >
              {isTopics ? (
                <div className={`${classes.circleCheck}`}>
                  <CircleCheck></CircleCheck>
                </div>
              ) : (
                <div className={`${classes.circleXmark}`}>
                  <CircleXmark></CircleXmark>
                </div>
              )}
              <Link to="/topics">
                <p className={`${classes.listSpacing} mb-0`}>Select Topics</p>
              </Link>
              <div>
                <Tooltip2
                  text="Topics help structure and focus your Lattitude conversations."
                  position={`${arrow}`}
                >
                  <TooltipTarget>
                    <div className={`${classes.circleInfo}`}>
                      <CircleInfo></CircleInfo>
                    </div>
                  </TooltipTarget>
                </Tooltip2>
              </div>
            </Row>
            <Row data-testid="firststeps-selectavailabilities">
              {availability ? (
                <div className={`${classes.circleCheck}`}>
                  <CircleCheck></CircleCheck>
                </div>
              ) : (
                <div className={`${classes.circleXmark}`}>
                  <CircleXmark></CircleXmark>
                </div>
              )}
              <Link to="/availability">
                <p className={`${classes.listSpacing} mb-0`}>
                  Add Availability
                </p>
              </Link>
              <div>
                <Tooltip2
                  text="To schedule a conversation on Lattitude, you first have to be available."
                  position={`${arrow}`}
                >
                  <TooltipTarget>
                    <div className={`${classes.circleInfo}`}>
                      <CircleInfo></CircleInfo>
                    </div>
                  </TooltipTarget>
                </Tooltip2>
              </div>{' '}
            </Row>

            <Row data-testid="firststeps-completeprofile">
              {completeProfile ? (
                <div className={`${classes.circleCheck}`}>
                  <CircleCheck></CircleCheck>
                </div>
              ) : (
                <div className={`${classes.circleXmark}`}>
                  <CircleXmark></CircleXmark>
                </div>
              )}
              <Link to="/profile">
                <p className={`${classes.listSpacing} mb-0`}>
                  Complete Profile
                </p>
              </Link>
              <div>
                <Tooltip2
                  text="Add your bio, education, experience, and (optionally) a profile picture."
                  position={`${arrow}`}
                >
                  <TooltipTarget>
                    <div className={`${classes.circleInfo}`}>
                      <CircleInfo></CircleInfo>
                    </div>
                  </TooltipTarget>
                </Tooltip2>
              </div>{' '}
            </Row>

            {isOnboardingAssessmentEnabled ? (
              <Row data-testid="firststeps-completeonboardingassessment">
                {onboardingSurveyCompleted ? (
                  <div className={`${classes.circleCheck}`}>
                    <CircleCheck></CircleCheck>
                  </div>
                ) : (
                  <div className={`${classes.circleXmark}`}>
                    <CircleXmark></CircleXmark>
                  </div>
                )}
                <Link
                  to={
                    onboardingSurveyCompleted ? '' : ASSESSMENT_PATHS.onboarding
                  }
                >
                  <p className={`${classes.listSpacing} mb-0`}>
                    Complete Onboarding Assessment
                  </p>
                </Link>
                <div>
                  <Tooltip2
                    text="Complete the onboarding assessment. It should only take about 8-12 minutes."
                    position={`${arrow}`}
                  >
                    <TooltipTarget>
                      <div className={`${classes.circleInfo}`}>
                        <CircleInfo></CircleInfo>
                      </div>
                    </TooltipTarget>
                  </Tooltip2>
                </div>{' '}
              </Row>
            ) : null}

            {isRelaceEnabled ? (
              <Row data-testid="firststeps-completerelaceassessment">
                {relaceAssessmentCompleted ? (
                  <div className={`${classes.circleCheck}`}>
                    <CircleCheck></CircleCheck>
                  </div>
                ) : (
                  <div className={`${classes.circleXmark}`}>
                    <CircleXmark></CircleXmark>
                  </div>
                )}
                <Link
                  to={relaceAssessmentCompleted ? '' : ASSESSMENT_PATHS.relace}
                >
                  <p className={`${classes.listSpacing} mb-0`}>
                    Complete ReLACE Assessment
                  </p>
                </Link>
                <div>
                  <Tooltip2
                    text="Complete the ReLACE assessment. It should only take about 8-10 minutes."
                    position={`${arrow}`}
                  >
                    <TooltipTarget>
                      <div className={`${classes.circleInfo}`}>
                        <CircleInfo></CircleInfo>
                      </div>
                    </TooltipTarget>
                  </Tooltip2>
                </div>{' '}
              </Row>
            ) : null}

            {isMatchingAssessmentEnabled ? (
              <Row data-testid="firststeps-completematchingassessment">
                {matchingSurveyCompleted ? (
                  <div className={`${classes.circleCheck}`}>
                    <CircleCheck></CircleCheck>
                  </div>
                ) : (
                  <div className={`${classes.circleXmark}`}>
                    <CircleXmark></CircleXmark>
                  </div>
                )}
                <Link
                  to={
                    matchingSurveyCompleted
                      ? ''
                      : matchingAssessmentPath(currentUser)
                  }
                >
                  <p className={`${classes.listSpacing} mb-0`}>
                    Complete Matching Assessment
                  </p>
                </Link>
                <div>
                  <Tooltip2
                    text="Complete the matching assessment. It should only take about 14 minutes."
                    position={`${arrow}`}
                  >
                    <TooltipTarget>
                      <div className={`${classes.circleInfo}`}>
                        <CircleInfo></CircleInfo>
                      </div>
                    </TooltipTarget>
                  </Tooltip2>
                </div>{' '}
              </Row>
            ) : null}

            <Row data-testid="firststeps-integratecalendar">
              {integrated ? (
                <div className={`${classes.circleCheck}`}>
                  <CircleCheck></CircleCheck>
                </div>
              ) : (
                <div className={`${classes.circleXmark}`}>
                  <CircleXmark></CircleXmark>
                </div>
              )}
              <Link to="availability">
                <p className={`${classes.listSpacing} mb-0`}>
                  Integrate Calendar
                </p>
              </Link>
              <div>
                <Tooltip2
                  text="Integrating your Google Calendar helps keep everything in one place."
                  position={`${arrow}`}
                >
                  <TooltipTarget>
                    <div className={`${classes.circleInfo}`}>
                      <CircleInfo></CircleInfo>
                    </div>
                  </TooltipTarget>
                </Tooltip2>
              </div>{' '}
            </Row>

            <Row data-testid="firststeps-schedulefirstcall">
              {completedConversation ? (
                <div className={`${classes.circleCheck}`}>
                  <CircleCheck></CircleCheck>
                </div>
              ) : (
                <div className={`${classes.circleXmark}`}>
                  <CircleXmark></CircleXmark>
                </div>
              )}
              <Link to="network">
                <p className={`${classes.listLastItem} mb-0`}>
                  Schedule Your First Conversation
                </p>
              </Link>
              <div>
                <Tooltip2
                  text="Start today by scheduling your first conversation."
                  position={`${arrow}`}
                >
                  <TooltipTarget>
                    <div className={`${classes.circleInfo}`}>
                      <CircleInfo></CircleInfo>
                    </div>
                  </TooltipTarget>
                </Tooltip2>
              </div>
            </Row>
          </Col>
        </div>
      ) : (
        <div className={`${classes.icon} d-flex justify-content-left`}>
          <Col>
            <Row data-testid="nextsteps-integratecalendar">
              {nextScheduled ? (
                <div className={`${classes.circleCheck}`}>
                  <CircleCheck></CircleCheck>
                </div>
              ) : (
                <div className={`${classes.circleXmark}`}>
                  <CircleXmark></CircleXmark>
                </div>
              )}
              <Link to="/network">
                <p className={`${classes.listSpacing} mb-0`}>
                  Integrate Calendar
                </p>
              </Link>
              <div>
                <Tooltip2
                  text="Schedule your next conversation on Lattitude!"
                  position={`${arrow}`}
                >
                  <TooltipTarget>
                    <div className={`${classes.circleInfo}`}>
                      <CircleInfo></CircleInfo>
                    </div>
                  </TooltipTarget>
                </Tooltip2>
              </div>{' '}
            </Row>

            <Row data-testid="nextsteps-addtopics">
              {fiveTopics ? (
                <div className={`${classes.circleCheck}`}>
                  <CircleCheck></CircleCheck>
                </div>
              ) : (
                <div className={`${classes.circleXmark}`}>
                  <CircleXmark></CircleXmark>
                </div>
              )}
              <Link to="topics">
                <p className={`${classes.listSpacing} mb-0`}>Add 5 Topics</p>
              </Link>
              <div>
                <Tooltip2
                  text="Add some more topics of interest."
                  position={`${arrow}`}
                >
                  <TooltipTarget>
                    <div className={`${classes.circleInfo}`}>
                      <CircleInfo></CircleInfo>
                    </div>
                  </TooltipTarget>
                </Tooltip2>
              </div>
            </Row>
            <Row data-testid="nextsteps-completeconversations">
              {fiveConvos ? (
                <div className={`${classes.circleCheck}`}>
                  <CircleCheck></CircleCheck>
                </div>
              ) : (
                <div className={`${classes.circleXmark}`}>
                  <CircleXmark></CircleXmark>
                </div>
              )}
              <Link to="/network">
                <p className={`${classes.listLastItem} mb-0`}>
                  Complete 5 Conversations
                </p>
              </Link>
              <div>
                <Tooltip2
                  text="Complete more conversations to learn more about your topics of interest"
                  position={`${arrow}`}
                >
                  <TooltipTarget>
                    <div className={`${classes.circleInfo}`}>
                      <CircleInfo></CircleInfo>
                    </div>
                  </TooltipTarget>
                </Tooltip2>
              </div>{' '}
            </Row>
          </Col>
        </div>
      )}
    </CardContainer>
  )
}
export default FirstStepsCard
