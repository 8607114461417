import { createUseStyles } from 'react-jss'

const useActionsStyles = createUseStyles({
  button: {
    color: '#0d7b48',
    border: '1px solid #4eba7e',
    backgroundColor: '#f3f3f3',
    fontFamily: 'Lato',
    fontSize: '13px',
    fontWeight: 'bold',
    letterSpacing: '0.33px',
    textTransform: 'uppercase',
    paddingTop: '9px',
    verticalAlign: 'middle',
    '&:hover,&:focus': {
      color: '#0d7b48',
      border: '1px solid #4eba7e',
      backgroundColor: '#edf8f2',
      boxShadow: '0 3px 6px 0 rgba(153, 155, 168, 0.61)',
    },
  },
  toggle: {
    color: '#f3f3f3 !important',
    border: '1px solid #4eba7e !important',
    backgroundColor: '#0d7b48 !important',
    '&:hover,&:focus': {
      color: '#edf8f2',
      border: '1px solid #4eba7e',
      backgroundColor: '#0d7b48',
      boxShadow: '0 3px 6px 0 rgba(153, 155, 168, 0.61)',
    },
  },
  menuItem: {
    fontFamily: 'Lato',
  },
})

export default useActionsStyles
