import React from 'react'

import { createUseStyles } from 'react-jss'
import CustomTextArea from '../../../elemets/CustomTextArea'
import CustomCheckbox from '../../../elemets/CustomCheckbox'
import colors from '../../../theme/theme2'

const useStyles = createUseStyles({
  comment: {
    fontFamily: 'Lato',
    fontSize: 14,
  },
  text: {
    height: 90,
  },
  checkboxCheck: {
    backgroundColor: [colors.primary, '!important'],
    '& i': {
      color: [colors.whiteBackground, '!important'],
    },
  },
})

const KudosModal = ({ kudos, setKudos }) => {
  const classes = useStyles()

  return (
    <div>
      <span className={`${classes.comment} px-3 pb-2`}>Thank You Message</span>
      <CustomTextArea
        value={kudos.comment}
        onChange={e => setKudos({ ...kudos, comment: e.target.value })}
        className={classes.text}
      />
      <div className="d-flex align-items-start pt-2">
        <CustomCheckbox
          checked={kudos.publishOnGroups}
          backgroundColor={kudos.publishOnGroups ? classes.checkboxCheck : ''}
          iconColor={classes.icon}
          onClick={() =>
            setKudos({ ...kudos, publishOnGroups: !kudos.publishOnGroups })
          }
        />
        <label className={`${classes.comment} px-2`}>
          Publish your kudos to the group
        </label>
      </div>
    </div>
  )
}

export default KudosModal
