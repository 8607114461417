import React, { useCallback, useMemo } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Topic, TopicId } from './data/Topic'
import { useTopics } from './data/TopicsProvider'

type TopicsSelectorProps = {
  value?: TopicId[]
  onChange: (newValue: TopicId[]) => void
  onClear: () => void
}

const TopicsSelector = ({
  value,
  onChange,
  onClear,
}: TopicsSelectorProps): JSX.Element => {
  const { topics, ready } = useTopics()

  const selected = useMemo(() => {
    if (!value || value.length < 1) {
      return []
    }
    return topics.filter(({ id }) => value.includes(id))
  }, [value, topics])

  const handleChange = useCallback(
    (selected: Topic[]) => {
      if (selected && selected.length > 0) {
        onChange(selected.map(({ id }) => id))
        return
      }

      onClear()
    },
    [onChange, onClear],
  )

  return (
    <Typeahead
      id="topicSelect"
      options={topics}
      selected={selected}
      disabled={!ready}
      labelKey="name"
      onChange={handleChange}
      multiple
    />
  )
}

export default TopicsSelector
