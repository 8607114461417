import React, { FC } from 'react'

import { createUseStyles } from 'react-jss'
import colors from '../theme/theme2'

const useStyles = createUseStyles({
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: colors.grey2,
    margin: '15px 0',
  },
})

type DividerProps = {
  children?: React.ReactNode
  className?: string
}
const Divider: FC<DividerProps> = ({
  children,
  className = '',
}: DividerProps) => {
  const classes = useStyles()

  return (
    <div className={`${classes.divider} ${className}`}>
      {children ? children : null}
    </div>
  )
}

export default Divider
