let iota = 0

// TODO convert to enum
const DeviceStatus = {
  Unset: iota++,
  Waiting: iota++,
  Ready: iota++,
  IncomingCall: iota++,
  Rejected: iota++,
  Cancelled: iota++,
  Connected: iota++,
  Disconnected: iota++,
  Reconnecting: iota++,
  Warning: iota++,
  Offline: iota++,
  Error: iota++,
}

export default DeviceStatus
