import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { useApi } from '@/api'
import { type IGroup as Group } from '../types/Groups.typings'
import useGroupState, {
  loading,
  clear,
  loaded,
  errored,
  Status,
  selectActive,
} from './reducer'

type GroupsContext = {
  active: Group | undefined
  groups: Group[]
  setActiveGroup: (groupId: number) => void
  defaultSkillSettings: Group['defaultSkillSettings'][]
}

export const Context = createContext<GroupsContext>({
  active: undefined,
  defaultSkillSettings: [],
  groups: [],
  setActiveGroup: () => {
    // TODO: implement the change of group prefix
    throw new Error('not implemented')
  },
})

interface GroupsProviderProps {
  profileId: number | undefined
  activeGroupId?: number
  children: React.ReactNode
}

export const GroupsProvider = ({
  profileId,
  activeGroupId,
  children,
}: GroupsProviderProps): JSX.Element | null => {
  const { invoke } = useApi()
  const [state, dispatch] = useGroupState()
  const setActiveGroup = useMemo(
    () => (groupId: number) => {
      if (
        state.status !== Status.Loaded ||
        !state.groups.some(({ id }: Group) => id === groupId)
      ) {
        throw new Error(`invalid group ID selected: ${groupId}`)
      }

      dispatch(selectActive(groupId))
    },
    [dispatch, state],
  )

  useEffect(() => {
    dispatch(loading(activeGroupId))

    if (!profileId) {
      dispatch(clear())
      return
    }

    const controller = new AbortController()

    invoke('getGroupsV1', {
      params: { profileId: String(profileId) },
      signal: controller.signal,
    })
      .then((groups: Group[]) => {
        if (controller.signal.aborted) {
          return
        }

        dispatch(loaded(groups))
      })
      .catch((err: Error) => {
        if (controller.signal.aborted || err.name === 'AbortError') {
          return
        }

        dispatch(errored(err))
      })

    return () => {
      controller.abort()
    }
  }, [profileId, activeGroupId, invoke, dispatch])

  const active = state.status === Status.Loaded ? state.active : undefined
  const defaultSkillSettings =
    state.status === Status.Loaded ? state.defaultSkillSettings : []
  const groups = state.status === Status.Loaded ? state.groups : []
  return (
    <Context.Provider
      value={{ active, groups, setActiveGroup, defaultSkillSettings }}
    >
      {children}
    </Context.Provider>
  )
}

export const useGroups = (): GroupsContext => useContext(Context)
