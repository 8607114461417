import React, { useCallback, useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import compose from 'just-compose'

import SkillLevelIcon from '../Icons/SkillLevel'
import { withErrorHandler } from '../../errors'
import { ratingValues } from '../Forms/Ratings'

// Elements
import CardContainer from '../../elemets/CardContainer'
import SquareButton from '../../elemets/SquareButton'
import CardTitle from '../../elemets/CardTitle'
import CircleName from '../../elemets/CircleName'
import Divider from '../../elemets/Divider'
import CustomSelect from '../../elemets/CustomSelect'

// ApiError
import { useApiSubmit } from '../../api/hooks'

// Theme
import colors, { headings, responsive } from '../../theme/theme2'

const useStyles = createUseStyles({
  row: {
    marginBottom: 6,
    flexDirection: 'row',
  },
  headerButton: {
    marginRight: 15,
  },
  title: {
    ...headings.title,
    marginBottom: 0,
  },
  subtitle: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: colors.greyParagraph,
    marginBottom: 0,
    wordBreak: 'break-word!important',
  },
  paragraph: {
    ...headings.paragraph,
  },
  index: {
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.primary,
  },
  arrow: {
    color: colors.primary,
    cursor: 'pointer',
  },
  arrowContainer: {
    width: 15,
  },
  selector: {
    minWidth: 65,
  },
  ...responsive,
})
export const conversationOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
]

const TopicCard = ({ topics }) => {
  const classes = useStyles()
  const [index, setIndex] = useState(0)
  const [selectedTopic, setSelectedTopic] = useState()
  useEffect(() => {
    setSelectedTopic(topics[0])
  }, [topics])

  const handleNext = useCallback(() => {
    if (index + 1 < topics.length) {
      setSelectedTopic(topics[index + 1])
      setIndex((i) => i + 1)
    }
  }, [topics, index])

  const handlePrev = useCallback(() => {
    if (index - 1 >= 0) {
      setSelectedTopic(topics[index - 1])
      setIndex((i) => i - 1)
    }
  }, [topics, index])

  if (selectedTopic) {
    return (
      <CardContainer>
        <Row noGutters className="align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <CircleName text={selectedTopic.groupName[0]} />
            <label className={`${classes.title} pl-3`}>
              {selectedTopic.groupName}
            </label>
          </div>
          {topics.length > 1 && (
            <div className="d-flex align-items-center">
              <div className={classes.arrowContainer}>
                <i
                  className={`fas fa-chevron-left p-1 ${classes.arrow} ${
                    index === 0 ? 'd-none' : ''
                  }`}
                  onClick={handlePrev}
                />
              </div>
              <label className={`mx-3 mb-0 ${classes.index}`}>
                {index + 1}
              </label>
              <div className={classes.arrowContainer}>
                <i
                  className={`fas fa-chevron-right p-1 ${classes.arrow} ${
                    index >= topics.length - 1 ? 'd-none' : ''
                  }`}
                  onClick={handleNext}
                />
              </div>
            </div>
          )}
        </Row>
        <Divider />
        <Col>
          <label className={classes.title}>
            <SkillLevelIcon rating={selectedTopic.rating} />
            {selectedTopic.rating} - {selectedTopic.skillName}
          </label>
          <p className={classes.paragraph}>{selectedTopic.summary}</p>
          <Row noGutters className="align-content-center justify-content-start">
            <label className={`${classes.paragraph} mb-0`}>
              {selectedTopic.years} Years
            </label>
          </Row>
        </Col>
      </CardContainer>
    )
  }
  return null
}

const TopicsOfInterest = ({ user, reload, handleError }) => {
  const classes = useStyles()
  const [listMode, setListMode] = useState(true)
  const [topics, setTopics] = useState([])
  const [maxConversations, setMaxConversations] = useState()

  const {
    submitted,
    submit: updateProfile,
    err: updateErr,
    submitting,
  } = useApiSubmit('updateProfileV1')

  useEffect(() => {
    if (user && user.topics) {
      const groups = {}
      user.topics.forEach((element) => {
        if (groups[element.groupId]) {
          groups[element.groupId].push(element)
        } else {
          groups[element.groupId] = [element]
        }
      })
      const result = []
      Object.keys(groups).forEach((key) => {
        result.push(groups[key])
      })
      setTopics(result)
      setMaxConversations(
        conversationOptions.find(
          (item) => item.value === user.maxConversations,
        ),
      )
    }
  }, [user])

  useEffect(() => {
    if (submitted && !updateErr && !submitting) {
      reload()
    } else if (updateErr && !submitting) {
      handleError(updateErr, true, {
        message: 'There was a problem updating your profile.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, updateErr, submitting])

  const save = useCallback(
    (maxConversations) => {
      try {
        updateProfile({
          params: {
            profileId: user.id,
          },
          body: {
            firstName: user.firstName,
            lastName: user.lastName,
            conversationFrequencyId: user.conversationFrequencyId,
            notificationFrequencyId: user.notificationFrequencyId,
            maxConversations: Number(maxConversations),
            email: user.email,
            regionId: user.region,
            showTour: user.showTour,
            bio: user.bio,
            funFact: user.funFact,
            homeTown: user.homeTown,
            highSchool: user.highSchool,
            linkedIn: user.linkedIn,
            location: user.location,
            phoneNumber: user.phoneNumber,
          },
        })
      } catch (err) {
        const { handleError } = this.props
        handleError(err, true, {
          message: 'There was a problem updating your profile.',
        })
      }
    },
    [
      updateProfile,
      user.bio,
      user.conversationFrequencyId,
      user.email,
      user.firstName,
      user.funFact,
      user.highSchool,
      user.homeTown,
      user.id,
      user.lastName,
      user.linkedIn,
      user.location,
      user.notificationFrequencyId,
      user.phoneNumber,
      user.region,
      user.showTour,
    ],
  )

  const handleSelectMax = useCallback(
    (key, value) => {
      setMaxConversations(value)
      save(value.value)
    },
    [save],
  )

  const Header = useCallback(
    () => (
      <>
        <div className={classes.regular}>
          <CardContainer className="mb-2 py-2">
            <Row className="justify-content-between align-items-center">
              <Col>
                <CardTitle title="Topics of Interest" />
              </Col>
              <Col className="pl-0">
                <div className="d-flex align-items-center">
                  <label className="mb-0">Max Scheduled Conversations</label>
                  <div className={classes.selector}>
                    <CustomSelect
                      options={conversationOptions}
                      onSelect={handleSelectMax}
                      value={maxConversations}
                      border={false}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={2} className="pl-0 d-flex justify-content-end">
                <SquareButton
                  onClick={() => setListMode(true)}
                  isActive={listMode}
                  className={classes.headerButton}
                >
                  <i className="ti-menu-alt" />
                </SquareButton>
                <SquareButton
                  onClick={() => setListMode(false)}
                  isActive={!listMode}
                >
                  <i className="ti-layout-grid2" />
                </SquareButton>
              </Col>
            </Row>
          </CardContainer>
        </div>
        <div className={classes.mobile}>
          <CardContainer className="mb-2">
            <CardTitle title="Topics of Interest" />
            <Divider />
            <Row noGutters className="align-items-center">
              <Col xs={8}>
                <label className="pl-3 mb-0">Max Scheduled Conversations</label>
              </Col>
              <Col xs={2} className={`${classes.selector}`}>
                <CustomSelect
                  options={conversationOptions}
                  onSelect={handleSelectMax}
                  value={maxConversations}
                  border={false}
                />
              </Col>
            </Row>
          </CardContainer>
        </div>
      </>
    ),
    [classes, handleSelectMax, listMode, maxConversations],
  )

  const TopicRow = useCallback(
    ({ topic }) => (
      <>
        <div className={classes.regular}>
          <CardContainer className={classes.row}>
            <Col xs={1}>
              <CircleName text={topic.groupName[0]} />
            </Col>
            <Col xs={5}>
              <label className={`${classes.title} w-100`}>
                {topic.skillName}
              </label>
              <label className={classes.subtitle}>{topic.groupName}</label>
            </Col>
            <Col xs={4} className="p-0">
              <label className={`${classes.title}`}>
                <SkillLevelIcon rating={topic.rating} />
                {topic.rating} {ratingValues[topic.rating - 1].text}
              </label>
              <label className={`${classes.subtitle} w-100 pl-3`}>
                Self-Rating
              </label>
            </Col>
            <Col xs={2}>
              <label className={`${classes.title} w-100`}>
                {topic.years} Years
              </label>
              <label className={classes.subtitle}>Years</label>
            </Col>
          </CardContainer>
        </div>
        <div className={classes.mobile}>
          <CardContainer className={classes.row}>
            <Col xs={3}>
              <CircleName text={topic.groupName[0]} />
            </Col>
            <Col xs={7} className="p-0">
              <label className={`${classes.title} w-100`}>
                {topic.skillName}
              </label>
              <label className={classes.subtitle}>{topic.groupName}</label>
            </Col>
            <Col xs={2} className="d-flex align-items-end">
              <label className={`${classes.title}`}>
                <SkillLevelIcon rating={topic.rating} />
                {topic.rating}
              </label>
            </Col>
          </CardContainer>
        </div>
      </>
    ),
    [
      classes.mobile,
      classes.regular,
      classes.row,
      classes.subtitle,
      classes.title,
    ],
  )

  return (
    <>
      <Header />
      {listMode ? (
        user.topics &&
        user.topics.map((topic) => <TopicRow topic={topic} key={topic.id} />)
      ) : (
        <Row>
          {topics.map((topic) => (
            <Col xs={12} md={6}>
              <TopicCard topics={topic} key={topic.id} />
            </Col>
          ))}
        </Row>
      )}
    </>
  )
}

export default compose(withErrorHandler)(TopicsOfInterest)
