import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Typeaheads } from '../Forms/Input'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  typeAHeads: {
    '& input': {
      color: '#575757',
      height: 30,
      '&::placeholder': {
        fontFamily: 'lato',
        fontWeight: 'bold',
        fontSize: 14,
        color: '#575757',
      },
    },
  },
})

const Filter = ({ filters = [], filterFunction }) => {
  const classes = useStyles()
  return (
    <Row>
      {filters.map((x, i) => {
        return x ? (
          <Col
            xs={12}
            className={`pb-3 pl-4 pr-4 ${classes.typeAHeads}`}
            key={i}
          >
            <Typeaheads
              id={`filter-${i}`}
              placeholder={x.placeholder}
              options={x.options}
              onChange={selected =>
                filterFunction({ filterName: x.name, selection: selected })
              }
              clearButton={true}
            />
          </Col>
        ) : null
      })}
    </Row>
  )
}

export default Filter
