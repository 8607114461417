import React, { FC, PropsWithChildren } from 'react'
import { Card } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import Divider from './Divider'

const useStyles = createUseStyles({
  card: {
    padding: 15,
    marginBottom: 32,
    border: 'none',
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
  },
  header: {
    marginBottom: 15,
  },
})

type Props = {
  footerDivider?: boolean
  footer?: React.ReactElement | null
  headerDivider?: boolean
  customHeaderDivider?: React.ReactElement | null
  header?: React.ReactElement | null
  className?: string
  dataTestId?: string
}
const CardContainer: FC<PropsWithChildren<Props>> = ({
  children,
  footerDivider = false,
  footer = null,
  headerDivider = false,
  customHeaderDivider = null,
  header = null,
  className = '',
  dataTestId = '',
}) => {
  const classes = useStyles()

  return (
    <Card className={`${classes.card} ${className}`} data-testid={dataTestId}>
      <>
        {header && <div className={classes.header}>{header}</div>}
        {headerDivider && (
          <>
            {customHeaderDivider ? (
              customHeaderDivider
            ) : (
              <Divider className="mt-0" />
            )}
          </>
        )}
        {children}
        {footerDivider && <Divider />}
        {footer}
      </>
    </Card>
  )
}

export default CardContainer
