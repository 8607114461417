import React, { ComponentType } from 'react'
import { useNotifications, NotificationData } from './hooks'

export type NotificationsProps = {
  pushNotification: (notification: NotificationData) => void
}

const withNotifications =
  <T extends NotificationsProps = NotificationsProps>(
    Component: React.ComponentType<T>,
  ): ComponentType<Omit<T, keyof NotificationsProps>> =>
  (props: Omit<T, keyof NotificationsProps>) => {
    const [, { pushNotification }] = useNotifications()
    return <Component {...(props as T)} pushNotification={pushNotification} />
  }

export default withNotifications
