import {
  createLikertMatrix,
  createYesNo,
  defaultTopLevelProps,
} from '../assessment-utils'
import {
  assessmentInfoUsphs as assessment2Info,
  BR_customPage1,
  questionGroup2_1,
  questionGroup2_5,
  questionGroup2_2,
  questionGroup2_3,
  questionGroup2_4,
  questionGroup2_6,
  questionGroup2_7,
  questionGroup2_8,
  questionGroupMentor1,
  questionGroupMentor2_1,
  questionGroupMentor2_2,
  questionGroupMentee1,
  questionGroupMentee2_1,
  questionGroupMentee2_2,
} from './AssessmentSchema.matching.ccusphs.questions'

const visibleIfIsMentor = "{mentoris1} = 'mentor' or {mentoris1} = 'both'"
const visibleIfIsMentee = "{mentoris1} = 'mentee' or {mentoris1} = 'both'"
export const surveyJsonMatchingUSPHS = {
  ...defaultTopLevelProps,
  ...assessment2Info,
  pages: [
    // Custom page for Broadridge
    BR_customPage1,

    // Common questions (both mentor and mentee)
    createLikertMatrix('page02', questionGroup2_1, '7p-SD-SA'),
    createLikertMatrix('page03', questionGroup2_2, '7p-SD-SA'),
    createLikertMatrix('page04', questionGroup2_3, '7p-SD-SA'),
    createLikertMatrix('page05', questionGroup2_4, '7p-SD-SA'),
    createLikertMatrix('page06', questionGroup2_5, '7p-SD-SA'),
    createLikertMatrix('page07', questionGroup2_6, '7p-SD-SA'),
    createLikertMatrix('page08', questionGroup2_7, '7p-SD-SA'),
    createLikertMatrix('page09', questionGroup2_8, '7p-SD-SA'),

    // Mentor specific questions
    createLikertMatrix(
      'page10',
      questionGroupMentor1,
      '7p-SD-SA',
      visibleIfIsMentor,
    ),
    createLikertMatrix(
      'page11',
      questionGroupMentor2_1,
      'BR-mentors-1',
      visibleIfIsMentor,
    ),
    createLikertMatrix(
      'page12',
      questionGroupMentor2_2,
      'BR-mentors-1',
      visibleIfIsMentor,
    ),
    // add mentor 8-pages

    createYesNo(
      'Are you willing and able to mentor two (2) mentees?',
      'm_numberofps',
      true,
      visibleIfIsMentor,
    ),

    // Mentee specific questions
    createLikertMatrix(
      'page13',
      questionGroupMentee1,
      '7p-SD-SA',
      visibleIfIsMentee,
    ),
    createLikertMatrix(
      'page14',
      questionGroupMentee2_1,
      'BR-mentees-1',
      visibleIfIsMentee,
    ),
    createLikertMatrix(
      'page15',
      questionGroupMentee2_2,
      'BR-mentees-1',
      visibleIfIsMentee,
    ),
    // add mentee 8-pages
  ],
}

export function validateMatchingQuestions(_, options) {
  if (options.name === 'empidconfirm') {
    const empIdQuestion = _.getQuestionByName('empid')
    if (!empIdQuestion) return

    if (empIdQuestion.value !== options.value) {
      options.error = `Please ensure that your Employee Id matches.`
    }
  }
}
