import React, { useMemo } from 'react'
import { Button } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

import colors, { actionButtonStyles } from '../theme/theme2'
import Loader from './Loader'

const ActionButton = ({
  text,
  onClick,
  className = '',
  type = 'LIGHT_GREEN',
  disabled = false,
  width = 'unset',
  height = 'unset',
  loading = false,
  dataTestId = '',
  ...rest
}) => {
  const useStyles = createUseStyles({
    button: {
      height,
      width,
      padding: '8px 30px',
      borderRadius: 4,
      border: 'none',
      fontFamily: 'Lato !important',
      fontSize: 13,
      letterSpacing: '0.33px',
      fontWeight: 'bold',
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:focus': {
        boxShadow: 'none !important',
      },
      '&:hover': {
        border: 'none',
        boxShadow: '0 3px 6px 0 rgba(153, 155, 168, 0.61)',
      },
      '&:active': {
        border: 'none',
      },
      '&:disabled, &:disabled:hover': {
        opacity: 0.8,
        boxShadow: 'unset',
      },
    },
    lightGreen: {
      backgroundColor: actionButtonStyles.secondary.initial,
      color: actionButtonStyles.secondary.text,
      border: `1px solid ${actionButtonStyles.primary.initial}`,
      '&:focus': {
        backgroundColor: actionButtonStyles.secondary.hover,
        color: actionButtonStyles.secondary.text,
        border: `1px solid ${actionButtonStyles.primary.initial}`,
      },
      '&:hover': {
        backgroundColor: actionButtonStyles.secondary.hover,
        color: actionButtonStyles.secondary.text,
        border: `1px solid ${actionButtonStyles.primary.initial}`,
      },
      '&:active': {
        color: `${actionButtonStyles.secondary.text} !important`,
        backgroundColor: `${actionButtonStyles.secondary.click} !important`,
        border: `1px solid ${actionButtonStyles.secondary.text}`,
      },
      '&:disabled, &:disabled:hover': {
        backgroundColor: actionButtonStyles.secondary.inactive,
        color: actionButtonStyles.secondary.inactiveText,
        border: `1px solid ${actionButtonStyles.secondary.inactiveText}`,
      },
    },
    strongGreen: {
      backgroundColor: actionButtonStyles.primary.initial,
      color: actionButtonStyles.primary.text,
      '&:focus': {
        backgroundColor: actionButtonStyles.primary.hover,
        color: actionButtonStyles.primary.text,
      },
      '&:hover': {
        backgroundColor: actionButtonStyles.primary.hover,
        color: actionButtonStyles.primary.text,
      },
      '&:active': {
        color: actionButtonStyles.primary.text,
        backgroundColor: `${actionButtonStyles.primary.click} !important`,
      },
      '&:disabled, &:disabled:hover': {
        backgroundColor: actionButtonStyles.primary.inactive,
      },
    },
    strongRed: {
      backgroundColor: actionButtonStyles.red.initial,
      color: actionButtonStyles.red.text,
      '&:focus': {
        backgroundColor: actionButtonStyles.red.hover,
        color: actionButtonStyles.red.text,
      },
      '&:hover': {
        backgroundColor: actionButtonStyles.red.hover,
        color: actionButtonStyles.red.text,
      },
      '&:active': {
        color: actionButtonStyles.red.text,
        backgroundColor: `${actionButtonStyles.red.click} !important`,
      },
      '&:disabled, &:disabled:hover': {
        backgroundColor: actionButtonStyles.red.inactive,
        color: actionButtonStyles.red.text,
      },
    },
    lightOrange: {
      backgroundColor: actionButtonStyles.orangeSecondary.inactive,
      color: actionButtonStyles.orangeSecondary.text,
      border: `1px solid ${actionButtonStyles.orangeSecondary.text}`,
      '&:focus': {
        backgroundColor: actionButtonStyles.orangeSecondary.hover,
        color: actionButtonStyles.orangeSecondary.text,
        border: `1px solid ${actionButtonStyles.orangeSecondary.text}`,
      },
      '&:hover': {
        backgroundColor: actionButtonStyles.orangeSecondary.hover,
        color: actionButtonStyles.orangeSecondary.text,
        border: `1px solid ${actionButtonStyles.orangeSecondary.text}`,
      },
      '&:active': {
        color: `${actionButtonStyles.orangeSecondary.text} !important`,
        backgroundColor: `${actionButtonStyles.orangeSecondary.click} !important`,
        border: `1px solid ${actionButtonStyles.orangeSecondary.text} !important`,
      },
      '&:disabled, &:disabled:hover': {
        border: `1px solid ${actionButtonStyles.orangeSecondary.inactiveText}`,
        color: actionButtonStyles.orangeSecondary.inactiveText,
        backgroundColor: actionButtonStyles.orangeSecondary.inactive,
      },
    },
    strongOrange: {
      backgroundColor: actionButtonStyles.orange.initial,
      color: actionButtonStyles.orange.text,
      '&:focus': {
        backgroundColor: actionButtonStyles.orange.hover,
        color: actionButtonStyles.orange.text,
      },
      '&:hover': {
        backgroundColor: actionButtonStyles.orange.hover,
        color: actionButtonStyles.orange.text,
      },
      '&:active': {
        color: actionButtonStyles.orange.text,
        backgroundColor: `${actionButtonStyles.orange.click} !important`,
      },
      '&:disabled, &:disabled:hover': {
        backgroundColor: actionButtonStyles.orange.inactive,
        color: actionButtonStyles.orange.text,
      },
    },
    lightRed: {
      backgroundColor: actionButtonStyles.redSecondary.inactive,
      color: actionButtonStyles.redSecondary.text,
      border: `1px solid ${actionButtonStyles.redSecondary.text}`,
      '&:focus': {
        backgroundColor: actionButtonStyles.redSecondary.hover,
        color: actionButtonStyles.redSecondary.text,
        border: `1px solid ${actionButtonStyles.redSecondary.text}`,
      },
      '&:hover': {
        backgroundColor: actionButtonStyles.redSecondary.hover,
        color: actionButtonStyles.redSecondary.text,
        border: `1px solid ${actionButtonStyles.redSecondary.text}`,
      },
      '&:active': {
        color: `${actionButtonStyles.redSecondary.text} !important`,
        backgroundColor: `${actionButtonStyles.redSecondary.click} !important`,
        border: `1px solid ${actionButtonStyles.redSecondary.text} !important`,
      },
      '&:disabled, &:disabled:hover': {
        border: `1px solid ${actionButtonStyles.redSecondary.inactiveText}`,
        color: actionButtonStyles.redSecondary.inactiveText,
        backgroundColor: actionButtonStyles.redSecondary.inactive,
      },
    },
    gray: {
      backgroundColor: '#c0c0bf',
      color: colors.white,
      border: `1px solid #c0c0bf`,
      '&:focus': {
        backgroundColor: '#626566',
        color: colors.white,
        border: `1px solid #626566`,
      },
      '&:hover': {
        backgroundColor: '#626566',
        color: colors.white,
        border: `1px solid #626566`,
      },
      '&:active': {
        color: `white!important`,
        backgroundColor: `#343434!important`,
        border: `1px solid #343434 !important`,
      },
      '&:disabled, &:disabled:hover': {
        border: `1px solid rgba(192, 192, 191, 0.5)`,
        color: colors.white,
        backgroundColor: 'rgba(192, 192, 191, 0.5)',
      },
    },
    transparent: {
      backgroundColor: 'transparent',
      color: colors.grey3,
      '&:hover': {
        color: colors.grey3,
        backgroundColor: colors.grey1,
      },
      '&:active': {
        color: colors.grey3,
        backgroundColor: `${colors.grey2} !important`,
      },
      '&:disabled, &:disabled:hover': {
        backgroundColor: 'transparent',
      },
    },
    loader: {
      position: 'absolute',
      marginTop: 8,
      marginLeft: 6,
    },
    save: {
      width: 40,
      height: 40,
      fontSize: 11,
      letterSpacing: '0.22px',
      borderRadius: 10,
      padding: '12px 6px',
      backgroundColor: actionButtonStyles.secondary.initial,
      color: actionButtonStyles.primary.initial,
      border: `1px solid ${actionButtonStyles.primary.initial}`,
      '&:hover': {
        backgroundColor: actionButtonStyles.secondary.hover,
        color: actionButtonStyles.primary.initial,
        border: `1px solid ${actionButtonStyles.primary.initial}`,
      },
      '&:active': {
        color: `${actionButtonStyles.secondary.text} !important`,
        backgroundColor: `${actionButtonStyles.secondary.click} !important`,
        border: `1px solid ${actionButtonStyles.secondary.text} !important`,
      },
      '&:disabled, &:disabled:hover': {
        backgroundColor: actionButtonStyles.secondary.inactive,
        color: actionButtonStyles.secondary.inactiveText,
        border: `1px solid ${actionButtonStyles.secondary.inactiveText}`,
      },
    },
    outlineWhite: {
      backgroundColor: 'transparent',
      color: colors.white,
      border: `1px solid ${colors.white}`,
      '&:hover': {
        color: `${colors.white} !important`,
        backgroundColor: `#f697a6`,
        border: `1px solid ${colors.white}`,
      },
      '&:active': {
        color: `${colors.white} !important`,
        backgroundColor: `#f697a6!important`,
        border: `1px solid ${colors.white}!important`,
      },
      '&:disabled, &:disabled:hover': {
        backgroundColor: 'transparent',
      },
    },
  })
  const classes = useStyles()

  const buttonColor = useMemo(() => {
    switch (type) {
      case 'LIGHT_GREEN':
        return { class: classes.lightGreen, loaderColor: colors.primary }
      case 'STRONG_GREEN':
        return { class: classes.strongGreen, loaderColor: 'white' }
      case 'LIGHT_RED':
        return { class: classes.lightRed, loaderColor: colors.error }
      case 'STRONG_RED':
        return { class: classes.strongRed, loaderColor: 'white' }
      case 'LIGHT_ORANGE':
        return { class: classes.lightOrange, loaderColor: colors.error }
      case 'STRONG_ORANGE':
        return { class: classes.strongOrange, loaderColor: 'white' }
      case 'TRANSPARENT':
        return { class: classes.transparent, loaderColor: colors.primary }
      case 'OUTLINE_WHITE':
        return { class: classes.outlineWhite, loaderColor: colors.white }
      case 'GRAY':
        return { class: classes.gray, loaderColor: colors.white }
      case 'SAVE':
        return { class: classes.save, loaderColor: colors.primary }
      default:
        break
    }
  }, [classes, type])

  return (
    <Button
      className={`${classes.button} ${buttonColor.class} ${className}`}
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestId}
      {...rest}
    >
      <span style={loading ? { color: 'transparent' } : {}}>{text}</span>
      {loading && (
        <div className={classes.loader}>
          <Loader color={buttonColor.loaderColor} />
        </div>
      )}
    </Button>
  )
}

export default ActionButton
