import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import { headings } from '../../../../theme/theme2'
import CustomTextArea from '../../../../elemets/CustomTextArea'
import SquareButton from '../../../../elemets/SquareButton'
import { isMobile } from '../../../../utils/common'
import RoundButton from '../../../../elemets/RoundButton'
import ActionButton from '../../../../elemets/ActionButton'
import { useApiSubmit } from '../../../../api'
import { useErrorHandler } from '../../../../errors'

const useStyles = createUseStyles({
  title: {
    ...headings.text,
    fontWeight: 'bold',
  },
  greyText: {
    ...headings.text,
    fontSize: 14,
    paddingLeft: 16,
    marginBottom: 0,
    opacity: 0.4,
    fontWeight: 'bold',
  },
  text: {
    ...headings.text,
    fontSize: 16,
    paddingLeft: 16,
    marginBottom: 0,
    fontWeight: 'bold',
    color: '#343434 !important',
  },
  description: {
    ...headings.text,
    fontSize: 14,
    paddingLeft: 16,
    marginBottom: 0,
    color: '#343434 !important',
  },
  titleRow: {
    height: 20,
  },
})

const GroupInformation = ({ group }) => {
  const classes = useStyles()
  const handleError = useErrorHandler()

  const [formState, setFormState] = useState({
    name: '',
    description: '',
  })
  const [editNameMode, setEditNameMode] = useState(false)
  const [editDescriptionMode, setEditDescriptionMode] = useState(false)

  const handleNameInputChange = useCallback(
    (name) => {
      setFormState({ ...formState, name: name })
    },
    [formState],
  )

  const handleDescriptionInputChange = useCallback(
    (name) => {
      setFormState({ ...formState, description: name })
    },
    [formState],
  )

  useEffect(() => {
    setFormState({
      name: group.name !== null ? group.name : '',
      description: group.description !== null ? group.description : '',
    })
  }, [group])

  const SaveMenu = ({ defaultState, formState, editMode, setEditMode }) => {
    const {
      submitted,
      submit: handleFormSave,
      err,
      submitting,
    } = useApiSubmit('updateGroup')

    useEffect(() => {
      if (submitted && !err && !submitting) {
        setEditMode(false)
      } else if (err && !submitting) {
        handleError(err, true, {
          message: 'There was a problem updating your group.',
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitted, err, submitting])

    return (
      <Row noGutters className={`justify-content-end mt-2 mb-2`}>
        <Row noGutters className="justify-content-end">
          <RoundButton
            icon="far fa-times-circle"
            onClick={() => {
              setFormState({ ...formState, ...defaultState })
              setEditMode(!editMode)
            }}
            type="BLACK"
            className="mr-3"
          />
          <ActionButton
            text="SAVE"
            onClick={() =>
              handleFormSave({
                params: {
                  groupId: group.id,
                },
                body: {
                  name: formState.name,
                  description: formState.description,
                },
              })
            }
            loading={submitting}
            type={'SAVE'}
            disabled={
              formState.description.length > 5000 || formState.name.length > 50
            }
          />
        </Row>
      </Row>
    )
  }

  return (
    <>
      <Row className={classes.titleRow}>
        <Col xs={editNameMode || isMobile() ? 10 : 9}>
          <p className={classes.greyText}>Group Name</p>
        </Col>
        {!editNameMode && (
          <Col xs={isMobile() ? 2 : 3} className={`d-flex justify-content-end`}>
            <SquareButton
              isActive={editNameMode}
              onClick={() => setEditNameMode(!editNameMode)}
            >
              <i className="material-icons">edit</i>
            </SquareButton>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12}>
          <CustomTextArea
            value={formState.name}
            onChange={(e) => handleNameInputChange(e.target.value)}
            editMode={editNameMode}
            className={`ml-2 ${classes.text}`}
            maxLength={50}
          />
        </Col>
      </Row>
      {editNameMode && (
        <SaveMenu
          editMode={editNameMode}
          formState={formState}
          setEditMode={setEditNameMode}
          defaultState={{ name: group.name }}
        />
      )}

      <Row className={classes.titleRow}>
        <Col xs={editDescriptionMode || isMobile() ? 10 : 9}>
          <p className={classes.greyText}>
            Group Description / Welcome Message
          </p>
        </Col>
        {!editDescriptionMode && (
          <Col xs={isMobile() ? 2 : 3} className={`d-flex justify-content-end`}>
            <SquareButton
              isActive={editDescriptionMode}
              onClick={() => setEditDescriptionMode(!editDescriptionMode)}
            >
              <i className="material-icons">edit</i>
            </SquareButton>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={editDescriptionMode || isMobile() ? 12 : 9}>
          <CustomTextArea
            value={formState.description}
            onChange={(e) => handleDescriptionInputChange(e.target.value)}
            editMode={editDescriptionMode}
            className={`ml-2 ${classes.description}`}
            maxLength={5000}
          />
        </Col>
      </Row>
      {editDescriptionMode && (
        <SaveMenu
          editMode={editDescriptionMode}
          formState={formState}
          setEditMode={setEditDescriptionMode}
          defaultState={{ description: group.description }}
        />
      )}
    </>
  )
}

export default GroupInformation
