import React, { ReactNode, createContext, useContext } from 'react'
import { Method } from './Method'

type ContextValue = {
  methods: Method[]
  ready: boolean
}

export const Context = createContext<ContextValue>({
  methods: [],
  ready: false,
})

type MethodsProviderProps = {
  methods: Method[]
  ready?: boolean
  children: ReactNode
}

const MethodsProvider = ({
  methods,
  ready = true,
  children,
}: MethodsProviderProps): JSX.Element => (
  <Context.Provider value={{ methods, ready }}>{children}</Context.Provider>
)

export default MethodsProvider

export const useMethods = (): ContextValue => useContext(Context)
