import React, { useCallback, useState, useEffect } from 'react'
import colors, { actionButtonStyles, headings } from '../../theme/theme2'
import { createUseStyles } from 'react-jss'
import { Row, Col, Button, Collapse } from 'react-bootstrap'
import ActionButton from '../../elemets/ActionButton'
import CardContainer from '../../elemets/CardContainer'
import Divider from '../../elemets/Divider'
import compose from 'just-compose'
import { withRouter } from 'react-router-dom'
import { decodeAtoBInputs, isMobile } from '../../utils/common'
import ClockIcon from '../Icons/Clock'
import { withNotifications } from '../../notifications'
import RequestAvailabilityModal from '../Modals/Conversation/RequestAvailabilityModal'
import TopicsContainer from '../Topics/TopicsContainer'
import GravatarContainer from '../Users/GravatarContainer'
import Icon from '../Icons'
import RecommendModal from '../Modals/Conversation/RecommendModal'
import { useGroupMembers } from '../Context/GroupMembers'
import MeetNowButton from '../Conversations/MeetNow/MeetNowButton'

const useStyles = createUseStyles({
  arrows: {
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },
  bioTitle: {
    ...headings.paragraph,
    fontWeight: 'bold!important',
    color: colors.dark,
  },
  bio: {
    ...headings.paragraph,
    color: colors.grey3,
  },
  expandButton: {
    fontFamily: 'Lato',
    fontSize: 12,
    textAlign: 'right',
  },
})

const ProfileCard = ({ user, history, openAvailabilityModal }) => {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)
  const [requested, setRequested] = useState(false)
  const [showRequestModal, setShowRequestModal] = useState(false)
  const [showRecommendModal, setShowRecommendModal] = useState(false)
  const [displayedTopic, setDisplayedTopic] = useState(0)
  const { groups } = useGroupMembers()

  useEffect(() => {
    if (openAvailabilityModal) {
      setShowRequestModal(true)
    }
  }, [openAvailabilityModal])

  const renderButton = useCallback(() => {
    if (user.availabilities > 0) {
      return (
        <>
          <MeetNowButton
            match={user}
            buttonType={'LIGHT_ORANGE'}
            reloadUpcoming={() => {}}
          />
          &nbsp;
          <ActionButton
            height="34px"
            text={
              <>
                <ClockIcon
                  style={{
                    color: actionButtonStyles.secondary.text,
                    cursor: 'default',
                    marginRight: 10,
                  }}
                />
                AVAILABILITY
              </>
            }
            onClick={() => {
              history.push(`/matches?token=${user.token}`, {
                params: { user },
              })
            }}
            type="LIGHT_GREEN"
            className="text-nowrap px-3"
          />
        </>
      )
    } else {
      return (
        <>
          <MeetNowButton
            match={user}
            buttonType={'LIGHT_ORANGE'}
            reloadUpcoming={() => {}}
          />
          &nbsp;
          <ActionButton
            height="34px"
            text={
              !requested ? 'REQUEST AVAILABILITY' : 'AVAILABILITY REQUESTED'
            }
            onClick={() => {
              setShowRequestModal(true)
            }}
            type="LIGHT_GREEN"
            className="text-nowrap px-3"
            disabled={requested}
          />
        </>
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, requested])

  return (
    <CardContainer className={`mb-2`}>
      {showRequestModal && (
        <RequestAvailabilityModal
          show={showRequestModal}
          match={user}
          setRequestedAvailability={setRequested}
          onClose={() => {
            setShowRequestModal(false)
            if (openAvailabilityModal) {
              history.push(`${window.location.pathname}`)
            }
          }}
        />
      )}
      {showRecommendModal && (
        <RecommendModal
          show={showRecommendModal}
          match={user}
          onClose={() => setShowRecommendModal(false)}
        />
      )}
      <Row>
        <Col xs={11} md={4} className={'pl-4 pr-0'}>
          <GravatarContainer profile={user} size={isMobile ? 60 : 50} />
        </Col>
        <Col xs={0} md={5} className={'d-none d-md-block'}>
          <TopicsContainer
            topics={user.topics.map((topic) => {
              return { ...topic, skillGroupName: topic.groupName }
            })}
            displaySummary={false}
            setDisplayedTopic={setDisplayedTopic}
          />
        </Col>
        <Col xs={1} md={3} className={'p-0 pr-md-3 align-self-center'}>
          <div className={'d-none d-md-flex justify-content-end'}>
            {renderButton()}
          </div>
          <div className={'text-md-right'}>
            <Button
              className={`${classes.arrows} p-0`}
              onClick={() => {
                setExpand(!expand)
              }}
            >
              <span className={`d-flex ${classes.expandButton}`}>
                <span className={`d-none d-md-block`}>
                  {expand ? 'Show Less' : 'Show More'}
                </span>
                <i
                  className={`ml-md-1 pt-md-1 fas ${
                    expand ? 'fa-chevron-up' : 'fa-chevron-down'
                  }`}
                />
              </span>
            </Button>
          </div>
        </Col>
      </Row>
      <Collapse in={expand}>
        <Row>
          <Divider className="mt-3" />
          <Col xs={12} className={`d-md-none px-4 py-2`}>
            <TopicsContainer
              topics={user.topics.map((topic) => {
                return { ...topic, skillGroupName: topic.groupName }
              })}
            />
          </Col>
          {user.topics[displayedTopic] &&
            user.topics[displayedTopic].summary !== '' && (
              <Col xs={8} className={`d-none d-md-block px-4 py-2`}>
                <span className={classes.bioTitle}>
                  Why a topic of Interest?
                </span>
                <br />
                <span className={`pl-3`}>
                  {user.topics[displayedTopic].summary}
                </span>
              </Col>
            )}
          <Col xs={12} className={`px-4 py-2`}>
            <span className={classes.bioTitle}>Bio / Executive Summary</span>
            <p className={`mt-1 pl-3 ${classes.bio}`}>
              {decodeAtoBInputs(user.bio)}
            </p>
          </Col>
          <Col
            xs={12}
            className={`px-1 px-sm-4 d-flex ${
              groups.length === 1
                ? 'justify-content-between'
                : 'justify-content-end'
            } justify-content-md-end`}
          >
            {groups.length === 1 && (
              <ActionButton
                height="34px"
                text={
                  <>
                    <Icon
                      icon={'fas fa-user-plus'}
                      style={{
                        color: actionButtonStyles.secondary.text,
                        cursor: 'default',
                        marginRight: 10,
                      }}
                    />
                    RECOMMEND
                  </>
                }
                onClick={() => {
                  setShowRecommendModal(true)
                }}
                type="LIGHT_GREEN"
                className="text-nowrap px-3"
              />
            )}
            <div className={'d-md-none'}>{renderButton()}</div>
          </Col>
        </Row>
      </Collapse>
    </CardContainer>
  )
}

export default compose(withRouter, withNotifications)(ProfileCard)
