import React from 'react'
import { Button } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    fontFamily: 'Lato !important',
    fontSize: 12,
    fontWeight: 'bold',
    '&:focus': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      border: `none`,
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      border: `none`,
      color: '#d8d8d8',
    },
    '&:disabled:hover': {
      backgroundColor: 'transparent',
      border: `none`,
      color: '#d8d8d8',
    },
    '&:active': {
      backgroundColor: 'transparent !important',
      border: 'none',
    },
  },
  iconOrder: {
    order: 2,
  },
  textOrder: {
    order: 1,
  },
})

const LinkButton = ({
  text,
  icon,
  onClick,
  className,
  iconChildren = '',
  iconRight = false,
  disabled = false,
  dataTestId = '',
}) => {
  const classes = useStyles()

  return (
    <Button
      className={`${classes.button} ${className}`}
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {icon && (
        <i
          className={`${icon} ${
            iconRight ? `ml-2 ${classes.iconOrder}` : 'mr-2'
          }`}
        >
          {iconChildren}{' '}
        </i>
      )}
      {text && (
        <span className={iconRight ? classes.textOrder : {}}>{text}</span>
      )}
    </Button>
  )
}

export default LinkButton
