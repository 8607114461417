import React, { useState } from 'react'
import CardContainer from '../../elemets/CardContainer'
import CardTitle from '../../elemets/CardTitle'
import { createUseStyles } from 'react-jss'
import { Button, Col, Collapse, Row } from 'react-bootstrap'
import moment from 'moment'
import colors from '../../theme/theme2'
import CustomInput from '../../elemets/CustomInput'

const useStyles = createUseStyles({
  experienceRow: {
    padding: '0 10px',
  },
  expandButton: {
    fontFamily: 'Lato',
    fontSize: 12,
    textAlign: 'right',
    color: colors.primary,
  },
  arrows: {
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },
  company: {
    fontFamily: 'lato',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 0,
  },
})

const ExperienceRow = ({ experience }) => {
  const classes = useStyles()
  return (
    <Row className={classes.experienceRow}>
      <Col className="pr-0">
        <CustomInput
          inputStyle="pr-0"
          value={experience.companyName}
          title="Company"
          editMode={false}
        />
      </Col>
      <Col className="pr-0">
        <CustomInput
          inputStyle="pr-0"
          value={experience.industryName}
          title="Industry"
          editMode={false}
        />
      </Col>
      <Col className="pr-0">
        <CustomInput
          inputStyle="pr-0"
          value={experience.title}
          title="Title / Position"
          editMode={false}
        />
      </Col>
      <Col className="pr-0">
        <CustomInput
          inputStyle="pr-0"
          value={`${moment(experience.dateStart).format('YYYY')} to ${
            experience.dateEnd
              ? moment(experience.dateEnd).format('YYYY')
              : 'Present'
          }`}
          title="Years"
          editMode={false}
        />
      </Col>
    </Row>
  )
}

const ExperienceInfo = ({ experiences }) => {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)

  return (
    experiences.length > 0 && (
      <CardContainer
        header={
          <div className={`d-flex justify-content-between`}>
            <CardTitle title={'Experience'} />
            {experiences.length > 2 && (
              <Button
                className={`${classes.arrows}`}
                onClick={() => {
                  setExpand(!expand)
                }}
              >
                <span className={classes.expandButton}>
                  {expand ? 'Show Less' : 'Show More'}
                  <i
                    className={`ml-1 fas ${
                      expand ? 'fa-chevron-up' : 'fa-chevron-down'
                    }`}
                  />
                </span>
              </Button>
            )}
          </div>
        }
        headerDivider={true}
      >
        {experiences.slice(0, 2).map((experience, index) => {
          return <ExperienceRow key={index} experience={experience} />
        })}
        <Collapse in={expand}>
          <div>
            {experiences
              .slice(2, experiences.length)
              .map((experience, index) => {
                return <ExperienceRow key={index} experience={experience} />
              })}
          </div>
        </Collapse>
      </CardContainer>
    )
  )
}

export default ExperienceInfo
