import React, { useCallback, useEffect, useState } from 'react'
import ConfirmationModal from '../../../Modals/ConfirmationModal'
import ActionButton from '../../../../elemets/ActionButton'
import { isMobile } from '../../../../utils/common'
import { createUseStyles } from 'react-jss'
import { useApiSubmit } from '../../../../api'
import compose from 'just-compose'
import { withErrorHandler } from '../../../../errors'
import { headings } from '../../../../theme/theme2'
import CustomSelect from '../../../../elemets/CustomSelect'

const useStyles = createUseStyles({
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  name: {
    ...headings.title,
    marginTop: 10,
  },
  select: {
    minWidth: 120,
  },
})

const PermissionModal = ({
  user,
  group,
  reload,
  setDisplayModal,
  handleError,
}) => {
  const classes = useStyles()
  const [rol, setRole] = useState({
    value: 'user',
    label: 'User',
  })

  const roles = [
    { value: 'user', label: 'User' },
    { value: 'admin', label: 'Admin' },
  ]

  const {
    submitted: submittedAdmin,
    submit: addGroupAdmin,
    err: addAdminErr,
    submittingAddAdmin,
  } = useApiSubmit('addGroupAdmin')

  const {
    submitted: submittedRemoveAdmin,
    submit: removeGroupAdmin,
    err: removeAdminErr,
    submittingRemoveAdmin,
  } = useApiSubmit('removeGroupAdmin')

  useEffect(() => {
    if (submittedAdmin && !addAdminErr && !submittingAddAdmin) {
      setDisplayModal(false)
      reload()
    } else if (addAdminErr && !submittingAddAdmin) {
      handleError(addAdminErr, true, {
        message: 'There was a problem adding the role to this profile.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedAdmin, addAdminErr, submittingAddAdmin])

  useEffect(() => {
    if (submittedRemoveAdmin && !removeAdminErr && !submittingRemoveAdmin) {
      setDisplayModal(false)
      reload()
    } else if (removeAdminErr && !submittingRemoveAdmin) {
      handleError(removeAdminErr, true, {
        message: 'There was a problem removing this role.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedRemoveAdmin, removeAdminErr, submittingRemoveAdmin])

  const save = useCallback(() => {
    if (rol.value === 'user') {
      removeGroupAdmin({
        params: {
          userId: user.profileId,
          groupId: group.id,
        },
      })
    } else if (rol.value === 'admin') {
      addGroupAdmin({
        params: {
          userId: user.profileId,
          groupId: group.id,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rol])

  const handleSelectRol = useCallback((key, value) => {
    setRole(value)
  }, [])

  return (
    <ConfirmationModal
      show={true}
      onClose={() => setDisplayModal(false)}
      closeIcon={true}
      title={'Account Settings'}
      content={
        <>
          <div className="pl-3">
            <p className={`mt-0 mb-0 ${classes.name}`}>Lattitude Permissions</p>
          </div>
          <div className={`pt-4 d-flex align-items-start ${classes.select}`}>
            <CustomSelect
              options={roles.filter(
                rol =>
                  (rol.value === 'admin' &&
                    !user.hasOwnProperty('isAdmin') &&
                    !user.isAdmin) ||
                  rol.value !== 'admin',
              )}
              name={'Lattitude Permissions'}
              onSelect={handleSelectRol}
              value={rol}
              border={false}
              className={classes.select}
            />
          </div>
        </>
      }
      footer={
        <div className="d-flex justify-content-end">
          <ActionButton
            text="UPDATE"
            className="w-40"
            onClick={save}
            type="STRONG_GREEN"
          />
        </div>
      }
      dialogClassName={isMobile() ? classes.dialogMobile : ''}
    />
  )
}

export default compose(withErrorHandler)(PermissionModal)
