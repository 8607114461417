import { invokeOperationWithAuth } from '../../api'
import { Conversation } from './Conversation'
import { TopicId } from './form/fields/topics/data/Topic'
import { getAccessToken } from '../../auth0'

interface SavedConversation extends Conversation {
  id: number
}

type ApproximateDuration = '10' | '20' | '30'

type ConversationMethod =
  | 'PhoneCall'
  | 'InPerson'
  | 'Slack'
  | 'Teams'
  | 'Zoom'
  | 'WebEx'
  | 'MeetNow'
  | 'Other'

type Text = { text: string }

type ConversationTopic = { id: number; isPrimary?: boolean }

type ParticipantType = 'Initiator' | 'Participant'

type ParticipantRelationship =
  | 'AccountabilityPartner'
  | 'Ally'
  | 'Champion'
  | 'FitnessCoach'
  | 'HR'
  | 'LifeCoach'
  | 'Manager'
  | 'Mentee'
  | 'Mentor'
  | 'Peer'
  | 'Sponsor'
  | 'Successor'
  | 'Therapist'

type Participant = {
  userId: number
  type: ParticipantType
  relationship?: ParticipantRelationship
  isAcknowledged?: boolean
}

interface CreateConversationRequest {
  approximateDuration: ApproximateDuration
  convoMethod: ConversationMethod
  convoDateTime: string // ISO 8601
  convoGoals: Text[]
  convoNotes: Text[]
  convoTopics: ConversationTopic[]
  groupId: number
  participants: Participant[]
}

interface CreateConversationResponse {
  conversationId: number
}

const getApproximateDuration = ({
  duration,
}: Conversation): ApproximateDuration => {
  switch (duration) {
    case 'LessThan10': {
      return '10'
    }
    case 'LessThan30': {
      return '20'
    }
    case 'Over30': {
      return '30'
    }
    default: {
      throw new Error('unsupported duration')
    }
  }
}

const getConversationMethod = ({
  method,
}: Conversation): ConversationMethod => {
  return method
}

const DefaultStartTime = 12 * 60 * 60 // seconds, 12:00 PM

const getISODateTime = ({
  date: timestamp,
  startTime = DefaultStartTime,
}: Conversation): string => {
  const date = new Date((timestamp + startTime) * 1000)
  return date.toISOString()
}

const getGoals = ({ goal }: Conversation): Text[] => {
  if (!goal) {
    return []
  }

  return [{ text: goal }]
}

const getNotes = ({ notes }: Conversation): Text[] => {
  if (!notes) {
    return []
  }

  return [{ text: notes }]
}

const getTopics = ({ topics }: Conversation): ConversationTopic[] => {
  if (!topics || topics.length < 1) {
    return []
  }

  const [primaryTopic, ...others] = topics
  return [
    {
      id: primaryTopic,
      isPrimary: true,
    },
    ...others.map(
      (id: TopicId): ConversationTopic => ({
        id,
        isPrimary: false,
      }),
    ),
  ]
}

const getInitiator = (userId: number): Participant => ({
  userId,
  type: 'Initiator',
})

const getParticipant = ({
  participant,
  primaryRelationship,
}: Conversation): Participant => ({
  userId: participant,
  type: 'Participant',
  relationship: primaryRelationship,
})

const createConversation = async (
  userId: number,
  groupId: number,
  conversation: Conversation,
  signal?: AbortSignal,
): Promise<SavedConversation> => {
  // debugger
  const data: CreateConversationRequest = {
    approximateDuration: getApproximateDuration(conversation),
    convoMethod: getConversationMethod(conversation),
    convoDateTime: getISODateTime(conversation),
    convoGoals: getGoals(conversation),
    convoNotes: getNotes(conversation),
    convoTopics: getTopics(conversation),
    groupId,
    participants: [getInitiator(userId), getParticipant(conversation)],
  }

  const res = await invokeOperationWithAuth(getAccessToken)(
    'addConversationLogV1',
    {
      body: JSON.stringify(data),
      signal,
    },
  )

  const { conversationId } = res as CreateConversationResponse
  return {
    id: conversationId,
    ...conversation,
  }
}

export default createConversation
