import React, { useCallback, useEffect, useState } from 'react'
import { useApiSubmit } from '../../../api'
import compose from 'just-compose'
import { withRouter } from 'react-router'
import { withErrorHandler } from '../../../errors'
import { Col, Row } from 'react-bootstrap'
import CustomCheckbox from '../../../elemets/CustomCheckbox'
import CustomTextArea from '../../../elemets/CustomTextArea'
import { createUseStyles } from 'react-jss'
import colors, { buttonStyles, headings } from '../../../theme/theme2'
import CustomDate from '../../../elemets/CustomDate'
import moment from 'moment'

const useStyles = createUseStyles({
  text: {
    ...headings.text,
    fontSize: 14,
    paddingLeft: 16,
    marginBottom: 0,
    fontWeight: 'bold',
    color: '#343434 !important',
  },
  roundButton: {
    ...buttonStyles.roundButton,
  },
  mobileAddButton: {
    ...buttonStyles.roundButton,
    fontSize: 30,
  },
  mobileRowAddButton: {
    marginRight: 3,
    marginTop: -15,
  },
  greyText: {
    ...headings.text,
    fontSize: 14,
    paddingLeft: 16,
    marginBottom: 0,
    opacity: 0.4,
    fontWeight: 'bold',
  },
  checkboxCheck: {
    backgroundColor: `${colors.primary} !important`,
  },
  checkboxUncheck: {
    backgroundColor: '#e8ecef !important',
  },
  checkboxIcon: {
    color: '#ffffff !important',
  },
  datePicker: {
    display: 'flex',
    '& label': {
      textAlign: 'right',
      paddingTop: 2,
    },
    '& .react-datepicker-wrapper': {
      width: 230,
    },
  },
  datePickerRed: {
    '& .react-datepicker-wrapper': {
      '& input': {
        color: `${colors.red}!important`,
      },
    },
  },
})

const ActionItemRow = ({ action, handleError }) => {
  const classes = useStyles()
  const [completed, setCompleted] = useState(true)
  const [dueDate, setDueDate] = useState('')

  useEffect(() => {
    setCompleted(action.completed)
  }, [action])

  useEffect(() => {}, [completed])

  const {
    submitted: updated,
    submit: updateConversationItem,
    submitting: updateLoading,
    err: updateErr,
  } = useApiSubmit('updateConversationItem')

  // Updated
  useEffect(() => {
    if (updated && !updateErr && !updateLoading) {
      //don't reload or the item would disappeared. but show clicked or updated
      setCompleted(s => !s)
    } else if (updateErr && !updateLoading) {
      handleError(updateErr, true, {
        message: 'There was a problem updating the item.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated, updateErr, updateLoading])

  const save = useCallback(
    actionItem => {
      updateConversationItem({
        params: {
          conversationId: actionItem.conversationId,
          itemId: actionItem.itemId,
        },
        body: {
          completed: !completed,
          updatedBy: actionItem.createdBy,
          userId: actionItem.createdBy,
          type: 'action',
          text: actionItem.text,
        },
      })
    },
    [updateConversationItem, completed],
  )

  useEffect(() => {
    if (dueDate !== '') {
      updateConversationItem({
        params: {
          conversationId: action.conversationId,
          itemId: action.itemId,
        },
        body: {
          completed: action.completed,
          updatedBy: action.createdBy,
          userId: action.createdBy,
          type: 'action',
          text: action.text,
          dueDate: dueDate,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dueDate])

  return (
    <Row className="">
      <Col xs={6}>
        <div className="d-flex align-items-center">
          <CustomCheckbox
            checked={completed}
            backgroundColor={
              completed ? classes.checkboxCheck : classes.checkboxUncheck
            }
            iconColor={classes.checkboxIcon}
            onClick={() => {
              save(action)
            }}
          />
          <CustomTextArea
            value={action.text}
            editMode={false}
            className={`ml-2 ${completed ? classes.greyText : classes.text}`}
          />
        </div>
      </Col>
      <Col xs={6} className={`d-flex justify-content-end`}>
        <CustomDate
          title={'DUE: '}
          name="endDate"
          placeholder="End Date"
          value={dueDate}
          valueToDisplay={
            dueDate !== ''
              ? dueDate
              : moment(action.dueDate).format('MM-DD-YYYY')
          }
          onChange={date => {
            setDueDate(date)
          }}
          disabled={completed}
          containerStyle={`${classes.datePicker} ${
            dueDate === '' ? classes.datePickerRed : ''
          }`}
        />
      </Col>
    </Row>
  )
}

const ActionItems = ({ items, handleError }) => {
  return items?.map(action => {
    return <ActionItemRow action={action} handleError={handleError} />
  })
}

export default compose(withRouter, withErrorHandler)(ActionItems)
