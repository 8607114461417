import React from 'react'
import { createUseStyles } from 'react-jss'
import colors, { headings } from '../../../theme/theme2'
import Icon from '../../Icons'
import { Button } from 'react-bootstrap'
import Loader from '../../../elemets/Loader'

const useStyles = createUseStyles({
  button: {
    height: '100%',
    width: 110,
    borderRadius: 0,
    padding: 3,
    minHeight: 36,
  },
  last: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  redBg: {
    backgroundColor: '#d8013b',
    border: '0!important',
    '&:hover': {
      backgroundColor: '#d8013b!important',
      color: 'white',
      border: '0!important',
    },
    '&:active': {
      backgroundColor: '#d8013b!important',
      color: 'white',
      border: '0!important',
    },
    '&:disabled, &:disabled:hover': {
      backgroundColor: '#d8013b!important',
      color: 'white',
      border: '0!important',
    },
  },
  yellowBg: {
    backgroundColor: '#f5a623',
    border: '0!important',
    '&:hover': {
      backgroundColor: '#f5a623!important',
      color: 'white',
      border: '0!important',
    },
    '&:active': {
      backgroundColor: '#f5a623!important',
      color: 'white',
      border: '0!important',
    },
    '&:disabled, &:disabled:hover': {
      backgroundColor: '#f5a623!important',
      color: 'white',
      border: '0!important',
    },
  },
  greyBg: {
    backgroundColor: '#707575',
    border: '0!important',
    '&:hover': {
      backgroundColor: '#707575!important',
      color: 'white',
      border: '0!important',
    },
    '&:active': {
      backgroundColor: '#707575!important',
      color: 'white',
      border: '0!important',
    },
    '&:disabled, &:disabled:hover': {
      backgroundColor: '#707575!important',
      color: 'white',
      border: '0!important',
    },
  },
  blueBg: {
    backgroundColor: '#4ea7ec',
    border: '0!important',
    '&:hover': {
      backgroundColor: '#4ea7ec!important',
      color: 'white',
      border: '0!important',
    },
    '&:active': {
      backgroundColor: '#4ea7ec!important',
      color: 'white',
      border: '0!important',
    },
    '&:disabled, &:disabled:hover': {
      backgroundColor: '#4ea7ec!important',
      color: 'white',
      border: '0!important',
    },
  },
  text: {
    ...headings.text,
    fontSize: 12,
    color: '#f3f3f2',
    fontWeight: 'bold',
  },
  icon: {
    borderRadius: 4,
    width: 40,
    height: 40,
    backgroundColor: colors.primary,
    color: colors.whiteBackground,
    paddingTop: 9,
    '& i': {
      fontSize: 20,
    },
    position: 'absolute',
    top: 10,
    left: 10,
  },
  grid: {
    display: 'grid',
  },
})

const HamburgerButton = ({
  title,
  icon,
  color,
  disabled = false,
  onClick,
  loading = false,
  radius,
}) => {
  const classes = useStyles()

  const getBG = bg => {
    switch (bg) {
      case 'red':
        return classes.redBg
      case 'yellow':
        return classes.yellowBg
      case 'blue':
        return classes.blueBg
      default:
        return classes.greyBg
    }
  }

  return (
    <Button
      className={`${classes.button} ${getBG(color)} ${radius}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={classes.grid}>
        <Icon icon={icon} />
        <span
          className={classes.text}
          style={loading ? { color: 'transparent' } : {}}
        >
          {title}
        </span>
        {loading && (
          <div className={classes.loader}>
            <Loader color={classes.loaderColor} />
          </div>
        )}
      </div>
    </Button>
  )
}

export default HamburgerButton
