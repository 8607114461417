import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '../auth0'

export function LogoutRoute(): null {
  const { logout } = useAuth0()
  const history = useHistory()

  useEffect(() => {
    logout({ returnTo: window.location.origin })
    history.push('/')
  }, [logout, history])

  return null
}
