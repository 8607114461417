import React from 'react'
import DateControl, { DateValue } from '../../controls/DateControl'
import Field from '../Field'

type DateFieldProps = {
  value?: DateValue
  onChange: (value: DateValue) => void
}

const toDateValue = (date: Date): DateValue => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return { year, month, day }
}

const DateField = ({ value, onChange }: DateFieldProps): JSX.Element => (
  <Field label="Date" labelId="date" required>
    <DateControl
      labelId="date"
      value={value}
      onChange={onChange}
      max={toDateValue(new Date())}
    />
  </Field>
)

export default DateField

export type { DateValue }
