import React, { useCallback, useEffect, useState } from 'react'
import ConfirmationModal from '../../../Modals/ConfirmationModal'
import ActionButton from '../../../../elemets/ActionButton'
import { isMobile } from '../../../../utils/common'
import { createUseStyles } from 'react-jss'
import CustomInput from '../../../../elemets/CustomInput'
import { useApiSubmit } from '../../../../api'
import compose from 'just-compose'
import { withErrorHandler } from '../../../../errors'

const useStyles = createUseStyles({
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  inputContainerStyle: {
    marginBottom: 10,
    '& label': {
      color: '#797575',
    },
  },
})

const EditUserModal = ({ user, setDisplayModal, reload, handleError }) => {
  const classes = useStyles()
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
  })

  const {
    submitted,
    submit: updateProfile,
    err: updateErr,
    submitting,
  } = useApiSubmit('updateProfileV1')

  useEffect(() => {
    setFormState(user)
  }, [user])

  const handleInput = useCallback((e) => {
    const value = e.target.value
    const name = e.target.name
    setFormState((f) => ({
      ...f,
      [name]: value,
    }))
  }, [])

  useEffect(() => {
    if (submitted && !updateErr && !submitting) {
      setDisplayModal(false)
      reload()
    } else if (updateErr && !submitting) {
      handleError(updateErr, true, {
        message: 'There was a problem updating your profile.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, updateErr, submitting])

  const save = useCallback(() => {
    try {
      if (
        formState.firstName === '' ||
        formState.lastName === '' ||
        formState.email === ''
      ) {
        handleError(null, true, {
          message: 'First Name, Last Name, and Email are required.',
        })
        return
      }
      const allowed = [
        'bio',
        'birthDate',
        'conversationFrequencyId',
        'email',
        'firstName',
        'funFact',
        'highSchool',
        'homeTown',
        'lastName',
        'linkedIn',
        'location',
        'maxConversations',
        'notificationFrequencyId',
        'phoneNumber',
        'regionId',
        'showTour',
        'title',
      ]

      const filtered = Object.keys(formState)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = formState[key]
          return obj
        }, {})

      updateProfile({
        params: {
          profileId: user.profileId,
        },
        body: {
          ...filtered,
        },
      })
    } catch (err) {
      const { handleError } = this.props
      handleError(err, true, {
        message: 'There was a problem updating your profile.',
      })
    }
  }, [formState, updateProfile, user, handleError])

  return (
    <ConfirmationModal
      show={true}
      onClose={() => setDisplayModal(false)}
      closeIcon={true}
      title={'Edit User'}
      content={
        <div className={classes.content}>
          <CustomInput
            containerStyle={classes.inputContainerStyle}
            name="firstName"
            value={formState.firstName}
            onChange={handleInput}
            title="First Name"
          />
          <CustomInput
            containerStyle={classes.inputContainerStyle}
            name="lastName"
            value={formState.lastName}
            onChange={handleInput}
            title="Last Name"
          />
          <CustomInput
            containerStyle={classes.inputContainerStyle}
            name="email"
            value={formState.email}
            onChange={handleInput}
            title="Email"
          />
        </div>
      }
      footer={
        <div className="d-flex justify-content-end">
          <ActionButton
            text="UPDATE"
            className="w-40"
            onClick={save}
            type="STRONG_GREEN"
          />
        </div>
      }
      dialogClassName={isMobile() ? classes.dialogMobile : ''}
    />
  )
}

export default compose(withErrorHandler)(EditUserModal)
