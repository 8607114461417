import React, { useCallback, useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import compose from 'just-compose'

import { Button } from 'react-bootstrap'

import { withErrorHandler } from '../../../errors'

import Linkify from 'linkify-react'

// Elements
import ActionButton from '../../../elemets/ActionButton'
import CustomTextArea from '../../../elemets/CustomTextArea'

// Theme
import colors, { headings } from '../../../theme/theme2'

// API
import { useApiSubmit } from '../../../api/hooks'

// Utils
import { isMobile } from '../../../utils/common'
import ConfirmationModal from '../../Modals/ConfirmationModal'
import HamburgerContainer from './HamburgerContainer'

const useStyles = createUseStyles({
  text: {
    ...headings.text,
    fontSize: 14,
    paddingLeft: 16,
    marginBottom: 0,
    color: '#252631 !important',
  },
  greyText: {
    ...headings.text,
    fontSize: 14,
    paddingLeft: 16,
    marginBottom: 0,
    opacity: 0.4,
  },
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  noteContainer: {
    margin: 5,
    border: `1px solid ${colors.grey1}`,
    borderRadius: 4,
    display: 'inline-flex',
    position: 'relative',
  },
  noteBaseColor: {
    width: 4,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
  },
  colorPrivate: {
    backgroundColor: colors.grey1,
  },
  colorShared: {
    background:
      'repeating-linear-gradient(320deg,#ffffff, #ffffff 2px, #4ea7ec, 2px, #4ea7ec 5px)',
  },
  colorEdited: {
    backgroundColor: '#4ea7ec',
  },
  colorParticipant: {
    backgroundColor: colors.primary,
  },
  participantInitials: {
    color: colors.primary,
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  close: {
    background: 'transparent',
    color: colors.dark,
    border: 'none',
    '&:hover': {
      background: 'transparent',
      border: 'none',
      color: colors.dark,
    },
  },
  focused: {
    '&:focus': {
      outline: 'none',
    },
  },
  btnsWrapper: {
    display: 'flex',
    margin: '5px',
    justifyContent: 'end',
    width: '100%',
  },
  divBtnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
})

const NoteRow = ({
  note,
  reloadNotes,
  conversationId,
  currentProfile,
  participant,
  handleError,
  handleDeleteNote,
}) => {
  const classes = useStyles()
  const [showBtns, setShowBtns] = useState(false)
  const [editedNote, setEditedNote] = useState({
    text: note.text,
    shareable: note.shareable,
  })
  const [editMode, setEditMode] = useState(false)
  const [show, setShow] = useState(true)

  const {
    submitted: updated,
    submit: updateConversationNote,
    submitting: updateLoading,
    err: updateErr,
  } = useApiSubmit('updateConversationNote')

  // Updated
  useEffect(() => {
    if (updated && !updateErr && !updateLoading) {
      reloadNotes()
      setEditMode(false)
    } else if (updateErr && !updateLoading) {
      handleError(updateErr, true, {
        message: 'There was a problem updating the note.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated, updateErr, updateLoading])

  const handleSave = useCallback(
    (text) => {
      const body = {
        text: text || editedNote.text,
        shareable: editedNote.shareable,
      }
      updateConversationNote({
        params: { conversationId, noteId: note.noteId },
        body,
      })
    },
    [
      editedNote.text,
      editedNote.shareable,
      updateConversationNote,
      conversationId,
      note.noteId,
    ],
  )

  const getNoteColor = (note) => {
    //Own PreCall
    if (note.userId === currentProfile.id) {
      //Own PreCall Shared
      if (note.shareable) {
        return classes.colorShared
      } else {
        return classes.colorPrivate
      }
    }
    //Participant PreCall Shared
    if (note.shareable) return classes.colorParticipant
  }

  const saveNote = (text) => {
    handleSave(text)
    setShowBtns((value) => !value)
  }

  return (
    show && (
      <div className={classes.noteContainer}>
        <div
          className={`${classes.noteBaseColor} ${
            editMode ? classes.colorEdited : getNoteColor(note)
          }`}
        />
        {note.userId === currentProfile.id ? (
          <>
            <div className={classes.divBtnWrapper}>
              <Linkify options={{ target: '_blank' }}>
                <div
                  className={`${classes.text} p-2 pl-3 pb-3 ${classes.focused}`}
                  style={{ width: '95%' }}
                  contentEditable={showBtns}
                  suppressContentEditableWarning={true}
                  onInput={(e) => {
                    setEditedNote({
                      ...editedNote,
                      text: e.target.innerText,
                    })
                  }}
                  onDoubleClick={() => setShowBtns(true)}
                >
                  {note.text}
                </div>
                {showBtns && (
                  <div className={classes.btnsWrapper}>
                    <ActionButton
                      style={{ marginRight: '5px' }}
                      text={'Cancel'}
                      onClick={() => saveNote(note.text)}
                    />

                    <ActionButton
                      type={'STRONG_GREEN'}
                      text={'Save'}
                      onClick={() => saveNote(editedNote.text)}
                    />
                  </div>
                )}
              </Linkify>
            </div>

            <HamburgerContainer
              buttons={[
                {
                  title: 'Delete',
                  onClick: () => handleDeleteNote(note.noteId),
                  icon: 'fas fa-times',
                  color: 'red',
                },
                note.shareable
                  ? {
                      title: 'Cancel Share',
                      onClick: () => {
                        updateConversationNote({
                          params: { conversationId, noteId: note.noteId },
                          body: { text: note.text, shareable: false },
                        })
                      },
                      icon: 'fas fa-sync-alt',
                      color: 'gray',
                    }
                  : {
                      title: 'Share',
                      onClick: () => {
                        updateConversationNote({
                          params: { conversationId, noteId: note.noteId },
                          body: { text: note.text, shareable: true },
                        })
                      },
                      icon: 'fas fa-sync-alt',
                      color: 'blue',
                    },
              ]}
            />
          </>
        ) : (
          <Linkify options={{ target: '_blank' }}>
            <div
              className={`${classes.text} p-2 pl-3 pb-3`}
              style={{ width: '95%' }}
            >
              <span className={classes.participantInitials}>
                {participant.firstName[0] + participant.lastName[0] + ' '}
              </span>
              - {note.text}
            </div>
            <HamburgerContainer
              buttons={[
                {
                  title: 'Hide',
                  onClick: () => setShow(false),
                  icon: 'fas fa-eye-slash',
                  color: 'grey',
                },
              ]}
            />
          </Linkify>
        )}
      </div>
    )
  )
}

const NoteInput = ({
  currentNote,
  setCurrentNote,
  conversationId,
  reloadNotes,
  handleError,
}) => {
  const classes = useStyles()
  const [note, setNote] = useState(currentNote)
  const {
    submitted,
    submit: addConversationNote,
    submitting,
    err,
  } = useApiSubmit('addConversationNote')

  const handleSave = useCallback(() => {
    addConversationNote({
      params: { conversationId },
      body: {
        text: note,
        shareable: false,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note])

  // Added
  useEffect(() => {
    if (submitted && !err && !submitting) {
      reloadNotes()
      setCurrentNote('')
      setNote('')
    } else if (err && !submitting) {
      handleError(err, true, {
        message: 'There was a problem adding the note.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, err, submitting])

  return (
    <Linkify options={{ target: '_blank' }}>
      <div className={`m-1`}>
        <CustomTextArea
          value={note}
          onChange={(event) => {
            setNote(event.target.value)
            setCurrentNote(event.target.value)
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter' || event.key === 'NumpadEnter') {
              handleSave()
            }
          }}
          placeholder={'Create a note...'}
          maxLength={2500}
          className={classes.text}
          editMode={true}
        />
      </div>
    </Linkify>
  )
}

const NotesCard = ({
  conversationId,
  handleError,
  currentProfile,
  participant,
  notes,
  reloadNotes,
  currentNote,
  setCurrentNote,
}) => {
  const classes = useStyles()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [noteSelected, setNoteSelected] = useState(0)

  const {
    submitted: deleted,
    submit: deleteConversationNote,
    submitting: deleteLoading,
    err: deleteErr,
  } = useApiSubmit('deleteConversationNote')

  // Deleted
  useEffect(() => {
    if (deleted && !deleteErr && !deleteLoading) {
      reloadNotes()
      setNoteSelected(0)
      setShowDeleteModal(false)
    } else if (deleteErr && !deleteLoading) {
      handleError(deleteErr, true, {
        message: 'There was a problem deleting the note.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted, deleteErr, deleteLoading])

  const handleDeleteNote = useCallback((itemToDelete) => {
    setShowDeleteModal(true)
    setNoteSelected(itemToDelete)
  }, [])

  const deleteNote = useCallback(() => {
    deleteConversationNote({ params: { conversationId, noteId: noteSelected } })
  }, [noteSelected, conversationId, deleteConversationNote])

  const compare = (a, b) => {
    if (a.noteId < b.noteId) {
      return 1
    }
    if (a.noteId > b.noteId) {
      return -1
    }
    return 0
  }

  return (
    <React.Fragment>
      <ConfirmationModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={
          <>
            <div>Delete Note?</div>{' '}
            <div className={classes.closeButton}>
              <Button
                className={classes.close}
                onClick={() => setShowDeleteModal(false)}
              >
                <i className="far fa-times-circle" />
              </Button>
            </div>
          </>
        }
        content={'Deleting a comment is forever. There is no undo.'}
        footer={
          <div className="d-flex justify-content-end">
            <ActionButton
              text="DELETE NOTE"
              type="STRONG_RED"
              onClick={deleteNote}
            />
          </div>
        }
        dialogClassName={isMobile() ? classes.dialogMobile : ''}
      />
      <NoteInput
        conversationId={conversationId}
        reloadNotes={reloadNotes}
        handleError={handleError}
        currentNote={currentNote}
        setCurrentNote={setCurrentNote}
      />
      {notes &&
        notes
          .sort(compare)
          .map((note) => (
            <NoteRow
              key={note.noteId}
              note={note}
              conversationId={conversationId}
              currentProfile={currentProfile}
              participant={participant}
              reloadNotes={reloadNotes}
              handleDeleteNote={handleDeleteNote}
              handleError={handleError}
            />
          ))}
    </React.Fragment>
  )
}

export default compose(withErrorHandler)(NotesCard)
