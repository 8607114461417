import React from 'react'
import colors from '../../theme/theme2'
import { createUseStyles } from 'react-jss'
import { isMobile } from '../../utils/common'

const useStyles = createUseStyles({
  banner: {
    maxHeight: 330,
    width: '100%',
    height: '100%',
    borderRadius: 4,
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    display: 'flex',
  },
  logoContainer: {
    display: 'flex',
    maxWidth: 270,
    maxHeight: 270,
    width: 270,
    height: 270,
    margin: 30,
    boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: colors.grey1,
  },
  logo: {
    width: '100%',
  },
  bannerMobile: {
    width: '70%',
    backgroundSize: 'cover',
  },
  logoMobile: {
    maxWidth: 109,
    maxHeight: 109,
    height: 109,
  },
})

const GroupBanner = ({ group }) => {
  const classes = useStyles()
  if (!group) return null

  const bgBanner = group?.image
    ? {
        background: `url(${group?.image}) round`,
      }
    : {
        background: `url('https://assets.golattitude.com/Lattitude_Logo_FullColor_Icon.png') left`,
      }
  const defaultLogoSrc =
    'https://assets.golattitude.com/Lattitude_Logo_FullColor_Icon.png'
  const logoSrc = group?.logo || defaultLogoSrc
  return !isMobile() ? (
    <div className={`${classes.banner} mb-4`} style={bgBanner}>
      <div className={classes.logoContainer}>
        <img
          className={classes.logo}
          src={logoSrc}
          alt="Group Logo"
          onError={(e) => {
            e.target.src = defaultLogoSrc
          }}
        />
      </div>
    </div>
  ) : (
    <div className={'d-flex'}>
      <div>
        <img
          className={classes.logoMobile}
          src={logoSrc}
          alt="Group Logo"
          onError={(e) => {
            e.target.src = defaultLogoSrc
          }}
        />
      </div>
      <div className={`${classes.bannerMobile}`} style={bgBanner} />
    </div>
  )
}

export default GroupBanner
