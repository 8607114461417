import React from 'react'
import { useCurrentUser } from '../components/Context/CurrentUser'
import { GroupsProvider } from './GroupsContext'

interface CurrentUserGroupsProviderProps {
  activeGroupId?: number
  children: React.ReactNode
}

export const CurrentUserGroupsProvider = ({
  activeGroupId,
  children,
}: CurrentUserGroupsProviderProps): JSX.Element | null => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { currentUser } = useCurrentUser() as any
  const { id: profileId } = currentUser || {}
  return (
    <GroupsProvider profileId={profileId} activeGroupId={activeGroupId}>
      {children}
    </GroupsProvider>
  )
}

export { Context, useGroups } from './GroupsContext'
