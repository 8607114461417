import React, { useEffect, useState, useCallback } from 'react'
import { withErrorHandler } from '../../errors'
import { Container } from 'react-bootstrap'
import qs from 'query-string'
import { createUseStyles } from 'react-jss'
import { withRouter } from 'react-router-dom'
import compose from 'just-compose'
import jwt_decode from 'jwt-decode'

// Elements
import NewConversationCard from '../Conversations/NewConversationCard'
import CardContainer from '../../elemets/CardContainer'
import MatchRow from './MatchRow'
import SelectAvailability from './SelectAvailability'
import LoaderPage from '../../elemets/LoaderPage'

// Theme
import { headings } from '../../theme/theme2'

// Utils
import { isMobile } from '../../utils/common'

// API
import { useApiFetch } from '../../api/index'

const useStyles = createUseStyles({
  mobile: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: -50,
  },
  title: {
    ...headings.title,
  },
  card: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    marginBottom: 8,
  },
})
const MatchesPage = React.memo(({ location, history }) => {
  const classes = useStyles()
  const [displayAvailability, setDisplayAvailability] = useState(false)
  const [params, setParams] = useState({
    skillId: null,
    groupId: null,
  })
  const [selectedOption, setSelectedOption] = useState({
    user: null,
  })

  //API CALLS
  const { data: userProfile, loading: loadingCurrentProfile } =
    useApiFetch('getCurrentProfile')
  const {
    data: matches,
    loading: loadingMatches,
    fetch,
  } = useApiFetch('listMatchesV1', {}, false)

  const {
    data: matchAvailability,
    loading: loadingMatchAvailability,
    err: errorMatchAvailability,
    fetch: listMatchAvailability,
  } = useApiFetch('listMatchAvailability', {}, false)

  const handleFind = useCallback(
    (params) => {
      fetch({ query: params })
    },
    [fetch],
  )

  useEffect(() => {
    const search = qs.parse(location.search)
    const skillId = search.skillId
    const groupId = search.groupId
    const token = search.token

    if (token && location.state.params.user) {
      const decoded = jwt_decode(token)
      const decodedSkillId = decoded.mat.find((i) => i[0] === 'skillId')[1]
      //const decodedGroupId = decoded.mat.find(i => i[0] === 'groupId')[1]

      setParams({
        skillId: decodedSkillId,
        //groupId: decodedGroupId,
      })
      setSelectedOption({ user: location.state.params.user })
      listMatchAvailability({ query: { token } })
    } else if (skillId && groupId) {
      setParams({
        skillId: skillId,
        groupId: groupId,
      })
      const query = {
        skillId,
        groupId,
      }
      handleFind(query)
    } else {
      history.push('/conversations')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      matchAvailability &&
      !errorMatchAvailability &&
      !loadingMatchAvailability
    ) {
      setDisplayAvailability(true)
    }
  }, [matchAvailability, errorMatchAvailability, loadingMatchAvailability])

  useEffect(() => {
    if (matches && !loadingMatches) {
      setDisplayAvailability(false)
    }
  }, [matches, loadingMatches])

  if (loadingCurrentProfile || loadingMatches || loadingMatchAvailability) {
    return <LoaderPage />
  }

  return (
    <Container className={isMobile() ? classes.mobile : ''}>
      <NewConversationCard
        user={userProfile}
        defaultValues={params}
        onFind={handleFind}
        buttonText="NEW SEARCH"
        setParams={setParams}
      />

      {displayAvailability ? (
        <SelectAvailability
          match={matchAvailability}
          user={selectedOption.user}
          skillId={params.skillId}
        />
      ) : (
        <>
          <CardContainer className={classes.card}>
            <span className={classes.title}>Available Matches</span>
          </CardContainer>
          {matches &&
            matches.map((match) => (
              <MatchRow
                key={match.profileId}
                user={match}
                setSelectedOption={setSelectedOption}
                fetchScheduled={listMatchAvailability}
              />
            ))}
        </>
      )}
    </Container>
  )
})

export default compose(withRouter, withErrorHandler)(MatchesPage)
