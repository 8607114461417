/* eslint-disable no-console */
import React, { useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { withRouter } from 'react-router'
import Icon from '../Icons'
import { Form, InputGroup, Button, Container } from 'react-bootstrap'
import UserListModal from './userListModal'
import { useContacts } from '../Context/ContactsProvider'
import { useChatConversationProvider } from '../Context/ChatConversationProvider'
import { Avatar, Badge, styled } from '@material-ui/core'
import moment from 'moment'

const useStyles = createUseStyles({
  wrapper: {
    display: 'grid',
    grid: '85vh / 18rem 1fr',
    gap: '1rem',
  },
  // LEFT SIDE
  wrapperUserList: {
    display: 'grid',
    grid: '3rem 3rem 1fr / 1fr',
    gap: '0.5rem',

    width: '100%',
    height: '100%',
    boxShadow: '0 6px 16px 0 rgb(153 155 168 / 30%)',
    padding: '1rem 0.5rem',
  },
  // LEFT SIDE

  // RIGHT SIDE
  wrapperChat: {
    display: 'grid',
    grid: '3rem 1fr 5rem / 1fr',
    width: '100%',
    height: '100%',
    boxShadow: '0 6px 16px 0 rgb(153 155 168 / 30%)',
    padding: '1rem',
  },
  // RIGHT SIDE

  buttonChat: {
    background: '#ededed',
    height: '3rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonSearch: {
    background: '#ededed',
  },
  buttonGreen: {
    background: 'green',
  },

  usersListModal: {
    height: '50vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '1px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#ededed',
      borderRadius: '1px',
      border: `1px solid #ededed`,
    },
  },
  chatListUsers: {
    background: '#ededed',
    display: 'grid',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '1px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#ededed',
      borderRadius: '1px',
      border: `1px solid #ededed`,
    },
    grid: 'repeat(auto-fill, 3.5rem) / auto',
  },
  chatListUsersCard: {
    background: '#ededed',
    display: 'grid',
    grid: '3.5rem /25% 1fr 10% 15%',
    alignItems: 'center',
    justifyItems: 'center',
  },
  currentChatUsersCard: {
    background: '#ededed',
    display: 'grid',
    grid: '3.5rem / 90% 10%',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'space-between',
  },
  chatListUsersCardImg: {
    border: '2px solid grey',
    borderRadius: '50%',
    width: '2.5rem',
    height: '2.5rem',
  },
  chatListUsersCardName: {
    justifySelf: 'start',
    padding: '0 0.5rem',
  },
  chatListUsersCardIcon: {
    color: '#9b9b9b',
    transform: 'rotate(90deg)',
    cursor: 'pointer',
  },

  inputIcon: {
    color: '#191919',
    position: 'absolute',
    width: '20px',
    height: '20px',
    left: '12px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  inputWrapper: {
    position: 'relative',
    width: '271px',
    background: '#ededed',
  },
  input: {
    boxSizing: 'border-box',
    color: '#191919',
    padding: '10px 35px',
    width: '100%',
  },
})

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px`,
    position: 'absolute',
    bottom: 1,
    right: 1,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
}))

function DMMessages() {
  const classes = useStyles()
  const { contacts, setSearchContacts, filteredContacts } = useContacts()
  const {
    chatConversation,
    createChatRoom,
    currentChatConversation,
    joinRoom,
    sendMessage,
    setNotification,
    setChatConversation,
    setSearchChatConversationList,
    filterdChatConversation,
    readMessages,
  } = useChatConversationProvider()

  const [statusUserModal, setStatusUserModal] = useState(false)

  const [text, setText] = useState('')
  const handleSubmit = (e) => {
    e.preventDefault()
    sendMessage(text)
    setText('')
  }

  const onClickUser = useCallback(
    (users, callback, chatConversations = []) => {
      createChatRoom(users, callback, chatConversations)
    },
    [createChatRoom],
  )

  const groupMsjByDate = (messages = []) => {
    const orderedMessagesByDate = messages.sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt)
    })
    const groupMessages = orderedMessagesByDate.reduce((acc, message) => {
      const isToday = moment(message.createdAt).isSame(moment(), 'day')
      let key
      if (isToday) key = 'Today'
      else key = moment(message.createdAt).fromNow()

      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(message)
      return acc
    }, {})
    return Object.entries(groupMessages)
  }

  const filterConversationList = useCallback(
    (e) => {
      setSearchChatConversationList(e.target.value)
    },
    [setSearchChatConversationList],
  )

  return (
    <Container>
      {/* MODAL USER LIST */}
      <UserListModal
        onClose={setStatusUserModal}
        users={filteredContacts || contacts}
        onClickUser={onClickUser}
        statusUserModal={statusUserModal}
        setSearch={setSearchContacts}
      />
      {/* MODAL USER LIST */}

      <div className={classes.wrapper}>
        <div className={classes.wrapperUserList}>
          {/* LABEL CHATS && BTN SEARCH USER LIST*/}
          <div className={classes.buttonChat}>
            <div>Chats</div>
            <div
              style={{
                width: '2.5rem',
                height: '2.5rem',
                borderRadius: '50%',
                background: '#318540',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0.5rem',
              }}
              onClick={() => setStatusUserModal((value) => !value)}
            >
              <Icon icon="far fa-edit" style={{ color: 'white' }} />
            </div>
          </div>
          {/* LABEL CHATS && BTN SEARCH USER LIST*/}

          {/* INPUT SEARCH */}
          <div className={classes.inputWrapper}>
            <input
              type="search"
              className={classes.input}
              placeholder="Search"
              onChange={filterConversationList}
            />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={classes.inputIcon}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          {/* INPUT SEARCH */}

          {/* LIST OF CHATS ROOM CONVERSACTIONS */}
          <div className={classes.chatListUsers}>
            {filterdChatConversation?.map((chatRoom) => (
              <div
                key={`chatroom-${chatRoom.room}`}
                style={{
                  background: `${
                    currentChatConversation === chatRoom ? '#dad6d6' : ''
                  }`,
                }}
                className={classes.chatListUsersCard}
                onClick={() => {
                  // TODO: fix. setChatConversation is not working as expected
                  setChatConversation((prev) => {
                    const room = prev.findIndex((r) => {
                      return r.room === chatRoom.room
                    })
                    if (prev) prev[room].messagesUnread = 0
                    return prev
                  })
                  setNotification(0)
                  return readMessages(chatRoom, () =>
                    joinRoom(chatConversation, chatRoom),
                  )
                }}
              >
                <StyledBadge overlap="circular" variant="dot">
                  <Avatar alt="avatar" src={chatRoom?.avatar} />
                </StyledBadge>
                <span className={classes.chatListUsersCardName}>
                  {chatRoom?.name} {chatRoom?.lasName}
                </span>

                {/* NOTIFY CIRCLE */}
                {chatRoom?.messagesUnread > 0 && (
                  <span
                    style={{
                      background: 'green',
                      borderRadius: '50%',
                      width: '1rem',
                      height: '1rem',
                      color: 'white',
                      textAlign: 'center',
                      fontSize: 'smaller',
                    }}
                  >
                    {chatRoom.messagesUnread}
                  </span>
                )}
                {/* NOTIFY CIRCLE */}

                <Icon
                  classstyle={classes.chatListUsersCardIcon}
                  icon="fas fa-ellipsis-v"
                />
              </div>
            ))}
          </div>
          {/* LIST OF CHATS ROOM CONVERSACTIONS */}
        </div>

        {/* CHAT CONVERSATION */}
        <div className={classes.wrapperChat}>
          {currentChatConversation ? (
            <>
              <div className={classes.currentChatUsersCard}>
                {/* <Avatar alt="avatar" src={currentChatConversation?.avatar} /> */}
                <span className={classes.chatListUsersCardName}>
                  {currentChatConversation?.name}{' '}
                  {currentChatConversation?.lasName}
                </span>
                <Icon
                  classstyle={classes.chatListUsersCardIcon}
                  icon="fas fa-ellipsis-v"
                />
              </div>
              <div className="flex-grow-1 overflow-auto">
                <div className="d-flex flex-column align-items-start justify-content-end px-3">
                  {groupMsjByDate(currentChatConversation?.messages).map(
                    ([date, messages]) => (
                      <div key={date} style={{ width: '100%' }}>
                        <div className="text-muted small pb-2 text-center">
                          {date}
                        </div>
                        {messages.map((message, index) => (
                          <div
                            key={index}
                            className={`my-1 d-flex flex-column ${
                              message.fromMe
                                ? 'align-self-end align-items-end'
                                : 'align-items-start'
                            }`}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                              className={`rounded px-2 py-1 ${
                                message.fromMe
                                  ? `${classes.buttonGreen} text-white`
                                  : `${classes.buttonSearch}`
                              }`}
                            >
                              {message.text}
                              <br />
                              <span
                                style={{
                                  fontSize: '0.5rem',
                                  alignSelf: 'flex-end',
                                }}
                              >
                                {moment(message.createdAt).format('h:mm a')}
                              </span>
                            </div>
                            <div
                              className={`text-muted small ${
                                message.fromMe ? 'text-right' : ''
                              }`}
                            >
                              {message.fromMe
                                ? 'You'
                                : `${currentChatConversation.name} ${currentChatConversation.lasName}`}
                            </div>
                          </div>
                        ))}
                      </div>
                    ),
                  )}
                </div>
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="m-2">
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      required
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      style={{ height: '75px', resize: 'none' }}
                    />
                    <InputGroup.Append>
                      <Button type="submit" className={classes.buttonGreen}>
                        Send
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Form>
            </>
          ) : (
            <h1>Chat is not selected</h1>
          )}
        </div>
        {/* CHAT CONVERSATION */}
      </div>
    </Container>
  )
}

export default withRouter(DMMessages)
