import React, { useState } from 'react'
import CardContainer from '../../elemets/CardContainer'
import CardTitle from '../../elemets/CardTitle'
import { Button, Col, Collapse, Row } from 'react-bootstrap'
import CustomInput from '../../elemets/CustomInput'
import moment from 'moment'
import { createUseStyles } from 'react-jss'
import colors from '../../theme/theme2'

const useStyles = createUseStyles({
  educationRow: {
    marginBottom: 22,
  },
  expandButton: {
    fontFamily: 'Lato',
    fontSize: 12,
    textAlign: 'right',
    color: colors.primary,
  },
  arrows: {
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },
})

const EducationRow = ({ education }) => {
  const classes = useStyles()
  const educationDisplayValue = React.useMemo(() => {
    const startYear = education.startDate
      ? moment(education.startDate).utc().format('MM-YYYY')
      : ''
    const endYear = education.endDate
      ? moment(education.endDate).utc().format('MM-YYYY')
      : 'Present'
    return startYear ? `${startYear} to ${endYear}` : 'Not Provided'
  }, [education.startDate, education.endDate])

  return (
    <Row className={classes.educationRow}>
      <Col className="pr-0">
        <CustomInput
          inputStyle="pr-0"
          value={education.schoolName}
          title="School"
          editMode={false}
        />
      </Col>
      <Col className="pr-0">
        <CustomInput
          inputStyle="pr-0"
          value={education.major}
          title="Vocation"
          editMode={false}
        />
      </Col>
      <Col className="pr-0">
        <CustomInput
          inputStyle="pr-0"
          value={educationDisplayValue}
          title="Years"
          editMode={false}
        />
      </Col>
    </Row>
  )
}

const EducationInfo = ({ educations }) => {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)

  return (
    educations.length > 0 && (
      <CardContainer
        header={
          <div className={`d-flex justify-content-between`}>
            <CardTitle title={'Education'} />
            {educations.length > 2 && (
              <Button
                className={`${classes.arrows}`}
                onClick={() => {
                  setExpand(!expand)
                }}
              >
                <span className={classes.expandButton}>
                  {expand ? 'Show Less' : 'Show More'}
                  <i
                    className={`ml-1 fas ${
                      expand ? 'fa-chevron-up' : 'fa-chevron-down'
                    }`}
                  />
                </span>
              </Button>
            )}
          </div>
        }
        headerDivider={true}
      >
        {educations.slice(0, 2).map((education, index) => {
          return <EducationRow key={index} education={education} />
        })}
        <Collapse in={expand}>
          <div>
            {educations.slice(2, educations.length).map((education, index) => {
              return <EducationRow key={index} education={education} />
            })}
          </div>
        </Collapse>
      </CardContainer>
    )
  )
}

export default EducationInfo
