import React, { useCallback, useState } from 'react'
import ConversationForm from './form/ConversationForm'
import { Conversation } from './Conversation'
import createConversation from './createConversationV1'
import { useCurrentUser } from '../../components/Context/CurrentUser'
import { useGroups } from '../../groups/GroupsContext'
import { useErrorHandler } from '../../errors'
import { PartialConversation } from './form/builder/state'

type CreateConversationProps = {
  onCancel: () => void
  onCreate: (conversationDetails: Conversation) => void
  data?: PartialConversation
  groupId?: number
}

const CreateConversation = ({
  onCancel,
  onCreate,
  data,
  groupId,
}: CreateConversationProps): JSX.Element | null => {
  const [saving, setSaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  )
  const { currentUser: { id: userId } = {} } = useCurrentUser() as {
    currentUser?: { id?: number }
  }
  const handleError = useErrorHandler()
  const disabled = saving || !userId || !groupId

  const saveConversationDetails = useCallback(
    (conversationDetails: Conversation) => {
      if (!userId || !groupId) {
        return
      }

      const controller = new AbortController()

      setSaving(true)
      setErrorMessage(undefined)
      createConversation(
        userId,
        groupId,
        conversationDetails,
        controller.signal,
      )
        .then(() => {
          if (controller.signal.aborted) {
            return
          }

          onCreate(conversationDetails)
        })
        .catch((err: Error) => {
          if (controller.signal.aborted || err.name === 'AbortError') {
            return
          }

          handleError(err, false)
          setErrorMessage(
            'There was a problem saving the conversation. Please try again.',
          )
        })
        .then(() => {
          if (controller.signal.aborted) {
            return
          }

          setSaving(false)
        })

      return () => {
        controller.abort()
      }
    },
    [onCreate, handleError, userId, groupId],
  )

  const { active: activeGroup } = useGroups()
  const activeGroupId = groupId || activeGroup?.id

  const cancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  if (!activeGroupId) {
    // group ID is required, because we determine which users are selectable based on group.
    return null
  }

  return (
    <ConversationForm
      onCancel={cancel}
      onSubmit={saveConversationDetails}
      errorMessage={errorMessage}
      disabled={disabled}
      conversationDetails={data}
      groupId={activeGroupId}
    />
  )
}

export default CreateConversation
