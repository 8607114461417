import React, { useCallback } from 'react'
import { Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import compose from 'just-compose'
import { withRouter } from 'react-router-dom'

// Elements
import CardContainer from '../../elemets/CardContainer'
import CardTitle from '../../elemets/CardTitle'

// Theme
import { headings } from '../../theme/theme2'
import ActionButton from '../../elemets/ActionButton'

const useStyles = createUseStyles({
  text: {
    ...headings.text,
    paddingLeft: 16,
    marginBottom: 0,
  },
  row: {
    marginBottom: 26,
  },
  viewButton: {
    width: '50px !important',
  },
})

const MyGroupsCard = ({ user, history }) => {
  const classes = useStyles()

  const handleGoTo = useCallback(
    (groupId) => {
      history.push(`/group/${groupId}/dashboard`)
    },
    [history],
  )

  const GroupRow = useCallback(
    ({ group, isLast, dataTestId }) => {
      return (
        <Row
          className={`align-items-center ${isLast ? '' : classes.row}`}
          data-testid={dataTestId}
        >
          <Col>
            <label className={classes.text}>{group.name}</label>
          </Col>
          <Col xs={4} className="text-right">
            <ActionButton
              className={classes.viewButton}
              text="VIEW"
              onClick={() => handleGoTo(group.id)}
            />
          </Col>
        </Row>
      )
    },
    [classes, handleGoTo],
  )

  return (
    <>
      <CardContainer
        header={<CardTitle title="My Groups" />}
        headerDivider={user.groups && user.groups.length > 0}
        dataTestId={'my-profile__my-groups-card'}
      >
        {user.groups &&
          user.groups.map((group, index) => (
            <GroupRow
              key={group.id}
              group={group}
              isLast={user.groups.length - 1 === index}
              dataTestId={'my-groups-card__row'}
            />
          ))}
      </CardContainer>
    </>
  )
}

export default compose(withRouter)(MyGroupsCard)
