import clsx from 'clsx'
import React from 'react'
import { createUseStyles } from 'react-jss'

type ErrorMessageProps = {
  message: string
  classes?: {
    root?: string
  }
}

const useStyles = createUseStyles({
  root: {
    margin: 0,
  },
})

const ErrorMessage = ({
  classes: { root } = {},
  message,
}: ErrorMessageProps): JSX.Element => {
  const classes = useStyles()
  return <p className={clsx(classes.root, root)}>{message}</p>
}

export default ErrorMessage
