import React, { createContext, useContext, ReactNode } from 'react'

import { makeGlobalLogger, logger } from '@/logger/logger'

interface LoggerContextType {
  debug: (message: any, ...optionalParams: any[]) => void
  info: (message: any, ...optionalParams: any[]) => void
  warn: (message: any, ...optionalParams: any[]) => void
  error: (message: any, ...optionalParams: any[]) => void
}

const LoggerContext = createContext<LoggerContextType>(
  logger as LoggerContextType,
)

interface LoggerProviderProps {
  children: ReactNode
}

export const LoggerProvider: React.FC<LoggerProviderProps> = ({ children }) => {
  const logger = makeGlobalLogger() // Initialize
  logger.debug('Logger initialized') // Log to
  const debug = (message: any, ...optionalParams: any[]) =>
    logger.debug(message, ...optionalParams)
  const info = (message: any, ...optionalParams: any[]) =>
    logger.info(message, ...optionalParams)
  const warn = (message: any, ...optionalParams: any[]) =>
    logger.warn(message, ...optionalParams)
  const error = (message: any, ...optionalParams: any[]) =>
    logger.error(message, ...optionalParams)

  const value = { debug, info, warn, error }

  return (
    <LoggerContext.Provider value={value}>{children}</LoggerContext.Provider>
  )
}

export const useLogger = (): LoggerContextType => useContext(LoggerContext)
