import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
  //  forwardRef,
} from 'react'
import { Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import compose from 'just-compose'

import { withErrorHandler } from '../../../errors'

// Elements
import CardContainer from '../../../elemets/CardContainer'
import CardTitle from '../../../elemets/CardTitle'
import CustomCheckbox from '../../../elemets/CustomCheckbox'
import Divider from '../../../elemets/Divider'

// Theme
import colors, { headings } from '../../../theme/theme2'
import CustomTextArea from '../../../elemets/CustomTextArea'

// API
import { useApiSubmit } from '../../../api'

// Utils
import { isMobile } from '../../../utils/common'
import HamburgerContainer from './HamburgerContainer'
// import moment from 'moment'
// import DatePicker from 'react-datepicker'
// import HamburgerButton from './HamburgerButton'

const useStyles = createUseStyles({
  text: {
    ...headings.text,
    fontSize: 14,
    paddingLeft: 16,
    marginBottom: 0,
    fontWeight: 'bold',
    color: '#343434 !important',
  },
  greyText: {
    ...headings.text,
    fontSize: 14,
    paddingLeft: 16,
    marginBottom: 0,
    opacity: 0.4,
    fontWeight: 'bold',
  },
  checkboxCheck: {
    backgroundColor: `${colors.primary} !important`,
  },
  checkboxUncheck: {
    backgroundColor: '#e8ecef !important',
  },
  checkboxInput: {
    marginTop: -22,
  },
  checkboxIcon: {
    color: '#ffffff !important',
  },
  withoutBold: {
    fontWeight: 500,
  },
  dueDate: {
    position: 'absolute',
    display: 'none',
    right: 40,
    top: 0,
  },
  divider: {
    backgroundColor: '#e8ecef',
  },
})

const ActionInput = ({
  currentActionItem,
  setCurrentActionItem,
  conversationId,
  currentProfile,
  handleError,
  reload,
}) => {
  const classes = useStyles()
  const [action, setAction] = useState(currentActionItem)

  const {
    submitted,
    submit: addConversationItem,
    submitting,
    err,
  } = useApiSubmit('addConversationItem')

  // Added
  useEffect(() => {
    if (submitted && !err && !submitting) {
      setAction({
        text: '',
        completed: false,
      })
      setCurrentActionItem({
        text: '',
        completed: false,
      })
      reload()
    } else if (err && !submitting) {
      handleError(err, true, {
        message: 'There was a problem adding the action.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, err, submitting])

  const handleSave = useCallback(() => {
    addConversationItem({
      params: { conversationId },
      body: {
        text: action.text,
        type: 'action',
        userId: currentProfile.id,
        completed: action.completed,
      },
    })
  }, [addConversationItem, conversationId, currentProfile, action])

  return (
    <>
      <Row className="mb-2">
        <Col>
          <div className="d-flex align-items-center">
            <CustomTextArea
              value={action.text}
              onChange={(e) => {
                setAction({ ...action, text: e.target.value })
                setCurrentActionItem({ ...action, text: e.target.value })
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter' || event.key === 'NumpadEnter') {
                  handleSave()
                }
              }}
              editMode={true}
              className={`ml-2 ${classes.text} ${classes.withoutBold}`}
              maxLength={200}
              placeholder={'Create an action item...'}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

const ActionRow = ({
  action,
  currentProfile,
  conversationId,
  divider,
  handleError,
  reload,
}) => {
  const classes = useStyles()
  const [editedAction, setEditedAction] = useState('')
  const [editMode, setEditMode] = useState(false)
  const dateRef = useRef(null)
  // const [closeFn, setCloseFn] = useState(() => {})
  // const [startDate, setStartDate] = useState(new Date())

  const {
    submitted: updated,
    submit: updateConversationItem,
    submitting: updateLoading,
    err: updateErr,
  } = useApiSubmit('updateConversationItem')

  const {
    submitted: deleted,
    submit: deleteConversationItem,
    submitting: deleteLoading,
    err: deleteErr,
  } = useApiSubmit('deleteConversationItem')

  useEffect(() => {
    setEditedAction(action.text)
  }, [action])

  // Deleted
  useEffect(() => {
    if (deleted && !deleteErr && !deleteLoading) {
      reload()
    } else if (deleteErr && !deleteLoading) {
      handleError(deleteErr, true, {
        message: 'There was a problem deleting the action.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted, deleteErr, deleteLoading])

  // Updated
  useEffect(() => {
    if (updated && !updateErr && !updateLoading) {
      setEditMode(false)
      reload()
    } else if (updateErr && !updateLoading) {
      handleError(updateErr, true, {
        message: 'There was a problem updating the action.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated, updateErr, updateLoading])

  const handleUpdate = useCallback(() => {
    const body = {
      text: editedAction,
      type: 'action',
      userId: currentProfile.id,
      completed: action.completed,
    }
    body.updatedBy = currentProfile.id
    updateConversationItem({
      params: { conversationId, itemId: action.itemId },
      body,
    })
  }, [
    editedAction,
    currentProfile.id,
    action.completed,
    action.itemId,
    updateConversationItem,
    conversationId,
  ])

  const handleDeleteAction = useCallback(
    (itemId) => {
      deleteConversationItem({ params: { conversationId, itemId } })
    },
    [conversationId, deleteConversationItem],
  )

  const checkboxBackground = useMemo(() => {
    return action.completed ? classes.checkboxCheck : classes.checkboxUncheck
  }, [action, classes])

  const handleChangeCheck = useCallback(() => {
    const body = {
      text: action.text,
      type: 'action',
      userId: currentProfile.id,
      completed: !action.completed,
      updatedBy: currentProfile.id,
    }
    updateConversationItem({
      params: { conversationId, itemId: action.itemId },
      body,
    })
  }, [
    action.completed,
    action.itemId,
    action.text,
    conversationId,
    currentProfile.id,
    updateConversationItem,
  ])

  // const handleChangeDate = useCallback(
  //   date => {
  //     if (
  //       action &&
  //       date &&
  //       ((action.dueDate && date !== action.dueDate) || !action.dueDate)
  //     ) {
  //       const body = {
  //         text: action.text,
  //         type: 'action',
  //         userId: currentProfile.id,
  //         completed: action.completed,
  //         updatedBy: currentProfile.id,
  //         dueDate: date,
  //       }
  //       updateConversationItem({
  //         params: { conversationId, itemId: action.itemId },
  //         body,
  //       })
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   },
  //   [action, conversationId, currentProfile.id, updateConversationItem],
  // )

  // useEffect(() => {
  //   if (action) {
  //     setStartDate(
  //       action.dueDate
  //         ? action.dueDate
  //         : moment()
  //             .add(15, 'days')
  //             .toDate(),
  //     )
  //   }
  // }, [action])

  // const DueDateButton = forwardRef(({ onClick }, ref) => (
  //   <HamburgerButton
  //     color={'yellow'}
  //     icon={'fas fa-calendar'}
  //     title={'Due Date'}
  //     onClick={onClick}
  //     ref={ref}
  //   />
  // ))

  // const CustomDatePicker = useMemo(() => {
  //   return (
  //     <DatePicker
  //       className={`${classes.dueDate} `}
  //       selected={startDate}
  //       onChange={date => {
  //         setStartDate(date)
  //         handleChangeDate(date)
  //         closeFn()
  //       }}
  //       dateFormat="MM-dd-yyyy"
  //       width={120}
  //       ref={dateRef}
  //       popperPlacement="top-end"
  //       customInput={<DueDateButton />}
  //     />
  //   )
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [startDate, closeFn])

  return (
    <>
      <Row className="">
        <Col>
          <div className="d-flex align-items-center">
            <CustomCheckbox
              checked={action.completed}
              backgroundColor={checkboxBackground}
              iconColor={classes.checkboxIcon}
              onClick={() => handleChangeCheck(action, editMode)}
            />
            <CustomTextArea
              value={editMode ? editedAction : action.text}
              onChange={(e) => setEditedAction(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === 'Enter' || event.key === 'NumpadEnter') {
                  handleUpdate()
                }
              }}
              editMode={editMode}
              enabled={true}
              className={`ml-2 ${
                action.completed && !editMode ? classes.greyText : classes.text
              }`}
              maxLength={500}
            />
            <HamburgerContainer
              // setCloseFn={setCloseFn}
              ref={dateRef}
              buttons={[
                {
                  title: 'Delete',
                  onClick: () => handleDeleteAction(action.itemId),
                  icon: 'fas fa-times',
                  color: 'red',
                },
                // {
                //   title: 'Due Date',
                //   icon: 'fas fa-calendar',
                //   color: 'yellow',
                //   keepOpen: true,
                //   component: CustomDatePicker,
                // },
                {
                  title: 'Edit',
                  onClick: () => {
                    setEditMode(true)
                  },
                  icon: 'fas fa-edit',
                  color: 'grey',
                },
              ]}
            />
          </div>
        </Col>
      </Row>
      {divider && <Divider className={classes.divider} />}
    </>
  )
}

const ActionItemsCard = ({
  currentActionItem,
  setCurrentActionItem,
  actions,
  reload,
  conversationId,
  currentProfile,
  inCall = false,
  show = false,
  handleError,
  loading,
}) => {
  const header = (
    <div className="d-flex justify-content-between">
      <CardTitle title="My Action Items" />
    </div>
  )
  const compare = (a, b) => {
    if (a.itemId < b.itemId) {
      return -1
    }
    if (a.itemId > b.itemId) {
      return 1
    }
    return 0
  }

  return actions && show && !loading ? (
    <>
      <CardContainer
        header={inCall && isMobile() ? null : header}
        headerDivider={true}
      >
        <ActionInput
          currentActionItem={currentActionItem}
          setCurrentActionItem={setCurrentActionItem}
          currentProfile={currentProfile}
          conversationId={conversationId}
          handleError={handleError}
          reload={reload}
        />
        {actions
          .sort(compare)
          .map((action, index) =>
            action.user.id === currentProfile.id ? (
              <ActionRow
                key={action.itemId}
                action={action}
                conversationId={conversationId}
                currentProfile={currentProfile}
                divider={index < actions.length - 1}
                reload={reload}
                handleError={handleError}
              />
            ) : null,
          )}
      </CardContainer>
    </>
  ) : null
}

export default compose(withErrorHandler)(ActionItemsCard)
