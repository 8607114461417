import React from 'react'
import BannerContainer, { BannerDescription } from './BannerContainer'
import ActionButton from '../../../elemets/ActionButton'
import compose from 'just-compose'
import { withRouter } from 'react-router'

const AddAvailabilityBanner = ({ onClose, history }) => {
  const header = (
    <BannerDescription
      icon={'fas fa-calendar-alt'}
      title={'NO LATTITUDE AVAILABILITY'}
    />
  )
  const description = (
    <p className={`m-0`}>
      Add some availability so that other users in your group can schedule a
      conversation with you
    </p>
  )

  const buttons = (
    <ActionButton
      height="26px"
      text={'ADD AVAILABILITY'}
      onClick={() => {
        history.push('/availability')
      }}
      type="LIGHT_GREEN"
      className="text-nowrap"
    />
  )

  return (
    <BannerContainer
      header={header}
      description={description}
      buttons={buttons}
      type={'GREEN'}
      onClose={onClose}
    />
  )
}

export default compose(withRouter)(AddAvailabilityBanner)
