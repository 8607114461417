import React from 'react'
import { useDurations } from './data/DurationsProvider'
import { DurationKey } from './data/Duration'
import SelectField from '../SelectField'

type DurationFieldProps = {
  value?: DurationKey
  onChange: (value: DurationKey) => void
}

const DurationField = ({
  value,
  onChange,
}: DurationFieldProps): JSX.Element => {
  const { durations, ready } = useDurations()
  return (
    <SelectField
      label="Conversation duration"
      labelId="duration"
      required
      value={value}
      onChange={onChange}
      options={durations}
      disabled={!ready}
    />
  )
}

export default DurationField
