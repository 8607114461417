import React, { useEffect } from 'react'
import { Modal as BootstrapModal, Button } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import CardTitle from './CardTitle'
import colors from '../theme/theme2'
import { isMobile } from '../utils/common'

const useStyles = createUseStyles({
  modal: {
    '& .modal-content': {
      border: 'none',
    },
  },
  container: {
    padding: 15,
  },
  close: {
    alignItems: 'center',
    display: 'flex',
    padding: '3px',
    backgroundColor: 'transparent',
    border: 'none',
    color: colors.dark,
    '&:focus': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      color: colors.dark,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&:active': {
      color: `${colors.dark} !important`,
      backgroundColor: 'transparent !important',
      border: 'none !important',
    },
  },
  header: {
    marginBottom: 17,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  body: {
    backgroundColor: colors.whiteBackground,
    padding: '25px 30px',
  },
  footer: {
    marginTop: 15,
  },
})

type Props = {
  children: React.ReactNode
  show: boolean
  close: () => void
  title: string | React.ReactElement
  titleComponent?: React.ReactElement | null
  footer?: React.ReactElement | null
  closeIcon?: boolean
  closeOutside?: boolean
  modalClass?: string
  bodyClass?: string
  dataTestId?: string
}

function Modal({
  children,
  show,
  close,
  title,
  titleComponent = null,
  footer = null,
  closeIcon = true,
  closeOutside = true,
  modalClass = '',
  bodyClass = '',
  dataTestId = '',
  ...rest
}: Props): React.ReactElement {
  const classes = useStyles()

  // Hook for handling keyup events.
  useEffect(() => {
    // Handler for the "Escape" keyup event.
    const handleKeyUp = (event: KeyboardEvent) => {
      // Check if "Escape" key was pressed.
      if (event.key === 'Escape' || event.key === 'Esc') {
        close()
      }
    }

    // Attach the keyup event listener.
    window.addEventListener('keyup', handleKeyUp)

    // Return a cleanup function to be run on unmount.
    return () => {
      // Detach the keyup event listener.
      window.removeEventListener('keyup', handleKeyUp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Provide an empty dependencies array to ensure this effect only runs once, at mount time.

  return (
    <BootstrapModal
      show={show}
      centered={!isMobile()}
      className={`${classes.modal} ${modalClass}`}
      onHide={closeOutside ? close : () => {}}
      {...rest}
    >
      <div className={classes.container} data-testid={dataTestId}>
        <div className={classes.header}>
          {titleComponent ? titleComponent : <CardTitle title={title} />}
          {closeIcon && (
            <Button
              className={classes.close}
              onClick={close}
              data-testid="modal-closebutton"
            >
              <i className="far fa-times-circle" />
            </Button>
          )}
        </div>
        <div className={`${classes.body} ${bodyClass}`}>{children}</div>
        {footer && <div className={classes.footer}>{footer}</div>}
      </div>
    </BootstrapModal>
  )
}

export default Modal
