import React, { useCallback, useContext, useEffect } from 'react'
import ConfirmationModal from '../../../Modals/ConfirmationModal'
import ActionButton from '../../../../elemets/ActionButton'
import { isMobile } from '../../../../utils/common'
import { createUseStyles } from 'react-jss'
import { useApiSubmit } from '../../../../api'
import { useErrorHandler } from '../../../../errors'
import { headings } from '../../../../theme/theme2'
import { IGroupMemberProfile } from '@/types/UserProfile.typings'
import { IGroup } from '@/types/Groups.typings'
import { NotificationsContext } from '@/notifications/hooks/NotificationsProvider'

const useStyles = createUseStyles({
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  name: {
    ...headings.title,
    marginTop: 10,
  },
  description: {
    ...headings.text,
    fontSize: 14,
    marginLeft: 10,
  },
})

type Props = {
  user: IGroupMemberProfile
  group: IGroup
  setDisplayModal: (display: boolean) => void
  reload: () => void
}

function ResetPasswordModal({
  user,
  group,
  setDisplayModal,
  reload,
}: Props): React.ReactElement {
  const classes = useStyles()
  const handleError = useErrorHandler()
  const { pushNotification } = useContext(NotificationsContext)
  const {
    submitted,
    submit: resetPassword,
    err: updateErr,
    submitting,
  } = useApiSubmit('admin.resetPasswordV1')

  useEffect(() => {
    if (submitted && !updateErr && !submitting) {
      pushNotification({
        level: 'success',
        subject: 'Success!',
        message: `Password update triggered successfully for: ${user.firstName} ${user.lastName}`,
        timeout: 5000,
      })
      setDisplayModal(false)
      reload()
    } else if (updateErr && !submitting) {
      // Unable to get API to return a good status code/message w/o refactoring useSubmit - so I used 418 teapot
      if (updateErr.message.endsWith(`I'm a teapot`)) {
        handleError(updateErr, true, {
          message:
            'Unable to change password for user using 3rd party auth. Inform user to change password in the provider (e.g. Google, Microsoft, LinkedIn). Alternatively, change email first then retry password reset.',
        })
      } else {
        handleError(updateErr, true, {
          message: 'There was a problem triggering password reset.',
        })
      }
    }
  }, [
    submitted,
    updateErr,
    submitting,
    handleError,
    setDisplayModal,
    reload,
    pushNotification,
    user.firstName,
    user.lastName,
  ])

  const save = useCallback(() => {
    resetPassword({
      params: {
        profileId: user.profileId,
      },
      query: {
        groupId: group.id,
      },
    })
  }, [resetPassword, user, group])

  return (
    <ConfirmationModal
      show={true}
      onClose={() => setDisplayModal(false)}
      closeIcon={true}
      title={'Confirm Password Reset'}
      content={
        <>
          <div>
            <p className={`mt-0 mb-0 ${classes.name}`}>
              User: {user.firstName} {user.lastName}
            </p>
          </div>
          <div className="pt-4 pl-3 d-flex align-items-start">
            <label className={classes.description}>
              Yes, I want to trigger a password reset for this user. This will
              re-send an invitation email to the user.
            </label>
          </div>
        </>
      }
      footer={
        <div className="d-flex justify-content-end">
          <ActionButton
            text="RESET PASSWORD"
            className="w-40"
            onClick={save}
            type="STRONG_RED"
            disabled={!resetPassword}
          />
        </div>
      }
      dialogClassName={isMobile() ? classes.dialogMobile : ''}
    />
  )
}

export default ResetPasswordModal
