import React from 'react'
import { createUseStyles } from 'react-jss'

interface GroupItemControlProps {
  name: string
  onClick: () => void
}

const useStyles = createUseStyles(() => ({
  root: {
    border: 'none',
    background: 'none',
    padding: '5px 8px',
    marginLeft: '-8px',
    width: '100%',
    fontWeight: '700',
    textAlign: 'left',
    color: '#343434',
    '&:hover,&:focus': {
      backgroundColor: '#e9ecef',
      color: '#4EBA7E',
    },
  },
}))

const GroupItemControl = ({
  name,
  onClick,
}: GroupItemControlProps): JSX.Element => {
  const classes = useStyles()
  return (
    <button className={classes.root} onClick={() => onClick()}>
      {name}
    </button>
  )
}

export default GroupItemControl
