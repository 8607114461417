import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  PropsWithChildren,
} from 'react'
import { getWafToken } from '@/api/get-waf-token'

interface WafTokenContextProps {
  wafToken: string | null
}

const WafTokenContext = createContext<WafTokenContextProps>({ wafToken: null })

export const WafTokenProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [wafToken, setWafToken] = useState<string | null>(null)

  useEffect(() => {
    const fetchWAFToken = async () => {
      const token = await getWafToken()
      if (token) {
        setWafToken(token)
      }
    }

    fetchWAFToken()
  }, []) // Empty array will run effect only once on mount

  return (
    <WafTokenContext.Provider value={{ wafToken }}>
      {children}
    </WafTokenContext.Provider>
  )
}

export const useWafToken = (): WafTokenContextProps =>
  useContext(WafTokenContext)
