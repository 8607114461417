import React from 'react'
import { createUseStyles } from 'react-jss'
import clsx from 'clsx'

const useStyles = createUseStyles({
  root: {
    cursor: 'pointer',
  },
})

const Icon = ({ classstyle = '', ...props }) => {
  const classes = useStyles()
  return <i className={clsx(props.icon, classes.root, classstyle)} {...props} />
}

export default Icon
