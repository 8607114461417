import React, { ReactNode } from 'react'
import { Modal as BootstrapModal } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

import Loader from './Loader'

type Props = {
  backgroundColor?: string
  children?: ReactNode
}

const LoaderPage: React.FC<Props> = ({
  backgroundColor = 'white',
  children = null,
}) => {
  const useStyles = createUseStyles({
    modal: {
      '& .modal-content': {
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: 'unset',
        outline: 0,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'unset',
      },
    },
    modalBackground: {
      backgroundColor: backgroundColor,
    },
  })

  const classes = useStyles()

  return (
    <BootstrapModal
      show
      centered
      className={classes.modal}
      backdropClassName={classes.modalBackground}
      onHide={() => {}}
    >
      {children ? children : <Loader />}
    </BootstrapModal>
  )
}

export default LoaderPage
