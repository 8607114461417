import React from 'react'
import Field from '../Field'
import ParticipantSelector from './ParticipantSelector'
import { ParticipantId } from './data/Participant'

type ParticipantFieldProps = {
  value?: ParticipantId
  onChange: (value: ParticipantId) => void
  onClear: () => void
}

const ParticipantField = ({
  value,
  onChange,
  onClear,
}: ParticipantFieldProps): JSX.Element => (
  <Field
    label="Name of person you conversed with"
    labelId="participant"
    required
  >
    <ParticipantSelector value={value} onChange={onChange} onClear={onClear} />
  </Field>
)

export default ParticipantField
