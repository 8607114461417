import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { createUseStyles } from 'react-jss'
import colors from '../../theme/theme2'
import { withRouter } from 'react-router-dom'
import { isMobile } from '../../utils/common'
import Icon from '../Icons'

const useStyles = createUseStyles({
  fonts: {
    fontFamily: 'lato',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
    cursor: 'pointer',
  },
  logo: {
    width: 150,
    height: 150,
    boxShadow: `0 6px 16px 0 rgba(153, 155, 168, 0.3)`,
    marginBottom: 10,
    cursor: 'pointer',
  },
  logoMobile: {
    boxShadow: `0 6px 16px 0 rgba(153, 155, 168, 0.3)`,
    maxWidth: 120,
    maxHeight: 120,
    marginBottom: 10,
    height: 120,
    cursor: 'pointer',
  },
  slideButton: {
    position: 'absolute',
    width: 20,
    height: 20,
    padding: 0,
    transform: 'translate(0, -50%)',
    cursor: 'pointer',
    color: 'transparent',
    border: 'none',
    outline: 'none',
    background: 'transparent',
    '& i': {
      '&:before': {
        color: colors.primary,
      },
    },
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    '&:active': {
      border: 'none',
      outline: 'none',
    },
  },
  slideButtonMobile: {
    top: 80,
  },
  slideButtonDesktop: {
    top: 100,
  },
  arrowLeft: {
    left: -30,
  },
  arrowRight: {
    right: -25,
  },
  leftAlign: {
    '& .slick-track': {
      margin: 0,
    },
  },
})

const PrevArrow = props => {
  const { onClick } = props
  const classes = useStyles()
  return (
    <button
      className={`slick-arrow ${classes.slideButton} ${classes.arrowLeft} ${
        isMobile() ? classes.slideButtonMobile : classes.slideButtonDesktop
      }`}
      onClick={onClick}
    >
      <Icon className={`fas fa-chevron-left`} />
    </button>
  )
}
const NextArrow = props => {
  const { onClick } = props
  const classes = useStyles()
  return (
    <button
      className={`slick-arrow ${classes.slideButton} ${classes.arrowRight} ${
        isMobile() ? classes.slideButtonMobile : classes.slideButtonDesktop
      }`}
      onClick={onClick}
    >
      <Icon className={`fas fa-chevron-right`} />
    </button>
  )
}

const SimpleSlider = ({ groups, history }) => {
  const classes = useStyles()
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }
  return (
    <Slider {...settings} className={classes.leftAlign}>
      {groups.map((group, i) => (
        <div key={i}>
          <div
            className={`d-flex justify-content-center`}
            onClick={() => {
              history.push(`/group/${group.id}/dashboard`)
            }}
          >
            <img
              className={isMobile() ? classes.logoMobile : classes.logo}
              src={
                group?.logo ||
                'https://assets.golattitude.com/Lattitude_Logo_FullColor_Icon.png'
              }
              alt="Group Logo"
              onError={e => {
                e.target.src =
                  'https://assets.golattitude.com/Lattitude_Logo_FullColor_Icon.png'
              }}
            />
          </div>
          <p className={classes.fonts}>{group.name}</p>
        </div>
      ))}
    </Slider>
  )
}

export default withRouter(SimpleSlider)
