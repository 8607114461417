import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useContext,
} from 'react'
import { Container, Form } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

import { useErrorHandler } from '../../errors'
import Modal from '../../elemets/Modal'
import CustomInput from '../../elemets/CustomInput'
import ActionButton from '../../elemets/ActionButton'
import { useApiSubmit } from '../../api/index'
import colors, { headings } from '../../theme/theme2'
import { NotificationsContext } from '@/notifications/hooks/NotificationsProvider'

const useStyles = createUseStyles({
  checked: {
    color: colors.primary,
  },
  unchecked: {
    color: colors.grey2,
  },
  conditionTitle: {
    ...headings.title,
    fontSize: 14,
  },
  conditionText: {
    fontSize: 14,
    color: colors.dark,
    fontFamily: 'Lato',
  },
  conditionContainer: {
    marginLeft: '25%',
  },
})

type Props = {
  show: boolean
  onClose: () => void
}

function ResetPasswordModal({ show, onClose }: Props): React.ReactElement {
  const classes = useStyles()
  const handleError = useErrorHandler()
  const { pushNotification } = useContext(NotificationsContext)
  const [formState, setFormState] = useState({
    password: '',
    repeatPassword: '',
  })
  const [errorPasswordMatch, setErrorPasswordMatch] = useState(false)
  const {
    submitted,
    submit: changePassword,
    err: updateErr,
    submitting,
  } = useApiSubmit('changePasswordV1')

  useEffect(() => {
    if (!show) {
      setFormState({
        password: '',
        repeatPassword: '',
      })
      setErrorPasswordMatch(false)
    }
  }, [show])

  useEffect(() => {
    if (submitted && !updateErr && !submitting) {
      pushNotification({
        level: 'success',
        subject: 'Success!',
        message: 'Password updated successfully.',
        timeout: 5000,
      })
      onClose()
    } else if (updateErr && !submitting) {
      // Unable to get API to return a good status code/message w/o refactoring useSubmit - so I used 418 teapot
      if (updateErr.message.endsWith(`I'm a teapot`)) {
        handleError(updateErr, true, {
          message:
            'Unable to change password when using 3rd party auth. Change password in your provider (e.g. Google, Microsoft, LinkedIn).',
        })
      } else {
        handleError(updateErr, true, {
          message: 'There was a problem updating your profile.',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, updateErr, submitting, handleError])

  const handleInput = useCallback(
    (e) => {
      const value = e.target.value
      const name = e.target.name
      if (errorPasswordMatch) {
        setErrorPasswordMatch(false)
      }
      setFormState((f) => ({
        ...f,
        [name]: value,
      }))
    },
    [errorPasswordMatch],
  )

  const Condition = useCallback(
    ({ text, checked, className }) => (
      <div className={className}>
        <i
          className={`fas fa-check-circle mr-2 ${
            checked ? classes.checked : classes.unchecked
          }`}
        ></i>
        <label className={classes.conditionText}>{text}</label>
      </div>
    ),
    [classes.checked, classes.conditionText, classes.unchecked],
  )

  const handleReset = useCallback(() => {
    if (formState.password === formState.repeatPassword) {
      const body = {
        newPassword: formState.password,
        confirmPassword: formState.repeatPassword,
      } as any
      changePassword({ body })
    } else {
      setErrorPasswordMatch(true)
    }
  }, [changePassword, formState.password, formState.repeatPassword])

  const atLeast8Chars = useMemo(
    () => formState.password.length >= 8,
    [formState.password],
  )
  const hasLowerCase = useMemo(
    () => /[a-z]/.test(formState.password),
    [formState.password],
  )
  const hasUpperCase = useMemo(
    () => /[A-Z]/.test(formState.password),
    [formState.password],
  )
  const hasNumber = useMemo(
    () => /\d/.test(formState.password),
    [formState.password],
  )
  const hasSpecialChar = useMemo(
    () => /[!@#$%^&*(),.?":{}|<>~`_+\-=[\]\\';/]/.test(formState.password),
    [formState.password],
  )
  const atLeast3Cond = useMemo(() => {
    let count = 0
    if (hasLowerCase) count += +1
    if (hasUpperCase) count += 1
    if (hasNumber) count += 1
    if (hasSpecialChar) count += 1

    return count > 2
  }, [hasLowerCase, hasUpperCase, hasNumber, hasSpecialChar])

  const isFormValid = useMemo(
    () => atLeast8Chars && atLeast3Cond,
    [atLeast8Chars, atLeast3Cond],
  )

  return (
    <Modal
      show={show}
      close={onClose}
      title="Reset Password"
      footer={
        <div className="d-flex justify-content-end">
          <ActionButton
            text="RESET PASSWORD"
            onClick={handleReset}
            type="STRONG_GREEN"
            disabled={!isFormValid}
            loading={submitting}
          />
        </div>
      }
    >
      <Container>
        <Form>
          <CustomInput
            name="password"
            title="New Password"
            value={formState.password}
            onChange={handleInput}
            containerStyle="mb-3"
            type="password"
          />
          <CustomInput
            name="repeatPassword"
            title="Confirm New Password"
            value={formState.repeatPassword}
            onChange={handleInput}
            containerStyle="mb-3"
            type="password"
            error={errorPasswordMatch}
            errorMessage="Passwords Don’t Match"
          />
        </Form>
        <div className={classes.conditionContainer}>
          <label className={classes.conditionTitle}>
            Your password must contain:
          </label>
          <Condition text="At least 8 characters" checked={atLeast8Chars} />
          <Condition
            text="At least 3 of the following:"
            checked={atLeast3Cond}
          />
          <Condition
            className="ml-4"
            text="Lower case letters (a-z)"
            checked={hasLowerCase}
          />
          <Condition
            className="ml-4"
            text="Upper case letters (A-Z)"
            checked={hasUpperCase}
          />
          <Condition className="ml-4" text="Number (0-9)" checked={hasNumber} />
          <Condition
            className="ml-4"
            text="Special characters"
            checked={hasSpecialChar}
          />
        </div>
      </Container>
    </Modal>
  )
}

export default ResetPasswordModal
