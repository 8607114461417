import React from 'react'
import { createUseStyles } from 'react-jss'
import { Col, Row } from 'react-bootstrap'
import Icon from '../Icons'
import { isMobile } from '../../utils/common'

const useStyles = createUseStyles({
  container: {
    minHeight: 40,
  },
  containerMobile: {
    marginTop: -80,
    marginBottom: 40,
  },
  label: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: '#707575',
  },
  sortName: {
    paddingLeft: 120,
  },
  sortNameMobile: {
    paddingLeft: '25px !important',
  },
  order: {
    margin: 0,
    '& span': {
      marginRight: 14,
      cursor: 'pointer',
    },
    '& i': {
      cursor: 'pointer',
    },
  },
  topics: {
    marginRight: 30,
  },
  subtopics: {
    marginRight: '49%',
  },
  experience: {
    display: 'inline-flex',
  },
  filter: {
    width: '100%',
    paddingLeft: 33,
    paddingRight: 12,
  },
})

const GroupOrder = ({ setSortField }) => {
  const classes = useStyles()

  return (
    <div
      className={`${classes.container} ${
        isMobile() ? classes.containerMobile : ''
      }  ${classes.label}`}
    >
      <Row className={classes.order} noGutters={isMobile()}>
        <Col
          xs={4}
          className={`pt-3 ${
            isMobile() ? classes.sortNameMobile : classes.sortName
          }`}
        >
          <div onClick={() => setSortField('name')}>
            <span>A-Z</span>
            <Icon className={'fas fa-sort'} />
          </div>
        </Col>
        <Col xs={5} className={`pt-3 ${classes.experience}`}>
          {!isMobile() && (
            <div onClick={() => setSortField('sg_name')}>
              <span>Topic</span>
              <Icon className={`fas fa-sort ${classes.topics}`} />
            </div>
          )}
          {!isMobile() && (
            <div
              className={`${classes.subtopics}`}
              onClick={() => setSortField('sk_name')}
            >
              <span>SubTopic</span>
              <Icon className={`fas fa-sort `} />
            </div>
          )}
          <div onClick={() => setSortField('us_years')}>
            <span>Experience</span>
            <Icon className={'fas fa-sort'} />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default GroupOrder
