import React, { ComponentType } from 'react'
import useErrorHandler, { Options } from './useErrorHandler'

export type ErrorHandlerProps = {
  handleError: (
    error: Error,
    showNotification: boolean,
    options?: Options,
  ) => void
}

const withErrorHandler =
  <T extends ErrorHandlerProps = ErrorHandlerProps>(
    Component: React.ComponentType<T>,
  ): ComponentType<Omit<T, keyof ErrorHandlerProps>> =>
  (props: Omit<T, keyof ErrorHandlerProps>) => {
    const handleError = useErrorHandler()
    return <Component {...(props as T)} handleError={handleError} />
  }

export default withErrorHandler
