import React, { useCallback } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ActionButton from '../../elemets/ActionButton'
import { createUseStyles } from 'react-jss'
import compose from 'just-compose'
import { withRouter } from 'react-router'
import { useBrand } from '../../BrandHandler'

const useStyles = createUseStyles({
  logoContainer: {
    width: '100%',
    textAlign: 'center',
  },
  logo: {
    maxWidth: 180,
    maxHeight: 180,
  },
  verticalCenter: {
    marginTop: '20%',
    position: 'absolute',
  },
})

const Landing = () => {
  const classes = useStyles()
  const { brandInfo, setLoadLanding } = useBrand()

  const handleLogin = useCallback(() => {
    setLoadLanding(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoadLanding])

  return (
    <Container>
      <Row>
        <Col xs={12} className={`d-flex justify-content-center`}>
          <div className={classes.verticalCenter}>
            <div className={classes.logoContainer}>
              <img
                className={classes.logo}
                src={
                  brandInfo?.whiteLabelLogo ||
                  'https://assets.golattitude.com/Lattitude-Logo_FullColor_Slogan.png'
                }
                alt="Brand Logo"
                onError={e => {
                  e.target.src =
                    'https://assets.golattitude.com/Lattitude-Logo_FullColor_Slogan.png'
                }}
              />
            </div>
            <div className={`d-flex justify-content-center p-5 text-center`}>
              <h1>{brandInfo.name}</h1>
            </div>
            <div className={`d-flex justify-content-center`}>
              <ActionButton
                text={'SIGN IN'}
                type={'STRONG_GREEN'}
                width={150}
                onClick={handleLogin}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default compose(withRouter)(Landing)
