import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Row, Col, Button } from 'react-bootstrap'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import compose from 'just-compose'

// Elements
import ActionButton from '../../elemets/ActionButton'
import CardContainer from '../../elemets/CardContainer'
import Divider from '../../elemets/Divider'

// Theme
import colors, { headings } from '../../theme/theme2'

// Modals
import ChormeNotSupportedModal from '../Modals/ChormeNotSupportedModal'

// Utils
import { isMobile, decodeAtoBInputs } from '../../utils/common'

// Icons
import ClockIcon from '../Icons/Clock'

// Modals
import ConfirmationModal from '../Modals/ConfirmationModal'

// API
import { useApiSubmit, useApiFetch } from '../../api'

// Errors
import withErrorHandler from '../../errors/withErrorHandler'

// Notifications
import withNotifications from '../../notifications/withNotifications'
import ReScheduleModal from '../Modals/Conversation/ReScheduleModal'
import RequestAvailabilityModal from '../Modals/Conversation/RequestAvailabilityModal'
import TopicsContainer from '../Topics/TopicsContainer'
import GravatarContainer from '../Users/GravatarContainer'
import PastConversationActions from '../../conversations/actions/PastConversationActions'
import ScheduledConversationActions from '../../conversations/actions/ScheduledConversationActions'

const useStyles = createUseStyles({
  container: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    marginBottom: 8,
  },
  mobile: {
    minHeight: 90,
  },
  expandMobile: {
    top: '30px!important',
  },
  expand: {
    position: 'absolute',
    top: 10,
    right: 20,
    zIndex: 500,
  },
  heart: {
    position: 'absolute',
    top: 10,
    left: 0,
    zIndex: 500,
  },
  arrows: {
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },
  experience: {
    top: -6,
    right: '25%',
    position: 'absolute',
    color: colors.primary,
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: 'bold',
  },
  experienceMobile: {
    top: 15,
    right: 15,
    position: 'absolute',
    color: colors.primary,
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: 'bold',
  },
  avatar: {
    fontSize: '24px !important',
    fontWeight: 'bold !important',
    color: `${colors.primary} !important`,
    backgroundColor: `#eff9f3!important`,
  },
  name: {
    ...headings.title,
    marginTop: 10,
  },
  position: {
    ...headings.paragraph,
  },
  bioTitle: {
    ...headings.paragraph,
    fontWeight: 'bold!important',
    color: colors.dark,
  },
  bio: {
    ...headings.paragraph,
    color: colors.grey3,
  },
  bioRow: {
    paddingLeft: 30,
  },
  lightGreyText: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: '#707575',
  },
  clockIcon: {
    color: colors.primary,
  },
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  expandButton: {
    fontFamily: 'Lato',
    fontSize: 12,
    textAlign: 'right',
  },
})

const ConversationRow = ({
  conversation,
  isLast,
  type,
  history,
  handleError,
  reload,
}) => {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)
  const [displayModal, setDisplayModal] = useState(false)
  const [displayCancelModal, setDisplayCancelModal] = useState(false)
  const [displayRescheduleModal, setDisplayRescheduleModal] = useState(false)
  const [, setRequested] = useState(false)
  const [showRequestModal, setShowRequestModal] = useState(false)
  const [scheduleAgain, setScheduleAgain] = useState(false)
  const otherUser = useMemo(() => {
    if (!conversation) {
      return null
    }

    const { participants = [] } = conversation
    return participants.find(({ currentUser }) => !currentUser)
  }, [conversation])

  const {
    submitted,
    submit: setConversationStatus,
    submitting,
    err,
  } = useApiSubmit('setConversationStatus')

  const {
    data: matches,
    loading: loadingMatches,
    err: errorMatches,
  } = useApiFetch('listMatchesV1', {}, false)

  useEffect(() => {
    if (submitted && !err && !submitting) {
      reload()
      setDisplayModal(false)
    } else if (err && !submitting) {
      handleError(err, true, {
        message: 'There was a problem cancelling the conversation.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, err, submitting])

  useEffect(() => {
    if (matches && !errorMatches && !loadingMatches) {
      const match = matches.find((x) => x.profileId === otherUser.profileId)
      if (!match) {
        handleError(true, true, {
          message:
            'There was a problem scheduling another conversation with this person. ',
        })
        if (scheduleAgain) {
          setScheduleAgain(false)
        }
      } else {
        if (scheduleAgain) {
          setScheduleAgain(false)
        } else {
          history.push(
            `/matches?skillId=${conversation.skillId}&groupId=${otherUser.groupId}&token=${match.token}`,
            {
              params: {
                user: match,
              },
            },
          )
        }
      }
    } else if (err && !submitting) {
      handleError(err, true, {
        message: 'There was a problem scheduling a conversation.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches, loadingMatches, errorMatches])

  const cancelConversation = useCallback(() => {
    setConversationStatus({
      params: { conversationId: conversation.id, status: 'cancelled' },
    })
  }, [conversation.id, setConversationStatus])

  if (otherUser) {
    return (
      <div>
        <ChormeNotSupportedModal
          show={displayModal}
          onClose={() => setDisplayModal(false)}
        />
        {showRequestModal && (
          <RequestAvailabilityModal
            show={showRequestModal}
            match={{
              ...otherUser,
              token: conversation.availabilityToken,
              topics: [
                {
                  skillName: conversation.skillName,
                  skillId: conversation.skillId,
                },
              ],
            }}
            setRequestedAvailability={setRequested}
            onClose={() => setShowRequestModal(false)}
          />
        )}
        <ConfirmationModal
          show={displayCancelModal}
          onClose={() => setDisplayCancelModal(false)}
          closeIcon={true}
          title="Cancel Conversation"
          content="Are you sure you want to cancel this conversation?  If you cancel, you will have to reschedule."
          footer={
            <div className="d-flex justify-content-end">
              <ActionButton
                text="CANCEL"
                className="w-40"
                onClick={cancelConversation}
                type="STRONG_RED"
              />
            </div>
          }
          dialogClassName={isMobile() ? classes.dialogMobile : ''}
        />
        {displayRescheduleModal && (
          <ReScheduleModal
            show={displayRescheduleModal}
            conversation={conversation}
            onClose={() => setDisplayRescheduleModal(false)}
            reloadUpcoming={reload}
          />
        )}
        {!isMobile() ? (
          <CardContainer
            className={`${classes.container} ${isLast ? 'mb-5' : ''}`}
          >
            <Row>
              <Col xs={4} className={'pt-2 pl-4'}>
                <GravatarContainer
                  profile={otherUser}
                  size={60}
                  customRightSide={
                    <>
                      <p className={`mb-0 ${classes.lightGreyText}`}>
                        {moment
                          .utc(conversation.scheduled)
                          .local()
                          .format('dddd, MMM DD, YYYY')}
                      </p>
                      <p className={`mb-0 ${classes.lightGreyText}`}>
                        {moment
                          .utc(conversation.scheduled)
                          .local()
                          .format('hh:mm A')}
                      </p>
                    </>
                  }
                />
              </Col>
              <Col xs={5} className="pt-3 pr-0">
                <TopicsContainer
                  topics={[
                    {
                      skillGroupName: conversation.skillGroupName,
                      rating: otherUser.rating || 1,
                      skillName: conversation.skillName,
                      years: otherUser.years || 0,
                      isMentor: otherUser.isMentor || false,
                    },
                  ]}
                  displaySummary={false}
                />
              </Col>
              <Col xs={3} className="justify-content-end pl-0 align-self-end">
                <div className={'d-flex justify-content-end'}>
                  {type === 'SCHEDULED' ? (
                    <ScheduledConversationActions
                      conversationId={conversation.id}
                      startTime={conversation.scheduled}
                      participantId={otherUser.profileId}
                      skill={{
                        id: conversation.skillId,
                        name: conversation.skillName,
                      }}
                      availabilityToken={conversation.availabilityToken}
                      onScheduled={() => {
                        if (typeof reload === 'function') {
                          reload()
                        }
                      }}
                    />
                  ) : (
                    <PastConversationActions
                      conversationId={conversation.id}
                      participantId={otherUser.profileId}
                      groupId={otherUser.groupId}
                      skill={{
                        id: conversation.skillId,
                        name: conversation.skillName,
                      }}
                      availabilityToken={conversation.availabilityToken}
                      onScheduled={() => {
                        if (typeof reload === 'function') {
                          reload()
                        }
                      }}
                    />
                  )}
                </div>
                <div className={'d-flex justify-content-end'}>
                  <Button
                    className={`${classes.arrows}`}
                    onClick={() => {
                      setExpand(!expand)
                    }}
                  >
                    <span className={classes.expandButton}>
                      {expand ? 'Show Less' : 'Show More'}
                      <i
                        className={`ml-1 fas ${
                          expand ? 'fa-chevron-down' : 'fa-chevron-right'
                        }`}
                      />
                    </span>
                  </Button>
                </div>
              </Col>
            </Row>
            {expand && (
              <Row>
                <Col xs={12} className={classes.bioRow}>
                  <Divider className="mt-3" />
                  <span className={classes.bioTitle}>
                    Bio / Executive Summary
                  </span>
                  <p className={`mt-1 ${classes.bio}`}>
                    {decodeAtoBInputs(otherUser.bio)}
                  </p>
                </Col>
              </Row>
            )}
          </CardContainer>
        ) : (
          <CardContainer className={`${classes.container} ${classes.mobile}`}>
            <Row className="align-items-center">
              <Col xs={10}>
                <GravatarContainer
                  profile={otherUser}
                  size={50}
                  customRightSide={
                    <>
                      <p className={`mb-0 ${classes.lightGreyText}`}>
                        {moment
                          .utc(conversation.scheduled)
                          .local()
                          .format('dddd, MMM DD, YYYY')}
                      </p>
                      <p className={`mb-0 ${classes.lightGreyText}`}>
                        {moment
                          .utc(conversation.scheduled)
                          .local()
                          .format('hh:mm A')}
                      </p>
                    </>
                  }
                />
              </Col>
              <Col xs={2}>
                <Button
                  className={classes.arrows}
                  onClick={() => {
                    setExpand(!expand)
                  }}
                >
                  <span>
                    <i
                      className={`fas ${
                        expand ? 'fa-chevron-down' : 'fa-chevron-right'
                      }`}
                    />
                  </span>
                </Button>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex justify-content-end">
                {type === 'SCHEDULED' ? (
                  <ScheduledConversationActions
                    conversationId={conversation.id}
                    startTime={conversation.scheduled}
                    participantId={otherUser.profileId}
                    skill={{
                      id: conversation.skillId,
                      name: conversation.skillName,
                    }}
                    availabilityToken={conversation.availabilityToken}
                    onScheduled={() => {
                      if (typeof reload === 'function') {
                        reload()
                      }
                    }}
                  />
                ) : (
                  <PastConversationActions
                    conversationId={conversation.id}
                    participantId={otherUser.profileId}
                    groupId={otherUser.groupId}
                    skill={{
                      id: conversation.skillId,
                      name: conversation.skillName,
                    }}
                    availabilityToken={conversation.availabilityToken}
                    onScheduled={() => {
                      if (typeof reload === 'function') {
                        reload()
                      }
                    }}
                  />
                )}
              </Col>
            </Row>
            {expand && (
              <>
                <Row className="mt-3">
                  <Col className={classes.bioRow}>
                    <TopicsContainer
                      topics={[
                        {
                          skillGroupName: conversation.skillGroupName,
                          rating: otherUser.rating || 1,
                          skillName: conversation.skillName,
                          years: otherUser.years || 0,
                          isMentor: otherUser.isMentor || false,
                        },
                      ]}
                      displaySummary={false}
                    />
                  </Col>
                  <Col xs={2} className="text-right">
                    {otherUser.availability && otherUser.availability > 0 ? (
                      <ClockIcon
                        style={{ color: colors.primary, cursor: 'default' }}
                      />
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={classes.bioRow}>
                    <Divider className="mt-3" />
                    <span className={classes.bioTitle}>
                      Bio / Executive Summary
                    </span>
                    <p className={`mt-1 ${classes.bio}`}>
                      {decodeAtoBInputs(otherUser.bio)}
                    </p>
                  </Col>
                </Row>
              </>
            )}
          </CardContainer>
        )}
      </div>
    )
  }
  return null
}

export default compose(
  withRouter,
  withErrorHandler,
  withNotifications,
)(ConversationRow)
