import { datadogRum } from '@datadog/browser-rum'
import { makeGlobalLogger } from './logger/logger'
// import { init, Replay } from '@sentry/react'
import loadingConfig from './config/init'
import version from './version'

// initialize sentry
loadingConfig
  // .then((config) => {
  //   const { dsn, environment, replaySampleRate, tracesSampleRate } =
  //     config.sentry
  //   if (dsn) {
  //     init({
  //       dsn,
  //       environment,
  //       release: `v${version}`,
  //       replaysSessionSampleRate: replaySampleRate || 0.2,
  //       replaysOnErrorSampleRate: tracesSampleRate || 1.0,
  //       integrations: [new Replay()],
  //       tracesSampleRate: tracesSampleRate || 0.2,
  //     })
  //     // eslint-disable-next-line no-console -- during init, wont use pino logger
  //     logger.info('Sentry initialized', {
  //       hasDsn: !!dsn,
  //       environment,
  //       replaySampleRate,
  //       tracesSampleRate,
  //       version,
  //     })
  //   } else {
  //     // eslint-disable-next-line no-console -- during init, wont use pino logger
  //     logger.info('Sentry not initialized', { environment, version })
  //   }
  //   return config
  // })
  .then(({ datadog, logLevel }) => {
    // Init Logger
    const logger = makeGlobalLogger({
      level: logLevel,
    })
    logger.info('Logger initialized', { logLevel: logger.level, version })

    // DataDog RUM
    const {
      applicationId,
      clientToken,
      defaultPrivacyLevel,
      env,
      service,
      site,
      sessionSampleRate,
      sessionReplaySampleRate,
      trackUserInteractions,
      trackLongTasks,
      trackResources,
    } = datadog

    if (clientToken) {
      datadogRum.init({
        ...datadog,
        allowedTracingUrls: [
          (url) => url.startsWith('https://api.golattitude.com'),
          (url) => url.startsWith('https://graph.golattitude.com'),
          (url) => url.startsWith('https://api.dev.golattitude.com'),
          (url) => url.startsWith('https://graph.dev.golattitude.com'),
        ],
      })

      // eslint-disable-next-line no-console -- during init, wont use pino logger
      logger.info('DataDog initialized', {
        service,
        site,
        env,
        hasClientToken: !!clientToken,
        hasApplicationId: !!applicationId,
        dpl: defaultPrivacyLevel,
        ssr: sessionSampleRate,
        srsr: sessionReplaySampleRate,
        tui: trackUserInteractions,
        tlt: trackLongTasks,
        tr: trackResources,
      })
    } else {
      // eslint-disable-next-line no-console -- during init, wont use pino logger
      logger.info('DataDog not initialized', { env, service, site })
    }
  })
