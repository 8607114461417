import React from 'react'
import CardContainer from '../../../elemets/CardContainer'
import { Button, Col, Row } from 'react-bootstrap'
import CircleName from '../../../elemets/CircleName'
import Divider from '../../../elemets/Divider'
import { createUseStyles } from 'react-jss'
import colors, { headings } from '../../../theme/theme2'
import TopicsContainer from '../../Topics/TopicsContainer'
import ScheduleButton from './ScheduleButton'
import { isMobile } from '../../../utils/common'
import CircleImage from '../../../elemets/CircleImage'

const useStyles = createUseStyles({
  card: {
    boxShadow: '0 6px 16px 0 rgb(0 0 0 / 30%)',
    marginBottom: 8,
    maxWidth: 450,
    paddingTop: 0,
    position: 'absolute',
    margin: '0 25%',
    zIndex: 10,
    width: '95%',
  },
  mobile: {
    margin: '0 2%',
  },
  avatar: {
    fontSize: '14px !important',
    fontWeight: 'bold !important',
    color: `${colors.primary} !important`,
    backgroundColor: `#eff9f3!important`,
  },
  name: {
    ...headings.title,
    marginTop: 10,
  },
  position: {
    ...headings.paragraph,
  },
  bioTitle: {
    ...headings.paragraph,
    fontWeight: 'bold!important',
    color: colors.dark,
  },
  bio: {
    ...headings.paragraph,
    color: colors.grey3,
    whiteSpace: 'break-spaces',
    textAlign: 'justify',
  },
  bioRow: {
    paddingLeft: 30,
    paddingRight: 30,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  title: {
    ...headings.title,
  },
  close: {
    position: 'absolute',
    right: 10,
    top: 5,
    padding: '3px',
    backgroundColor: 'transparent',
    border: 'none',
    color: colors.dark,
    '&:focus': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      color: colors.dark,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&:active': {
      color: `${colors.dark} !important`,
      backgroundColor: 'transparent !important',
      border: 'none !important',
    },
  },
})

const DetailsCard = ({ profileTopics, match, onClose, reloadUpcoming }) => {
  const classes = useStyles()

  return (
    <CardContainer
      className={`${classes.card} ${isMobile() ? classes.mobile : ''}`}
    >
      <Row noGutters className="align-items-center">
        {match.avatar ? (
          <CircleImage
            src={match.avatar}
            size={40}
            className={classes.avatar}
          />
        ) : (
          <CircleName
            id="avatar-name"
            size={40}
            text={
              match.firstName && match.lastName
                ? match.firstName[0] + match?.lastName[0]
                : ''
            }
            className={classes.avatar}
          />
        )}
        <div className="pl-3">
          <p className={`mb-0 ${classes.name}`}>
            {match.firstName} {match.lastName}
          </p>
          <p className={classes.position}>{match.title}</p>
        </div>
        <Button className={classes.close} onClick={onClose}>
          <i className="far fa-times-circle" />
        </Button>
      </Row>
      <Divider className={`mt-0`} />
      <Row>
        <Col xs={12} className={'p'}>
          <TopicsContainer topics={match.topics} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={`mt-3 d-flex justify-content-end`}>
          <ScheduleButton
            profileTopics={profileTopics}
            match={match}
            reloadUpcoming={reloadUpcoming}
          />
        </Col>
      </Row>
    </CardContainer>
  )
}

export default DetailsCard
