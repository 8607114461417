import React, { useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'

// Modals
import UploadImageModal from '../Modals/UploadImageModal'

// Theme
import colors from '../../theme/theme2'

// Elements
import Icon from '../Icons/index'
import CircleImage from '../../elemets/CircleImage'
import CircleName from '../../elemets/CircleName'

const useStyles = createUseStyles({
  name: {
    fontFamily: 'Lato',
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.dark,
    width: '100%',
    marginBottom: 0,
    marginTop: 23,
  },
  description: {
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.dark,
  },
  editable: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  cameraIcon: {
    color: colors.primary,
    position: 'absolute',
    bottom: 0,
    right: 'calc(50% - 60px)',
    cursor: 'pointer',
    padding: '6px 6px 0px 6px',
    fontSize: 20,
  },
})

const PersonalAvatar = ({
  avatar,
  fullName,
  description,
  avatarEditable = false,
  reload,
}) => {
  const classes = useStyles()

  // States
  const [displayModal, setDisplayModal] = useState(false)

  // Callbacks
  const handleSelectAvatarModal = useCallback(() => {
    setDisplayModal(true)
  }, [])

  return (
    <div>
      {avatarEditable && (
        <UploadImageModal
          endpoint={'/v1/profiles/me/account/gravatar'}
          title={'Upload Profile Photo'}
          imageType="avatar"
          faIconClass={'fa-user'}
          show={displayModal}
          onClose={() => setDisplayModal(false)}
          forcePageReload
        />
      )}
      <div
        className={classes.editable}
        onClick={avatarEditable ? handleSelectAvatarModal : null}
      >
        {avatar ? (
          <CircleImage src={avatar} size={120} />
        ) : (
          <CircleName size={120} text={fullName} />
        )}
        <Icon className={`fas fa-camera ${classes.cameraIcon}`} />
      </div>
      <label className={classes.name} data-testid={'personal-avatar__name'}>
        {fullName}
      </label>
      <span
        className={classes.description}
        data-testid={'personal-avatar__title'}
      >
        {description}
      </span>
      {/* <StarsScore /> */}
    </div>
  )
}

export default PersonalAvatar
