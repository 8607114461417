import React, { useMemo, useState } from 'react'
import CardContainer from '../../elemets/CardContainer'
import CardTitle from '../../elemets/CardTitle'
import { createUseStyles } from 'react-jss'
import { Button, Col, Collapse, Row } from 'react-bootstrap'
import { isMobile } from '../../utils/common'
import CircleName from '../../elemets/CircleName'
import SkillLevelIcon from '../Icons/SkillLevel'
import Divider from '../../elemets/Divider'
import colors, { headings } from '../../theme/theme2'

const useStyles = createUseStyles({
  container: {
    paddingBottom: 0,
  },
  icon: {
    '& i': {
      cursor: 'default',
      '&:before': {
        fontSize: 20,
        color: colors.primary,
      },
    },
  },
  avatar: {
    fontSize: isMobile() ? '18px!important' : '22px!important',
    fontWeight: 'bold !important',
    color: `${colors.primary} !important`,
    backgroundColor: `#eff9f3!important`,
  },
  expandButton: {
    fontFamily: 'Lato',
    fontSize: isMobile() ? 10 : 12,
    textAlign: 'right',
    color: colors.primary,
  },
  arrows: {
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },
  experience: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: '#707575',
  },
  subTopics: {
    ...headings.text,
    fontSize: 14,
  },
  topic: {
    ...headings.text,
    fontWeight: 'bold',
  },
  mentorBadge: {
    width: 18,
    height: 18,
    margin: '4px 0 28px 9px',
    padding: '1px 3px 3px 3px',
    backgroundColor: colors.primary,
    borderRadius: 2,
    color: colors.whiteBackground,
    fontFamily: 'lato',
    fontSize: 13,
    fontWeight: 'bold',
    cursor: 'default',
  },
  topicMobile: {
    ...headings.title,
  },
})

const ratingValues = [
  {
    value: 1,
    text: 'Little experience',
  },
  {
    value: 2,
    text: 'Some experience',
  },
  {
    value: 3,
    text: 'Significant experience',
  },
  {
    value: 4,
    text: 'Can teach topic',
  },
  {
    value: 5,
    text: 'Subject matter expert',
  },
]

const TopicCard = ({ topic }) => {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)

  const showMoreArrows = useMemo(() => {
    return (
      topic?.summary && (
        <Button
          className={`${classes.arrows} p-0`}
          onClick={() => {
            setExpand(!expand)
          }}
        >
          <span className={classes.expandButton}>
            {expand ? 'Show Less' : 'Show More'}
            <i
              className={`ml-1 fas ${
                expand ? 'fa-chevron-up' : 'fa-chevron-down'
              }`}
            />
          </span>
        </Button>
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand, topic.summary])

  return (
    <CardContainer className={`mb-2 py-3`}>
      <Row>
        <Col xs={2} className={`${isMobile() ? 'pl-3 pr-0' : 'pl-4'}`}>
          <Row noGutters className="align-items-center">
            <CircleName
              id="avatar-name"
              size={isMobile() ? 40 : 50}
              text={topic?.skillName[0] + topic?.skillName[1].toUpperCase()}
              className={classes.avatar}
            />
          </Row>
        </Col>
        <Col xs={isMobile() ? 7 : 10} className={isMobile() ? `p-0` : ''}>
          <Row noGutters className="justify-content-between">
            <Col>
              <span className={classes.topic}>{topic.groupName}</span>
              {topic?.isMentor && (
                <span className={classes.mentorBadge}>M</span>
              )}
              <br />
              <span className={classes.subTopics}>{topic?.skillName}</span>
            </Col>
            {!isMobile() && (
              <>
                <Col
                  className={`d-flex justify-content-center ${classes.subTopics} pt-2`}
                >
                  <div className={classes.icon}>
                    <SkillLevelIcon rating={topic?.rating} />
                  </div>
                  {topic?.rating} -{' '}
                  {ratingValues[parseInt(topic?.rating) - 1 || 0].text}
                </Col>
                <Col className={`pt-2`} style={{ textAlign: 'right' }}>
                  <span className={classes.experience}>
                    {topic?.years} Years Experience
                  </span>
                  <br />
                  {showMoreArrows}
                </Col>
              </>
            )}
          </Row>
        </Col>
        {isMobile() && (
          <Col xs={3} className={`pl-0`} style={{ textAlign: 'end' }}>
            <div className={classes.icon}>
              <SkillLevelIcon rating={topic?.rating} />
              <span className={classes.topicMobile}>{topic?.rating}</span>
            </div>
            {showMoreArrows}
          </Col>
        )}
      </Row>
      <Collapse in={expand}>
        <Row>
          <Col xs={12} className={`pl-4`}>
            <Divider />
            <span>{topic.summary}</span>
          </Col>
        </Row>
      </Collapse>
    </CardContainer>
  )
}

const TopicsInfo = ({ topics }) => {
  const classes = useStyles()
  return (
    topics.length > 0 && (
      <>
        <CardContainer
          header={<CardTitle title={'Topics of Interest'} />}
          className={classes.container}
        />
        {topics.map((topic, index) => {
          return <TopicCard key={index} topic={topic} />
        })}
      </>
    )
  )
}

export default TopicsInfo
