import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import compose from 'just-compose'

import { withErrorHandler } from '../../../errors'

// Elements
import CardContainer from '../../../elemets/CardContainer'
import CardTitle from '../../../elemets/CardTitle'
import CircleImage from '../../../elemets/CircleImage'

// Theme
import CircleName from '../../../elemets/CircleName'
import Divider from '../../../elemets/Divider'
import CustomTextArea from '../../../elemets/CustomTextArea'

// API
import { useApiSubmit } from '../../../api/hooks'

// Utils
import { isMobile } from '../../../utils/common'

// Theme
import colors, { headings } from '../../../theme/theme2'
import HamburgerContainer from './HamburgerContainer'
import Icon from '../../Icons'

const useStyles = createUseStyles({
  mobileName: {
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.primary,
  },
  text: {
    ...headings.text,
    fontSize: 14,
    paddingLeft: 16,
    marginBottom: 0,
    fontWeight: 'bold',
    color: '#343434 !important',
  },
  withoutBold: {
    fontWeight: 500,
  },
  circle: {
    width: '45px!important',
    fontWeight: 'bold!important',
  },
  circleProfile: {
    color: 'white!important',
    backgroundColor: `${colors.primary}!important`,
  },
  greyText: {
    ...headings.text,
    fontSize: 12,
    color: '#8a8a8a',
  },
  divider: {
    backgroundColor: '#e8ecef',
  },
})

const AgendaInput = ({
  conversationId,
  currentProfile,
  handleError,
  reload,
}) => {
  const classes = useStyles()
  const [agenda, setAgenda] = useState({
    text: '',
    completed: false,
  })

  const {
    submitted,
    submit: addConversationItem,
    submitting,
    err,
  } = useApiSubmit('addConversationItem')

  // Added
  useEffect(() => {
    if (submitted && !err && !submitting) {
      setAgenda({
        text: '',
        completed: false,
      })
      reload()
    } else if (err && !submitting) {
      handleError(err, true, {
        message: 'There was a problem adding the action.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, err, submitting])

  const handleSave = useCallback(() => {
    addConversationItem({
      params: { conversationId },
      body: {
        text: agenda.text,
        type: 'agenda',
        userId: currentProfile.id,
        completed: agenda.completed,
      },
    })
  }, [addConversationItem, conversationId, currentProfile, agenda])

  return (
    <>
      <Row className="mb-2">
        <Col>
          <div className="d-flex align-items-center">
            <CustomTextArea
              value={agenda.text}
              onChange={(e) => setAgenda({ ...agenda, text: e.target.value })}
              onKeyPress={(event) => {
                if (event.key === 'Enter' || event.key === 'NumpadEnter') {
                  handleSave()
                }
              }}
              editMode={true}
              className={`ml-2 ${classes.text} ${classes.withoutBold}`}
              maxLength={200}
              placeholder={'Create an agenda item...'}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

const AgendaRow = ({
  agenda,
  divider,
  currentProfile,
  conversationId,
  reload,
  handleError,
}) => {
  const classes = useStyles()
  const [editedAgenda, setEditedAgenda] = useState('')
  const [editMode, setEditMode] = useState(false)

  const name = useMemo(() => {
    if (
      agenda &&
      agenda.user &&
      agenda.user.firstName &&
      agenda.user.lastName
    ) {
      return agenda.user.firstName[0] + agenda.user.lastName[0]
    }
    return ''
  }, [agenda])

  const {
    submitted: updated,
    submit: updateConversationItem,
    submitting: updateLoading,
    err: updateErr,
  } = useApiSubmit('updateConversationItem')

  const {
    submitted: deleted,
    submit: deleteConversationItem,
    submitting: deleteLoading,
    err: deleteErr,
  } = useApiSubmit('deleteConversationItem')

  useEffect(() => {
    if (agenda) {
      setEditedAgenda(agenda.text)
    }
  }, [agenda])

  // Deleted
  useEffect(() => {
    if (deleted && !deleteErr && !deleteLoading) {
      reload()
    } else if (deleteErr && !deleteLoading) {
      handleError(deleteErr, true, {
        message: 'There was a problem deleting the agenda.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted, deleteErr, deleteLoading])

  // Updated
  useEffect(() => {
    if (updated && !updateErr && !updateLoading) {
      setEditMode(false)
      reload()
    } else if (updateErr && !updateLoading) {
      handleError(updateErr, true, {
        message: 'There was a problem updating the agenda.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated, updateErr, updateLoading])

  const handleDeleteAgenda = useCallback(
    (itemId) => {
      deleteConversationItem({ params: { conversationId, itemId } })
    },
    [conversationId, deleteConversationItem],
  )

  const handleUpdate = useCallback(() => {
    updateConversationItem({
      params: { conversationId, itemId: agenda.itemId },
      body: {
        text: editedAgenda,
        type: 'agenda',
        userId: currentProfile.id,
        completed: agenda.completed,
        updatedBy: currentProfile.id,
      },
    })
  }, [
    updateConversationItem,
    conversationId,
    agenda.itemId,
    agenda.completed,
    editedAgenda,
    currentProfile.id,
  ])

  return (
    <>
      <Row>
        <Col>
          <div className="d-flex align-items-center">
            {isMobile() ? (
              <span className={classes.mobileName}>{name}</span>
            ) : (
              <>
                {agenda.user.avatar ? (
                  <CircleImage src={agenda.user.avatar} size={40} />
                ) : (
                  <CircleName
                    className={`${classes.circle} ${
                      agenda.user.id === currentProfile.id
                        ? classes.circleProfile
                        : ''
                    }`}
                    text={name}
                  />
                )}
              </>
            )}
            <CustomTextArea
              value={editMode ? editedAgenda : agenda.text}
              onChange={(e) => setEditedAgenda(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === 'Enter' || event.key === 'NumpadEnter') {
                  handleUpdate()
                }
              }}
              editMode={editMode}
              enabled={true}
              className={`ml-2 ${classes.text}`}
            />
            {agenda.user.id === currentProfile.id && (
              <HamburgerContainer
                buttons={[
                  {
                    title: 'Delete',
                    onClick: () => handleDeleteAgenda(agenda.itemId),
                    icon: 'fas fa-times',
                    color: 'red',
                  },
                  {
                    title: 'Edit',
                    onClick: () => {
                      setEditMode(true)
                    },
                    icon: 'fas fa-edit',
                    color: 'grey',
                  },
                ]}
              />
            )}
          </div>
        </Col>
      </Row>
      {divider && <Divider className={classes.divider} />}
    </>
  )
}

const AgendaItemsCard = ({
  agendas,
  reload,
  show,
  conversationId,
  handleError,
  currentProfile,
  loading,
}) => {
  const classes = useStyles()

  const Header = useCallback(
    () => (
      <div className="d-flex justify-content-between">
        <CardTitle title="Agenda Items" />
        <div className={classes.greyText}>
          <Icon icon="fas fa-sync-alt" />
          <span className={'pl-2'}>Shared</span>
        </div>
      </div>
    ),
    [classes.greyText],
  )

  const compare = (a, b) => {
    if (a.itemId < b.itemId) {
      return -1
    }
    if (a.itemId > b.itemId) {
      return 1
    }
    return 0
  }

  return agendas && show && !loading ? (
    <CardContainer header={Header()} headerDivider={agendas.length > 0}>
      <AgendaInput
        conversationId={conversationId}
        currentProfile={currentProfile}
        handleError={handleError}
        reload={reload}
      />
      {agendas.sort(compare).map((agenda, index) => (
        <AgendaRow
          key={agenda.itemId}
          agenda={agenda}
          conversationId={conversationId}
          currentProfile={currentProfile}
          divider={index !== agendas.length - 1}
          reload={reload}
          handleError={handleError}
        />
      ))}
    </CardContainer>
  ) : null
}

export default compose(withErrorHandler)(AgendaItemsCard)
