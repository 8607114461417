import React, { useCallback, useEffect, useState } from 'react'
import compose from 'just-compose'
import { createUseStyles } from 'react-jss'

// Theme
import { withErrorHandler } from '../../../errors'
import { withNotifications } from '../../../notifications'
import Divider from '../../../elemets/Divider'
import { useApiFetch } from '../../../api'
import ConversationList from './ConversationsList'
import ActionModal from './ActionModal'

const useStyles = createUseStyles({
  body: {
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
  },
  modalContainer: {
    '& .modal-dialog': {
      width: 500,
    },
  },
})

const ActionItemsModal = ({
  show,
  onClose,
  handleError,
  status = 'completed',
  setDueItems,
}) => {
  const classes = useStyles()
  const [convoFiltered, setConvoFiltered] = useState([])

  const {
    data: conversations,
    loading: areConversationsLoading,
    err: conversationsErr,
  } = useApiFetch('listConversations', {
    query: { count: 1000, status: status },
  })

  const {
    data: actionItems,
    err,
    reload,
  } = useApiFetch('listActionItems', {
    query: { type: 'action' },
  })

  useEffect(
    () => {
      if (conversationsErr || err)
        handleError(conversationsErr, true, {
          message: 'There was a problem loading conversations.',
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [areConversationsLoading, conversationsErr, err],
  )

  useEffect(() => {
    if (conversations && actionItems) {
      actionItems.results
        .filter(actionItem => !actionItem.completed)
        .map(ai => {
          if (convoFiltered.indexOf(ai.conversationId) === -1) {
            setConvoFiltered(s => [...s, ai.conversationId])
          }
          return true
        })
      setDueItems(
        actionItems.results.filter(actionItem => {
          return (
            !actionItem.completed &&
            conversations.find(
              conversation => conversation.id === actionItem.conversationId,
            ) !== undefined
          )
        }).length,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations, actionItems])

  const handleOnClose = useCallback(() => {
    reload()
    onClose()
  }, [onClose, reload])
  return (
    <div className={classes.modalContainer}>
      <ActionModal
        show={show}
        close={handleOnClose}
        title="Action Items"
        closeIcon={true}
        bodyClass={classes.body}
      >
        <Divider />

        {
          //Remove repeated conversation ids from convoFiltered
          conversations
            ?.filter(
              conversation => convoFiltered.indexOf(conversation.id) > -1,
            )
            .slice(0, 5)
            .map((conversation, index, total) => {
              const aItems = actionItems?.results.filter(
                ai => ai.conversationId === conversation.id,
              )
              return (
                <div key={conversation.id}>
                  <ConversationList
                    conversation={conversation}
                    actionItems={aItems}
                  />
                  {index !== total.length - 1 && <Divider className={`mt-0`} />}
                </div>
              )
            })
        }
      </ActionModal>
    </div>
  )
}

export default compose(withErrorHandler, withNotifications)(ActionItemsModal)
