import React, { useEffect, useState, useCallback } from 'react'
import { useAuth0 } from '../../auth0'
import { useApiFetch, useApiSubmit } from '../../api'
import { useErrorHandler } from '../../errors'

import ConfirmationModal from '../Modals/ConfirmationModal'
import ActionButton from '../../elemets/ActionButton'
import OnBoardingTemplate from './OnBoardingTemplate'
import LoaderPage from '../../elemets/LoaderPage'
import CustomBanner from './CustomBanner/CustomBanner'
import { useGroups } from '../../groups/GroupsContext'
import { useCurrentUser } from '@/components/Context/CurrentUser'

const Home = () => {
  const [showCustomBanner, setShowCustomBanner] = useState(true)
  const [showTooltip, setShowTooltip] = useState(0)
  const { loading: isAuthLoading, logout } = useAuth0()
  const {
    currentUser,
    loading: isUserLoading,
    loadingUserError: userErr,
    reload: userReload,
  } = useCurrentUser()
  const { active: activeGroup } = useGroups()
  const [displayEnableAccountModal, setDisplayEnableAccountModal] =
    useState(false)
  const [activationModalContent, setActivationModalContent] = useState({
    title: '',
    content: '',
    footer: null,
  })

  const {
    submitted,
    submit: activateProfile,
    err: activateErr,
    submitting,
  } = useApiSubmit('activateProfile')

  const {
    data: userBanner,
    loading: isUserBannerLoading,
    reload: reloadBanner,
  } = useApiFetch('getBannerV1')

  useEffect(() => {
    if (submitted && !activateErr && !submitting) {
      userReload()
      setDisplayEnableAccountModal(false)
    } else if (activateErr && !submitting) {
      handleError(activateErr, true, {
        message: 'There was a problem activating your profile.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, activateErr, submitting])

  useEffect(() => {
    if (!isUserLoading && currentUser?.id && !currentUser.isActive) {
      setActivationModalContent({
        title: `Reactivate Account`,
        content:
          'Your account has been deactivated. In order to access the Lattitude community you will need to enable your account.',
        footer: (
          <div className="d-flex justify-content-end">
            <ActionButton
              type="STRONG_GREEN"
              text="ENABLE ACCOUNT"
              onClick={activateProfile}
              loading={submitting}
            />
          </div>
        ),
      })
      setDisplayEnableAccountModal(true)
    }
  }, [isUserLoading, currentUser, submitting, activateProfile])

  useEffect(() => {
    if (currentUser?.showTour) {
      setShowTooltip(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const handleCloseCustomBanner = useCallback(() => {
    setShowCustomBanner(false)
  }, [])

  const handleError = useErrorHandler()
  if (isAuthLoading) return <LoaderPage />
  if (isUserLoading) return <LoaderPage />
  if (userErr) {
    handleError(userErr, true, {
      message: 'There was a problem loading your profile.',
    })
  }

  if (isUserBannerLoading) return <LoaderPage />
  if (currentUser?.isActive) {
    if (currentUser.groups.length === 0) {
      alert(
        'You can not access Lattitude until you have been invited to a group.',
      )
      logout()
    }
    if (!activeGroup?.id) {
      return <LoaderPage />
    }
  }

  return (
    <>
      <ConfirmationModal
        show={displayEnableAccountModal}
        onClose={logout}
        footer={activationModalContent.footer}
        title={activationModalContent.title}
        content={activationModalContent.content}
        closeIcon={true}
        closeOutside={false}
      />
      {showCustomBanner && (
        <CustomBanner
          banner={userBanner}
          onClose={handleCloseCustomBanner}
          showTour={() => {
            setShowTooltip(1)
          }}
          reload={reloadBanner}
        />
      )}

      {currentUser?.isActive && (
        <OnBoardingTemplate
          profile={currentUser}
          showTooltip={showTooltip}
          setShowTooltip={setShowTooltip}
          groupId={activeGroup.id}
        />
      )}
    </>
  )
}

export default Home
