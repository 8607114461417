import { Dispatch, useReducer } from 'react'
import { Conversation } from '../../Conversation'
import { ParticipantId } from '../fields/participant/data/Participant'
import { MethodKey } from '../fields/method/data/Method'
import { DurationKey } from '../fields/duration/data/Duration'
import { RelationshipKey } from '../fields/relationship/data/Relationship'
import { TopicId } from '../fields/topics/data/Topic'

export type PartialConversation = {
  [key in keyof Conversation]?: Conversation[key]
}

export type State = {
  value: PartialConversation
}

type SetDateAction = {
  type: 'SET_DATE'
  payload: { year: number; month: number; day: number }
}

export const setDate = (
  year: number,
  month: number,
  day: number,
): SetDateAction => ({
  type: 'SET_DATE',
  payload: { year, month, day },
})

type SetStartTimeAction = {
  type: 'SET_START_TIME'
  payload: { hours: number; minutes: number }
}

export const setStartTime = (
  hours: number,
  minutes: number,
): SetStartTimeAction => ({
  type: 'SET_START_TIME',
  payload: { hours, minutes },
})

type SetParticipantAction = { type: 'SET_PARTICIPANT'; payload: ParticipantId }

export const setParticipant = (id: ParticipantId): SetParticipantAction => ({
  type: 'SET_PARTICIPANT',
  payload: id,
})

type ClearParticipantAction = { type: 'CLEAR_PARTICIPANT' }

export const clearParticipant = (): ClearParticipantAction => ({
  type: 'CLEAR_PARTICIPANT',
})

type SetMethodAction = { type: 'SET_METHOD'; payload: MethodKey }

export const setMethod = (key: MethodKey): SetMethodAction => ({
  type: 'SET_METHOD',
  payload: key,
})

type SetDurationAction = { type: 'SET_DURATION'; payload: DurationKey }

export const setDuration = (key: DurationKey): SetDurationAction => ({
  type: 'SET_DURATION',
  payload: key,
})

type SetPrimaryRelationshipAction = {
  type: 'SET_PRIMARY_RELATIONSHIP'
  payload: RelationshipKey
}

export const setPrimaryRelationship = (
  key: RelationshipKey,
): SetPrimaryRelationshipAction => ({
  type: 'SET_PRIMARY_RELATIONSHIP',
  payload: key,
})

type SetGoalAction = {
  type: 'SET_GOAL'
  payload: string
}

export const setGoal = (goal: string): SetGoalAction => ({
  type: 'SET_GOAL',
  payload: goal,
})

type SetNotesAction = {
  type: 'SET_NOTES'
  payload: string
}

export const setNotes = (notes: string): SetNotesAction => ({
  type: 'SET_NOTES',
  payload: notes,
})

type SetTopicsAction = {
  type: 'SET_TOPICS'
  payload: TopicId[]
}

export const setTopics = (topics: TopicId[]): SetTopicsAction => ({
  type: 'SET_TOPICS',
  payload: topics,
})

type ClearTopicsAction = {
  type: 'CLEAR_TOPICS'
}

export const clearTopics = (): ClearTopicsAction => ({
  type: 'CLEAR_TOPICS',
})

type Action =
  | SetDateAction
  | SetStartTimeAction
  | SetParticipantAction
  | ClearParticipantAction
  | SetMethodAction
  | SetDurationAction
  | SetPrimaryRelationshipAction
  | SetGoalAction
  | SetNotesAction
  | SetTopicsAction
  | ClearTopicsAction

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_DATE': {
      const {
        payload: { year, month, day },
      } = action
      const { value } = state
      const datestamp = new Date(year, month - 1, day, 0, 0, 0, 0)
      const date = datestamp.getTime() / 1000
      return { ...state, value: { ...value, date } }
    }
    case 'SET_START_TIME': {
      const {
        payload: { hours, minutes },
      } = action
      const { value } = state
      if (Number.isNaN(hours) || Number.isNaN(minutes)) {
        // invalid time, just clear existing value
        const { startTime: existingStartTime, ...rest } = value
        return { ...state, value: rest }
      }

      const startTime = hours * 60 * 60 + minutes * 60
      return { ...state, value: { ...value, startTime } }
    }
    case 'SET_PARTICIPANT': {
      const { payload: participant } = action
      const { value } = state
      return { ...state, value: { ...value, participant } }
    }
    case 'CLEAR_PARTICIPANT': {
      const { value } = state
      const { participant, ...rest } = value
      return { ...state, value: rest }
    }
    case 'SET_METHOD': {
      const { payload: method } = action
      const { value } = state
      return { ...state, value: { ...value, method } }
    }
    case 'SET_DURATION': {
      const { payload: duration } = action
      const { value } = state
      return { ...state, value: { ...value, duration } }
    }
    case 'SET_PRIMARY_RELATIONSHIP': {
      const { payload: primaryRelationship } = action
      const { value } = state
      return { ...state, value: { ...value, primaryRelationship } }
    }
    case 'SET_GOAL': {
      const { payload: goal } = action
      const { value } = state
      return { ...state, value: { ...value, goal } }
    }
    case 'SET_NOTES': {
      const { payload: notes } = action
      const { value } = state
      return { ...state, value: { ...value, notes } }
    }
    case 'SET_TOPICS': {
      const { payload: topics } = action
      const { value } = state
      return { ...state, value: { ...value, topics } }
    }
    case 'CLEAR_TOPICS': {
      const { value } = state
      const { topics, ...rest } = value
      return { ...state, value: rest }
    }
    default: {
      return state
    }
  }
}

export const useConversationsBuilderState = (
  initialValue: PartialConversation = {},
): [State, Dispatch<Action>] => useReducer(reducer, { value: initialValue })
