import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import compose from 'just-compose'

// Components
import CardContainer from '../../elemets/CardContainer'
import Calendar from './Calendar'
import Divider from '../../elemets/Divider'
import CardTitle from '../../elemets/CardTitle'
import RoundButton from '../../elemets/RoundButton'
import IntegrationRow from './IntegrationRow'

// Errors
import { withErrorHandler } from '../../errors'

// Modals
import IntegrationModal from '../Modals/IntegrationModal'

// API
import { useApiSubmit } from '../../api'
import { useBaseUri } from '../../config/useConfig'

const AvailabilityIntegrationCard = React.memo(
  ({
    handleError,
    calendars,
    checkedList,
    onCheckIntegration,
    eventList,
    reload,
    onDayClick,
    selectedDay,
  }) => {
    // const classes = useStyles()
    const lattusAppBaseUri = useBaseUri('lattusApp')
    const [displayIntegrationModal, setDisplayIntegrationModal] =
      useState(false)

    const { submitted, submit, err, submitting } =
      useApiSubmit('addIntegration')

    const {
      submitted: submittedMicrosoft,
      submit: submitMicrosoft,
      err: errMicrosoft,
      submitting: submittingMicrosoft,
    } = useApiSubmit('addMicrosoftIntegration')

    // Microsoft integration listening
    useEffect(() => {
      if (submittedMicrosoft && !errMicrosoft && !submittingMicrosoft) {
        reload()
      } else if (errMicrosoft && !submittingMicrosoft) {
        handleError(errMicrosoft, true, {
          message: `There was a problem linking your account: ${errMicrosoft}.`,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submittedMicrosoft, errMicrosoft, submittingMicrosoft])

    // Google integration listening
    useEffect(() => {
      if (submitted && !err && !submitting) {
        reload()
      } else if (err && !submitting) {
        handleError(err, true, {
          message: `There was a problem linking your account: ${err}.`,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitted, err, submitting])

    const linkGoogleAccount = async (code) => {
      const body = {
        code,
      }

      submit({ body })
      setDisplayIntegrationModal(false)
    }

    const linkMicrosoftAccount = async (token, id, email) => {
      const body = {
        id,
        email,
        token,
      }

      submitMicrosoft({ body })
      setDisplayIntegrationModal(false)
    }

    return (
      <>
        <IntegrationModal
          show={displayIntegrationModal}
          onClose={() => setDisplayIntegrationModal(false)}
          linkGoogleAccount={linkGoogleAccount}
          linkMicrosoftAccount={linkMicrosoftAccount}
          microsoftRedirectURI={`${lattusAppBaseUri}/availability`}
        />
        <CardContainer>
          <Calendar
            className="w-100"
            selectedDays={
              eventList ? eventList.map((event) => event.startTime) : []
            }
            onDayClick={onDayClick}
            selectedDay={selectedDay}
          />
          <Divider />
          <Row noGutters className="justify-content-between mb-3">
            <CardTitle title="My Calendars" className="p-0" />
            <RoundButton
              icon="fa fa-plus-circle"
              onClick={() => setDisplayIntegrationModal(true)}
              type="GREEN"
            />
          </Row>
          {calendars.map((calendar, ix) => (
            <IntegrationRow
              key={ix}
              calendar={calendar}
              checked={checkedList.includes(calendar.email)}
              onCheck={onCheckIntegration}
            />
          ))}
        </CardContainer>
      </>
    )
  },
)

export default compose(withErrorHandler)(AvailabilityIntegrationCard)
