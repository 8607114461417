import React, { useCallback, useState, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { Row } from 'react-bootstrap'

import { useErrorHandler } from '../../errors'
import Modal from '../../elemets/Modal'
import CardTitle from '../../elemets/CardTitle'
import Icon from '../Icons/index'
import ImageDropZone from '../../elemets/ImageDropZone'
import ActionButton from '../../elemets/ActionButton'
import colors, { headings } from '../../theme/theme2'
import { isMobile } from '../../utils/common'
import { getAccessToken } from '../../auth0'
import CircleImage from '../../elemets/CircleImage'
import { useBaseUri } from '../../config/useConfig'
import { useWafToken } from '../../components/Context/WafTokenProvider'
import { useHistory } from 'react-router-dom'

const useStyles = createUseStyles({
  danger: {
    ...headings.paragraph,
    fontWeight: 'bold',
    color: colors.red,
  },
  text: {
    ...headings.paragraph,
    color: colors.dark,
    marginBottom: 55,
  },
  chrome: {
    color: colors.primary,
    '&:hover, &:active, &:focus': {
      color: colors.primary,
    },
    padding: 0,
  },
  body: {
    padding: 15,
    backgroundColor: colors.whiteBackground,
    margin: isMobile() ? '0 -14px' : 'unset',
  },
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  userIcon: {
    fontSize: 20,
    color: colors.primary,
    marginLeft: 5,
  },
  placeholderTitle: {
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.dark,
    marginBottom: 0,
  },
  placeholderSubtitle: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: colors.grey5,
    marginBottom: 0,
  },
  fileName: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: colors.grey5,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '65%',
    overflow: 'hidden',
  },
  circuleImage: {
    border: `1px dashed ${colors.grey2}`,
  },
  modal: {
    '& .modal-dialog': {
      maxWidth: isMobile() ? '100% !important' : '360px !important',
    },
  },
})

const UploadImageModal = ({
  show,
  onClose,
  imageType,
  endpoint,
  title = 'Upload Image',
  faIconClass = 'fa-camera',
  forcePageReload = false,
  ...rest
}) => {
  const handleError = useErrorHandler()
  const history = useHistory()
  const classes = useStyles()
  const lattusApiBaseUri = useBaseUri('lattusApi')
  const { wafToken } = useWafToken()

  // Refs
  const inputFileRef = useRef()

  // States
  const [value, setValue] = useState({ file: undefined, url: undefined })
  const [loading, setLoading] = useState(false)

  // Handlers
  const handleOnChange = useCallback((file) => {
    setValue(file)
  }, [])

  const handleSubmit = useCallback(async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append('file', value.file, value.file.name)

    const headers = new Headers()
    const token = await getAccessToken()

    headers.set('Authorization', `Bearer ${token}`)
    headers.set('x-aws-waf-token', wafToken)

    const requestOptions = {
      method: 'POST',
      headers,
      body: formData,
      redirect: 'follow',
    }

    const uri = `${lattusApiBaseUri}${endpoint}`

    fetch(uri, requestOptions)
      .then(() => {
        setLoading(false)
        onClose()
        if (forcePageReload) {
          history.go(0) // (alternative to window.location.reload()
        }
      })
      .catch((uploadErr) => {
        setLoading(false)
        handleError(uploadErr, true, {
          message: `There was a problem updating the ${imageType}.`,
        })
      })
  }, [
    handleError,
    onClose,
    value.file,
    lattusApiBaseUri,
    wafToken,
    endpoint,
    imageType,
    history,
    forcePageReload,
  ])

  const handleManualOpen = useCallback(() => {
    if (inputFileRef.current) {
      inputFileRef.current.click()
    }
  }, [inputFileRef])

  const handleOnClose = useCallback(() => {
    setValue({ file: undefined, url: undefined })
    onClose()
  }, [onClose])

  return (
    <Modal
      show={show}
      close={handleOnClose}
      titleComponent={
        <div className="d-flex align-items-center">
          <CardTitle title={title} />
          <Icon className={`fas ${faIconClass} ${classes.userIcon}`} />
        </div>
      }
      closeIcon
      closeOutside={false}
      dialogClassName={isMobile() ? classes.dialogMobile : ''}
      bodyClass={classes.body}
      modalClass={classes.modal}
      {...rest}
      footer={
        <Row
          noGutters
          className={`${
            isMobile() ? 'justify-content-end' : 'justify-content-between'
          } align-items - center`}
        >
          {!isMobile() && (
            <span className={classes.fileName}>
              {value.file ? value.file.name : 'JPG, PNG or GIF - 10mb max'}
            </span>
          )}
          <ActionButton
            height="34px"
            text="SAVE"
            onClick={handleSubmit}
            type="STRONG_GREEN"
            disabled={!value.file}
            loading={loading}
          />
        </Row>
      }
    >
      <ImageDropZone
        placeholder={
          <div className="text-center">
            <p className={classes.placeholderTitle}>
              {isMobile() ? 'Click to Select File' : 'Drag & Drop'}
            </p>
            {!isMobile() && (
              <p className={classes.placeholderSubtitle}>To Upload</p>
            )}
          </div>
        }
        errorMessage={
          <div className="text-center">
            <p className={classes.placeholderTitle}>Wrong File Type</p>
            <p className={classes.placeholderSubtitle}>JPG, PNG and GIF only</p>
          </div>
        }
        icon="fa fa-cloud-upload-alt"
        errorIcon="fas fa-exclamation-triangle"
        maxSize={1024 * 1024 * 10} // 10 mb
        value={value}
        onChange={handleOnChange}
        displayComponent={
          <CircleImage
            src={value.url}
            size={260}
            className={classes.circuleImage}
          />
        }
        inputFileRef={inputFileRef}
        onClick={isMobile() ? handleManualOpen : null}
      />

      <Row noGutters className="justify-content-center my-3">
        {isMobile() ? (
          <p className={classes.placeholderSubtitle}>JPG, PNG and GIF only</p>
        ) : (
          <ActionButton
            height="34px"
            text="Or, Select File"
            onClick={handleManualOpen}
            type="LIGHT_GREEN"
          />
        )}
      </Row>
    </Modal>
  )
}

export default UploadImageModal
