import './version'
import './wdyr'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-day-picker/dist/style.css'
import 'react-datepicker/dist/react-datepicker.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'typeface-work-sans'
import 'typeface-roboto-condensed'
import './setup'
import React from 'react'
// v16
import ReactDOM from 'react-dom'
// v18
// import { createRoot } from 'react-dom'
import App from './App'
import GlobalErrorBoundary from '@/errors/GlobalErrorBoundary'
import * as serviceWorker from './serviceWorker'
import './index.css'
import { makeGlobalLogger } from '@/logger/logger'

function StartApp() {
  return (
    <GlobalErrorBoundary>
      <App />
    </GlobalErrorBoundary>
  )
}

// v16
ReactDOM.render(<StartApp />, document.getElementById('root'))
// v18
// const root = createRoot(document.getElementById('root'))
// root.render(<StartApp />, document.getElementById('root'))

serviceWorker.unregister()
