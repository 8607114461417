import React from 'react'
import { PartialConversation } from '../../builder/state'
import { TopicId } from './data/Topic'
import { useConversationDetailsBuilder } from '../../builder/ConversationBuilder'
import AvailableTopics from './data/AvailableTopics'
import TopicsField from './TopicsField'

const selectTopics = (value: PartialConversation): TopicId[] | undefined =>
  value.topics

const SetTopics = (): JSX.Element => {
  const { value, setTopics, clearTopics } = useConversationDetailsBuilder()
  const topics = selectTopics(value)

  return (
    <AvailableTopics>
      <TopicsField value={topics} onChange={setTopics} onClear={clearTopics} />
    </AvailableTopics>
  )
}

export default SetTopics
