import React from 'react'
import { useConversationDetailsBuilder } from './builder/ConversationBuilder'
import ErrorMessage from './ErrorMessage'

type MissingFieldsErrorMessageProps = {
  classes?: {
    message?: string
  }
}

const MissingFieldsErrorMessage = ({
  classes: { message } = {},
}: MissingFieldsErrorMessageProps): JSX.Element | null => {
  const { submission } = useConversationDetailsBuilder()
  if (submission) {
    return null
  }

  return (
    <ErrorMessage
      classes={{ root: message }}
      message="One or more required fields have not been filled."
    />
  )
}

export default MissingFieldsErrorMessage
