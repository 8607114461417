import React, { useCallback, useEffect, useState } from 'react'

import { Row, Col } from 'react-bootstrap'

import { createUseStyles } from 'react-jss'
import ProfileInfo from './ProfileInfo'
import AvailabilityInfo from './AvailabilityInfo'
import ExperienceInfo from './ExperienceInfo'
import EducationInfo from './EducationInfo'
import TopicsInfo from './TopicsInfo'
import { isMobile, isTablet } from 'react-device-detect'
import BioInfo from './BioInfo'
import ScheduleModal from '../../components/Modals/Conversation/ScheduleModal'

const useStyles = createUseStyles({
  marginMobile: {
    marginBottom: 70,
  },
})

const PublicProfile = ({
  profile,
  onScheduled,
  skillSettings,
  startSchedulingOpen = false,
  profileAvailability = [],
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(!!startSchedulingOpen)
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <Row noGutters={isMobile}>
      <Col xs={12} md={isTablet ? 12 : 4}>
        <ProfileInfo profile={profile} />
        <AvailabilityInfo
          availabilities={profileAvailability}
          profile={profile}
        />
        <BioInfo profile={profile} />
        <ScheduleModal
          show={open}
          profileTopics={skillSettings}
          match={{
            token: profile.token,
            topics: skillSettings,
          }}
          reloadUpcoming={() => {
            // this is called on success; success does not automatically close the modal
            onScheduled()
            handleClose()
          }}
          onClose={handleClose}
        />
      </Col>
      <Col
        xs={12}
        md={isTablet ? 12 : 8}
        className={isMobile ? classes.marginMobile : ''}
      >
        <ExperienceInfo experiences={profile?.experience || []} />
        <EducationInfo educations={profile?.education || []} />
        <TopicsInfo topics={profile?.topics || []} />
      </Col>
    </Row>
  )
}
export default PublicProfile
