import React from 'react'
import GroupIcon from '../Icons/Group'
import { createUseStyles } from 'react-jss'

interface GroupLabelProps {
  name: string
}

const useStyles = createUseStyles(() => ({
  root: {
    color: '#343434',
  },
  icon: {
    cursor: 'initial',
  },
  label: {
    marginLeft: '10px',
  },
}))

const GroupLabel = ({ name }: GroupLabelProps): JSX.Element => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <GroupIcon classstyle={classes.icon} />
      <span className={classes.label}>Group: </span>
      <span>{name}</span>
    </div>
  )
}

export default GroupLabel
