import React, { useEffect, useCallback } from 'react'
import { Row } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import { withRouter } from 'react-router-dom'

// import ActionButton from '../elemets/ActionButton'
import LinkButton from '../elemets/LinkButton'
import colors, { headings } from '../theme/theme2'
import { Context } from './MobileNavVisibility'
import { useAuth0 } from '../auth0'

const useStyles = createUseStyles({
  container: {
    padding: '0 32px',
  },
  danger: {
    ...headings.paragraph,
    fontWeight: 'bold',
    color: colors.red,
  },
  text: {
    ...headings.paragraph,
    color: colors.dark,
    marginBottom: 55,
  },
  chrome: {
    color: colors.primary,
    '&:hover, &:active, &:focus': {
      color: colors.primary,
    },
    padding: 0,
  },
})

const ChromeiOSError = ({ history, location }) => {
  const context = React.useContext(Context)
  const { isAuthenticated } = useAuth0()

  useEffect(() => {
    context.setVisibility(false)
    return () => context.setVisibility(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context])

  const handleContinueInChrome = useCallback(() => {
    if (!isAuthenticated) {
      localStorage.setItem('useChrome', true)
    }
    if (location?.state?.from) {
      history.push(`${location?.state?.from}`, { from: '/' })
    } else {
      history.push('/', { from: '/' })
    }
  }, [history, location, isAuthenticated])

  const classes = useStyles()
  return (
    <div className={classes.container}>
      <p className={classes.danger}>Warning: Unsupported Browser Detected</p>
      <p className={classes.text}>
        It looks like you are using a browser that is not fully supported.
        Please use a destkop browser or open the app in Safari while using Mac
        iOS.
      </p>
      {/* <Row className="justify-content-center mb-3">
        <ActionButton text="LAUNCH IN SAFARI" type="STRONG_GREEN"/>
      </Row> */}
      <Row className="justify-content-center">
        <LinkButton
          className={classes.chrome}
          text="Continue with Chrome"
          onClick={handleContinueInChrome}
        />
      </Row>
    </div>
  )
}

export default withRouter(ChromeiOSError)
