import React from 'react'
import { createUseStyles } from 'react-jss'
import colors from '../theme/theme2'

const useStyles = createUseStyles({
  input: {
    width: '100%',
    padding: '0 16px',
    border: `1px solid ${colors.grey1}`,
    borderRadius: 4,
    color: colors.dark,
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: 16,
    '&:disabled': {
      color: colors.dark,
      cursor: 'default',
      border: '1px solid transparent',
      backgroundColor: 'transparent',
    },
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: colors.dark,
      opacity: 1,
    },
  },
  label: {
    width: '100%',
    padding: '0 16px',
    border: `1px solid transparent`,
    borderRadius: 4,
    color: colors.dark,
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 0,
  },
  title: {
    fontSize: 14,
    fontFamily: 'Lato',
    color: colors.grey2,
    paddingLeft: 16,
    marginBottom: 0,
    width: '100%',
  },
  borderError: {
    border: `1px solid ${colors.red}`,
  },
  errorText: {
    fontFamily: 'Lato',
    fontSize: 10,
    fontWeight: 500,
    color: colors.red,
  },
})

type Props = {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  editMode?: boolean
  placeholder?: string
  title?: string
  name: string
  containerStyle?: string
  inputStyle?: string
  error?: boolean
  errorMessage?: string
  type?: string
  dataTestId?: string
  isRequired?: boolean
}

function CustomInput({
  value,
  onChange,
  editMode = true,
  placeholder,
  title,
  name,
  containerStyle,
  inputStyle = '',
  error,
  errorMessage,
  type = 'text',
  dataTestId = '',
  isRequired = false,
}: Props): React.ReactElement {
  const classes = useStyles()

  return (
    <div className={containerStyle}>
      {title && (
        <label className={`${classes.title} mb-0 w-100`} htmlFor={name}>
          {title}
          {isRequired && editMode ? (
            <span className="text-danger"> *</span>
          ) : null}
        </label>
      )}
      {editMode ? (
        <input
          value={value}
          onChange={onChange}
          disabled={!editMode}
          className={`${classes.input} ${
            error ? classes.borderError : ''
          } ${inputStyle}`}
          name={name}
          placeholder={placeholder}
          type={type}
          data-testid={dataTestId}
        />
      ) : (
        <label
          className={`${classes.label} ${
            error ? classes.borderError : ''
          } ${inputStyle}`}
          data-testid={dataTestId + '--displayed-field'}
        >
          {value}
        </label>
      )}
      {errorMessage && error && (
        <label className={`${classes.errorText} mb-0 w-100`}>
          {errorMessage}
        </label>
      )}
    </div>
  )
}

export default CustomInput
