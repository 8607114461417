import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Row, Collapse } from 'react-bootstrap'
import moment from 'moment'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'

import colors, { responsive } from '../../../theme/theme2'
import { isMobile, isBrowserSupported } from '@/utils/common.js'
import KudosModal from '../Modals/KudosModal'
import { useErrorHandler } from '../../../errors'
import ChromeNotSupportedModal from '../../Modals/ChormeNotSupportedModal'
import ReScheduleModal from '../../Modals/Conversation/ReScheduleModal'
import SkillLevelIcon from '../../Icons/SkillLevel'
import { ratingValues } from '../../Forms/Ratings'
import {
  CALL_LAYOUT,
  CallLayout,
  ConvoStatus,
} from '../conversations.constants'

// Elements
import CardContainer from '../../../elemets/CardContainer'
import ActionButton from '../../../elemets/ActionButton'
import LinkButton from '../../../elemets/LinkButton'
import Divider from '../../../elemets/Divider'
import MeetNowButton from '../MeetNow/MeetNowButton'

// Modals
import ConfirmationModal from '../../Modals/ConfirmationModal'

// API
import { useApiSubmit } from '@/api'
import ScheduleModal from '../../Modals/Conversation/ScheduleModal'
import CircleName from '../../../elemets/CircleName'
import CircleImage from '../../../elemets/CircleImage'
import {
  ConversationFromApi,
  Participant,
} from '@/components/Conversations/conversation.types'
import { useIsMounted } from 'usehooks-ts'

const useStyles = createUseStyles({
  name: {
    fontFamily: 'Lato',
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.primary,
    marginBottom: 0,
  },
  position: {
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.dark,
    marginBottom: 0,
  },
  subtitle: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: colors.grey5,
    marginBottom: 5,
  },
  date: {
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.dark,
    marginBottom: 0,
    width: '100%',
  },
  title: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: colors.grey5,
    width: '100%',
    marginBottom: 0,
  },
  text: {
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.dark,
    width: '100%',
    marginBottom: 0,
  },
  row: {
    marginBottom: 25,
  },
  w45: {
    width: '45%!important',
  },
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  showDetailsButton: {
    color: colors.primary,
    '&:hover, &:active, &:focus': {
      color: colors.primary,
    },
  },
  cancelButton: {
    padding: '8px 0!important',
  },
  image: {},
  ...responsive,
})

type Props = {
  setSelectedLayout: (s: CallLayout) => void
  callLayout: CallLayout
  conversation: ConversationFromApi
  startTime: string
  endTime: string
  allowedBeforeTime: string
  allowedAfterTime: string
  me: Participant
  otherParticipant: Participant
  reload: () => any
}

const CallDetailsCard: FC<Props> = ({
  setSelectedLayout,
  callLayout,
  conversation,
  startTime,
  endTime,
  allowedBeforeTime,
  allowedAfterTime,
  me,
  otherParticipant,
  reload = () => {},
}: Props) => {
  const isMounted = useIsMounted()
  const classes = useStyles()
  const [displayModal, setDisplayModal] = useState(false)
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [displayRescheduleModal, setDisplayRescheduleModal] = useState(false)
  const [displayScheduleModal, setDisplayScheduleModal] = useState(false)
  const [displayKudosModal, setDisplayKudosModal] = useState(false)
  const [kudos, setKudos] = useState({
    comment: '',
    publishOnGroups: false,
  })
  const handleError = useErrorHandler()
  const history = useHistory()

  //
  // API cals
  //
  const {
    submitted,
    submit: setConversationStatus,
    submitting,
    err,
  } = useApiSubmit('setConversationStatus')

  //
  // Effects
  //
  useEffect(() => {
    if (submitted && !err && !submitting) {
      history.push('/conversations')
    } else if (err && !submitting) {
      if (isMounted()) {
        handleError(err, true, {
          message: 'There was a problem cancelling the conversation.',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, err, submitting, isMounted])

  //
  // Memoized values & handlers
  //
  const isDisabled = useMemo(() => {
    const now = moment.utc()
    return now.isAfter(allowedBeforeTime) && now.isBefore(allowedAfterTime)
  }, [allowedBeforeTime, allowedAfterTime])

  const Item = useCallback(
    ({ title, children, className = '' }) => (
      <div className={`${classes.row} ${className}`}>
        <label className={classes.title}>{title}</label>
        <label className={classes.text}>{children}</label>
      </div>
    ),
    [classes.row, classes.text, classes.title],
  )

  const handleKudosCall = useCallback(() => {
    if (isMounted()) {
      //sendKudos(kudos)
      setDisplayKudosModal(false)
    }
  }, [isMounted])

  const handleJoinCall = useCallback(() => {
    if (!isMounted()) return
    if (!isBrowserSupported()) {
      setDisplayModal(true)
    } else {
      setSelectedLayout(CALL_LAYOUT.IN)
    }
  }, [setSelectedLayout, isMounted])

  const handleCancelCall = useCallback(() => {
    setConversationStatus({
      params: { conversationId: conversation.id, status: 'cancelled' },
    })
  }, [conversation, setConversationStatus])

  const footerButton = useMemo(() => {
    if (!isMounted()) return null

    if (callLayout === CALL_LAYOUT.PRE) {
      return (
        <div className={`d-flex justify-content-end`}>
          <ActionButton
            onClick={handleJoinCall}
            text="JOIN CONVO"
            disabled={isDisabled}
            type="STRONG_GREEN"
          />
        </div>
      )
    } else if (callLayout === CALL_LAYOUT.POST) {
      return (
        <div className={`d-flex justify-content-start`}>
          <ActionButton
            height="37px"
            text="SCHEDULE AGAIN"
            onClick={() => setDisplayScheduleModal(true)}
            type="LIGHT_GREEN"
            className={`w-50 ml-3 ${classes.cancelButton}`}
          />
          <MeetNowButton
            className="w-50 ml-3"
            style={{ height: '37px' }}
            match={{
              ...otherParticipant,
              token: conversation.availabilityToken,
              topics: [
                {
                  skillId: conversation.skillId,
                  skillName: conversation.skillName,
                },
              ],
            }}
            buttonType={'LIGHT_ORANGE'}
            reloadUpcoming={() => {}}
          />
          {/*<ActionButton*/}
          {/*  height="37px"*/}
          {/*  text="SEND KUDOS"*/}
          {/*  onClick={() => setDisplayRescheduleModal(true)}*/}
          {/*  type="LIGHT_RED"*/}
          {/*  className="w-50 ml-3"*/}
          {/*/>*/}
        </div>
      )
    }
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callLayout, isDisabled, isMounted])

  return (
    <>
      {displayRescheduleModal && (
        <ReScheduleModal
          show={displayRescheduleModal}
          conversation={conversation}
          onClose={() => setDisplayRescheduleModal(false)}
          reloadUpcoming={reload}
        />
      )}
      <ScheduleModal
        show={displayScheduleModal}
        profileTopics={[
          { skillId: conversation.skillId, skillName: conversation.skillName },
        ]}
        match={{
          ...otherParticipant,
          token: conversation.availabilityToken,
          topics: [
            {
              skillId: conversation.skillId,
              skillName: conversation.skillName,
            },
          ],
        }}
        onClose={() => setDisplayScheduleModal(false)}
        reloadUpcoming={reload}
      />
      <ChromeNotSupportedModal
        show={displayModal}
        onClose={() => setDisplayModal(false)}
      />
      <ConfirmationModal
        show={displayConfirmationModal}
        onClose={() => setDisplayConfirmationModal(false)}
        footer={
          <div className="d-flex justify-content-end">
            <ActionButton
              text="CANCEL CONVERSATION"
              type="LIGHT_RED"
              onClick={handleCancelCall}
              loading={submitting}
            />
          </div>
        }
        title="Cancel Conversation?"
        content="I wish to cancel this conversation.  This time slot will now be marked as available. Please update your availability if you are no longer available at this time."
        closeIcon={true}
      />
      <ConfirmationModal
        show={displayKudosModal}
        onClose={() => setDisplayKudosModal(false)}
        closeIcon
        title={'Send Kudos'}
        content={
          <KudosModal
            kudos={kudos}
            setKudos={(message: any) => setKudos(message)}
          />
        }
        footer={
          <div className="d-flex justify-content-end">
            <ActionButton
              text="SAVE"
              className="w-40"
              onClick={handleKudosCall}
              type={'SAVE'}
            />
          </div>
        }
        dialogClassName={isMobile() ? classes.dialogMobile : ''}
      />
      <CardContainer
        footerDivider={
          callLayout === CALL_LAYOUT.PRE || callLayout === CALL_LAYOUT.POST
        }
        footer={footerButton as any}
      >
        <div className="pl-3">
          <Row noGutters className="align-items-center mb-4">
            {otherParticipant.avatar ? (
              <CircleImage
                className={classes.image}
                src={otherParticipant.avatar}
                size={75}
              />
            ) : (
              <CircleName
                id="avatar-name"
                size={75}
                text={
                  otherParticipant.firstName && otherParticipant.lastName
                    ? otherParticipant.firstName[0] +
                      otherParticipant?.lastName[0]
                    : ''
                }
                className={classes.image}
              />
            )}
            <div className="pl-2">
              <p className={classes.name}>
                {otherParticipant.firstName} {otherParticipant.lastName}
              </p>
              <p className={classes.position}>{otherParticipant.title}</p>
            </div>
          </Row>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <Item title="Scheduled Day" className="mb-3">
                {moment.utc(startTime).local().format('dddd, MMMM DD, YYYY')}
              </Item>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-12">
              <Item title="Scheduled Start Time" className="mb-3">
                {moment.utc(startTime).local().format('hh:mm A')}
              </Item>
            </div>
            <div className="col-6 col-md-12">
              <Item title="Scheduled End Time" className="mb-0">
                {moment.utc(endTime).local().format('hh:mm A')}
              </Item>
            </div>
          </div>
          <Row className="justify-content-end">
            <LinkButton
              text={showDetails ? 'Hide Details' : 'Show Details'}
              icon={showDetails ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
              onClick={() => setShowDetails((s) => !s)}
              iconRight
              className={classes.showDetailsButton}
            />
          </Row>
        </div>
        <Collapse in={showDetails}>
          <div id={'showDetails'}>
            <Divider className="mb-4" />
            <div className="pl-3">
              <Item title="Topic:">{conversation.skillGroupName}</Item>
              <Item title="Subtopic:">{conversation.skillName}</Item>
              {otherParticipant.summary && otherParticipant.summary !== '' && (
                <Item title="Why a Topic of Interest:">
                  {otherParticipant.summary || ''}
                </Item>
              )}
              {me && me.rating && (
                <Item title="My Rating:">
                  <>
                    {' '}
                    <SkillLevelIcon rating={me.rating} />
                    {me.rating} - {ratingValues[me.rating - 1].text}
                  </>
                </Item>
              )}
              {otherParticipant && otherParticipant.rating && (
                <Item title="Their Rating:">
                  <>
                    <SkillLevelIcon rating={otherParticipant.rating} />
                    {otherParticipant.rating} -{' '}
                    {ratingValues[otherParticipant.rating - 1].text}
                  </>
                </Item>
              )}
            </div>
            <div className="d-flex justify-content-between">
              {callLayout === CALL_LAYOUT.PRE && (
                <>
                  <ActionButton
                    onClick={() => setDisplayConfirmationModal(true)}
                    text="CANCEL CONVO"
                    type="LIGHT_RED"
                    className={`w-50 mr-3 ${classes.cancelButton}`}
                  />
                  {moment.utc(startTime).isAfter(moment()) &&
                    conversation.statusName === ConvoStatus.Scheduled && (
                      <ActionButton
                        height="37px"
                        text="RESCHEDULE"
                        onClick={() => setDisplayRescheduleModal(true)}
                        type="LIGHT_GREEN"
                        className="w-50 ml-3"
                      />
                    )}
                </>
              )}
            </div>
          </div>
        </Collapse>
      </CardContainer>
    </>
  )
}

export default CallDetailsCard
