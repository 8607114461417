import React from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

type ViewConversationDetailsProps = {
  conversationId: number
  disabled?: boolean
  className?: string
}

const ViewConversationDetails = ({
  conversationId,
  disabled = false,
  className,
}: ViewConversationDetailsProps): JSX.Element => {
  const history = useHistory()
  return (
    <Button
      className={className}
      disabled={disabled}
      onClick={() => {
        history.push(`/conversation/${conversationId}`)
      }}
    >
      Convo details
    </Button>
  )
}

export default ViewConversationDetails
