import { createContext } from 'react'

type SetVisibility = (visible: boolean) => void

const MobileNavVisibilityContext = createContext<{
  setVisibility: SetVisibility
}>({ setVisibility: () => {} })

export const Context = MobileNavVisibilityContext

export const Provider = MobileNavVisibilityContext.Provider
