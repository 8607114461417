import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  field: {
    paddingBottom: '24px',
    '&:last-child': {
      paddingBottom: 0,
    },
    '@media (min-width: 768px)': {
      padding: 0,
    },
  },
  required: {
    '&:after': {
      content: '"*"',
    },
  },
  label: {
    padding: '0',
    margin: '0',
  },
  multiLine: {
    alignSelf: 'flex-start',
    marginTop: 7,
  },
})

type FieldProps = {
  label: string
  labelId: string
  required?: boolean
  multiLine?: boolean
  children: ReactNode
}

const Field = ({
  label,
  labelId,
  required = false,
  multiLine = false,
  children,
}: FieldProps): JSX.Element => {
  const classes = useStyles()
  return (
    <>
      <label
        id={labelId}
        className={clsx(classes.label, {
          [classes.required]: required,
          [classes.multiLine]: multiLine,
        })}
      >
        {label}
      </label>
      <div className={classes.field}>{children}</div>
    </>
  )
}

export default Field
