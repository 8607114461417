import React from 'react'
import { createUseStyles } from 'react-jss'
import colors from '../theme/theme2'

const CircleName = ({ size = 40, text, className, id = '' }) => {
  const useStyles = createUseStyles({
    circle: {
      height: size,
      width: size,
      borderRadius: size / 2,
      backgroundColor: colors.primary100,
      color: colors.primary,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Lato',
      fontSize: 14,
      fontWeight: 500,
    },
  })
  const classes = useStyles()

  return (
    <div id={id} className={`${classes.circle} ${className}`}>
      {text}
    </div>
  )
}

export default CircleName
