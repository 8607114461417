import React from 'react'
import SelectControl, { Option } from '../controls/SelectControl'
import Field from './Field'

type SelectFieldProps<T extends string> = {
  label: string
  labelId: string
  onChange: (value: T) => void
  options: Option<T>[]
  disabled?: boolean
  required?: boolean
  value?: T
}

const SelectField = function SelectField<T extends string>({
  label,
  labelId,
  onChange,
  options,
  disabled,
  required,
  value,
}: SelectFieldProps<T>): JSX.Element {
  return (
    <Field label={label} labelId={labelId} required={required}>
      <SelectControl
        labelId={labelId}
        onChange={onChange}
        options={options}
        disabled={disabled}
        value={value}
      />
    </Field>
  )
}

export default SelectField
