import React from 'react'
import { createUseStyles } from 'react-jss'
import colors from '../theme/theme2'

const CustomCheckbox = ({
  size = 20,
  backgroundColor = '',
  iconColor = '',
  checked,
  onClick,
  dataTestId = '',
}) => {
  const useStyles = createUseStyles({
    box: {
      minWidth: size,
      minHeight: size,
      borderRadius: 4,
      border: 'solid 1px #c0c0bf',
      backgroundColor: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    icon: {
      color: colors.primary,
      fontSize: 13,
    },
  })
  const classes = useStyles()

  return (
    <div
      className={`${classes.box} ${backgroundColor}`}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {checked && (
        <i
          className={`fa fa-check ${classes.icon} ${iconColor}`}
          data-testid="greencheck"
        />
      )}
    </div>
  )
}

export default CustomCheckbox
