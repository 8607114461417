import React, { useCallback, useEffect, useMemo, useState } from 'react'
import compose from 'just-compose'
import { Dropdown } from '../Forms'
import { withErrorHandler } from '../../errors'
import { withNotifications } from '../../notifications'
import { withRouter } from 'react-router-dom'
import colors, { headings } from '../../theme/theme2'
import { useApiSubmit } from '../../api'
import { Col, Row } from 'react-bootstrap'
import ActionButton from '../../elemets/ActionButton'
import { createUseStyles } from 'react-jss'
import CustomTextArea from '../../elemets/CustomTextArea'
import Ratings from '../Forms/Ratings'
import { isMobile } from '../../utils/common'
import { useQuery } from '@apollo/client'
import { getTopics } from '../../data/topics/topics.query'
import LoaderPage from '../../elemets/LoaderPage'
import AutoSuggestTopic from './AutoSuggestTopic'
import { useCurrentUser } from '../Context/CurrentUser'
import AddTopicModal from './AddTopicModal'
import LinkButton from '../../elemets/LinkButton'

const useStyles = createUseStyles({
  container: {
    marginLeft: '12rem!important',
  },
  label: {
    ...headings.text,
    fontSize: 14,
    fontWeight: 'bold',
    '& *': {
      fontSize: '14px!important',
      fontWeight: 'bold!important',
    },
  },
  red: {
    color: '#d8013b!important',
  },
  interest: {
    minHeight: 64,
  },
  experienceDropdown: {
    '& select': {
      padding: 2,
    },
  },
  autoSuggest: {
    '& .react-autosuggest__container': {
      position: 'relative',
    },
    '& .react-autosuggest__input': {
      width: '100%',
      height: 30,
      padding: '0px 20px',
      border: ' 1px solid gainsboro',
      borderRadius: 4,
      marginBottom: 10,
    },
    '& .react-autosuggest__input--focused': {
      outline: 'none',
    },
    '& .react-autosuggest__suggestions-container--open': {
      display: 'block',
      position: 'absolute',
      overflowY: 'scroll',
      maxHeight: 400,
      top: 31,
      width: '100%',
      border: '1px solid #aaa',
      borderRadius: 4,
      padding: 10,
      backgroundColor: '#fff',
      fontSize: 16,
      zIndex: 1000,
    },
    '& .react-autosuggest__suggestions-list': {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    '& .react-autosuggest__suggestion': {
      cursor: 'pointer',
      padding: '10px 20px',
    },
    '& .react-autosuggest__suggestion--highlighted': {
      backgroundColor: '#ddd',
    },
    '& .react-autosuggest__section-container': {
      borderTop: '1px dashed #ccc',
    },
    '& .react-autosuggest__section-container--first': {
      borderTop: 0,
    },
    '& .react-autosuggest__section-title': {
      padding: '10px 0 0 10px',
      fontSize: 12,
      color: '#777',
    },
    '& .highlight': {
      fontWeight: 'bold',
      color: colors.primary,
    },
  },
  suggest: {
    alignSelf: 'center',
    color: colors.primary,
    marginBottom: 10,
    '&:hover': {
      color: colors.primary,
    },
    '& > i': {
      color: colors.white,
      backgroundColor: colors.primary,
      borderRadius: '50%',
      padding: 3.33,
      width: '20px',
      height: '20px',
    },
  },
  topicRow: {
    justifyContent: 'space-between',
    paddingLeft: 15,
  },
  topicLabel: {
    alignSelf: 'flex-end',
  },
})

const TopicForm = ({ setAddTopic, handleError }) => {
  const classes = useStyles()
  const [displayModal, setDisplayModal] = useState(false)
  const [formState, setFormState] = useState({
    skillId: 0,
    rating: 1,
    years: 1,
    summary: '',
    isMentor: 0,
  })
  const { currentUser, reload } = useCurrentUser()
  const {
    submitted,
    submit: addTopicToUser,
    submitting,
    err,
  } = useApiSubmit('addTopic')

  const { loading, data: topics } = useQuery(getTopics)

  useEffect(() => {
    if (submitted && !err && !submitting) {
      setAddTopic(false)
      reload()
    } else if (err && !submitting) {
      handleError(err, true, {
        message: 'There was a problem adding the topic.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, err, submitting])

  const handleAddTopic = useCallback(() => {
    if (!currentUser?.id) return
    addTopicToUser({
      params: {
        profileId: currentUser.id,
      },
      body: {
        rating: formState.rating,
        skillId: Number(formState.skillId),
        summary: formState.summary || '',
        years: parseInt(formState.years || 1),
        isMentor: formState.isMentor === '1',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, formState])

  const suggestedTopics = useMemo(() => {
    if (!currentUser?.id) return
    return topics
      ? topics['skillgroups']
          .map((topic) => {
            return {
              ...topic,
              skills: topic.skills.filter((subtopic) => {
                return !currentUser.topics.some(
                  (topic) => topic.skillId === subtopic.skillId,
                )
              }),
            }
          })
          .filter((topic) => {
            return topic.skills.length > 0
          })
      : []
  }, [currentUser, topics])

  const handleSetFormState = useCallback(
    (value) => {
      setFormState({ ...formState, skillId: value.skillId })
    },
    [formState],
  )

  return (
    <>
      <AddTopicModal show={displayModal} setDisplayModal={setDisplayModal} />
      <div className={`container`}>
        {loading && <LoaderPage />}
        <Row>
          <Col xs={{ span: 10, offset: 1 }} className={`mb-1 ${classes.label}`}>
            <Row className={classes.topicRow}>
              <label htmlFor="groupId" className={classes.topicLabel}>
                Topic<span className={classes.red}>*</span>
              </label>
              <LinkButton
                icon="fas fa-plus"
                text="Suggest a Topic"
                className={classes.suggest}
                onClick={() => setDisplayModal(true)}
              />
            </Row>
            <AutoSuggestTopic
              handleSetFormState={handleSetFormState}
              suggestedTopics={suggestedTopics}
              setDisplayModal={() => setDisplayModal(true)}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 10, offset: 1 }}
            md={{ span: 5, offset: 1 }}
            className={classes.label}
          >
            <label htmlFor="rating">
              Self-rating experience level<span className={classes.red}>*</span>
            </label>
            <Ratings
              name="rating"
              value={formState.rating}
              onChange={(e) => {
                setFormState({ ...formState, rating: e.target.value })
              }}
              maxWidth={'100%'}
              required
            />
          </Col>
          <Col
            xs={{ span: 10, offset: 1 }}
            md={{ span: 5, offset: 0 }}
            className={classes.label}
          >
            <Row>
              <Col xs={6}>
                <label htmlFor="isMentor">
                  Mentor or Mentee<span className={classes.red}>*</span>
                </label>
                <Dropdown
                  name="isMentor"
                  value={formState.isMentor}
                  options={[
                    { id: '0', name: 'Mentee' },
                    { id: '1', name: 'Mentor' },
                  ]}
                  text={formState.isMentor}
                  onChange={(e) => {
                    setFormState({ ...formState, isMentor: e.target.value })
                  }}
                  maxwidth={'100px'}
                />
              </Col>
              <Col xs={6}>
                <label htmlFor="years">
                  {isMobile() ? 'Experience' : 'Years of experience'}
                  <span className={classes.red}>*</span>
                </label>
                <Dropdown
                  name="years"
                  value={formState.years}
                  options={[...Array(100).keys()].map((index) => {
                    return { id: index, name: index }
                  })}
                  text={formState.years}
                  onChange={(e) => {
                    setFormState({ ...formState, years: e.target.value })
                  }}
                  maxwidth={'65px'}
                  className={classes.experienceDropdown}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 10, offset: 1 }}>
            <label className={classes.label} htmlFor="summary">
              Why a topic of interest?
            </label>
            <CustomTextArea
              name="summary"
              value={formState.summary}
              onChange={(e) => {
                setFormState({ ...formState, summary: e.target.value })
              }}
              placeholder={
                !(formState.isMentor === '1')
                  ? 'What are you looking to learn?'
                  : 'What do you want to share?'
              }
              maxLength={500}
              className={classes.interest}
            />
          </Col>
          <Col
            xs={{ span: 10, offset: 1 }}
            className={`d-flex justify-content-end`}
          >
            <ActionButton
              text="SAVE"
              onClick={handleAddTopic}
              type="STRONG_GREEN"
              disabled={
                formState.skillId === 0 || formState.summary.length > 500
              }
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default compose(
  withErrorHandler,
  withNotifications,
  withRouter,
)(TopicForm)
