import React, { useMemo, useContext, ReactNode, createContext } from 'react'
import {
  PartialConversation,
  useConversationsBuilderState,
  setDate,
  setDuration,
  setStartTime,
  setParticipant,
  clearParticipant,
  setMethod,
  setPrimaryRelationship,
  setGoal,
  setNotes,
  clearTopics,
  setTopics,
} from './state'
import { Conversation } from '../../Conversation'
import { ParticipantId } from '../fields/participant/data/Participant'
import { MethodKey } from '../fields/method/data/Method'
import { DurationKey } from '../fields/duration/data/Duration'
import { RelationshipKey } from '../fields/relationship/data/Relationship'
import { TopicId } from '../fields/topics/data/Topic'

type ContextValue = {
  clearParticipant: () => void
  clearTopics: () => void
  setDate: (year: number, month: number, day: number) => void
  setStartTime: (hours: number, minutes: number) => void
  setParticipant: (id: ParticipantId) => void
  setMethod: (key: MethodKey) => void
  setDuration: (key: DurationKey) => void
  setPrimaryRelationship: (key: RelationshipKey) => void
  setGoal: (goal: string) => void
  setNotes: (notes: string) => void
  setTopics: (topics: TopicId[]) => void
  value: PartialConversation
  submission: Conversation | null
}

const notImplemented = () => {
  throw new Error('not implemented')
}

export const Context = createContext<ContextValue>({
  clearParticipant: notImplemented,
  clearTopics: notImplemented,
  setDate: notImplemented,
  setStartTime: notImplemented,
  setParticipant: notImplemented,
  setMethod: notImplemented,
  setDuration: notImplemented,
  setPrimaryRelationship: notImplemented,
  setGoal: notImplemented,
  setNotes: notImplemented,
  setTopics: notImplemented,
  value: {},
  submission: null,
})

type ConversationBuilderProps = {
  initialValue: PartialConversation
  children: ReactNode
}

const toConversation = (value: PartialConversation): Conversation | null => {
  const { date } = value
  if (!date) {
    return null
  }

  const { participant } = value
  if (!participant) {
    return null
  }

  const { method } = value
  if (!method) {
    return null
  }

  const { duration } = value
  if (!duration) {
    return null
  }

  const { primaryRelationship } = value
  if (!primaryRelationship) {
    return null
  }

  const { topics } = value
  if (!topics || topics.length < 1) {
    return null
  }

  // optional values
  const { startTime, goal, notes } = value
  return {
    participant,
    date,
    startTime,
    method,
    duration,
    primaryRelationship,
    goal,
    notes,
    topics,
  }
}

const ConversationBuilder = ({
  initialValue,
  children,
}: ConversationBuilderProps): JSX.Element => {
  const [state, dispatch] = useConversationsBuilderState(initialValue)
  const contextValue = useMemo(
    (): ContextValue => ({
      clearParticipant: () => dispatch(clearParticipant()),
      clearTopics: () => dispatch(clearTopics()),
      setDate: (year: number, month: number, day: number) =>
        dispatch(setDate(year, month, day)),
      setStartTime: (hours: number, minutes: number) =>
        dispatch(setStartTime(hours, minutes)),
      setParticipant: (participantId: ParticipantId) =>
        dispatch(setParticipant(participantId)),
      setMethod: (key: MethodKey) => dispatch(setMethod(key)),
      setDuration: (key: DurationKey) => dispatch(setDuration(key)),
      setPrimaryRelationship: (key: RelationshipKey) =>
        dispatch(setPrimaryRelationship(key)),
      setGoal: (goal: string) => dispatch(setGoal(goal)),
      setNotes: (notes: string) => dispatch(setNotes(notes)),
      setTopics: (topics: TopicId[]) => dispatch(setTopics(topics)),
      value: state.value,
      submission: toConversation(state.value),
    }),
    [state.value, dispatch],
  )

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

export default ConversationBuilder

export const useConversationDetailsBuilder = (): ContextValue =>
  useContext(Context)
