import React from 'react'
// import { captureException, getClient, withScope } from '@sentry/react'
import { datadogRum } from '@datadog/browser-rum'
import LoaderPage from '../elemets/LoaderPage'

interface GlobalErrorBoundaryState {
  hasError: boolean
}

class GlobalErrorBoundary extends React.Component<
  React.PropsWithChildren<{}>,
  GlobalErrorBoundaryState
> {
  constructor(props: React.PropsWithChildren<{}>) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_: Error): GlobalErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return { hasError: true }
  }

  componentDidCatch(err: Error, info: React.ErrorInfo): void {
    // Datadog error handling
    const renderingError = new Error(err.message)
    renderingError.name = `ReactRenderingError`
    renderingError.stack = info?.componentStack ?? ''
    renderingError.cause = err
    datadogRum.addError(renderingError)

    // // Sentry error handling
    // if (getClient()) {
    //   withScope((scope) => {
    //     scope.setExtras({ componentStack: info.componentStack })
    //     captureException(err)
    //   })
    // }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <LoaderPage>
          <h1>Something went wrong.</h1>
        </LoaderPage>
      )
    }

    return this.props.children
  }
}

export default GlobalErrorBoundary
