import React, { useCallback, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import compose from 'just-compose'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

// Elements
import CardContainer from '../../elemets/CardContainer'
import SquareButton from '../../elemets/SquareButton'
import CardTitle from '../../elemets/CardTitle'
import CustomInput from '../../elemets/CustomInput'
import LinkButton from '../../elemets/LinkButton'
import Divider from '../../elemets/Divider'

// Components
import ExperienceModal from './ExperienceModal'

// Theme
import colors, { responsive } from '../../theme/theme2'

// Utils
import { isMobile } from '../../utils/common'

const useStyles = createUseStyles({
  experienceRow: {
    marginBottom: 22,
  },
  edit: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addButton: {
    color: colors.primary,
    '&:hover, &:active, &:focus': {
      color: colors.primary,
    },
    paddingRight: 0,
  },
  ...responsive,
})

const ExperienceCard = ({ user, reload }) => {
  const classes = useStyles()
  const [displayModal, setDisplayModal] = useState(false)
  const [selectedExperience, setSelectedExperience] = useState()

  const handleAddExperience = useCallback(() => {
    setSelectedExperience(undefined)
    setDisplayModal(true)
  }, [])

  const handleOpenEdit = useCallback((experience) => {
    setSelectedExperience(experience)
    setDisplayModal(true)
  }, [])

  const onFinish = useCallback(() => {
    setDisplayModal(false)
    setSelectedExperience(undefined)
    reload()
  }, [reload])

  const ExperienceRow = useCallback(
    ({ experience, divider, dataTestId }) => (
      <>
        <div className={classes.regular} data-testid={dataTestId}>
          <Row className={classes.experienceRow}>
            <Col className="pr-0">
              <CustomInput
                inputStyle="pr-0"
                value={experience.companyName}
                title="Company"
                editMode={false}
              />
            </Col>
            <Col className="pr-0">
              <CustomInput
                inputStyle="pr-0"
                value={experience.title}
                title="Title / Position"
                editMode={false}
              />
            </Col>
            <Col className="pr-0">
              <CustomInput
                inputStyle="pr-0"
                value={`${moment(experience.dateStart).format('MM-YYYY')} to ${
                  experience.dateEnd
                    ? moment(experience.dateEnd).format('MM-YYYY')
                    : 'Present'
                }`}
                title="Years"
                editMode={false}
              />
            </Col>
            <Col xs={2} className={classes.edit}>
              <SquareButton onClick={() => handleOpenEdit(experience)}>
                <i className="material-icons">edit</i>
              </SquareButton>
            </Col>
          </Row>
        </div>
        <div className={classes.mobile} data-testid={dataTestId}>
          <div className="d-flex">
            <Col xs={10} className="p-0">
              <CustomInput
                inputStyle="pr-0"
                value={experience.companyName}
                title="Company"
                editMode={false}
                containerStyle="mb-3"
              />
              <CustomInput
                inputStyle="pr-0"
                value={experience.title}
                title="Title / Position"
                editMode={false}
                containerStyle="mb-3"
              />
              <CustomInput
                inputStyle="pr-0"
                value={`${moment(experience.dateStart).format('MM-YYYY')} to ${
                  experience.dateEnd
                    ? moment(experience.dateEnd).format('MM-YYYY')
                    : 'Present'
                }`}
                title="Years"
                editMode={false}
                containerStyle="mb-3"
              />
            </Col>
            <Col xs={2}>
              <SquareButton onClick={() => handleOpenEdit(experience)}>
                <i className="material-icons">edit</i>
              </SquareButton>
            </Col>
          </div>
        </div>
        {divider && <Divider />}
      </>
    ),
    [
      classes.edit,
      classes.experienceRow,
      classes.mobile,
      classes.regular,
      handleOpenEdit,
    ],
  )

  return (
    <>
      <ExperienceModal
        show={displayModal}
        experience={selectedExperience}
        onClose={() => {
          setSelectedExperience(undefined)
          setDisplayModal(false)
        }}
        user={user}
        onFinish={onFinish}
      />
      <CardContainer
        header={
          <div className="d-flex justify-content-between align-items-center">
            <CardTitle title="Experience" />
            <LinkButton
              icon="las la-plus-circle"
              text="Add Experience"
              className={classes.addButton}
              onClick={handleAddExperience}
            />
          </div>
        }
        headerDivider={isMobile()}
        dataTestId={'my-profile__experience-card-container'}
      >
        {user.experience?.length > 0 &&
          user.experience?.map((experience, index) => (
            <ExperienceRow
              key={experience.id}
              experience={experience}
              divider={isMobile() && index < user.experience.length - 1}
              dataTestId="experience-card-container__experience-row"
            />
          ))}
      </CardContainer>
    </>
  )
}

export default compose(withRouter)(ExperienceCard)
