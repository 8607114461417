import React from 'react'
import { useRelationships } from './data/RelationshipsProvider'
import { RelationshipKey } from './data/Relationship'
import SelectField from '../SelectField'

type PrimaryRelationshipFieldProps = {
  value?: RelationshipKey
  onChange: (value: RelationshipKey) => void
}

const PrimaryRelationshipField = ({
  value,
  onChange,
}: PrimaryRelationshipFieldProps): JSX.Element => {
  const { relationships, ready } = useRelationships()
  return (
    <SelectField
      label="Relationship of the person you conversed with"
      labelId="primaryRelationship"
      required
      value={value}
      onChange={onChange}
      options={relationships}
      disabled={!ready}
    />
  )
}

export default PrimaryRelationshipField
