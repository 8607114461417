import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useContext,
} from 'react'
import { Button } from 'react-bootstrap'
import compose from 'just-compose'
import { createUseStyles } from 'react-jss'
import { withRouter } from 'react-router-dom'

import { withErrorHandler } from '../../errors'
import { NotificationsContext } from '../../notifications/hooks/NotificationsProvider'

// Elements
import CustomInput from '../../elemets/CustomInput'
import ActionButton from '../../elemets/ActionButton'
import CardTitle from '../../elemets/CardTitle'
import CardContainer from '../../elemets/CardContainer'

// Api
import { useApiSubmit } from '../../api/index'

// Theme
import colors, { headings, buttonStyles } from '../../theme/theme2'

// Utils
import { isMobile } from '../../utils/common'

const useStyles = createUseStyles({
  checked: {
    color: colors.primary,
  },
  unchecked: {
    color: colors.grey2,
  },
  conditionTitle: {
    ...headings.title,
    fontSize: 14,
  },
  conditionText: {
    fontSize: 14,
    color: colors.dark,
    fontFamily: 'Lato',
  },
  conditionContainer: {
    marginLeft: '20%',
    marginTop: 25,
    marginBottom: 25,
  },
  close: {
    ...buttonStyles.close,
  },
})

const ResetPassword = ({ handleError, history }) => {
  const classes = useStyles()
  const [formState, setFormState] = useState({
    password: '',
    repeatPassword: '',
  })
  const { pushNotification } = useContext(NotificationsContext)
  const [errorPasswordMatch, setErrorPasswordMatch] = useState(false)
  const {
    submitted,
    submit: changePassword,
    err: updateErr,
    submitting,
  } = useApiSubmit('changePasswordV1')

  useEffect(() => {
    if (!isMobile()) {
      history.push('/profile')
    }
  }, [history])

  const close = useCallback(() => {
    history.push('/profile')
  }, [history])

  useEffect(() => {
    if (submitted && !updateErr && !submitting) {
      pushNotification({
        level: 'success',
        subject: 'Success!',
        message: 'Password updated successfully.',
        timeout: 5000,
      })
      close()
    } else if (updateErr && !submitting) {
      handleError(updateErr, true, {
        message: 'There was a problem updating your profile.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, updateErr, submitting])

  const handleInput = useCallback(
    (e) => {
      const value = e.target.value
      const name = e.target.name
      if (errorPasswordMatch) {
        setErrorPasswordMatch(false)
      }
      setFormState((f) => ({
        ...f,
        [name]: value,
      }))
    },
    [errorPasswordMatch],
  )

  const Condition = useCallback(
    ({ text, checked, className }) => (
      <div className={className}>
        <i
          className={`fas fa-check-circle mr-2 ${
            checked ? classes.checked : classes.unchecked
          }`}
        ></i>
        <label className={classes.conditionText}>{text}</label>
      </div>
    ),
    [classes.checked, classes.conditionText, classes.unchecked],
  )

  const handleReset = useCallback(() => {
    if (formState.password === formState.repeatPassword) {
      changePassword({
        body: {
          newPassword: formState.password,
          confirmPassword: formState.repeatPassword,
        },
      })
    } else {
      setErrorPasswordMatch(true)
    }
  }, [changePassword, formState.password, formState.repeatPassword])

  const atLeast8Chars = useMemo(
    () => formState.password.length >= 8,
    [formState.password],
  )
  const hasLowerCase = useMemo(
    () => /[a-z]/.test(formState.password),
    [formState.password],
  )
  const hasUpperCase = useMemo(
    () => /[A-Z]/.test(formState.password),
    [formState.password],
  )
  const hasNumber = useMemo(
    () => /\d/.test(formState.password),
    [formState.password],
  )
  const hasSpecialChar = useMemo(
    () => /[!@#$%^&*(),.?":{}|<>]/.test(formState.password),
    [formState.password],
  )
  const atLeast3Cond = useMemo(() => {
    let count = 0
    if (hasLowerCase) count += 1
    if (hasUpperCase) count += 1
    if (hasNumber) count += 1
    if (hasSpecialChar) count += 1

    return count > 2
  }, [hasLowerCase, hasUpperCase, hasNumber, hasSpecialChar])

  const isFormValid = useMemo(
    () => atLeast8Chars && atLeast3Cond,
    [atLeast8Chars, atLeast3Cond],
  )

  return (
    <CardContainer
      header={
        <div className="d-flex justify-content-between">
          <CardTitle title="Reset Password" />
          <Button className={classes.close} onClick={close}>
            <i className="far fa-times-circle"></i>
          </Button>
        </div>
      }
      footer={
        <div className="d-flex justify-content-end">
          <ActionButton
            text="RESET PASSWORD"
            onClick={handleReset}
            type="STRONG_GREEN"
            disabled={!isFormValid}
            loading={submitting}
          />
        </div>
      }
    >
      <div className="mt-4">
        <CustomInput
          name="password"
          title="New Password"
          value={formState.password}
          onChange={handleInput}
          type="password"
        />
        <CustomInput
          name="repeatPassword"
          title="Confirm New Password"
          value={formState.repeatPassword}
          onChange={handleInput}
          type="password"
          error={errorPasswordMatch}
          errorMessage="Passwords Don’t Match"
        />
        <div className={classes.conditionContainer}>
          <label className={classes.conditionTitle}>
            Your password must contain:
          </label>
          <Condition text="At least 8 characters" checked={atLeast8Chars} />
          <Condition
            text="At least 3 of the following:"
            checked={atLeast3Cond}
          />
          <Condition
            className="ml-4"
            text="Lower case letters (a-z)"
            checked={hasLowerCase}
          />
          <Condition
            className="ml-4"
            text="Upper case letters (A-Z)"
            checked={hasUpperCase}
          />
          <Condition className="ml-4" text="Number (0-9)" checked={hasNumber} />
          <Condition
            className="ml-4"
            text="Special characters"
            checked={hasSpecialChar}
          />
        </div>
      </div>
    </CardContainer>
  )
}

export default compose(withErrorHandler, withRouter)(ResetPassword)
