import React, { useCallback, useEffect, useState } from 'react'
import { useApiSubmit } from '../../../api'
import moment from 'moment/moment'
import ActionButton from '../../../elemets/ActionButton'
import { isMobile } from '../../../utils/common'
import { Col, Row } from 'react-bootstrap'
import CustomSelect from '../../../elemets/CustomSelect'
import { createUseStyles } from 'react-jss'
import colors, { headings } from '../../../theme/theme2'

const useStyles = createUseStyles({
  body: {
    margin: 0,
    padding: 0,
  },
  label: {
    ...headings.title,
    fontSize: 12,
  },
  title: {
    ...headings.title,
  },
  dateRow: {
    padding: isMobile() ? '18px 9px 14px 9px' : '13px 15px 13px 31px',
    width: '100%',
  },
  time: {
    ...headings.paragraph,
    fontSize: 12,
    color: colors.dark,
    fontWeight: 'bold',
  },
  subtitle: {
    ...headings.paragraph,
    color: colors.dark,
    fontWeight: 'bold',
  },
  bgGrey: {
    backgroundColor: colors.grey1,
  },
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  availabilitiesMobile: {
    width: '100%',
  },
  modal: {
    '& .modal-dialog': {
      minWidth: 730,
    },
  },
  select: {
    width: '100%',
  },
  arrow: {
    minWidth: 10,
  },
  icon: {
    '& i': {
      '&:before': {
        color: colors.primary,
      },
    },
  },
})

export const AvailabilityRow = ({
  index,
  match,
  availability,
  topics,
  reloadUpcoming,
  handleError,
  pushNotification,
}) => {
  const classes = useStyles()
  const [selectedAvailability, setSelectedAvailability] = useState(false)
  const [skillId, setSkillId] = useState(0)

  const {
    submitted,
    submit: scheduleConversation,
    err,
    submitting,
  } = useApiSubmit('scheduleConversation')

  useEffect(() => {
    if (submitted && !submitting && !err) {
      pushNotification({
        level: 'success',
        subject: 'Conversation Scheduled.',
        timeout: 10,
      })
      setSelectedAvailability(true)
      reloadUpcoming()
    } else if (!submitting && err) {
      handleError(err, true, {
        message: 'Unable to scheduled a conversation.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, submitting, err])

  useEffect(() => {
    if (topics) {
      setSkillId(topics[0])
    }
  }, [topics])

  const handleSchedule = useCallback(
    (availability) => {
      if (match?.token && skillId) {
        const query = {
          token: match.token,
        }
        const body = {
          skillId: parseInt(skillId.value, 10),
          startTime: moment(availability.startTime).utc(),
        }
        scheduleConversation({ query, body })
      }
    },
    [scheduleConversation, skillId, match],
  )

  const renderButton = useCallback(
    (availability) => {
      return selectedAvailability ? (
        <ActionButton
          height="34px"
          text="CONVO SCHEDULED"
          type="LIGHT_GREEN"
          className="text-nowrap"
          disabled
        />
      ) : (
        <ActionButton
          height="34px"
          text="SCHEDULE"
          onClick={() => handleSchedule(availability)}
          type="LIGHT_GREEN"
          className="text-nowrap"
        />
      )
    },
    [selectedAvailability, handleSchedule],
  )

  const handleSelect = useCallback((key, value) => {
    setSkillId(value)
  }, [])

  return (
    <div
      key={`${availability.id}_${availability.startTime}`}
      className={`${classes.dateRow} ${index % 2 ? '' : classes.bgGrey} ${
        isMobile() ? classes.availabilitiesMobile : ''
      }`}
    >
      {!isMobile() ? (
        <Row className="align-items-center">
          <Col xs={3} className={classes.time}>
            {moment(availability.startTime).format('dddd, MMM DD, YYYY')}
            <br />
            {moment(availability.startTime).format('hh:mm A')} to{' '}
            {moment(availability.endTime).format('hh:mm A')}
          </Col>
          <Col xs={6}>
            <CustomSelect
              options={topics}
              className={classes.select}
              value={skillId}
              onSelect={handleSelect}
            />
          </Col>
          <Col xs={3} className="d-flex justify-content-end">
            {renderButton(availability)}
          </Col>
        </Row>
      ) : (
        <Row className="align-items-center">
          <Col xs={12} className={`mb-3`}>
            <CustomSelect
              options={topics}
              className={classes.select}
              value={skillId}
              onSelect={handleSelect}
            />
          </Col>
          <Col xs={7} className={classes.time}>
            {moment(availability.startTime).format('dddd, MMM DD, YYYY')}
            <br />
            {moment(availability.startTime).format('hh:mm A')} to{' '}
            {moment(availability.endTime).format('hh:mm A')}
          </Col>
          <Col xs={5} className="d-flex justify-content-end">
            {renderButton(availability)}
          </Col>
        </Row>
      )}
    </div>
  )
}
