import React, { ReactNode, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { getTopics } from '../../../../../../data/topics/topics.query'
import { Topic } from './Topic'
import TopicsProvider from './TopicsProvider'

interface GetTopicsResponse {
  skillgroups: Array<{
    id: number
    skillGroupName: string
    skills: Array<{
      skillId: number
      skillName: string
    }>
  }>
}

type AvailableTopicsProps = {
  children: ReactNode
}

const AvailableTopics = ({ children }: AvailableTopicsProps): JSX.Element => {
  const { loading, data } = useQuery(getTopics)
  const topics = useMemo((): Topic[] => {
    if (!data) {
      return []
    }

    const response = data as GetTopicsResponse
    const { skillgroups } = response
    return skillgroups
      .map(({ id, skillGroupName, skills }) =>
        skills.map(({ skillId, skillName }) => ({
          id: skillId,
          name: skillName,
          group: {
            id,
            name: skillGroupName,
          },
        })),
      )
      .flat()
  }, [data])
  const ready = !loading && !!topics

  return (
    <TopicsProvider topics={topics} ready={ready}>
      {children}
    </TopicsProvider>
  )
}

export default AvailableTopics
