import React from 'react'
import CardContainer from '../../elemets/CardContainer'
import CircleImage from '../../elemets/CircleImage'
import { Col, Row } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import colors from '../../theme/theme2'

const useStyles = createUseStyles({
  name: {
    fontFamily: 'lato',
    fontWeight: 'bold',
    fontSize: 20,
  },
  title: {
    fontFamily: 'lato',
    fontWeight: 'bold',
    fontSize: 14,
  },
  bioTitle: {
    fontFamily: 'lato',
    fontWeight: 'bold',
    fontSize: 18,
  },
  bio: {
    fontFamily: 'lato',
    fontSize: 14,
    fontWeight: '400',
  },
  funFact: {
    fontFamily: 'lato',
    fontWeight: 'bold',
    fontSize: 16,
  },
  linkedIn: {
    fontFamily: 'lato',
    fontSize: 14,
    color: '#626566',
    marginBottom: 0,
  },
  expandButton: {
    fontFamily: 'Lato',
    fontSize: 12,
    textAlign: 'right',
    color: colors.primary,
  },
  arrows: {
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },
})

const ProfileInfo = ({ profile }) => {
  const classes = useStyles()

  if (!profile) {
    return null
  }

  return (
    <CardContainer>
      <Row>
        <Col xs={12} className={`d-flex justify-content-center pt-3`}>
          <CircleImage size={120} src={profile?.avatar} />
        </Col>
        <Col xs={12} className={`d-flex justify-content-center pt-2`}>
          <span className={classes.name}>
            {profile?.firstName + ' ' + profile?.lastName}
          </span>
        </Col>
        <Col xs={12} className={`d-flex justify-content-center pb-4`}>
          <span className={classes.title}>{profile?.title}</span>
        </Col>
      </Row>
    </CardContainer>
  )
}

export default ProfileInfo
