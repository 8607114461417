import React, { Fragment, useState, useRef } from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Asterisk from './Asterisk'

const Dropdown = props => {
  function getValue(item) {
    const valueField = props.valueField
    let value = item.id

    if (valueField) {
      value = item[valueField]
    }

    return value
  }

  function getText(item) {
    if (props.textField != null) {
      if (props.textField.split(',').length > 1) {
        const textField = props.textField.split(',')

        if (textField.length > 1) return getTextMultipleFields(item, textField)
      }
    }

    if (props.textField) {
      return item[props.textField]
    } else return item.name
  }

  function getTextMultipleFields(item, textFields) {
    let text = ''
    let i

    for (i = 0; i < textFields.length; i++) {
      text = text + item[textFields[i]]
      if (i !== textFields.length - 1) {
        text = text + '; '
      }
    }

    return text
  }

  function createOption(item) {
    const value = getValue(item)
    const text = getText(item)

    return <option value={value}>{text}</option>
  }

  function renderTooltip(text) {
    return <Tooltip>{text}</Tooltip>
  }

  const [show, setShow] = useState(true)
  const target = useRef(null)

  return (
    <Form.Group style={{ maxwidth: props.maxwidth ? props.maxwidth : 300 }}>
      {props.label && (
        <Form.Label>
          {props.label} {props.required && <Asterisk />}
        </Form.Label>
      )}
      {props.toolTip && (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 250 }}
          overlay={renderTooltip(props.toolTip)}
        >
          <i
            ref={target}
            onClick={() => setShow(!show)}
            className={`fas fa-info-circle fa-lg info`}
            style={{ fontSize: 12, position: 'absolute' }}
          />
        </OverlayTrigger>
      )}
      <Form.Control as="select" sm="10" value={props.value || ''} {...props}>
        {props.options.map(item => {
          const parent = []
          parent.push(<Fragment key={item.id}>{createOption(item)}</Fragment>)
          return parent
        })}
      </Form.Control>
    </Form.Group>
  )
}

export default Dropdown
