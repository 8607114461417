import React from 'react'
import { Card, Row, Col, Image, Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ClampLines from 'react-clamp-lines'
import colors from '../../theme/theme2'
import RequestAvailability from './Availability'
import Topic from './Topic'
import { ClockIcon } from '../Icons'

const style = {
  root: {
    fontSize: '1em',
    marginBottom: 10,
  },
  name: {
    fontWeight: 'bold',
  },
  moreInfo: {
    color: colors.primary,
  },
  bio: {
    color: '#999999',
  },
  icon: {
    paddingRight: 5,
    display: 'inline',
  },
}

class ProfileCard extends React.Component {
  constructor(props) {
    super(props)
    this.getBio = this.getBio.bind(this)
    this.onClick = this.onClick.bind(this)
    this.getBadge = this.getBadge.bind(this)
    this.getUniqueTopics = this.getUniqueTopics.bind(this)
    this.state = {
      expanded: false,
      caption: '',
      displayedTopics: [],
    }
  }

  componentDidMount() {
    const { topics = [], bio = '' } = this.props.user
    const isMobile = window.innerWidth < 600
    const maxChar = isMobile ? 100 : 400

    const displayedTopics = [...topics].splice(0, 2)
    this.setState({
      displayedTopics: displayedTopics,
      caption: topics.length > 2 || bio.length > maxChar ? 'More Info + ' : '',
    })
  }

  onClick() {
    const { expanded } = this.state
    const { topics } = this.props.user
    if (expanded) {
      this.setState({
        expanded: false,
        caption: 'More Info +',
        displayedTopics: [...topics.slice(0, 2)],
      })
    } else {
      this.setState({
        expanded: true,
        caption: 'Less Info -',
        displayedTopics: topics,
      })
    }
  }

  getBadge(score) {
    let badge = null

    if (score >= 3) {
      badge = (
        <Badge pill variant="success">
          Exact Match
        </Badge>
      )
    } else if (score > 0) {
      badge = (
        <Badge pill variant="success">
          Close Match
        </Badge>
      )
    }

    return badge
  }

  getUniqueTopics(skillGroupFieldName) {
    const uniqueTopics = [
      ...new Set(this.state.displayedTopics.map(x => x[skillGroupFieldName])),
    ]
    const allSubTopics = []

    uniqueTopics.map(x => {
      const subTopics = this.state.displayedTopics.filter(
        y => y[skillGroupFieldName] === x,
      )
      return allSubTopics.push({
        topic: x,
        subTopics: subTopics,
      })
    })

    return allSubTopics
  }

  render() {
    const {
      user,
      availabilities = [],
      skillGroupFieldName = 'skillGroupName',
    } = this.props
    const { firstName = '', lastName = '' } = user
    const { expanded } = this.state
    const name = firstName + ' ' + lastName
    const bio = this.getBio(user.bio)
    const { avatar = '/images/graycircle.png', token = null } = user
    const badge = this.getBadge(user.score)
    const topics = this.getUniqueTopics(skillGroupFieldName)

    return (
      <Card style={style.root}>
        <Card.Body>
          <Row>
            <Col xs={2} lg={1} style={{ paddingRight: 0 }}>
              <Image src={avatar} roundedCircle fluid />
            </Col>
            <Col>
              <Row>
                <Col>
                  <div style={style.name}>{name}</div>
                </Col>
                <Col xs={7} lg={4}>
                  <div style={{ display: 'inline-block' }}>{badge}</div>
                  {token && (
                    <>
                      {availabilities > 0 && <ClockIcon />}
                      <RequestAvailability
                        to={`/matches?token=${token}`}
                        user={user}
                      />
                    </>
                  )}
                </Col>
              </Row>
              <div style={style.bio}>
                {!expanded && (
                  <ClampLines
                    id="info"
                    text={bio}
                    lines={2}
                    ellipsis="..."
                    buttons={false}
                  />
                )}
                {expanded && <span>{bio}</span>}
              </div>
              {topics.map((topic, i) => {
                return <Topic key={i.toString()} topic={topic}></Topic>
              })}
              <Link style={style.moreInfo} onClick={this.onClick}>
                {this.state.caption}
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
}

export default ProfileCard
