import React from 'react'
import BannerContainer, { BannerDescription } from './BannerContainer'
import ActionButton from '../../../elemets/ActionButton'
import compose from 'just-compose'
import { withRouter } from 'react-router'
import { createUseStyles } from 'react-jss'
import { actionButtonStyles } from '../../../theme/theme2'

const useStyles = createUseStyles({
  button: {
    '& button': {
      color: '#f5a623',
      borderColor: '#f5a623',
      '&:focus': {
        color: '#f5a623!important',
        border: `1px solid #f5a623!important`,
      },
      '&:hover': {
        backgroundColor: actionButtonStyles.secondary.hover,
        color: '#f5a623!important',
        border: `1px solid #f5a623!important`,
      },
      '&:active': {
        backgroundColor: `#ffffff50!important`,
        color: 'white!important',
        border: `1px solid #f5a623!important`,
      },
    },
  },
})

const AddTopicBanner = ({ onClose, history }) => {
  const classes = useStyles()
  const header = (
    <BannerDescription
      icon={'fas fa-comment-dots'}
      title={'ADD A TOPIC OF INTEREST'}
    />
  )
  const description = (
    <p className={`m-0`}>
      Let the group know what you want to talk about, and why it's a topic of
      interest to you. Do you want to learn, teach, or both?
    </p>
  )

  const buttons = (
    <div className={classes.button}>
      <ActionButton
        height="26px"
        width="80px"
        text={'ACCEPT'}
        onClick={() => {
          history.push('/topics')
        }}
        type="LIGHT_GREEN"
        className="text-nowrap"
      />
    </div>
  )
  return (
    <BannerContainer
      header={header}
      description={description}
      buttons={buttons}
      type={'LIGHT_ORANGE'}
      onClose={onClose}
    />
  )
}

export default compose(withRouter)(AddTopicBanner)
