import React from 'react'

const style = {
  color: 'green',
  marginBottom: 5,
  paddingRight: 5,
  size: 10,
  minWidth: 14,
  minHeight: 10,
}

const SkillLevelIcon = props => {
  const rating = props.rating
  const file =
    rating === 5
      ? '/images/skill_levels/tree.png'
      : `/images/skill_levels/${rating}.svg`

  if (rating === 0) return null

  return <img alt="skill_level" src={file} style={style} />
}

export default SkillLevelIcon
