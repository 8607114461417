import React, { useEffect, ReactNode } from 'react'

type WarnOnLeaveProps = {
  children: ReactNode
}

const WarnOnLeave = ({ children }: WarnOnLeaveProps): JSX.Element => {
  useEffect(() => {
    window.onbeforeunload = () => {
      return 'Are you sure you want to leave? This will disconnect the conversation.'
    }
    return () => {
      window.onbeforeunload = null
    }
  })
  return <>{children}</>
}

export default WarnOnLeave
