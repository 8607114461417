import React, { createContext, useState, useEffect, useContext } from 'react'
import { createUseStyles, Styles } from 'react-jss'
import NoSleep from 'nosleep.js'
import Call from './Call'
import WarnOnLeave from './WarnOnLeave'
import WebRTC from './WebRTC'
import ConversationProvider from './ConversationProvider'

type ActiveCallBarProps = {
  visible: boolean
  onClose: () => void
}

const noSleep = new NoSleep()

const useStyles = createUseStyles<string>(
  (): Styles<string> => ({
    root: {
      width: '100%',
      position: 'fixed',
      bottom: 0,
      left: 0,
      zIndex: 10000,
      filter: 'drop-shadow(4px 4px 8px #343434)',
      textAlign: 'left',
    },
  }),
)

const ActiveCallBar = ({ visible, onClose }: ActiveCallBarProps) => {
  const classes = useStyles()
  if (!visible) {
    return null
  }
  return (
    <WebRTC>
      <WarnOnLeave>
        <div className={classes.root}>
          <Call onClose={onClose} />
        </div>
      </WarnOnLeave>
    </WebRTC>
  )
}

type SetVisible = (visible: boolean) => void

type SetConversationId = (conversationId?: number) => void

type ActiveCallContextValue = {
  visible: boolean
  setVisible: SetVisible
  conversationId: number | undefined
  setConversationId: SetConversationId
}

const ActiveCallContext = createContext<ActiveCallContextValue>({
  visible: false,
  setVisible: () => {},
  conversationId: undefined,
  setConversationId: () => {},
})

const ActiveCallProvider = ({ children }: { children?: Node }): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(false)
  const [conversationId, setConversationId] = useState<number | undefined>()

  useEffect(() => {
    if (visible) {
      noSleep.enable()
    } else {
      noSleep.disable()
    }

    return () => noSleep.disable()
  })

  return (
    <ActiveCallContext.Provider
      value={{ visible, setVisible, conversationId, setConversationId }}
    >
      <ConversationProvider
        conversationId={conversationId}
        load={!!conversationId}
      >
        <>
          {children}
          <ActiveCallBar
            visible={visible}
            onClose={() => {
              setVisible(false)
              setConversationId(undefined)
            }}
          />
        </>
      </ConversationProvider>
    </ActiveCallContext.Provider>
  )
}

export const useActiveCall = (): ActiveCallContextValue =>
  useContext(ActiveCallContext)

export default ActiveCallProvider
