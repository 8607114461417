import React from 'react'
import { Row, Col } from 'react-bootstrap'
import SkillLevelIcon from '../Icons/SkillLevel'
import { isMobile } from '../../utils/common'

const style = {
  icon: {
    paddingRight: 5,
    display: 'inline',
  },
  experience: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: '#707575',
  },
}

const Topic = props => {
  const { subTopics = [], topic, experience = null } = props.topic

  return (
    <div>
      <Row>
        <Col>
          <strong>{topic}</strong>
        </Col>
      </Row>
      {subTopics.map((subTopic, i) => {
        const { rating, skillName } = subTopic

        return (
          <Row key={i}>
            <Col className={experience >= 0 ? 'pr-0' : ''}>
              <Row noGutters className="justify-content-between">
                {isMobile() ? (
                  <>
                    <Col xs={12} className={experience >= 0 ? 'pr-0' : ''}>
                      <div style={style.icon}>
                        <SkillLevelIcon rating={rating} />
                      </div>
                      {rating} - {skillName}
                    </Col>
                    {experience >= 0 && (
                      <Col xs={12} className="ml-1 pl-3">
                        <span style={style.experience}>
                          {experience} Years Experience
                        </span>
                      </Col>
                    )}
                  </>
                ) : (
                  <>
                    <Col
                      className={experience >= 0 ? 'pr-0' : ''}
                      xs={experience >= 0 ? 8 : undefined}
                    >
                      <div style={style.icon}>
                        <SkillLevelIcon rating={rating} />
                      </div>
                      {rating} - {skillName}
                    </Col>
                    {experience >= 0 && (
                      <Col className="pl-0 text-end">
                        <span style={style.experience}>
                          {experience} Years Experience
                        </span>
                      </Col>
                    )}
                  </>
                )}
              </Row>
            </Col>
          </Row>
        )
      })}
    </div>
  )
}

export default Topic
