import React from 'react'
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from 'mdbreact'
import { createUseStyles, useTheme } from 'react-jss'
import colors from '../../theme/theme2'

const useStyles = createUseStyles({
  root: {
    background: colors.dark, //'#CCCCB8',background:
    color: 'white',
    marginTop: '25px',
    textAlign: 'center',
  },
  link: {
    color: 'white',
  },
})

const RegularFooter = () => {
  const classes = useStyles(useTheme)
  const links = [
    {
      title: 'Legal',
      items: [
        {
          label: 'Terms of use',
          link: 'https://www.golattitude.com/terms-of-use',
        },
        {
          label: 'Privacy Policy',
          link: 'https://www.golattitude.com/privacy-policy',
        },
      ],
    },
    {
      title: 'Support',
      items: [
        {
          label: 'Help Topics',
          link: 'https://golattitude.com/help-topics/',
        },
        {
          label: 'FAQs',
          link: 'https://golattitude.com/frequently-asked-questions-faqs/',
        },
        {
          label: 'Report an Issue',
          link: 'https://golattitude.atlassian.net/servicedesk/customer/portal/3',
        },
      ],
    },
    {
      title: 'Lattitude',
      items: [
        {
          label: 'About Us',
          link: 'https://www.golattitude.com/about',
        },
        {
          label: 'Pricing',
          link: 'https://www.golattitude.com/pricing',
        },
        {
          label: 'Success Stories',
          link: 'https://www.golattitude.com/success-stories',
        },
      ],
    },
  ]

  return (
    <div className={classes.root}>
      <MDBFooter className="font-small pt-4 mt-4">
        <MDBContainer className="text-center text-md-left">
          <MDBRow>
            <MDBCol md="4">
              <p>&copy; Lattitude, Inc. {new Date().getFullYear()}</p>
            </MDBCol>
            {links.map((link) => {
              return (
                <MDBCol key={link.title}>
                  <h5 className="title">{link.title}</h5>
                  <ul>
                    {link.items.map((item) => {
                      return (
                        <li key={item.label} className="list-unstyled">
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.link}
                          >
                            {item.label}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </MDBCol>
              )
            })}
          </MDBRow>
        </MDBContainer>
      </MDBFooter>
    </div>
  )
}

export default RegularFooter
