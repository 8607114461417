import React from 'react'
import Select from 'react-select'
import { createUseStyles } from 'react-jss'
import colors from '../theme/theme2'

const useStyles = createUseStyles({
  select: {
    width: '100%',
    padding: '0 13px',
    border: `1px solid ${colors.grey1}`,
    borderRadius: 4,
    color: colors.dark,
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: 16,
    '&:disabled': {
      color: colors.dark,
      cursor: 'default',
      border: '1px solid transparent',
      backgroundColor: 'transparent',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  title: {
    fontSize: 14,
    fontFamily: 'Lato',
    color: colors.grey2,
    paddingLeft: 16,
    marginBottom: 0,
    width: '100%',
  },
})

const CustomSelect = ({
  options,
  value,
  onSelect,
  name,
  className,
  title,
  placeholder = 'Select',
  isSearchable = true,
  border = true,
  titleClassName = '',
  isMobile = false,
  isRequired = false,
  menuWidth = 'unsert',
  dataTestId = '',
}) => {
  const classes = useStyles()
  const fontStyles = {
    fontSize: 14,
    fontWeight: 500,
    color: colors.grey3,
    lineHeight: 2,
    fontFamily: 'Lato',
  }

  const colourStyles = {
    container: (styles) => ({
      ...styles,
      ...fontStyles,
      width: '100%',
      padding: '0 13px',
      border: border ? `1px solid ${colors.grey1}` : 'none',
      borderRadius: 4,
      backgroundColor: 'white',
      '&:disabled': {
        color: colors.dark,
        cursor: 'default',
        border: '1px solid transparent',
        backgroundColor: 'transparent',
      },
      '&:focus': {
        outline: 'none',
      },
      '&:active': {
        outline: 'none',
      },
      height: isMobile ? 24.5 : '',
    }),
    placeholder: (styles) => ({
      ...styles,
      ...fontStyles,
      padding: 0,
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      div: {
        padding: 0,
        color: colors.black,
        width: '15px',
      },
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      width: 0,
    }),
    valueContainer: (styles) => ({
      ...styles,
      ...fontStyles,
      fontSize: isMobile ? 13 : 14,
      padding: 0,
    }),
    control: (styles) => ({
      ...styles,
      border: 'none',
      minHeight: 'unset',
      boxShadow: 'none',
      '&:focus, &:active, &:after, &:hover': {
        border: 'none',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 1300,
      marginRight: '-13px',
      width: menuWidth,
    }),
    input: (styles) => ({
      ...styles,
      margin: 0,
      padding: 0,
    }),
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        ...fontStyles,
        color: isSelected ? colors.primary : colors.grey3,
        padding: '3px 12px',
        backgroundColor: isSelected ? colors.primary100 : 'white',
        '&:hover': {
          backgroundColor: colors.primary100,
          color: colors.primary,
          cursor: 'pointer',
        },
        '&:active': {
          backgroundColor: colors.primary700,
          color: colors.primary,
        },
      }
    },
  }

  return (
    <div className={className} data-testid={dataTestId}>
      {title && (
        <label
          className={`${classes.title} mb-0 w-100 ${titleClassName}`}
          htmlFor={name}
        >
          {title}
          {isRequired ? <span className="text-danger"> *</span> : null}
        </label>
      )}
      <Select
        options={options}
        isSearchable={isSearchable}
        placeholder={placeholder}
        onChange={(e) => onSelect(name, e)}
        name={name}
        value={value}
        styles={colourStyles}
        data-testid={'select'}
      />
    </div>
  )
}

export default CustomSelect
