import React, { useState, useEffect, useContext, useCallback } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import { invokeOperationWithAuth } from './api'
import loadingConfig from './config/init'
import { useBrand } from './BrandHandler'
import { useBaseUri } from './config/useConfig'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

const clientPr = loadingConfig.then((config) =>
  createAuth0Client({
    domain: config.auth0.domain,
    client_id: config.auth0.clientId,
    audience: config.auth0.audience,
    redirect_uri: window.location.origin,
  }),
)

export const Auth0Context = React.createContext()

export const useAuth0 = () => useContext(Auth0Context)

export const getAccessToken = async () => {
  const auth0 = await clientPr
  return auth0.getTokenSilently()
}

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
}) => {
  // TODO refactor to use useReducer
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [isProfileCreated, setProfileCreated] = useState(false)
  const [isInitialized, setInitialized] = useState(
    !window.location.search.includes('code='),
  )
  const baseUriLattusApp = useBaseUri('lattusApp')

  const { isBranded, brandInfo, setLoadLanding } = useBrand()

  clientPr.then(setAuth0)

  // TODO async useEffect
  useEffect(() => {
    const initAuth0 = async () => {
      if (!auth0Client) return

      if (!isInitialized) {
        try {
          const { appState } = await auth0Client.handleRedirectCallback()
          setInitialized(true)
          onRedirectCallback(appState)
          setLoadLanding(false)
        } catch (e) {
          setInitialized(true)
          onRedirectCallback({
            appState: { targetUrl: window.location.pathname },
          })
          setLoadLanding(false)
        }
      }

      if (await auth0Client.isAuthenticated()) {
        if (!isProfileCreated) {
          const getAccessToken = () => auth0Client.getTokenSilently()
          await invokeOperationWithAuth(getAccessToken)('createProfile', {
            query: {
              groupId: isBranded ? brandInfo.id : null,
            },
          })
            .then(() => {
              setProfileCreated(true)
              setIsAuthenticated(true)
              setInitialized(true)
              setLoading(false)
              setLoadLanding(false)
            })
            .catch((err) => {
              if (err.res.status === 403) {
                auth0Client.logout({ returnTo: baseUriLattusApp })
              }
            })
        }
      } else {
        setProfileCreated(false)
        setLoading(false)
      }
    }

    initAuth0()
  }, [
    auth0Client,
    baseUriLattusApp,
    isProfileCreated,
    onRedirectCallback,
    isInitialized,
    isBranded,
    brandInfo.id,
    setLoadLanding,
  ])

  const log = useCallback(async () => {
    // SEND LOGIN TO API TO TRACK
    await invokeOperationWithAuth(getAccessToken)('trackUserLogs', {
      query: {
        activity: 'LOGIN',
      },
    })
  }, [])

  useEffect(() => {
    if (isAuthenticated && isProfileCreated) log()
  }, [isAuthenticated, isProfileCreated, log])

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated: isAuthenticated && isProfileCreated,
        loading: loading || !isInitialized,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        loginWithPopup: (...p) => auth0Client.loginWithPopup(...p),
        getUser: (...p) => auth0Client.getUser(...p),
        logout: async (...p) => {
          // SEND LOGOUT TO API TO TRACK
          await invokeOperationWithAuth(getAccessToken)('trackUserLogs', {
            query: {
              activity: 'LOGOUT',
            },
          })
          return auth0Client.logout(...p)
        },
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
