import React, { useEffect, useMemo, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import MatchesList from './MatchesList'
import GroupBanner from './GroupBanner'
import GroupInfo from './GroupInfo'
import GroupFilters from './GroupFilters'
import { isMobile } from '../../utils/common'
import LoaderPage from '../../elemets/LoaderPage'
import { createUseStyles } from 'react-jss'
import { headings } from '../../theme/theme2'
import { useCurrentUser } from '../Context/CurrentUser'
import { useGroupMembers } from '../Context/GroupMembers'

const useStyles = createUseStyles({
  mobile: {
    paddingLeft: 0,
    paddingRight: 0,
  },

  title: {
    ...headings.title,
  },
})

const GroupDashboard = () => {
  const classes = useStyles()
  const { id } = useParams()
  const location = useLocation()
  const history = useHistory()
  const query = new URLSearchParams(location.search)
  const availabilityProfileId = query.get('pid')
  //STATES
  const [group, setGroup] = useState({})
  const [firstLoad, setFirstLoad] = useState(true)
  const { currentUser, loading: loadingCurrentProfile } = useCurrentUser()
  const {
    groupMembers,
    filterMembers,
    loading,
    setGroups,
    setFilters,
    hasMoreItems,
    setPage,
    setSortField,
  } = useGroupMembers()

  //EFFECTS
  const handleScroll = () => {
    if (
      Math.ceil(window?.innerHeight + document?.documentElement?.scrollTop) >=
        document?.documentElement?.offsetHeight * 0.75 &&
      hasMoreItems?.current
    ) {
      document.activeElement.blur()
      setPage()
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const groupId = Number(id)
    if (currentUser?.id) {
      const group = currentUser.groups.find((x) => x.id === groupId)
      if (!group) {
        history.push('/unauthorized')
      } else {
        setGroup(group)
        setGroups([group.id])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, id, currentUser])

  useEffect(() => {
    if (firstLoad && currentUser?.id) {
      setFirstLoad(false)
    }
  }, [currentUser, firstLoad])

  //LOADING
  const isLoading = useMemo(
    () => firstLoad && (loadingCurrentProfile || loading),
    [firstLoad, loadingCurrentProfile, loading],
  )

  //RENDER
  return !isMobile() ? (
    <Container>
      {isLoading && <LoaderPage />}
      <GroupBanner group={group} />
      <Row>
        <Col xs={12} md={8}>
          <GroupInfo
            name={group.name}
            info={group.description}
            isAdmin={group.isAdmin}
            id={group.id}
          />
        </Col>
        <Col xs={12} md={4}>
          <GroupFilters
            matches={filterMembers}
            setFilters={setFilters}
            title={'Members'}
          />
        </Col>
      </Row>
      <MatchesList
        filteredMatches={groupMembers}
        loading={loading}
        setSortField={setSortField}
        openAvailabilitiesFor={availabilityProfileId}
      />
    </Container>
  ) : (
    <Container className={classes.mobile}>
      <div className={'d-flex justify-content-center'}>
        <p className={`${classes.title} `}>{group.name}</p>
      </div>
      {isLoading && <LoaderPage />}
      <GroupBanner group={group} />
      <GroupInfo
        name={group.name}
        info={group.description}
        isAdmin={group.isAdmin}
        id={group.id}
      />
      <Row noGutters>
        <Col xs={12}>
          <GroupFilters
            matches={filterMembers}
            setFilters={setFilters}
            title={'Members'}
          />
        </Col>
      </Row>
      <MatchesList
        filteredMatches={groupMembers}
        loading={loading}
        setSortField={setSortField}
      />
    </Container>
  )
}

export default GroupDashboard
