import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import compose from 'just-compose'
import { createUseStyles } from 'react-jss'
import { AvailabilityRow } from './AvailabilityRow'

// Theme
import { withErrorHandler } from '../../../errors'
import { withNotifications } from '../../../notifications'
import Modal from '../../../elemets/Modal'
import ActionButton from '../../../elemets/ActionButton'
import colors, { headings } from '../../../theme/theme2'
import { useApiFetch } from '../../../api'
import { isMobile } from '../../../utils/common'
import Icon from '../../Icons'
import RequestAvailabilityModal from './RequestAvailabilityModal'

const useStyles = createUseStyles({
  body: {
    margin: 0,
    padding: 0,
  },
  label: {
    ...headings.title,
    fontSize: 12,
  },
  title: {
    ...headings.title,
  },
  dateRow: {
    padding: isMobile() ? '18px 9px 14px 9px' : '13px 15px 13px 31px',
    width: '100%',
  },
  time: {
    ...headings.paragraph,
    fontSize: 12,
    color: colors.dark,
    fontWeight: 'bold',
  },
  subtitle: {
    ...headings.paragraph,
    color: colors.dark,
    fontWeight: 'bold',
  },
  bgGrey: {
    backgroundColor: colors.grey1,
  },
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  availabilitiesMobile: {
    width: '100%',
  },
  modal: {
    '& .modal-dialog': {
      minWidth: 730,
    },
  },
  select: {
    width: '100%',
  },
  arrow: {
    minWidth: 10,
  },
  icon: {
    '& i': {
      '&:before': {
        color: colors.primary,
      },
    },
  },
})

const ScheduleModal = ({
  profileTopics,
  match,
  title = 'Schedule a Conversation',
  show,
  onClose,
  handleError,
  reloadUpcoming,
  pushNotification,
}) => {
  const classes = useStyles()
  const [pagination, setPagination] = useState(0)
  const [upperLimit, setUpperLimit] = useState(0)
  const [showRequestAvailability, setShowRequestAvailability] = useState(false)
  const [requested, setRequested] = useState(false)

  const {
    data: matchAvailability,
    loading: loadingMatchAvailability,
    err: errorMatchAvailability,
    fetch: listMatchAvailability,
  } = useApiFetch('listMatchAvailability', {}, false)

  useEffect(() => {
    if (matchAvailability) {
      setUpperLimit(Math.ceil(matchAvailability.length / (isMobile() ? 4 : 5)))
    }
  }, [matchAvailability])

  useEffect(() => {
    if (!loadingMatchAvailability && errorMatchAvailability) {
      handleError(errorMatchAvailability, true, {
        message: 'Unable to load participant availability.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchAvailability, loadingMatchAvailability, errorMatchAvailability])

  useEffect(() => {
    if (show && match?.token) {
      listMatchAvailability({ query: { token: match.token } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.token, show])

  const topics = useMemo(() => {
    if (!match || !match.topics) return []
    if (Array.isArray(match.topics)) {
      return match.topics
        .filter((topic) => {
          return profileTopics.find(
            (profileTopic) => profileTopic.skillId === topic.skillId,
          )
        })
        .map((topic) => {
          return { label: topic.skillName, value: topic.skillId }
        })
    }
  }, [match, profileTopics])

  return (
    <>
      {showRequestAvailability && (
        <RequestAvailabilityModal
          setRequestedAvailability={setRequested}
          show={showRequestAvailability}
          match={match}
          onClose={() => {
            setShowRequestAvailability(false)
          }}
        />
      )}
      <Modal
        show={show}
        close={onClose}
        title={title}
        closeIcon={true}
        bodyClass={classes.body}
        modalClass={`${!isMobile() ? classes.modal : ''}`}
        dialogClassName={isMobile() ? classes.dialogMobile : ''}
      >
        <Row noGutters className={`pb-2`}>
          {matchAvailability
            ?.slice(
              pagination * (isMobile() ? 4 : 5),
              pagination * (isMobile() ? 4 : 5) + (isMobile() ? 4 : 5),
            )
            .map((av, index) => (
              <AvailabilityRow
                key={index}
                availability={av}
                index={index}
                reloadUpcoming={reloadUpcoming}
                topics={topics}
                match={match}
                handleError={handleError}
                pushNotification={pushNotification}
              />
            ))}
          {(!matchAvailability || matchAvailability?.length === 0) && (
            <div className={`w-100`}>
              <p className={`${classes.subtitle} p-3`}>
                {' '}
                Sorry, the match doesn't have any availabilities at the moment{' '}
              </p>
              <div className={'d-flex justify-content-end w-100'}>
                <ActionButton
                  height="34px"
                  text={requested ? 'REQUEST SENT' : 'SEND REQUEST'}
                  onClick={() => {
                    setShowRequestAvailability(true)
                  }}
                  type="STRONG_GREEN"
                  className="text-nowrap"
                  disabled={requested}
                />
              </div>
            </div>
          )}
        </Row>

        {matchAvailability?.length > 5 && (
          <Row noGutters className={`pb-2 pr-3`}>
            <Col xs={12} className={'d-flex justify-content-center'}>
              <div className={`d-flex ${classes.icon}`}>
                <div className={classes.arrow}>
                  {pagination !== 0 && (
                    <Icon
                      icon={'fas fa-chevron-left'}
                      onClick={() => setPagination((s) => s - 1)}
                    />
                  )}
                </div>
                <span className={'pr-2 pl-2'}>{pagination + 1}</span>
                <div className={classes.arrow}>
                  {pagination + 1 < upperLimit && (
                    <Icon
                      icon={'fas fa-chevron-right'}
                      onClick={() => setPagination((s) => s + 1)}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  )
}

export default compose(withErrorHandler, withNotifications)(ScheduleModal)
