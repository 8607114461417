import React from 'react'
import { Link } from 'react-router-dom'
import { createUseStyles, useTheme } from 'react-jss'
import colors from '../../theme/theme2'
import {
  AvailabilityIcon,
  ConversationsIcon,
  HomeIcon,
  TopicsIcon,
} from '../Icons'

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: colors.dark,
    display: 'flex',
    alignItems: 'center',
    fontSize: '.8rem',
    position: 'fixed',
    bottom: 0,
    marginTop: theme.spacing(6),
    marginBottom: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    textAlign: 'center',
    width: '100%',
    zIndex: theme.zIndex.nav,
  },
  navItem: {
    color: 'white',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid transparent',
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '&:hover, &:focus': {
      borderBottomColor: '#000',
    },
    '&:hover': {
      color: colors.primary,
    },
  },
  link: {
    color: 'white',
    '&:hover': {
      color: colors.primary,
    },
    '&:hover, &:focus': {
      textDecoration: 'none',
    },
  },
  icon: {
    color: 'white',
    fontSize: '2em',
    '&:hover': {
      color: colors.primary,
    },
  },
}))

const footerItems = [
  {
    title: 'Home',
    link: '/home',
    icon: HomeIcon,
  },
  {
    title: 'Topics',
    link: '/topics',
    icon: TopicsIcon,
  },
  {
    title: 'Availability',
    link: '/availability',
    icon: AvailabilityIcon,
  },
  {
    title: 'Convos',
    link: '/conversations',
    icon: ConversationsIcon,
  },
]

const MobileFooter = () => {
  const theme = useTheme()
  const classes = useStyles({ theme })
  return (
    <div className={classes.root}>
      {footerItems.map(function (item) {
        const Icon = item.icon
        return (
          <div key={item.title} className={classes.navItem}>
            <Link className={classes.link} to={item.link}>
              <div className={classes.icon}>
                <Icon />
              </div>
              {item.title}
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default MobileFooter
