import React from 'react'
import { ButtonGroup, Dropdown } from 'react-bootstrap'
import useActionsStyles from './actionsStyles'
import RequestAvailability from './RequestAvailability'
import Reschedule from './Reschedule'
import JoinOrViewConversation from './JoinOrViewConversation'
import MeetNow from './MeetNow'

type ScheduledConversationActionsProps = {
  conversationId: number
  startTime: Date
  participantId: number
  availabilityToken: string
  skill: { id: number; name: string }
  onScheduled: () => void
}

const ScheduledConversationActions = ({
  conversationId,
  startTime,
  participantId,
  availabilityToken,
  skill,
  onScheduled,
}: ScheduledConversationActionsProps): JSX.Element => {
  const classes = useActionsStyles()
  return (
    <Dropdown as={ButtonGroup}>
      <JoinOrViewConversation
        conversationId={conversationId}
        startTime={startTime}
        className={classes.button}
      />
      <Dropdown.Toggle split className={classes.toggle} />
      <Dropdown.Menu>
        <RequestAvailability
          availabilityToken={availabilityToken}
          skill={skill}
        />
        <Reschedule
          conversationId={conversationId}
          participantId={participantId}
          availabilityToken={availabilityToken}
          skillId={skill.id}
          onScheduled={onScheduled}
        />
        <MeetNow
          availabilityToken={availabilityToken}
          skill={skill}
          onScheduled={onScheduled}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ScheduledConversationActions
