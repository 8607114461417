import React from 'react'
import Icon from '../Icons'
import { createUseStyles } from 'react-jss'
import { withRouter } from 'react-router'
import colors, { buttonStyles } from '../../theme/theme2'
import Tooltip from './Tooltip'
import ActionButton from '../../elemets/ActionButton'
import { isMobile } from '../../utils/common'

const useStyles = createUseStyles({
  help: {
    display: 'none !important',
    ...buttonStyles.primary,
    position: 'fixed!important',
    bottom: 25,
    right: 25,
    width: 155,
    padding: '10px!important',
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    color: colors.whiteBackground,
    '&:focus': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      color: `${colors.whiteBackground}!important`,
      backgroundColor: `${colors.primary}!important`,
      border: 'none',
    },
    '&:active': {
      color: `${colors.whiteBackground} !important`,
      backgroundColor: `${colors.primary}!important`,
      border: 'none !important',
    },
    '& i': {
      '&:before': {
        fontSize: 22,
        color: colors.whiteBackground,
      },
    },
  },
  helpMobile: {
    position: 'initial!important',
  },
  iconText: {
    fontFamily: 'lato',
    fontSize: 12,
    position: 'relative',
    top: -3,
  },
  content: {
    '& i': {
      '&:before': {
        fontSize: 20,
        color: colors.primary,
      },
    },
  },
  tooltipPosition: {
    right: 25,
    bottom: 90,
    position: 'fixed!important',
  },
  tooltipPositionMobile: {
    top: -320,
    left: 10,
  },
})

const HelpButton = ({ history, closeTooltip, setTooltip, showTooltip }) => {
  const classes = useStyles()
  const content = (
    <p className={classes.content}>
      {`Don't worry, we are always here! Click "HELP?" and start typing your question`}
      {
        ' to search through FAQs, "how to videos" and/or send us a support request.'
      }
    </p>
  )
  return (
    <>
      {showTooltip === 4 && (
        <Tooltip
          activeNumber={showTooltip}
          title={'Help Center'}
          content={content}
          setTooltip={setTooltip}
          close={closeTooltip}
          arrow={isMobile() ? 'arrowDownMobileHelp' : 'arrowDown'}
          className={
            isMobile() ? classes.tooltipPositionMobile : classes.tooltipPosition
          }
        />
      )}
      <ActionButton
        className={`${classes.help} ${isMobile() ? classes.helpMobile : ''}`}
        text={
          <>
            <div className={classes.icon}>
              <Icon icon="fas fa-life-ring" />
              <span className={`pl-2 ${classes.iconText}`}>HELP</span>
            </div>
          </>
        }
        onClick={() => {
          history.push('/helpDesk')
        }}
        type="STRONG_GREEN"
      />
    </>
  )
}

export default withRouter(HelpButton)
