/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import CardContainer from '../../elemets/CardContainer'
import CardTitle from '../../elemets/CardTitle'

import { createUseStyles } from 'react-jss'
import { isMobile } from '../../utils/common'
import ActionButton from '../../elemets/ActionButton'
import colors, { headings } from '../../theme/theme2'
import compose from 'just-compose'
import { withRouter } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import Tooltip from './Tooltip'
import Icon from '../Icons'
import SimpleSlider from './SuggestedMatches/Slider'
import DetailsCard from './SuggestedMatches/DetailsCard'

const useStyles = createUseStyles({
  title: {
    padding: 0,
  },
  container: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    minHeight: 310,
  },
  greenClock: {
    color: colors.primary,
    '& .icon': {
      color: colors.primary,
    },
    marginRight: 15,
  },
  greyClock: {
    color: colors.grey2,
    '& .icon': {
      color: colors.grey2,
    },
  },
  tooltipText: {
    ...headings.title,
    fontSize: 12,
  },

  slider: {
    paddingLeft: 50,
    paddingRight: 50,
  },
  sliderMobile: {
    paddingLeft: 20,
    paddingRight: 10,
  },
  mobileText: {
    fontWeight: 'normal',
    marginLeft: 40,
  },
  tooltipPosition: {
    left: -450,
    bottom: -43,
  },
  tooltipPositionMobile: {
    bottom: -260,
  },
  textMain: {
    ...headings,
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.dark,
  },
  textSubtitle: {
    ...headings,
    fontSize: 14,
    fontWeight: 'normal',
    color: colors.grey2,
  },
})

const SuggestedMatches = ({
  history,
  setTooltip,
  showTooltip,
  closeTooltip,
  reloadUpcoming,
  matches,
  profileTopics = [],
}) => {
  const classes = useStyles()
  const [sortedMatches, setSortedMatches] = useState([])
  const [details, setDetails] = useState({
    show: false,
    match: {},
  })

  useEffect(() => {
    if (matches) {
      setSortedMatches(matches)
    }
  }, [matches])

  const header = (
    <div className={`d-flex`}>
      <div className={'d-flex justify-content-start w-50'}>
        <CardTitle className={classes.title} title={'Suggested Matches'} />
      </div>
      <div
        className={`d-flex justify-content-end ${!isMobile() ? 'w-50' : ''}`}
      >
        <ActionButton
          className={`${classes.button}`}
          type={'LIGHT_GREEN'}
          text={
            <>
              <span className={classes.iconText}>MY NETWORK</span>
            </>
          }
          onClick={() => {
            history.push(`/Network`)
          }}
        />
      </div>
    </div>
  )

  const tooltipContent = (
    <>
      <p> Members are sorted by the topics that you want to talk about. </p>
      {!isMobile() ? (
        <p className={`${classes.tooltipText} mb-0`}>
          <span className={classes.greenClock}>
            <Icon icon="fas fa-clock" />
          </span>
          Solid Green Clock - Indicates Overlap Availability
          <br />
          <span className={classes.greenClock}>
            <Icon icon="far fa-clock" />
          </span>
          Green Outline Clock - Indicates Availability But No Overlap
        </p>
      ) : (
        <p className={`${classes.tooltipText} mb-0`}>
          <span className={classes.greenClock}>
            <Icon icon="fas fa-clock" />
          </span>
          Solid Green Clock
          <br />
          <span className={`${classes.mobileText}`}>
            - Indicates Overlap Availability
          </span>
          <br />
          <span className={classes.greenClock}>
            <Icon icon="far fa-clock" />
          </span>
          Green Outline Clock
          <br />
          <span className={`${classes.mobileText}`}>
            - Indicates Availability But No Overlap
          </span>
        </p>
      )}
    </>
  )

  // TODO: meet now.
  return (
    <CardContainer
      className={`${classes.container} ${
        isMobile() ? classes.mobile : ''
      } pb-0`}
      header={header}
      headerDivider={true}
    >
      {showTooltip === 3 && (
        <Tooltip
          activeNumber={showTooltip}
          title={'Schedule a Conversation'}
          content={tooltipContent}
          setTooltip={setTooltip}
          close={closeTooltip}
          arrow={isMobile() ? 'arrowUp' : 'arrowRight'}
          className={
            isMobile() ? classes.tooltipPositionMobile : classes.tooltipPosition
          }
        />
      )}
      <Row>
        {details.show && (
          <DetailsCard
            profileTopics={profileTopics}
            match={details.match}
            onClose={() => {
              setDetails({ show: false, match: {} })
            }}
            reloadUpcoming={reloadUpcoming}
          />
        )}
        <Col
          xs={12}
          className={`${isMobile() ? classes.sliderMobile : classes.slider}`}
        >
          {sortedMatches.length > 0 ? (
            <SimpleSlider
              profileTopics={profileTopics}
              matches={sortedMatches}
              reloadUpcoming={reloadUpcoming}
              setDetails={setDetails}
            />
          ) : (
            <div className={`d-flex justify-content-center p-4 mt-5`}>
              <div className={`pt-2 ${classes.icon}`}>
                <Icon icon={'fas fa-exclamation-triangle'} />
              </div>
              <div className={`pl-3`}>
                <p className={`${classes.textMain} mb-0`}>
                  You have no featured matches.
                </p>
                <p className={classes.textSubtitle}>
                  Add new topics to get more matches.
                </p>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </CardContainer>
  )
}

export default compose(withRouter)(SuggestedMatches)
