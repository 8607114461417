import {
  createLikertMatrix,
  createRadioQuestion,
  defaultTopLevelProps,
} from '../assessment-utils'
import {
  assessment1Info,
  questionGroup0,
  questionGroup1,
  questionGroup2,
  questionGroup3,
  questionGroup4,
  questionGroup5,
  questionGroup6,
  questionGroup7,
  questionGroup8,
  questionGroup9,
  questionGroup10,
  questionGroup11,
  questionGroup12,
  questionGroup13,
  questionGroup14,
} from './AssessmentSchema.onboarding.questions.'

export const surveyJsonOnboarding = {
  ...defaultTopLevelProps,
  ...assessment1Info,
  pages: [
    createRadioQuestion('page0', questionGroup0, 'curr_org1'),
    //
    // Baseline - questionGroup1 and 2
    //
    createLikertMatrix('page1', questionGroup1, '7p-SD-SA'),
    createLikertMatrix('page2', questionGroup2, '5p-NT-CT'),

    //
    // ReLace-quarterly - questionGroups 4-14
    //
    createLikertMatrix('page3', questionGroup3, '7p-SD-SA'),
    questionGroup4(),
    createLikertMatrix('page5', questionGroup5, '7p-SD-SA'),
    createLikertMatrix('page6', questionGroup6, '7p-SD-SA'),
    createLikertMatrix('page7', questionGroup7, '7p-SD-SA'),
    createLikertMatrix('page8', questionGroup8, '7p-SD-SA'),
    createLikertMatrix('page9', questionGroup9, '7p-SD-SA'),
    createLikertMatrix('page10', questionGroup10, '7p-SD-SA'),
    createLikertMatrix('page11', questionGroup11, '7p-SD-SA'),
    createLikertMatrix('page12', questionGroup12, '7p-SD-SA'),
    createLikertMatrix('page13', questionGroup13, '6-NA-FM'),
    questionGroup14(),
  ],
}
