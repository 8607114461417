import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import SkillLevelIcon from '../Icons/SkillLevel'
import MentorBadge from './MentorBadge'
import { createUseStyles } from 'react-jss'
import colors from '../../theme/theme2'

const useStyles = createUseStyles({
  icon: {
    paddingRight: 5,
    display: 'inline',
  },
  rating: {
    fontFamily: 'Lato',
    fontSize: 13,
    fontWeight: 500,
    marginRight: 10,
  },
  summary: {
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 'bold',
  },
  experience: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: colors.primary,
    textAlign: 'center',
  },
  years: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: '#707575',
    paddingLeft: 20,
  },
  arrows: {
    paddingTop: 0,
    paddingBottom: 0,
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },
  groupName: {
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 'bold',
  },
  counter: {
    lineHeight: 1.9,
  },
})

const TopicsContainer = ({
  topics,
  displaySummary = true,
  setDisplayedTopic = () => {},
}) => {
  const [subtopic, setSubtopic] = useState(0)
  const classes = useStyles()

  useEffect(() => {
    if (topics[subtopic] === undefined) {
      setSubtopic(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topics])

  const swipeExp = counter => {
    let nextTopic = subtopic + counter
    if (nextTopic < 0) {
      nextTopic = topics.length - 1
    } else if (nextTopic === topics.length) {
      nextTopic = 0
    }
    setSubtopic(nextTopic)
  }

  useEffect(() => {
    setDisplayedTopic(subtopic)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtopic])

  const Swipe = (
    <>
      <Button
        className={classes.arrows}
        onClick={() => {
          swipeExp(-1)
        }}
      >
        <i className={`fas fa-chevron-left`} />
      </Button>
      <span className={classes.counter}>
        {subtopic + 1} / {topics.length}
      </span>
      <Button
        className={classes.arrows}
        onClick={() => {
          swipeExp(1)
        }}
      >
        <i className={`fas fa-chevron-right`} />
      </Button>
    </>
  )

  return !(topics[subtopic] === undefined) ? (
    <>
      <Row>
        <Col xs={8} className={`pr-0 ${classes.groupName}`}>
          {topics[subtopic].skillGroupName || topics[subtopic].groupName}
          <MentorBadge isMentor={topics[subtopic].isMentor} />
        </Col>
        <Col xs={4} className={`pl-0 pr-0 ${classes.experience}`}>
          {topics.length > 1 && Swipe}
        </Col>
      </Row>
      <Row>
        <Col xs={7} className={`pr-0`}>
          <div className={classes.icon}>
            <SkillLevelIcon rating={topics[subtopic].rating} />
          </div>
          <span className={classes.rating}>
            {topics[subtopic].rating} - {topics[subtopic].skillName}
          </span>
        </Col>
        <Col xs={5} className={`pt-1 pl-0 pr-0 d-flex justify-content-center`}>
          <span className={classes.years}>
            {topics[subtopic].years} Years Experience
          </span>
        </Col>
      </Row>
      {displaySummary && topics[subtopic].summary !== '' && (
        <Row>
          <Col xs={12} className={`pt-2`}>
            <span className={classes.summary}>Why a topic of Interest?</span>
            <br />
            <span className={classes.rating}>{topics[subtopic].summary}</span>
          </Col>
        </Row>
      )}
    </>
  ) : null
}

export default TopicsContainer
