import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import colors from '../../../theme/theme2'

const useStyles = createUseStyles({
  header: {
    width: '100%',
    minHeight: 90,
    color: colors.whiteBackground,
    textAlign: 'center',
    margin: '-17px 0 0 0',
    padding: '0 5% 0 5%',
  },
  green: {
    backgroundColor: colors.primary,
  },
  orange: {
    backgroundColor: '#db5e12',
  },
  lightOrange: {
    backgroundColor: '#f5a623',
  },
  blue: {
    backgroundColor: '#4ea7ec',
  },
  yellow: {
    backgroundColor: '#f5a623',
  },
  red: {
    backgroundColor: colors.error,
  },
  strongRed: {
    backgroundColor: '#9e0016',
  },
  text: {
    paddingTop: 20,
    fontFamily: 'Lato',
    textAlign: 'left',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    margin: 0,
  },
  subtitle: {
    fontWeight: '500',
    fontSize: 13,
  },
  close: {
    position: 'absolute',
    right: 0,
    padding: '3px',
    backgroundColor: 'transparent',
    border: 'none',
    color: colors.whiteBackground,
    cursor: 'pointer',
    '&:focus': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      color: colors.dark,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&:active': {
      color: `${colors.dark} !important`,
      backgroundColor: 'transparent !important',
      border: 'none !important',
    },
  },
  content: {
    cursor: 'pointer',
    '& i': {
      position: 'relative',
      top: 32,
      left: -20,
      '&:before': {
        fontSize: 25,
        color: colors.whiteBackground,
      },
    },
  },
  clickable: {
    cursor: 'pointer',
  },
})

export const BannerDescription = ({ icon, title, subtitle }) => {
  const classes = useStyles()

  return (
    <div className={`d-flex justify-content-center`}>
      <div className={classes.iconContainer}>
        <i className={`${icon} mr-2 pt-1`} />
      </div>
      <div>
        <p className={`${classes.title} text-center text-md-left`}>{title}</p>
        {subtitle && (
          <span className={`${classes.subtitle} text-center text-md-left`}>
            {subtitle}
          </span>
        )}
      </div>
    </div>
  )
}

const BannerContainer = ({
  layout,
  type,
  clickable = false,
  onClick,
  onClose,
  header,
  description,
  buttons,
}) => {
  const classes = useStyles()

  const getBannerColor = type => {
    switch (type) {
      case 'RED':
        return classes.red
      case 'STRONG_RED':
        return classes.strongRed
      case 'GREEN':
        return classes.green
      case 'YELLOW':
        return classes.yellow
      case 'ORANGE':
        return classes.orange
      case 'BLUE':
        return classes.blue
      case 'LIGHT_ORANGE':
        return classes.lightOrange
      default:
        return classes.green
    }
  }

  const headerOnly = header && !description && !buttons

  const headerWithDesc = header && description && !buttons

  return (
    <Row
      className={`${classes.header} ${getBannerColor(type)} ${
        clickable ? classes.clickable : ''
      }`}
      onClick={clickable ? onClick : () => {}}
    >
      <Button className={classes.close} onClick={onClose}>
        <i className="far fa-times-circle" />
      </Button>
      {layout ? (
        layout
      ) : (
        <>
          <Col
            xs={12}
            md={headerOnly ? 12 : headerWithDesc ? 6 : 4}
            lg={headerOnly ? 12 : headerWithDesc ? 6 : 3}
            className={`align-self-center pt-3 pt-md-0`}
          >
            {header}
          </Col>
          {description && (
            <Col
              xs={12}
              md={4}
              lg={6}
              className={`align-self-center text-center text-md-left px-0 py-3 py-md-0`}
            >
              {description}
            </Col>
          )}
          {buttons && (
            <Col
              xs={12}
              md={4}
              lg={3}
              className={`align-self-center d-flex pb-3 pb-md-0 justify-content-center justify-content-md-start`}
            >
              {buttons}
            </Col>
          )}
        </>
      )}
    </Row>
  )
}

export default BannerContainer
