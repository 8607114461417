import React from 'react'
import { Conversation } from '../Conversation'
import Section from './Section'
import Submit from './Submit'
import Cancel from './Cancel'
import { createUseStyles } from 'react-jss'
import ConversationBuilder from './builder/ConversationBuilder'
import FieldSet from './fields/FieldSet'
import SetParticipant from './fields/participant/SetParticipant'
import SetDate from './fields/date/SetDate'
import SetStartTime from './fields/time/SetStartTime'
import SetMethod from './fields/method/SetMethod'
import SetDuration from './fields/duration/SetDuration'
import SetPrimaryRelationship from './fields/relationship/SetPrimaryRelationship'
import SetGoal from './fields/goal/SetGoal'
import SetNotes from './fields/notes/SetNotes'
import SetTopics from './fields/topics/SetTopics'
import MissingFieldsErrorMessage from './MissingFieldsErrorMessage'
import ErrorMessage from './ErrorMessage'
import { PartialConversation } from './builder/state'

type ConversationFormProps = {
  conversationDetails?: PartialConversation
  errorMessage?: string
  disabled: boolean
  groupId: number
  onSubmit: (conversationDetails: Conversation) => void
  onCancel: () => void
}

const useStyles = createUseStyles({
  footerMessage: {
    flexGrow: 1,
    order: 1,
  },
  footerButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    gap: '8px',
  },
})

const ConversationForm = ({
  conversationDetails,
  errorMessage,
  disabled,
  groupId,
  onSubmit,
  onCancel,
}: ConversationFormProps): JSX.Element => {
  const classes = useStyles()
  return (
    <ConversationBuilder initialValue={conversationDetails || {}}>
      <Section>
        Please log the details of your call to track your progress.
      </Section>
      <Section title="Basic Info">
        <FieldSet>
          <SetParticipant groupId={groupId} />
          <SetDate />
          <SetStartTime />
          <SetMethod />
          <SetDuration />
        </FieldSet>
      </Section>
      <Section title="Relationships">
        <FieldSet>
          <SetPrimaryRelationship />
        </FieldSet>
      </Section>
      <Section title="Goals">
        <FieldSet>
          <SetGoal />
        </FieldSet>
      </Section>
      <Section title="Conversation Content">
        <FieldSet>
          <SetTopics />
        </FieldSet>
      </Section>
      <Section title="Notes">
        <FieldSet>
          <SetNotes />
        </FieldSet>
      </Section>
      <Section>
        <div className={classes.footerButtons}>
          {errorMessage ? (
            <ErrorMessage
              classes={{ root: classes.footerMessage }}
              message={errorMessage}
            />
          ) : null}
          <MissingFieldsErrorMessage
            classes={{ message: classes.footerMessage }}
          />
          <Submit disabled={disabled} onClick={onSubmit} />
          <Cancel disabled={disabled} onClick={onCancel} />
        </div>
      </Section>
    </ConversationBuilder>
  )
}

export default ConversationForm
