import React, { useState, useMemo, useCallback } from 'react'
import { Row, Col } from 'react-bootstrap'
import ActionButton from '../../elemets/ActionButton'
import CardContainer from '../../elemets/CardContainer'
import CardTitle from '../../elemets/CardTitle'
import LoaderPage from '../../elemets/LoaderPage'
import { getTopics } from '../../data/topics/topics.query'
import { useQuery } from '@apollo/client'

import Filter from './Filter'
import { createUseStyles } from 'react-jss'
import { ratingValues } from '../Forms/Ratings'
import colors from '../../theme/theme2'
import AutoSuggestTopic from '../Topics/AutoSuggestTopic'

const useStyles = createUseStyles({
  container: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    minHeight: 270,
  },
  autoSuggest: {
    '& .react-autosuggest__suggestions-container--open': {
      fontSize: [14, '!important'],
      paddingLeft: '10px',
      marginLeft: '0px',
      marginTop: '10px',
      color: 'black',
    },
    '& .react-autosuggest__input': {
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '10px',
      marginTop: '10px',
      marginBottom: '0px',
      width: '100%',
      height: '30px',
    },
    '& .react-autosuggest__input::placeholder': {
      color: '#575757',
      fontSize: '14px',
      fontFamily: 'lato',
      fontWeight: 'bold',
      opacity: 1,
    },
  },
  filter: {
    width: '100%',
  },
  switch: {
    color: colors.primary,
    '&:hover, &:active, &:focus': {
      color: colors.primary,
    },
    '& i': {
      fontSize: 18,
    },
    padding: 0,
    position: 'absolute',
    top: 20,
    right: 20,
  },
})

const GroupFilters = ({ matches = [], title, setFilters }) => {
  const classes = useStyles()
  const [filterState, setFilterState] = useState({
    topics: [],
    subtopics: [],
    ratings: [],
    isMentor: [],
    people: [],
  })

  const [subtopic, setSubtopic] = useState()
  const { loading, data: topics } = useQuery(getTopics)

  const handleSetFormState = useCallback(value => {
    setSubtopic([value.skillId])
  }, [])

  const handleDeleteFormState = useCallback(() => {
    setSubtopic(null)
  }, [])

  const suggestedTopics = useMemo(() => {
    return topics
      ? topics['skillgroups']
          .map(topic => {
            return {
              ...topic,
              skills: topic.skills,
            }
          })
          .filter(topic => {
            return topic.skills.length > 0
          })
      : []
  }, [topics])

  const filters = useMemo(
    () => [
      {
        name: 'ratings',
        placeholder: 'Filter by Rating',
        options: ratingValues.map(x => x.value + ' - ' + x.text),
      },
      {
        name: 'isMentor',
        placeholder: 'Mentor/Mentee',
        options: ['Mentor', 'Mentee'],
      },
      {
        name: 'people',
        placeholder: 'Filter by Person',
        options: [...matches.map(x => x.firstName + ' ' + x.lastName)],
      },
    ],
    [matches],
  )

  // update filters state
  const onFilterData = ({ filterName, selection }) => {
    const filterObj = {
      [filterName]: selection,
    }
    setFilterState({ ...filterState, ...filterObj })
  }

  // filter matches using filter states values and update filteredMatches
  const filterData = useCallback(() => {
    if (setFilters) {
      setFilters({
        subtopics: subtopic && subtopic.length > 0 ? subtopic[0] : null,
        ratings:
          filterState.ratings.length > 0
            ? filterState.ratings[0].split(' - ')[0]
            : null,
        isMentor:
          filterState.isMentor.length > 0
            ? filterState.isMentor[0] === 'Mentor'
            : null,
        fullName: filterState.people.length > 0 ? filterState.people[0] : null,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState, subtopic, setFilters])

  return (
    <CardContainer
      className={classes.container}
      header={
        <>
          <CardTitle title={title} />
          {/*<LinkButton
            icon={showFavorites ? 'fa fa-toggle-on' : 'fa fa-toggle-off'}
            text="Favorites Only"
            className={classes.switch}
            iconRight
            onClick={() => setShowFavorites(!showFavorites)}
          />*/}
        </>
      }
      headerDivider={true}
      footer={
        <div className={`d-flex justify-content-end`}>
          <ActionButton text="SEARCH" onClick={filterData} type="LIGHT_GREEN" />
        </div>
      }
    >
      {loading && <LoaderPage />}
      <Row>
        <Col xs={12} className={`pb-3 pl-4 pr-4 ${classes.typeAHeads}`}>
          <AutoSuggestTopic
            handleSetFormState={handleSetFormState}
            className={classes.autoSuggest}
            suggestedTopics={suggestedTopics}
            placeholder="Filter by topic (start typing one)"
            handleDeleteFormState={handleDeleteFormState}
          />
        </Col>
      </Row>
      <Filter
        className={classes.filter}
        filters={filters}
        data={matches}
        filterFunction={onFilterData}
      />
    </CardContainer>
  )
}

export default GroupFilters
