import React from 'react'
import KebabMenu from '../../elemets/KebabMenu'
import ManuallyLogConversation from './ManuallyLogConversation'
import CreateConversationModal from '../../conversations/details/CreateConversationModal'
import ModalProvider from '../../conversations/details/ModalProvider'

type UpcomingConversationMenuProps = {
  groupId: number
}

const UpcomingConversationMenu = ({
  groupId,
}: UpcomingConversationMenuProps): JSX.Element => {
  return (
    <ModalProvider Modal={CreateConversationModal} groupId={groupId}>
      <KebabMenu id="schedule-conversations" label="Schedule conversations">
        <ManuallyLogConversation />
      </KebabMenu>
    </ModalProvider>
  )
}

export default UpcomingConversationMenu
