import React, { useCallback, useState, useEffect } from 'react'
import compose from 'just-compose'
import { createUseStyles } from 'react-jss'
import { withRouter } from 'react-router-dom'

import { withErrorHandler } from '../../errors'

// Components
import PersonalAvatar from './PersonalAvatar'
import ResetPasswordModal from './ResetPasswordModal'

// Elements
import CardContainer from '../../elemets/CardContainer'
import CustomInput from '../../elemets/CustomInput'
import SquareButton from '../../elemets/SquareButton'
import ActionButton from '../../elemets/ActionButton'

// ApiError
import { useApiSubmit } from '../../api/hooks'

// Utils
import { isMobile } from '../../utils/common'

const useStyles = createUseStyles({
  inputContainerStyle: {
    marginBottom: 10,
  },
  personalAvatar: {
    textAlign: 'center',
    marginTop: '-20px',
    marginBottom: 22,
  },
})

const PersonalCard = ({ user, reload, history, handleError }) => {
  const classes = useStyles()
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    phoneNumber: '',
    linkedIn: '',
    location: '',
    homeTown: '',
    highSchool: '',
  })
  const [editMode, setEditMode] = useState(false)
  const [displayModal, setDisplayModal] = useState(false)
  const {
    submitted,
    submit: updateProfile,
    err: updateErr,
    submitting,
  } = useApiSubmit('updateProfileV1')

  useEffect(() => {
    setFormState({
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      title: user.title || '',
      email: user.email || '',
      phoneNumber: user.phoneNumber || '',
      linkedIn: user.linkedIn || '',
      location: user.location || '',
      homeTown: user.homeTown || '',
      highSchool: user.highSchool || '',
    })
  }, [user])

  useEffect(() => {
    if (submitted && !updateErr && !submitting) {
      setEditMode(false)
      reload()
    } else if (updateErr && !submitting) {
      handleError(updateErr, true, {
        message: 'There was a problem updating your profile.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, updateErr, submitting])

  const save = useCallback(() => {
    try {
      if (
        formState.firstName === '' ||
        formState.lastName === '' ||
        formState.email === ''
      ) {
        handleError(null, true, {
          message: 'First Name, Last Name, and Email are required.',
        })
        return
      }

      updateProfile({
        params: {
          profileId: user.id,
        },
        body: {
          firstName: formState.firstName,
          lastName: formState.lastName,
          title: formState.title,
          conversationFrequencyId: user.conversationFrequencyId,
          notificationFrequencyId: user.notificationFrequencyId,
          maxConversations: Number(user.maxConversations),
          email: formState.email,
          regionId: user.region,
          showTour: user.showTour,
          bio: user.bio,
          funFact: user.funFact,
          phoneNumber: formState.phoneNumber,
          linkedIn: formState.linkedIn,
          location: formState.location,
          homeTown: formState.homeTown,
          highSchool: formState.highSchool,
        },
      })
    } catch (err) {
      const { handleError } = this.props
      handleError(err, true, {
        message: 'There was a problem updating your profile.',
      })
    }
  }, [formState, updateProfile, user, handleError])

  const handleInput = useCallback((e) => {
    const value = e.target.value
    const name = e.target.name
    setFormState((f) => ({
      ...f,
      [name]: value,
    }))
  }, [])

  const handleChangeEditState = useCallback(() => {
    if (editMode) {
      save()
    } else {
      if (isMobile()) {
        history.push('/profile/edit', { user })
      } else {
        setEditMode(true)
      }
    }
  }, [editMode, history, save, user])

  const handleOpenResetPassword = useCallback(() => {
    if (isMobile()) {
      history.push('/profile/reset-password')
    } else {
      setDisplayModal(true)
    }
  }, [history])

  // const handleOpenPrivacySettings = useCallback(() => { }, [])

  return (
    <>
      <ResetPasswordModal
        show={displayModal}
        onClose={() => setDisplayModal(false)}
      />
      <CardContainer
        dataTestId={'my-profile__user-demographics-card'}
        footerDivider={!isMobile()}
        footer={
          <div className="d-flex justify-content-between">
            <ActionButton
              className="mr-1"
              text="RESET PASSWORD"
              onClick={handleOpenResetPassword}
            />
            {/* {!isMobile() && (
              <ActionButton
                className="ml-1"
                text="PRIVACY SETTINGS"
                onClick={handleOpenPrivacySettings}
              />
            )} */}
          </div>
        }
        header={
          <div className="d-flex justify-content-end">
            <SquareButton onClick={handleChangeEditState}>
              {editMode ? (
                <span>Save</span>
              ) : (
                <i className="material-icons">edit</i>
              )}
            </SquareButton>
          </div>
        }
      >
        <div className={classes.personalAvatar}>
          <PersonalAvatar
            avatar={user.avatar}
            fullName={`${user.firstName} ${user.lastName}`}
            description={user.title}
            avatarEditable
            score={3}
            reload={reload}
          />
        </div>
        {/* <PersonalEdit></PersonalEdit> */}
        {/* on mobile devices the PersonalEdit component houses the "edit" form below  */}
        {editMode && (
          <>
            <CustomInput
              isRequired={true}
              containerStyle={classes.inputContainerStyle}
              name="firstName"
              value={formState.firstName}
              onChange={handleInput}
              editMode={editMode}
              title="First Name"
              dataTestId={'user-demographics-card__first-name'}
            />
            <CustomInput
              isRequired={true}
              containerStyle={classes.inputContainerStyle}
              name="lastName"
              value={formState.lastName}
              onChange={handleInput}
              editMode={editMode}
              title="Last Name"
              dataTestId={'user-demographics-card__last-name'}
            />
            <CustomInput
              containerStyle={classes.inputContainerStyle}
              name="title"
              value={formState.title}
              onChange={handleInput}
              editMode={editMode}
              title="Title"
              dataTestId={'user-demographics-card__title'}
            />
          </>
        )}
        <CustomInput
          isRequired={true}
          containerStyle={classes.inputContainerStyle}
          name="email"
          value={formState.email}
          onChange={handleInput}
          editMode={editMode}
          title="Email"
          dataTestId={'user-demographics-card__email'}
        />
        <CustomInput
          containerStyle={classes.inputContainerStyle}
          name="phoneNumber"
          value={formState.phoneNumber}
          onChange={handleInput}
          editMode={editMode}
          title="Phone"
          dataTestId={'user-demographics-card__phone-number'}
        />
        <CustomInput
          containerStyle={classes.inputContainerStyle}
          name="linkedIn"
          value={formState.linkedIn}
          onChange={handleInput}
          editMode={editMode}
          title="LinkedIn Profile"
          dataTestId={'user-demographics-card__linked-in'}
        />
        <CustomInput
          containerStyle={classes.inputContainerStyle}
          name="location"
          value={formState.location}
          onChange={handleInput}
          editMode={editMode}
          title="Location"
          dataTestId={'user-demographics-card__location'}
        />
        <CustomInput
          containerStyle={classes.inputContainerStyle}
          name="homeTown"
          value={formState.homeTown}
          onChange={handleInput}
          editMode={editMode}
          title="Home Town"
          dataTestId={'user-demographics-card__home-town'}
        />
        <CustomInput
          containerStyle={classes.inputContainerStyle}
          name="highSchool"
          value={formState.highSchool}
          onChange={handleInput}
          editMode={editMode}
          title="High School"
          dataTestId={'user-demographics-card__high-school'}
        />
      </CardContainer>
    </>
  )
}

export default compose(withErrorHandler, withRouter)(PersonalCard)
