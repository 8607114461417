import React, { useCallback, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import ReScheduleModal from '../../components/Modals/Conversation/ReScheduleModal'

type RescheduleProps = {
  availabilityToken: string
  conversationId: number
  participantId: number
  skillId: number
  disabled?: boolean
  onScheduled: () => void
  className?: string
}

const Reschedule = ({
  availabilityToken,
  conversationId,
  participantId,
  skillId,
  disabled = false,
  onScheduled,
  className,
}: RescheduleProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  return (
    <>
      <Dropdown.Item
        onClick={handleOpen}
        disabled={disabled}
        className={className}
      >
        Reschedule
      </Dropdown.Item>
      <ReScheduleModal
        show={open}
        conversation={{
          id: conversationId,
          participants: [{ profileId: participantId, currentUser: false }],
          skillId,
          availabilityToken,
        }}
        onClose={handleClose}
        reloadUpcoming={() => {
          onScheduled()
        }}
      />
    </>
  )
}

export default Reschedule
