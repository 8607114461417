import React, { useCallback } from 'react'
import StartTimeField, { TimeValue } from './StartTimeField'
import { useConversationDetailsBuilder } from '../../builder/ConversationBuilder'
import { PartialConversation } from '../../builder/state'

const selectTime = (value: PartialConversation): TimeValue | undefined => {
  if (!value) {
    return undefined
  }

  const { startTime } = value
  if (typeof startTime !== 'number') {
    return undefined
  }

  const startTimeMinutes = startTime / 60
  const hours = Math.trunc(startTimeMinutes / 60)
  const minutes = startTimeMinutes - hours * 60
  return { hours, minutes }
}

const SetStartTime = (): JSX.Element => {
  const { value, setStartTime } = useConversationDetailsBuilder()
  const time = selectTime(value)
  const handleChange = useCallback(
    (time: TimeValue) => {
      const { hours, minutes } = time
      setStartTime(hours, minutes)
    },
    [setStartTime],
  )

  return <StartTimeField value={time} onChange={handleChange} />
}

export default SetStartTime
