import React from 'react'
import ParticipantField from './ParticipantField'
import GroupParticipants from './data/GroupParticipants'
import { useConversationDetailsBuilder } from '../../builder/ConversationBuilder'
import { PartialConversation } from '../../builder/state'

const selectParticipant = (value: PartialConversation): number | undefined =>
  value.participant

type SetParticipantProps = {
  groupId: number
}

const SetParticipant = ({ groupId }: SetParticipantProps): JSX.Element => {
  const { value, setParticipant, clearParticipant } =
    useConversationDetailsBuilder()
  const participant = selectParticipant(value)

  return (
    <GroupParticipants groupId={groupId}>
      <ParticipantField
        value={participant}
        onChange={setParticipant}
        onClear={clearParticipant}
      />
    </GroupParticipants>
  )
}

export default SetParticipant
