import React from 'react'
import { createUseStyles } from 'react-jss'

// Elements
import ConfirmationModal from './ConfirmationModal'
import Divider from '../../elemets/Divider'

// Theme
import colors from '../../theme/theme2'
import LinkAccountButton from '../AccountLinking/Buttons/LinkAccountButton'
import MicrosoftLoginButton from '../AccountLinking/Buttons/MicrosoftLoginButton'

const useStyles = createUseStyles({
  integrationModal: {
    '& .modal-dialog': {
      justifyContent: 'center',
    },
    '& .modal-content': {
      '&>div': {
        '&>div': {
          backgroundColor: 'unset',
          padding: 0,
        },
      },
      width: '70%',
      margin: '70% auto',
    },
  },
  provider: {
    fontFamily: 'lato',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
    width: '100%',
    backgroundColor: '#ffff',
    border: 'none',
    color: colors.primary,
    padding: '7px 12px',
    '& .google-square': {
      height: 38,
      width: 38,
      border: '1px solid #4285f4',
      borderRadius: 2,
      backgroundColor: '#ffffff',
      marginRight: 11,
      boxShadow: '0 3px 6px 0 rgba(153, 155, 168, 0.61)',
    },
    '&:hover': {
      backgroundColor: '#ffff',
      color: colors.primary,
    },
    '&:active': {
      color: `${colors.primary} !important`,
      backgroundColor: `${colors.grey1} !important`,
      '& .google-square': {
        boxShadow: '0 0 0 0.2rem rgba(38,143,255,.5)',
      },
    },
    '&:focus': {
      boxShadow: 'none !important',
    },
    '&:disabled, &:disabled:hover': {
      color: '#707575 !important',
      backgroundColor: '#ffff !important',
      boxShadow: 'unset',
      '& .google-square': {
        border: 'none',
        backgroundColor: `${colors.grey2} !important`,
        boxShadow: 'none !important',
      },
    },
  },
  google: {
    color: colors.grey3,
    fontSize: 18,
  },
  dividerColor: {
    backgroundColor: '#e8ecef !important',
  },
})

const IntegrationModal = ({
  show,
  onClose,
  linkGoogleAccount,
  linkMicrosoftAccount,
  microsoftRedirectURI,
}) => {
  const classes = useStyles()
  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={'Add Integration'}
      content={
        <>
          <Divider className={`mb-0 ${classes.dividerColor}`} />
          <div className="d-flex align-items-center">
            <LinkAccountButton linkAccount={linkGoogleAccount} />
          </div>
          <Divider className={`mt-0 mb-5 ${classes.dividerColor}`} />
          <Divider className={`mb-0 ${classes.dividerColor}`} />
          <div className="d-flex align-items-center">
            <MicrosoftLoginButton
              linkMicrosoftAccount={linkMicrosoftAccount}
              microsoftRedirectURI={microsoftRedirectURI}
            />
          </div>
          <Divider className={`mt-0 mb-5 ${classes.dividerColor}`} />
        </>
      }
      closeIcon={true}
      className={classes.integrationModal}
    />
  )
}

export default IntegrationModal
