import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { connect } from 'twilio-video'
import compose from 'just-compose'
import moment from 'moment'

import VideoCallButtons from './VideoCallButtons'
import CircleName from '../../elemets/CircleName'
import CircleImage from '../../elemets/CircleImage'

import colors, { headings } from '../../theme/theme2'
import { withErrorHandler } from '../../errors'
import { useApiSubmit } from '../../api/index'
import { withNotifications } from '../../notifications'
import { CALL_LAYOUT } from '../Conversations/conversations.constants'
import { useUnmount } from 'usehooks-ts'
import { log } from '@/utils/logger'

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    overflowX: 'hidden',
  },
  fullScreenContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100vw',
    display: 'flex',
    zIndex: 10000,
    alignItems: 'center',
    backgroundColor: colors.dark,
    overflowY: 'hidden',
  },
  fullScreen: {
    height: '100%',
    width: '100%',
    backgroundColor: colors.dark,
    '& p': {
      color: 'white!important',
    },
  },
  ownVideoContainer: {
    position: 'absolute',
    top: 25,
    right: 25,
    width: '20%',
    minWidth: 100,
    maxWidth: 300,
    '&:before': {
      display: 'block',
      content: '""',
      width: '100%',
      paddingTop: 'calc((9 / 16) * 100%)',
    },
    '& > .inner': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      overflow: 'hidden',
    },
  },
  ownVideoFullScreen: {
    height: '100%',
    '& video': {
      width: '100%',
    },
  },
  ownVideoSmallScreen: {
    width: '100%',
    '& video': {
      width: '100%',
    },
  },
  videoFullScreen: {
    width: '100%',
    '& video': {
      width: '100%',
    },
  },
  videoSmallScreen: {
    width: '100%',
    '& video': {
      width: '100%',
    },
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '25%',
  },
  avatar: {
    fontSize: '48px !important',
    fontWeight: 'bold !important',
    color: 'white !important',
    backgroundColor: `${colors.primary} !important`,
  },

  emptyRoom: {
    ...headings.title,
    color: colors.dark,
    textAlign: 'center',
    padding: '25%',
  },

  close: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 10,
    alignItems: 'center',
    display: 'flex',
    padding: '3px',
    backgroundColor: 'transparent',
    border: 'none',
    color: colors.dark,
    '&:focus': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      color: colors.dark,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&:active': {
      color: `${colors.dark} !important`,
      backgroundColor: 'transparent !important',
      border: 'none !important',
    },
  },

  outer: {
    position: 'relative',
    '&:before': {
      display: 'block',
      content: '""',
      width: '100%',
      paddingTop: 'calc((9 / 16) * 100%)',
    },
    '& > .inner': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      overflow: 'hidden',
    },
  },
})

function handleDisconnect(currentRoom) {
  if (currentRoom.current) {
    if (currentRoom.current?.localParticipant) {
      log.debug('de-registering tracks')
      currentRoom.current.localParticipant.tracks.forEach(function (track) {
        track.track.stop()
      })
    }

    currentRoom.current.disconnect()
    log.info('disconnected the current room')
  }
}

const VideoCall = React.memo(
  ({
    me,
    otherParticipant,
    handleError,
    conversation,
    displayFeedback,
    startTime,
    endTime,
    allowedAfterTime,
    onTimeChange,
    isExtendingConversation,
    selectedLayout,
    setSelectedLayout,
  }) => {
    const classes = useStyles()
    const currentRoom = useRef(null)
    const screenTrack = useRef(null)
    const [mute, setMute] = useState(false)
    const [camOff, setCamOff] = useState(false)
    const [hideCamPrev, setHideCamPrev] = useState(false)
    const [displayAvatar, setDisplayAvatar] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const [shareScreen, setShareScreen] = useState(false)
    const [displayEmptyRoom, setDisplayEmptyRoom] = useState(true)
    const [displayConversationEnded, setDisplayConversationEnded] =
      useState(false)
    const [hasVideo, setHasVideo] = useState(false)
    const [hasAudio, setHasAudio] = useState(false)
    const [hasScreenShare, setHasScreenShare] = useState(false)

    // handle disconnection when navigating in-app
    useUnmount(() => {
      log.debug('unmount from: VideoCall.jsx')
      handleDisconnect(currentRoom)
    })

    // handle disconnection when closing the tab
    const exitHandler = useCallback(() => {
      handleDisconnect(currentRoom)
    }, [])
    useEffect(() => {
      window.addEventListener('beforeunload', exitHandler)

      return () => {
        window.removeEventListener('beforeunload', exitHandler)
      }
    }, [exitHandler])

    const {
      result: videoCallData,
      submit: createPresence,
      err,
    } = useApiSubmit('rtc.createPresence')

    const finishConversation = useCallback(() => {
      displayFeedback(true)
    }, [displayFeedback])

    useEffect(() => {
      if (selectedLayout !== CALL_LAYOUT.IN) {
        return
      }
      const end = moment.utc(allowedAfterTime)
      const autoCloseConvo = () => {
        const now = moment.utc()

        // If currentTime is after endTime
        if (now.isSameOrAfter(end)) {
          log.info('auto closing conversation')
          handleDisconnect(currentRoom)
          finishConversation()
        }
      }

      autoCloseConvo()
      const clockId = setInterval(autoCloseConvo, 500)
      // setClock(clockId)

      return () => clearInterval(clockId)
    }, [allowedAfterTime, finishConversation, selectedLayout])

    useEffect(() => {
      if (selectedLayout !== CALL_LAYOUT.IN) {
        return
      }
      createPresence({ params: { conversationId: conversation.id } })
    }, [conversation, selectedLayout, createPresence])

    useEffect(() => {
      if (err) {
        handleError(err, true, {
          message: `You are trying to join a conversation that already ended`,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [err])

    const initRoom = useCallback((room) => {
      currentRoom.current = room

      room.localParticipant.tracks.forEach((publication) => {
        if (publication.track) {
          document
            .getElementById('own-media-div')
            .appendChild(publication.track.attach())
        }
        // unsubscribe from tracks when component unmounts
        return () => {
          if (publication.unpublish) {
            publication.unpublish()
          }
        }
      })

      // Participants already in the room
      room.participants.forEach((participant) => {
        participant.tracks.forEach((publication) => {
          if (publication.track) {
            document
              .getElementById('remote-media-div')
              .appendChild(publication.track.attach())
          }
          if (publication.isSubscribed) {
            handleTrackEnabled(publication.track)
          }
          publication.on('subscribed', handleTrackEnabled)
        })

        participant.on('trackSubscribed', (track) => {
          handleTrackSubscribed(track)
        })

        participant.on('trackUnsubscribed', (track) => {
          handleTrackUnsubscribed(track)
        })
      })

      // new participants connected
      room.on('participantConnected', (participant) => {
        participant.tracks.forEach((publication) => {
          if (publication.isSubscribed) {
            const track = publication.track
            document
              .getElementById('remote-media-div')
              .appendChild(track.attach())
            handleTrackEnabled(publication.track)
          }
          publication.on('subscribed', handleTrackEnabled)
        })
        participant.tracks.forEach((publication) => {
          if (publication.track) {
            document
              .getElementById('remote-media-div')
              .appendChild(publication.track.attach())
          }
        })

        participant.on('trackSubscribed', (track) => {
          handleTrackSubscribed(track)
        })

        participant.on('trackUnsubscribed', (track) => {
          handleTrackUnsubscribed(track)
        })
        setDisplayEmptyRoom(false)
      })

      // Listen participants disconnection
      room.on('disconnected', () => {
        setDisplayEmptyRoom(false)
        setDisplayConversationEnded(true)
      })
      room.on('participantDisconnected', () => {
        setDisplayEmptyRoom(false)
        setDisplayConversationEnded(true)
      })
    }, [])

    // TODO async useEffect
    const isMounted = useRef(true)
    useEffect(() => {
      if (selectedLayout !== CALL_LAYOUT.IN || !videoCallData) {
        return
      }

      const abortController = new AbortController()
      const signal = abortController.signal

      const constraints = { audio: true, video: true }

      const getUserMediaPromise =
        navigator.mediaDevices.getUserMedia(constraints)

      const connectToRoomWithVideo = () =>
        connect(videoCallData.videoToken, {
          name: videoCallData.room,
          iceTransportPolicy: 'relay',
          enableDscp: true,
          params: {
            audioConstraints: {
              echoCancellation: true,
              noiseSuppression: true,
            },
          },
        })

      const connectToRoomWithAudio = () =>
        connect(videoCallData.videoToken, {
          name: videoCallData.room,
          video: false,
          audio: true,
          iceTransportPolicy: 'relay',
          enableDscp: true,
          params: {
            audioConstraints: {
              echoCancellation: true,
              noiseSuppression: true,
            },
          },
        })

      getUserMediaPromise
        .then(connectToRoomWithVideo, connectToRoomWithAudio)
        .then((room) => {
          if (isMounted.current) {
            initRoom(room)
          }
        })
        .catch((error) => {
          if (!signal.aborted) {
            handleError(error, true, {
              message: `Unable to connect to Room: ${error.message}`,
            })
          }
        })

      return () => {
        isMounted.current = false
        abortController.abort()
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initRoom, selectedLayout, videoCallData])

    function handleTrackEnabled(track) {
      if (track.kind === 'video') {
        const handleEnable = () => {
          document.getElementById('remote-media-div').style.display = 'inline'
          setDisplayAvatar(false)
        }
        const handleDisable = () => {
          document.getElementById('remote-media-div').style.display = 'none'
          setDisplayAvatar(true)
        }

        track.on('enabled', handleEnable)
        track.on('disabled', handleDisable)

        return () => {
          track.off('enabled', handleEnable)
          track.off('disabled', handleDisable)
        }
      }
      //   track.on('enabled', () => {
      //     /* Hide the avatar image and show the associated <video> element. */
      //     document.getElementById('remote-media-div').style.display = 'inline'
      //     setDisplayAvatar(false)
      //   })
      //   track.on('disabled', () => {
      //     /* Hide the associated <video> element and show an avatar image. */
      //     document.getElementById('remote-media-div').style.display = 'none'
      //     setDisplayAvatar(true)
      //   })
      // }
      // // add return function to remove listeners
      // return () => {
      //   track.off('enabled')
      //   track.off('disabled')
      // }
    }

    function handleTrackUnsubscribed(track) {
      if (track.name.includes('screen')) {
        document.getElementById('remote-media-div').style.display = 'inline'
        document.getElementById('remote-screen-share-dive').innerHTML = ''
        setHasScreenShare(false)
      } else {
        if (track.kind === 'video') {
          setHasVideo(false)
        } else if (track.kind === 'audio') {
          setHasAudio(false)
        }
        if (document.getElementById('remote-media-div')) {
          document.getElementById('remote-media-div').innerHTML = ''
        }
        if (document.getElementById('remote-screen-share-dive')) {
          document.getElementById('remote-screen-share-dive').innerHTML = ''
        }
      }
    }

    function handleTrackSubscribed(track) {
      setDisplayEmptyRoom(false)
      setDisplayConversationEnded(false)
      if (track.name.includes('screen')) {
        document
          .getElementById('remote-screen-share-dive')
          .appendChild(track.attach())
        document.getElementById('remote-media-div').style.display = 'none'
        setHasScreenShare(true)
      } else {
        if (track.kind === 'video') {
          // document.getElementById('remote-media-div').innerHTML = ''
          setHasVideo(true)
        }
        if (track.kind === 'audio') {
          setHasAudio(true)
        }
        document.getElementById('remote-media-div').appendChild(track.attach())
        if (track.kind === 'video' && !track.isEnabled) {
          setDisplayAvatar(true)
          document.getElementById('remote-media-div').style.display = 'none'
        }
      }
    }

    const handleMute = useCallback(() => {
      setMute((m) => {
        if (m) {
          currentRoom.current.localParticipant.audioTracks.forEach(
            (publication) => {
              publication.track.enable()
            },
          )
        } else {
          currentRoom.current.localParticipant.audioTracks.forEach(
            (publication) => {
              publication.track.disable()
            },
          )
        }
        return !m
      })
    }, [currentRoom])

    const handleCamOff = useCallback(() => {
      setCamOff((c) => {
        if (c) {
          currentRoom.current.localParticipant.videoTracks.forEach(
            (publication) => {
              publication.track.enable()
            },
          )
        } else {
          currentRoom.current.localParticipant.videoTracks.forEach(
            (publication) => {
              if (
                !screenTrack.current ||
                (screenTrack.current &&
                  screenTrack.current.trackName !== publication.track.name)
              ) {
                publication.track.disable()
              }
            },
          )
        }
        return !c
      })
    }, [currentRoom])

    const handleEndCall = useCallback(() => {
      handleDisconnect(currentRoom)
      finishConversation()
    }, [currentRoom, finishConversation])

    useEffect(() => {
      if (selectedLayout !== CALL_LAYOUT.IN) {
        return
      }
      if (!camOff && !hideCamPrev) {
        if (currentRoom.current) {
          currentRoom.current.localParticipant.tracks.forEach((publication) => {
            if (publication.track) {
              document
                .getElementById('own-media-div')
                .appendChild(publication.track.attach())
            }
          })
        }
      }
    }, [camOff, currentRoom, hideCamPrev, selectedLayout])

    const handleSharScreen = useCallback(() => {
      if (!screenTrack.current) {
        navigator.mediaDevices
          .getDisplayMedia()
          .then((stream) => {
            currentRoom.current.localParticipant
              .publishTrack(stream.getVideoTracks()[0], {
                logLevel: 'info',
                name: `screen-${stream.getVideoTracks()[0].id}`,
              })
              .then((publication) => {
                screenTrack.current = publication
                setShareScreen(true)
                // on stop sharing
                stream.getVideoTracks()[0].onended = function () {
                  currentRoom.current.localParticipant.unpublishTrack(
                    publication.track,
                  )
                  publication.track.stop()
                  screenTrack.current = null
                  setShareScreen(false)
                }
              })
          })
          .catch((e) => {
            alert(`Could not share the screen. ${e}`)
          })
      } else {
        currentRoom.current.localParticipant.unpublishTrack(
          screenTrack.current.track,
        )
        screenTrack.current.track.stop()
        screenTrack.current = null
        setShareScreen(false)
      }
    }, [currentRoom, screenTrack])

    const handleFullScreen = useCallback(() => {
      const elem = document.getElementById('video-call')

      /* View in fullscreen */
      if (fullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen()
        }
      } else {
        /* Close fullscreen */
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen()
        }
      }
      setFullScreen((f) => !f)
    }, [fullScreen])

    const handlePip = useCallback(() => {
      setHideCamPrev((s) => !s)
    }, [])

    return (
      <>
        <div id="video-call" className={classes.outer}>
          <div className={fullScreen ? classes.fullScreen : 'w-100'}>
            <VideoCallButtons
              isMute={mute}
              isCamOff={camOff}
              onMute={handleMute}
              onCamOff={handleCamOff}
              onEndCall={handleEndCall}
              onFullScreen={handleFullScreen}
              onPip={handlePip}
              onHandleSharScreen={handleSharScreen}
              onExtendConversationEndTime={onTimeChange}
              startTime={startTime}
              endTime={endTime}
              isExtendingConversation={isExtendingConversation}
              isSharing={shareScreen}
              settingOptions={[
                {
                  id: 1,
                  label: fullScreen ? 'Small Screen' : 'Full Screen',
                  action: handleFullScreen,
                },
                {
                  id: 2,
                  label: shareScreen ? 'Stop Sharing' : 'Share Screen',
                  action: handleSharScreen,
                },
              ]}
            />
          </div>
          <div className={'inner'}>
            <div className={fullScreen ? classes.fullScreen : 'w-100'}>
              {((!hasVideo && !hasScreenShare && hasAudio) ||
                (!hasScreenShare && displayAvatar)) && (
                <div className={classes.avatarContainer}>
                  {otherParticipant.avatar ? (
                    <CircleImage
                      id="avatar-name"
                      size={120}
                      className={classes.avatar}
                      src={otherParticipant.avatar}
                    />
                  ) : (
                    <CircleName
                      id="avatar-name"
                      className={classes.avatar}
                      text={
                        otherParticipant.firstName && otherParticipant.lastName
                          ? otherParticipant.firstName[0] +
                            otherParticipant?.lastName[0]
                          : ''
                      }
                      size={12}
                    />
                  )}
                </div>
              )}
              {displayEmptyRoom && (
                <p className={classes.emptyRoom}>
                  Please wait for the other person to join.
                  <br />
                  <i>
                    We will keep this conversation open until{' '}
                    {moment.utc(allowedAfterTime).local().format('hh:mm A')}.
                  </i>
                </p>
              )}
              {displayConversationEnded && (
                <p className={classes.emptyRoom}>The conversation ended</p>
              )}
              <div
                id="remote-media-div"
                className={
                  hasScreenShare
                    ? 'd-none'
                    : fullScreen
                      ? classes.videoFullScreen
                      : classes.videoSmallScreen
                }
              />
              <div
                id="remote-screen-share-dive"
                className={
                  fullScreen
                    ? classes.videoFullScreen
                    : classes.videoSmallScreen
                }
              />
              <div className={classes.ownVideoContainer}>
                {!camOff && !hideCamPrev && (
                  <div
                    id="own-media-div"
                    className={`${
                      fullScreen
                        ? classes.ownVideoFullScreen
                        : classes.ownVideoSmallScreen
                    } inner`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  },
)

const VideoCallWrapper = ({
  otherParticipant,
  handleError,
  conversation,
  displayFeedback,
  pushNotification,
  elapsedSeconds,
  onTimeChange,
  startTime,
  endTime,
  allowedAfterTime,
  isExtendingConversation,
  selectedLayout,
  setSelectedLayout,
}) => {
  const internalHandleError = React.useCallback(
    (...args) => handleError(...args),
    [handleError],
  )
  return (
    <VideoCall
      otherParticipant={otherParticipant}
      handleError={internalHandleError}
      conversation={conversation}
      displayFeedback={displayFeedback}
      pushNotification={pushNotification}
      startTime={startTime}
      endTime={endTime}
      allowedAfterTime={allowedAfterTime}
      elapsedSeconds={elapsedSeconds}
      onTimeChange={onTimeChange}
      isExtendingConversation={isExtendingConversation}
      selectedLayout={selectedLayout}
      setSelectedLayout={setSelectedLayout}
    />
  )
}

export default compose(withNotifications, withErrorHandler)(VideoCallWrapper)
