import React from 'react'
import { PartialConversation } from '../../builder/state'
import { useConversationDetailsBuilder } from '../../builder/ConversationBuilder'
import NotesField from './NotesField'

const selectNotes = (value: PartialConversation): string | undefined =>
  value.notes

const SetNotes = (): JSX.Element => {
  const { value, setNotes } = useConversationDetailsBuilder()
  const goal = selectNotes(value)
  return <NotesField value={goal} onChange={setNotes} />
}

export default SetNotes
