import React, { useMemo, useCallback } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useParticipants } from './data/ParticipantsProvider'
import { Participant, ParticipantId } from './data/Participant'
import { useCurrentUser } from '../../../../../components/Context/CurrentUser'

type ParticipantSelectorProps = {
  value?: ParticipantId
  onChange: (newValue: ParticipantId) => void
  onClear: () => void
}

const ParticipantSelector = ({
  value,
  onChange,
  onClear,
}: ParticipantSelectorProps): JSX.Element => {
  const { candidates, ready: participantsReady } = useParticipants()
  const { currentUser, loading: loadingCurrentUser } = useCurrentUser()
  const ready = participantsReady && !loadingCurrentUser

  const otherMembers = useMemo(() => {
    if (!currentUser?.id) return
    return candidates.filter(({ id }) => id !== currentUser.id)
  }, [currentUser, candidates])

  const selected = useMemo(() => {
    if (!value) {
      return []
    }
    return otherMembers?.filter(({ id }) => id === value) ?? []
  }, [value, otherMembers])

  const handleChange = useCallback(
    ([newValue]: Participant[]) => {
      if (newValue) {
        onChange(newValue.id)
        return
      }

      onClear()
    },
    [onChange, onClear],
  )

  return (
    <Typeahead
      id="participantSelect"
      options={otherMembers ?? []}
      selected={selected}
      disabled={!ready}
      labelKey="name"
      onChange={handleChange}
    />
  )
}

export default ParticipantSelector
