import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import compose from 'just-compose'
import { createUseStyles } from 'react-jss'

// Theme
import { Dropdown } from '../Forms'
import Ratings from '../Forms/Ratings'
import { withErrorHandler } from '../../errors'
import { withNotifications } from '../../notifications'
import Modal from '../../elemets/Modal'
import ActionButton from '../../elemets/ActionButton'
import colors, { headings } from '../../theme/theme2'
import { useApiSubmit } from '../../api'
import { isMobile } from '../../utils/common'
import Icon from '../Icons'
import CustomTextArea from '../../elemets/CustomTextArea'
import { useCurrentUser } from '../Context/CurrentUser'
import AutoSuggestTopic from './AutoSuggestTopic'
import { useQuery } from '@apollo/client'
import { getTopics } from '../../data/topics/topics.query'
import LoaderPage from '../../elemets/LoaderPage'
import LinkButton from '../../elemets/LinkButton'

const useStyles = createUseStyles({
  body: {
    margin: 0,
    padding: '15 15 0 15',
  },
  title: {
    ...headings.title,
    fontSize: 20,
    paddingRight: 10,
  },
  label: {
    ...headings.title,
    fontSize: 12,
  },
  formInputs: {
    '& .form-group': {
      margin: 0,
    },
    '& select': {
      fontSize: 12,
    },
    '& input': {
      fontSize: 12,
    },
    '& textarea': {
      fontSize: 12,
    },
    '& .dropdown-menu': {
      fontSize: 12,
    },
    '& .dropdown-toggle': {
      fontSize: 12,
    },
  },
  icon: {
    '& i': {
      cursor: 'unset',
      '&:before': {
        fontSize: 20,
        color: colors.primary,
      },
    },
  },
  inputCounter: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: '#8a8a8a',
  },
  red: {
    color: colors.red,
  },
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  interest: {
    minHeight: isMobile() ? 80 : 120,
  },
  autoSuggest: {
    '& .react-autosuggest__suggestions-container--open': {
      fontSize: [12, '!important'],
    },
  },
  suggest: {
    alignSelf: 'center',
    color: colors.primary,
    marginBottom: 10,
    '&:hover': {
      color: colors.primary,
    },
    '& > i': {
      color: colors.white,
      backgroundColor: colors.primary,
      borderRadius: '50%',
      padding: 3.33,
      width: '20px',
      height: '20px',
    },
  },
  topicRow: {
    justifyContent: 'flex-end',
  },
})

const TopicsModal = ({
  title = 'Add Topics',
  edit = false,
  editTopic,
  show,
  onClose,
  onSuggest,
  handleError,
}) => {
  const classes = useStyles()
  const [formState, setFormState] = useState({
    skillId: editTopic?.skillId || 0,
    rating: editTopic?.rating || 1,
    years: editTopic?.years || 1,
    summary: editTopic?.summary || '',
    isMentor: editTopic?.isMentor ? '1' : '0' || 0,
  })
  const { currentUser, reload } = useCurrentUser()
  const { loading, data: topics } = useQuery(getTopics)

  const {
    submitted,
    submit: addTopicToUser,
    submitting,
    err,
  } = useApiSubmit('addTopic')

  const {
    submitted: updateSubmitted,
    submit: updateTopicToUser,
    submitting: updateSubmitting,
    err: updateErr,
  } = useApiSubmit('updateTopic')

  useEffect(() => {
    if (submitted && !err && !submitting) {
      reload()
      onClose()
    } else if (err && !submitting) {
      handleError(err, true, {
        message: 'There was a problem adding the topic.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, err, submitting])

  useEffect(() => {
    if (updateSubmitted && !updateErr && !updateSubmitting) {
      reload()
      onClose()
    } else if (updateErr && !updateSubmitting) {
      handleError(updateErr, true, {
        message: 'There was a problem updating the topic.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSubmitted, updateErr, updateSubmitting])

  const handleAddTopic = useCallback(() => {
    if (!currentUser?.id) return
    addTopicToUser({
      params: {
        profileId: currentUser.id,
      },
      body: {
        rating: formState.rating,
        skillId: Number(formState.skillId),
        summary: formState.summary || '',
        years: parseInt(formState.years || 1),
        isMentor: formState.isMentor === '1',
      },
    })
  }, [currentUser, formState, addTopicToUser])

  const handleUpdateTopic = useCallback(() => {
    if (!currentUser?.id) return
    updateTopicToUser({
      params: {
        profileId: currentUser.id,
        topicId: editTopic.id,
      },
      body: {
        rating: formState.rating,
        skillId: Number(formState.skillId),
        summary: formState.summary || '',
        years: parseInt(formState.years || 1),
        isMentor: formState.isMentor === '1',
        typeId: 1,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, formState])

  const suggestedTopics = useMemo(() => {
    if (!currentUser?.id) return
    return topics
      ? topics['skillgroups']
          .map((topic) => {
            return {
              ...topic,
              skills: topic.skills.filter((subtopic) => {
                return (
                  !currentUser.topics.some(
                    (topic) => topic.skillId === subtopic.skillId,
                  ) ||
                  (edit && editTopic.skillId === subtopic.skillId)
                )
              }),
            }
          })
          .filter((topic) => {
            return topic.skills.length > 0
          })
      : []
  }, [currentUser, edit, editTopic, topics])

  const handleSetFormState = useCallback(
    (value) => {
      setFormState({ ...formState, skillId: value.skillId })
    },
    [formState],
  )

  return (
    <Modal
      show={show}
      close={onClose}
      title={
        <div
          className={`${classes.icon} ${
            isMobile() ? 'd-flex justify-content-center' : ''
          }`}
        >
          <label className={`${classes.title}`}>{title}</label>
          <Icon icon="fas fa-leaf" />
        </div>
      }
      footer={
        <Row noGutters className="justify-content-end">
          <ActionButton
            text={edit ? 'UPDATE' : 'ADD'}
            onClick={edit ? handleUpdateTopic : handleAddTopic}
            type="STRONG_GREEN"
            loading={false}
            disabled={formState.skillId === 0 || formState.summary.length > 500}
          />
        </Row>
      }
      closeIcon={true}
      bodyClass={classes.body}
      dialogClassName={isMobile() ? classes.dialogMobile : ''}
    >
      {loading && <LoaderPage />}
      <Row className={classes.topicRow}>
        <LinkButton
          icon="fas fa-plus"
          text="Suggest a Topic"
          className={classes.suggest}
          onClick={() => onSuggest()}
        />
      </Row>
      <Row noGutters className={`pb-2`}>
        <Col xs={4} className={classes.label}>
          <label htmlFor="groupId">Topic</label>
        </Col>
        <Col xs={8} className={classes.formInputs}>
          <AutoSuggestTopic
            suggestedTopics={suggestedTopics}
            handleSetFormState={handleSetFormState}
            className={classes.autoSuggest}
            initialValue={edit ? editTopic.skillName : ''}
            setDisplayModal={onSuggest}
          />
        </Col>
      </Row>

      <Row noGutters className={`pb-2`}>
        <Col xs={4} className={classes.label}>
          <label htmlFor="rating">
            {isMobile() ? 'Self-rating' : 'Self-rating experience level'}
          </label>
        </Col>
        <Col xs={8} className={classes.formInputs}>
          <Ratings
            name="rating"
            value={formState.rating}
            onChange={(e) => {
              setFormState({ ...formState, rating: e.target.value })
            }}
            required
          />
        </Col>
      </Row>

      <Row noGutters className={`pb-2`}>
        <Col xs={4} className={classes.label}>
          <label htmlFor="years">
            {isMobile() ? 'Experience' : 'Years of experience on this topic'}
          </label>
        </Col>
        <Col xs={8} className={classes.formInputs}>
          <Dropdown
            name="years"
            value={formState.years}
            options={[...Array(100).keys()].map((index) => {
              return { id: index, name: index }
            })}
            text={formState.years}
            onChange={(e) => {
              setFormState({ ...formState, years: e.target.value })
            }}
          />
        </Col>
      </Row>
      <Row noGutters className={`pb-2`}>
        <Col xs={4} className={classes.label}>
          <label htmlFor="isMentor">Mentor or Mentee</label>
        </Col>
        <Col xs={8} className={classes.formInputs}>
          <Dropdown
            name="isMentor"
            value={formState.isMentor}
            options={[
              { id: '0', name: 'Mentee' },
              { id: '1', name: 'Mentor' },
            ]}
            text={formState.isMentor}
            onChange={(e) => {
              setFormState({ ...formState, isMentor: e.target.value })
            }}
          />
        </Col>
      </Row>

      <Row noGutters className={`pb-2`}>
        <Col xs={4} className={classes.label}>
          <label htmlFor="summary">Why a topic of interest?</label>
        </Col>
        <Col xs={8} className={classes.formInputs}>
          <CustomTextArea
            name="summary"
            value={formState.summary}
            onChange={(e) => {
              setFormState({ ...formState, summary: e.target.value })
            }}
            placeholder={
              !(formState.isMentor === '1')
                ? 'What are you looking to learn?'
                : 'What do you want to share?'
            }
            maxLength={500}
            className={classes.interest}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default compose(withErrorHandler, withNotifications)(TopicsModal)
