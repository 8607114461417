import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import compose from 'just-compose'
import { createUseStyles } from 'react-jss'

import { withErrorHandler } from '../../errors'

// Elements
import Modal from '../../elemets/Modal'
import CustomInput from '../../elemets/CustomInput'
import ActionButton from '../../elemets/ActionButton'
import LinkButton from '../../elemets/LinkButton'
import CustomCheckbox from '../../elemets/CustomCheckbox'
import LoaderPage from '../../elemets/LoaderPage'

// Api
import { useApiFetch, useApiSubmit } from '../../api/index'

// Theme
import colors from '../../theme/theme2'

// Utils
import { getDateToAPI, getDateFromAPI, isMobile } from '../../utils/common'
import CustomSelect from '../../elemets/CustomSelect'
import moment from 'moment'

const YEARS = () => {
  const years = []
  const dateStart = moment()
  const dateEnd = moment().subtract(100, 'y')
  while (dateEnd.diff(dateStart, 'years') < 0) {
    years.push({
      label: dateStart.format('YYYY'),
      value: dateStart.format('YYYY'),
    })
    dateStart.subtract(1, 'year')
  }
  return years
}

const useStyles = createUseStyles({
  input: {
    marginBottom: 14,
  },
  delete: {
    color: colors.red,
    '&:hover': {
      color: colors.red,
    },
  },
  present: {
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.greyParagraph,
    marginLeft: 10,
  },
  addButton: {
    float: 'right',
    color: colors.primary,
    '&:hover, &:active, &:focus': {
      color: colors.primary,
    },
  },
  addCompany: {
    marginBottom: 14,
  },
  endDateInput: {
    width: '100%',
    padding: '0 16px',
    border: `1px solid ${colors.grey1}`,
    borderRadius: 4,
    color: colors.dark,
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: 16,
    '&:disabled': {
      marginTop: 24,
      color: colors.grey2,
      cursor: 'default',
      border: '1px solid transparent',
      backgroundColor: 'white',
    },
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: colors.dark,
      opacity: 1,
    },
  },
  select: {
    marginBottom: 14,
  },
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
})

const ExperienceModal = ({
  show,
  experience,
  user,
  onClose,
  handleError,
  onFinish,
}) => {
  const classes = useStyles()
  const [formState, setFormState] = useState({
    company: '',
    dateStart: '',
    dateEnd: '',
    title: '',
    industry: '',
  })
  const [checkPresent, setCheckPresent] = useState(false)

  const { loading: loadingIndustries, data: industries } = useApiFetch(
    'getIndustries',
    { query: { count: 1000 } },
  )

  const {
    submitted: added,
    submit: addExperience,
    err: errAdd,
    submitting: addLoading,
  } = useApiSubmit('addExperience')

  const {
    submitted: updated,
    submit: updateExperience,
    err: errUpdate,
    submitting: updateLoading,
  } = useApiSubmit('updateExperience')

  const {
    submitted: removed,
    submit: removeExperience,
    err: errRemove,
    submitting: removeLoading,
  } = useApiSubmit('removeExperience')

  useEffect(() => {
    if (experience) {
      setFormState({
        company: experience.companyName,
        dateStart: getDateFromAPI(experience.dateStart),
        dateEnd: experience.dateEnd ? getDateFromAPI(experience.dateEnd) : '',
        title: experience.title,
        industry: experience.industryId
          ? { value: experience.industryId, label: experience.industryName }
          : '',
      })
      if (!experience.dateEnd) {
        setCheckPresent(true)
      }
    } else {
      setFormState({
        company: '',
        dateStart: '',
        dateEnd: '',
        title: '',
        industry: '',
      })
    }
  }, [experience])

  // When saved
  useEffect(() => {
    if (
      (added && !addLoading && !errAdd) ||
      (updated && !updateLoading && !errUpdate)
    ) {
      setFormState({
        company: '',
        dateStart: '',
        dateEnd: '',
        title: '',
        industry: '',
      })
      setCheckPresent(false)
      onFinish()
    } else if (errAdd && !addLoading) {
      handleError(errAdd, true, {
        message: 'There was a problem saving your Experience.',
      })
    } else if (errUpdate && !updateLoading) {
      handleError(errUpdate, true, {
        message: 'There was a problem saving your Experience.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [added, updated, addLoading, updateLoading, errAdd, errUpdate])

  // When Removed
  useEffect(() => {
    if (removed && !removeLoading && !errRemove) {
      setCheckPresent(false)
      onFinish()
    } else if (errRemove && !removeLoading) {
      handleError(errRemove, true, {
        message: 'There was a problem removing your Experience.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removed, removeLoading, errRemove])

  const handleInput = useCallback((e) => {
    const value = e.target.value
    const name = e.target.name
    setFormState((f) => ({
      ...f,
      [name]: value,
    }))
  }, [])

  const isEditMode = useMemo(() => experience && experience.id, [experience])
  const isFormInvalid = useMemo(
    () =>
      formState.company === '' ||
      formState.title === '' ||
      formState.industry === '',
    [formState],
  )

  const handleSave = useCallback(
    () => {
      const body = {
        companyName: formState.company,
        title: formState.title,
        dateStart: getDateToAPI(formState.dateStart),
        industryId: parseInt(formState.industry.value, 10),
      }
      if (formState.dateEnd) {
        body['dateEnd'] = getDateToAPI(formState.dateEnd)

        if (moment(body.dateEnd).isBefore(moment(body.dateStart))) {
          handleError(errRemove, true, {
            message: 'End Date should be after Start Date',
          })
          return
        }
      }

      if (isEditMode) {
        updateExperience({
          params: { profileId: user.id, experienceId: experience.id },
          body,
        })
      } else {
        addExperience({
          params: { profileId: user.id },
          body,
        })
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditMode, updateExperience, user, experience, formState, addExperience],
  )

  const handleSelect = useCallback((key, value) => {
    setFormState((f) => ({
      ...f,
      [key]: value,
    }))
  }, [])

  const handleDelete = useCallback(() => {
    removeExperience({
      params: { profileId: user.id, experienceId: experience.id },
    })
  }, [removeExperience, user, experience])

  return (
    <Modal
      show={show}
      close={() => {
        if (!isEditMode) {
          setFormState({
            company: '',
            dateStart: '',
            dateEnd: '',
            title: '',
            industry: '',
          })
        }
        setCheckPresent(false)
        return onClose()
      }}
      title="Experience"
      footer={
        <div
          className={`d-flex ${
            isEditMode ? 'justify-content-between' : 'justify-content-end'
          } `}
        >
          {isEditMode && (
            <LinkButton
              icon="las la-minus-circle"
              text="Delete"
              className={classes.delete}
              onClick={handleDelete}
            />
          )}
          <ActionButton
            text="SAVE"
            onClick={handleSave}
            type="STRONG_GREEN"
            disabled={isFormInvalid}
            loading={updateLoading || addLoading}
          />
        </div>
      }
      dialogClassName={isMobile() ? classes.dialogMobile : ''}
    >
      <Container>
        {removeLoading && <LoaderPage />}
        {loadingIndustries ? null : (
          <>
            <CustomInput
              isRequired={true}
              inputStyle="pr-0"
              value={formState.company}
              title="Employer"
              placeholder="Company Name"
              name="company"
              onChange={handleInput}
              containerStyle={classes.input}
            />
            <CustomInput
              isRequired={true}
              name="title"
              title="Title / Position"
              placeholder="Job Title"
              value={formState.title}
              containerStyle={classes.input}
              onChange={handleInput}
            />
            <CustomSelect
              isRequired={true}
              options={industries?.map((industry) => ({
                value: industry.id,
                label: industry.name,
              }))}
              name="industry"
              value={formState.industry}
              onSelect={handleSelect}
              className={classes.select}
              title="Industry"
              placeholder={'Industry'}
              dataTestId={'modal__combobox-industry'}
            />
            <Row>
              <Col xs={6}>
                <CustomSelect
                  isRequired={true}
                  options={moment.months().map((month, i) => ({
                    value: i,
                    label: month,
                  }))}
                  name="startDateMonth"
                  value={
                    formState.dateStart !== ''
                      ? {
                          value: moment(formState.dateStart).format('MM'),
                          label: moment(formState.dateStart).format('MMMM'),
                        }
                      : ''
                  }
                  onSelect={(key, value) => {
                    const date =
                      formState.dateStart !== ''
                        ? moment(formState.dateStart)
                        : moment()
                    date.set({ date: '1', month: value.value })
                    setFormState({ ...formState, dateStart: date.format() })
                  }}
                  className={classes.select}
                  title={'Start Date'}
                  placeholder={'Month'}
                  dataTestId={'modal__combobox-start-month'}
                />
              </Col>
              <Col xs={6}>
                <CustomSelect
                  options={YEARS()}
                  value={
                    formState.dateStart !== ''
                      ? {
                          value: moment(formState.dateStart).format('YY'),
                          label: moment(formState.dateStart).format('YYYY'),
                        }
                      : ''
                  }
                  onSelect={(key, value) => {
                    const date =
                      formState.dateStart !== ''
                        ? moment(formState.dateStart)
                        : moment()
                    date.set({ date: '1', year: value.value })
                    setFormState({ ...formState, dateStart: date.format() })
                  }}
                  className={classes.select}
                  title={' '}
                  placeholder={'Year'}
                  dataTestId={'modal__combobox-start-year'}
                />
              </Col>
            </Row>
            {!checkPresent && (
              <Row>
                <Col xs={6}>
                  <CustomSelect
                    options={moment.months().map((month, i) => ({
                      value: i,
                      label: month,
                    }))}
                    name="endDateMonth"
                    //value={}
                    value={
                      formState.dateEnd !== ''
                        ? {
                            value: moment(formState.dateEnd).format('MM'),
                            label: moment(formState.dateEnd).format('MMMM'),
                          }
                        : ''
                    }
                    onSelect={(key, value) => {
                      const date =
                        formState.dateEnd !== ''
                          ? moment(formState.dateEnd)
                          : moment()
                      date.set({ date: '1', month: value.value })
                      setFormState({ ...formState, dateEnd: date.format() })
                    }}
                    className={classes.select}
                    title={'End Date'}
                    placeholder={'Month'}
                    dataTestId={'modal__combobox-end-month'}
                  />
                </Col>
                <Col xs={6}>
                  <CustomSelect
                    options={YEARS()}
                    value={
                      formState.dateEnd !== ''
                        ? {
                            value: moment(formState.dateEnd).format('YY'),
                            label: moment(formState.dateEnd).format('YYYY'),
                          }
                        : ''
                    }
                    onSelect={(key, value) => {
                      const date =
                        formState.dateEnd !== ''
                          ? moment(formState.dateEnd)
                          : moment()
                      date.set({ date: '1', year: value.value })
                      setFormState({ ...formState, dateEnd: date.format() })
                    }}
                    className={classes.select}
                    title={' '}
                    placeholder={'Year'}
                    dataTestId={'modal__combobox-end-year'}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <div className="d-flex mt-2 pl-4">
                  <CustomCheckbox
                    checked={checkPresent}
                    onClick={() => {
                      setCheckPresent((c) => !c)
                      setFormState((f) => ({ ...f, dateEnd: '' }))
                    }}
                    dataTestId={'modal__checkbox-present'}
                  />
                  <span className={classes.present}>Present</span>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Modal>
  )
}

export default compose(withErrorHandler)(ExperienceModal)
