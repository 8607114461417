import React, {
  FC,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import { Row, Col, Toast } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { createUseStyles } from 'react-jss'
import moment from 'moment'
import { useIsMounted } from 'usehooks-ts'

import colors, { headings, responsive } from '../../../theme/theme2'
import ClockSolidIcon from '../../Icons/ClockSolid'
import ProgressBar from './ProgressBar'
import { isMobile } from '@/utils/common.js'
import TimerIcon from '../../Icons/Timer'
import { log } from '@/utils/logger'

const useStyles = createUseStyles({
  text: {
    ...headings.text,
    fontWeight: 'bold',
    color: colors.dark,
    marginBottom: 0,
    '@media (min-width: 768px)': {
      fontSize: 15,
    },
  },
  timer: {
    textAlign: 'right',
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 5,
  },
  title: {
    ...headings.text,
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.dark,
    '@media (min-width: 768px)': {
      fontSize: 13,
    },
  },
  progress: {
    marginLeft: 5,
    width: 30,
    height: 5,
    backgroundColor: colors.primary,
  },
  icon: {
    color: colors.primary,
    paddingRight: 5,
  },
  iconWarning: {
    color: '#f5a623',
    paddingRight: 5,
  },
  iconEnd: {
    color: colors.red,
    paddingRight: 5,
  },
  paddingRight: {
    paddingRight: 0,
  },
  paddingTop: {
    paddingTop: 5,
  },
  callTextContainer: {
    paddingRight: 10,
    marginBottom: 8,
  },
  callTextContainerMobile: {
    paddingRight: 4,
  },
  bgGreen: {
    backgroundColor: colors.primary,
  },
  bgWarning: {
    backgroundColor: '#f5a623',
  },
  bgEnding: {
    backgroundColor: colors.red,
  },
  toastMsg: {
    marginLeft: 10,
    position: 'relative',
    top: -2,
  },
  toastIcon: {
    fontSize: 20,
  },
  toastBody: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: colors.whiteBackground,
    zIndex: 1000,
    display: 'none',
    height: 40,
  },
  toastDesktop: {
    borderRadius: 4,
    width: '100%',
    textAlign: 'center',
    marginTop: -8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    border: 0,
    '&.toast': {
      maxWidth: 'unset',
    },
  },
  toastNone: {
    display: 'none',
  },
  toastMobile: {
    width: '100%',
    border: 'unset',
    borderRadius: 0,
    height: 30,
    paddingTop: 0,
    '& .toast-body': {
      height: 30,
      padding: 0,
      textAlign: 'center',
    },
  },
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  ...responsive,
})

const DEFAULT_THRESHOLDS = {
  GREEN: 25 * 60,
  YELLOW: 26 * 60,
  RED: 28 * 60,
}
const timeStepInSeconds = 300 // 300 sec (5 minutes) to meet your requirements

type Props = {
  startTime: string
  endTime: string
  allowedAfterTime: string
  elapsedSeconds: number
}
const Timer: FC<Props> = ({
  startTime,
  endTime,
  allowedAfterTime,
  elapsedSeconds,
}: Props) => {
  const classes = useStyles()
  const isMounted = useIsMounted()
  const [statusColor, setStatusColor] = useState({
    icon: classes.icon,
    bg: classes.bgGreen,
  })
  const [showToast, setShowToast] = useState(false)
  const [toast, setToast] = useState({
    msg: '',
    class: classes.toastNone,
  })
  const [clock, setClock] = useState<number | null>(null)
  const [THRESHOLDS, setThresholds] = useState(DEFAULT_THRESHOLDS)
  const hasShownYellowToast = useRef(false)
  const hasShownRedToast = useRef(false)

  const calculateThresholds = useCallback(() => {
    const start = moment.utc(startTime)
    const end = moment.utc(endTime)
    const totalSeconds = end.diff(start, 'seconds')
    // Thresholds in seconds
    const GREEN = totalSeconds * 0.899
    const YELLOW = totalSeconds * 0.9
    const RED = totalSeconds * 0.95

    log.debug(
      {
        start,
        end,
        totalSeconds,
        settings: {
          GREEN,
          YELLOW,
          RED,
        },
      },
      'calculateThresholds',
    )

    return {
      GREEN,
      YELLOW,
      RED,
    }
  }, [startTime, endTime])

  useEffect(() => {
    setThresholds(calculateThresholds())
  }, [calculateThresholds])

  useEffect(() => {
    const start = moment.utc(startTime)
    // const scheduleEnd = moment.utc(endTime)
    const maxEnd = moment.utc(allowedAfterTime)

    // Function that updates both time and status
    const updateTimeAndStatus = () => {
      if (!isMounted()) return

      const now = moment.utc()
      const diff = now.diff(start, 'seconds')

      let newIcon = statusColor.icon
      let newBg = statusColor.bg

      // If elapsedSeconds is after endTime
      if (now.isSameOrAfter(maxEnd)) {
        if (clock) {
          clearInterval(clock)
        }

        newIcon = classes.iconEnd
        newBg = classes.bgEnding
        // show red toast here if not shown already
      } else if (diff >= THRESHOLDS.RED) {
        newIcon = classes.iconEnd
        newBg = classes.bgEnding
        // Only change toast if the class would change. Avoids setting state unless necessary.
        if (!hasShownRedToast.current) {
          setToast({
            msg: `Time's up, the conversation will auto-close at ${moment
              .utc(allowedAfterTime)
              .local()
              .format('hh:mm A')}`,
            class: newBg,
          })
          setShowToast(true)
          hasShownRedToast.current = true
        }
      } else if (diff >= THRESHOLDS.YELLOW) {
        newIcon = classes.iconWarning
        newBg = classes.bgWarning
        // Only change toast if the class would change. Avoids setting state unless necessary.
        if (!hasShownYellowToast.current) {
          const remainingMinutes = moment.utc(endTime).diff(now, 'minutes')
          setToast({
            msg: `Wrap it up, This conversation has ${remainingMinutes} minutes remaining`,
            class: newBg,
          })
          setShowToast(true)
          hasShownYellowToast.current = true
        }
      } else if (diff <= THRESHOLDS.GREEN) {
        newIcon = classes.icon
        newBg = classes.bgGreen
      }

      if (newIcon !== statusColor.icon || newBg !== statusColor.bg) {
        setStatusColor({
          icon: newIcon,
          bg: newBg,
        })
      }
    }

    // Run once to set initial values
    updateTimeAndStatus()

    // Interval to update elapsedSeconds every second
    const clockId = setInterval(updateTimeAndStatus, 1000) as unknown as number
    setClock(clockId)

    return () => clearInterval(clockId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allowedAfterTime,
    startTime,
    endTime,
    THRESHOLDS.RED,
    THRESHOLDS.YELLOW,
    THRESHOLDS.GREEN,
    isMounted,
  ])

  const totalBars = useMemo(() => {
    const start = moment.utc(startTime)
    const end = moment.utc(endTime)
    const duration = moment.duration(end.diff(start))
    const durationInSeconds = duration.asSeconds()

    // dividing total duration by each time step to get the total bars
    return Math.ceil(durationInSeconds / timeStepInSeconds)
  }, [startTime, endTime])

  const activeBars = useMemo(() => {
    // Change the calculation of activeBars to match the 5 minute timeStepInSeconds
    return elapsedSeconds
      ? Math.floor(elapsedSeconds / timeStepInSeconds) + 1
      : 0
  }, [elapsedSeconds])

  const clockItem = (
    <label className={classes.text}>
      <span className={statusColor.icon}>
        <ClockSolidIcon />
      </span>
      {'00:' +
        moment(elapsedSeconds * 1000)
          .utc()
          .format('mm:ss')}
    </label>
  )

  return (
    <>
      {!isMobile() ? (
        <Row className={classes.timer}>
          <Col
            sm={{ span: 5, offset: 1 }}
            md={{ span: 5, offset: 0 }}
            lg={{ span: 4, offset: 2 }}
            xl={{ span: 3, offset: 4 }}
            className={classes.callTextContainer}
          >
            <label className={classes.title}>Conversation Time</label>
          </Col>
          <Col
            sm={{ span: 5, offset: 1 }}
            md={{ span: 6, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
            xl={{ span: 5, offset: 0 }}
            className={classes.paddingRight}
          >
            {clockItem}
          </Col>
          <Col
            md={{ span: 12, offset: 0 }}
            className="d-flex justify-content-end"
          >
            <ProgressBar
              cols={totalBars}
              active={activeBars}
              status={statusColor}
            />
          </Col>
        </Row>
      ) : (
        <Row className={classes.timer}>
          <Col
            xs={{ span: 4, offset: 3 }}
            className={classes.callTextContainerMobile}
          >
            <label className={classes.title}>Conversation Time</label>
          </Col>
          <Col xs={{ span: 4, offset: 1 }} className={classes.paddingRight}>
            {clockItem}
          </Col>
          <Col
            xs={{ span: 9, offset: 3 }}
            className="d-flex justify-content-end"
          >
            <ProgressBar
              cols={totalBars}
              active={activeBars}
              status={statusColor}
            />
          </Col>
        </Row>
      )}

      {document.getElementById('alert-container') &&
        createPortal(
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={10000}
            autohide
            animation={true}
            className={`${classes.toastBody} ${toast.class} ${
              isMobile() ? classes.toastMobile : classes.toastDesktop
            }`}
          >
            <Toast.Body className={!isMobile() ? classes.paddingTop : ''}>
              <span className={classes.toastIcon}>
                <TimerIcon />
              </span>
              <span className={classes.toastMsg}>{toast.msg}</span>
            </Toast.Body>
          </Toast>,
          document.getElementById('alert-container') as Element,
        )}
    </>
  )
}

export default Timer
