/* eslint-disable no-console */
import React, { Fragment, useState } from 'react'
import AutoSuggestHighlightMatch from 'autosuggest-highlight/match'
import AutoSuggestHighlightParse from 'autosuggest-highlight/parse'
import AutoSuggest from 'react-autosuggest'
import { createUseStyles } from 'react-jss'
import colors from '../../theme/theme2'
import LinkButton from '../../elemets/LinkButton'

const useStyles = createUseStyles({
  autoSuggest: {
    '& .react-autosuggest__container': {
      position: 'relative',
    },
    '& .react-autosuggest__input': {
      width: '100%',
      height: 30,
      padding: '0px 20px',
      border: ' 1px solid gainsboro',
      borderRadius: 4,
      marginBottom: 10,
    },
    '& .react-autosuggest__input--focused': {
      outline: 'none',
    },
    '& .react-autosuggest__suggestions-container--open': {
      display: 'block',
      position: 'absolute',
      overflowY: 'scroll',
      maxHeight: 400,
      top: 31,
      width: '100%',
      border: '1px solid #aaa',
      borderRadius: 4,
      padding: 10,
      backgroundColor: '#fff',
      fontSize: 16,
      zIndex: 1000,
    },
    '& .react-autosuggest__suggestions-list': {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    '& .react-autosuggest__suggestion': {
      cursor: 'pointer',
      padding: '10px 20px',
    },
    '& .react-autosuggest__suggestion--highlighted': {
      backgroundColor: '#ddd',
    },
    '& .react-autosuggest__section-container': {
      borderTop: '1px dashed #ccc',
    },
    '& .react-autosuggest__section-container--first': {
      borderTop: 0,
    },
    '& .react-autosuggest__section-title': {
      padding: '10px 0 0 10px',
      fontSize: 12,
      color: '#777',
    },
    '& .highlight': {
      fontWeight: 'bold',
      color: colors.primary,
    },
    '& .no_results': {
      fontSize: 16,
      color: '#777',
    },
    '& .suggest_span': {
      fontSize: [14, '!important'],
      alignSelf: 'center',
      color: colors.black,
      fontWeight: 'normal !important',
    },
  },
  suggest: {
    alignSelf: 'center',
    color: colors.primary,
    '&:hover': {
      color: colors.primary,
    },
    '& > i': {
      color: colors.white,
      backgroundColor: colors.primary,
      borderRadius: '50%',
      padding: 3.33,
      width: '20px',
      height: '20px',
    },
  },
  suggestDiv: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
})

const AutoSuggestTopic = ({
  suggestedTopics,
  handleSetFormState,
  className = '',
  initialValue = '',
  placeholder = 'Type your topic of interest',
  handleDeleteFormState,
  setDisplayModal,
}) => {
  const classes = useStyles()
  const [value, setValue] = useState(initialValue)
  const [suggestions, setSuggestions] = useState([])

  const escapeRegexCharacters = (str) =>
    str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  const getSuggestions = (value) => {
    const escapedValue = escapeRegexCharacters(value.trim())
    if (escapedValue === '') {
      return []
    }
    const suggestedValues = suggestedTopics
      .map((section) => {
        return {
          skillGroupName: section.skillGroupName,
          skills: section.skills.filter(
            (subtopic) =>
              subtopic.skillName
                .toUpperCase()
                .includes(escapedValue.toUpperCase()) ||
              section.skillGroupName
                .toUpperCase()
                .includes(escapedValue.toUpperCase()),
          ),
        }
      })
      .filter((section) => section.skills.length > 0)
    if (suggestedValues.length > 0) {
      return suggestedValues
    } else {
      return [
        {
          skillGroupName: 'No result found',
          skills: [{ skillId: 0, skillName: '' }],
        },
      ]
    }
  }

  const renderSuggestion = (suggestion, { query }) => {
    const suggestionText = `${suggestion.skillName}`
    const matches = AutoSuggestHighlightMatch(suggestionText, query)
    const parts = AutoSuggestHighlightParse(suggestionText, matches)
    return (
      <span className={'suggestion-content'}>
        <span className="name">
          {parts.map((part, index) => {
            if (part.text !== '') {
              const className = part.highlight ? 'highlight' : null
              return (
                <span className={className} key={`opt-${index}`}>
                  {part.text}
                </span>
              )
            }

            return (
              <Fragment key={`topic-suggestion-${index}`}>
                {setDisplayModal ? (
                  <div
                    className={classes.suggestDiv}
                    onClick={() => setDisplayModal()}
                  >
                    <span className="suggest_span">
                      Nobody is talking about this yet, please
                    </span>
                    <LinkButton
                      icon="fas fa-plus"
                      text="Suggest a Topic"
                      className={classes.suggest}
                    />
                  </div>
                ) : (
                  <span className={'no_results'} key={index}>
                    No result found
                  </span>
                )}
              </Fragment>
            )
          })}
        </span>
      </span>
    )
  }

  const renderSectionTitle = (section) => {
    if (section.skillGroupName === 'No result found') {
      return null
    }
    return <strong>{section.skillGroupName}</strong>
  }

  const getSectionSuggestions = (section) => section.skills

  return (
    <div className={`${classes.autoSuggest} ${className}`}>
      <AutoSuggest
        multiSection={true}
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionsFetchRequested={({ value }) => {
          setValue(value)
          setSuggestions(getSuggestions(value))
        }}
        onSuggestionSelected={(_, { suggestion }) => {
          handleSetFormState(suggestion)
        }}
        getSuggestionValue={(suggestion) => suggestion.skillName}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        inputProps={{
          placeholder: placeholder,
          value: value,
          onChange: (event, { newValue }) => {
            setValue(newValue)
            if (handleDeleteFormState) {
              handleDeleteFormState()
            }
          },
        }}
      />
    </div>
  )
}

export default AutoSuggestTopic
