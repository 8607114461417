import React, { useCallback } from 'react'
import ConversationRequestBanner from './ConversationRequestBanner'
import AddAvailabilityBanner from './AddAvailabilityBanner'
import AddTopicBanner from './AddTopicBanner'
import IntegrateCalendarBanner from './IntegrateCalendarBanner'
import RescheduleRequestBanner from './RescheduleRequestBanner'

const CustomBanner = ({ banner, reload, onClose }) => {
  const renderBannerType = useCallback(
    (type) => {
      switch (type) {
        case 'ADD_AVAILABILITY':
          return <AddAvailabilityBanner onClose={onClose} />
        case 'ADD_TOPIC':
          return <AddTopicBanner onClose={onClose} />
        case 'CONVERSATION_REQUEST':
          return <ConversationRequestBanner banner={banner} onClose={onClose} />
        case 'INTEGRATE_CALENDAR':
          return <IntegrateCalendarBanner onClose={onClose} />
        case 'RESCHEDULE_REQUEST':
          return (
            <RescheduleRequestBanner
              banner={banner}
              onClose={onClose}
              reload={reload}
            />
          )
        default:
          return <div />
      }
    },
    [banner, onClose, reload],
  )

  return renderBannerType(banner?.type)
}

export default CustomBanner
