import React from 'react'
import { createUseStyles } from 'react-jss'
import colors from '../../theme/theme2'

const useStyles = createUseStyles({
  mentorBadge: {
    width: 18,
    height: 18,
    margin: '4px 0 28px 9px',
    padding: '1px 3px 3px 3px',
    backgroundColor: colors.primary,
    borderRadius: 2,
    color: colors.whiteBackground,
    fontFamily: 'lato',
    fontSize: 13,
    fontWeight: 'bold',
    cursor: 'default',
  },
})

const MentorBadge = ({ isMentor }) => {
  const classes = useStyles()
  return isMentor ? <span className={classes.mentorBadge}>M</span> : null
}

export default MentorBadge
