import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

// Elements
import CustomCheckbox from '../../elemets/CustomCheckbox'

// Theme
import colors, { headings } from '../../theme/theme2'

const useStyles = createUseStyles({
  text: {
    ...headings.text,
    marginBottom: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  checkboxCheck: {
    backgroundColor: `${colors.primary} !important`,
  },
  checkboxUncheck: {
    backgroundColor: '#e8ecef !important',
  },
  checkboxIcon: {
    color: '#ffffff !important',
  },
})
const IntegrationRow = React.memo(({ calendar, checked, onCheck }) => {
  const classes = useStyles()
  return (
    <Row className="mb-2">
      <Col xs={2} className="d-flex justify-content-center align-items-center">
        {calendar.checkbox && (
          <CustomCheckbox
            checked={checked}
            onClick={() => onCheck(calendar.email)}
            backgroundColor={
              checked ? classes.checkboxCheck : classes.checkboxUncheck
            }
            iconColor={classes.checkboxIcon}
          />
        )}
      </Col>
      <Col xs={10} className="pl-0">
        <label className={classes.text}>{calendar.name}</label>
      </Col>
    </Row>
  )
})

export default IntegrationRow
