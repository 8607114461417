import React from 'react'
import OtherGroupsSelector from './OtherGroupsSelector'
import GroupLabel from '../ActiveGroup/GroupLabel'

const GroupSelector = (): JSX.Element => (
  <>
    <div>
      <GroupLabel name="Other Groups" />
    </div>
    <OtherGroupsSelector />
  </>
)

export default GroupSelector
