import { useEffect, useState } from 'react'
import moment from 'moment'

export const isMobile = () => window.screen.width < 600
export const getDateFromAPI = date => new Date(moment(date))
export const getDateToAPI = date => moment(date).format('YYYY-MM-DD')
export const isChromeIOS = () => navigator.userAgent.match('CriOS')
export const isFirefoxIOS = () => navigator.userAgent.match('FxiOS')
export const isBrowserSupported = () => !isFirefoxIOS()

// Hook
export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

export const decodeAtoBInputs = input => {
  try {
    return decodeURIComponent(window.atob(input))
  } catch (e) {
    try {
      return decodeURIComponent(encodeURIComponent(window.atob(input)))
    } catch (e) {
      return ''
    }
  }
}

export const getPeriodsWithDST = (start, end, frequency) => {
  let morePeriods = true
  let startDate = start
  const endDate = end
  let year = new Date().getFullYear()
  const periods = []
  while (morePeriods) {
    // Period start before DST
    if (
      moment(startDate).isBefore(
        moment().startOf('month').month(2).year(year).day(7).add(7, 'd'),
      )
    ) {
      if (
        moment(endDate).isSameOrAfter(
          moment().startOf('month').month(2).year(year).day(7).add(7, 'd'),
        )
      ) {
        // Period finish on DST
        periods.push({
          startDate,
          endDate: new Date(
            moment().startOf('month').month(2).year(year).day(7).add(6, 'd'),
          ),
        })
        switch (frequency) {
          case 'daily':
            startDate = new Date(
              moment().startOf('month').month(2).year(year).day(7).add(7, 'd'),
            )
            break
          case 'weekly':
            startDate = new Date(
              moment()
                .startOf('month')
                .month(2)
                .year(year)
                .day(7)
                .add(7 + moment(startDate).day(), 'd'),
            )
            break
          case 'weekdays':
            startDate = new Date(
              moment().startOf('month').month(2).year(year).day(7).add(8, 'd'),
            )
            break
          case 'monthly':
            startDate =
              moment(startDate).date() >=
              moment()
                .startOf('month')
                .month(2)
                .year(year)
                .day(7)
                .add(7, 'd')
                .date()
                ? new Date(
                    moment()
                      .startOf('month')
                      .month(2)
                      .year(year)
                      .add(moment(startDate).date() - 1, 'd'),
                  )
                : new Date(
                    moment()
                      .startOf('month')
                      .month(3)
                      .year(year)
                      .add(moment(startDate).date() - 1, 'd'),
                  )
            break
          default:
            break
        }
      } else {
        // Period finish before DST
        periods.push({
          startDate,
          endDate,
        })
        morePeriods = false
      }
    }

    // Period start on DST
    if (
      morePeriods &&
      moment(startDate).isSameOrAfter(
        moment().startOf('month').month(2).year(year).day(7).add(7, 'd'),
      ) &&
      moment(startDate).isBefore(
        moment().startOf('month').month(10).year(year).day(7),
      )
    ) {
      if (
        moment(endDate).isSameOrAfter(
          moment().startOf('month').month(10).year(year).day(7),
        )
      ) {
        // Period finish after DST
        periods.push({
          startDate,
          endDate: new Date(
            moment().startOf('month').month(10).year(year).day(6),
          ),
        })
        switch (frequency) {
          case 'daily':
            startDate = new Date(
              moment().startOf('month').month(10).year(year).day(7),
            )
            break
          case 'weekly':
            startDate = new Date(
              moment()
                .startOf('month')
                .month(10)
                .year(year)
                .day(7)
                .add(moment(startDate).day(), 'd'),
            )
            break
          case 'weekdays':
            startDate = new Date(
              moment().startOf('month').month(10).year(year).day(7).add(1, 'd'),
            )
            break
          case 'monthly':
            startDate =
              moment(startDate).date() >=
              moment().startOf('month').month(10).year(year).day(7).date()
                ? new Date(
                    moment()
                      .startOf('month')
                      .month(10)
                      .year(year)
                      .add(moment(startDate).date() - 1, 'd'),
                  )
                : new Date(
                    moment()
                      .startOf('month')
                      .month(11)
                      .year(year)
                      .add(moment(startDate).date() - 1, 'd'),
                  )
            break
          default:
            break
        }
      } else {
        // Period finish on DST
        periods.push({
          startDate,
          endDate,
        })
        morePeriods = false
      }
    }
    year += 1
  }
  return periods
}
