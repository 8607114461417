import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from '../../../elemets/Modal'
import colors, { headings } from '../../../theme/theme2'
import { isMobile } from '../../../utils/common'
import Icon from '../../Icons'
import { useApiFetch, useApiSubmit } from '../../../api'
import CustomSelect from '../../../elemets/CustomSelect'
import ActionButton from '../../../elemets/ActionButton'
import { Col, Row } from 'react-bootstrap'
import compose from 'just-compose'
import { withErrorHandler } from '../../../errors'
import { withNotifications } from '../../../notifications'
import CustomTextArea from '../../../elemets/CustomTextArea'
import moment from 'moment'
import CustomDate from '../../../elemets/CustomDate'
import { useCurrentUser } from '../../Context/CurrentUser'

const useStyles = createUseStyles({
  body: {
    padding: 10,
    backgroundColor: '#f8fafb',
  },
  label: {
    ...headings.title,
    fontSize: 12,
  },
  title: {
    ...headings.title,
    paddingRight: 5,
  },
  icon: {
    '& i': {
      cursor: 'default',
      paddingTop: 3,
      '&:before': {
        color: colors.primary,
      },
    },
  },
  subtitle: {
    ...headings.paragraph,
    color: colors.dark,
    fontWeight: 'bold',
  },
  bgGrey: {
    backgroundColor: colors.grey1,
  },
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  select: {
    '& *': {
      fontSize: '12px!important',
    },
  },
  dateMobile: {
    '& input': {
      width: '100%',
      minWidth: isMobile() ? 180 : 220,
      fontSize: isMobile() ? '12px!important' : 'inherit',
      fontWeight: 'normal',
      height: isMobile() ? 24.5 : '',
    },
    '& .react-datepicker-wrapper': {
      width: '100%',
    },
  },
  timeMobile: {
    paddingLeft: 10,
    minWidth: isMobile() ? 122 : 135,
  },
  withoutBold: {
    minHeight: 60,
    fontWeight: 500,
  },
  textMain: {
    ...headings,
    fontSize: 14,
    paddingTop: 10,
    color: colors.dark,
  },
})

const SuggestedTimeRow = ({ time, setTime, index }) => {
  const classes = useStyles()
  const [hour, setHour] = useState({ label: '08:00am', value: '08:00' })
  const [date, setDate] = useState(time.toDate())

  const timeList = useMemo(() => {
    return [
      { label: '12:00am', value: '00:00' },
      { label: '12:30am', value: '00:30' },
      { label: '01:00am', value: '01:00' },
      { label: '01:30am', value: '01:30' },
      { label: '02:00am', value: '02:00' },
      { label: '02:30am', value: '02:30' },
      { label: '03:00am', value: '03:00' },
      { label: '03:30am', value: '03:30' },
      { label: '04:00am', value: '04:00' },
      { label: '04:30am', value: '04:30' },
      { label: '05:00am', value: '05:00' },
      { label: '05:30am', value: '05:30' },
      { label: '06:00am', value: '06:00' },
      { label: '06:30am', value: '06:30' },
      { label: '07:00am', value: '07:00' },
      { label: '07:30am', value: '07:30' },
      { label: '08:00am', value: '08:00' },
      { label: '08:30am', value: '08:30' },
      { label: '09:00am', value: '09:00' },
      { label: '09:30am', value: '09:30' },
      { label: '10:00am', value: '10:00' },
      { label: '10:30am', value: '10:30' },
      { label: '11:00am', value: '11:00' },
      { label: '11:30am', value: '11:30' },
      { label: '12:00pm', value: '12:00' },
      { label: '12:30pm', value: '12:30' },
      { label: '01:00pm', value: '13:00' },
      { label: '01:30pm', value: '13:30' },
      { label: '02:00pm', value: '14:00' },
      { label: '02:30pm', value: '14:30' },
      { label: '03:00pm', value: '15:00' },
      { label: '03:30pm', value: '15:30' },
      { label: '04:00pm', value: '16:00' },
      { label: '04:30pm', value: '16:30' },
      { label: '05:00pm', value: '17:00' },
      { label: '05:30pm', value: '17:30' },
      { label: '06:00pm', value: '18:00' },
      { label: '06:30pm', value: '18:30' },
      { label: '07:00pm', value: '19:00' },
      { label: '07:30pm', value: '19:30' },
      { label: '08:00pm', value: '20:00' },
      { label: '08:30pm', value: '20:30' },
      { label: '09:00pm', value: '21:00' },
      { label: '09:30pm', value: '21:30' },
      { label: '10:00pm', value: '22:00' },
      { label: '10:30pm', value: '22:30' },
      { label: '11:00pm', value: '23:00' },
      { label: '11:30pm', value: '23:30' },
    ]
  }, [])

  useEffect(() => {
    if (time) {
      setHour({ label: time.format('hh:mma'), value: time.format('hh:mm') })
    }
  }, [time])

  const handleDateChange = useCallback((date) => {
    setDate(date)
    setTime((s) =>
      s.map((d, i) =>
        i === index
          ? moment(date).set({ hour: d.hours(), minute: d.minutes() })
          : d,
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelect = useCallback((key, value) => {
    setHour(value)
    setTime((s) =>
      s.map((d, i) =>
        i === index
          ? moment(d).set({
              hour: parseInt(value.value.split(':')[0]),
              minute: parseInt(value.value.split(':')[1]),
            })
          : d,
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`d-flex justify-content-center p-2`}>
      <CustomDate
        name="startDate"
        placeholder="Start Date"
        value={date}
        onChange={(date) => {
          handleDateChange(date)
        }}
        mask={false}
        containerStyle={classes.dateMobile}
        valueToDisplay={moment(date).format('dddd, MMM DD, YYYY')}
      />
      <CustomSelect
        options={timeList.slice(0, -1)}
        name="startTime"
        value={hour}
        onSelect={handleSelect}
        className={`${classes.timeMobile}`}
        isMobile={isMobile()}
      />
    </div>
  )
}

const RequestRescheduleModal = ({
  conversation,
  setRequestedAvailability,
  show,
  onClose,
  handleError,
  pushNotification,
}) => {
  const classes = useStyles()
  const [notes, setNotes] = useState('')
  const [suggested, setSuggested] = useState([
    moment().set({ hour: 8, minute: 0 }),
  ])
  const [added, setAdded] = useState(0)
  const [submitAdd, setSubmitAdd] = useState(false)
  const { currentUser } = useCurrentUser()

  const {
    submitted: submittedAvailability,
    submit: addAvailability,
    err: errorAvailability,
    submitting: submittingAvailability,
  } = useApiSubmit('addAvailabilityV1')

  const {
    data: requestReschedule,
    loading: loadingRequestReschedule,
    err: errorRequestReschedule,
    fetch: fetchRequestReschedule,
  } = useApiFetch('requestReschedule', {}, false)
  // endpoint to be defined

  const handleRequestAvailability = useCallback(() => {
    if (conversation && currentUser?.id) {
      fetchRequestReschedule({
        body: {
          conversationId: conversation.id,
          skillId: conversation.skillId,
          userId: conversation.participants.find(
            (user) => !user.currentUser.profileId,
          ),
          requestorId: currentUser.id,
          notes: notes,
          suggestedDates: suggested.map((d) =>
            d.set({ seconds: 0 }).utc().format(),
          ),
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRequestReschedule, conversation, currentUser])

  // Created
  useEffect(() => {
    if (
      !submittingAvailability &&
      !errorAvailability &&
      submittedAvailability
    ) {
      setAdded(added + 1)
    }
    if (errorAvailability && !submittingAvailability) {
      handleError(errorAvailability, true, {
        message: 'There was a problem adding the availability.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedAvailability, errorAvailability, submittingAvailability])

  useEffect(() => {
    if (
      requestReschedule !== null &&
      !loadingRequestReschedule &&
      !errorRequestReschedule
    ) {
      setRequestedAvailability(true)
      setSubmitAdd(true)
      pushNotification({
        level: 'success',
        subject: 'Request sent.',
        timeout: 10,
      })
    } else if (!loadingRequestReschedule && errorRequestReschedule) {
      handleError(errorRequestReschedule, true, {
        message: 'Unable to request additional availability.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestReschedule, loadingRequestReschedule, errorRequestReschedule])

  useEffect(() => {
    if (submitAdd) {
      checkContinueAdding()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitAdd])

  useEffect(() => {
    if (added !== 0) {
      checkContinueAdding()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [added])

  const handleAddMoreTimeSlot = useCallback(() => {
    setSuggested((s) => [...s, moment().set({ hour: 8, minute: 0 })])
  }, [])

  const checkContinueAdding = useCallback(() => {
    if (added < suggested.length) {
      addAvailability({
        body: {
          startTime: suggested[added].set({ seconds: 0 }).format(),
          endTime: suggested[added]
            .add(30, 'minutes')
            .set({ seconds: 0 })
            .format(),
        },
      })
    } else {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [added, suggested])

  return (
    <Modal
      show={show}
      close={onClose}
      title={
        <div
          className={`${classes.icon} ${
            isMobile() ? 'd-flex justify-content-center' : ''
          }`}
        >
          <label className={`${classes.title}`}>Request Reschedule</label>
          <Icon icon="fas fa-calendar-alt" />
        </div>
      }
      closeIcon={true}
      bodyClass={classes.body}
      modalClass={`${!isMobile() ? classes.modal : ''}`}
      dialogClassName={isMobile() ? classes.dialogMobile : ''}
    >
      <span className={`${classes.subtitle} ml-2`}>
        Would you like to suggest a Date and Time?
      </span>
      <Row className={`mb-3`}>
        <Col xs={12} className={'px-4 py-2'}>
          {suggested.map((time, index) => {
            return (
              <SuggestedTimeRow
                key={index}
                index={index}
                time={time}
                setTime={setSuggested}
              />
            )
          })}
          {suggested.length < 3 && (
            <div
              className={`d-flex justify-content-center ${classes.icon} pt-2`}
            >
              <span className={`${classes.subtitle} mr-3`}>
                Suggest another Date and Time
              </span>
              <Icon
                icon="fas fa-plus-circle"
                onClick={() => {
                  handleAddMoreTimeSlot()
                }}
              />
            </div>
          )}
        </Col>
      </Row>
      <span className={`${classes.subtitle} ml-2`}>
        Why do you want to reschedule?
      </span>
      <Row>
        <Col xs={12}>
          <CustomTextArea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            editMode={true}
            className={`ml-2 ${classes.withoutBold}`}
            maxLength={200}
          />
        </Col>
      </Row>
      <div className={`mt-3 d-flex justify-content-end`}>
        <ActionButton
          className={`${classes.actionButton}`}
          type={'STRONG_GREEN'}
          text={'SEND REQUEST'}
          onClick={handleRequestAvailability}
          disabled={notes === null || notes === ''}
        />
      </div>
    </Modal>
  )
}
export default compose(
  withErrorHandler,
  withNotifications,
)(RequestRescheduleModal)
