import React, { useEffect, useRef } from 'react'
import { useDevice } from 'react-use-device'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { createUseStyles } from 'react-jss'
import { withRouter } from 'react-router-dom'
import colors from '../../../theme/theme2'
import { isMobile } from '../../../utils/common'
import Icon from '../../Icons'
import MatchCards from './MatchCards'

const useStyles = createUseStyles({
  fonts: {
    fontFamily: 'lato',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
    cursor: 'pointer',
  },
  slideButton: {
    position: 'absolute',
    width: 20,
    height: 20,
    padding: 0,
    transform: 'translate(0, -50%)',
    cursor: 'pointer',
    color: 'transparent',
    border: 'none',
    outline: 'none',
    background: 'transparent',
    '& i': {
      '&:before': {
        color: colors.primary,
      },
    },
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    '&:active': {
      border: 'none',
      outline: 'none',
    },
  },
  slideButtonMobile: {
    top: 120,
    height: '100%',
  },
  slideButtonDesktop: {
    top: 100,
  },
  arrowLeft: {
    left: -30,
  },
  arrowRight: {
    right: -25,
  },
  arrowLeftMobile: {
    left: -10,
  },
  arrowRightMobile: {
    right: 0,
  },
  leftAlign: {
    '& .slick-track': {
      margin: 0,
    },
  },
})

const PrevArrow = props => {
  const { onClick } = props
  const classes = useStyles()
  return (
    <button
      className={`slick-arrow ${classes.slideButton}
      ${isMobile() ? classes.slideButtonMobile : classes.slideButtonDesktop}
      ${isMobile() ? classes.arrowLeftMobile : classes.arrowLeft}
      `}
      onClick={onClick}
    >
      <Icon className={`fas fa-chevron-left`} />
    </button>
  )
}
const NextArrow = props => {
  const { onClick } = props
  const classes = useStyles()
  return (
    <button
      className={`slick-arrow ${classes.slideButton}
      ${isMobile() ? classes.slideButtonMobile : classes.slideButtonDesktop}
      ${isMobile() ? classes.arrowRightMobile : classes.arrowRight}
      `}
      onClick={onClick}
    >
      <Icon className={`fas fa-chevron-right`} />
    </button>
  )
}

const SimpleSlider = ({
  profileTopics,
  matches = [],
  reloadUpcoming,
  setDetails,
}) => {
  const classes = useStyles()
  const { isDESKTOP } = useDevice()
  const slider1 = useRef(null)

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: isDESKTOP ? 5 : 2,
    initialSlide: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    swipe: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }
  useEffect(() => {
    // slider1.current.slickNext()
    slider1.current.slickPrev()
  }, [matches])

  return (
    <Slider {...settings} className={classes.leftAlign} ref={slider1}>
      {matches?.map((match, i) => (
        <div key={i}>
          <MatchCards
            profileTopics={profileTopics}
            match={match}
            reloadUpcoming={reloadUpcoming}
            setDetails={setDetails}
          />
        </div>
      ))}
    </Slider>
  )
}

export default withRouter(SimpleSlider)
