import React, { useState, useMemo, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { Row, Col, Button } from 'react-bootstrap'

// Hooks
import { useCurrentUser } from '../Context/CurrentUser'

// Elements
import CardContainer from '../../elemets/CardContainer'
import Divider from '../../elemets/Divider'
import { Typeaheads } from '../Forms/Input'

// Theme
import colors, { headings } from '../../theme/theme2'
import Icon from '../Icons/index'
import { isMobile } from '../../utils/common'

const useStyles = createUseStyles({
  title: {
    ...headings.title,
  },
  card: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    marginBottom: 8,
  },
  sort: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: '#707575',
    whiteSpace: 'nowrap',
    '& span': {
      marginRight: '5%',
      cursor: 'pointer',
    },
    '& i': {
      cursor: 'pointer',
      '&:before': {
        fontSize: 12,
        color: '#707575',
      },
    },
  },
  arrows: {
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },
})

const ConversationFilter = ({ setFilters, filterConversations, setOrder }) => {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)
  const { currentUser } = useCurrentUser()

  const handleSelect = useCallback(
    (key, select) => {
      if (select && select.length > 0) {
        setFilters(key, select[0].value)
      } else {
        setFilters(key, null)
      }
    },
    [setFilters],
  )

  const topicOptions = useMemo(() => {
    const options = []
    if (filterConversations && filterConversations.length > 0) {
      filterConversations.forEach((conversation) => {
        if (!options.find((option) => option.value === conversation.skillId)) {
          options.push({
            label: conversation.skillName,
            value: conversation.skillId,
          })
        }
      })
    }
    return options
  }, [filterConversations])

  const personOptions = useMemo(() => {
    const options = []
    if (filterConversations && filterConversations.length > 0) {
      filterConversations.forEach((conversation) => {
        const nroUser =
          conversation.request1ProfileId !== currentUser?.id ? 1 : 2
        if (
          !options.find(
            (option) =>
              option.value === conversation[`request${nroUser}ProfileId`],
          )
        ) {
          options.push({
            label: `${conversation[`request${nroUser}FirstName`]} ${
              conversation[`request${nroUser}LastName`]
            }`,
            value: conversation[`request${nroUser}ProfileId`],
          })
        }
      })
    }
    return options
  }, [currentUser, filterConversations])

  return (
    <CardContainer className={classes.card}>
      {isMobile() ? (
        <>
          <Row>
            <Col>
              <span className={classes.title}>Past Conversations</span>
            </Col>
            <Col xs={2}>
              <Button
                className={classes.arrows}
                onClick={() => {
                  setExpand(!expand)
                }}
              >
                <span>
                  <i
                    className={`fas ${
                      expand ? 'fa-chevron-down' : 'fa-chevron-right'
                    }`}
                  />
                </span>
              </Button>
            </Col>
          </Row>
          {expand && (
            <Row>
              <Col xs={12}>
                <Typeaheads
                  id="filter-topic"
                  placeholder="Filter by Topic"
                  options={topicOptions}
                  onChange={(select) => handleSelect('topic', select)}
                  clearButton={true}
                  className="my-2"
                />
              </Col>
              <Col xs={12}>
                <Typeaheads
                  id="filter-person"
                  placeholder="Filter by Person"
                  options={personOptions}
                  onChange={(select) => handleSelect('person', select)}
                  clearButton={true}
                  className="my-2"
                />
              </Col>
            </Row>
          )}
          <Divider />
          <Row>
            <Col>
              <Row noGutters className="justify-content-around">
                <div className={classes.sort} onClick={() => setOrder('name')}>
                  <span>A-Z</span>
                  <Icon className={'fas fa-sort'} />
                </div>
                <div
                  className={classes.sort}
                  onClick={() => setOrder('co_datescheduled')}
                >
                  <span>Date</span>
                  <Icon className={'fas fa-sort'} />
                </div>
              </Row>
            </Col>
            <Col>
              <div className={classes.sort} onClick={() => setOrder('sk_name')}>
                <span>Sub-Topic</span>
                <Icon className={'fas fa-sort'} />
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col xs={6}>
              <span className={classes.title}>Past Conversations</span>
            </Col>
            <Col xs={6}>
              <Row noGutters>
                <Typeaheads
                  id="filter-topic"
                  placeholder="Filter by Topic"
                  options={topicOptions}
                  onChange={(select) => handleSelect('topic', select)}
                  clearButton={true}
                  className="w-50 pr-1"
                />
                <Typeaheads
                  id="filter-person"
                  placeholder="Filter by Person"
                  options={personOptions}
                  onChange={(select) => handleSelect('person', select)}
                  clearButton={true}
                  className="w-50 pr-1"
                />
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col xs={{ offset: 1, span: 2 }}>
              <Row noGutters className="justify-content-around">
                <div className={classes.sort} onClick={() => setOrder('name')}>
                  <span>A-Z</span>
                  <Icon className={'fas fa-sort'} />
                </div>
                <div
                  className={classes.sort}
                  onClick={() => setOrder('co_datescheduled')}
                >
                  <span>Date</span>
                  <Icon className={'fas fa-sort'} />
                </div>
              </Row>
            </Col>
            <Col xs={{ offset: 1, span: 2 }}>
              <div className={classes.sort} onClick={() => setOrder('sk_name')}>
                <span>Sub-Topic</span>
                <Icon className={'fas fa-sort'} />
              </div>
            </Col>
          </Row>
        </>
      )}
    </CardContainer>
  )
}

export default ConversationFilter
