import React from 'react'
import TextField from '../TextField'

type NotesFieldProps = {
  value?: string
  onChange: (value: string) => void
}

const NotesField = ({ value, onChange }: NotesFieldProps): JSX.Element => (
  <TextField
    label="Please write any additional notes about the conversation"
    labelId="notes"
    value={value}
    onChange={onChange}
    maxLength={2500}
  />
)

export default NotesField
