export const assessment2Info = {
  title: 'Air Mobility Command (AMC) Mentor Questionnaire',
  description: '',
  completedHtml:
    '<h3>Your responses have been saved and submitted.  Thank you for your input.</h3>',
}

export const BR_customPage1 = {
  name: 'page01',
  showTitle: false,
  checkErrorsMode: 'onValueChanged',
  elements: [
    {
      name: 'empid',
      type: 'text',
      title: 'Please enter your 10-digit DoD ID:',
      isRequired: true,
      validators: [
        {
          type: 'text',
          minLength: 10,
          maxLength: 10,
        },
      ],
    },
    {
      name: 'empidconfirm',
      type: 'text',
      title: 'Please re-enter your 10-digit DoD ID:',
      isRequired: true,
      validators: [
        {
          type: 'text',
          minLength: 10,
          maxLength: 10,
        },
      ],
    },
    {
      type: 'dropdown',
      name: 'timezone',
      description: `Note that daylight savings/standard notations have been removed for simplicity`,
      title: 'Which time zone is closest to yours?',
      isRequired: true,
      showNoneItem: false,
      showOtherItem: false,
      choices: [
        {
          value: -10,
          text: `Hawaiian Time`,
        },
        {
          value: -8,
          text: `Alaska Time`,
        },
        {
          value: -7,
          text: `Pacific Time`,
        },
        { value: -6, text: `Mountain Time` },
        { value: -5, text: `Central Time` },
        {
          value: -4,
          text: `Eastern Time`,
        },
      ],
    },
    {
      type: 'radiogroup',
      name: 'mentoris1',
      title:
        'Please select how you would like to participate in the mentorship program:',
      isRequired: true,
      choices: [
        {
          value: 'mentor',
          text: 'I would like to be a **mentor**, and *provide mentorship* to someone',
        },
        {
          value: 'mentee',
          text: 'I would like to be a **mentee**, and *be mentored* by someone',
        },
      ],
    },
  ],
  showQuestionNumbers: false,
}

export const questionGroup2_1 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 1 of 8`,
  [
    [
      `gp1`,
      `I'd feel bad keeping extra change I shouldn't have received in a transaction, even if no one else noticed.`,
    ],
    [
      `gp2`,
      `I'd be disappointed in myself for breaking someone's decorative vase and not owning up to it, even if I didn't get caught.`,
    ],
    [
      `gp3`,
      `I'd be upset with myself for lying to cover up for a mistake I made, even if no one ever found out.`,
    ],
  ],
]

export const questionGroup2_2 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 2 of 8`,
  [
    [`e1`, `I would never go down a stretch of rapids in a kayak.`],
    [`a1`, `I am not easily satisfied.`],
    [
      `c1`,
      `At home and work, I make sure everything is always well-organized and back in its place.`,
    ],
    [`o1`, `Going to an art gallery is my idea of a very good time.`],
    [`h1`, `I would like to show off expensive things I own to others.`],
    [`e2`, `People would say that I worry a lot.`],
  ],
]
export const questionGroup2_3 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 3 of 8`,
  [
    [`a2`, `It is easy for me to forgive others who have wronged me.`],
    [`c2`, `I tend to put things off, sometimes to the last minute.`],
    [`x1`, `I tend to have a very high energy level throughout the day.`],
    [`h2`, `I find it very difficult to tell a lie.`],
    [`o3`, `I'm bored by subjects like science and history.`],
    [`e3`, `It matters a lot to me that I am liked.`],
  ],
]
export const questionGroup2_4 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 4 of 8`,
  [
    [`a3`, `I rarely feel irritated.`],
    [`c3`, `I am not satisfied until my work product is absolutely perfect.`],
    [`h3`, `It is ok to cheat a little to win.`],
    [`x2`, `I am very comfortable in social situations.`],
    [
      `o2`,
      `I often find myself believing or doing things very differently from most other people I know.`,
    ],
    [`x3`, `I tend to talk and express myself a lot with people.`],
  ],
]
export const questionGroup2_5 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 5 of 8`,
  [
    [`h1`, `I would like to show off expensive things I own to others.`],
    [`h2`, `Lying is extremely uncomfortable for me to do.`],
    [`h3`, `It is ok to cheat a little to win.`],
  ],
]
export const questionGroup2_6 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 6 of 8`,
  [
    [
      `c1`,
      `At home and work, I make sure everything is always well-organized and back in its place.`,
    ],
    [`c2`, `I tend to put things off, sometimes to the last minute.`],
    [`c3`, `I am not satisfied until my work product is absolutely perfect.`],
    [`o1`, `Going to an art gallery is my idea of a very good time.`],
    [
      `o2`,
      `I often find myself believing or doing things very differently from most other people I know.`,
    ],
    [`o3`, `I'm bored by subjects like science and history.`],
  ],
]
export const questionGroup2_7 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 7 of 8`,
  [
    [
      `pt1`,
      `I believe there are many relevant sides to every issue, and I put time into considering both.`,
    ],
    [
      `pt2`,
      `Any time I am upset with someone, I try to imagine the situation from their point of view.`,
    ],
    [
      `pt3`,
      `When I am sure I am right about something, I don't put too much stock into their arguments.`,
    ],
    [
      `pt4`,
      `When I provide feedback, I first consider what it would be like to receive the feedback I am giving.`,
    ],
  ],
]
export const questionGroup2_8 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 8 of 8`,
  [
    [
      `ec1`,
      `I don't generally feel very sorry for other people and their problems.`,
    ],
    [
      `ec2`,
      `I often feel great tenderness for people in difficult situations.`,
    ],
    [`ec3`, `Other people's losses are not of particular concern to me.`],
    [
      `ec4`,
      `I feel a strong sense of protectiveness toward others when they are wronged.`,
    ],
  ],
]

export const questionGroupMentor1 = [
  ``,
  `For each of the following, please indicate your level of agreement/disagreement with **how much each describes why you want to be a mentor for someone:**`,
  ``,
  [
    [
      `m_goal1`,
      `Help someone career plan`,
    ],
    [`m_goal2`, `Help someone learn more about Mobility Enterprise`],
    [`m_goal3`, `Help someone learn new skills or develop their skills`],
    [`m_goal4`, `Meet new people across our Mobility Enterprise`],
  ],
]

export const questionGroupMentor2_1 = [
  ``,
  `For each of the following, **indicate how much you would like to mentor someone in each of the following areas.** <br>Please keep in mind your own skillset and **what you believe you would *excel* at mentoring.**`,
  `part 1 of 2`,
  [
    [`m_skill1`, `Air Force Corporate Structure`],
    [`m_skill2`, `Collaboration and Teamwork`],
    [`m_skill3`, `Effective Communication`],
    [`m_skill4`, `Innovation and Creativity`],
    [`m_skill5`, `Installation Management`],
    [`m_skill6`, `Talent Management & Succession Planning`],
  ],
]
export const questionGroupMentor2_2 = [
  ``,
  `For each of the following, **indicate how much you would like to mentor someone in each of the following areas.** <br>Please keep in mind your own skillset and **what you believe you would *excel* at mentoring.**`,
  `part 2 of 2`,
  [
    [`m_skill7`, `Leadership`],
    [`m_skill8`, `Managing Change`],
    [`m_skill9`, `Negotiation and Influencing`],
    [`m_skill10`, `Presentation Skills`],
    [`m_skill11`, `Project Management`],
    [`m_skill12`, `Time Management`],
  ],
]

export const questionGroupMentee1 = [
  ``,
  `For each of the following, please indicate your level of agreement/disagreement with **how much each describes why you want to be mentored by someone:**`,
  ``,
  [
    [`p_goal1`, `Help me career plan`],
    [`p_goal2`, `Help me learn more about Mobility Enterprise`],
    [`p_goal3`, `Help me learn new skills or develop my skills`],
    [`p_goal4`, `Meet new people across our Mobility Enterprise`],
  ],
]

export const questionGroupMentee2_1 = [
  ``,
  `For each of the following, indicate **how much you would like to be mentored by someone in each of the following areas. <br>** Please keep in mind your own skillset and **what you believe you could learn the most about in a mentorship program.**`,
  `part 1 of 2`,
  [
    [`p_skill1`, `Air Force Corporate Structure`],
    [`p_skill2`, `Collaboration and Teamwork`],
    [`p_skill3`, `Effective Communication`],
    [`p_skill4`, `Innovation and Creativity`],
    [`p_skill5`, `Installation Management`],
    [`p_skill6`, `Talent Management & Succession Planning`],
  ],
]
export const questionGroupMentee2_2 = [
  ``,
  `For each of the following, indicate **how much you would like to be mentored by someone in each of the following areas. <br>** Please keep in mind your own skillset and **what you believe you could learn the most about in a mentorship program.**`,
  `part 2 of 2`,
  [
    [`p_skill7`, `Leadership`],
    [`p_skill8`, `Managing Change`],
    [`p_skill9`, `Negotiation and Influencing`],
    [`p_skill10`, `Presentation Skills`],
    [`p_skill11`, `Project Management`],
    [`p_skill12`, `Time Management`],
  ],
]
