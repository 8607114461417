export type BreakpointStyles = {
  keys: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Record<string, any>
  up: (key: string) => string
  down: (key: string) => string
}

const calculateBreakpoints = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Record<string, any>,
): BreakpointStyles => ({
  keys: [...Object.keys(values)],
  values: { ...values },
  up: (key: string) => `@media (min-width: ${values[key]}px)`,
  down: (key: string) => `@media (max-width: ${values[key]}px)`,
})

export default calculateBreakpoints
