import { gql } from '@apollo/client'
import { topicSubtopicsBasicFragment } from './topics.fragment'

export const getTopics = gql`
  query getTopics {
    skillgroups {
      ...topicSubtopics
    }
  }
  ${topicSubtopicsBasicFragment}
`
