import React, { FC, useCallback } from 'react'
import CardContainer from '../../../../elemets/CardContainer'
import { isMobile } from '../../../../utils/common'
import Icon from '../../../Icons'
import CardTitle from '../../../../elemets/CardTitle'
import { createUseStyles } from 'react-jss'
import colors, { headings } from '../../../../theme/theme2'
import ActionButton from '../../../../elemets/ActionButton'
import { Row, Col } from 'react-bootstrap'
import { getAccessToken } from '../../../../auth0'
import setParams from '../../../../api/params'
import { useBaseUri } from '../../../../config/useConfig'
import { useWafToken } from '../../../../components/Context/WafTokenProvider'
import { useErrorHandler } from '@/errors'
import { IGroup } from '@/types/Groups.typings'

const useStyles = createUseStyles({
  ul: {
    padding: 0,
    listStyleType: 'none',
    '& li': {
      ...headings.paragraph,
      fontWeight: 'bold',
      color: colors.dark,
      margin: 15,
      '& i': {
        '&:before': {
          color: colors.primary,
        },
      },
    },
  },
  containers: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
  },
  header: {
    '& i': {
      cursor: 'auto',
      '&:before': {
        fontSize: 20,
        color: colors.primary,
      },
    },
  },
  message: {
    height: 160,
    paddingTop: 65,
    textAlign: 'center',
  },
})
type Props = {
  group: IGroup
}
const Reporting: FC<Props> = ({ group }) => {
  const handleError = useErrorHandler()
  const classes = useStyles()
  const lattusApiBaseUri = useBaseUri('lattusApi')
  const { wafToken } = useWafToken()

  const downloadFile = useCallback(async () => {
    if (!wafToken) return
    const headers = new Headers()
    const token = await getAccessToken()

    headers.append('Authorization', `Bearer ${token}`)
    headers.set('x-aws-waf-token', wafToken)

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      redirect: 'follow',
    }

    const endpoint = '/v1/groups/{groupId}/report'
    const base = lattusApiBaseUri
    const path = setParams(endpoint, { groupId: group.id })
    const uri = `${base}${path}`

    fetch(uri, requestOptions)
      .then((data) => data.json())
      .then((response) => {
        const a = document.createElement('a') //Create
        a.href =
          'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' +
          response.content
        a.download = response.filename //File name Here
        a.click()
      })
      .catch((e) =>
        handleError(e, true, {
          level: 'error',
          message: 'There was an error downloading your report',
        }),
      )
  }, [group.id, handleError, lattusApiBaseUri, wafToken])

  return (
    <CardContainer
      className={classes.containers}
      headerDivider={true}
      header={
        <div
          className={`${classes.header} ${
            isMobile() ? 'd-flex justify-content-center' : ''
          }`}
        >
          <Icon icon="fas fa-chart-bar" />
          <CardTitle title={'Reporting'} />
        </div>
      }
      footer={
        <div className="d-flex justify-content-end">
          <ActionButton
            type={'LIGHT_GREEN'}
            text={'Download Report'}
            onClick={downloadFile}
          />
        </div>
      }
    >
      <Row noGutters={isMobile()}>
        <Col
          xs={12}
          className={`d-flex justify-content-center ${classes.message}`}
        >
          <p>Your monthly activity reports can now be downloaded.</p>
        </Col>
      </Row>
    </CardContainer>
  )
}

export default Reporting
