import React, { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'

type FieldSetProps = {
  children: ReactNode
}

const useStyles = createUseStyles({
  root: {
    '@media (min-width: 768px)': {
      display: 'grid',
      gridTemplateColumns: '[label] 60% [value] auto',
      columnGap: '24px',
      rowGap: '24px',
      alignItems: 'center',
    },
  },
})

const FieldSet = ({ children }: FieldSetProps): JSX.Element => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}

export default FieldSet
