import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import colors, { headings } from '../../../../theme/theme2'
import ActionButton from '../../../../elemets/ActionButton'
import { isMobile } from '../../../../utils/common'
import UploadImageModal from '../../../../components/Modals/UploadImageModal'
// import { useApiSubmit } from '@/api'

const useStyles = createUseStyles({
  title: {
    ...headings.text,
    fontWeight: 'bold',
    margin: 0,
  },
  greyText: {
    ...headings.paragraph,
    fontSize: 12,
    color: '#7c8080',
  },
  button: {
    width: '100%!important',
    padding: '20px!important',
    color: [colors.grey3, '!important'],
  },
  preview: {
    borderRadius: 4,
    width: 40,
    height: 40,
    position: 'absolute',
    top: 10,
    left: 10,
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  icon: {
    borderRadius: 4,
    width: 40,
    height: 40,
    backgroundColor: colors.primary,
    color: colors.whiteBackground,
    paddingTop: 9,
    '& i': {
      fontSize: 20,
    },
    position: 'absolute',
    top: 10,
    left: 10,
  },
  iconText: {
    paddingLeft: 45,
  },
  floatR: {
    float: 'right',
  },
  floatL: {
    float: 'left',
  },
  clear: {
    clear: 'both',
  },
})

const GroupImages = ({ group, loading }) => {
  const classes = useStyles()
  const [displayLogoModal, setDisplayLogoModal] = useState(false)
  const [displayBannerModal, setDisplayBannerModal] = useState(false)

  return (
    <>
      {!isMobile() ? (
        <>
          <Row className={'mb-3'}>
            <Col xs={4}>
              <p className={classes.title}>Group Logo</p>
              <p className={classes.greyText}>
                270 x 270 Pixels (JPEG, GIF, PNG)
              </p>
            </Col>
            <Col xs={8}>
              <ActionButton
                text="Upload Your Group Logo"
                className="w-40"
                onClick={() => setDisplayLogoModal(true)}
                type="STRONG_GREEN"
              />
              <UploadImageModal
                endpoint={`/v1/groups/${group.id}/image/logo`}
                title={'Upload Your Group Logo'}
                imageType="logo"
                show={displayLogoModal}
                onClose={() => setDisplayLogoModal(false)}
                forcePageReload
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <p className={classes.title}>Group Banner</p>
              <p className={classes.greyText}>
                1140 x 330 Pixels (JPEG, GIF, PNG)
              </p>
            </Col>
            <Col xs={8}>
              <ActionButton
                text="Upload Your Group Banner"
                className="w-40"
                onClick={() => setDisplayBannerModal(true)}
                type="STRONG_GREEN"
              />
              <UploadImageModal
                endpoint={`/v1/groups/${group.id}/image`}
                title={'Upload Your Group Banner'}
                imageType="banner"
                show={displayBannerModal}
                onClose={() => setDisplayBannerModal(false)}
                forcePageReload
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className={'pl-3 pr-3 mb-3'}>
            <Col xs={12} className={`${classes.clear}`}>
              <p className={`${classes.title} ${classes.floatL}`}>
                {' '}
                Group Logo{' '}
              </p>
              <p className={`${classes.greyText} ${classes.floatR} mt-1`}>
                270 x 270 Pixels (JPEG, GIF, PNG)
              </p>
            </Col>
            <Col xs={12}>
              <ActionButton
                text="Upload Your Group Logo"
                className="w-40"
                onClick={() => setDisplayLogoModal(true)}
                type="STRONG_GREEN"
              />
              <UploadImageModal
                endpoint={`/v1/groups/${group.id}/image/logo`}
                title={'Upload Your Group Logo'}
                imageType="logo"
                show={displayLogoModal}
                onClose={() => setDisplayLogoModal(false)}
                forcePageReload
              />
            </Col>
          </Row>
          <Row className={'pl-3 pr-3'}>
            <Col xs={12} className={`${classes.clear}`}>
              <p className={`${classes.title} ${classes.floatL}`}>
                {' '}
                Group Banner{' '}
              </p>
              <p className={`${classes.greyText} ${classes.floatR} mt-1`}>
                1140 x 330 Pixels (JPEG, GIF, PNG)
              </p>
            </Col>
            <Col xs={12}>
              <ActionButton
                text="Upload Your Group Banner"
                className="w-40"
                onClick={() => setDisplayBannerModal(true)}
                type="STRONG_GREEN"
              />
              <UploadImageModal
                endpoint={`/v1/groups/${group.id}/image`}
                title={'Upload Your Group Banner'}
                imageType="banner"
                show={displayBannerModal}
                onClose={() => setDisplayBannerModal(false)}
                forcePageReload
              />
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default GroupImages
