import React from 'react'
import lattusApiClient from '../../lattusApi'
import { withErrorHandler } from '../../errors'
import ProfileCard from '../ProfileCard/ProfileCard'
import Filter from '../Filter/Filter'
import { ratingValues } from '../Forms/Ratings'
import { Spinner } from 'react-bootstrap'

// deprecated page
const { listMatchesV1 } = lattusApiClient

class MatchesPage extends React.Component {
  constructor(props) {
    super(props)
    this.getMatches = this.getMatches.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.filter = this.filter.bind(this)
    this.filterRating = this.filterRating.bind(this)
    this.state = {
      matches: [],
      filteredMatches: [],
      filter: {
        topics: '',
        ratings: '',
        people: '',
      },
      showSpinner: true,
    }
    alert('yo')
  }

  componentDidMount() {
    const { skills = [], groups = [] } = this.props
    if (skills && groups) this.getMatches(skills, groups)
  }

  componentDidUpdate(prevProps) {
    if (this.props.skills !== prevProps.skills) {
      if (this.state.filteredMatches.length > 0) return

      const { skills, groups } = this.props
      this.getMatches(skills, groups)
    }
  }

  filter(allData, array, field) {
    const filteredData = []

    if (array.length > 0) {
      allData.map((x) => {
        x.topics.map((y) => {
          if (array.includes(y[field]) && !filteredData.includes(x)) {
            return filteredData.push(x)
          }
          return filteredData
        })
        return filteredData
      })
    }

    return filteredData
  }

  getMatches(skills, groups) {
    if (skills.length === 0) return

    const query = {
      skillId: skills,
      groupId: groups,
    }

    return listMatchesV1({ query: query })
      .then((result) => {
        this.setState({
          matches: result,
          filteredMatches: result,
          filterOptions: {},
          showSpinner: false,
        })
      })
      .catch((error) => {
        const { handleError } = this.props
        handleError(error, true, {
          message:
            'There was a problem retrieving your matches. ' + error.message,
        })
      })
  }

  onFilterChange(filter) {
    const { topics = [], ratings = [], people = [] } = filter
    let filteredData = this.state.matches

    filteredData =
      topics.length > 0
        ? this.filter(filteredData, topics, 'skillName')
        : filteredData
    filteredData =
      ratings.length > 0
        ? this.filterRating(filteredData, ratings)
        : filteredData
    filteredData =
      people.length > 0
        ? filteredData.filter((x) =>
            people.includes(x.firstName + ' ' + x.lastName),
          )
        : filteredData

    this.setState({
      filteredMatches: filteredData,
    })
  }

  filterRating(data, filterValues) {
    const text = filterValues[0].split('-')
    if (text.length === 0) return data

    const rating = Number(text[0].trim())
    return this.filter(data, [rating], 'rating')
  }

  render() {
    const { filteredMatches = [], matches = [], showSpinner } = this.state
    const { title, showFilter = false } = this.props
    const filters = [
      {
        name: 'topics',
        placeholder: 'Filter by topic',
        options: [
          ...new Set(
            matches
              .map((x) => x.topics)
              .flat()
              .map((x) => x.skillName)
              .sort(),
          ),
        ],
      },
      {
        name: 'ratings',
        placeholder: 'Filter by rating',
        options: ratingValues.map((x) => x.value + ' - ' + x.text),
      },
      {
        name: 'people',
        placeholder: 'Filter by person',
        options: [...matches.map((x) => x.firstName + ' ' + x.lastName)],
      },
    ]

    return (
      <div>
        <h2>{title}</h2>

        {showFilter && (
          <Filter
            filters={filters}
            data={filteredMatches}
            onChange={this.onFilterChange}
          />
        )}

        <div>
          {showSpinner && (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}

          {filteredMatches.map(function (match) {
            const { availabilities = 0 } = match

            return (
              <ProfileCard
                key={match.profileId}
                user={match}
                availabilities={availabilities}
              />
            )
          })}

          {filteredMatches.length === 0 && !showSpinner && (
            <div>
              <p>
                There are no other members with this topic of interest right
                now.{' '}
              </p>
              <p>
                Please visit your profile to add another topic or try to
                schedule another conversation with a different topic.{' '}
              </p>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withErrorHandler(MatchesPage)
