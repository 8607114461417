// src/components/Profile.js
import React from 'react'
import { Container } from 'react-bootstrap'

const ReportIssue = () => {
  return (
    <Container>
      <h1>Report An Issue</h1>
      <div style={{ margin: 'auto' }}>
        <iframe
          src="https://share.hsforms.com/1GWGYTBTEQs-JinoixDorMg48a29"
          title="reportIssue"
          width="100%"
          max-width="600"
          height="700px"
          frameBorder={0}
          display="initial"
          position="relative"
          overflow="hidden"
        />
      </div>{' '}
    </Container>
  )
}

export default ReportIssue
