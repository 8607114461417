import React, { useCallback, useEffect, useState } from 'react'
import compose from 'just-compose'
import StarRatings from 'react-star-ratings'
import { createUseStyles } from 'react-jss'

import colors from '../../../theme/theme2'
import CustomTextArea from '../../../elemets/CustomTextArea'
import { isMobile } from '../../../utils/common'
import ActionButton from '../../../elemets/ActionButton'
import { useApiSubmit } from '../../../api'
import { withErrorHandler } from '../../../errors'
import { CALL_LAYOUT } from '../conversations.constants'

const useStyles = createUseStyles({
  comment: {
    fontFamily: 'Lato',
    fontSize: 14,
  },
  stars: {
    width: '100%',
    textAlign: 'center',
    paddingBottom: 10,
  },
  startTitle: {
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.greyParagraph,
    padding: isMobile() ? '0 25px' : '0 40px',
  },
  starRates: {
    width: isMobile() ? '100%' : 340,
  },
  title: {
    fontFamily: 'Lato',
    fontSize: 18,
    fontWeight: 'bold',
  },
  text: {
    height: 90,
  },
  feedbackBG: {
    backgroundColor: '#f8fafb',
  },
})

const Feedback = ({ conversation, changeCallState, handleError }) => {
  const classes = useStyles()
  const [feedback, setFeedback] = useState({
    rating: 1,
    comments: '',
  })
  const {
    submitted,
    submit: leaveConversationFeedback,
    submitting,
    err,
  } = useApiSubmit('leaveConversationFeedback')

  useEffect(() => {
    if (submitted && !err && !submitting) {
      changeCallState(CALL_LAYOUT.POST)
    } else if (err && !submitting) {
      handleError(err, true, {
        message: 'There was a problem sending your feedback.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, submitting, err, handleError])

  const handleFeedbackCall = useCallback(() => {
    leaveConversationFeedback({
      params: { conversationId: conversation.id },
      body: {
        rating: feedback.rating,
        comments: feedback.comments,
      },
    })
  }, [feedback, conversation, leaveConversationFeedback])

  return (
    <>
      <p className={`pt-2 ${classes.title}`}>How was the conversation?</p>
      <div className={`${classes.feedbackBG} mt-4 pt-4 mb-3`}>
        <div className={classes.stars}>
          <StarRatings
            rating={feedback?.rating}
            starRatedColor="#4EBA7E"
            starHoverColor="#4EBA7E"
            numberOfStars={5}
            starDimension={isMobile() ? '40px' : '60px'}
            name="rating"
            starSpacing="5px"
            changeRating={(newRating) => {
              setFeedback({ ...feedback, rating: newRating })
            }}
          />
        </div>
        <div
          className={`d-flex justify-content-center pb-4 ${classes.startTitle}`}
        >
          <div
            className={`d-flex justify-content-between ${classes.starRates}`}
          >
            <div>Very Bad</div>
            <div>Very Good</div>
          </div>
        </div>
        <div className={`p-3`}>
          <span className={`${classes.comment} px-3`}>Comments / Feedback</span>
          <CustomTextArea
            value={feedback?.comments}
            onChange={(e) =>
              setFeedback({ ...feedback, comments: e.target.value })
            }
            className={classes.text}
            maxLength={500}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <ActionButton
          text="SUBMIT"
          onClick={handleFeedbackCall}
          loading={submitting}
          disabled={
            feedback &&
            feedback.comments &&
            feedback.comments.length > 500 &&
            feedback.rating === 0
          }
          type={'STRONG_GREEN'}
        />
      </div>
    </>
  )
}

export default compose(withErrorHandler)(Feedback)
