import React from 'react'
import { createUseStyles, Styles } from 'react-jss'

type AvatarProps = {
  src: string
}

const useStyles = createUseStyles<string>(
  (): Styles<string> => ({
    avatar: {
      borderRadius: '50%',
      filter: 'drop-shadow(2px 2px 4px #343434)',
      width: '100%',
    },
  }),
)

const Avatar = ({ src }: AvatarProps): JSX.Element => {
  const classes = useStyles()
  return <img src={src} alt="User's avatar" className={classes.avatar} />
}

export default Avatar
