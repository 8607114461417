import React, { useState, useCallback, useEffect } from 'react'
import colors, { actionButtonStyles, headings } from '../../theme/theme2'
import { createUseStyles } from 'react-jss'
import { Row, Col, Button } from 'react-bootstrap'
import compose from 'just-compose'
import moment from 'moment'

import { withErrorHandler } from '../../errors'
import { withNotifications } from '../../notifications'

import ActionButton from '../../elemets/ActionButton'
import CardContainer from '../../elemets/CardContainer'
import CircleName from '../../elemets/CircleName'
import Divider from '../../elemets/Divider'

import { decodeAtoBInputs, isMobile } from '../../utils/common'
import { useApiSubmit } from '../../api/index'
import MatchIcon from './MatchIcon'
import LoaderPage from '../../elemets/LoaderPage'
import RequestAvailabilityModal from '../Modals/Conversation/RequestAvailabilityModal'
import CustomSelect from '../../elemets/CustomSelect'
import ClockIcon from '../Icons/Clock'
import TopicsContainer from '../Topics/TopicsContainer'
import CircleImage from '../../elemets/CircleImage'
import { useCurrentUser } from '../Context/CurrentUser'

const useStyles = createUseStyles({
  card: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    marginBottom: 8,
  },
  mobile: {
    minHeight: 90,
  },
  arrows: {
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },
  experience: {
    top: -6,
    right: '25%',
    position: 'absolute',
    color: colors.primary,
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: 'bold',
  },
  experienceMobile: {
    top: 15,
    right: 15,
    position: 'absolute',
    color: colors.primary,
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: 'bold',
  },
  avatar: {
    fontSize: '24px !important',
    fontWeight: 'bold !important',
    color: `${colors.primary} !important`,
    backgroundColor: `#eff9f3!important`,
  },
  name: {
    ...headings.title,
    marginTop: 10,
  },
  position: {
    ...headings.paragraph,
  },
  bioTitle: {
    ...headings.paragraph,
    fontWeight: 'bold!important',
    color: colors.dark,
  },
  bio: {
    ...headings.paragraph,
    color: colors.grey3,
  },
  bioRow: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  title: {
    ...headings.title,
  },
  dateRow: {
    padding: isMobile() ? '18px 9px 14px 9px' : '13px 15px 13px 31px',
  },
  time: {
    ...headings.paragraph,
    color: colors.dark,
    fontWeight: 'bold',
  },
  subtitle: {
    ...headings.paragraph,
    color: colors.dark,
    fontWeight: 'bold',
  },
  bgGrey: {
    backgroundColor: colors.grey1,
  },
  matchScore: {
    position: 'absolute',
    top: 12,
    right: 45,
  },
  select: {
    '& *': {
      fontSize: '13px!important',
    },
  },
})

const SelectAvailability = ({ match, user, handleError }) => {
  const classes = useStyles()
  const [availabilities, setAvailabilities] = useState([])
  const [requestedAvailability, setRequestedAvailability] = useState(false)
  const [expand, setExpand] = useState(false)
  const [showRequest, setShowRequest] = useState(false)

  useEffect(() => {
    if (match) {
      let availabilitiesDays = []
      match.forEach((availability) => {
        availabilitiesDays.push(
          moment(availability.startTime).format('dddd, MMM DD, YYYY'),
        )
      })
      availabilitiesDays = availabilitiesDays.filter(
        (item, index) => availabilitiesDays.indexOf(item) === index,
      )
      setAvailabilities(
        availabilitiesDays.map((day) => {
          return {
            day: day,
            availabilities: match
              .filter(
                (av) =>
                  moment(av.startTime).format('dddd, MMM DD, YYYY') === day,
              )
              .map((av) => {
                return {
                  label: `${moment(av.startTime).format('hh:mm')} to ${moment(
                    av.endTime,
                  ).format('hh:mm A')}`,
                  value: av.startTime,
                  availability: av,
                }
              }),
          }
        }),
      )
    }
  }, [match])

  const AvailabilityRow = ({ availability, index }) => {
    const [selected, setSelected] = useState()
    const [topic, setTopic] = useState()
    const [sent, setSent] = useState(false)
    const [matchingTopics, setMatchingTopics] = useState([])
    const [count, setCount] = useState(1)
    const { currentUser } = useCurrentUser()

    const {
      submitted,
      submit: scheduleConversation,
      err,
      submitting,
    } = useApiSubmit('scheduleConversation')

    useEffect(() => {
      if (submitted && !submitting && !err) {
        setSent(true)
      } else if (!submitting && err) {
        handleError(err, true, {
          message: 'Unable to schedule this conversation.',
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitted, submitting, err])

    useEffect(() => {
      if (availability.hasOwnProperty('availabilities')) {
        setSelected(availability.availabilities[0])
      }
      if (user.hasOwnProperty('topics')) {
        setTopic({
          label: 'user.topics[0].skillName',
          value: 'user.topics[0].skillId',
        })
      }
    }, [availability])

    useEffect(() => {
      if (user?.topics && user.topics.length > 0 && currentUser?.id) {
        setMatchingTopics(
          user.topics
            ?.filter((topic) => {
              return currentUser.topics.find((profileTopic) => {
                return profileTopic.skillId === topic.skillId
              })
            })
            .map((topic) => {
              return { label: topic.skillName, value: topic.skillId }
            }),
        )
      }
    }, [currentUser])

    useEffect(() => {
      if (matchingTopics.length > 0) {
        setTopic(matchingTopics[0])
      } else {
        setTopic({
          label: user.topics[0].skillName,
          value: user.topics[0].skillId,
        })
      }
    }, [matchingTopics])

    const handleSchedule = useCallback(
      () => {
        const query = {
          token: user.token,
        }
        const body = {
          skillId: parseInt(topic.value, 10),
          startTime: moment(selected.value).utc(),
        }
        scheduleConversation({ query, body })
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [user, selected, topic],
    )
    const renderButton = useCallback(() => {
      return (
        <ActionButton
          height="34px"
          text={
            !sent ? (
              <>
                <ClockIcon
                  style={{
                    color: actionButtonStyles.secondary.text,
                    cursor: 'default',
                    marginRight: 10,
                  }}
                />
                SCHEDULE
              </>
            ) : (
              'CONVO SCHEDULED'
            )
          }
          onClick={handleSchedule}
          type="LIGHT_GREEN"
          className="text-nowrap"
          disabled={sent}
        />
      )
    }, [handleSchedule, sent])
    const handleSelect = useCallback(
      (key, value) => {
        if (value && value.value !== selected.value) {
          if (sent) {
            setSent(false)
            availability.availabilities.splice(
              availability.availabilities.findIndex(
                (availability) => availability.value === selected.value,
              ),
              1,
            )
            setCount((c) => c + 1)
          }
          setSelected(value)
        }
      },
      [availability.availabilities, selected, sent],
    )

    const handleTopic = useCallback((key, value) => {
      setTopic(value)
    }, [])

    return (
      <div
        key={index}
        className={`${classes.dateRow} ${index % 2 ? '' : classes.bgGrey}`}
      >
        {submitting && <LoaderPage />}
        {isMobile() ? (
          <>
            <Row className="align-items-center mb-3">
              <Col xs={12} className={classes.time}>
                {moment(availability.day).format('dddd, MMM DD, YYYY')}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <CustomSelect
                  options={
                    matchingTopics.length > 0
                      ? matchingTopics
                      : user.topics.map((topic) => {
                          return {
                            label: topic.skillName,
                            value: topic.skillId,
                          }
                        })
                  }
                  name="topic"
                  value={topic}
                  onSelect={handleTopic}
                  placeholder={'Select Topic of Interest'}
                  className={`${classes.select}`}
                />
              </Col>
            </Row>
            <Row className={`mt-3`}>
              <Col xs={6}>
                <CustomSelect
                  id={count}
                  options={availability.availabilities}
                  name="startTime"
                  value={selected}
                  onSelect={handleSelect}
                  className={`${classes.select}`}
                />
              </Col>
              <Col xs={6} className="d-flex justify-content-end">
                {renderButton()}
              </Col>
            </Row>
          </>
        ) : (
          <Row className="align-items-center">
            <Col xs={3} className={classes.time}>
              {moment(availability.day).format('dddd, MMM DD, YYYY')}
            </Col>
            <Col xs={2} className={classes.time}>
              <CustomSelect
                id={count}
                options={availability.availabilities}
                name="startTime"
                value={selected}
                onSelect={handleSelect}
                className={`${classes.select}`}
              />
            </Col>
            <Col xs={4}>
              <CustomSelect
                options={
                  matchingTopics.length > 0
                    ? matchingTopics
                    : user.topics.map((topic) => {
                        return { label: topic.skillName, value: topic.skillId }
                      })
                }
                name="topic"
                value={topic}
                onSelect={handleTopic}
                placeholder={'Select Topic of Interest'}
                className={`${classes.select}`}
              />
            </Col>
            <Col className="d-flex justify-content-end">{renderButton()}</Col>
          </Row>
        )}
      </div>
    )
  }

  return (
    <>
      {showRequest && (
        <RequestAvailabilityModal
          show={showRequest}
          match={user}
          setRequestedAvailability={setRequestedAvailability}
          onClose={() => setShowRequest(false)}
        />
      )}
      <CardContainer className={classes.card}>
        <span className={classes.title}>Select Availability</span>
      </CardContainer>
      {!isMobile() ? (
        <CardContainer className={classes.card}>
          <Row>
            <Col xs={4} className={'pt-2 pl-4'}>
              <Row noGutters className="align-items-center">
                {user.avatar ? (
                  <CircleImage
                    src={user.avatar}
                    size={60}
                    className={classes.avatar}
                  />
                ) : (
                  <CircleName
                    id="avatar-name"
                    size={60}
                    text={
                      user.firstName && user.lastName
                        ? user.firstName[0] + user?.lastName[0]
                        : ''
                    }
                    className={classes.avatar}
                  />
                )}
                <div className="pl-3">
                  <p className={`mb-0 ${classes.name}`}>
                    {user.firstName} {user.lastName}
                  </p>
                  <p className={classes.position}>{user.title}</p>
                </div>
              </Row>
            </Col>
            <Col xs={5} className={'pt-3'}>
              <TopicsContainer topics={user.topics} />
            </Col>
            <div className={classes.matchScore}>
              <MatchIcon score={user.score} />
            </div>
          </Row>
          <Row>
            <Col xs={12} className={classes.bioRow}>
              <Divider className="mt-3" />
              <span className={classes.bioTitle}>Bio / Executive Summary</span>
              <p className={`mt-1 ${classes.bio}`}>
                {decodeAtoBInputs(user.bio)}
              </p>
            </Col>
          </Row>
          <label className={`${classes.title} pl-3 mb-2`}>
            Select Availability
          </label>
          {availabilities.map((av, index) => (
            <AvailabilityRow key={index} availability={av} index={index} />
          ))}
          <div className="d-flex justify-content-end mt-3">
            <ActionButton
              height="34px"
              text={
                requestedAvailability
                  ? 'REQUEST HAS BEEN SENT'
                  : 'REQUEST ADDITIONAL TIME'
              }
              onClick={
                requestedAvailability ? () => {} : () => setShowRequest(true)
              }
              type="LIGHT_GREEN"
              className="text-nowrap"
              disabled={requestedAvailability}
            />
          </div>
        </CardContainer>
      ) : (
        <CardContainer className={`${classes.container} ${classes.mobile}`}>
          <div className={classes.matchIcon}>
            <MatchIcon score={user.score} />
          </div>
          <Row>
            <Col xs={12}>
              <Row noGutters className="align-items-center">
                {user.avatar ? (
                  <CircleImage
                    src={user.avatar}
                    size={40}
                    className={classes.avatar}
                  />
                ) : (
                  <CircleName
                    id="avatar-name"
                    size={40}
                    text={
                      user.firstName && user.lastName
                        ? user.firstName[0] + user?.lastName[0]
                        : ''
                    }
                    className={classes.avatar}
                  />
                )}
                <div className="pl-3">
                  <p className={`mb-0 ${classes.name}`}>
                    {user.firstName} {user.lastName}
                  </p>
                  <p className={classes.position}>{user.title}</p>
                </div>
              </Row>
            </Col>
          </Row>
          <TopicsContainer topics={user.topics} />
          <Divider className="my-3" />
          {user.bio && (
            <>
              <div>
                <span className={classes.bioTitle}>
                  Bio / Executive Summary
                </span>
                <Button
                  className={classes.arrows}
                  onClick={() => {
                    setExpand(!expand)
                  }}
                >
                  <span>
                    <i
                      className={`fas ${
                        expand ? 'fa-chevron-down' : 'fa-chevron-right'
                      }`}
                    />
                  </span>
                </Button>
              </div>
              {expand && (
                <p className={`mt-1 ${classes.bio}`}>
                  {decodeAtoBInputs(user.bio)}
                </p>
              )}
            </>
          )}
          <div className="mt-3">
            {availabilities.map((i, index) => (
              <AvailabilityRow key={index} availability={i} index={index} />
            ))}
          </div>
          <div className="d-flex justify-content-end mt-3">
            <ActionButton
              height="34px"
              text={
                requestedAvailability
                  ? 'REQUEST HAS BEEN SENT'
                  : 'REQUEST ADDITIONAL TIME'
              }
              onClick={
                requestedAvailability ? () => {} : () => setShowRequest(true)
              }
              type="LIGHT_GREEN"
              className="text-nowrap"
              disabled={requestedAvailability}
            />
          </div>
        </CardContainer>
      )}
    </>
  )
}

export default compose(withErrorHandler, withNotifications)(SelectAvailability)
