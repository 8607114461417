/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useEffect } from 'react'
import { Row, Col, Collapse } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import { withRouter } from 'react-router-dom'
import moment from 'moment/moment'
import { isMobile as isMobileDetect } from 'react-device-detect'
import { useIsMounted, useInterval, useUnmount } from 'usehooks-ts'

import { useErrorHandler } from '@/errors'
import { getConversation } from '@/conversations/details/getConversationV1'
import { compose } from '@/utils/compose-hoc'

// Theme
import colors, { responsive } from '../../../theme/theme2'

// Elements
import CardContainer from '../../../elemets/CardContainer'

// Components
import Timer from './Timer'
import VideoCall from '../../VideoCall'

// Utils
import { isMobile } from '@/utils/common.js'
import { log } from '@/utils/logger'

// Context
import { Context } from '../../MobileNavVisibility'
import Divider from '../../../elemets/Divider'
import SkillLevelIcon from '../../Icons/SkillLevel'
import { ratingValues } from '../../Forms/Ratings'
import LinkButton from '../../../elemets/LinkButton'
import Feedback from '../Components/Feedback'
import {
  ConversationFromApi,
  Participant,
} from '@/components/Conversations/conversation.types'
import { CallLayout } from '@/components/Conversations/conversations.constants'

const font = {
  fontFamily: 'Lato',
  marginBottom: 0,
}
const useStyles = createUseStyles({
  name: {
    ...font,
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.dark,
  },
  position: {
    ...font,
    fontSize: 12,
  },
  mobileText: {
    ...font,
    fontSize: 14,
    fontWeight: 'bold',
  },
  mobileSubtitle: {
    ...font,
    fontSize: 12,
    color: colors.grey2,
  },
  subtitle: {
    ...font,
    fontSize: 14,
    color: colors.grey2,
  },
  text: {
    ...font,
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.dark,
    width: '100%',
    marginBottom: 2,
  },
  skills: {
    textAlign: 'end',
  },
  header: {
    marginBottom: 5,
  },
  mobileToast: {
    '& .toast': {
      maxWidth: 'unset',
    },
  },
  showDetailsButton: {
    color: colors.primary,
    '&:hover, &:active, &:focus': {
      color: colors.primary,
    },
  },
  showDetails: {
    padding: 10,
    paddingTop: 0,
    width: '100%',
  },
  row: {},
  title: {},
  ...responsive,
})

type Props = {
  conversation: ConversationFromApi & any
  otherParticipant: Participant
  me: Participant
  startTime: string
  endTime: string
  allowedBeforeTime: string
  allowedAfterTime: string
  onTimeChange: (t: string) => Promise<void>
  isExtendingConversation: boolean
  selectedLayout: CallLayout
  setSelectedLayout: (l: CallLayout) => void
  updateEndTimeOnly: (t: string) => void
}

const Call = React.memo(
  ({
    conversation,
    otherParticipant,
    me,
    startTime,
    endTime,
    allowedBeforeTime,
    allowedAfterTime,
    onTimeChange,
    isExtendingConversation,
    selectedLayout,
    setSelectedLayout,
    updateEndTimeOnly,
  }: Props) => {
    // isMounted
    const isMounted = useIsMounted()

    // useInterval
    const classes = useStyles()
    const [displayFeedbackModal, setDisplayFeedbackModal] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [checkConvo, setCheckConvo] = useState(false)
    const [isChecking, setIsChecking] = useState(false)
    const [elapsedSeconds, setElapsedSeconds] = useState(0)
    const handleError = useErrorHandler()

    const context = React.useContext(Context)

    const checkConversation = useCallback(async () => {
      if (checkConvo && conversation.id && endTime && !isChecking) {
        setIsChecking(true)
        const controller = new AbortController()
        try {
          const response = await getConversation(
            conversation.id,
            controller.signal,
          )
          if (controller.signal.aborted) {
            return
          }
          if (response.scheduledEndTime !== endTime) {
            updateEndTimeOnly(response.scheduledEndTime)
          }
        } catch (err: any) {
          if (controller.signal.aborted || err.name === 'AbortError') {
            return
          }
          handleError(err, false)
        } finally {
          setIsChecking(false)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversation.id, endTime, checkConvo, isChecking])

    useEffect(() => {
      if (isMounted()) {
        setCheckConvo(true)
      } else {
        setCheckConvo(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted])

    // check if conversation scheduledEndTime needs to be updated
    useInterval(
      () => {
        checkConversation()
        log.debug('interval: checkConversation')
      },
      // Delay in milliseconds or null to stop it
      isMounted() && checkConvo ? 5000 : null,
    )

    // Hide footer
    useEffect(() => {
      if (isMobileDetect) {
        context.setVisibility(false)
        return () => context.setVisibility(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context])

    const Item = useCallback(
      ({ title, children, className = '' }) => (
        <div className={`${classes.row} ${className}`}>
          <label className={classes.title}>{title}</label>
          <label className={classes.text}>{children}</label>
        </div>
      ),
      [classes.row, classes.text, classes.title],
    )

    // Update elapsedSeconds every second
    useInterval(
      () => {
        const now = moment.utc()
        if (
          now.isSameOrAfter(startTime) &&
          now.isSameOrBefore(allowedAfterTime)
        ) {
          log.debug('interval: update elapsedTime')
          const elapsed = now.diff(startTime, 'seconds')
          setElapsedSeconds(elapsed)
        }
      },
      isMounted() &&
        moment.utc(startTime).isSameOrAfter(allowedBeforeTime) &&
        moment.utc(endTime).isSameOrBefore(allowedAfterTime)
        ? 1000
        : null,
    )

    // Cleanup
    useUnmount(() => {
      // eslint-disable-next-line no-console
      log.debug('unmounting "InCall > Call.jsx" component')
      setCheckConvo(false)
    })

    return (
      <CardContainer className={isMobile() ? 'px-0 pb-1' : ''}>
        {!isMobile() ? (
          <div className={classes.regular}>
            <Row className={classes.header}>
              <Col xs={12} sm={6}>
                <div>
                  <label className={classes.subtitle}>
                    {conversation.skillGroupName}
                  </label>
                  <label className={classes.text}>
                    {conversation.skillName}
                  </label>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <Timer
                  elapsedSeconds={elapsedSeconds}
                  startTime={startTime}
                  endTime={endTime}
                  allowedAfterTime={allowedAfterTime}
                />
              </Col>
            </Row>
            {displayFeedbackModal && (
              <Feedback
                conversation={conversation}
                changeCallState={setSelectedLayout}
              />
            )}
            {!displayFeedbackModal && (
              <Row>
                <Col xs={12} md={12} lg={12} className="px-0 px-md-3">
                  <div
                    style={{
                      width: '100%',
                      backgroundColor: colors.grey2,
                      borderRadius: 2,
                      marginBottom: 5,
                    }}
                  >
                    <VideoCall
                      otherParticipant={otherParticipant}
                      conversation={conversation}
                      displayFeedback={setDisplayFeedbackModal}
                      startTime={startTime}
                      endTime={endTime}
                      allowedAfterTime={allowedAfterTime}
                      onTimeChange={onTimeChange}
                      isExtendingConversation={isExtendingConversation}
                      selectedLayout={selectedLayout}
                      setSelectedLayout={setSelectedLayout}
                    />
                  </div>
                  <Col
                    xs={12}
                    md={12}
                    lg={12}
                    className="px-0"
                    id="alert-container"
                  />
                </Col>
              </Row>
            )}
          </div>
        ) : (
          <div className={classes.mobile}>
            <Row className="no-gutters">
              <Col xs={12} sm={6} className="px-3">
                <Timer
                  allowedAfterTime={allowedAfterTime}
                  elapsedSeconds={elapsedSeconds}
                  startTime={startTime}
                  endTime={endTime}
                />
              </Col>
              {displayFeedbackModal && (
                <Col xs={12} sm={6} className="px-3">
                  <Feedback
                    conversation={conversation}
                    changeCallState={setSelectedLayout}
                  />
                </Col>
              )}
              {!displayFeedbackModal && (
                <>
                  <Col xs={12} sm={6} className="px-0">
                    <div
                      style={{
                        width: '100%',
                        backgroundColor: colors.grey2,
                        borderRadius: 2,
                      }}
                    >
                      <VideoCall
                        otherParticipant={otherParticipant}
                        conversation={conversation}
                        displayFeedback={setDisplayFeedbackModal}
                        startTime={startTime}
                        endTime={endTime}
                        allowedAfterTime={allowedAfterTime}
                        onTimeChange={onTimeChange}
                        isExtendingConversation={isExtendingConversation}
                        selectedLayout={selectedLayout}
                        setSelectedLayout={setSelectedLayout}
                      />
                    </div>
                    <div
                      id="alert-container"
                      className={`px-0 ${classes.mobileToast}`}
                    />
                  </Col>
                  <Col xs={6} className="pl-3 pt-1">
                    <div className="pl-2">
                      <p className={classes.name}>
                        {otherParticipant.firstName} {otherParticipant.lastName}
                      </p>
                      <p className={classes.position}>
                        {otherParticipant.title}
                      </p>
                    </div>
                  </Col>
                  <Col xs={6} className="pr-3 pt-1 d-flex justify-content-end">
                    <div className={classes.skills}>
                      <LinkButton
                        text={showDetails ? 'Hide Details' : 'Show Details'}
                        icon={
                          showDetails
                            ? 'fas fa-chevron-up'
                            : 'fas fa-chevron-down'
                        }
                        onClick={() => setShowDetails((s) => !s)}
                        iconRight
                        className={classes.showDetailsButton}
                      />
                    </div>
                  </Col>
                  <Collapse in={showDetails}>
                    <div id={'showDetails'} className={classes.showDetails}>
                      <Divider className="mb-4" />
                      <div className="pl-3">
                        <Item title="Topic:">
                          {conversation.skillGroupName}
                        </Item>
                        <Item title="Subtopic:">{conversation.skillName}</Item>
                        {otherParticipant.summary &&
                          otherParticipant.summary !== '' && (
                            <Item title="Why a Topic of Interest:">
                              {otherParticipant.summary || ''}
                            </Item>
                          )}
                        {me && me.rating && (
                          <Item title="My Rating:">
                            <>
                              {' '}
                              <SkillLevelIcon rating={me.rating} />
                              {me.rating} - {ratingValues[me.rating - 1].text}
                            </>
                          </Item>
                        )}
                        {otherParticipant && otherParticipant.rating && (
                          <Item title="Their Rating:">
                            <>
                              <SkillLevelIcon
                                rating={otherParticipant.rating}
                              />
                              {otherParticipant.rating} -{' '}
                              {ratingValues[otherParticipant.rating - 1].text}
                            </>
                          </Item>
                        )}
                      </div>
                    </div>
                  </Collapse>
                </>
              )}
            </Row>
          </div>
        )}
      </CardContainer>
    )
  },
)

export default compose<Props>(withRouter)(Call)
