import React, { useCallback, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { isBrowserSupported } from '../../utils/common'
import ChormeNotSupportedModal from '../../components/Modals/ChormeNotSupportedModal'

type JoinCallProps = {
  conversationId: number
  disabled?: boolean
  className?: string
}

const JoinCall = ({
  conversationId,
  disabled = false,
  className,
}: JoinCallProps): JSX.Element => {
  const [warning, setWarning] = useState(false)
  const history = useHistory()
  const handleJoinCall = useCallback(() => {
    if (!isBrowserSupported()) {
      setWarning(true)
      return
    }
    history.push(`/conversation/${conversationId}`)
  }, [conversationId, history])
  const dismissWarning = useCallback(() => {
    setWarning(false)
  }, [])
  return (
    <>
      <Button
        onClick={handleJoinCall}
        disabled={disabled}
        className={className}
      >
        Join convo
      </Button>
      <ChormeNotSupportedModal show={warning} onClose={dismissWarning} />
    </>
  )
}

export default JoinCall
