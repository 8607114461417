import React from 'react'
import { Button } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import colors, { squareButtonStyles } from '../theme/theme2'

const useStyles = createUseStyles({
  button: {
    zIndex: 2,
    height: 40,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: squareButtonStyles.initial.background,
    border: `1px solid ${squareButtonStyles.initial.border}`,
    color: squareButtonStyles.initial.icon,
    '&:focus': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      color: squareButtonStyles.hover.icon,
      backgroundColor: squareButtonStyles.hover.background,
      border: `1px solid ${squareButtonStyles.hover.border}`,
      boxShadow: '0 3px 6px 0 rgba(153, 155, 168, 0.61)',
    },
    '&:disabled': {
      color: squareButtonStyles.hover.icon,
      backgroundColor: squareButtonStyles.hover.background,
      border: `1px solid ${squareButtonStyles.hover.border}`,
      boxShadow: '0 3px 6px 0 rgba(153, 155, 168, 0.61)',
    },
    '&:active': {
      color: `${squareButtonStyles.click.icon} !important`,
      backgroundColor: `${squareButtonStyles.click.background} !important`,
      border: `1px solid ${squareButtonStyles.click.border} !important`,
      boxShadow: '0 3px 6px 0 rgba(153, 155, 168, 0.61)',
    },
  },
  active: {
    backgroundColor: colors.primary,
    border: `1px solid ${colors.primary}`,
    color: 'white',
    '&:hover': {
      color: 'white',
      backgroundColor: colors.primary700,
      border: `1px solid ${colors.primary700}`,
    },
    '&:active': {
      color: 'white !important',
      backgroundColor: `${colors.primary} !important`,
      border: `1px solid ${colors.primary} !important`,
    },
  },
})

const SquareButton = ({
  children,
  onClick,
  isActive,
  className = {},
  disabled = false,
}) => {
  const classes = useStyles()

  return (
    <Button
      className={[classes.button, isActive ? classes.active : {}, className]}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  )
}

export default SquareButton
