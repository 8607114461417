import React, { ReactNode } from 'react'
import Paper from '@material-ui/core/Paper'
import { createUseStyles } from 'react-jss'
import SectionTitle from './SectionTitle'

type SectionProps = {
  title?: string
  children: ReactNode
}

const useStyles = createUseStyles({
  root: {
    padding: '16px',
    marginBottom: '8px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
})

const Section = ({ title, children }: SectionProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      {title ? <SectionTitle value={title} /> : null}
      {children}
    </Paper>
  )
}

export default Section
