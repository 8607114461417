import React, { type FC, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useFeatureFlag } from 'configcat-react'
import { useIsMounted } from 'usehooks-ts'

import { FeatureFlags, FeatureFlagDefaults } from '@/config/ConfigEnums'
import ActionButton from '../../../elemets/ActionButton'
import GroupIcon from '../../Icons/Group'
import MeetNowModal from './MeetNowModal'

const useStyles = createUseStyles({
  container: {
    '& .modal-dialog': {
      minWidth: 730,
    },
  },
  actionButton: {
    width: '122px!important',
    fontWeight: 'bold!important',
    fontSize: '12px!important',
    paddingLeft: '0!important',
    paddingRight: '0!important',
  },
  iconText: {
    paddingLeft: 10,
  },
})

type Props = {
  match: any
  buttonType?: string
  showIcon?: boolean
  reloadUpcoming: () => void
  className?: string
  style?: React.CSSProperties
}

const MeetNowButton: FC<Props> = ({
  match,
  buttonType,
  showIcon,
  reloadUpcoming,
  className = '',
  style = {},
}: Props) => {
  const isMounted = useIsMounted()
  const { value: meetNowValue, loading: meetNowLoading } = useFeatureFlag(
    FeatureFlags.meetNow,
    FeatureFlagDefaults.meetNow,
  )
  const [showModal, setShowModal] = useState(false)
  const classes = useStyles()

  if (!buttonType) {
    buttonType = 'STRONG_ORANGE'
  }

  if (meetNowLoading || !meetNowValue || !isMounted()) {
    return null
  }

  return (
    <div className={`${classes.container} ${className}`} style={style}>
      {showModal ? (
        <MeetNowModal
          show={showModal}
          match={match}
          onClose={() => {
            if (isMounted()) {
              setShowModal(false)
              reloadUpcoming()
            }
          }}
        />
      ) : null}

      <ActionButton
        className={`${classes.actionButton}`}
        type={buttonType}
        text={
          <>
            {showIcon && (
              <>
                <GroupIcon />
                &nbsp;&nbsp;
              </>
            )}
            <span className={showIcon ? classes.iconText : ''}>Meet Now</span>
          </>
        }
        onClick={() => {
          if (isMounted()) {
            setShowModal(true)
          }
        }}
      />
    </div>
  )
}

export default MeetNowButton
