import React from 'react'
import { createUseStyles } from 'react-jss'
import { Container } from 'react-bootstrap'
import Notification, { NotificationData } from './Notification'
import { LattusTheme } from '../../theme'

type NotificationsProps = {
  notifications: NotificationData[]
  clearNotification: (id: number) => void
}

const useStyles = createUseStyles<string, unknown, LattusTheme>(theme => ({
  root: {
    position: 'fixed',
    right: theme.spacing(1),
    zIndex: theme.zIndex.toast,
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '350px',
    padding: '16px',
  },
}))

const Notifications = ({
  notifications,
  clearNotification,
}: NotificationsProps): JSX.Element | null => {
  const classes = useStyles()

  if (notifications.length < 1) {
    return null
  }

  return (
    <Container>
      <div className={classes.root || ''}>
        <div className={classes.wrapper || ''}>
          {notifications.map(data => (
            <Notification
              key={data.id}
              onClose={() => clearNotification(data.id)}
              {...data}
            />
          ))}
        </div>
      </div>
    </Container>
  )
}

export default Notifications
