import React, { useCallback, useEffect, useState } from 'react'
import ActionButton from '../../../elemets/ActionButton'
import AutoSuggestInput from '../../../elemets/AutoSuggestInput'
import CardContainer from '../../../elemets/CardContainer'
import { Col, Row, Button, Collapse } from 'react-bootstrap'
import colors from '../../../theme/theme2'
import { createUseStyles } from 'react-jss'
import CustomTextArea from '../../../elemets/CustomTextArea'
import Divider from '../../../elemets/Divider'
import GravatarContainer from '../../Users/GravatarContainer'
import Icon from '../../Icons'
import Modal from '../../../elemets/Modal'
import TopicsContainer from '../../Topics/TopicsContainer'
import { useCurrentUser } from '../../Context/CurrentUser'
import { useGroupMembers } from '../../Context/GroupMembers'
import { useApiSubmit } from '../../../api'
import { withNotifications } from '../../../notifications'
import { withErrorHandler } from '../../../errors'
import compose from 'just-compose'
import { isMobile } from '../../../utils/common'

const useStyles = createUseStyles({
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
      '& > div': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  body: {
    padding: 0,
  },
  notes: {
    minHeight: 82,
  },
  close: {
    alignItems: 'center',
    display: 'flex',
    padding: '3px',
    backgroundColor: 'transparent',
    border: 'none',
    color: colors.primary,
    '&:focus': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      color: colors.primary,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&:active': {
      color: `${colors.primary} !important`,
      backgroundColor: 'transparent !important',
      border: 'none !important',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  expandButton: {
    fontFamily: 'Lato',
    fontSize: 12,
    textAlign: 'right',
  },
  arrows: {
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },
})

export const RecommendMatchCard = ({ match, closeIcon = false, onClose }) => {
  const classes = useStyles()
  const [expand, setExpand] = useState(!isMobile())
  const [swipe, setSwipe] = useState()
  return (
    <CardContainer>
      {closeIcon && (
        <div className={classes.closeButton}>
          <Button className={classes.close} onClick={onClose}>
            <i className="far fa-times-circle" />
          </Button>
        </div>
      )}
      <Row>
        <Col xs={8}>
          <GravatarContainer profile={match} size={40} />
        </Col>
        <Col xs={4} className={`px-0 align-self-center`}>
          {swipe}
          {isMobile() && (
            <Button
              className={`${classes.arrows}`}
              onClick={() => {
                setExpand(!expand)
              }}
            >
              <span className={classes.expandButton}>
                {expand ? 'Show Less' : 'Show More'}
                <i
                  className={`ml-1 fas ${
                    expand ? 'fa-chevron-up' : 'fa-chevron-down'
                  }`}
                />
              </span>
            </Button>
          )}
        </Col>
      </Row>
      <Collapse in={expand}>
        <div>
          <Divider />
          <TopicsContainer
            topics={match.topics}
            setSwipeComponent={setSwipe}
            showSwipe={false}
          />
        </div>
      </Collapse>
    </CardContainer>
  )
}

const RecommendModal = ({
  match,
  show,
  onClose,
  handleError,
  pushNotification,
}) => {
  const classes = useStyles()
  const [notes, setNotes] = useState('')
  const [suggestedMatch, setSuggestedMatch] = useState()
  const { groups, filterMembers } = useGroupMembers()
  const { currentUser } = useCurrentUser()

  const {
    submitted,
    submit: addRecommendation,
    err,
    submitting,
  } = useApiSubmit('addRecommendation')

  useEffect(
    () => {
      if (submitted && !err && !submitting) {
        pushNotification({
          level: 'success',
          subject: 'Recommendation Sent.',
          timeout: 10,
        })
        onClose()
      } else if (!submitting && err) {
        handleError(err, true, {
          message: 'Unable to create a recommendation.',
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [err, submitting, submitted],
  )

  const handleRecommend = useCallback(() => {
    if (!currentUser?.id) return
    addRecommendation({
      body: {
        recommendatorId: currentUser.id,
        recommendatorGroupId:
          groups.length > 0 ? groups[0] : currentUser.groups[0].id,
        userId1: match.profileId,
        userId2: suggestedMatch.member.profileId,
        notes: notes,
        topicId: 206,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, groups, match, notes, suggestedMatch])
  return (
    <Modal
      closeIcon={true}
      title={'Recommend a Conversation'}
      show={show}
      close={onClose}
      footer={
        <div className={`d-flex justify-content-end pr-2`}>
          <ActionButton
            height="34px"
            text={
              <>
                <Icon icon={'fas fa-user-plus mr-2'} />
                RECOMMEND
              </>
            }
            onClick={handleRecommend}
            type="STRONG_GREEN"
            className="text-nowrap px-3"
            disabled={!suggestedMatch || !notes}
          />
        </div>
      }
      bodyClass={isMobile() ? classes.body : ''}
      dialogClassName={isMobile() ? classes.dialogMobile : ''}
    >
      <RecommendMatchCard match={match} />
      {!suggestedMatch && (
        <div className={`pb-3`}>
          <AutoSuggestInput
            data={filterMembers
              .filter((member) => {
                return member.profileId !== match.profileId
              })
              .map((member) => {
                return {
                  member: member,
                  label: member.firstName + ' ' + member.lastName,
                  value: member.profileId.toString(),
                }
              })}
            filterFunction={(data, value) => {
              return data.filter((member) => {
                return member.label
                  ?.toLowerCase()
                  .includes(value.trim().toLowerCase())
              })
            }}
            initValue={''}
            placeHolder={'Select your recommendation (the other user)'}
            setFormValue={setSuggestedMatch}
          />
        </div>
      )}

      {suggestedMatch && (
        <RecommendMatchCard
          match={suggestedMatch.member}
          closeIcon={true}
          onClose={() => {
            setSuggestedMatch(undefined)
          }}
        />
      )}

      <CustomTextArea
        placeholder={
          'Tell both people why you are recommending this conversation to take place.'
        }
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        editMode={true}
        maxLength={1000}
        className={`${classes.notes} pt-3`}
      />
    </Modal>
  )
}

export default compose(withNotifications, withErrorHandler)(RecommendModal)
