import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react'
import { io, Socket } from 'socket.io-client'
import { useIsMounted, useUnmount } from 'usehooks-ts'

import { useBaseUri } from '../../config/useConfig'
import { useCurrentUser } from './CurrentUser'
import { useAuth0 } from '../../auth0'
// import { useAuth0 } from '@auth0/auth0-react'
import { useWafToken } from './WafTokenProvider'

// Assuming the socket could potentially be undefined initially
const SocketContext = createContext<Socket | undefined>(undefined)

export function useSocket(): Socket | undefined {
  return useContext(SocketContext)
}

interface SocketProviderProps {
  children: ReactNode
}

export default function SocketProvider({
  children,
}: SocketProviderProps): JSX.Element {
  const isMounted = useIsMounted()
  const [socket, setSocket] = useState<Socket | undefined>()
  const { currentUser } = useCurrentUser()
  const lattusApiBaseUri = useBaseUri('lattusApi')
  const { getTokenSilently, isAuthenticated } = useAuth0()
  const { wafToken } = useWafToken()

  useEffect(() => {
    if (!isMounted() || !currentUser?.id) return
    if (socket) return
    if (!wafToken) return
    let newSocket: Socket | null = null

    const initSocket = async () => {
      if (!isAuthenticated || !currentUser.id) return

      const token = await getTokenSilently()

      if (currentUser?.id && lattusApiBaseUri) {
        newSocket = io(lattusApiBaseUri, {
          path: '/sockets',
          auth: {
            token,
          },
          query: { id: currentUser.id },
          transports: ['websocket', 'polling'],
          // transports: ['websocket'],
          transportOptions: {
            headers: {
              'x-aws-waf-token': wafToken,
              authorization: `Bearer ${token}`,
            },
          },
          rememberUpgrade: true,
          extraHeaders: {
            'x-aws-waf-token': wafToken,
            authorization: `Bearer ${token}`,
          },
        })
        setSocket(newSocket)
      }
    }

    initSocket()

    // return () => {
    //   debugger
    //   if (newSocket) newSocket.close()
    // }
  }, [
    isMounted,
    currentUser,
    lattusApiBaseUri,
    getTokenSilently,
    isAuthenticated,
    wafToken,
    socket,
  ])
  useUnmount(() => {
    if (socket) socket.close()
  })

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  )
}
