import 'isomorphic-fetch'
import { useState, useEffect } from 'react'
import { getAccessToken } from '../auth0'
import { useBaseUri } from '../config/useConfig'

// reference: https://www.robinwieruch.de/react-hooks-fetch-data/

export const useAssessmentsApi = () => {
  const [data, setData] = useState(null)
  const [options, setoptions] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const assessmentsApiBaseUri = useBaseUri('assessmentsApi')

  useEffect(() => {
    if (options === null) return

    const controller = new AbortController()
    const fetchData = async () => {
      setIsError(false)
      setIsLoading(true)

      try {
        const headers = new Headers()
        const token = await getAccessToken()
        if (controller.signal.aborted) {
          return
        }

        headers.append('Authorization', `Bearer ${token}`)
        headers.append('Content-Type', 'application/json')

        const { endpoint, ...opts } = options

        const requestOptions = {
          headers,
          redirect: 'follow',
          ...opts,
          signal: controller.signal,
        }

        const result = await fetch(
          `${assessmentsApiBaseUri}${endpoint}`,
          requestOptions,
        )
          .then((r) => r.json())
          .catch((err) => {
            if (err.name === 'AbortError') {
              return
            }

            setIsError(true)
          })

        if (controller.signal.aborted) {
          return
        }

        if (result.data && result.data.assessmentId) {
          destroySurveyData(result.data.assessmentId)
        }

        setData(result)
      } catch (error) {
        if (error.name === 'AbortError') {
          return
        }

        setIsError(true)
      }

      setIsLoading(false)
    }

    fetchData()
    return () => {
      controller.abort()
    }
  }, [options, assessmentsApiBaseUri])

  function destroySurveyData(storageItemKey) {
    if (!window.localStorage.getItem('debug:lattitude')) {
      window.localStorage.removeItem(storageItemKey)
    }
  }

  return [{ data, isLoading, isError }, setoptions]
}
