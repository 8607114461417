import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import compose from 'just-compose'
import { createUseStyles } from 'react-jss'

import { useErrorHandler, withErrorHandler } from '../../errors'
import { withNotifications } from '../../notifications'

// Elements
import Modal from '../../elemets/Modal'
import ActionButton from '../../elemets/ActionButton'

// Api
import { useApiSubmit } from '../../api/index'

// Theme
import CardTitle from '../../elemets/CardTitle'
import CustomCheckbox from '../../elemets/CustomCheckbox'

const useStyles = createUseStyles({
  description: {
    width: 448,
    fontFamily: 'Lato',
    letterSpacing: 'normal',
    fontSize: 14,
    paddingLeft: 10,
  },
  title: {
    paddingLeft: 0,
  },
  checkboxCheck: {
    backgroundColor: ['#de0029', '!important'],
  },
  icon: { color: ['white', '!important'] },
})

const AccountSettingsModal = ({ show, onClose, logout }) => {
  const classes = useStyles()
  const [formState, setFormState] = useState({
    deleteAccount: false,
  })
  const handleError = useErrorHandler()

  const isFormInvalid = useMemo(() => !formState.deleteAccount, [formState])

  const handleCheckboxChange = useCallback(() => {
    setFormState(f => ({ ...f, deleteAccount: !f.deleteAccount }))
  }, [])

  const {
    submitted,
    submit: deleteProfile,
    err: deleteErr,
    submitting,
  } = useApiSubmit('deleteProfile')

  useEffect(() => {
    if (submitted && !deleteErr && !submitting) {
      onClose()
      logout()
    } else if (deleteErr && !submitting) {
      handleError(deleteErr, true, {
        message: 'There was a problem adding the goal.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, deleteErr, submitting])

  const handleDelete = useCallback(() => {
    if (isFormInvalid) {
      deleteProfile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      show={show}
      close={onClose}
      title="Account Settings"
      footer={
        <Row noGutters className="justify-content-end">
          <ActionButton
            text="DELETE ACCOUNT"
            onClick={handleDelete}
            type="STRONG_RED"
            disabled={!formState.deleteAccount}
            loading={submitting}
          />
        </Row>
      }
    >
      <Container>
        <CardTitle title="Delete Account" className={classes.title} />
        <div className="pt-4 d-flex align-items-start">
          <CustomCheckbox
            checked={formState.deleteAccount}
            backgroundColor={
              formState.deleteAccount ? classes.checkboxCheck : ''
            }
            iconColor={classes.icon}
            onClick={() => {
              handleCheckboxChange()
            }}
          />
          <label className={classes.description}>
            Yes, I want to permanently delete my Lattitude account
          </label>
        </div>
      </Container>
    </Modal>
  )
}

export default compose(
  withErrorHandler,
  withNotifications,
)(AccountSettingsModal)
