import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Collapse, Row } from 'react-bootstrap'
import CardContainer from '../../elemets/CardContainer'
import CircleName from '../../elemets/CircleName'
import { createUseStyles } from 'react-jss'
import colors, { headings } from '../../theme/theme2'
import HamburgerContainer from '../Conversations/Components/HamburgerContainer'
import TopicsModal from './TopicsModal'
import { withErrorHandler } from '../../errors'
import compose from 'just-compose'
import { useApiSubmit } from '../../api'
import { isMobile } from '../../utils/common'
import SkillLevelIcon from '../Icons/SkillLevel'
import Divider from '../../elemets/Divider'
import { useCurrentUser } from '../Context/CurrentUser'

const useStyles = createUseStyles({
  icon: {
    '& i': {
      cursor: 'default',
      '&:before': {
        fontSize: 20,
        color: colors.primary,
      },
    },
  },
  avatar: {
    fontSize: '26px!important',
    fontWeight: 'bold !important',
    color: `${colors.primary} !important`,
    backgroundColor: `#eff9f3!important`,
    marginTop: 10,
  },
  hamburgerContainer: {
    position: 'absolute',
    top: -5,
    right: 5,
  },
  expandButton: {
    fontFamily: 'Lato',
    fontSize: 12,
    textAlign: 'right',
  },
  arrows: {
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },
  buttonContainer: {
    padding: '40px 5px 0 0',
  },
  experience: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: '#707575',
  },
  subTopics: {
    ...headings.text,
    fontSize: 14,
  },
  topic: {
    ...headings.text,
    fontWeight: 'bold',
  },
  mentorBadge: {
    width: 18,
    height: 18,
    margin: '4px 0 28px 9px',
    padding: '1px 3px 3px 3px',
    backgroundColor: colors.primary,
    borderRadius: 2,
    color: colors.whiteBackground,
    fontFamily: 'lato',
    fontSize: 13,
    fontWeight: 'bold',
    cursor: 'default',
  },
  showMore: {
    position: 'absolute',
    width: 63,
    right: 35,
    bottom: 3,
  },
})

const TopicCard = ({ topic, handleError }) => {
  const classes = useStyles()
  const [editModal, showEditModal] = useState(false)
  const [expand, setExpand] = useState(false)

  const { currentUser, reload } = useCurrentUser()

  const {
    submitted,
    submit: deleteUserTopic,
    submitting,
    err,
  } = useApiSubmit('removeTopic')

  useEffect(() => {
    if (submitted && !err && !submitting) {
      reload()
    } else if (err && !submitting) {
      handleError(err, true, {
        message: 'There was a problem deleting the topic.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, err, submitting])

  const handleDelete = useCallback(() => {
    if (!currentUser?.id) return
    deleteUserTopic({
      params: { profileId: currentUser.id, topicId: topic.id },
    })
  }, [currentUser, deleteUserTopic, topic.id])

  return topic ? (
    <CardContainer>
      {editModal && (
        <TopicsModal
          onClose={() => {
            showEditModal(false)
          }}
          reload={reload}
          handleError={handleError}
          show={editModal}
          title={'Edit Topic of Interest'}
          editTopic={topic}
          edit={true}
        />
      )}
      <Row>
        <Col xs={2} md={3} lg={2}>
          <CircleName
            id="avatar-name"
            size={isMobile() ? 50 : 60}
            text={topic?.groupName[0] + topic?.groupName[1].toUpperCase()}
            className={`mt-0 ${classes.avatar}`}
          />
        </Col>
        <Col xs={9} md={8} lg={9} className={`px-md-0 px-lg-3`}>
          <Row noGutters className="justify-content-between">
            <Col xs={12} className={classes.topic}>
              <strong>{topic.groupName}</strong>
              {topic?.isMentor && (
                <span className={classes.mentorBadge}>M</span>
              )}
            </Col>
            <Col
              xs={12}
              className={`d-flex ${classes.subTopics} ${
                topic?.years >= 0 ? 'pr-0' : ''
              }`}
            >
              <div className={classes.icon}>
                <SkillLevelIcon rating={topic?.rating} />
              </div>
              {topic?.rating} - {topic?.skillName}
            </Col>
            {topic?.years >= 0 && (
              <Col xs={12} className="ml-1" style={{ paddingLeft: 10 }}>
                <span className={classes.experience}>
                  {topic?.years} Years Experience
                </span>
              </Col>
            )}
          </Row>
        </Col>
        <Col
          xs={1}
          className={`${classes.buttonContainer} d-flex justify-content-end`}
        >
          <HamburgerContainer
            buttons={[
              {
                title: 'Delete',
                onClick: () => {
                  handleDelete()
                },
                icon: 'fas fa-times',
                color: 'red',
              },
              {
                title: 'Edit',
                onClick: () => {
                  showEditModal(true)
                },
                icon: 'fas fa-edit',
                color: 'grey',
              },
            ]}
            className={classes.hamburgerContainer}
          />
          {topic.summary.length > 0 && (
            <Button
              className={`${classes.arrows} pb-0`}
              onClick={() => {
                setExpand(!expand)
              }}
            >
              <span
                className={`${classes.expandButton} ${classes.showMore} d-none d-lg-block`}
              >
                {expand ? 'Show Less' : 'Show More'}
              </span>
              <span className={classes.expandButton}>
                <i
                  className={`ml-1 fas ${
                    expand ? 'fa-chevron-up' : 'fa-chevron-down'
                  }`}
                />
              </span>
            </Button>
          )}
        </Col>
      </Row>
      <Collapse in={expand}>
        <Row>
          <Divider />
          <Col xs={12} className={`pl-4`}>
            <strong>Why a topic of interest?</strong>
            <br />
            <br />
            <span>{topic.summary}</span>
          </Col>
        </Row>
      </Collapse>
    </CardContainer>
  ) : null
}

export default compose(withErrorHandler)(TopicCard)
