import React, { ReactNode } from 'react'
import { Duration } from './Duration'
import DurationsProvider from './DurationsProvider'

const durations: Duration[] = [
  {
    key: 'LessThan10',
    label: '< 10 minutes',
  },
  {
    key: 'LessThan30',
    label: '11 - 30 minutes',
  },
  {
    key: 'Over30',
    label: '31+ minutes',
  },
]

type AvailableDurationsProps = {
  children: ReactNode
}

const AvailableDurations = ({
  children,
}: AvailableDurationsProps): JSX.Element => (
  <DurationsProvider durations={durations} ready>
    {children}
  </DurationsProvider>
)

export default AvailableDurations
