import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { headings } from '../../../theme/theme2'
import Icon from '../../Icons'
import HamburgerButton from './HamburgerButton'

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    right: 0,
    transform: 'translateY(-50%)',
    top: '50%',
    display: 'flex',
    zIndex: 10,
    borderRadius: 4,
  },
  hamburger: {
    display: 'flex',
    width: '5%',
    justifyContent: 'flex-end',
    padding: 15,
    position: 'relative',
    '& > i': {
      color: '#9b9b9b',
      position: 'absolute',
      top: '50%',
      msTransform: 'translateY(-50%)',
      transform: 'translateY(-50%)',
      zIndex: 11,
      cursor: 'pointer',
    },
  },
  hamburgerColorActive: {
    color: 'white!important',
  },
  text: {
    ...headings.text,
    fontSize: 14,
    marginBottom: 0,
    color: '#f3f3f2',
    fontWeight: 'bold',
    textAlign: 'right',
  },
  firstButton: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  lastButton: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  roundButton: {
    borderRadius: 4,
  },
})

const HamburgerContainer = ({
  buttons = [],
  setCloseFn = () => {},
  className = '',
}) => {
  const classes = useStyles()
  const [show, setShow] = useState(false)

  useEffect(
    () => {
      setCloseFn(() => () => {
        setShow(false)
      })
      return () => {
        setCloseFn(() => () => {})
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const getRadius = (index, total) => {
    if (total === 1) return classes.roundButton
    if (index === 0) return classes.firstButton
    if (index === total - 1) return classes.lastButton
    return ''
  }

  return (
    <div className={`${classes.hamburger} ${className}`}>
      {show && (
        <div className={classes.container}>
          {buttons.map((button, index) => {
            return button.component ? (
              button.component
            ) : (
              <HamburgerButton
                key={index}
                title={button.title}
                onClick={() => {
                  if (!button.keepOpen) setShow(false)
                  button.onClick()
                }}
                icon={button.icon}
                color={button.color}
                radius={getRadius(index, buttons.length)}
              />
            )
          })}
        </div>
      )}
      <Icon
        icon="fas fa-ellipsis-v"
        onClick={() => {
          setShow(s => !s)
        }}
        classstyle={`${show ? classes.hamburgerColorActive : ''}`}
      />
    </div>
  )
}

export default HamburgerContainer
