import React, { useState, useEffect } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { createUseStyles, useTheme } from 'react-jss'
import { Helmet } from 'react-helmet'
import PrivateRoute from './PrivateRoute'
import NavBar from './Navbar/NavBar'
import Home from './OnBoarding/index'
import ProfilePage from './ProfilePage'
import ResetPassword from './Profile/ResetPassword'
import PersonalEdit from './Profile/PersonalEdit'
import BioIceBreaker from './Profile/BioIceBreaker'
import { ConversationsPage, ConversationPage } from './Conversations'
import MyProfile from './Profile/MyProfile'
import Footer from './Footer/Footer'
import Availability from './Availability'
import Settings from './Settings'
import { GroupDashboard } from './Groups'
import { MatchesPage } from './Matches'
import InviteUsers from './InviteUsers'
import Unauthorized from './Unauthorized'
import IsAuthenticated from './IsAuthenticated'
import TermsConditions from './TermsConditions'
import { ErrorBoundary } from '../errors'
import { AppNotifications } from '../notifications'
import ComingSoon from './ComingSoon'
import Payments from './Payments'
import { TopicsDashboard } from './Topics'
import ReportIssue from './ReportIssue'
// import { Topic, SubTopics, AvailabilityIntro, Bio, Users } from './Welcome'
import { Provider as MobileNavVisibilityProvider } from './MobileNavVisibility'
import ActiveCallProvider from './Conversations/Call/ActiveCall'
import { Dashboard } from './AccountLinking'
import ChromeiOSError from './ChromeiOSError'
import { isBrowserSupported } from '../utils/common'
import { useAuth0 } from '../auth0'
import AdminGroupDashboard from './Admin/Groups'
import NetworkDashboard from './Network/index'
import Landing from './WhiteLabel/Landing'
import { useBrand } from '../BrandHandler'
import CurrentUser from './Context/CurrentUser'
import GroupMembers from './Context/GroupMembers'
import UnsubscribeEmail from './UnsubscribeEmail'
import DMMessages from './DMMessages'
import ContactsProvider from './Context/ContactsProvider'
import ChatConversationProvider from './Context/ChatConversationProvider'
import SocketProvider from './Context/SocketProvider'
import { AssessmentsDashboard } from './Assessments'
import { useBaseUri } from '../config/useConfig'
import { CurrentUserGroupsProvider } from '../groups/CurrentUserGroupsContext'
import { LogoutRoute } from './Logout'

const useStyles = createUseStyles({
  root: ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
  }),
  appWrapper: ({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    minHeight: '100vh',
    '@media only screen and (max-width: 600px)': {
      paddingBottom: 100,
    },
    fontSize: 15,
    backgroundColor: '#f8fafb',
    '& .container': {
      '@media (min-width: 576px)': {
        maxWidth: 540,
      },
      '@media(min-width: 768px)': {
        maxWidth: 900,
      },
      '@media(min-width: 992px)': {
        maxWidth: 1040,
      },
      '@media(min-width: 1200px)': {
        maxWidth: 1340,
      },
    },
  }),
})

const LattusUI = ({ history }) => {
  const theme = useTheme()
  const classes = useStyles({ theme })
  const [footerVisible, setFooterVisible] = useState(true)
  const [showNavBar, setShowNavBar] = useState(true)
  const { isAuthenticated, loading } = useAuth0()
  const { isBranded, loadLanding, setLoadLanding } = useBrand()
  const helpdeskBaseUri = useBaseUri('helpdesk')

  React.useEffect(() => {
    window.routerHistory = history
  }, [history])

  useEffect(() => {
    if (!localStorage.getItem('useChrome') && !isBrowserSupported()) {
      history.push('/wrongBrowser', { from: history.location.pathname })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isAuthenticated && !isBrowserSupported()) {
      localStorage.clear()
    }
    if (isBranded && !loading && isAuthenticated) {
      setLoadLanding(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, loading, isBranded])

  return (
    !loading &&
    (loadLanding ? (
      <Landing />
    ) : (
      <div className={classes.root}>
        <CurrentUser>
          <CurrentUserGroupsProvider>
            <SocketProvider>
              <ContactsProvider>
                <ChatConversationProvider>
                  <NavBar show={showNavBar} />
                  <AppNotifications />
                  <MobileNavVisibilityProvider
                    value={{ setVisibility: setFooterVisible }}
                  >
                    <ErrorBoundary>
                      <ActiveCallProvider>
                        <div className={classes.appWrapper}>
                          <IsAuthenticated>
                            <TermsConditions />
                          </IsAuthenticated>
                          <Switch>
                            {/*public routes*/}
                            <Route path="/logout" component={LogoutRoute} />
                            <Route
                              path="/wrongBrowser"
                              render={ChromeiOSError}
                            />
                            <Route
                              path="/unsubscribe-email/:emailKey/:id"
                              component={UnsubscribeEmail}
                            />
                            <Route
                              path="/helpDesk"
                              component={() => {
                                window.location.href = helpdeskBaseUri
                                return null
                              }}
                            />

                            {/*private routes*/}
                            <PrivateRoute exact path="/">
                              <Helmet>
                                <title>Home</title>
                              </Helmet>
                              <Home />
                            </PrivateRoute>
                            <PrivateRoute path="/home" component={Home} />
                            <PrivateRoute
                              path="/dmmessages"
                              render={DMMessages}
                            />

                            <PrivateRoute
                              path="/availability"
                              component={Availability}
                            />
                            <PrivateRoute
                              exact
                              path="/profile"
                              component={MyProfile}
                            />
                            <PrivateRoute
                              exact
                              path="/profile/edit"
                              component={PersonalEdit}
                            />
                            <PrivateRoute
                              exact
                              path="/profile/reset-password"
                              component={ResetPassword}
                            />
                            <PrivateRoute
                              exact
                              path="/profile/experience"
                              component={MyProfile}
                            />
                            <PrivateRoute
                              exact
                              path="/profile/education"
                              component={MyProfile}
                            />
                            <PrivateRoute
                              exact
                              path="/profile/bio"
                              component={BioIceBreaker}
                            />
                            <PrivateRoute
                              exact
                              path="/profile/:id"
                              component={ProfilePage}
                            />
                            <PrivateRoute
                              path="/account"
                              component={Dashboard}
                            />
                            <PrivateRoute
                              path="/assessments"
                              component={AssessmentsDashboard}
                            />
                            <PrivateRoute
                              path="/conversation/:id"
                              render={(props) => (
                                <ConversationPage showNavBar={setShowNavBar} />
                              )}
                            />
                            <PrivateRoute
                              path="/conversations"
                              component={ConversationsPage}
                            />
                            <PrivateRoute
                              path="/users/invite"
                              component={InviteUsers}
                            />
                            <PrivateRoute
                              path="/settings"
                              component={Settings}
                            />

                            <PrivateRoute
                              path="/matches"
                              component={MatchesPage}
                            />
                            <PrivateRoute
                              path="/topics"
                              component={TopicsDashboard}
                            />
                            <PrivateRoute
                              path="/unauthorized"
                              component={Unauthorized}
                            />
                            <PrivateRoute
                              path="/comingsoon"
                              component={ComingSoon}
                            />
                            <PrivateRoute
                              path="/payment"
                              component={Payments}
                            />
                            <PrivateRoute
                              path="/reportissue"
                              component={ReportIssue}
                            />

                            {/* welcome wizard - partially implemented, but not ready for production use */}
                            {/*<PrivateRoute*/}
                            {/*  path="/welcome/topics/:id/subtopics"*/}
                            {/*  component={SubTopics}*/}
                            {/*/>*/}
                            {/*<PrivateRoute*/}
                            {/*  path="/welcome/topics"*/}
                            {/*  component={Topic}*/}
                            {/*/>*/}

                            {/*<PrivateRoute path="/welcome/bio" component={Bio} />*/}
                            {/*<PrivateRoute*/}
                            {/*  path="/welcome/availability"*/}
                            {/*  component={AvailabilityIntro}*/}
                            {/*/>*/}
                            {/*<PrivateRoute*/}
                            {/*  path="/welcome/matches"*/}
                            {/*  component={Users}*/}
                            {/*/>*/}

                            <GroupMembers>
                              <PrivateRoute
                                path="/admin/groups/:id"
                                component={AdminGroupDashboard}
                              />
                              <PrivateRoute
                                path="/network"
                                component={NetworkDashboard}
                              />
                              <PrivateRoute
                                path="/group/:id/dashboard"
                                component={GroupDashboard}
                              />
                            </GroupMembers>

                            <Route path="*">
                              <Redirect to="/" />
                            </Route>
                          </Switch>
                        </div>
                        {footerVisible && <Footer className="Footer" />}
                      </ActiveCallProvider>
                    </ErrorBoundary>
                  </MobileNavVisibilityProvider>
                </ChatConversationProvider>
              </ContactsProvider>
            </SocketProvider>
          </CurrentUserGroupsProvider>
        </CurrentUser>
      </div>
    ))
  )
}

export default withRouter(LattusUI)
