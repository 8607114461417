import React, { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import CardContainer from '../../elemets/CardContainer'
import CardTitle from '../../elemets/CardTitle'
import colors, { headings } from '../../theme/theme2'
import ActionButton from '../../elemets/ActionButton'
import { Button, Row } from 'react-bootstrap'
import ArrowRightIcon from '../Icons/ArrowRight'
import scrollToComponent from 'react-scroll-to-component'
import { isMobile } from '../../utils/common'

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    backgroundColor: colors.whiteBackground,
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    '& .card': {
      marginBottom: 0,
    },
    zIndex: '9!important',
    borderRadius: 5,
    width: '100%',
    maxWidth: 480,
  },
  containerMobile: {
    width: '94%',
  },
  text: {
    ...headings.text,
    fontSize: 14,
    textAlign: 'justify',
  },
  footer: {
    position: 'relative',
    bottom: 0,
    width: '100%',
    height: 60,
    backgroundColor: '#f3f3f2',
  },
  innerCircle: {
    width: 11,
    height: 11,
    borderRadius: 25,
    backgroundColor: colors.whiteBackground,
    position: 'relative',
    top: 3,
    left: 3,
  },
  outerCircle: {
    width: 17,
    height: 17,
    borderRadius: 25,
    backgroundColor: colors.primary,
    cursor: 'pointer',
  },
  tooltipFooter: {
    width: '33%',
  },
  tooltipProgress: {
    display: 'flex',
    paddingTop: 20,
  },
  progressBar: {
    width: 20,
    height: 3,
    backgroundColor: colors.grey2,
    marginTop: 6,
  },
  footerText: {
    ...headings.title,
    fontSize: 17,
    padding: 14,
  },
  footerButton: {
    display: 'grid',
    justifyContent: 'right',
  },
  button: {
    borderRadius: '0!important',
    width: '120px!important',
    height: '60px!important',
    padding: '0!important',
    margin: '0!important',
    zIndex: '10!important',
    fontSize: '18px!important',
    '&:hover': {
      border: 'none',
      boxShadow: 'unset!important',
    },
  },
  mobileButton: {
    width: '90px!important',
  },
  arrow: {
    width: 0,
    height: 0,
    position: 'absolute',
  },
  arrowDown: {
    right: 8,
    borderRight: '20px solid transparent',
    borderLeft: '20px solid transparent',
    borderTop: '30px solid white',
  },
  arrowDownMobile: {
    left: '35px!important',
    right: 'unset!important',
    borderRight: '20px solid transparent',
    borderLeft: '20px solid transparent',
    borderTop: '30px solid white',
  },
  arrowDownMobileHelp: {
    left: 'unset!important',
    right: '35px!important',
    borderRight: '20px solid transparent',
    borderLeft: '20px solid transparent',
    borderTop: '30px solid white',
  },
  arrowUp: {
    top: -30,
    right: 70,
    borderRight: '20px solid transparent',
    borderLeft: '20px solid transparent',
    borderBottom: '30px solid white',
  },
  arrowRight: {
    top: 90,
    right: -40,
    borderTop: '20px solid transparent',
    borderBottom: '20px solid transparent',
    borderLeft: '40px solid white',
  },
  arrowLeft: {
    top: 90,
    left: -40,
    borderTop: '20px solid transparent',
    borderBottom: '20px solid transparent',
    borderRight: '40px solid white',
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'transparent',
    border: 'none',
    color: colors.dark,
    '&:focus': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      color: colors.dark,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&:active': {
      color: `${colors.dark} !important`,
      backgroundColor: 'transparent !important',
      border: 'none !important',
    },
  },
})

const Tooltip = ({
  title = '',
  content,
  activeNumber = 1,
  setTooltip,
  close,
  arrow = 'arrowLeft',
  className,
}) => {
  const classes = useStyles()
  const tooltipRef = useRef(null)

  useEffect(() => {
    scrollToComponent(tooltipRef.current, {
      offset: 0,
      align: 'middle',
      duration: 500,
      ease: 'inCirc',
    })
  }, [])

  return (
    <div
      className={`${classes.container} ${className} ${
        isMobile() ? classes.containerMobile : ''
      }`}
      ref={tooltipRef}
    >
      <CardContainer
        className={`p-0`}
        header={
          <>
            <CardTitle title={title} className={`pt-3`} />
            <Button className={classes.close} onClick={close}>
              <i className="far fa-times-circle" />
            </Button>
          </>
        }
        footer={
          <Row
            noGutters
            className={`${classes.footer} p-0 mb-0 d-flex justify-content-between`}
          >
            <div className={`${classes.tooltipFooter}`}>
              <p className={`${classes.footerText} mb-0`}>
                Lattitude Tip {activeNumber}
              </p>
            </div>
            <div
              className={`${classes.tooltipFooter} ${classes.tooltipProgress}`}
            >
              {[1, 2, 3, 4].map(tip => {
                return (
                  <>
                    <div
                      className={classes.outerCircle}
                      onClick={() => {
                        setTooltip(tip)
                      }}
                    >
                      {activeNumber < tip && (
                        <div className={classes.innerCircle} />
                      )}
                    </div>
                    {tip !== 4 && <div className={classes.progressBar} />}
                  </>
                )
              })}
            </div>
            <div className={`${classes.tooltipFooter} ${classes.footerButton}`}>
              <ActionButton
                className={`${classes.button} ${
                  isMobile() ? classes.mobileButton : ''
                }`}
                type={'STRONG_GREEN'}
                text={
                  <>
                    {activeNumber !== 4 ? (
                      <span>
                        {' '}
                        Next &nbsp; <ArrowRightIcon />
                      </span>
                    ) : (
                      'Close'
                    )}
                  </>
                }
                onClick={() => {
                  activeNumber !== 4 ? setTooltip(activeNumber + 1) : close()
                }}
              />
            </div>
          </Row>
        }
      >
        <div
          className={`pr-4 pl-4 pb-4 ${!isMobile() ? 'pt-3' : ''} ${
            classes.text
          }`}
        >
          {content}
        </div>
      </CardContainer>
      <div className={`${classes.arrow} ${classes[arrow]}`} />
    </div>
  )
}

export default Tooltip
