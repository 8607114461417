import compose from 'just-compose'
import React, { useEffect, useState, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

// custom utils
import { isMobile } from '../../utils/common'
import { useAssessmentsApi } from '../../api/assessmentsApi'
import { useErrorHandler, withErrorHandler } from '../../errors'
import { useCurrentUser } from '../Context/CurrentUser'

// common style
// import Icon from '../Icons'
import colors, { headings } from '../../theme/theme2'

// common elements
import CardContainer from '../../elemets/CardContainer'
import LoaderPage from '../../elemets/LoaderPage'

// THIS COMPONENT
import 'survey-core/defaultV2.css'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import { Converter } from 'showdown'

// survey custom theme & styles
// => use custom css: https://surveyjs.io/form-library/examples/customize-survey-with-css/reactjs#content-code
// => use custom theme generator: https://surveyjs.io/form-library/documentation/manage-default-themes-and-styles#apply-a-predefined-theme
import { customSurveyJsTheme } from './survey-theme'
// survey schema - temporarily hard-coded
import { surveyJsonMatchingUSPHS } from './assessment-schemas/AssessmentSchema.matching.ccusphs'
import { surveyJsonOnboarding } from './assessment-schemas/AssessmentSchema.onboarding'
import { surveyJsonRelace } from './assessment-schemas/AssessmentSchema.relace'
import {
  surveyJsonMatching,
  validateMatchingQuestions,
} from './assessment-schemas/AssessmentSchema.matching'
import {
  getAssessmentIdByPath,
  isAssessmentPath,
  isMatchingPathUSPHS,
  isMatchingPath,
  isOnboardingPath,
  isRelacePath,
} from './assessment-utils'
import { useGroups } from '../../groups/GroupsContext'
import CurrentGroup from './CurrentGroup'

const useStyles = createUseStyles({
  title: {
    ...headings.title,
    fontSize: 20,
    paddingRight: 10,
  },
  icon: {
    '& i': {
      cursor: 'unset',
      '&:before': {
        fontSize: 20,
        color: colors.primary,
      },
    },
  },
  iconOrange: {
    '& i': {
      cursor: 'unset',
      '&:before': {
        fontSize: 20,
        color: '#f5a623',
      },
    },
  },
  textMain: {
    ...headings.title,
    fontSize: 16,
  },
  textSubtitle: {
    color: colors.grey2,
  },
  addButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 16,
  },
  addButtonMobile: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonPlus: {
    fontSize: 30,
  },
})

const destroySurveyData = (storageItemKey) => {
  if (!window.localStorage.getItem('debug:lattitude')) {
    window.localStorage.removeItem(storageItemKey)
  }
}

const AssessmentsDashboard = ({ history, location }) => {
  const classes = useStyles()
  const handleError = useErrorHandler()
  const { currentUser } = useCurrentUser()
  const [{ data, isLoading: loadingAssessment }, doFetch] = useAssessmentsApi()
  const [completedAssessment, setCompletedAssessment] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { active: activeGroup } = useGroups()
  const { id: groupId } = activeGroup || {}

  // component utility functions
  const goHome = useMemo(
    () => () => {
      history.push('/home')
    },
    [history],
  )

  //
  // SurveyJS
  //

  const determineNewSurveyModel = (pathname) => {
    if (isMatchingPathUSPHS(pathname)) {
      return new Model(surveyJsonMatchingUSPHS)
    } else if (isMatchingPath(pathname)) {
      return new Model(surveyJsonMatching)
    } else if (isOnboardingPath(pathname)) {
      return new Model(surveyJsonOnboarding)
    } else if (isRelacePath(pathname)) {
      return new Model(surveyJsonRelace)
    } else {
      goHome()
    }
  }

  // SurveyJS model
  const survey = determineNewSurveyModel(location.pathname)
  survey.onComplete.add(handleOnComplete)

  // SurveyJS theme
  survey.applyTheme(customSurveyJsTheme)

  // surveyJS validation
  survey.onValidateQuestion.add(validateMatchingQuestions)

  // setup markdown converter
  const converter = new Converter()
  survey.onTextMarkdown.add(convertMarkdown)

  // save in localstorage for auto-completion
  const storageItemKey = getAssessmentIdByPath(location.pathname)
  survey.sendResultOnPageNext = true // https://surveyjs.io/form-library/documentation/handle-survey-results-continue-incomplete
  // Save survey results
  survey.onPartialSend.add((survey) => saveSurveyData(survey))
  survey.onComplete.add((survey) => saveSurveyData(survey))
  // Restore survey results
  const prevData = window.localStorage.getItem(storageItemKey) || null
  if (prevData) {
    const data = JSON.parse(prevData)
    survey.data = data
    if (data.pageNo) {
      survey.currentPageNo = data.pageNo
    }
  }

  useEffect(() => {
    if (!currentUser || !groupId) {
      return
    }

    let cancelled = false

    if (
      currentUser?.id &&
      !completedAssessment &&
      !data &&
      location.pathname &&
      isAssessmentPath(location.pathname)
    ) {
      const fetchData = async () => {
        setIsLoading(true)

        const assessmentId = getAssessmentIdByPath(location.pathname)
        const fakeResponseId = 'response1'
        const path = `/v1/assessments/${assessmentId}/responses/${fakeResponseId}`
        const queryParams = `?userId=${currentUser.id}&groupId=${groupId}`
        const endpoint = `${path}${queryParams}`
        try {
          await doFetch({
            endpoint,
            method: 'GET',
          })

          if (cancelled) {
            return
          }
        } catch (err) {
          if (cancelled) {
            return
          }

          handleError(err, true, {
            message:
              'There was a problem checking for an existing response. Please try again soon. If issues persist contact support.',
          })
        } finally {
          if (cancelled) {
            return
          }

          setIsLoading(false)
        }
      }

      fetchData()
    }

    return () => {
      cancelled = true
    }
  }, [
    currentUser,
    completedAssessment,
    data,
    location,
    groupId,
    doFetch,
    handleError,
  ])

  useEffect(() => {
    if (!!data && !!data.data) {
      goHome()
      setIsLoading(false)
    }
  }, [data, goHome])

  useEffect(() => {
    if (!completedAssessment || !currentUser?.id || !groupId) {
      return
    }

    let cancelled = false
    const fetchData = async () => {
      setIsLoading(true)

      const assessmentId = getAssessmentIdByPath(location.pathname)
      const assessmentResponse = JSON.stringify(completedAssessment.data)
      const path = `/v1/assessments/${assessmentId}/responses`
      const queryParams = `?userId=${currentUser.id}&groupId=${groupId}`
      const endpoint = `${path}${queryParams}`
      try {
        await doFetch({
          endpoint,
          method: 'POST',
          body: assessmentResponse,
        })

        if (cancelled) {
          return
        }

        destroySurveyData(storageItemKey)
      } catch (err) {
        if (cancelled) {
          return
        }

        handleError(err, true, {
          message:
            'There was a problem saving your response. Please try again soon. If issues persist contact support.',
        })
      } finally {
        if (cancelled) {
          return
        }

        setIsLoading(false)
      }
    }

    fetchData()

    return () => {
      cancelled = true
    }
  }, [
    completedAssessment,
    currentUser?.id,
    storageItemKey,
    doFetch,
    groupId,
    handleError,
    location.pathname,
  ])

  function handleOnComplete(sender) {
    setCompletedAssessment(sender)
  }

  function saveSurveyData(survey) {
    const data = survey.data
    data.pageNo = survey.currentPageNo
    window.localStorage.setItem(storageItemKey, JSON.stringify(data))
  }

  function convertMarkdown(survey, options) {
    // Convert Markdown to HTML
    let str = converter.makeHtml(options.text)
    // Remove root paragraphs <p></p>
    str = str.substring(3)
    str = str.substring(0, str.length - 4)
    // Set HTML markup to render
    options.html = str
  }

  if (!currentUser) {
    return null
  }

  return (
    <div className={!isMobile() ? 'container' : ''}>
      <Row noGutters={isMobile()} className={`m-0`}>
        {(isLoading || loadingAssessment) && <LoaderPage />}
        <Col xs={12}>
          <CardContainer
            header={
              <div className="ml-3">
                <label className={`${classes.title}`}>Assessments</label>
                <CurrentGroup />
              </div>
            }
          >
            <Survey model={survey} />
          </CardContainer>
        </Col>
      </Row>
    </div>
  )
}

export default compose(withErrorHandler)(AssessmentsDashboard)
