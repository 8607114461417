import React, { useCallback, useMemo, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { createUseStyles } from 'react-jss'

// Components
import CardContainer from '../../elemets/CardContainer'
import Divider from '../../elemets/Divider'
import RoundButton from '../../elemets/RoundButton'
import ActionButton from '../../elemets/ActionButton'
import AddAvailabilityModal from './AddAvailabilityModal'
import Calendar from './Calendar'

// Theme
import colors, { headings } from '../../theme/theme2'

const useStyles = createUseStyles({
  arrowContainer: {
    padding: '0 7px',
    cursor: 'pointer',
    color: colors.primary,
  },
  square: {
    width: 55,
    height: 55,
    borderRadius: 4,
    padding: '6px 10px',
    textAlign: 'center',
  },
  lattusCard: {
    backgroundColor: colors.primary,
  },
  lattusText: {
    marginBottom: 0,
    color: 'white',
    fontSize: 12,
    fontFamily: 'Lato',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  calendarCard: {
    backgroundColor: colors.grey1,
  },
  calendarText: {
    marginBottom: 0,
    color: colors.grey3,
    fontSize: 10,
    fontFamily: 'Lato',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  availabilityCard: {
    backgroundColor: colors.primary100,
    height: '100%',
  },
  availabilityText: {
    marginBottom: 0,
    color: colors.primary,
    fontSize: 12,
    fontFamily: 'Lato',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  textBold: {
    fontWeight: 'bold',
    fontSize: 13,
  },
  dayButton: {
    borderTopRightRadius: '0 !important',
    borderBottomRightRadius: '0 !important',
    borderRight: 'none !important',
    width: '75px !important',
  },
  monthButton: {
    borderTopLeftRadius: '0 !important',
    borderBottomLeftRadius: '0 !important',
    borderLeft: 'none !important',
    width: '75px !important',
  },
  plusIcon: {
    fontSize: 30,
  },
  arrowRight: {
    marginLeft: 30,
  },
  eventText: {
    ...headings.paragraph,
    color: colors.dark,
    fontWeight: 'bold',
    marginBottom: 0,
  },
  currentDateText: {
    ...headings.text,
    color: colors.dark,
    fontWeight: 'bold',
  },
  todayButton: {
    width: '50px !important',
  },
})
const EventsCard = React.memo(({ eventList, reload, setCurrentDate }) => {
  const classes = useStyles()
  const [displayMonth, setDisplayMonth] = useState(true)
  const [displayModal, setDisplayModal] = useState(false)
  const [selectedDay, setSelectedDay] = useState(new Date())
  const [selectedEvent, setSelectedEvent] = useState()

  const handlePrev = useCallback(() => {
    setCurrentDate(new Date(moment(selectedDay).subtract(1, 'day')))
    setSelectedDay(new Date(moment(selectedDay).subtract(1, 'day')))
  }, [selectedDay, setCurrentDate])

  const handleNext = useCallback(() => {
    setCurrentDate(new Date(moment(selectedDay).add(1, 'day')))
    setSelectedDay(new Date(moment(selectedDay).add(1, 'day')))
  }, [selectedDay, setCurrentDate])

  const handleEditEvent = useCallback(event => {
    setSelectedEvent({
      id: event.id,
      startTime: new Date(event.startTime),
      endTime: new Date(event.endTime),
      isRecurring: event.recurringAvailabilityId,
    })
    setDisplayModal(true)
  }, [])

  const EventRow = useCallback(
    ({ event, isLast }) => {
      let eventColor = {
        card: classes.calendarCard,
        text: classes.calendarText,
      }
      switch (event.type) {
        case 'availability':
          eventColor = {
            card: classes.availabilityCard,
            text: classes.availabilityText,
          }
          break
        case 'conversation':
          eventColor = { card: classes.lattusCard, text: classes.lattusText }
          break
        case 'calendar':
          eventColor = {
            card: classes.calendarCard,
            text: classes.calendarText,
          }
          break
        default:
          break
      }

      return (
        <div
          className="cursor-pointer"
          onClick={
            event.type === 'availability'
              ? () => handleEditEvent(event)
              : () => {}
          }
        >
          <Row>
            <Col xs={3}>
              <div className={`${classes.square} ${eventColor.card}`}>
                <p className={`mb-0 ${eventColor.text} ${classes.textBold}`}>
                  {moment(event.startTime).format('h:mm')}
                </p>
                <p className={`mb-0 ${eventColor.text}`}>
                  {moment(event.startTime).format('a').toUpperCase()}
                </p>
              </div>
            </Col>
            <Col className="pl-0 d-flex align-items-center">
              <label className={classes.eventText}>{event.title}</label>
            </Col>
          </Row>
          <Row>
            <Col xs={{ offset: 3 }} className="pl-0">
              {!isLast && <Divider className="my-2" />}
            </Col>
          </Row>
        </div>
      )
    },
    [classes, handleEditEvent],
  )

  const eventsToDisplay = useMemo(() => {
    const result = []
    if (eventList) {
      for (const event of eventList) {
        if (
          moment(selectedDay).format('YYYY-DD-MM') ===
          moment(event.startTime).format('YYYY-DD-MM')
        ) {
          result.push(event)
        }
      }
    }
    return result
  }, [eventList, selectedDay])

  const handleAddIntegration = useCallback(() => {
    const day = moment(selectedDay).format('YYYY-DD-MM').split('-')
    setSelectedEvent({
      startTime: new Date(day[0], day[2] - 1, day[1], '8', '00'),
      endTime: new Date(day[0], day[2] - 1, day[1], '8', '30'),
    })
    setDisplayModal(true)
  }, [selectedDay])

  const handleCloseAddAvailability = useCallback(() => {
    setDisplayModal(false)
    setSelectedEvent(undefined)
  }, [])

  const goToToday = useCallback(() => {
    setCurrentDate(new Date())
    setSelectedDay(new Date())
  }, [setCurrentDate])

  return (
    <>
      <AddAvailabilityModal
        show={displayModal}
        onClose={handleCloseAddAvailability}
        event={selectedEvent}
        reload={reload}
      />

      <Row noGutters className="mb-4 px-3">
        <Col xs={3}>
          <ActionButton
            text="TODAY"
            type="LIGHT_GREEN"
            className={classes.todayButton}
            onClick={goToToday}
          />
        </Col>
        <Col xs={6} className="d-flex justify-content-center">
          <ActionButton
            text="Day"
            type={displayMonth ? 'LIGHT_GREEN' : 'STRONG_GREEN'}
            onClick={() => setDisplayMonth(false)}
            className={classes.dayButton}
          />
          <ActionButton
            text="Month"
            type={displayMonth ? 'STRONG_GREEN' : 'LIGHT_GREEN'}
            onClick={() => setDisplayMonth(true)}
            className={classes.monthButton}
          />
        </Col>
        <Col xs={3} className="d-flex justify-content-end">
          <RoundButton
            icon={`fa fa-plus-circle ${classes.plusIcon}`}
            onClick={handleAddIntegration}
            type="GREEN"
          />
        </Col>
      </Row>

      <CardContainer>
        {displayMonth && (
          <Calendar
            className="w-100"
            selectedDay={selectedDay}
            selectedDays={
              eventList ? eventList.map(event => event.startTime) : []
            }
            onDayClick={day => setSelectedDay(new Date(day))}
            onMonthChange={day => setCurrentDate(new Date(day))}
          />
        )}
        <Row noGutters className="justify-content-between mb-4">
          <label className={classes.currentDateText}>
            {moment(selectedDay).format('dddd, MMM DD, YYYY')}
          </label>
          <div className="d-flex">
            <div className={classes.arrowContainer}>
              <i className={`fas fa-chevron-left`} onClick={handlePrev} />
            </div>
            <div className={`${classes.arrowContainer} ${classes.arrowRight}`}>
              <i className={`fas fa-chevron-right`} onClick={handleNext} />
            </div>
          </div>
        </Row>
        {eventsToDisplay.map((event, index) => (
          <EventRow
            key={event.id}
            event={event}
            isLast={index === eventsToDisplay.length - 1 ? true : false}
          />
        ))}
      </CardContainer>
    </>
  )
})

export default EventsCard
