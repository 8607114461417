import React, { useCallback, useState, useEffect } from 'react'
import compose from 'just-compose'
import { createUseStyles } from 'react-jss'
import { withRouter } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import { withErrorHandler } from '../../errors'

// Elements
import CardContainer from '../../elemets/CardContainer'
import CustomInput from '../../elemets/CustomInput'
import ActionButton from '../../elemets/ActionButton'
import CardTitle from '../../elemets/CardTitle'
import LoaderPage from '../../elemets/LoaderPage'

// ApiError
import { useApiSubmit } from '../../api/hooks'

// Theme
import { buttonStyles } from '../../theme/theme2'

// Utils
import { isMobile } from '../../utils/common'

const useStyles = createUseStyles({
  inputContainerStyle: {
    marginBottom: 10,
  },
  personalAvatar: {
    textAlign: 'center',
    marginTop: '-20px',
    marginBottom: 22,
  },
  close: {
    ...buttonStyles.close,
  },
  content: {
    margin: '20px 0 35px 0',
  },
})

const PersonalEdit = ({ handleError, location, history }) => {
  const classes = useStyles()
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    phoneNumber: '',
    linkedIn: '',
    location: '',
    homeTown: '',
    highSchool: '',
  })
  const [user, setUser] = useState()
  const {
    submitted,
    submit: updateProfile,
    err: updateErr,
    submitting,
  } = useApiSubmit('updateProfileV1')

  useEffect(() => {
    if (!isMobile()) {
      history.push('/profile')
    }
  }, [history])

  useEffect(() => {
    if (location) {
      setUser(location.state.user)
    }
  }, [location])

  useEffect(() => {
    if (user) {
      setFormState({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        title: user.title || '',
        email: user.email || '',
        phoneNumber: user.phoneNumber || '',
        linkedIn: user.linkedIn || '',
        location: user.location || '',
        homeTown: user.homeTown || '',
        highSchool: user.highSchool || '',
      })
    }
  }, [user])

  useEffect(() => {
    if (submitted && !updateErr && !submitting) {
      history.push('/profile')
    } else if (updateErr && !submitting) {
      handleError(updateErr, true, {
        message: 'There was a problem updating your profile.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, updateErr, submitting])

  const save = useCallback(() => {
    try {
      if (
        formState.firstName === '' ||
        formState.lastName === '' ||
        formState.email === ''
      ) {
        handleError(null, true, {
          message: 'First Name, Last Name, and Email are required.',
        })
        return
      }

      updateProfile({
        params: {
          profileId: user.id,
        },
        body: {
          firstName: formState.firstName,
          lastName: formState.lastName,
          title: formState.title,
          conversationFrequencyId: user.conversationFrequencyId,
          notificationFrequencyId: user.notificationFrequencyId,
          maxConversations: Number(user.maxConversations),
          email: formState.email,
          regionId: user.region,
          showTour: user.showTour,
          bio: user.bio,
          funFact: user.funFact,
          phoneNumber: formState.phoneNumber,
          linkedIn: formState.linkedIn,
          location: formState.location,
          homeTown: formState.homeTown,
          highSchool: formState.highSchool,
        },
      })
    } catch (err) {
      const { handleError } = this.props
      handleError(err, true, {
        message: 'There was a problem updating your profile.',
      })
    }
  }, [formState, updateProfile, user, handleError])

  const handleInput = useCallback((e) => {
    const value = e.target.value
    const name = e.target.name
    setFormState((f) => ({
      ...f,
      [name]: value,
    }))
  }, [])

  const close = useCallback(() => {
    history.push('/profile')
  }, [history])

  return (
    <CardContainer
      dataTestId={'my-profile__user-demographics-card--mobile'}
      header={
        <div className="d-flex justify-content-between">
          <CardTitle title="Account Profile" />
          <Button className={classes.close} onClick={close}>
            <i className="far fa-times-circle"></i>
          </Button>
        </div>
      }
      footer={
        <div className="d-flex justify-content-end">
          <ActionButton
            type="STRONG_GREEN"
            text="SAVE"
            onClick={save}
            width={150}
            loading={submitting}
          />
        </div>
      }
    >
      {submitted && <LoaderPage />}
      <div className={classes.content}>
        <CustomInput
          isRequired={true}
          containerStyle={classes.inputContainerStyle}
          name="firstName"
          value={formState.firstName}
          onChange={handleInput}
          title="First Name"
          dataTestId={'user-demographics-card__first-name'}
        />
        <CustomInput
          isRequired={true}
          containerStyle={classes.inputContainerStyle}
          name="lastName"
          value={formState.lastName}
          onChange={handleInput}
          title="Last Name"
          dataTestId={'user-demographics-card__last-name'}
        />
        <CustomInput
          containerStyle={classes.inputContainerStyle}
          name="title"
          value={formState.title}
          onChange={handleInput}
          title="Title"
          dataTestId={'user-demographics-card__title'}
        />
        <CustomInput
          isRequired={true}
          containerStyle={classes.inputContainerStyle}
          name="email"
          value={formState.email}
          onChange={handleInput}
          title="Email"
          dataTestId={'user-demographics-card__email'}
        />
        <CustomInput
          containerStyle={classes.inputContainerStyle}
          name="phoneNumber"
          value={formState.phoneNumber}
          onChange={handleInput}
          title="Phone"
          dataTestId={'user-demographics-card__phone-number'}
        />
        <CustomInput
          containerStyle={classes.inputContainerStyle}
          name="linkedIn"
          value={formState.linkedIn}
          onChange={handleInput}
          title="LinkedIn Profile"
          dataTestId={'user-demographics-card__linked-in'}
        />
        <CustomInput
          containerStyle={classes.inputContainerStyle}
          name="location"
          value={formState.location}
          onChange={handleInput}
          title="Location"
          dataTestId={'user-demographics-card__location'}
        />
        <CustomInput
          containerStyle={classes.inputContainerStyle}
          name="homeTown"
          value={formState.homeTown}
          onChange={handleInput}
          title="Home Town"
          dataTestId={'user-demographics-card__home-town'}
        />
        <CustomInput
          containerStyle={classes.inputContainerStyle}
          name="highSchool"
          value={formState.highSchool}
          onChange={handleInput}
          title="High School"
          dataTestId={'user-demographics-card__high-school'}
        />
      </div>
    </CardContainer>
  )
}

export default compose(withErrorHandler, withRouter)(PersonalEdit)
