import React, { ReactNode, createContext, useContext } from 'react'
import { Topic } from './Topic'

type ContextValue = {
  topics: Topic[]
  ready: boolean
}

export const Context = createContext<ContextValue>({
  topics: [],
  ready: false,
})

type TopicsProviderProps = {
  topics: Topic[]
  ready: boolean
  children: ReactNode
}

const TopicsProvider = ({
  topics,
  ready,
  children,
}: TopicsProviderProps): JSX.Element => (
  <Context.Provider value={{ topics, ready }}>{children}</Context.Provider>
)

export default TopicsProvider

export const useTopics = (): ContextValue => useContext(Context)
