import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Auth0Provider } from './auth0'
import { ConfigCat } from './configCat'
import LattusUI from './components/LattusUI'
import BaselineStyles from './components/BaselineStyles'
import ThemeProvider from './theme'
import ScrollToTop from './components/ScrollToTop'
import BrandHandler from './BrandHandler'
import config from './config/init'
import ConfigProvider from './config/Provider'
import ApolloProvider from './apollo/Provider'
import { NotificationsProvider } from './notifications/hooks/NotificationsProvider'
import { LoggerProvider } from '@/logger/LogContext'
import { WafTokenProvider } from '@/components/Context/WafTokenProvider'

function App() {
  const onRedirectCallback = (appState) => {
    if (!window.routerHistory)
      window.location.href =
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
    window.routerHistory.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    )
  }

  return (
    <ThemeProvider>
      <BaselineStyles />
      <Helmet defaultTitle="Lattitude" titleTemplate="%s | Lattitude" />
      <NotificationsProvider>
        <ConfigProvider config={config}>
          <LoggerProvider>
            <BrowserRouter>
              <WafTokenProvider>
                <BrandHandler>
                  <Auth0Provider onRedirectCallback={onRedirectCallback}>
                    <ConfigCat>
                      <ApolloProvider>
                        <ScrollToTop />
                        <LattusUI />
                      </ApolloProvider>
                    </ConfigCat>
                  </Auth0Provider>
                </BrandHandler>
              </WafTokenProvider>
            </BrowserRouter>
          </LoggerProvider>
        </ConfigProvider>
      </NotificationsProvider>
    </ThemeProvider>
  )
}

export default App
