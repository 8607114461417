import React from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import compose from 'just-compose'
import SaveCancel from './SaveCancel'
import Cards from 'react-credit-cards'
import { withErrorHandler } from '../errors'
import { withNotifications } from '../notifications'
import 'react-credit-cards/es/styles-compiled.css'

class Payments extends React.Component {
  constructor(props) {
    super(props)
    this.save = this.save.bind(this)
    this.cancel = this.cancel.bind(this)
    this.handleInputFocus = this.handleInputFocus.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.state = {
      cvc: '',
      expiry: '',
      focus: '',
      name: '',
      number: '',
    }
  }

  save() {
    const { pushNotification, reload } = this.props
    pushNotification({
      level: 'success',
      timeout: 10,
      subject: 'Background information saved.',
    })
    reload()
  }

  cancel() {
    const { reload } = this.props
    reload()
  }

  handleInputFocus = e => {
    this.setState({ focus: e.target.name })
  }

  handleInputChange = e => {
    const { name, value } = e.target

    this.setState({ [name]: value })
  }

  render() {
    const rowStyle = {
      paddingTop: 5,
      paddingBottom: 5,
    }

    return (
      <Container>
        <h1>Payments</h1>
        <Cards
          cvc={this.state.cvc}
          expiry={this.state.expiry}
          focus={this.state.focus}
          name={this.state.name}
          number={this.state.number}
        />

        <Form
          style={{
            maxWidth: 500,
            margin: 'auto',
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Row style={rowStyle}>
            <Col>
              <Form.Control
                type="tel"
                name="number"
                placeholder="Card Number"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
            </Col>
          </Row>
          <Row style={rowStyle}>
            <Col>
              <Form.Control
                type="tel"
                name="name"
                placeholder="Name"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
            </Col>
          </Row>
          <Row style={rowStyle}>
            <Col>
              <Form.Control
                type="tel"
                name="expiry"
                placeholder="Valid Thru"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
            </Col>
            <Col>
              <Form.Control
                type="tel"
                name="cvc"
                placeholder="CVC"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
            </Col>
          </Row>
        </Form>
        <SaveCancel onSaveClick={this.save} onCancelClick={this.cancel} />
      </Container>
    )
  }
}

export default compose(withNotifications, withErrorHandler)(Payments)
