import React, { useState } from 'react'

import { createUseStyles } from 'react-jss'
import { Button, Col, Row } from 'react-bootstrap'
import { withRouter } from 'react-router'
import compose from 'just-compose'
import colors from '../../../theme/theme2'
import ActionButton from '../../../elemets/ActionButton'
import moment from 'moment'
import SkillLevelIcon from '../../Icons/SkillLevel'
import { withErrorHandler } from '../../../errors'
import ReScheduleModal from '../../Modals/Conversation/ReScheduleModal'
import GravatarContainer from '../../Users/GravatarContainer'

const useStyles = createUseStyles({
  title: {
    padding: 0,
  },
  container: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
  },
  mobile: {
    paddingTop: 0,
    minHeight: 'unset',
  },
  icon: {
    '& i': {
      fontSize: 20,
      color: colors.primary,
    },
    position: 'absolute',
    left: 10,
  },
  iconText: {
    paddingLeft: 10,
  },
  actionButton: {
    width: '100%!important',
  },
  arrows: {
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
      color: [colors.primary700, '!important'],
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },
  avatar: {
    fontWeight: 'bold!important',
    position: 'relative',
    top: -10,
  },
  name: {
    fontWeight: 'bold!important',
    fontSize: 18,
  },
  time: {
    fontWeight: 'bold!important',
    fontSize: 12,
    color: colors.primary,
  },
  skillIcon: {
    paddingRight: 5,
    display: 'inline',
  },
  rating: {
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 500,
    marginRight: 10,
  },
  experience: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: '#707575',
    paddingLeft: 19,
  },
  experienceMobile: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: '#707575',
    paddingLeft: 20,
  },
  years: {
    paddingLeft: 35,
  },
  expandedInfo: {
    paddingLeft: 47,
  },
  skills: {
    fontWeight: 'bold!important',
    fontSize: 16,
  },
})

const UpcomingConvoCard = ({
  conversation = {},
  user = {},
  reloadUpcoming,
  history,
}) => {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)
  const [showReschedule, setShowReschedule] = useState(false)

  return (
    <>
      {showReschedule && (
        <ReScheduleModal
          show={showReschedule}
          conversation={conversation}
          onClose={() => setShowReschedule(false)}
          reloadUpcoming={reloadUpcoming}
        />
      )}
      <Row>
        <Col xs={11} className={'p-2 pl-3'}>
          <GravatarContainer
            profile={user}
            size={40}
            customRightSide={
              <p className={`m-0 ${classes.time}`}>
                {conversation.scheduled
                  ? moment
                      .utc(conversation.scheduled)
                      .local()
                      .format('dddd, MMM DD, YYYY hh:mm')
                  : ''}
              </p>
            }
          />
        </Col>
        <Col xs={1} className={`align-self-center py-2 px-0`}>
          <Button
            className={`${classes.arrows} p-0`}
            onClick={() => {
              setExpand(!expand)
            }}
          >
            <span>
              <i
                className={`fas ${
                  expand ? 'fa-chevron-down' : 'fa-chevron-right'
                }`}
              />
            </span>
          </Button>
        </Col>
      </Row>
      {moment
        .utc(conversation.scheduled)
        .isAfter(moment().subtract(30, 'minutes')) &&
        moment
          .utc(conversation.scheduled)
          .isBefore(moment().add(5, 'minutes')) &&
        conversation.statusName === 'Scheduled' && (
          <Row className={'mb-3'}>
            <Col xs={12} className={'d-flex justify-content-end'}>
              <ActionButton
                height="37px"
                text="JOIN CONVO"
                onClick={() => {
                  history.push(`/conversation/${conversation.id}`, {
                    params: { conversation },
                  })
                }}
                type="STRONG_GREEN"
              />
            </Col>
          </Row>
        )}
      {expand && (
        <>
          <Row noGutters className="align-items-center pb-2 ">
            <div className={classes.expandedInfo}>
              <p className={`mb-0 ${classes.skills}`}>
                {conversation.skillGroupName}
              </p>
              {user.hasOwnProperty('rating') && (
                <p className={`mb-0`}>
                  <span className={classes.skillIcon}>
                    <SkillLevelIcon rating={user.rating} />
                  </span>
                  <span className={classes.rating}>
                    {user.rating} - {conversation.skillName}
                  </span>
                  <br />
                  <span className={classes.experience}>
                    {user.years} Years Experience
                  </span>
                </p>
              )}
            </div>
          </Row>
          <div className={'d-flex justify-content-between mb-2 pl-2 pr-2'}>
            {moment.utc(conversation.scheduled).isAfter(moment()) &&
              conversation.statusName === 'Scheduled' && (
                <ActionButton
                  height="37px"
                  text="RESCHEDULE"
                  onClick={() => setShowReschedule(true)}
                  type="LIGHT_RED"
                />
              )}
            <ActionButton
              height="37px"
              text="DETAILS"
              onClick={() => {
                history.push(`/conversation/${conversation.id}`, {
                  params: { conversation },
                })
              }}
              type="LIGHT_GREEN"
            />
          </div>
        </>
      )}
    </>
  )
}

export default compose(withRouter, withErrorHandler)(UpcomingConvoCard)
