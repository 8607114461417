import { gql } from '@apollo/client'

// Fragments

export const topicSubtopicsBasicFragment = gql`
  fragment topicSubtopics on skillgroups {
    id: sg_id
    skillGroupName: sg_name
    skills {
      skillId: sk_id
      skillName: sk_name
    }
  }
`
