import React, { useCallback, useMemo } from 'react'
import { useGroups } from '../../../groups/GroupsContext'
import { type IGroup as GroupData } from '../../../types/Groups.typings'
import GroupSelectorControl, { type Group } from './GroupSelectorControl'

const OtherGroupsSelector = (): JSX.Element => {
  const { groups, active, setActiveGroup } = useGroups()

  const selectableGroups = useMemo(() => {
    if (!active) {
      return groups
    }
    return groups.filter(({ id }: GroupData) => id !== active.id)
  }, [active, groups])

  const handleSelect = useCallback(
    ({ id }: Group) => {
      setActiveGroup(id)
    },
    [setActiveGroup],
  )

  return (
    <GroupSelectorControl groups={selectableGroups} onSelect={handleSelect} />
  )
}

export default OtherGroupsSelector
