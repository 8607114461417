import React from 'react'
import { Modal as BootstrapModal, Button } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import colors from '../../../theme/theme2'
import { isMobile } from '../../../utils/common'
import CardTitle from '../../../elemets/CardTitle'

const useStyles = createUseStyles({
  modal: {
    '& .modal-dialog': {
      maxWidth: 730,
    },
    '& .modal-content': {
      border: 'none',
    },
  },
  container: {
    padding: 15,
  },
  close: {
    alignItems: 'center',
    display: 'flex',
    padding: '3px',
    backgroundColor: 'transparent',
    border: 'none',
    color: colors.dark,
    '&:focus': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      color: colors.dark,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&:active': {
      color: `${colors.dark} !important`,
      backgroundColor: 'transparent !important',
      border: 'none !important',
    },
  },
  header: {
    marginBottom: 17,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  body: {
    backgroundColor: colors.whiteBackground,
    padding: '25px 30px',
  },
  footer: {
    marginTop: 15,
  },
})

const ActionModal = ({
  children,
  show,
  close,
  title,
  footer = null,
  closeIcon = true,
  closeOutside = true,
  bodyClass = '',
  ...rest
}) => {
  const classes = useStyles()

  return (
    <BootstrapModal
      show={show}
      centered={!isMobile()}
      className={classes.modal}
      onHide={closeOutside ? close : () => {}}
      {...rest}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <CardTitle title={title} />
          {closeIcon && (
            <Button className={classes.close} onClick={close}>
              <i className="far fa-times-circle" />
            </Button>
          )}
        </div>
        <div className={`${classes.body} ${bodyClass}`}>{children}</div>
        {footer && <div className={classes.footer}>{footer}</div>}
      </div>
    </BootstrapModal>
  )
}

export default ActionModal
