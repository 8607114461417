import React from 'react'

type HOC<P = {}> = (
  BaseComponent: React.ComponentType<P>,
) => React.ComponentType<P>

export const compose = <P extends object = {}>(...hocs: HOC<any>[]): HOC<P> => {
  return (BaseComponent: React.ComponentType<P>): React.ComponentType<P> => {
    return hocs.reduce((Acc, hoc) => hoc(Acc), BaseComponent)
  }
}
