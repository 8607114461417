import React from 'react'
import { createUseStyles } from 'react-jss'
import DatePicker from 'react-datepicker'
import CurrencyFormat from 'react-currency-format'

import colors from '../theme/theme2'

const useStyles = createUseStyles({
  input: {
    width: '100%',
    padding: '0 16px',
    border: `1px solid ${colors.grey1}`,
    borderRadius: 4,
    color: colors.dark,
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: 16,
    '&:disabled': {
      color: colors.dark,
      cursor: 'default',
      border: '1px solid transparent',
      backgroundColor: 'transparent',
    },
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: colors.dark,
      opacity: 1,
    },
  },
  title: {
    fontSize: 14,
    fontFamily: 'Lato',
    color: colors.grey2,
    paddingLeft: 16,
    marginBottom: 0,
    width: '100%',
  },
  datepicker: {
    '& .react-datepicker': {
      fontFamily: 'lato',
    },
    '& .react-datepicker__navigation': {
      border: 0,
      width: 14,
      height: 20,
      top: 7,
      color: colors.primary,
      fontSize: 25,
      fontWeight: 'bolder',
      textIndent: 'unset',
      lineHeight: '0.8',
      '&:focus': {
        outlineColor: colors.primary,
      },
    },
    '& .react-datepicker__navigation--previous': {
      left: 20,
      '&::before': {
        content: '"<"',
      },
    },
    '& .react-datepicker__navigation--next': {
      right: 20,
      '&::before': {
        content: '">"',
      },
    },
    '& .react-datepicker__current-month': {
      color: '#343434',
      fontFamily: 'lato',
      fontSize: 16,
    },
    '& .react-datepicker__day--keyboard-selected': {
      borderRadius: 15,
      backgroundColor: colors.primary,
      '&:hover': {
        backgroundColor: '#3a8769',
      },
    },
  },
})

const CustomDate = ({
  value,
  onChange,
  placeholder,
  title,
  name,
  containerStyle,
  inputStyle = '',
  mask = true,
  valueToDisplay = '',
}) => {
  const classes = useStyles()

  return (
    <div className={`${containerStyle} ${classes.datepicker}`}>
      {title && (
        <label className={`${classes.title} mb-0 w-100`} htmlFor={name}>
          {title}
        </label>
      )}
      <DatePicker
        className={`${classes.input} ${inputStyle}`}
        value={valueToDisplay}
        selected={value}
        onChange={onChange}
        placeholderText={placeholder}
        dateFormat="MM-dd-yyyy"
        width={300}
        customInput={
          mask ? (
            <CurrencyFormat
              value={value}
              onValueChange={val => onChange(val.value)}
              format="##-##-####"
              placeholder="MM-DD-YYYY"
              mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
            />
          ) : undefined
        }
      />
    </div>
  )
}

export default CustomDate
