import React from 'react'
import { DayPicker } from 'react-day-picker'
import moment from 'moment'
import Helmet from 'react-helmet'
import colors from '../../theme/theme2'
import 'react-day-picker/dist/style.css'

const Calendar = React.memo(
  ({
    selectedDay,
    selectedDays = [],
    onDayClick,
    className = '',
    onMonthChange,
  }) => {
    const renderDay = (day) => {
      const date = day.getDate().toString()
      const fullDate = moment(day).format('MM/DD/YYYY')

      const contains = selectedDays
        .map((x) => moment(x).format('MM/DD/YYYY'))
        .includes(fullDate)

      const style = {
        cell: {
          height: 40,
          width: 40,
          position: 'relative',
          right: 10,
          top: 5,
        },
        date: {
          height: 40,
          left: 10,
          position: 'absolute',
          width: '100%',
        },
        dot: {
          height: 40,
          position: 'relative',
          top: 5,
          left: 10,
          fontSize: 20,
          textAlign: 'center',
          width: '100%',
          color: colors.primary,
        },
      }

      return (
        <div style={style.cell}>
          <div style={style.date}>{date}</div>
          {contains && <div style={style.dot}>.</div>}
        </div>
      )
    }

    const modifiers = {
      disabled: [
        {
          // after: moment()
          //   .add(3, 'week')
          //   .toDate(),
          before: moment().toDate(),
        },
      ],
      selected: selectedDay,
    }

    const modifierStyles = {
      selected: {
        color: 'white',
        backgroundColor: colors.primary,
      },
    }

    return (
      <>
        <Helmet>
          <style>{`
               .DayPicker-Day {
                 padding: 0px;
                 left: 10;
               }
               .DayPicker-Caption > div {
                 font-family: 'Lato';
                 font-size: 16px;
                 font-weight: bold;
               }
               {/* .DayPicker-Month{
                 margin: 0;
                 width: 100%;
               } */}
               `}</style>
        </Helmet>
        <div className="w-100">
          <DayPicker
            onDayClick={onDayClick}
            modifiers={modifiers}
            modifiersStyles={modifierStyles}
            renderDay={renderDay}
            fixedWeeks
            className={className}
            onMonthChange={(e) =>
              onMonthChange ? onMonthChange(e) : undefined
            }
            selectedDay={selectedDay}
          />
        </div>
      </>
    )
  },
)

export default Calendar
