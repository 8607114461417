// src/components/Profile.js
import React from 'react'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import jwt from 'jwt-decode'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import compose from 'just-compose'
import qs from 'query-string'
import { Dropdown } from '../Forms'
import SaveCancel from '../SaveCancel'
import lattusApiClient from '../../lattusApi'
import { withErrorHandler } from '../../errors'
import { withNotifications } from '../../notifications'
import { buttonStyles } from '../../theme/theme2'

const {
  getCurrentProfile,
  listMatchAvailability,
  scheduleConversation,
  requestAvailabilityV1,
} = lattusApiClient

class MatchAvailability extends React.Component {
  constructor(props) {
    super(props)
    this.save = this.save.bind(this)
    this.getSkills = this.getSkills.bind(this)
    this.requestAvailability = this.requestAvailability.bind(this)
    this.showError = this.showError.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.state = {
      availabilities: [],
      topics: [],
      skillId: null,
      startTime: '',
      token: '',
    }
  }

  componentDidMount() {
    const { location } = this.props
    const search = qs.parse(location.search)
    const token = search.token

    const decodedToken = jwt(token)
    const mat = decodedToken.mat
    const skills = this.getSkills(mat)

    listMatchAvailability({ query: { token: token } })
      .catch(error => {
        this.showError(error, "Unable to get your match's availability.")
        return []
      })
      .then(result => {
        const dates = result.map(x => {
          x.startTime = moment(x.startTime).format('ddd MMMM DD YYYY hh:mm a')
          return x
        })
        this.setState({
          availabilities: dates,
          token: token,
        })
        if (dates.length > 0) {
          this.setState({
            startTime: dates[0].startTime,
          })
        }
      })

    getCurrentProfile()
      .then(result => {
        this.setState({
          topics: result.topics.filter(x => skills.includes(x.skillId)),
          skillId: skills[0],
        })
      })
      .catch()
  }

  getSkills(object) {
    const skills = []

    object.map(x => {
      if (x[0] === 'skillId') {
        return skills.push(x[1])
      }
      return null
    })

    return skills
  }

  requestAvailability(e) {
    e.preventDefault()
    const { token, skillId } = this.state
    requestAvailabilityV1({
      query: { token: token },
      body: { skillId: Number(skillId) },
    })
      .then(() => {
        const { pushNotification } = this.props
        pushNotification({
          level: 'success',
          subject: 'Request sent.',
          timeout: 10,
        })
      })
      .catch(error => {
        this.showError(error, 'Unable to request additional availability.')
      })
  }

  save() {
    if (this.state.startTime === '') {
      alert(
        'Please select a time or request additional time from the other person.',
      )
      return
    }

    const body = {
      skillId: Number(this.state.skillId),
      startTime: moment(this.state.startTime).utc(),
    }
    const topic = this.state.topics.find(
      x => Number(x.skillId) === Number(this.state.skillId),
    )

    scheduleConversation({ query: { token: this.state.token }, body: body })
      .then(() => {
        const { history, pushNotification } = this.props
        pushNotification({
          level: 'success',
          subject: 'Conversation scheduled.',
          message: `Your conversation on ${topic.skillName} has been scheduled for ${this.state.startTime}.`,
          timeout: 10,
        })
        history.push('/conversations')
      })
      .catch(error => {
        this.showError(
          error,
          'There was a problem scheduling this conversation.',
        )
      })
  }

  showError(error, message) {
    const { handleError } = this.props
    handleError(error, true, {
      message: message,
    })
  }

  handleInput(e) {
    const value = e.target.value
    const name = e.target.name
    this.setState({
      [name]: value,
    })
  }

  render() {
    return (
      <Container>
        <h1>Select Availability</h1>

        <Row>
          <Col lg={9}>
            <p>
              If none of the time slots below fit your schedule, please “Request
              Additional Time” and this will notify the mentor to update their
              availability.
            </p>
          </Col>
          <Col style={{ textAlign: 'center' }}>
            <Button
              onClick={this.requestAvailability}
              style={buttonStyles.primary}
              pullRight
            >
              Request Additional Time
            </Button>
          </Col>
        </Row>

        <div style={{ textAlign: 'center' }} />
        <Form style={{ paddingTop: 10 }}>
          <Dropdown
            name="skillId"
            label="Topic"
            options={this.state.topics}
            onChange={this.handleInput}
            valueField="skillId"
            textField="skillName"
            value={this.state.skillId}
            required
          />

          <Dropdown
            name="startTime"
            label="Time"
            options={this.state.availabilities}
            valueField="startTime"
            textField="startTime"
            onChange={this.handleInput}
            value={this.state.startTime}
            required
          />
        </Form>

        <SaveCancel onSaveClick={this.save} />
      </Container>
    )
  }
}

export default compose(
  withRouter,
  withErrorHandler,
  withNotifications,
)(MatchAvailability)
