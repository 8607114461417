import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import compose from 'just-compose'

import { withErrorHandler } from '../../errors'

// Elements
import CardContainer from '../../elemets/CardContainer'
import CardTitle from '../../elemets/CardTitle'
import Divider from '../../elemets/Divider'

// Theme
import colors, { headings, buttonStyles } from '../../theme/theme2'

// API
import { useApiFetch, useApiSubmit } from '../../api/index'

// auth0
import ConfirmationModal from '../Modals/ConfirmationModal'
import ActionButton from '../../elemets/ActionButton'
import IntegrationModal from '../Modals/IntegrationModal'
import { useBaseUri } from '../../config/useConfig'

const useStyles = createUseStyles({
  text: {
    ...headings.text,
    paddingLeft: 16,
    marginBottom: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  deleteRoundButton: {
    ...buttonStyles.roundButton,
    color: colors.red,
    '&:hover': {
      color: colors.red700,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&:active': {
      color: colors.red100,
      backgroundColor: 'transparent !important',
    },
  },
  addRoundButton: {
    ...buttonStyles.roundButton,
    color: colors.primary,
    '&:hover': {
      color: colors.primary700,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&:active': {
      color: colors.primary100,
      backgroundColor: 'transparent !important',
    },
  },
  integrationIcon: {
    color: '#9b9b9b',
  },
  integrationModal: {
    '& .modal-dialog': {
      justifyContent: 'center',
    },
    '& .modal-content': {
      '&>div': {
        '&>div': {
          backgroundColor: 'unset',
          padding: 0,
        },
      },
      width: '70%',
    },
  },
  provider: {
    fontFamily: 'lato !important',
    fontSize: '14px !important',
    width: '100% !important',
    justifyContent: 'end!important',
    '&:hover': {
      color: `${colors.primary}!important`,
      '& i': {
        color: `#039ce5 !important`,
      },
    },
  },
  google: {
    color: colors.grey2,
    marginRight: 10,
  },
})

export const providers = [
  {
    name: 'Calendar',
    connection: 'google-oauth2',
    provider: 'google',
    icon: 'fab fa-google',
  },
  {
    name: 'Calendar',
    connection: 'windowslive',
    provider: 'windows',
    icon: 'fab fa-windows',
  },
]

const IntegrationsCard = ({ handleError }) => {
  const classes = useStyles()
  const [displayModal, setDisplayModal] = useState(false)
  //  const [displayCalendarModal, setDisplayCalendarModal] = useState(false)
  const [displayIntegrationModal, setDisplayIntegrationModal] = useState(false)
  const [confirmationModalContent, setConfirmationModalContent] = useState({
    title: '',
    content: '',
    footer: null,
  })
  const lattusAppBaseUri = useBaseUri('lattusApp')

  const { data: identities, reload: reloadIdentities } =
    useApiFetch('getIntegrations')

  const { submitted, submit, err, submitting } = useApiSubmit('addIntegration')

  const {
    submitted: submittedUnlinkAccount,
    submit: unlinkAccount,
    err: unlinkAccountErr,
    submitting: submittingUnlinkAccount,
  } = useApiSubmit('deleteIntegration')

  const {
    submitted: submittedMicrosoft,
    submit: submitMicrosoft,
    err: errMicrosoft,
    submitting: submittingMicrosoft,
  } = useApiSubmit('addMicrosoftIntegration')

  // Microsoft integration listening
  useEffect(() => {
    if (submittedMicrosoft && !errMicrosoft && !submittingMicrosoft) {
      reloadIdentities()
    } else if (errMicrosoft && !submittingMicrosoft) {
      handleError(errMicrosoft, true, {
        message: `There was a problem linking your account: ${errMicrosoft}.`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedMicrosoft, errMicrosoft, submittingMicrosoft])

  // Listen to Link
  useEffect(() => {
    if (submitted && !err && !submitting) {
      reloadIdentities()
    } else if (err && !submitting) {
      handleError(err, true, {
        message: `There was a problem linking your account: ${err}.`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, err, submitting])

  // Listen to unlinking
  useEffect(() => {
    if (
      submittedUnlinkAccount &&
      !unlinkAccountErr &&
      !submittingUnlinkAccount
    ) {
      setDisplayModal(false)
      reloadIdentities()
    } else if (unlinkAccountErr && !submittingUnlinkAccount) {
      handleError(unlinkAccountErr, true, {
        message: `There was a problem unlinking your account: ${unlinkAccountErr}.`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedUnlinkAccount, unlinkAccountErr, submittingUnlinkAccount])

  const linkGoogleAccount = async (code) => {
    const body = {
      code,
    }

    submit({ body })
    setDisplayIntegrationModal(false)
  }

  const linkMicrosoftAccount = async (token, id, email) => {
    const body = {
      id,
      email,
      token,
    }

    submitMicrosoft({ body })
    setDisplayIntegrationModal(false)
  }

  const integrations = useMemo(() => {
    const result = []
    if (identities) {
      providers.forEach((p) => {
        const integratedIdentities = identities.filter(
          (i) => i.active && i.provider === p.connection,
        )
        integratedIdentities.forEach((i) => {
          result.push({ ...p, ...i })
        })
      })
    }
    return result
  }, [identities])

  const deleteIntegration = useCallback(
    (integration) => {
      setConfirmationModalContent({
        title: `Disconnect from ${integration.name}`,
        content:
          'You are about to disconnect a calendar from Lattitude. Your calendar will no longer be synced and external events will be removed from your Lattitude account.',
        footer: (
          <div className="d-flex justify-content-end">
            <ActionButton
              type="STRONG_RED"
              text="DISCONNECT"
              onClick={() =>
                unlinkAccount({
                  params: { id: integration.id },
                })
              }
              loading={submittingUnlinkAccount}
            />
          </div>
        ),
      })
      setDisplayModal(true)
    },
    [unlinkAccount, submittingUnlinkAccount],
  )

  const IntegrationRow = useCallback(
    ({ integration, divider }) => (
      <>
        <Row>
          <Col xs={10} className="pr-0">
            <div className="d-flex align-items-center pl-3">
              <i
                className={`${classes.integrationIcon} ${integration.icon}`}
              ></i>
              {integration.active && (
                <label className={classes.text}>{integration.email}</label>
              )}
            </div>
          </Col>
          <Col xs={2} className="d-flex justify-content-end">
            <Button
              className={classes.deleteRoundButton}
              onClick={() => deleteIntegration(integration)}
            >
              <i className="las la-minus-circle"></i>
            </Button>
          </Col>
        </Row>
        {divider && <Divider />}
      </>
    ),
    [classes, deleteIntegration],
  )

  // const handleAddCalendar = useCallback(()=>{})

  return (
    <>
      <ConfirmationModal
        show={displayModal}
        onClose={() => setDisplayModal(false)}
        footer={confirmationModalContent.footer}
        title={confirmationModalContent.title}
        content={confirmationModalContent.content}
        closeIcon={true}
      />

      {/*<ConfirmationModal*/}
      {/*  show={displayCalendarModal}*/}
      {/*  onClose={() => setDisplayCalendarModal(false)}*/}
      {/*  footer={(<ActionButton onClick={handleAddCalendar} text={'Add Calendar'} type={'STRONG_GREEN'}/>)}*/}
      {/*  title={'Add Calendar'}*/}
      {/*  content={<GoogleCalendar calendar={calendar}  setCalendar={setCalendar}/>}*/}
      {/*  closeIcon={true}*/}
      {/*/>*/}

      <IntegrationModal
        show={displayIntegrationModal}
        onClose={() => setDisplayIntegrationModal(false)}
        linkGoogleAccount={linkGoogleAccount}
        linkMicrosoftAccount={linkMicrosoftAccount}
        microsoftRedirectURI={`${lattusAppBaseUri}/profile`}
      />

      <CardContainer
        header={
          <div className="d-flex justify-content-between">
            <CardTitle title="Integrations" />
            <Button
              className={classes.addRoundButton}
              onClick={() => {
                setDisplayIntegrationModal(true)
              }}
            >
              <i className="fa fa-plus-circle" />
            </Button>
          </div>
        }
      >
        {integrations.map((integration, index) => (
          <IntegrationRow
            integration={integration}
            divider={index < integrations.length - 1}
            key={index}
          />
        ))}
      </CardContainer>
    </>
  )
}

export default compose(withErrorHandler)(IntegrationsCard)
