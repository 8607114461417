import React from 'react'
import CardContainer from '../../elemets/CardContainer'
import { createUseStyles } from 'react-jss'
import CardTitle from '../../elemets/CardTitle'
import colors, { headings } from '../../theme/theme2'
import { isMobile } from '../../utils/common'
import Icon from '../Icons'
import { Row, Col } from 'react-bootstrap'
import SimpleSlider from './Slider'

const useStyles = createUseStyles({
  container: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    minHeight: 305,
    maxHeight: 305,
    color: colors.dark,
  },
  containerMobile: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    minHeight: 270,
    maxHeight: 270,
    color: colors.dark,
  },
  title: {
    ...headings,
    fontSize: 20,
    paddingLeft: 16,
    margin: 0,
  },
  icon: {
    margin: -16,
    '& i': {
      marginTop: 7,
      '&:before': {
        color: colors.primary,
      },
    },
  },

  left: {
    left: 0,
  },
  right: {
    right: 0,
  },
  slider: {
    paddingLeft: 50,
    paddingRight: 50,
  },
})

const GroupSlider = ({ groups = [] }) => {
  const classes = useStyles()

  return (
    <CardContainer
      className={isMobile() ? classes.containerMobile : classes.container}
      headerDivider={true}
      header={
        <CardTitle
          title={
            <div
              className={`${classes.icon} ${
                isMobile() ? 'd-flex justify-content-center' : ''
              }`}
            >
              <Icon icon="fas fa-users" />
              <label className={`${classes.title}`}>My Groups</label>
            </div>
          }
        />
      }
    >
      <Row>
        <Col xs={12} className={classes.slider}>
          <SimpleSlider groups={groups} />
        </Col>
      </Row>
    </CardContainer>
  )
}

export default GroupSlider
