import React from 'react'
import { PartialConversation } from '../../builder/state'
import { RelationshipKey } from './data/Relationship'
import { useConversationDetailsBuilder } from '../../builder/ConversationBuilder'
import AvailableRelationships from './data/AvailableRelationships'
import PrimaryRelationshipField from './PrimaryRelationshipField'

const selectPrimaryRelationship = (
  value: PartialConversation,
): RelationshipKey | undefined => value.primaryRelationship

const SetPrimaryRelationship = (): JSX.Element => {
  const { value, setPrimaryRelationship } = useConversationDetailsBuilder()
  const primaryRelationship = selectPrimaryRelationship(value)

  return (
    <AvailableRelationships>
      <PrimaryRelationshipField
        value={primaryRelationship}
        onChange={setPrimaryRelationship}
      />
    </AvailableRelationships>
  )
}

export default SetPrimaryRelationship
