import React, { useCallback, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import CreateConversationModal from '../details/CreateConversationModal'

type ManuallyAddConversationProps = {
  participantId: number
  skillId: number
  groupId: number | undefined
  disabled?: boolean
  className?: string
}

const ManuallyAddConversation = ({
  participantId,
  skillId,
  groupId,
  disabled = false,
  className,
}: ManuallyAddConversationProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  return (
    <>
      <Dropdown.Item
        onClick={handleOpen}
        disabled={disabled}
        className={className}
      >
        Manually log conversation
      </Dropdown.Item>
      <CreateConversationModal
        data={{
          participant: participantId,
          topics: skillId ? [skillId] : [],
        }}
        groupId={groupId}
        open={open}
        onClose={handleClose}
      />
    </>
  )
}

export default ManuallyAddConversation
