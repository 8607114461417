import { ISurveyElement, IElement, IPage } from 'survey-core'
import {
  AssessmentQuestionGroup,
  IAssessmentFlags,
} from '../../types/Assessments.typings'

// TODO: SurveyJS types
/* eslint-disable @typescript-eslint/no-explicit-any */

export const defaultTopLevelProps = {
  // following will allow auto-collapse - see https://surveyjs.io/form-library/examples/automatically-move-to-next-page-if-answer-selected/reactjs#content-code
  showProgressBar: 'top',
  // goNextPageAutomatic: true,
  showNavigationButtons: true,
  logoPosition: 'right',
  showQuestionNumbers: 'off',
  // widthMode: 'static',
}

export function createRadioQuestion(
  name: string,
  questionGroup: AssessmentQuestionGroup,
  questionId: string,
): IPage {
  const [pageDescription, instructions, questionGroupDescription, choices] =
    questionGroup
  const elements: any[] = [
    {
      type: 'radiogroup',
      name: questionId,
      description: questionGroupDescription,
      title: instructions,
      isRequired: true,
      choices: choices.map((q) => ({ value: q[0], text: q[1] })),
      colCount: 1,
      separateSpecialChoices: false,
      showClearButton: false,
    },
  ]
  return wrapQuestionAsPage(questionId, elements, pageDescription)
}

export function createLikertMatrix(
  name: string,
  questionGroup: AssessmentQuestionGroup,
  likertNum: string,
  visibleIf: boolean,
): IPage {
  const [pageDescription, instructions, questionGroupDescription, questions] =
    questionGroup
  const elements: any = [
    {
      type: 'matrix',
      name,
      description: questionGroupDescription,
      title: instructions,
      isRequired: true,
      isAllRowRequired: true,
      columns: getLikertCols(likertNum),
      rows: convertToMatrixRows(questions),
      alternateRows: true,
      visibleIf,
    },
  ]
  return wrapQuestionAsPage(name, elements, pageDescription)
}

export function createYesNo(
  title: string,
  name: string,
  isRequired: boolean,
  visibleIf: boolean,
): IPage {
  const elements: any[] = [
    {
      type: 'radiogroup',
      title,
      name,
      isRequired,
      choices: ['no', 'yes'],
      colCount: 1,
      separateSpecialChoices: false,
      showClearButton: false,
      visibleIf,
    },
  ]
  return wrapQuestionAsPage(name, elements)
}

export function createBoolean(
  title: string,
  name: string,
  required = true,
  visibleIf: boolean,
): IPage {
  const elements: any = [
    {
      type: 'boolean',
      name,
      title,
      required,
      visibleIf,
    },
  ]
  return wrapQuestionAsPage(name, elements)
}

export function wrapQuestionAsPage(
  name: string,
  elementsArr: Partial<ISurveyElement[]>,
  pageDescription = '',
): any {
  return {
    name,
    description: pageDescription,
    showQuestionNumbers: 'off',
    showTitle: false,
    elements: elementsArr as IElement[],
  }
}

const likert_6_NA_FM = [
  { value: 1, text: '1<br>Not at all' },
  { value: 2, text: '2<br>One time' },
  { value: 3, text: '3<br>Two times' },
  { value: 4, text: '4<br>Four times' },
  { value: 5, text: '5<br>Five or more times' },
]
const likert5_5p_NT_CT = [
  { value: 1, text: '1<br>Not at all true of me' },
  { value: 2, text: '2<br>Slightly true of me' },
  { value: 3, text: '3<br>Moderately true of me' },
  { value: 4, text: '4<br>Mostly true of me' },
  { value: 5, text: '5<br>Completely true of me' },
]
const likert_7p_SD_SA = [
  { value: 1, text: '1<br>Strongly Disagree' },
  { value: 2, text: '2<br></br>Disagree' },
  { value: 3, text: '3<br>Slightly Disagree' },
  { value: 4, text: '4<br>Neither Agree nor Disagree' },
  { value: 5, text: '5<br>Slightly Agree' },
  { value: 6, text: '6<br></br>Agree' },
  { value: 7, text: '7<br>Strongly Agree' },
]

// Special Likert 5pt scales for BR (matching assessment)
const likert5_5p_BR_special_mentors1 = [
  { value: 1, text: `1<br>Do not want to mentor this` },
  { value: 2, text: `2<br></br>willing, but prefer not to mentor this` },
  { value: 3, text: `3<br></br>neutral, ok with this` },
  { value: 4, text: `4<br>would like to mentor on this` },
  { value: 5, text: `5<br>would very strongly like to mentor on this` },
]
const likert5_5p_BR_special_mentees1 = [
  { value: 1, text: `1<br>Do not want to be mentored on this` },
  {
    value: 2,
    text: `2<br></br>willing, but prefer not to be mentored on this`,
  },
  { value: 3, text: `3<br></br>neutral, ok with this` },
  { value: 4, text: `4<br>would like to be mentored on this` },
  { value: 5, text: `5<br>would very strongly like to be mentored on this` },
]

function getLikertCols(numCols: string) {
  if (numCols === '7p-SD-SA') return likert_7p_SD_SA
  if (numCols === '5p-NT-CT') return likert5_5p_NT_CT
  if (numCols === '6-NA-FM') return likert_6_NA_FM
  if (numCols === 'BR-mentors-1') return likert5_5p_BR_special_mentors1
  if (numCols === 'BR-mentees-1') return likert5_5p_BR_special_mentees1
}

function convertToMatrixRows(arrayOfArrays: any[][]): any[] {
  const rows: any[] = []
  arrayOfArrays.forEach((arr) => {
    const [value, text] = arr
    rows.push({ value, text })
  })
  return rows
}

export const ASSESSMENT_NAMES = {
  onboarding: 'onboarding',
  matching: 'matching',
  matchingUSPHS: 'matchingUSPHS',
  relace: 'relace',
}

export const ASSESSMENT_PATHS = {
  onboarding: `/assessments/${ASSESSMENT_NAMES.onboarding}`,
  matching: `/assessments/${ASSESSMENT_NAMES.matching}`,
  matchingUSPHS: `/assessments/${ASSESSMENT_NAMES.matching}/usphs`,
  relace: `/assessments/${ASSESSMENT_NAMES.relace}`,
}

export const isAssessmentPath = (path: string): boolean => {
  return (
    isOnboardingPath(path) ||
    isMatchingPath(path) ||
    isMatchingPathUSPHS(path) ||
    isRelacePath(path)
  )
}

export const isOnboardingPath = (path: string): boolean => {
  return path === ASSESSMENT_PATHS.onboarding
}

export const isMatchingPath = (path: string): boolean => {
  return path === ASSESSMENT_PATHS.matching
}

export const isMatchingPathUSPHS = (path: string): boolean => {
  return path === ASSESSMENT_PATHS.matchingUSPHS
}

export const isRelacePath = (path: string): boolean => {
  return path === ASSESSMENT_PATHS.relace
}

interface AssessmentIds {
  [key: string]: string
  onboarding: string
  matching: string
  matchingUSPHS: string
  relace: string
}

const ASSESSMENT_IDS: AssessmentIds = {
  onboarding: '01GT9X3GWERS9G8R1196K416MD',
  matching: '01HCAK702VQXFSY08CMHGA07VM',
  matchingUSPHS: '01H0QTGXA585V3BNT7SYP3D40W',
  relace: '01H3D5FHNT45BZN1EPT6GXE3MB',
}

export const getAssessmentIdByPath = (path: string): string => {
  if (!path) return ''
  if (isOnboardingPath(path)) return ASSESSMENT_IDS.onboarding
  if (isMatchingPath(path)) return ASSESSMENT_IDS.matching
  if (isMatchingPathUSPHS(path)) return ASSESSMENT_IDS.matchingUSPHS
  if (isRelacePath(path)) return ASSESSMENT_IDS.relace
  return ''
}

export const getAssessmentIdByName = (name: string): string => {
  return ASSESSMENT_IDS[name] || ''
}

function enableAssessment(
  assessmentName: string,
  activeGroupId: number | undefined,
  assessmentsFlags: IAssessmentFlags,
): boolean {
  return (
    !!activeGroupId &&
    !!assessmentsFlags?.[assessmentName]?.includes(activeGroupId)
  )
}

export function onboardingAssessmentEnabled(
  activeGroupId: number | undefined,
  assessmentsFlags: IAssessmentFlags,
): boolean {
  return enableAssessment(
    ASSESSMENT_NAMES.onboarding,
    activeGroupId,
    assessmentsFlags,
  )
}

export function matchingAssessmentEnabled(
  activeGroupId: number | undefined,
  assessmentsFlags: IAssessmentFlags,
): boolean {
  return enableAssessment(
    ASSESSMENT_NAMES.matching,
    activeGroupId,
    assessmentsFlags,
  )
}

export function relaceAssessmentEnabled(
  activeGroupId: number | undefined,
  assessmentsFlags: IAssessmentFlags,
): boolean {
  return enableAssessment(
    ASSESSMENT_NAMES.relace,
    activeGroupId,
    assessmentsFlags,
  )
}
