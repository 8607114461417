import React, { useState } from 'react'
import { Container, Navbar, Nav, Image, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useAuth0 } from '../../auth0'
import { useApiFetch } from '../../api'
import SettingsDropdown from './ProfileIcon'
import colors from '../../theme/theme2'
import { createUseStyles } from 'react-jss'
import { isMobile } from '../../utils/common'
import { useBrand } from '../../BrandHandler'
import Message from '../Icons/Message'
import { useBaseUri } from '../../config/useConfig'

const navLinks = [
  {
    title: 'Home',
    url: '/home',
    testid: 'nav-home',
  },
  {
    title: 'Topics',
    url: '/topics',
    testid: 'nav-topics',
  },
  {
    title: 'Availability',
    url: '/availability',
    testid: 'nav-availability',
  },
  {
    title: 'Conversations',
    url: '/conversations',
    testid: 'nav-conversations',
  },
  {
    title: 'My Network',
    url: '/network',
    testid: 'nav-network',
  },
  {
    title: 'DM',
    url: '/dmmessages',
    icon: <Message />,
    testid: 'nav-directmessages',
  },
]

const useStyles = createUseStyles({
  root: {},
  transition: {
    height: isMobile() ? 85 : 85,
  },
  navShow: {
    visibility: 'visible',
    opacity: 1,
    transition: 'height 0.5s, visibility 2s linear, opacity 2s linear',
  },
  navHidden: {
    visibility: 'hidden',
    opacity: 0,
    height: 0,
    transition: 'height 1.5s, visibility 0.3s linear, opacity 0.3s linear',
  },
  expandedHeight: {
    height: 298,
  },
  brand: {
    fontSize: 10,
  },
  nav: {
    backgroundColor: 'rgb(255, 255, 255, 1)',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, .5)',
    margin: '0 auto',
  },
  navItem: {
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
    transition: 'height 1s',
  },
  toggle: {
    backgroundPosition: 'center',
  },
  link: {
    color: colors.dark,
    '&:focus, &:active, &:after': {
      color: colors.primary,
    },
    '&:hover': {
      color: colors.primary,
      textDecoration: 'none',
    },
  },
  navBarMobile: {
    '@media (max-width: 600px)': {
      height: 'calc(100vh - 135px)',
      overflowY: 'scroll',
    },
    '@media (min-width: 600px) and (max-width: 990px)': {
      maxHeight: '100vh',
      overflowY: 'scroll',
    },
  },
})

const NavBar = ({ show }) => {
  const classes = useStyles()
  const lattusAppBaseUri = useBaseUri('lattusApp')
  const [expanded, setExpanded] = useState(false)
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()
  const { isBranded, brandInfo } = useBrand()
  const { data: currentProfile, loading: isUserLoading } =
    useApiFetch('getCurrentProfile')

  if (isUserLoading) return null

  return (
    <div className={classes.root}>
      <div
        className={`${classes.transition} ${
          !show ? classes.navHidden : classes.navShow
        }`}
      />
      <Navbar
        fixed="top"
        className={`${classes.nav} ${
          !expanded ? `${classes.transition}` : ''
        } ${!show ? classes.navHidden : classes.navShow}`}
        expand="lg"
        expanded={expanded}
      >
        <Container style={{ height: 72 }}>
          <Navbar.Brand className={classes.brand} as={Link} to="/home">
            {isBranded && brandInfo && brandInfo['whiteLabelLogo'] ? (
              <Row>
                <Col>
                  <Image
                    src={
                      brandInfo['whiteLabelLogo'] ||
                      'https://assets.golattitude.com/Lattitude-Logo_FullColor_Slogan_Cropped.png'
                    }
                    link="/home"
                    height={50}
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <Image
                    src={
                      'https://assets.golattitude.com/Lattitude-Logo_FullColor_Slogan_Cropped.png'
                    }
                    link="/home"
                    height={50}
                  />
                </Col>
              </Row>
            )}
          </Navbar.Brand>
          <Navbar.Toggle
            data-testid={'nav-mobile-menu'}
            aria-controls="basic-navbar-nav"
            className={classes.toggle}
            onClick={() => setExpanded(expanded ? false : 'expanded')}
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className={classes.navBarMobile}
          >
            {(expanded || !isMobile()) && (
              <Nav
                className="ml-auto"
                style={{
                  background: 'rgb(255, 255, 255, 1)',
                  // borderRadius: '4px',
                  borderBottomRightRadius: '4px',
                  borderBottomLeftRadius: '4px',
                  height: '100%',
                }}
              >
                {isAuthenticated && (
                  <NavLinks
                    links={navLinks}
                    onClick={() => setExpanded(false)}
                  />
                )}
                {!isAuthenticated && (
                  <Nav.Link
                    data-testid="nav-login-redirect"
                    onClick={() => loginWithRedirect({})}
                    className={classes.link}
                  >
                    Log in
                  </Nav.Link>
                )}
                <SettingsDropdown
                  data-testid="nav-mysettings"
                  isAuthenticated={isAuthenticated}
                  logout={() => logout({ returnTo: lattusAppBaseUri })}
                  currentProfile={currentProfile}
                  onClick={() => setExpanded(false)}
                />
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

const NavLinks = (props) => {
  const classes = useStyles()

  return (
    <Nav className={classes.navItem}>
      {props.links.map(function (item) {
        return (
          <Nav.Link
            data-testid={item.testid}
            key={item.url}
            to={item.url}
            className={classes.link}
            as={Link}
            onClick={props.onClick}
          >
            {item.icon || item.title}
          </Nav.Link>
        )
      })}
    </Nav>
  )
}

export default NavBar
