import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import CircleName from '../../../../elemets/CircleName'
import { createUseStyles } from 'react-jss'
import colors, { headings } from '../../../../theme/theme2'
import CardContainer from '../../../../elemets/CardContainer'
import SquareButton from '../../../../elemets/SquareButton'
import CardTitle from '../../../../elemets/CardTitle'
import Icon from '../../../Icons'
import EditUserModal from './EditUserModal'
import RemoveUserModal from './RemoveUserModal'
import PermissionModal from './PermissionModal'
import { isMobile } from '../../../../utils/common'
import moment from 'moment'
import MentorBadge from '../../../Topics/MentorBadge'
import CircleImage from '../../../../elemets/CircleImage'
import ResetPasswordModal from './ResetPasswordModal'

const useStyles = createUseStyles({
  containers: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    height: 60,
  },
  avatar: {
    fontSize: '14px !important',
    fontWeight: 'bold !important',
    color: `${colors.primary} !important`,
    backgroundColor: `#eff9f3!important`,
  },
  name: {
    ...headings.title,
  },
  profile: {
    paddingLeft: 30,
    paddingTop: 10,
  },
  stats: {
    ...headings.paragraph,
    fontSize: 12,
    color: '#707575',
    paddingTop: 15,
    paddingLeft: 25,
  },
  editButton: {
    paddingTop: 10,
  },
  userMenu: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 300,
    height: 275,
    zIndex: 2,
    boxShadow:
      '0 6px 16px 0 rgba(153, 155, 168, 0.1), 0 2px 6px 0 rgba(0, 0, 0, 0.5)',
  },
  ul: {
    padding: 0,
    listStyleType: 'none',
    '& li': {
      ...headings.paragraph,
      fontWeight: 'bold',
      color: colors.dark,
      margin: 20,
      '& i': {
        '&:before': {
          color: colors.primary,
        },
      },
    },
    cursor: 'pointer',
  },
  names: {
    whiteSpace: 'nowrap',
    // overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80%',
  },
  icons: {
    cursor: 'default',
    lineHeight: 0,
  },
})

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleHideDropdown = (event) => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false)
    }
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true)
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true)
      document.removeEventListener('click', handleClickOutside, true)
    }
  })
  return { ref, isComponentVisible, setIsComponentVisible }
}

const Menu = ({
  setEditModal,
  setRemoveModal,
  setPermissionModal,
  setResetPasswordModal,
}) => {
  const classes = useStyles()

  return (
    <CardContainer
      className={classes.userMenu}
      header={<CardTitle title={'Manage User'} />}
      headerDivider={true}
    >
      <ul className={classes.ul}>
        <li
          onClick={() => {
            setPermissionModal(true)
          }}
        >
          <Icon icon="fas fa-tasks" />{' '}
          <span className="ml-2">Lattitude Permissions</span>
        </li>
        <li
          onClick={() => {
            setEditModal(true)
          }}
        >
          <Icon icon="fas fa-edit" /> <span className="ml-2">Edit User</span>
        </li>
        <li
          onClick={() => {
            setResetPasswordModal(true)
          }}
        >
          <Icon icon="fas fa-key" />{' '}
          <span className="ml-2">Reset Password</span>
        </li>
        <hr></hr>
        <li
          onClick={() => {
            setRemoveModal(true)
          }}
        >
          <Icon icon="fas fa-times-circle" />{' '}
          <span className="ml-2">Remove User</span>
        </li>
      </ul>
    </CardContainer>
  )
}

const ProfileCard = ({ user, group, reload }) => {
  const classes = useStyles()
  const [editModal, setEditModal] = useState(false)
  const [removeModal, setRemoveModal] = useState(false)
  const [permissionModal, setPermissionModal] = useState(false)
  const [resetPasswordModal, setResetPasswordModal] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const displayUserMenu = useCallback(() => {
    setIsComponentVisible(true)
  })
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  return !isMobile() ? (
    <CardContainer className={`p-0 mb-2 ${classes.containers}`}>
      {editModal && (
        <EditUserModal
          user={user}
          setDisplayModal={setEditModal}
          reload={reload}
        />
      )}
      {removeModal && (
        <RemoveUserModal
          user={user}
          group={group}
          setDisplayModal={setRemoveModal}
          reload={reload}
        />
      )}
      {permissionModal && (
        <PermissionModal
          user={user}
          group={group}
          setDisplayModal={setPermissionModal}
          reload={reload}
        />
      )}
      {resetPasswordModal && (
        <ResetPasswordModal
          user={user}
          group={group}
          setDisplayModal={setResetPasswordModal}
          reload={reload}
        />
      )}
      <Row noGutters>
        <Col xs={5} className={`${classes.profile} pl-3`}>
          <Row noGutters className="align-items-center">
            {user.avatar ? (
              <CircleImage src={user.avatar} size={40} />
            ) : (
              <CircleName
                id="avatar-name"
                size={40}
                text={
                  user.firstName && user.lastName
                    ? user.firstName[0] + user?.lastName[0]
                    : ''
                }
                className={classes.circleName}
              />
            )}
            <div className={`pl-3 d-flex ${classes.name}`}>
              {user.firstName} {user.lastName}
              <div className={classes.icons}>
                {user.topics.some((topic) => topic.isMentor) && (
                  <MentorBadge isMentor={true} />
                )}
                {user.isAdmin && <Icon icon={'fas fa-key'} />}
              </div>
            </div>
          </Row>
        </Col>
        <Col xs={2} className={classes.stats}>
          <span>{user.maxConversations}</span>
        </Col>
        <Col xs={2} className={`${classes.stats} pl-0 pr-0`}>
          <span>
            {user?.lastLogin ? moment(user.lastLogin).format('MM/DD/YYYY') : ''}
          </span>
        </Col>
        <Col xs={2} className={`${classes.stats} pl-0 pr-0`}>
          <span>
            {user?.dateCreated
              ? moment(user.dateCreated).format('MM/DD/YYYY')
              : ''}
          </span>
        </Col>
        <Col xs={1} className={'pl-0 pr-3 d-flex justify-content-end'}>
          <div className={classes.editButton} ref={ref}>
            <SquareButton onClick={displayUserMenu}>
              <i className="material-icons">edit</i>
            </SquareButton>
            {isComponentVisible && (
              <Menu
                setEditModal={setEditModal}
                setRemoveModal={setRemoveModal}
                setPermissionModal={setPermissionModal}
                setResetPasswordModal={setResetPasswordModal}
              />
            )}
          </div>
        </Col>
      </Row>
    </CardContainer>
  ) : (
    <Row noGutters>
      <Col xs={12}>
        <CardContainer className={`p-0 mb-2 ${classes.containers}`}>
          {editModal && (
            <EditUserModal
              user={user}
              setDisplayModal={setEditModal}
              reload={reload}
            />
          )}
          {removeModal && (
            <RemoveUserModal
              user={user}
              group={group}
              setDisplayModal={setRemoveModal}
              reload={reload}
            />
          )}
          {permissionModal && (
            <PermissionModal
              user={user}
              group={group}
              setDisplayModal={setPermissionModal}
              reload={reload}
            />
          )}
          {resetPasswordModal && (
            <ResetPasswordModal
              user={user}
              group={group}
              setDisplayModal={setResetPasswordModal}
              reload={reload}
            />
          )}
          <Row noGutters>
            <Col xs={10} className={classes.profile}>
              <Row noGutters className={`align-items-center pl-2`}>
                {user.avatar ? (
                  <CircleImage src={user.avatar} size={40} />
                ) : (
                  <CircleName
                    id="avatar-name"
                    size={40}
                    text={
                      user.firstName && user.lastName
                        ? user.firstName[0] + user?.lastName[0]
                        : ''
                    }
                    className={classes.circleName}
                  />
                )}
                <div className={`d-flex pl-3 ${classes.name} ${classes.names}`}>
                  {user.firstName} {user.lastName}
                  <div className={classes.icons}>
                    {user.topics.some((topic) => topic.isMentor) && (
                      <MentorBadge isMentor={true} />
                    )}
                    {user.isAdmin && <Icon icon={'fas fa-key'} />}
                  </div>
                </div>
              </Row>
            </Col>
            <Col xs={2} className={'pl-0 '}>
              <div className={classes.editButton} ref={ref}>
                <SquareButton onClick={displayUserMenu}>
                  <i className="material-icons">edit</i>
                </SquareButton>
                {isComponentVisible && (
                  <Menu
                    setEditModal={setEditModal}
                    setRemoveModal={setRemoveModal}
                    setPermissionModal={setPermissionModal}
                    setResetPasswordModal={setResetPasswordModal}
                  />
                )}
              </div>
            </Col>
          </Row>
        </CardContainer>
      </Col>
    </Row>
  )
}

export default ProfileCard
