import React from 'react'
import { Container, Form } from 'react-bootstrap'
import SaveCancel from './SaveCancel'
import { TextArea, Dropdown } from './Forms'

class InviteUsers extends React.Component {
  constructor(props) {
    super(props)
    this.save = this.save.bind(this)
    this.state = {
      users: [],
    }
  }

  save() {}

  render() {
    return (
      <Container>
        <h2>Invite Users</h2>

        <Form>
          <TextArea name="email" label="Email Address(es)" />
          <Dropdown name="groupId" label="Group" options={[]} />

          <h4>Invitation Message</h4>
          <p>
            “(User Name) has invited you to join Lattitude. Lattitude is a
            platform for curious, motivated minds to have focussed conversations
            on specific topics.”
          </p>
        </Form>

        <SaveCancel />
      </Container>
    )
  }
}

export default InviteUsers
