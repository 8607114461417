import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'

// Components
import LoaderPage from '../../elemets/LoaderPage'
import AddAvailabilityCard from './AddAvailabilityCard'
import GridCalendar from './GridCalendar'
import AvailabilityIntegrationCard from './AvailabilityIntegrationCard'
import MyCalendarsCard from './MyCalendarsCard'
import EventsCard from './EventsCard'

// API
import { useApiFetch } from '../../api/index'

// Utils
import { isMobile } from '../../utils/common'

const AvailabilityPage = React.memo(() => {
  const [checkedList, setCheckedList] = useState()
  const [currentDate, setCurrentDate] = useState(new Date())

  const {
    data: userProfile,
    reload: reloadUser,
    loading: loadingUser,
  } = useApiFetch('getCurrentProfile')
  const {
    data: eventList,
    reload: reloadEvent,
    loading: loadingEvent,
    fetch: fetchEvent,
  } = useApiFetch('listEvents', {}, false)
  const {
    data: identities,
    loading: loadingIdentities,
    reload: reloadIdentities,
  } = useApiFetch('getIntegrations')

  useEffect(() => {
    if (identities) {
      const checked = []
      identities.forEach(identity => {
        checked.push(identity.email)
      })
      setCheckedList(checked)
    }
  }, [identities])

  useEffect(() => {
    if (checkedList && currentDate) {
      const date = new Date(currentDate)
      const firstDayOfTheMonth = new Date(
        date.getFullYear(),
        date.getMonth(),
        1,
        0,
        0,
      )
      const lastDayOfTheMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0,
        0,
        0,
      )
      const query = {
        count: 5000,
        after: moment(firstDayOfTheMonth).subtract(7, 'day').utc().format(),
        before: moment(lastDayOfTheMonth).add(7, 'day').utc().format(),
      }
      if (checkedList.length > 0) {
        query.calendar = checkedList
      }
      fetchEvent({ query })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList, currentDate])

  const calendarsToDisplay = useMemo(() => {
    const result = []
    if (userProfile) {
      result.push({
        email: userProfile.email,
        name: 'Lattitude Calendar',
        checkbox: false,
      })
    }
    if (identities) {
      identities.forEach(identity => {
        result.push({
          email: identity.email,
          name: identity.email,
          checkbox: true,
        })
      })
    }
    return result
  }, [userProfile, identities])

  const handleCheckIntegration = useCallback(
    email => {
      if (!checkedList.includes(email)) {
        setCheckedList(c => [...c, email])
      } else {
        setCheckedList(c => c.filter(item => item !== email))
      }
    },
    [checkedList],
  )

  const onSideCalendarClick = useCallback(day => {
    setCurrentDate(day)
  }, [])

  return (
    <div className="container p-0">
      {(loadingUser || loadingEvent || loadingIdentities) && <LoaderPage />}
      {isMobile() ? (
        <>
          <EventsCard
            eventList={eventList}
            reload={reloadEvent}
            setCurrentDate={setCurrentDate}
          />
          <MyCalendarsCard
            calendars={calendarsToDisplay}
            checkedList={checkedList || []}
            onCheckIntegration={handleCheckIntegration}
            user={userProfile}
          />
        </>
      ) : (
        <Row noGutters>
          <Col xs={12} sm={12} md={5} lg={5} xl={4} className="px-0 px-md-3">
            <AddAvailabilityCard
              user={userProfile}
              reload={reloadUser}
              reloadEvents={reloadEvent}
            />
            <AvailabilityIntegrationCard
              calendars={calendarsToDisplay}
              checkedList={checkedList || []}
              onCheckIntegration={handleCheckIntegration}
              eventList={eventList}
              reload={reloadIdentities}
              onDayClick={onSideCalendarClick}
              selectedDay={currentDate}
            />
          </Col>
          <Col xs={12} sm={12} md={7} lg={7} xl={8} className="px-0 px-md-3">
            <GridCalendar
              eventList={eventList}
              reload={reloadEvent}
              setCurrentDate={setCurrentDate}
              defaultDate={currentDate}
            />
          </Col>
        </Row>
      )}
    </div>
  )
})

export default AvailabilityPage
