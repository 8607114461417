import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useApiSubmit } from '../../api'

const ContactsContext = createContext()
export function useContacts() {
  return useContext(ContactsContext)
}
export default function ContactsProvider({ children }) {
  const [contacts, setContacts] = useState(null)
  const [searchContacts, setSearchContacts] = useState('')
  const [filteredContacts, setFilteredContacts] = useState('')

  const { result, submit: usersOfGroupV1 } = useApiSubmit('usersOfGroupV1')
  const getContacts = useCallback(
    (filterText) => {
      if (!contacts) usersOfGroupV1({ body: { text: filterText } })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contacts],
  )

  useEffect(() => {
    // Only fetch contacts once, on component mount
    if (!contacts) {
      usersOfGroupV1({ body: { text: '' } }) // Assuming you don't want to filter initially
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only run once as empty dependency array prevents re-running
  }, [])
  useEffect(() => {
    if (result) {
      const orderedUsers = result.users.sort((a, b) => {
        if (a.ur_first < b.ur_first) return -1
        if (a.ur_first > b.ur_first) return 1
        return 0
      })

      setContacts(orderedUsers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  useEffect(() => {
    if (searchContacts.length > 0) {
      const filteredContacts = contacts.filter((contact) => {
        const fullName = `${contact.ur_first} ${contact.ur_last}`
        return fullName.toLowerCase().includes(searchContacts.toLowerCase())
      })
      setFilteredContacts(filteredContacts)
    } else {
      setFilteredContacts(contacts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContacts])

  return (
    <ContactsContext.Provider
      value={{
        contacts,
        getContacts,
        searchContacts,
        setSearchContacts,
        filteredContacts,
      }}
    >
      {children}
    </ContactsContext.Provider>
  )
}
