import React from 'react'
import { useConversationDetailsBuilder } from '../../builder/ConversationBuilder'
import { PartialConversation } from '../../builder/state'
import { MethodKey } from './data/Method'
import AvailableMethods from './data/AvailableMethods'
import MethodField from './MethodField'

const selectMethod = (value: PartialConversation): MethodKey | undefined =>
  value.method

const SetMethod = (): JSX.Element => {
  const { value, setMethod } = useConversationDetailsBuilder()
  const method = selectMethod(value)

  return (
    <AvailableMethods>
      <MethodField value={method} onChange={setMethod} />
    </AvailableMethods>
  )
}

export default SetMethod
