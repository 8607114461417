import React, { useState, useEffect } from 'react'
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { getAccessToken } from '../auth0'
import { useBaseUri } from '../config/useConfig'

type ProviderProps = {
  children: React.ReactNode
}

/** Provider initialize an Apollo client and makes it available to the React application. */
const Provider = ({ children }: ProviderProps): JSX.Element | null => {
  const [client, setClient] =
    useState<ApolloClient<NormalizedCacheObject> | null>(null)
  const graphBaseUri = useBaseUri('graph')

  useEffect(() => {
    const superLink = ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            // eslint-disable-next-line no-console
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            )
          })
        }
        if (networkError) {
          // eslint-disable-next-line no-console
          console.log(`[Network error]: ${networkError}`)
        }
      }),
      setContext(async (_, { headers }) => {
        const token = await getAccessToken()
        const out = {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }

        return out
      }),
      new BatchHttpLink({
        credentials: 'same-origin',
        uri: `${graphBaseUri || ''}/`,
      }),
    ])

    setClient(
      new ApolloClient({
        cache: new InMemoryCache(),
        link: superLink,
      }),
    )
  }, [graphBaseUri])

  useEffect(
    () => () => {
      client?.stop()
    },
    [client],
  )

  if (!client) {
    return null
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default Provider
