import { Dispatch, useReducer } from 'react'
import { type IGroup as Group } from '../types/Groups.typings'

export enum Status {
  Unset,
  Loading,
  Loaded,
  Error,
}

type EmptyState = {
  status: Status.Unset
}

type LoadingState = {
  status: Status.Loading
  selectGroupId?: number
}

type LoadedState = {
  status: Status.Loaded
  groups: Group[]
  active: Group | undefined
  defaultSkillSettings: Group['defaultSkillSettings'][]
}

type ErrorState = {
  status: Status.Error
  error: Error
}

export type State = EmptyState | LoadingState | LoadedState | ErrorState

type LoadingAction = {
  type: 'LOADING'
  payload?: number
}

export const loading = (selectGroupId?: number): LoadingAction => ({
  type: 'LOADING',
  payload: selectGroupId,
})

type ClearAction = {
  type: 'CLEAR'
}

export const clear = (): ClearAction => ({ type: 'CLEAR' })

type LoadedAction = {
  type: 'LOADED'
  payload: Group[]
}

export const loaded = (payload: Group[]): LoadedAction => ({
  type: 'LOADED',
  payload,
})

type ErroredAction = {
  type: 'ERRORED'
  payload: Error
}

export const errored = (payload: Error): ErroredAction => ({
  type: 'ERRORED',
  payload,
})

type SelectActiveAction = {
  type: 'SELECT'
  payload: number
}

export const selectActive = (groupId: number): SelectActiveAction => ({
  type: 'SELECT',
  payload: groupId,
})

type Action =
  | LoadingAction
  | ClearAction
  | LoadedAction
  | ErroredAction
  | SelectActiveAction

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'LOADING': {
      const { payload: selectGroupId } = action
      return { status: Status.Loading, selectGroupId }
    }
    case 'LOADED': {
      const { status } = state
      if (status !== Status.Loading) {
        return state
      }

      const { selectGroupId } = state
      const { payload: groups } = action
      const active =
        typeof selectGroupId !== 'number'
          ? groups[0]
          : groups.find(({ id }) => id === selectGroupId)
      const defaultSkillSettings = active
        ? [active.defaultSkillSettings]
        : ([] as Group['defaultSkillSettings'][])
      return { status: Status.Loaded, groups, active, defaultSkillSettings }
    }
    case 'ERRORED': {
      const { payload: error } = action
      return { status: Status.Error, error }
    }
    case 'SELECT': {
      const { status } = state
      if (status !== Status.Loaded) {
        // invalid action - can only select an active group when there are groups in state
        return state
      }

      const { payload: groupId } = action
      const { groups } = state
      const active = groups.find(({ id }: Group) => id === groupId)
      if (!active) {
        // invalid action - selected a group that is not in the current state
        return state
      }

      return { ...state, active }
    }
    default: {
      return { status: Status.Unset }
    }
  }
}

const useGroupState = (): [State, Dispatch<Action>] =>
  useReducer(reducer, { status: Status.Unset })

export default useGroupState
