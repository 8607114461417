import React, { ReactNode, createContext, useContext } from 'react'
import { Relationship } from './Relationship'

type ContextValue = {
  relationships: Relationship[]
  ready: boolean
}

export const Context = createContext<ContextValue>({
  relationships: [],
  ready: false,
})

type RelationshipsProviderProps = {
  relationships: Relationship[]
  ready?: boolean
  children: ReactNode
}

const RelationshipsProvider = ({
  relationships,
  ready = false,
  children,
}: RelationshipsProviderProps): JSX.Element => (
  <Context.Provider value={{ relationships, ready }}>
    {children}
  </Context.Provider>
)

export default RelationshipsProvider

export const useRelationships = (): ContextValue => useContext(Context)
