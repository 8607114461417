import { invokeOperationWithAuth } from '@/api'
import { getAccessToken } from '../../auth0'

export const getConversation = async (
  conversationId: string,
  signal?: AbortSignal,
): Promise<any> => {
  return invokeOperationWithAuth(getAccessToken)('getConversation', {
    params: { conversationId },
    signal,
  })
}
