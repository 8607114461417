import React from 'react'
import Field from './Field'
import TextControl from '../controls/TextControl'

type TextFieldProps = {
  label: string
  labelId: string
  onChange: (value: string) => void
  maxLength?: number
  required?: boolean
  value?: string
}

const TextField = ({
  label,
  labelId,
  onChange,
  maxLength,
  required,
  value,
}: TextFieldProps): JSX.Element => (
  <Field label={label} labelId={labelId} required={required} multiLine>
    <TextControl
      value={value}
      onChange={onChange}
      labelId={labelId}
      maxLength={maxLength}
    />
  </Field>
)

export default TextField
