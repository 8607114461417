import React, { useCallback } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { useKebabMenu } from '../../elemets/KebabMenu'
import { useModalProvider } from '../../conversations/details/ModalProvider'

const ManuallyLogConversation = React.forwardRef((): JSX.Element => {
  const { open: openModal } = useModalProvider()
  const { close: closeMenu } = useKebabMenu()

  const handleOpen = useCallback(() => {
    openModal()
    closeMenu()
  }, [openModal, closeMenu])

  return <MenuItem onClick={handleOpen}>Manually Log Conversation</MenuItem>
})

export default ManuallyLogConversation
