import React, { type FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

// Api
import { useApiFetch } from '@/api'

// Elements
import ConversationLayout from './ConversationLayout'
import LoaderPage from '../../../elemets/LoaderPage'
import { ConversationFromApi } from '@/components/Conversations/conversation.types'
import { IUserProfile } from '@/types/UserProfile.typings'

type Props = {
  showNavBar: (b: boolean) => void
  conversationId: string
}

// Component key ensures the component instance is remounted whenever id changes
const ConversationPage = ({ showNavBar, conversationId }: Props) => {
  const {
    data: conversation,
    reload,
    loading: loadingConversation,
  } = useApiFetch<ConversationFromApi>('getConversation', {
    params: { conversationId },
  })

  const { data: currentProfile, loading: loadingCurrentProfile } =
    useApiFetch<IUserProfile>('getCurrentProfile')

  const { me, otherParticipant } = useMemo(() => {
    return {
      otherParticipant:
        conversation?.participants.find(
          (participant) => !participant.currentUser,
        ) ?? null,
      me:
        conversation?.participants.find(
          (participant) => participant.currentUser,
        ) ?? null,
    }
  }, [conversation])

  if (
    loadingConversation ||
    loadingCurrentProfile ||
    !currentProfile ||
    !me ||
    !otherParticipant
  ) {
    // return loading page while fetching data
    return <LoaderPage />
  }

  if (conversation) {
    return (
      <ConversationLayout
        conversation={conversation}
        me={me}
        otherParticipant={otherParticipant}
        reload={reload}
        currentProfile={currentProfile}
        showNavBar={showNavBar}
      />
    )
  }

  // fallback when both loadings and conversation are falsy
  return null
}

// Wrap Conversation in a component that will force remounting when id changes
type WrapperProps = {
  showNavBar: (b: boolean) => void
}
const ConversationPageWrapper: FC<WrapperProps> = (props: WrapperProps) => {
  const { showNavBar } = props
  const { id: conversationId } = useParams<{ id: string }>()
  if (!conversationId) {
    return null
  }
  return (
    <ConversationPage
      key={`conversation-page-${conversationId}`}
      conversationId={conversationId}
      showNavBar={showNavBar}
    />
  )
}

export default ConversationPageWrapper
