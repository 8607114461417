import { useContext } from 'react'
import {
  Auth0Config,
  BaseUri,
  Config,
  ConfigCat,
  GoogleConfig,
  MicrosoftConfig,
} from './Config'
import { Context } from './Provider'

/** useConfig provides the application configuration. */
const useConfig = (): Config => useContext(Context)

/** useAuth0 provides the Auth0 client configuration. */
export const useAuth0 = (): Auth0Config => {
  const { auth0 } = useConfig()
  return auth0
}

/** useBaseUri provides the base URI value for the specified subsystem/service. */
export const useBaseUri = (name: keyof BaseUri): string => {
  const { baseUri } = useConfig()
  return baseUri[name] ?? ''
}

/** useConfigCat provides the ConfigCat configuration. */
export const useConfigCat = (): ConfigCat => {
  const { configCat } = useConfig()
  return (
    configCat ?? { sdkKey: 'rDDbCLOvF06GPc3MmsDjWQ/4MGQa11kUUqmE4tM5hmK6w' }
  )
}

/** useGoogleConfig provides the Google Calendar configuration. */
export const useGoogleConfig = (): GoogleConfig => {
  const { google } = useConfig()
  return google
}

/** useMicrosoftConfig provides the Microsoft 365 configuration. */
export const useMicrosoftConfig = (): MicrosoftConfig => {
  const { microsoft } = useConfig()
  return microsoft
}

export default useConfig
