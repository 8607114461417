import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { isMobile } from '../../../utils/common'
import colors from '../../../theme/theme2'
import { createUseStyles } from 'react-jss'
import { useApiSubmit } from '../../../api'
import compose from 'just-compose'
import { withErrorHandler } from '../../../errors'
import RoundButton from '../../../elemets/RoundButton'

const presetQuestion = [
  { text: 'What is it that you really want to be and do?', disabled: false },
  {
    text: 'What are you doing really well that is helping you get there?',
    disabled: false,
  },
  {
    text: 'What are you not doing well that is preventing you from getting there?',
    disabled: false,
  },
  {
    text: 'What will you do differently tomorrow to meet those challenges?',
    disabled: false,
  },
  {
    text: 'How can I help / where do you need the most help?',
    disabled: false,
  },
  { text: 'How did you get to where you are today?', disabled: false },
  { text: 'What is one tip for somebody in my shoes?', disabled: false },
  {
    text: 'What is one thing you would do differently if you could go back and redo?',
    disabled: false,
  },
  {
    text: 'What does a successful day look like for you?  What does a tough day look like for you?',
    disabled: false,
  },
  { text: 'How do you balance your work and home life?', disabled: false },
  {
    text: 'Do you have any books that you would suggest I read?',
    disabled: false,
  },
  {
    text: 'Would it be possible to schedule another conversation with you to follow up in 3 weeks?',
    disabled: false,
  },
]
const useStyles = createUseStyles({
  text: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: colors.dark,
    margin: 0,
  },
  bgGrey: {
    backgroundColor: 'rgba(243, 243, 242, 0.6)',
  },
  disabled: {
    color: colors.grey2,
  },
  buttonPlus: {
    fontSize: 18,
  },
  index: {
    marginRight: 16,
    color: colors.dark,
  },
})

const SuggestedQuestions = ({ conversationId, reload, handleError }) => {
  const classes = useStyles()
  const [questions, setQuestions] = useState(presetQuestion)
  const {
    submitted,
    submit: addConversationNote,
    submitting,
    err,
  } = useApiSubmit('addConversationNote')

  // Added
  useEffect(() => {
    if (submitted && !err && !submitting) {
      reload()
    } else if (err && !submitting) {
      handleError(err, true, {
        message: 'There was a problem adding the note.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, err, submitting])

  const handleSave = useCallback(
    (index) => {
      addConversationNote({
        params: { conversationId },
        body: {
          text: questions[index].text,
          shareable: false,
        },
      })

      setQuestions((questions) => {
        questions[index].disabled = true
        return questions
      })
    },
    [addConversationNote, conversationId, questions],
  )

  return (
    <div className={isMobile() ? '' : 'pl-3'}>
      {questions.map((question, index) => {
        return (
          <Row
            noGutters
            className={`
              justify-content-between align-items-center
              ${isMobile() ? 'p-2' : 'p-3'}
              ${question.disabled ? classes.disabled : ' '}
              ${index % 2 !== 0 ? classes.bgGrey : ''}`}
            key={question.text}
          >
            <Col xs={11} className="d-flex">
              <p
                className={`${classes.text} ${
                  question.disabled ? classes.disabled : ' '
                }`}
              >
                <span className="mr-1">{index + 1}.</span>
                {question.text}
              </p>
            </Col>
            <Col xs={1} className="d-flex flex-row-reverse">
              <RoundButton
                onClick={() => {
                  handleSave(index)
                }}
                disabled={question.disabled}
                type="GREEN"
                icon="fa fa-plus-circle"
                className={classes.buttonPlus}
              />
            </Col>
          </Row>
        )
      })}
    </div>
  )
}

export default compose(withErrorHandler)(SuggestedQuestions)
