import React, { useEffect, useMemo } from 'react'
import { Container } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import { useIsMounted } from 'usehooks-ts'
import { useParams, useLocation, useHistory } from 'react-router-dom'

import { useApiFetch } from '@/api'
import LoaderPage from '../elemets/LoaderPage'
import PublicProfile from './PublicProfile/PublicProfile'
import { useErrorHandler } from '@/errors'
import { useGroups } from '@/groups/GroupsContext'

const ProfilePage = () => {
  const isMounted = useIsMounted()
  const [isLoading, setIsLoading] = React.useState(true)
  const { id: profileId } = useParams()
  const { search } = useLocation()
  const { push } = useHistory()
  const queryParams = new URLSearchParams(search)
  const otherParticipantId = queryParams.get('meet')
  const groupId = queryParams.get('group')
    ? parseInt(queryParams.get('group'), 10)
    : null
  const handleError = useErrorHandler()
  const { defaultSkillSettings } = useGroups()
  // const { groups, defaultSkillSettings } = useGroups()
  // // get groups default skill
  // const group = useMemo(() => {
  //   // Only recalculate if groupId or groups change
  //   return groupId
  //     ? groups.find((g) => g.id === groupId)
  //     : { defaultSkillSettings: {} }
  // }, [groupId, groups])

  // setup fetch handlers
  const {
    data: profile,
    loading: loadingProfile,
    err: profileErr,
    fetch: fetchProfile,
  } = useApiFetch('getProfileV1', {}, false)
  const {
    data: profileAvailability,
    err: profileAvailabilityErr,
    loading: loadingProfileAvailability,
    fetch: fetchAvailabilities,
  } = useApiFetch('listMatchAvailability', {}, false)

  // make api request - Profile
  useEffect(() => {
    if (!isMounted()) return
    if (profileId && !profile && !loadingProfile && !profileErr) {
      fetchProfile({
        params: { profileId },
      })
    }
  }, [isMounted, profile, profileId, fetchProfile, loadingProfile, profileErr])

  // handle api response - Profile
  useEffect(() => {
    if (!isMounted()) return
    if (profile) {
      if (!otherParticipantId) {
        setIsLoading(false)
      }
    } else if (profileErr) {
      handleError(profileErr, true, {
        message: 'Error fetching profile',
        level: 'error',
        subject: 'Profile',
      })
      push('/')
    }
  }, [isMounted, profile, profileErr, handleError, push, otherParticipantId])

  // make api request - Availabilities
  useEffect(() => {
    if (!isMounted()) return
    if (
      profile &&
      !profileAvailability &&
      !loadingProfileAvailability &&
      !profileAvailabilityErr
    ) {
      fetchAvailabilities({ query: { token: profile.token } })
    }
  }, [
    isMounted,
    profileAvailability,
    fetchAvailabilities,
    loadingProfileAvailability,
    profileAvailabilityErr,
    otherParticipantId,
    profile,
  ])

  // handle api response - Availabilities
  useEffect(() => {
    if (!isMounted()) return
    if (profile && profileAvailability && defaultSkillSettings.length > 0) {
      setIsLoading(false)
    } else if (profileAvailabilityErr) {
      handleError(profileAvailabilityErr, true, {
        message: 'Error fetching availability',
        level: 'error',
        subject: 'Profile Availability',
      })
      push('/')
    }
  }, [
    isMounted,
    profile,
    profileAvailability,
    profileAvailabilityErr,
    handleError,
    defaultSkillSettings,
    push,
  ])
  const {
    data: completedConversationsData,
    loading,
    fetch: fetchCompleted,
  } = useApiFetch('listConversations', {}, null)
  // Pass down "loading" into the "reload" prop so the downstream components know when to refresh.
  // See example:
  // - Main: src/components/Conversations/ConversationsPage.jsx
  // - Child: src/conversations/actions/ScheduleAgain.tsx
  return (
    <>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <Container className={`${isMobile ? 'p-0' : ''}`}>
          <PublicProfile
            profile={profile}
            profileAvailability={profileAvailability}
            startSchedulingOpen={!!otherParticipantId}
            onScheduled={() => fetchCompleted()}
            skillSettings={defaultSkillSettings}
          />
        </Container>
      )}
    </>
  )
}

export default ProfilePage
