import React, { useCallback } from 'react'
import { useConversationDetailsBuilder } from './builder/ConversationBuilder'
import { Conversation } from '../Conversation'
import SubmitButton from './SubmitButton'

type SubmitProps = {
  onClick: (conversationDetails: Conversation) => void
  disabled: boolean
}

const Submit = ({ onClick, disabled }: SubmitProps): JSX.Element => {
  const { submission } = useConversationDetailsBuilder()
  const canSubmit = !!submission

  const handleSubmit = useCallback(() => {
    if (!submission) {
      throw new Error('cannot submit an incomplete conversation')
    }

    onClick(submission)
  }, [onClick, submission])

  return (
    <SubmitButton onClick={handleSubmit} disabled={disabled || !canSubmit} />
  )
}

export default Submit
