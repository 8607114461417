import React from 'react'
import { MicrosoftLogin } from 'react-microsoft-login'
import { useMicrosoftConfig } from '../../../config/useConfig'
import { useErrorHandler } from '../../../errors'

const MicrosoftLoginButton = ({ linkMicrosoftAccount, redirectUri }) => {
  const handleError = useErrorHandler()
  const { clientId } = useMicrosoftConfig()

  const handleMicrosoftResponse = (err, response) => {
    if (err) {
      handleError(err, true, {
        message: `There was a problem linking your account: ${err}`,
      })
    } else {
      const {
        idToken: { rawIdToken },
        uniqueId,
        account: { userName },
      } = response
      linkMicrosoftAccount(rawIdToken, uniqueId, userName)
    }
  }

  return (
    <MicrosoftLogin
      authCallback={handleMicrosoftResponse}
      clientId={clientId}
      redirectUri={redirectUri}
      prompt="select_account"
      graphScopes={[
        'Calendars.ReadWrite',
        'offline_access',
        'MailboxSettings.Read',
      ]}
    />
  )
}

export default MicrosoftLoginButton
