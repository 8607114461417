/* eslint-disable no-unused-expressions */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useCurrentUser } from './CurrentUser'
import { useSocket } from './SocketProvider'

const ChatConversationProviderContext = createContext()
export function useChatConversationProvider() {
  return useContext(ChatConversationProviderContext)
}
export default function ChatConversationProvider({ children }) {
  const [chatConversation, setChatConversation] = useState([])
  const [filterdChatConversation, setFilteredChatConversation] = useState(null)
  const [searchChatConversationList, setSearchChatConversationList] =
    useState('')
  const [currentChatConversation, setCurrentChatConversation] = useState(null)
  const [notification, setNotification] = useState(0)

  const { currentUser } = useCurrentUser()
  const socket = useSocket()

  const joinRoom = useCallback(
    (rooms, currentRoom) => {
      if (socket)
        socket.emit(
          'client:join-room',
          {
            rooms,
          },
          () => {
            setCurrentChatConversation(currentRoom)
          },
        )
    },
    [socket],
  )

  const getConversationList = useCallback(
    (currentRoom) => {
      if (!currentUser.id) return
      socket?.emit(
        'client:get-conversation-list',
        { userId: currentUser?.id },
        (conversationList) => {
          if (conversationList.length) {
            const rooms = conversationList.map((room) => {
              const messages = room.messages.map((message) => ({
                ...message,
                fromMe: message.sender_id === currentUser?.id,
              }))

              const unreadmessage = room.messages.reduce((acc, message) => {
                const messagesUnRead = message.readers.reduce(
                  (acc2, reader) => {
                    if (!reader.read && reader.reader_id === currentUser.id) {
                      return (acc2 += 1)
                    }
                    return acc2
                  },
                  0,
                )
                return (acc += messagesUnRead)
              }, 0)

              const conversationUser = room.users.find(
                (user) => user.ur_id !== currentUser.id,
              )

              const receivers = room.users.filter(
                (user) => user.ur_id !== currentUser.id,
              )

              return {
                room: room.conversation_chat_id,
                avatar: conversationUser.ur_avatar_uri,
                name: conversationUser.ur_first,
                lasName: conversationUser.ur_last,
                messages: messages,
                messagesUnread: unreadmessage,
                receivers: receivers,
              }
            })

            setNotification((prev) =>
              rooms.reduce((acc, room) => acc + room.messagesUnread, prev),
            )
            setChatConversation(rooms)
            joinRoom(
              rooms.map((room) => room.room),
              rooms.find((r) => r.room === currentRoom),
            )
          }
        },
      )
    },
    [currentUser, joinRoom, socket],
  )

  const createChatRoom = useCallback(
    (withUsers, callback, chatConversations = []) => {
      const existConversation = chatConversations.filter(
        (room) =>
          room.receivers.length <= 2 &&
          room.receivers.some((user) => user.ur_id === withUsers.ur_id),
      )
      if (existConversation.length)
        setCurrentChatConversation(existConversation[0])
      else {
        const users = [currentUser, withUsers]
        setCurrentChatConversation({
          room: null,
          avatar: withUsers.ur_avatar_uri,
          name: withUsers.ur_first,
          lasName: withUsers.ur_last,
          messages: [],
          receivers: users,
        })
      }
      callback()
    },
    [currentUser],
  )

  const sendMessage = useCallback(
    (message) => {
      socket.emit(
        'client:send-message',
        {
          conversationChatId: currentChatConversation.room,
          message,
          senderId: currentUser.id,
          receiversId: currentChatConversation.receivers
            ?.filter((r) => r.ur_id)
            .map((r) => r.ur_id),
        },
        (response) => {
          const newConversationMsj = {
            ...currentChatConversation,
            room: response.conversation_chat_id,
            messages: [
              ...currentChatConversation.messages,
              { ...response, fromMe: true },
            ],
          }
          setCurrentChatConversation(newConversationMsj)
          setChatConversation((prevState) => {
            if (
              prevState?.find((r) => r.room === response.conversation_chat_id)
            ) {
              return prevState.map((room) => {
                if (room.room === response.conversation_chat_id) {
                  return {
                    ...room,
                    messages: [...room.messages, { ...response, fromMe: true }],
                  }
                } else {
                  return room
                }
              })
            } else {
              return prevState
                ? [...prevState, newConversationMsj]
                : [newConversationMsj]
            }
          })
        },
      )
    },
    [currentChatConversation, currentUser, socket],
  )

  const readMessages = useCallback(
    (chatRoom, cb) => {
      if (chatRoom) {
        const recentlyRead = chatRoom.messages.reduce((acc, message) => {
          const messagesUnRead = message.readers?.reduce((acc2, reader) => {
            if (!reader.read && reader.reader_id === currentUser.id) {
              return [...acc2, reader.id]
            } else return acc2
          }, [])

          return [...acc, ...messagesUnRead]
        }, [])

        if (recentlyRead.length)
          socket.emit('client:read-messages', { recentlyRead })
        cb()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socket],
  )

  useEffect(() => {
    if (socket) {
      socket.on('server:receive-message', (response) => {
        setNotification((prevState) => ++prevState)
        setChatConversation((prevState) => {
          const newChatConversation = [...prevState]
          const index = newChatConversation.findIndex(
            (room) => room.room === response.conversation_chat_id,
          )
          newChatConversation[index].messages.push(response)
          newChatConversation[index].messagesUnread += 1
          return newChatConversation
        })
      })
    }
  }, [socket])

  useEffect(() => {
    socket?.on('server:get-conversation-list', () => {
      getConversationList()
    })
  }, [socket, getConversationList])

  useEffect(() => {
    if (searchChatConversationList.length > 0) {
      const search = chatConversation?.filter((room) => {
        return room.name
          .toLowerCase()
          .includes(searchChatConversationList.toLowerCase())
      })
      setFilteredChatConversation(search)
    } else {
      setFilteredChatConversation(chatConversation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchChatConversationList])

  useEffect(() => {
    setFilteredChatConversation(chatConversation)
  }, [chatConversation])

  return (
    <ChatConversationProviderContext.Provider
      value={{
        chatConversation,
        setChatConversation,
        createChatRoom,
        currentChatConversation,
        joinRoom,
        sendMessage,
        notification,
        setNotification,
        setSearchChatConversationList,
        searchChatConversationList,
        filterdChatConversation,
        readMessages,
      }}
    >
      {children}
    </ChatConversationProviderContext.Provider>
  )
}
