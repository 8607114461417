import React, { useMemo } from 'react'
import CardContainer from '../../elemets/CardContainer'
import CardTitle from '../../elemets/CardTitle'

import { createUseStyles } from 'react-jss'
import { isMobile } from '../../utils/common'
import CustomTextArea from '../../elemets/CustomTextArea'
import ActionButton from '../../elemets/ActionButton'
import colors from '../../theme/theme2'
import Icon from '../Icons'
import compose from 'just-compose'
import { withRouter } from 'react-router-dom'

const useStyles = createUseStyles({
  title: {
    padding: 0,
  },
  container: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    minHeight: 310,
  },
  mobile: {
    paddingTop: 0,
    minHeight: 'unset',
  },
  icon: {
    '& i': {
      fontSize: 20,
      color: colors.primary,
    },
    position: 'absolute',
    left: 10,
  },
  iconText: {
    paddingLeft: 10,
  },
})

const GroupInfo = ({ name, info, isAdmin, id, history }) => {
  const classes = useStyles()

  const description = useMemo(() => {
    let value =
      'Welcome to your Lattitude group! You can navigate from here to view other members, see their interests, and schedule a conversation with them. Through our research and studies, we recommend that you schedule at least one conversation each month.'
    if (info && info !== '') {
      value = info
    }
    return value
  }, [info])

  const header = (
    <div className={`d-flex ${isMobile() ? 'justify-content-end mt-4' : ''}`}>
      {!isMobile() && (
        <div className={'d-flex justify-content-start w-50'}>
          <CardTitle className={classes.title} title={name} />
        </div>
      )}
      {isAdmin && (
        <div
          className={`d-flex justify-content-end ${!isMobile() ? 'w-50' : ''}`}
        >
          <ActionButton
            className={`${classes.button}`}
            type={'LIGHT_GREEN'}
            text={
              <>
                <div className={classes.icon}>
                  <Icon icon="fas fa-cog" />
                </div>
                <span className={classes.iconText}>GROUP SETTINGS</span>
              </>
            }
            onClick={() => {
              history.push(`/admin/groups/${id}`)
            }}
          />
        </div>
      )}
    </div>
  )

  return (
    <CardContainer
      className={`${classes.container} ${isMobile() ? classes.mobile : ''}`}
      header={header}
      headerDivider={!isMobile()}
    >
      <CustomTextArea value={description} editMode={false} />
    </CardContainer>
  )
}

export default compose(withRouter)(GroupInfo)
