import React, { useCallback } from 'react'
import DateField, { DateValue } from './DateField'
import { useConversationDetailsBuilder } from '../../builder/ConversationBuilder'
import { PartialConversation } from '../../builder/state'

const selectDate = (value: PartialConversation): DateValue | undefined => {
  const { date } = value
  if (typeof date !== 'number') {
    return undefined
  }

  const datestamp = new Date(date * 1000)
  const year = datestamp.getFullYear()
  const month = datestamp.getMonth() + 1
  const day = datestamp.getDate()
  return { year, month, day }
}

const SetDate = (): JSX.Element => {
  const { value, setDate } = useConversationDetailsBuilder()
  const date = selectDate(value)
  const handleChange = useCallback(
    ({ year, month, day }: DateValue) => {
      setDate(year, month, day)
    },
    [setDate],
  )

  return <DateField value={date} onChange={handleChange} />
}

export default SetDate
