import React, { useCallback, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import compose from 'just-compose'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

// Elements
import CardContainer from '../../elemets/CardContainer'
import SquareButton from '../../elemets/SquareButton'
import CardTitle from '../../elemets/CardTitle'
import CustomInput from '../../elemets/CustomInput'
import LinkButton from '../../elemets/LinkButton'
import Divider from '../../elemets/Divider'

// Components
import EducationModal from './EducationModal'

// Theme
import colors, { responsive } from '../../theme/theme2'

// Utils
import { isMobile } from '../../utils/common'

const useStyles = createUseStyles({
  educationRow: {
    marginBottom: 22,
  },
  edit: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addButton: {
    color: colors.primary,
    '&:hover, &:active, &:focus': {
      color: colors.primary,
    },
  },
  ...responsive,
})

const EducationCard = ({ user, reload }) => {
  const classes = useStyles()
  const [displayModal, setDisplayModal] = useState(false)
  const [selectedEducation, setSelectedEducation] = useState()

  const handleAddEducation = useCallback(() => {
    setSelectedEducation(undefined)
    setDisplayModal(true)
  }, [])

  const handleEdit = useCallback((education) => {
    setSelectedEducation(education)
    setDisplayModal(true)
  }, [])

  const onFinish = useCallback(() => {
    setDisplayModal(false)
    setSelectedEducation(undefined)
    reload()
  }, [reload])

  const handleCloseModal = useCallback(() => {
    setDisplayModal(false)
    setSelectedEducation(undefined)
  }, [])

  const EducationRow = useCallback(
    function ({ education, divider, dataTestId }) {
      if (!education) {
        return null
      }
      const startYear = education.startDate
        ? moment(education.startDate).utc().format('MM-YYYY')
        : ''
      const endYear = education.endDate
        ? moment(education.endDate).utc().format('MM-YYYY')
        : 'Present'
      const educationDisplayValue = startYear
        ? `${startYear} to ${endYear}`
        : 'Not Provided'
      return (
        <>
          <div className={classes.regular} data-testid={dataTestId}>
            <Row className={classes.educationRow}>
              <Col className="pr-0">
                <CustomInput
                  inputStyle="pr-0"
                  value={education.schoolName}
                  title="School"
                  editMode={false}
                />
              </Col>
              <Col className="pr-0">
                <CustomInput
                  inputStyle="pr-0"
                  value={education.major}
                  title="Vocation"
                  editMode={false}
                />
              </Col>
              <Col className="pr-0">
                <CustomInput
                  inputStyle="pr-0"
                  value={educationDisplayValue}
                  title="Years"
                  editMode={false}
                />
              </Col>
              <Col xs={2} className={classes.edit}>
                <SquareButton onClick={() => handleEdit(education)}>
                  <i className="material-icons">edit</i>
                </SquareButton>
              </Col>
            </Row>
          </div>
          <div className={classes.mobile}>
            <div className="d-flex">
              <Col xs={10} className="p-0">
                <CustomInput
                  inputStyle="pr-0"
                  value={education.schoolName}
                  title="School"
                  editMode={false}
                  containerStyle="mb-3"
                />
                <CustomInput
                  inputStyle="pr-0"
                  value={education.major}
                  title="Vocation"
                  editMode={false}
                  containerStyle="mb-3"
                />
                <CustomInput
                  inputStyle="pr-0"
                  value={educationDisplayValue}
                  title="Years"
                  editMode={false}
                  containerStyle="mb-3"
                />
              </Col>
              <Col xs={2}>
                <SquareButton onClick={() => handleEdit(education)}>
                  <i className="material-icons">edit</i>
                </SquareButton>
              </Col>
            </div>
          </div>
          {divider && <Divider />}
        </>
      )
    },
    [
      classes.edit,
      classes.educationRow,
      classes.mobile,
      classes.regular,
      handleEdit,
    ],
  )

  return (
    <>
      <EducationModal
        show={displayModal}
        education={selectedEducation}
        onClose={handleCloseModal}
        onFinish={onFinish}
        user={user}
        data-testid="myprofile-education-modal"
      />
      <CardContainer
        header={
          <div className="d-flex justify-content-between align-items-center">
            <CardTitle title="Education" />
            <LinkButton
              icon="las la-plus-circle"
              text="Add Education"
              className={classes.addButton}
              onClick={handleAddEducation}
              dataTestId="myprofile-addeducationbutton"
            />
          </div>
        }
        headerDivider={isMobile()}
        dataTestId="myprofile-education-cardcontainer"
      >
        {user?.education.length > 0 &&
          user.education?.map((education, index) => (
            <EducationRow
              key={education.id}
              education={education}
              divider={isMobile() && index < user.education.length - 1}
              dataTestId={`educationcard-${index}`}
            />
          ))}
      </CardContainer>
    </>
  )
}

export default compose(withRouter)(EducationCard)
