const colors = {
  primary: '#4EBA7E',
  primary100: 'rgba(78, 186, 126, 0.1)',
  primary700: 'rgba(78, 186, 126, 0.7)',
  greenDark: '#0d7b48',
  success: '#CDFF00',
  error: '#FE4164',
  error100: 'rgba(254, 65, 100, 0.1)',
  error700: 'rgba(254, 65, 100, 0.7)',
  alert: '#9ACD32',
  dark: '#343434',
  grey1: '#F3F3F2',
  grey2: '#C0C0BF',
  grey3: '#414A4C',
  grey4: '#333333',
  grey5: '#707575',
  grey6: '#989B9A',
  grey7: '#717171',
  grey8: '#6B6B6B',
  greyBorder: '#eceef5',
  greyParagraph: '#626566',
  whiteBackground: '#f8fafb',
  white: '#ffffff',
  red: '#de0029',
  red100: 'rgba(222, 0, 41, 0.1)',
  red700: 'rgba(222, 0, 41, 0.7)',
  redDark: '#a1001d',
  warningOrange: '#f5a623',
  black: '#000000',
  orange: '#DB5E12', // added as part of "meet now" feature
}

const calendarColors = {
  grey: { color: '#d8d8d8' },
  red: { color: '#f5511e' },
  yellow: { color: '#e4c442' },
  green: { color: '#c0cb33' },
  blue: { color: '#039ce5' },
  purple: { color: '#b39edb' },
  brown: { color: '#a79b8e' },
}

const headings = {
  callOut: {
    fontSize: 96,
    color: colors.primary,
  },
  h1: {
    fontWeight: 'bold',
    fontSize: 72,
    lineHeight: 80,
    fontColor: colors.dark,
  },
  h2: {
    fontWeight: 'bold',
    fontSize: 40,
    lineHeight: 44,
    fontColor: colors.dark,
  },
  subTitle: {
    fontWeight: 'semi-bold',
    fontSize: 24,
    lineHeight: 27,
    fontColor: colors.dark,
  },
  h3: {
    fontWeight: 'bold',
    fontSize: 20,
    fontColor: colors.dark,
  },
  paragraph: {
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 500,
    color: colors.greyParagraph,
  },
  title: {
    fontFamily: 'Lato',
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.dark,
  },
  text: {
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 500,
  },
}

const buttonStyles = {
  primary: {
    color: '#ffffff',
    borderRadius: '3px',
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    '&:hover, &:focused': {
      backgroundColor: 'white!important', //'#7BCC9F',
      color: 'black',
    },
  },
  secondary: {
    color: '#ffffff',
    borderRadius: '3px',
    backgroundColor: colors.dark,
    borderColor: colors.dark,
    '& :hover': {
      backgroundColor: 'white', //'#7BCC9F',
      color: 'black',
    },
  },
  notes: {
    backgroundColor: 'white',
    color: colors.primary,
    borderColor: colors.primary,
  },
  cancel: {
    backgroundColor: 'red',
    borderRadius: '3px',
    borderColor: 'red',
  },
  close: {
    alignItems: 'center',
    display: 'flex',
    padding: '3px',
    backgroundColor: 'transparent',
    border: 'none',
    color: colors.dark,
    '&:focus': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      color: colors.dark,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&:active': {
      color: `${colors.dark}`,
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
  roundButton: {
    alignItems: 'center',
    display: 'flex',
    padding: '3px',
    backgroundColor: 'transparent',
    border: 'none',
    color: colors.primary,
    '&:focus': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      color: colors.primary700,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&:active': {
      color: colors.primary100,
      backgroundColor: 'transparent !important',
    },
  },
}

const actionButtonStyles = {
  primary: {
    initial: colors.primary,
    hover: colors.primary,
    click: '#0d7b48',
    inactive: '#9ad5b2',
    text: '#ffffff',
  },
  secondary: {
    initial: colors.grey1,
    hover: '#edf8f2',
    click: '#a7e8c4',
    inactive: '#f9f9f8',
    text: '#0d7b48',
    inactiveText: '#9ad5b2',
  },
  red: {
    initial: '#d8013b',
    hover: '#d8013b',
    click: '#9e0016',
    inactive: '#f697a6',
    text: '#ffffff',
  },
  redSecondary: {
    initial: colors.grey1,
    hover: '#fdebee',
    click: '#ffc6d0',
    inactive: '#f9f9f8',
    text: '#d8013b',
    inactiveText: '#f697a6',
  },
  orange: {
    initial: colors.orange,
    hover: '#FF6E1F',
    click: '#A3470E',
    inactive: '#FFC19A',
    text: '#ffffff',
  },
  orangeSecondary: {
    initial: colors.grey1,
    hover: '#FFEBE2',
    click: '#FFA472',
    inactive: '#f9f9f8',
    text: colors.orange,
    inactiveText: '#FFC19A',
  },
}

const squareButtonStyles = {
  initial: {
    border: colors.greyBorder,
    icon: colors.primary,
    background: '#ffffff',
  },
  hover: {
    border: colors.primary,
    icon: colors.primary,
    background: '#edf8f2',
  },
  click: {
    border: '#0d7b48',
    icon: '#0d7b48',
    background: '#a7e8c4',
  },
}

const cardStyles = {
  conversation: {
    background: 'white',
    marginTop: 10,
    marginBottom: 10,
  },
  user: {
    background: 'white',
    marginBottom: 10,
    border: 'none',
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: '32px',
    paddingBottom: '32px',
  },
}

const font = {
  fontFamily: "'Nunito Sans', sans-serif",
}

const responsive = {
  mobile: {
    '@media (min-width: 599px)': {
      display: 'none',
    },
    '@media (max-width: 600px)': {
      // display: 'block',
    },
  },
  regular: {
    '@media (min-width: 599px)': {
      // display: 'block',
    },
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
}

export default colors
export {
  buttonStyles,
  calendarColors,
  actionButtonStyles,
  squareButtonStyles,
  headings,
  font,
  cardStyles,
  responsive,
}
