import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Typeaheads } from '../Forms/Input'
import { FilterIcon } from '../Icons'

class Filter extends React.Component {
  constructor(props) {
    super(props)
    this.setFilter = this.setFilter.bind(this)
    this.state = {
      showFilter: true,
      filter: {},
    }
  }

  setFilter(field, selected) {
    this.setState(
      prevState => {
        return {
          filter: {
            ...prevState.filter,
            [field]: selected,
          },
        }
      },
      () => this.props.onChange(this.state.filter),
    )
  }

  render() {
    const { filters = [] } = this.props
    const { showFilter } = this.state

    return (
      <Row style={{ paddingBottom: 20 }}>
        <Col md="auto">
          <FilterIcon
            style={{ paddingTop: 5 }}
            onClick={() => this.setState({ showFilter: !showFilter })}
          />
        </Col>
        {showFilter && (
          <>
            {filters.map(x => {
              return (
                <Col xs={12} lg={3} style={{ paddingBottom: 10 }}>
                  <Typeaheads
                    placeholder={x.placeholder}
                    options={x.options}
                    selected={this.state.filter[x.name]}
                    onChange={selected => this.setFilter(x.name, selected)}
                    clearButton={true}
                  />
                </Col>
              )
            })}
          </>
        )}
      </Row>
    )
  }
}

export default Filter
