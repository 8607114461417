import { useContext, useMemo } from 'react'
import {
  NotificationsContext,
  NotificationData,
  Notification,
} from './NotificationsProvider'

export type State = {
  notifications: Notification[]
}

type Callback = () => void

export type Actions = {
  clearNotification: (id: number) => void
  pushNotification: (data: NotificationData, callback?: Callback) => void
}

// This hook matches the signature of the original useNotifications hook, that used a
// use-global-state package. The underlying mechanism has since been refactored to use
// Context/Provider, but this hooks signature is maintained for backwards compatibility with
// existing code. New code should access the Notifications Context directly.
const useNotifications = (): [State, Actions] => {
  const { notifications, clearNotification, pushNotification } =
    useContext(NotificationsContext)
  const state = useMemo(() => ({ notifications }), [notifications])
  const actions = useMemo(
    () => ({ clearNotification, pushNotification }),
    [clearNotification, pushNotification],
  )
  return [state, actions]
}

export default useNotifications
export type { NotificationData }
