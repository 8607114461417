import React, { useEffect, useMemo } from 'react'
import Container from 'react-bootstrap/Container'
import GroupSlider from './GroupSlider'
import { Row, Col } from 'react-bootstrap'
import GroupFilters from '../Groups/GroupFilters'
import MatchesList from '../Groups/MatchesList'
import { createUseStyles } from 'react-jss'
import { isMobile } from '../../utils/common'
import Icon from '../Icons'
import colors, { headings } from '../../theme/theme2'
import compose from 'just-compose'
import { withErrorHandler } from '../../errors'
import { withRouter } from 'react-router-dom'
import LoaderPage from '../../elemets/LoaderPage'
import { useBrand } from '../../BrandHandler'
import { useCurrentUser } from '../Context/CurrentUser'
import { useGroupMembers } from '../Context/GroupMembers'

const useStyles = createUseStyles({
  mobile: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  title: {
    ...headings,
    fontSize: 20,
    paddingLeft: 16,
    margin: 0,
  },
  icon: {
    margin: -16,
    '& i': {
      marginTop: 7,
      '&:before': {
        color: colors.primary,
      },
    },
  },
})

const NetworkDashboard = ({ handleError, history }) => {
  const classes = useStyles()
  // STATES
  const { isBranded, brandInfo } = useBrand()
  const { currentUser, loading: loadingCurrentProfile } = useCurrentUser()
  const {
    error,
    setGroups,
    setPage,
    groupMembers,
    filterMembers,
    loading,
    hasMoreItems,
    setFilters,
    setSortField,
  } = useGroupMembers()

  // EFFECTS
  useEffect(() => {
    if (isBranded) {
      history.push(`/group/${brandInfo.id}/dashboard`)
    }
  }, [isBranded, brandInfo, history])

  const handleScroll = () => {
    if (
      Math.ceil(window?.innerHeight + document?.documentElement?.scrollTop) >=
        document?.documentElement?.offsetHeight * 0.75 &&
      hasMoreItems?.current
    ) {
      document.activeElement.blur()
      setPage()
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!loading && error) {
      hasMoreItems.current = false
      handleError(error, true, {
        message: 'Unable to fetch group list.',
      })
    }
  }, [loading, error, groupMembers, handleError, hasMoreItems])

  useEffect(() => {
    if (currentUser?.groups.length > 0) {
      setGroups(currentUser.groups.map((x) => x.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  //LOADING
  const isLoading = useMemo(
    () => loadingCurrentProfile || loading,
    [loading, loadingCurrentProfile],
  )

  return (
    <Container className={isMobile() ? classes.mobile : ''}>
      {isLoading && <LoaderPage />}
      {!isMobile() ? (
        <Row>
          <Col xs={8}>
            <GroupSlider groups={currentUser.groups} />
          </Col>
          <Col xs={4}>
            <GroupFilters
              matches={filterMembers}
              setFilters={setFilters}
              context={'Network'}
              title={
                <div
                  className={`${classes.icon} ${
                    isMobile() ? 'd-flex justify-content-center' : ''
                  }`}
                >
                  <Icon icon="fas fa-user" />
                  <label className={`${classes.title}`}>Network Search</label>
                </div>
              }
            />
          </Col>
        </Row>
      ) : (
        <Row noGutters>
          <Col xs={12}>
            <GroupSlider groups={currentUser.groups} />
          </Col>
          <Col xs={12} className="p-0">
            <GroupFilters
              matches={filterMembers}
              setFilters={setFilters}
              context={'Network'}
              title={
                <div
                  className={`${classes.icon} ${
                    isMobile() ? 'd-flex justify-content-center' : ''
                  }`}
                >
                  <Icon icon="fas fa-user" />
                  <label className={`${classes.title}`}>Network Search</label>
                </div>
              }
            />
          </Col>
        </Row>
      )}
      <MatchesList
        filteredMatches={groupMembers}
        showOrder={true}
        setSortField={setSortField}
      />
    </Container>
  )
}

export default compose(withErrorHandler, withRouter)(NetworkDashboard)
