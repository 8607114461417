import React, { useCallback, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import RequestAvailabilityModal from '../../components/Modals/Conversation/RequestAvailabilityModal'

type RequestAvailabilityProps = {
  availabilityToken: string
  skill: { id: number; name: string }
  disabled?: boolean
  className?: string
}

const RequestAvailability = ({
  availabilityToken,
  skill: { id: skillId, name: skillName },
  disabled = false,
  className,
}: RequestAvailabilityProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  return (
    <>
      <Dropdown.Item
        onClick={handleOpen}
        disabled={disabled}
        className={className}
      >
        Request availability
      </Dropdown.Item>
      <RequestAvailabilityModal
        show={open}
        match={{
          token: availabilityToken,
          topics: [{ skillId, skillName }],
        }}
        setRequestedAvailability={() => {
          /* noop - this is a holdover from the legacy implementation that isn't necessary here */
        }}
        onClose={handleClose}
      />
    </>
  )
}

export default RequestAvailability
