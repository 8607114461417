import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

import { useErrorHandler, withErrorHandler } from '../../errors'
import CardContainer from '../../elemets/CardContainer'
import { isMobile } from '../../utils/common'
import Icon from '../Icons'
import colors, { headings } from '../../theme/theme2'
import ActionButton from '../../elemets/ActionButton'
import LoaderPage from '../../elemets/LoaderPage'
import TopicCard from './TopicCard'
import TopicForm from './TopicForm'
import compose from 'just-compose'
import RoundButton from '../../elemets/RoundButton'
import TopicsModal from './TopicsModal'
import { useCurrentUser } from '../Context/CurrentUser'
import AddTopicModal from './AddTopicModal'

const useStyles = createUseStyles({
  title: {
    ...headings.title,
    fontSize: 20,
    paddingRight: 10,
  },
  icon: {
    '& i': {
      cursor: 'unset',
      '&:before': {
        fontSize: 20,
        color: colors.primary,
      },
    },
  },
  iconOrange: {
    '& i': {
      cursor: 'unset',
      '&:before': {
        fontSize: 20,
        color: '#f5a623',
      },
    },
  },
  textMain: {
    ...headings.title,
    fontSize: 16,
  },
  textSubtitle: {
    color: colors.grey2,
  },
  addButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 16,
  },
  addButtonMobile: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonPlus: {
    fontSize: 30,
  },
})

const TopicsDashboard = ({ location }) => {
  const classes = useStyles()
  const [addTopic, setAddTopic] = useState(false)
  const [addModal, showAddModal] = useState(false)
  const [displayModal, setDisplayModal] = useState(false)

  const { currentUser, loading } = useCurrentUser()

  const handleError = useErrorHandler()

  useEffect(() => {
    if (location && location.state && location.state.openModal) {
      setDisplayModal(true)
    }
  }, [location])

  if (!currentUser?.id) {
    return null
  }

  return (
    <>
      <AddTopicModal show={displayModal} setDisplayModal={setDisplayModal} />
      <div className={!isMobile() ? 'container' : ''}>
        <Row noGutters={isMobile()} className={`m-0`}>
          {addModal && (
            <TopicsModal
              onClose={() => {
                showAddModal(false)
              }}
              onSuggest={() => {
                showAddModal(false)
                setDisplayModal(true)
              }}
              handleError={handleError}
              show={addModal}
            />
          )}
          {loading && <LoaderPage />}
          <Col xs={12}>
            <CardContainer
              header={
                <>
                  <div className={`${classes.icon} ml-3`}>
                    <label className={`${classes.title}`}>
                      {addTopic ? 'Add Topics' : 'Topics of Interest'}
                    </label>
                    <Icon icon="fas fa-list-alt" />
                  </div>

                  {!addTopic &&
                    currentUser.topics &&
                    currentUser.topics.length > 0 && (
                      <div className={classes.addButton}>
                        <RoundButton
                          icon="fa fa-plus-circle"
                          onClick={() => {
                            isMobile() ? showAddModal(true) : setAddTopic(true)
                          }}
                          type="GREEN"
                          className={classes.buttonPlus}
                        />
                      </div>
                    )}
                </>
              }
              headerDivider={
                (currentUser.topics && currentUser.topics.length === 0) ||
                addTopic
              }
              className={
                (currentUser.topics && currentUser.topics.length === 0) ||
                addTopic
                  ? ''
                  : 'pb-0'
              }
            >
              {!addTopic &&
                currentUser.topics &&
                currentUser.topics.length === 0 && (
                  <div className={`d-flex justify-content-center m-4`}>
                    <div style={{ width: isMobile() ? '100%' : '40%' }}>
                      <div className={`d-flex`}>
                        <div className={`pt-2 ${classes.iconOrange}`}>
                          <Icon icon={'fas fa-exclamation-triangle'} />
                        </div>
                        <div className={`pl-3`}>
                          <p className={`${classes.textMain} mb-0`}>
                            You Have No Topics of Interest
                          </p>
                          <p className={classes.textSubtitle}>
                            Add some topics of interest and let other members of
                            your group know what you want to talk about.
                          </p>
                        </div>
                      </div>
                      <div className={`d-flex justify-content-end`}>
                        <ActionButton
                          text="ADD"
                          onClick={() => {
                            isMobile() ? showAddModal(true) : setAddTopic(true)
                          }}
                          type="STRONG_GREEN"
                        />
                      </div>
                    </div>
                  </div>
                )}
              {addTopic && <TopicForm setAddTopic={setAddTopic} />}
            </CardContainer>
          </Col>
        </Row>

        {currentUser.topics && currentUser.topics.length > 0 && (
          <Row noGutters={isMobile()} className={`m-0`}>
            {currentUser.topics.map((topic, index) => {
              return (
                <Col xs={12} md={6} key={topic.id}>
                  <TopicCard key={index} topic={topic} />
                </Col>
              )
            })}
          </Row>
        )}
      </div>
    </>
  )
}

export default compose(withErrorHandler)(TopicsDashboard)
