export default class ApiError extends Error {
  context: string

  req: Request

  res: Response

  constructor(context: string, req: Request, res: Response) {
    super()
    this.context = context
    this.req = req
    this.res = res
  }

  get message(): string {
    const { context, res: { statusText = 'unknown' } = {} } = this
    return `api operation failed: ${context}: ${statusText}`
  }

  set message(context: string) {
    this.context = context
  }

  wrap(context: string): ApiError {
    const { context: existingContext } = this
    this.context = `${context}: ${existingContext}`
    return this
  }
}
