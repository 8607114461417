import React from 'react'

import TabCardContainer from './TabCardContainer'
import NotesCard from './NotesCard'
import HistoryCard from './HistoryCard'

const NotesHistory = ({
  currentNote,
  setCurrentNote,
  notesHistory,
  conversationId,
  currentProfile,
  participant,
  notes,
  reload,
  show = false,
}) => {
  return notes && show ? (
    <>
      <TabCardContainer
        tabs={[
          {
            title: 'Notes',
            component: (
              <NotesCard
                currentNote={currentNote}
                setCurrentNote={setCurrentNote}
                conversationId={conversationId}
                currentProfile={currentProfile}
                participant={participant}
                notes={notes}
                reloadNotes={reload}
              />
            ),
          },
          notesHistory && notesHistory.length > 0
            ? {
                title: 'History',
                component: (
                  <HistoryCard participant={participant} notes={notesHistory} />
                ),
              }
            : null,
        ]}
      />
    </>
  ) : null
}

export default NotesHistory
