import { createChildLogger } from '@/logger/logger'

const logger = createChildLogger({ module: 'get-waf-token' })

const AwsWafIntegration = window.AwsWafIntegration

/* used to set the header `x-aws-waf-token` for dealing with "CHALLENGE" responses from AWS WAF */
export async function getWafToken(): Promise<string> {
  let wafToken = ''
  if (!AwsWafIntegration) return wafToken

  if (!AwsWafIntegration.hasToken()) {
    logger.debug('withWafToken: no token or token expired. retrieving...')
    wafToken = await AwsWafIntegration.getToken().catch((e) => {
      logger.error('Cannot fetch WafToken. Setting to empty string.', e)
      return ''
    })
  } else {
    // we know the current token is valid and can be immediately used
    wafToken = await AwsWafIntegration.getToken()
    logger.debug('withWafToken: token cached')
  }
  logger.debug('getWafToken: hasLength: ', wafToken.length)

  return wafToken
}
