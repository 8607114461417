import React from 'react'
import { Helmet } from 'react-helmet'
import { useTheme } from 'react-jss'

const BaselineStyles = () => {
  const theme = useTheme()
  return (
    <Helmet>
      <style>{`
      body {
        font-family: ${theme.typography.body.fontFamily};
        font-weight: ${theme.typography.body.fontWeight};
        font-size: ${theme.typography.fontSize}px;
        background-color: ${theme.palette.secondary.main};
      }
      h1 {
        font-family: ${theme.typography.h1.fontFamily};
        font-weight: ${theme.typography.h1.fontWeight};
        font-size: ${theme.typography.h1.fontSize};
        line-height: ${theme.typography.h1.lineHeight};
        letter-spacing: ${theme.typography.h1.letterSpacing};
      }
      h2 {
        font-family: ${theme.typography.h2.fontFamily};
        font-weight: ${theme.typography.h2.fontWeight};
        font-size: ${theme.typography.h2.fontSize};
        line-height: ${theme.typography.h2.lineHeight};
        letter-spacing: ${theme.typography.h2.letterSpacing};
      }
      h3 {
        font-family: ${theme.typography.h3.fontFamily};
        font-weight: ${theme.typography.h3.fontWeight};
        font-size: ${theme.typography.h3.fontSize};
        line-height: ${theme.typography.h3.lineHeight};
        letter-spacing: ${theme.typography.h3.letterSpacing};
      }
      h4 {
        font-family: ${theme.typography.h4.fontFamily};
        font-weight: ${theme.typography.h4.fontWeight};
        font-size: ${theme.typography.h4.fontSize};
        line-height: ${theme.typography.h4.lineHeight};
        letter-spacing: ${theme.typography.h4.letterSpacing};
      }
      h5 {
        font-family: ${theme.typography.h5.fontFamily};
        font-weight: ${theme.typography.h5.fontWeight};
        font-size: ${theme.typography.h5.fontSize};
        line-height: ${theme.typography.h5.lineHeight};
        letter-spacing: ${theme.typography.h5.letterSpacing};
      }
      h6 {
        font-family: ${theme.typography.h6.fontFamily};
        font-weight: ${theme.typography.h6.fontWeight};
        font-size: ${theme.typography.h6.fontSize};
        line-height: ${theme.typography.h6.lineHeight};
        letter-spacing: ${theme.typography.h6.letterSpacing};
      }
      .btn, .nav-link {
        font-family: ${theme.typography.button.fontFamily};
      }
      `}</style>
    </Helmet>
  )
}

export default BaselineStyles
