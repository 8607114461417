import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import { isMobile, isTablet } from 'react-device-detect'
import { useFeatureFlag } from 'configcat-react'
import colors from '../../theme/theme2'
import SquareButton from '../../elemets/SquareButton'

import { FeatureFlagDefaults, FeatureFlags } from '../../config/ConfigEnums'

const useStyles = createUseStyles({
  buttonGrey: {
    cursor: 'pointer',
    backgroundColor: colors.grey1,
    color: colors.greyParagraph,
    border: 'none',
    '&:hover,&:focus,&:active': {
      backgroundColor: `${colors.grey1} !important`,
      boxShadow: 'none',
      border: 'none !important',
      color: `${colors.greyParagraph} !important`,
    },
    '&:active': {
      backgroundColor: `${colors.grey4} !important`,
    },
  },
  buttonRed: {
    margin: '0 15px',
    backgroundColor: `${colors.red} !important`,
    border: 'none',
    '&:hover,&:focus,&:active': {
      backgroundColor: `${colors.red} !important`,
      boxShadow: 'none',
      border: 'none !important',
    },
    '&:active': {
      backgroundColor: `${colors.redDark} !important`,
    },
  },
  container: {
    position: 'absolute',
    bottom: 20,
    width: '100%',
  },
  settings: {
    padding: 0,
    backgroundColor: 'transparent !important',
    border: 'none',
    '&:hover,&:focus,&:active': {
      backgroundColor: 'transparent !important',
      boxShadow: 'none',
      border: 'none',
    },
  },
  dropdown: {
    '& .dropdown-toggle::after': {
      display: 'none !important',
    },
    '& .show .dropdown-toggle': {
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
  fontRed: {
    color: `${colors.red} !important`,
    '&:hover,&:focus,&:active': {
      color: `${colors.red} !important`,
    },
  },
  cog: {
    zIndex: 10,
  },
  marginLeft15: {
    marginLeft: 15,
  },
})

const VideoCallButtons = ({
  isMute,
  onMute,
  onEndCall,
  isCamOff,
  onCamOff,
  onFullScreen,
  onPip,
  onHandleSharScreen,
  isSharing,
  onExtendConversationEndTime,
  isExtendingConversation,
  endTime,
}) => {
  const { value: meetNowValue, loading: meetNowLoading } = useFeatureFlag(
    FeatureFlags.meetNow,
    FeatureFlagDefaults.meetNow,
  )
  const [showMeetNow, setShowMeetNow] = useState()

  const classes = useStyles()

  useEffect(() => {
    if (!meetNowLoading) {
      setShowMeetNow(meetNowValue)
    }
  }, [meetNowLoading, meetNowValue])

  return (
    <div className={`d-flex ${classes.container}`}>
      <Col xs={2} className={`d-flex justify-content-start ${classes.cog}`}>
        {/* <SquareButton className={classes.buttonGrey}>
          <span className="material-icons">chat</span>
        </SquareButton> */}
        <SquareButton className={classes.buttonGrey} onClick={onPip}>
          <span className="material-icons">picture_in_picture</span>
        </SquareButton>
      </Col>
      <Col>
        <Row noGutters className="justify-content-center">
          <SquareButton
            className={`${classes.buttonGrey} ${isMute ? classes.fontRed : ''}`}
            onClick={onMute}
          >
            {isMute ? (
              <span className="material-icons">mic_off</span>
            ) : (
              <span className="material-icons">mic</span>
            )}
          </SquareButton>
          <SquareButton
            className={classes.buttonRed}
            isActive
            onClick={onEndCall}
          >
            <span className="material-icons">call_end</span>
          </SquareButton>
          <SquareButton
            className={`${classes.buttonGrey} ${
              isCamOff ? classes.fontRed : ''
            }`}
            onClick={onCamOff}
          >
            {isCamOff ? (
              <span className="material-icons">videocam_off</span>
            ) : (
              <span className="material-icons">videocam</span>
            )}
          </SquareButton>
          {!isMobile && !isTablet && (
            <SquareButton
              className={`${classes.buttonGrey} ${classes.marginLeft15} ${
                isSharing ? classes.fontRed : ''
              }`}
              onClick={onHandleSharScreen}
            >
              <span className="material-icons">screen_share</span>
            </SquareButton>
          )}
          {showMeetNow && (
            <SquareButton
              className={`${classes.buttonGrey} ${classes.marginLeft15}`}
              disabled={isExtendingConversation}
              onClick={() => onExtendConversationEndTime(endTime)}
            >
              <span className="material-icons">update</span>
            </SquareButton>
          )}
        </Row>
      </Col>
      <Col xs={2} className={`d-flex justify-content-end ${classes.cog}`}>
        <SquareButton
          className={`${classes.buttonGrey}`}
          onClick={onFullScreen}
        >
          <span className="material-icons">fullscreen</span>
        </SquareButton>
      </Col>
    </div>
  )
}

export default VideoCallButtons
