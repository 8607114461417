import React, { useCallback, useEffect, useState } from 'react'
import CardContainer from '../../elemets/CardContainer'
import CardTitle from '../../elemets/CardTitle'
import ActionButton from '../../elemets/ActionButton'
import { Button, Col, Collapse, Row } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import colors, { headings } from '../../theme/theme2'
import { useApiSubmit } from '../../api'
import { withNotifications } from '../../notifications'
import compose from 'just-compose'
import { useErrorHandler, withErrorHandler } from '../../errors'
import moment from 'moment'
import CustomSelect from '../../elemets/CustomSelect'
import Icon from '../Icons'
import ArrowLeft from '../Icons/ArrowLeft'
import ArrowRight from '../Icons/ArrowRight'
import RequestAvailabilityModal from '../Modals/Conversation/RequestAvailabilityModal'

const useStyles = createUseStyles({
  scheduleButton: {
    marginBottom: 10,
    minWidth: 97,
    '& span': {
      fontSize: 11,
    },
  },
  scheduledButton: {
    '&:disabled': {
      backgroundColor: `${colors.primary}!important`,
      opacity: '1!important',
    },
  },
  message: {
    ...headings.paragraph,
    fontWeight: 'bold',
  },
  arrows: {
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
      color: [colors.primary700, '!important'],
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },

  icon: {
    '& i': {
      '&:before': {
        color: colors.primary,
      },
    },
  },
})

const Slot = ({ availability, state, setSelected }) => {
  const classes = useStyles()

  const handleSelect = useCallback(() => {
    if (state === 'SELECTED') {
      setSelected(undefined)
    } else {
      setSelected(availability)
    }
  }, [state, setSelected, availability])

  return (
    <ActionButton
      height="34px"
      text={availability.label.replace(' to ', '-')}
      onClick={handleSelect}
      type={`${state === 'SELECTED' ? 'STRONG_GREEN' : 'LIGHT_GREEN'}`}
      className={`text-nowrap px-1 ${classes.scheduleButton}`}
      disabled={state === 'SCHEDULED'}
    />
  )
}

const Availability = ({ timeSlot, profile, topic, pushNotification }) => {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)
  const [scheduled, setScheduled] = useState([])
  const [selectedAvailability, setSelectedAvailability] = useState()
  const [nav, setNav] = useState(0)
  const { handleError } = useErrorHandler()

  const {
    submitted,
    submit: scheduleConversation,
    err,
    submitting,
  } = useApiSubmit('scheduleConversation')

  const handleSchedule = useCallback(() => {
    if (selectedAvailability) {
      if (profile?.token) {
        const query = {
          token: profile.token,
        }
        const body = {
          skillId: parseInt(topic.value, 10),
          startTime: moment(selectedAvailability.availability.startTime).utc(),
        }
        scheduleConversation({ query, body })
      }
    }
  }, [selectedAvailability, profile.token, topic.value, scheduleConversation])

  useEffect(() => {
    if (submitted && !submitting && !err) {
      pushNotification({
        level: 'success',
        subject: 'Conversation Scheduled.',
        timeout: 10,
      })
      setScheduled([...scheduled, selectedAvailability.availability.id])
      setSelectedAvailability(undefined)
    } else if (!submitting && err) {
      handleError(err, true, {
        message: 'Unable to request scheduled a conversation.',
      })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, submitting, err])

  return (
    <Row className="align-items-center ">
      <Col
        xs={10}
        className={`px-4`}
        onClick={() => {
          setExpand(!expand)
        }}
        style={{ cursor: 'pointer' }}
      >
        <strong>{moment(timeSlot.day).format('dddd')}</strong>
        {moment(timeSlot.day).format(' MMM DD, YYYY')}
      </Col>
      <Col xs={2} className={`d-flex justify-content-end p-0 pt-2 pr-3`}>
        <Button
          className={`${classes.arrows}`}
          onClick={() => {
            setExpand(!expand)
          }}
        >
          <span>
            <i
              className={`fas ${expand ? 'fa-chevron-up' : 'fa-chevron-down'}`}
            />
          </span>
        </Button>
      </Col>
      <Collapse in={expand}>
        <Col xs={12}>
          <Row className={`px-3`}>
            {timeSlot.availabilities
              .slice(nav, nav + (timeSlot.availabilities.length > 9 ? 6 : 9))
              .map((availability, index) => {
                let state = scheduled.includes(availability.availability.id)
                  ? 'SCHEDULED'
                  : ''
                if (state !== 'SCHEDULED' && selectedAvailability) {
                  state =
                    selectedAvailability.availability.id ===
                    availability.availability.id
                      ? 'SELECTED'
                      : ''
                }
                return (
                  <Col
                    key={index}
                    xs={4}
                    md={6}
                    xl={4}
                    className={`p-0 d-flex justify-content-center`}
                  >
                    <Slot
                      availability={availability}
                      state={state}
                      setSelected={setSelectedAvailability}
                    />
                  </Col>
                )
              })}
          </Row>
          {timeSlot.availabilities.length > 9 && (
            <Row className={`px-3`}>
              <Col
                xs={4}
                md={6}
                xl={4}
                className={`p-0 d-flex justify-content-center`}
              >
                <ActionButton
                  height="34px"
                  text={
                    <>
                      <ArrowLeft />
                      <span className={`ml-1`}>PREVIOUS</span>
                    </>
                  }
                  onClick={() => {
                    setNav(nav - 6)
                  }}
                  type="LIGHT_GREEN"
                  className={`text-nowrap w-25 ${classes.scheduleButton}`}
                  disabled={nav <= 0}
                />
              </Col>
              <Col
                xs={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 0 }}
                xl={{ span: 4, offset: 4 }}
                className={`p-0 d-flex justify-content-center`}
              >
                <ActionButton
                  height="34px"
                  text={
                    <>
                      <span className={`mr-1`}>NEXT</span>
                      <ArrowRight />
                    </>
                  }
                  onClick={() => {
                    setNav(nav + 6)
                  }}
                  type="LIGHT_GREEN"
                  className={`text-nowrap w-25 ${classes.scheduleButton}`}
                  disabled={nav >= timeSlot.availabilities.length - 6}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12} className={`d-flex justify-content-center`}>
              <ActionButton
                height="34px"
                text={'SCHEDULE CONVERSATION'}
                onClick={handleSchedule}
                type="STRONG_GREEN"
                className="text-nowrap w-100"
                disabled={!selectedAvailability || topic === 0}
              />
            </Col>
          </Row>
        </Col>
      </Collapse>
    </Row>
  )
}

const AvailabilityInfo = ({ profile, availabilities, pushNotification }) => {
  const classes = useStyles()
  const [requested, setRequested] = useState(false)
  const [showRequestAvailability, setShowRequestAvailability] = useState(false)
  const [topic, setTopic] = useState(0)
  const [pagination, setPagination] = useState(0)
  const [upperLimit, setUpperLimit] = useState(0)
  const [flagDemo, setFlagDemo] = useState(false)
  const [groupedAvailability, setGroupAvailability] = useState([])

  useEffect(() => {
    if (groupedAvailability.length > 0) {
      setUpperLimit(Math.ceil(groupedAvailability.length / 4))
    }
  }, [groupedAvailability])

  useEffect(() => {
    if (availabilities && availabilities.length > 0) {
      let availabilitiesDays = []
      availabilities.forEach((availability) => {
        availabilitiesDays.push(
          moment(availability.startTime).format('dddd, MMM DD, YYYY'),
        )
      })
      availabilitiesDays = availabilitiesDays.filter(
        (item, index) => availabilitiesDays.indexOf(item) === index,
      )
      setGroupAvailability(
        availabilitiesDays.map((day) => {
          return {
            day: day,
            availabilities: availabilities
              .filter(
                (av) =>
                  moment(av.startTime).format('dddd, MMM DD, YYYY') === day,
              )
              .map((av) => {
                return {
                  label: `${moment(av.startTime).format('hh:mm')} to ${moment(
                    av.endTime,
                  ).format('hh:mm A')}`,
                  value: av.startTime,
                  availability: av,
                }
              }),
          }
        }),
      )
    }
  }, [availabilities])

  const handleSelect = useCallback((key, value) => {
    setFlagDemo(true)
    setTopic(value)
  }, [])

  if (!profile) {
    return null
  }

  return (
    <CardContainer
      header={<CardTitle title={'Availability'} />}
      headerDivider={true}
    >
      {showRequestAvailability && (
        <RequestAvailabilityModal
          setRequestedAvailability={setRequested}
          show={showRequestAvailability}
          match={profile}
          onClose={() => {
            setShowRequestAvailability(false)
          }}
        />
      )}
      <Row>
        <Col xs={12} className={`pb-3`}>
          {'1. SELECT TOPIC'}
          <CustomSelect
            options={
              profile?.topics.map((topic) => {
                return { value: topic.skillId, label: topic.skillName }
              }) || []
            }
            className={classes.select}
            value={topic}
            onSelect={handleSelect}
            placeholder={'Select Topic to see Availability'}
            onClick={() => (setFlagDemo.useState = true)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={`pb-3`}>
          {'2. SELECT DATE & TIME'}
        </Col>
      </Row>
      {flagDemo && (
        <>
          {groupedAvailability
            .slice(pagination * 4, pagination * 4 + 4)
            .map((availability, index) => {
              return (
                <Availability
                  key={index}
                  timeSlot={availability}
                  topic={topic}
                  profile={profile}
                  pushNotification={pushNotification}
                />
              )
            })}
          {groupedAvailability?.length > 4 && (
            <Row noGutters className={`pt-2 pb-2 pr-3`}>
              <Col xs={12} className={'d-flex justify-content-center'}>
                <div className={`d-flex ${classes.icon}`}>
                  <div className={classes.arrow}>
                    {pagination !== 0 && (
                      <Icon
                        icon={'fas fa-chevron-left'}
                        onClick={() => setPagination((s) => s - 1)}
                      />
                    )}
                  </div>
                  <span
                    className={'pr-2 pl-2'}
                    style={{ color: colors.primary }}
                  >
                    {pagination + 1}/{upperLimit}
                  </span>
                  <div className={classes.arrow}>
                    {pagination + 1 < upperLimit && (
                      <Icon
                        icon={'fas fa-chevron-right'}
                        onClick={() => setPagination((s) => s + 1)}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          )}
          {groupedAvailability.length === 0 && (
            <Row>
              <Col xs={12} className={`d-flex justify-content-center`}>
                <p className={`${classes.message} p-3`}>
                  {' '}
                  Sorry, the profile doesn't have any availabilities at the
                  moment{' '}
                </p>
              </Col>
              <Col xs={12} className={`d-flex justify-content-center`}>
                <ActionButton
                  height="34px"
                  text={
                    !requested
                      ? 'REQUEST AVAILABILITY'
                      : 'AVAILABILITY REQUESTED'
                  }
                  onClick={() => {
                    setShowRequestAvailability(true)
                  }}
                  type="STRONG_GREEN"
                  className="text-nowrap"
                  disabled={requested || topic === 0}
                />
              </Col>
            </Row>
          )}{' '}
        </>
      )}
    </CardContainer>
  )
}

export default compose(withNotifications, withErrorHandler)(AvailabilityInfo)
