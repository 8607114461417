import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Switch,
  Route,
  Redirect,
  Link,
  useRouteMatch,
  useHistory,
  useParams,
} from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import compose from 'just-compose'

import { withErrorHandler } from '../../../errors'
import { withRouter } from 'react-router-dom'
import LoaderPage from '../../../elemets/LoaderPage'
import GroupBanner from '../../Groups/GroupBanner'
import { Container, Row, Col } from 'react-bootstrap'
import CardContainer from '../../../elemets/CardContainer'
import CardTitle from '../../../elemets/CardTitle'
import Icon from '../../Icons'
import colors, { headings } from '../../../theme/theme2'
import { isMobile } from '../../../utils/common'
import GroupDetails from './Information/GroupDetails'
import GroupUserAdministration from './Users/GroupUserAdministration'
import Reporting from './Reporting/Reporting'
// import Billing from './Billing/Billing'
import { useCurrentUser } from '../../../components/Context/CurrentUser'

const useStyles = createUseStyles({
  ul: {
    padding: 0,
    listStyleType: 'none',
    cursor: 'pointer',
  },
  li: {
    ...headings.paragraph,
    fontWeight: 'bold',
    color: colors.dark,
    margin: 15,
    cursor: 'pointer',
    '& i': {
      '&:before': {
        color: colors.primary,
      },
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  containers: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
  },
  mainContent: {
    paddingBottom: 275,
  },
  icon: {
    '& i': {
      '&:before': {
        color: colors.primary,
      },
    },
  },
  arrowContainer: {
    position: 'relative',
  },
  arrows: {
    position: 'absolute',
    zIndex: 10,
    cursor: 'auto',
    top: 19,
    '&:before': {
      fontSize: 20,
      color: colors.primary,
    },
    '&.fa-chevron-left': {
      left: 10,
    },
    '&.fa-chevron-right': {
      right: 10,
    },
  },
})

const AdminGroupDashboard = ({ match, history }) => {
  const classes = useStyles()
  const { path, url } = useRouteMatch()
  const [group, setGroup] = useState({})
  const [firstLoad, setFirstLoad] = useState(true)
  const [loadingImages, setLoadingImages] = useState(false)
  const [activeTab, setActiveTab] = useState('Permission')
  const { currentUser: userProfile, loading: loadingCurrentProfile } =
    useCurrentUser()

  useEffect(() => {
    if (firstLoad && userProfile?.id) {
      setFirstLoad(false)
    }
  }, [userProfile, firstLoad])

  //EFFECTS
  useEffect(() => {
    const groupId = Number(match.params.id)
    if (userProfile?.id) {
      const group = userProfile.groups.find((x) => x.id === groupId)
      if (!group || !group.isAdmin) history.push('/unauthorized')
      setGroup(group)
    }
  }, [history, match.params.id, userProfile])

  //LOADING
  const isLoading = useMemo(
    () => firstLoad && loadingCurrentProfile && loadingImages,
    [firstLoad, loadingCurrentProfile, loadingImages],
  )

  const changeActiveTab = useCallback(
    (direction) => {
      const tabs = ['Permission', 'Details', 'Billing', 'Reporting']
      const currentTabIndex = tabs.indexOf(activeTab)
      const nextTab =
        currentTabIndex + direction < 0
          ? tabs.length - 1
          : currentTabIndex + direction >= tabs.length
            ? 0
            : currentTabIndex + direction
      setActiveTab(tabs[nextTab])
    },
    [activeTab],
  )

  return (
    <Container className={isMobile() ? 'p-0' : ''}>
      {isLoading && <LoaderPage />}
      <GroupBanner group={group} />
      {isMobile() && (
        <div className={classes.arrowContainer}>
          <Icon
            className={`fas fa-chevron-left ${classes.arrows}`}
            onClick={() => {
              changeActiveTab(-1)
            }}
          />
          <Icon
            className={`fas fa-chevron-right ${classes.arrows}`}
            onClick={() => {
              changeActiveTab(1)
            }}
          />
        </div>
      )}
      <Row noGutters={isMobile()}>
        {!isMobile() && (
          <Col xs={4}>
            <CardContainer
              className={classes.containers}
              headerDivider={true}
              header={<CardTitle title={'Group Administration'} />}
            >
              <ul className={classes.ul}>
                <Link className={classes.link} to={`${url}/permission`}>
                  <li className={classes.li}>
                    <Icon icon="fas fa-user" />{' '}
                    <span className="ml-2">Account Settings</span>
                  </li>
                </Link>
                <Link className={classes.link} to={`${url}/details`}>
                  <li className={classes.li}>
                    <Icon icon="fas fa-globe" />
                    <span className="ml-2">Group Information</span>
                  </li>
                </Link>

                {/*<li>
                  <Icon icon="fas fa-bell" />{' '}
                  <span className="ml-2">Notifications</span>
                </li>*/}
                {/*<Link className={classes.link} to={`${url}/billing`}>*/}
                {/*  <li className={classes.li}>*/}
                {/*    <Icon icon="fas fa-cog" />*/}
                {/*    <span className="ml-2">Billing and Account</span>*/}
                {/*  </li>*/}
                {/*</Link>*/}
                <Link className={classes.link} to={`${url}/reporting`}>
                  <li className={classes.li}>
                    <Icon icon="fas fa-chart-bar" />
                    <span className="ml-2">Reporting</span>
                  </li>
                </Link>
              </ul>
            </CardContainer>
          </Col>
        )}
        <Col xs={!isMobile() ? 8 : 12} className={classes.mainContent}>
          <Switch>
            <Route path={`${path}/permission`}>
              <GroupUserAdministration group={group} />
            </Route>
            <Route path={`${path}/details`}>
              <GroupDetails group={group} loading={setLoadingImages} />
            </Route>
            {/*<Route path={`${path}/billing`}>*/}
            {/*  <Billing group={group} />*/}
            {/*</Route>*/}
            <Route path={`${path}/reporting`}>
              <Reporting group={group} />
            </Route>
            <Redirect to={`${path}/permission`} />
          </Switch>
        </Col>
      </Row>
    </Container>
  )
}

export default compose(withErrorHandler, withRouter)(AdminGroupDashboard)
