import React, { ChangeEvent, useCallback } from 'react'
import FormControl from 'react-bootstrap/FormControl'

export interface DateValue {
  year: number
  month: number
  day: number
}

const toDateInputValue = (date: DateValue | undefined): string => {
  if (!date) {
    return ''
  }

  const { year, month, day } = date
  return [year, month, day]
    .map((val: number): string => String(val))
    .map((val: string): string => {
      if (val.length < 2) {
        return `0${val}`
      }
      return val
    })
    .join('-')
}

const setDate = (value: string): DateValue => {
  const [year, month, day] = value
    .split('-', 3)
    .map((part: string): number => Number.parseInt(part, 10))
  return { year, month, day }
}

type DateControlProps = {
  labelId: string
  value?: DateValue
  max?: DateValue
  onChange: (value: DateValue) => void
}

const DateControl = ({
  labelId,
  value,
  max,
  onChange,
}: DateControlProps): JSX.Element => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value: newValue },
      } = event
      onChange(setDate(newValue))
    },
    [onChange],
  )

  return (
    <FormControl
      type="date"
      value={toDateInputValue(value)}
      max={toDateInputValue(max)}
      aria-labelledby={labelId}
      onChange={handleChange}
    />
  )
}

export default DateControl
