import React, { useRef, useCallback, useState, useMemo, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'

import compose from 'just-compose'
import { createUseStyles } from 'react-jss'

// Components
import CardContainer from '../../elemets/CardContainer'
import ActionButton from '../../elemets/ActionButton'
import CustomSelect from '../../elemets/CustomSelect'
import AddAvailabilityModal from './AddAvailabilityModal'

// Error
import { withErrorHandler } from '../../errors'

// Theme
import colors, { headings } from '../../theme/theme2'
import ShowMoreModal from './ShowMoreModal'

const useStyles = createUseStyles({
  viewSelector: {
    minWidth: 105,
  },
  headerDate: {
    ...headings.title,
    margin: '0 21px 0 30px',
    minWidth: 130,
  },
  arrowContainer: {
    padding: '0 7px',
    cursor: 'pointer',
    color: colors.primary,
  },
  headerDayNumber: {
    fontFamily: 'Lato',
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.dark,
    margin: 0,
    width: 36,
    height: 36,
    paddingTop: 2,
  },
  headerCurrentDayNumber: {
    fontFamily: 'Lato',
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white',
    margin: 0,
    width: 36,
    height: 36,
    paddingTop: 2,
    borderRadius: 18,
    backgroundColor: colors.primary,
  },
  headerDayName: {
    fontFamily: 'Lato',
    fontSize: 10,
    color: colors.dark,
    margin: 0,
  },
  lattusCard: {
    backgroundColor: colors.primary,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textBold: {
    fontWeight: 'bold',
  },
  lattusText: {
    marginBottom: 0,
    color: 'white',
    fontSize: 12,
    fontFamily: 'Lato',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  calendarCard: {
    backgroundColor: colors.grey1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  calendarText: {
    marginBottom: 0,
    color: colors.grey3,
    fontSize: 12,
    fontFamily: 'Lato',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  availabilityCard: {
    backgroundColor: colors.primary100,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  availabilityText: {
    marginBottom: 0,
    color: colors.primary,
    fontSize: 12,
    fontFamily: 'Lato',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  lightGreenDot: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: '#9ad5b2',
    marginRight: 4,
  },
  strongGreenDot: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: colors.primary,
    marginRight: 4,
  },
  greyDot: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: '#d8d8d8',
    marginRight: 4,
  },
  weekItem: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  weekItemText: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: colors.grey3,
    marginBottom: 0,
  },
})

const viewOptions = [
  { value: 'day', label: 'Day' },
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' },
]
const GridCalendar = React.memo(
  ({ eventList, reload, setCurrentDate, defaultDate }) => {
    const localizer = momentLocalizer(moment)
    const classes = useStyles()
    const toolbarRef = useRef(null)
    const [calendarState, setCalendarState] = useState({
      showing: { value: 'week', label: 'Week' },
      selectedDate: new Date(),
      monthShowing: moment(),
    })
    const [displayModal, setDisplayModal] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState()
    const [showMoreModal, setShowMoreModal] = useState(false)
    const [moreModal, setMoreModal] = useState({
      currentDay: moment().format(),
      availabilityList: [],
    })

    useEffect(() => {
      if (defaultDate) {
        setCalendarState((s) => ({ ...s, selectedDate: defaultDate }))
      }
    }, [defaultDate])
    // Anchor the time to 8:00am
    useEffect(() => {
      const x = document.getElementsByClassName('rbc-time-content')[0]
      x.scrollTop = 440
    }, [])

    useEffect(() => {
      if (
        calendarState.showing.value === 'week' ||
        calendarState.showing.value === 'day'
      ) {
        const x = document.getElementsByClassName('rbc-time-content')[0]
        x.scrollTop = 440
      }
    }, [calendarState])

    const eventListToDisplay = useMemo(() => {
      let result = []
      if (eventList && eventList.length > 0) {
        result = eventList.map((item) => ({
          id: item.id,
          title: item.title,
          start: new Date(item.startTime),
          end: new Date(item.endTime),
          type: item.type,
          recurringAvailabilityId: item.recurringAvailabilityId,
        }))
      }
      return result
    }, [eventList])

    const handleChangeView = useCallback(
      (key, value) => {
        setCurrentDate(new Date())
        setCalendarState((c) => ({
          ...c,
          [key]: value,
          selectedDate: new Date(),
          monthShowing: moment(),
        }))
      },
      [setCurrentDate],
    )

    const goToToday = useCallback(() => {
      setCurrentDate(new Date())
      setCalendarState((c) => ({ ...c, selectedDate: new Date() }))
      toolbarRef.current.onNavigate('TODAY')
    }, [toolbarRef, setCurrentDate])

    const handlePrev = useCallback(() => {
      const nextState = { ...calendarState }
      nextState.monthShowing = calendarState.monthShowing.subtract(
        1,
        toolbarRef.current.view,
      )
      nextState.selectedDate = nextState.monthShowing
        .startOf(toolbarRef.current.view)
        .toDate()
      setCurrentDate(nextState.selectedDate)
      setCalendarState(nextState)
      toolbarRef.current.onNavigate('PREV')
    }, [toolbarRef, calendarState, setCurrentDate])

    const handleNext = useCallback(() => {
      const nextState = { ...calendarState }
      nextState.monthShowing = calendarState.monthShowing.add(
        1,
        toolbarRef.current.view,
      )
      nextState.selectedDate = nextState.monthShowing
        .startOf(toolbarRef.current.view)
        .toDate()
      setCurrentDate(nextState.selectedDate)
      setCalendarState(nextState)
      toolbarRef.current.onNavigate('NEXT')
    }, [toolbarRef, calendarState, setCurrentDate])

    const handleAddAvailability = useCallback((slot) => {
      setSelectedEvent({
        startTime: slot.start,
        endTime:
          slot.start === slot.end
            ? new Date(slot.end.getTime() + 30 * 60000)
            : slot.end,
      })
      setDisplayModal(true)
    }, [])

    const handleCloseAddAvailability = useCallback(() => {
      setSelectedEvent(undefined)
      setDisplayModal(false)
    }, [])

    const getCustomToolbar = useCallback(
      (toolbar) => {
        toolbarRef.current = toolbar

        return (
          <Row
            noGutters
            className="justify-content-between align-items-center mb-4"
          >
            <div className="d-flex align-items-center">
              <ActionButton
                text="TODAY"
                type="LIGHT_GREEN"
                onClick={goToToday}
              />
              <label className={classes.headerDate}>
                {calendarState.showing.value === 'day'
                  ? moment(calendarState.selectedDate).format('MMMM DD, YYYY')
                  : moment(calendarState.selectedDate).format('MMMM YYYY')}
              </label>
              <div className={classes.arrowContainer}>
                <i className={`fas fa-chevron-left`} onClick={handlePrev} />
              </div>
              <div className={classes.arrowContainer}>
                <i className={`fas fa-chevron-right`} onClick={handleNext} />
              </div>
            </div>

            <CustomSelect
              options={viewOptions}
              value={calendarState.showing}
              onSelect={handleChangeView}
              name="showing"
              className={classes.viewSelector}
            />
          </Row>
        )
      },
      [
        goToToday,
        classes,
        calendarState,
        handlePrev,
        handleNext,
        handleChangeView,
      ],
    )

    const getWeekCustomHeader = useCallback(
      (header) => {
        const label = header.label.split(' ')

        const isCurrent =
          moment().format('YYYY-DD-MM') ===
          moment(header.date).format('YYYY-DD-MM')
        return (
          <Col className="text-center mb-2">
            <p className={classes.headerDayName}>{label[1]}</p>
            <label
              className={
                isCurrent
                  ? classes.headerCurrentDayNumber
                  : classes.headerDayNumber
              }
            >
              {label[0]}
            </label>
          </Col>
        )
      },
      [classes],
    )

    const handleEditEvent = useCallback((event) => {
      setSelectedEvent({
        id: event.id,
        startTime: event.start,
        endTime: event.end,
        isRecurring: event.recurringAvailabilityId,
      })
      setDisplayModal(true)
    }, [])

    const getWeekCustomEvent = useCallback(
      (event) => {
        let eventColor = {
          card: classes.calendarCard,
          text: classes.calendarText,
        }
        switch (event.event.type) {
          case 'availability':
            eventColor = {
              card: classes.availabilityCard,
              text: classes.availabilityText,
            }
            break
          case 'conversation':
            eventColor = { card: classes.lattusCard, text: classes.lattusText }
            break
          case 'calendar':
            eventColor = {
              card: classes.calendarCard,
              text: classes.calendarText,
            }
            break
          default:
            break
        }

        return (
          <div
            className={`p-1 ${eventColor.card}`}
            onClick={
              event.event.type === 'availability'
                ? () => handleEditEvent(event.event)
                : () => {}
            }
          >
            <span className={eventColor.text}>
              <b>{event.title}</b>
              <br />
              {moment(event.event.start).format('h:mma')} -{' '}
              {moment(event.event.end).format('h:mma')}
            </span>
          </div>
        )
      },
      [
        classes.availabilityCard,
        classes.availabilityText,
        classes.calendarCard,
        classes.calendarText,
        classes.lattusCard,
        classes.lattusText,
        handleEditEvent,
      ],
    )

    const getMonthCustomEvent = useCallback(
      (event) => {
        let coloredDot = {
          card: classes.calendarCard,
          text: classes.calendarText,
        }
        switch (event.event.type) {
          case 'availability':
            coloredDot = classes.lightGreenDot
            break
          case 'conversation':
            coloredDot = classes.strongGreenDot
            break
          case 'calendar':
            coloredDot = classes.greyDot
            break
          default:
            break
        }

        return (
          <div
            className={classes.weekItem}
            onClick={
              event.event.type === 'availability'
                ? () => handleEditEvent(event.event)
                : () => {}
            }
          >
            <div className={coloredDot} />
            <label className={classes.weekItemText}>
              {moment(event.event.start).format('Ha')} {event.title}
            </label>
          </div>
        )
      },
      [
        classes.calendarCard,
        classes.calendarText,
        classes.greyDot,
        classes.lightGreenDot,
        classes.strongGreenDot,
        classes.weekItem,
        classes.weekItemText,
        handleEditEvent,
      ],
    )

    return (
      <>
        <AddAvailabilityModal
          show={displayModal}
          onClose={handleCloseAddAvailability}
          event={selectedEvent}
          reload={reload}
        />
        <ShowMoreModal
          show={showMoreModal}
          onClose={() => setShowMoreModal(false)}
          currentDay={moreModal.currentDay}
          availabilityList={moreModal.availabilityList}
        />
        <CardContainer>
          <Calendar
            localizer={localizer}
            events={eventListToDisplay}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 750 }}
            toolbar={true}
            date={calendarState.selectedDate}
            defaultDate={calendarState.selectedDate}
            onNavigate={(date) => {
              calendarState.selectedDate = date
            }}
            components={{
              toolbar: getCustomToolbar,
              day: {
                event: getWeekCustomEvent,
              },
              week: {
                header: getWeekCustomHeader,
                event: getWeekCustomEvent,
              },
              month: {
                event: getMonthCustomEvent,
              },
            }}
            views={{ month: true, week: true, day: true }}
            view={calendarState.showing.value}
            onView={handleChangeView}
            selectable
            onSelectSlot={handleAddAvailability}
            allDayAccessor={false}
            onShowMore={(events, date) => {
              setMoreModal({ currentDay: date, availabilityList: events })
              setShowMoreModal(!showMoreModal)
            }}
          />
        </CardContainer>
      </>
    )
  },
)

export default compose(withErrorHandler)(GridCalendar)
