import { invokeOperationWithAuth } from '../../../../../../api'
import { getAccessToken } from '../../../../../../auth0'

interface GroupMemberData {
  profileId: number
  firstName: string
  lastName: string
}

type GetGroupMembersResponse = GroupMemberData[]

const getGroupMembers = async function* (
  groupId: number,
  signal?: AbortSignal,
): AsyncGenerator<GetGroupMembersResponse, void> {
  let page = 1

  do {
    try {
      const res = await invokeOperationWithAuth(getAccessToken)(
        'getGroupMembers',
        {
          params: { groupId },
          query: { page },
          signal,
        },
      )

      const members = res as GetGroupMembersResponse
      if (members.length < 1) {
        break
      }

      yield members
      page += 1
    } catch (err) {
      // there is no way to determine the total number of pages from this particular API operation.
      // therefore, we keep looping. the service 500's when there are no more pages.
      // so, we'll assume that an error just meas there are no more pages.
      // this is not a great assumption, but it's what we have to work with.
      break
    }
  } while (page < Number.MAX_SAFE_INTEGER)
}

export default getGroupMembers

export const getAllGroupMembers = async (
  groupId: number,
  signal?: AbortSignal,
): Promise<GroupMemberData[]> => {
  let allGroupMembers: GroupMemberData[] = []

  for await (const page of getGroupMembers(groupId, signal)) {
    allGroupMembers = [...allGroupMembers, ...page]
    if (signal?.aborted) {
      break
    }
  }

  return allGroupMembers
}
