import React from 'react'
import Toast from 'react-bootstrap/Toast'
import Moment from 'react-moment'
import { createUseStyles } from 'react-jss'
import clsx from 'clsx'
import { LattusTheme } from '../../theme'

export type NotificationData = {
  id: number
  subject?: string
  created?: Date
  message?: string
  timeout?: number
  level?: 'info' | 'success' | 'warning' | 'error'
}

type NotificationProps = NotificationData & {
  onClose: () => void
}

const headerStyle = ({
  contrastText: color,
  light: backgroundColor,
  dark: borderColor,
}: {
  contrastText: string
  light: string
  dark: string
}) => ({ color, backgroundColor, borderColor })

const useStyles = createUseStyles<string, unknown, LattusTheme>(theme => ({
  root: {
    zIndex: theme.zIndex.toast,
    width: '100%',
    maxHeight: `calc(100vh - 180px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  datestamp: {
    marginBottom: 0,
  },
  message: {
    marginTop: 0,
  },
  info: headerStyle(theme.palette.info),
  success: headerStyle(theme.palette.success),
  warning: headerStyle(theme.palette.warning),
  error: headerStyle(theme.palette.error),
}))

const Notification = ({
  subject,
  created,
  message,
  onClose,
  timeout = 0,
  level = 'info',
}: NotificationProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Toast
      className={classes.root}
      show={true}
      onClose={onClose}
      autohide={(timeout || 0) > 0}
      delay={(timeout || 0) * 1000}
    >
      <Toast.Header className={classes[level]}>
        <strong className={clsx('mr-auto', classes.title)}>
          {subject || 'Notification'}
        </strong>
      </Toast.Header>
      {message && (
        <Toast.Body>
          {message && <p className={classes.message}>{message}</p>}
          {created && (
            <p className={classes.datestamp}>
              <small>
                <Moment fromNow>{created.valueOf()}</Moment>
              </small>
            </p>
          )}
        </Toast.Body>
      )}
    </Toast>
  )
}

Notification.defaultProps = {
  timeout: 0,
  level: 'info',
}

export default Notification
