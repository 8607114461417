import React from 'react'
import { createUseStyles } from 'react-jss'
import compose from 'just-compose'

import CardContainer from '../../../elemets/CardContainer'
import { isMobile } from '../../../utils/common'
import colors, { headings } from '../../../theme/theme2'
import Icon from '../../Icons'

import { withErrorHandler } from '../../../errors'
import { withNotifications } from '../../../notifications'
import ScheduleButton from './ScheduleButton'
import GravatarContainer from '../../Users/GravatarContainer'
import MeetNowButton from '../../Conversations/MeetNow/MeetNowButton'

const useStyles = createUseStyles({
  matchContainer: {
    textAlign: 'center',
    margin: 20,
  },
  personContainer: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    minHeight: 122,
    width: 122,
  },
  matchName: {
    ...headings.text,
    fontWeight: 'bold',
    fontSize: 16,
    color: colors.dark,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  matchInfo: {
    fontWeight: 'normal',
    fontSize: 12,
    color: colors.greyParagraph,
  },
  circleName: {
    fontWeight: 'bold!important',
    fontSize: '24px!important',
    margin: '10px 16%',
  },
  greenClock: {
    color: colors.primary,
    '& .icon': {
      color: colors.primary,
    },
  },
  greyClock: {
    color: colors.grey2,
    '& .icon': {
      color: colors.grey2,
    },
  },
  clockContainer: {
    position: 'absolute',
    top: 10,
    left: 10,
    '& i': {
      cursor: 'default!important',
    },
  },
  hamburgerContainer: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
})

const MatchCards = ({
  profileTopics,
  match = {},
  reloadUpcoming,
  setDetails,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.matchContainer}>
      <CardContainer
        className={`${classes.personContainer} ${
          isMobile() ? classes.mobile : ''
        } mb-0`}
      >
        {match.availabilities > 0 ? (
          <div className={`${classes.clockContainer} ${classes.greenClock}`}>
            <Icon icon={'far fa-clock'} />
          </div>
        ) : (
          <div className={`${classes.clockContainer} ${classes.greenClock}`} />
        )}
        <div className={`${classes.hamburgerContainer} ${classes.greenClock}`}>
          <Icon
            icon={'fas fa-bars'}
            onClick={() => setDetails({ show: true, match: match })}
          />
        </div>
        <div className={`d-flex justify-content-center w-100`}>
          <GravatarContainer profile={match} size={60} enableName={false} />
        </div>
        <div>
          <p className={`mt-0 mb-0 ${classes.matchName}`}>{match.firstName}</p>
          <p className={`mt-0 mb-0 ${classes.matchInfo}`}>{match.lastName}</p>
        </div>
      </CardContainer>
      <br />
      <ScheduleButton
        profileTopics={profileTopics}
        match={match}
        reloadUpcoming={reloadUpcoming}
      />
      <div className="mt-2"></div>
      <MeetNowButton match={match} showIcon reloadUpcoming={reloadUpcoming} />
    </div>
  )
}

export default compose(withErrorHandler, withNotifications)(MatchCards)
