import React, { useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import colors from '../../theme/theme2'

const RequestAvailability = props => {
  const handleRequesteAvailability = useCallback(() => {
    props.history.push(props.to, {
      params: {
        user: props.user,
      },
    })
  }, [props])

  return (
    <div
      style={{
        float: 'right',
        paddingLeft: 5,
        color: colors.primary,
        cursor: 'pointer',
      }}
      onClick={handleRequesteAvailability}
    >
      View Availability
    </div>
  )
}

export default withRouter(RequestAvailability)
