import { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { ApiError, UnauthenticatedError } from './errors'
import setParams from './params'
import toQuerystring from './querystring'
import { useAuth0 } from '../auth0'
import { BaseUri } from '../config/Config'
import loadingConfig from '../config/init'
import {
  errored,
  loaded,
  loading,
  useFetchState,
  Status,
  InvokeOptions,
} from './reducer'
import { getWafToken } from '@/api/get-waf-token'
import { useWafToken } from '@/components/Context/WafTokenProvider'
import { createChildLogger } from '@/logger/logger'

const logger = createChildLogger({
  module: 'api',
})
type GetAccessToken = () => Promise<string>

type Operation = { type?: string; href: string }

type OperationMap = Map<string, Operation>

type OperationLink = Operation & { rel: string }

// TODO break FetchResult into separate sub-types where err and data don't have to be optional.
// (Maintining this structure for now prevents having to update every component that uses this code.)
export type FetchResult<Data, Query> = {
  ready: boolean
  loading: boolean
  err?: Error
  data?: Data
  reload: () => void
  fetch: (options?: InvokeOptions<Query>) => void
}

// TODO break SubmitResult into separate sub-types where err and result don't have to be optional.
// (Maintaining this structure for now prevents having to update every component that uses this code.)
export type SubmitResult<Output, Input> = {
  ready: boolean
  submitting: boolean
  submitted: boolean
  err?: Error
  result?: Output
  submit: (options?: InvokeOptions<Input>) => void
}

const withWafToken = async <Input>(options: InvokeOptions<Input> = {}) => {
  const headers = new Headers((options || {}).headers || {})
  const token = await getWafToken()

  if (token) {
    headers.set('x-aws-waf-token', token)
  } else {
    logger.error('Failed to retrieve WAF token')
  }
  return { ...options, headers }
}

const withToken =
  <Input>(getAccessToken: GetAccessToken) =>
  async (options: InvokeOptions<Input> = {}) => {
    const token = await getAccessToken()
    const headers = new Headers((options || {}).headers || {})
    headers.set('Authorization', `Bearer ${token}`)
    return { ...options, headers }
  }

const apiFactory = (
  baseUriKey: keyof BaseUri,
  { version = 'v1' }: { version?: string } = {},
) => {
  const gettingBaseUri = loadingConfig.then(
    ({ baseUri: { [baseUriKey]: baseUri } }) => baseUri,
  )
  const invoke = async <Input>(
    endpoint: string,
    options: InvokeOptions<Input> = {},
  ): Promise<Response> => {
    const { headers, query = {}, params = {}, body } = options || {}
    const baseUri = await gettingBaseUri

    const host = endpoint.startsWith('http') ? '' : baseUri
    const path = setParams(endpoint, params)
    const uri = `${host}${path}${toQuerystring(query)}`
    const reqHeaders = new Headers(headers || {})
    let reqBody

    if (body) {
      reqBody = typeof body !== 'string' ? JSON.stringify(body) : body
      reqHeaders.set('Content-Type', 'application/json')
    }

    if (endpoint === '/v1/status' || endpoint === '/spec') {
      reqHeaders.set('Accept', 'application/json')
    }
    const token = await getWafToken()
    if (token) {
      reqHeaders.set('x-aws-waf-token', token)
    }

    let reqOptions = {
      ...options,
      headers: reqHeaders,
    }

    if (reqBody) {
      reqOptions = { ...reqOptions, body: reqBody }
      logger.debug('Request Body:', {
        reqBody,
        endpoint,
      })
    }

    const req = new Request(uri, reqOptions)
    const res = await fetch(req)

    if (!res.ok) {
      if (res.status === 401) {
        if (res.redirected) {
          // some browsers (e.g., Safari) do not include the Authorization header in
          // the redirect, even when the redirect does not change domains.
          // this brute-force workaround retries the failed request.
          return invoke(res.url, reqOptions)
        }
        throw new UnauthenticatedError(endpoint)
      }
      throw new ApiError(endpoint, req, res)
    }

    return res
  }

  const statusPr = invoke(`/${version}/status`).then((res) => res.json())
  const availablePr = statusPr.then((body) => {
    const { service = 'error' } = body
    return service === 'ok'
  })
  const operationsPr = statusPr.then(
    (body: { links: OperationLink[] }): OperationMap => {
      const { links = [] } = body
      return links.reduce(
        (
          mapping: OperationMap,
          { rel, href, type }: OperationLink,
        ): OperationMap => mapping.set(rel, { href, type }),
        new Map(),
      )
    },
  )
  const specPr = invoke('/spec').then((res) => res.json())
  const getOperation = async (name: string) => {
    const operations = await operationsPr
    if (operations.has(name)) {
      return operations.get(name)
    }
    const versioned = `${name}${version.toUpperCase()}`
    if (operations.has(versioned)) {
      return operations.get(versioned)
    }
    throw new Error(`invalid operation: ${name}`)
  }
  const needsAuth = async ({
    type = 'get',
    href,
  }: {
    type?: string
    href: string
  }) => {
    const { paths = {} } = await specPr
    const methods = paths[href] || {}
    const operation = methods[type] || {}
    const { security = [] } = operation
    return security.length > 0
  }

  const invokeOperation = async (
    operationName: string,
    options: InvokeOptions<unknown> = {},
  ) => {
    const available = await availablePr
    if (!available) throw new Error(`api is not available`)

    const operation = (await getOperation(operationName)) || {}
    const { type = 'get', href } = operation as { type: string; href: string }
    if (!href) {
      throw new Error(`invalid endpoint configuration: ${operationName}`)
    }

    const res = await invoke(href, {
      method: type.toUpperCase(),
      ...options,
    })

    const contentLength = Number.parseInt(
      res.headers.get('Content-Length') || '0',
      10,
    )
    if (contentLength < 0) {
      return null
    }

    const contentType = res.headers.get('Content-Type')
    if (!contentType || !contentType.includes('application/json')) {
      return res.text()
    }

    return res.json()
  }

  const apiFetch = async <Query>(
    operationName: string,
    getTokenSilently: () => Promise<string>,
    options: InvokeOptions<Query> = {},
  ) => {
    const operation = await getOperation(operationName)
    if (!operation) {
      throw new Error(
        `attempted to invoke unknown API operation: ${operationName}`,
      )
    }

    const needsToken = await needsAuth(operation)
    const wrapOptions = needsToken
      ? withToken(getTokenSilently)
      : (options: InvokeOptions<Query>) => options
    const authOptions = await wrapOptions(options)
    const wafOptions = await withWafToken({ ...authOptions })

    return invokeOperation(operationName, wafOptions)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- The untyped query parameter value is a holdover from pre-TS code.
  const useFetch = <Data, Query = any>(
    operationName: string,
    operationOptions: InvokeOptions<Query> = {},
    operationDefaultExecute = true,
  ): FetchResult<Data, Query> => {
    const {
      isAuthenticated,
      getTokenSilently,
      loading: authLoading,
    } = useAuth0()
    const abortController = useRef(new AbortController())
    const authReady = useMemo(
      () => isAuthenticated && !authLoading,
      [isAuthenticated, authLoading],
    )
    const [state, dispatch] = useFetchState<Data, Query>(operationOptions)
    const [queue, setQueue] = useState<Array<InvokeOptions<Query>>>([])
    let { wafToken } = useWafToken()
    if (!wafToken) {
      wafToken = ''
    }

    const doFetch = useMemo(
      () =>
        (options: InvokeOptions<Query> = {}) => {
          if (state.status === Status.Loading) {
            // already loading, don't try to load again
            return
          }

          if (options.headers && wafToken) {
            options.headers.set('x-aws-waf-token', wafToken)
          } else if (wafToken) {
            options.headers = new Headers({ 'x-aws-waf-token': wafToken })
            options.headers.set('Accept', 'application/json')
          } else {
            logger.warn('WAF token not available')
          }
          if (options.body && options.headers) {
            options.headers.set('Content-Type', 'application/json')
          }
          dispatch(loading(options))
          apiFetch(operationName, getTokenSilently, {
            signal: abortController.current.signal,
            ...options,
          })
            .then((data) => {
              if (abortController.current.signal.aborted) {
                return
              }

              dispatch(loaded(data as Data))
            })
            .catch((err) => {
              if (abortController.current.signal.aborted) {
                return
              }

              if (err instanceof Error && err.name !== 'AbortError') {
                dispatch(errored(err))
              }
            })
        },
      [
        wafToken,
        state.status,
        operationName,
        getTokenSilently,
        dispatch,
        abortController,
      ],
    )

    const reload = useCallback(() => {
      setQueue((last) => [...last, state.options || {}])
    }, [state.options])

    const fetch = useCallback(
      (options: InvokeOptions<Query> = state.options ?? {}) => {
        setQueue((last) => [...last, options])
      },
      [state.options],
    )

    useEffect(() => {
      let active = true
      // Trigger invoking the API automatically on mount if operationDefaultExecute is true.
      // The state.count check prevents doing this multiple times. The state.count value is
      // incremented on each fetch.
      if (operationDefaultExecute && state.count < 1 && authReady) {
        if (active) {
          setQueue((last) => [...last, operationOptions])
        }
      }
      return () => {
        active = false
      }
    }, [operationDefaultExecute, operationOptions, state.count, authReady])

    useEffect(() => {
      if (!authReady || state.status === Status.Loading || queue.length < 1) {
        return
      }

      const [next] = queue
      doFetch(next)
      setQueue((last) => last.slice(1))
    }, [authReady, queue, state.status, doFetch])

    useEffect(() => () => abortController.current.abort(), [])

    const data = state.status === Status.Loaded ? state.data : undefined
    const err = state.status === Status.Error ? state.error : undefined
    return {
      ready: authReady,
      loading: state.status === Status.Loading || !authReady,
      data,
      err,
      reload,
      fetch,
    }
  }

  /* This is just a "use" handler, it does not execute the query itself. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- The untyped query parameter value is a holdover from pre-TS code.
  const useSubmit = <Output, Input = any>(
    operationName: string,
  ): SubmitResult<Output, Input> => {
    const {
      isAuthenticated,
      getTokenSilently,
      loading: authLoading,
    } = useAuth0()
    const abortController = useRef(new AbortController())
    const authReady = useMemo(
      () => isAuthenticated && !authLoading,
      [isAuthenticated, authLoading],
    )
    const [state, dispatch] = useFetchState<Output, Input>()
    const [queue, setQueue] = useState<Array<InvokeOptions<Input>>>([])
    const { wafToken } = useWafToken()

    const doFetch = useMemo(
      () =>
        (options: InvokeOptions<Input> = {}) => {
          if (state.status === Status.Loading) {
            // already loading, don't try to load again
            return () => {}
          }

          if (!wafToken) {
            return () => {}
          }
          if (options.headers) {
            options.headers.set('x-aws-waf-token', wafToken)
            options.headers.set('Accept', 'application/json')
          } else {
            options.headers = new Headers({ 'x-aws-waf-token': wafToken })
            options.headers.set('Accept', 'application/json')
          }

          if (options.body) {
            options.headers.set('Content-Type', 'application/json')
          }

          dispatch(loading(options))
          apiFetch(operationName, getTokenSilently, {
            signal: abortController.current.signal,
            ...options,
          })
            .then((data) => {
              dispatch(loaded(data as Output))
            })
            .catch((err) => {
              if (err instanceof Error && err.name !== 'AbortError') {
                dispatch(errored(err))
              }
            })
        },
      [state.status, operationName, getTokenSilently, dispatch, wafToken],
    )

    const submit = useCallback((options: InvokeOptions<Input> = {}) => {
      setQueue((last) => [...last, options])
    }, [])

    useEffect(() => {
      if (!authReady || state.status === Status.Loading || queue.length < 1) {
        return
      }

      const [next] = queue
      doFetch(next)
      setQueue((last) => last.slice(1))
    }, [authReady, queue, state.status, doFetch])

    useEffect(() => () => abortController.current.abort(), [])

    const result = state.status === Status.Loaded ? state.data : undefined
    const err = state.status === Status.Error ? state.error : undefined
    return {
      ready: authReady,
      submitting: state.status === Status.Loading || queue.length > 0,
      submitted: state.status === Status.Loaded && queue.length === 0,
      result,
      err,
      submit,
    }
  }

  return {
    invoke,
    invokeOperation,
    fetchHook: useFetch,
    submitHook: useSubmit,
    operations: operationsPr,
  }
}

const api = apiFactory('lattusApi')

/**
 * React hook that fetchs data from the API.
 */
export const useApiFetch = api.fetchHook

/**
 * React hook that submits data to the API.
 */
export const useApiSubmit = api.submitHook

/**
 * Low-level function to invoke an operation on the API, usually from outside of a React hook.
 */
export const invokeOperation = api.invokeOperation

/**
 * Low-level function to invoke an operation on the API with an access token.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- The untyped query parameter value is a holdover from pre-TS code.
export const invokeOperationWithAuth = <Query = any>(
  getAccessToken: GetAccessToken,
): ((
  operationName: string,
  options?: InvokeOptions<Query>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Promise<any>) => {
  const wrapOptions = withToken(getAccessToken)
  return async (
    operationName: string,
    options: InvokeOptions<Query> = {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    const authOptions = await wrapOptions(options)
    return invokeOperation(operationName, authOptions)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- The untyped query parameter value is a holdover from pre-TS code.
export const useApi = <Query = any>(): {
  invoke: (
    operationName: string,
    options?: InvokeOptions<Query>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<any>
} => {
  const { getTokenSilently } = useAuth0()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const invoke = useCallback(invokeOperationWithAuth(getTokenSilently), [
    getTokenSilently,
  ])
  return { invoke }
}

/**
 * Promise that resolves to an array of all operations available on the API.
 */
export const operations = api.operations
