import React, { ChangeEvent, useCallback } from 'react'
import FormControl from 'react-bootstrap/FormControl'

type TextControlProps = {
  labelId: string
  value?: string
  maxLength?: number
  onChange: (newValue: string) => void
}

const TextControl = ({
  labelId,
  value,
  maxLength,
  onChange,
}: TextControlProps): JSX.Element => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const {
        currentTarget: { value: newValue },
      } = event
      onChange(newValue)
    },
    [onChange],
  )

  return (
    <FormControl
      as="textarea"
      value={value}
      onChange={handleChange}
      maxLength={maxLength}
      aria-labelledby={labelId}
    />
  )
}

export default TextControl
