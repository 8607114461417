import React from 'react'
import GroupItemControl from './GroupItemControl'

export interface Group {
  id: number
  name: string
}

interface GroupSelectorControlProps {
  groups: Group[]
  onSelect: (group: Group) => void
}

const GroupSelectorControl = ({
  groups,
  onSelect,
}: GroupSelectorControlProps): JSX.Element => (
  <>
    {groups.map(
      (group: Group): React.ReactNode => (
        <GroupItemControl
          key={group.id}
          name={group.name}
          onClick={() => onSelect(group)}
        />
      ),
    )}
  </>
)

export default GroupSelectorControl
