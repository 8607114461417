import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import PublicProfile from '../PublicProfile/PublicProfile'
import { useApiFetch } from '../../api'
import Modal from '../../elemets/Modal'
import { isMobile } from 'react-device-detect'
import LoaderPage from '../../elemets/LoaderPage'
import { useErrorHandler } from '@/errors'

const useStyles = createUseStyles({
  modal: {
    padding: '0!important',
  },
  dialogMobile: {
    margin: 0,
    maxWidth: 'unset!important',
    '& .modal-content': {
      borderRadius: 0,
      '& > div': {
        padding: isMobile ? 0 : 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white!important',
    padding: isMobile ? 0 : 'inherit',
  },
})

const PublicProfileModal = ({ show, profileId, onClose }) => {
  const classes = useStyles()
  const handleError = useErrorHandler()

  const {
    data: profile,
    loading: loadingProfile,
    fetch: fetchProfile,
    err: profileErr,
  } = useApiFetch('getProfileV1', {}, false)

  const {
    data: matchAvailability,
    loading: loadingMatchAvailability,
    err: errorMatchAvailability,
    fetch: listMatchAvailability,
  } = useApiFetch('listMatchAvailabilityV1', {}, false)

  useEffect(() => {
    if (!show) return
    if (!loadingMatchAvailability && errorMatchAvailability) {
      handleError(errorMatchAvailability, true, {
        message: 'Error fetching availabilities',
        level: 'error',
        subject: 'Profile',
      })
    }
  }, [show, errorMatchAvailability, loadingMatchAvailability, handleError])

  useEffect(() => {
    if (!show) return
    if (profileId) {
      fetchProfile({ params: { profileId } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, show])

  useEffect(() => {
    if (!show) return
    if (loadingProfile || profileErr) return
    if (profileErr) {
      handleError(profileErr, true, {
        message: 'Error fetching profile',
        level: 'error',
        subject: 'Profile',
      })
    } else if (profile) {
      listMatchAvailability({ query: { token: profile.token } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, profileErr, profileId, show])

  return (
    <>
      {loadingProfile || loadingMatchAvailability || !show ? (
        <LoaderPage />
      ) : null}
      <Modal
        close={onClose}
        show={!!(show && profile && matchAvailability)}
        closeIcon={true}
        closeOutside={false}
        className={classes.modal}
        dialogClassName={classes.dialogMobile}
        bodyClass={classes.modalBody}
      >
        <PublicProfile
          profile={profile}
          profileAvailability={matchAvailability}
        />
      </Modal>
    </>
  )
}

export default PublicProfileModal
