import React from 'react'
import ActionButton from '../../../elemets/ActionButton'
import { Col, Row } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import { isMobile } from 'react-device-detect'

const useStyles = createUseStyles({
  action: {
    padding: '8px 15px!important',
  },
})

const LeftPanelTabs = ({ tab, setTabs, show = false }) => {
  const classes = useStyles()
  return show ? (
    <Row noGutters>
      <Col
        xs={12}
        className={`${
          isMobile ? 'pl-2 pr-2' : ''
        } d-flex justify-content-between mb-3`}
      >
        <ActionButton
          text="Agenda"
          onClick={() => {
            setTabs('0')
          }}
          type={tab === '0' ? 'STRONG_GREEN' : 'LIGHT_GREEN'}
        />
        <ActionButton
          text="Action Items"
          onClick={() => {
            setTabs('1')
          }}
          type={tab === '1' ? 'STRONG_GREEN' : 'LIGHT_GREEN'}
          className={classes.action}
        />
        <ActionButton
          text="Notes"
          onClick={() => {
            setTabs('2')
          }}
          type={tab === '2' ? 'STRONG_GREEN' : 'LIGHT_GREEN'}
        />
      </Col>
    </Row>
  ) : null
}

export default LeftPanelTabs
