import React from 'react'
import {
  ConfigCatProvider,
  createConsoleLogger,
  LogLevel,
} from 'configcat-react'
import useConfig from './config/useConfig'

export const ConfigCat = ({ children }) => {
  const { configCat } = useConfig()
  let logger
  const hostname = window.location.hostname
  if (hostname.includes('localhost') || hostname.includes('app.dev')) {
    logger = createConsoleLogger(LogLevel.Info)
  }

  return (
    <ConfigCatProvider sdkKey={configCat.sdkKey} options={{ logger }}>
      {children}
    </ConfigCatProvider>
  )
}
