import React, { ChangeEvent, useCallback } from 'react'
import FormControl from 'react-bootstrap/FormControl'

export interface TimeValue {
  hours: number
  minutes: number
}

const toTimeInputValue = (time: TimeValue | undefined): string => {
  if (!time) {
    return ''
  }

  const { hours, minutes } = time
  if (Number.isNaN(hours)) {
    return ''
  }

  if (Number.isNaN(minutes)) {
    return ''
  }

  return [hours, minutes]
    .map((val: number): string => String(val))
    .map((val: string): string => {
      if (val.length < 2) {
        return `0${val}`
      }
      return val
    })
    .join(':')
}

const setTime = (value: string): TimeValue => {
  const [hours, minutes] = value
    .split(':', 2)
    .map((part: string): number => Number.parseInt(part, 10))
  return { hours, minutes }
}

type TimeControlProps = {
  labelId: string
  value?: TimeValue
  onChange: (value: TimeValue) => void
}

const TimeControl = ({
  labelId,
  value,
  onChange,
}: TimeControlProps): JSX.Element => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value: newValue },
      } = event
      onChange(setTime(newValue))
    },
    [onChange],
  )

  return (
    <FormControl
      type="time"
      value={toTimeInputValue(value)}
      aria-labelledby={labelId}
      onChange={handleChange}
    />
  )
}

export default TimeControl
