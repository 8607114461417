import React, { CSSProperties, ReactNode } from 'react'

import colors from '../theme/theme2'

type LoaderProps = {
  size?: number
  color?: string
  style?: CSSProperties
}

const Loader: React.FC<LoaderProps> = ({
  size = 80,
  color = colors.primary,
  style,
}) => {
  const circles: ReactNode[] = [...Array(2)].map((_, index) => (
    <div
      key={index}
      style={{
        borderColor: `${color}`,
        borderWidth: size * 0.05,
      }}
    />
  ))

  return (
    <div className="lds-ripple" style={{ width: size, height: size, ...style }}>
      {circles}
    </div>
  )
}

export default Loader
