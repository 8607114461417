import React from 'react'
import Button from 'react-bootstrap/Button'
import { createUseStyles } from 'react-jss'
import clsx from 'clsx'

type SubmitButtonProps = {
  disabled?: boolean
  onClick: () => void
}

const useStyles = createUseStyles({
  root: {
    backgroundColor: '#4EBA7E !important',
    border: 'none',
    '&:hover': {
      boxShadow: '0 3px 6px 0 rgba(153, 155, 168, 0.61)',
    },
  },
  disabled: {
    '&:hover': {
      boxShadow: 'none !important',
    },
  },
})

const SubmitButton = ({
  disabled = false,
  onClick,
}: SubmitButtonProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Button
      className={clsx(classes.root, { [classes.disabled]: disabled })}
      variant="primary"
      onClick={onClick}
      disabled={disabled}
    >
      Submit
    </Button>
  )
}

export default SubmitButton
