import React from 'react'
import compose from 'just-compose'
import { withErrorHandler } from '../../../../errors'
import { withRouter } from 'react-router-dom'
import CardContainer from '../../../../elemets/CardContainer'
import CardTitle from '../../../../elemets/CardTitle'
import { createUseStyles } from 'react-jss'
import Icon from '../../../Icons'
import colors, { headings } from '../../../../theme/theme2'
import GroupInformation from './GroupInformation'
import GroupImages from './GroupImages'
import { isMobile } from '../../../../utils/common'

const useStyles = createUseStyles({
  ul: {
    padding: 0,
    listStyleType: 'none',
    '& li': {
      ...headings.paragraph,
      fontWeight: 'bold',
      color: colors.dark,
      margin: 15,
      '& i': {
        '&:before': {
          color: colors.primary,
        },
      },
    },
  },
  containers: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
  },
  header: {
    '& i': {
      cursor: 'auto',
      '&:before': {
        fontSize: 20,
        color: colors.primary,
      },
    },
  },
})

const GroupDetails = ({ group, loading }) => {
  const classes = useStyles()

  return (
    <CardContainer
      className={classes.containers}
      headerDivider={true}
      footerDivider={true}
      header={
        <div
          className={`${classes.header} ${
            isMobile() ? 'd-flex justify-content-center' : ''
          }`}
        >
          <Icon icon="fas fa-globe" />
          <CardTitle title={'Group Information'} />
        </div>
      }
      footer={<GroupImages group={group} loading={loading} />}
    >
      <GroupInformation group={group} />
    </CardContainer>
  )
}

export default compose(withErrorHandler, withRouter)(GroupDetails)
