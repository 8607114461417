export enum FeatureFlags {
  assessmentsAll = 'assessmentsAll',
  meetNow = 'meetNow',
}

export const FeatureFlagDefaults = {
  assessmentsAll:
    '{"onboarding":[1, 479, 483, 494, 679, 691], "matching":[1, 679, 691], "relace":[1, 494, 500, 505]}',
  meetNow: false,
}
