import React from 'react'
import ProfileCard from './ProfileCard'
import LoaderPage from '../../elemets/LoaderPage'
import GroupOrder from '../Network/GroupOrder'

const MatchesList = ({
  filteredMatches,
  loading,
  setSortField,
  showOrder = false,
  openAvailabilitiesFor = null,
}) => (
  <>
    {loading && <LoaderPage />}
    {showOrder && filteredMatches.length > 0 && (
      <GroupOrder profiles={filteredMatches} setSortField={setSortField} />
    )}
    {filteredMatches.map(function (match) {
      const { availabilities = 0 } = match

      const openAvailabilitiesModal =
        openAvailabilitiesFor === `${match.profileId}`

      return (
        <React.Fragment key={match.externalId || match.email}>
          <ProfileCard
            user={match}
            availabilities={availabilities}
            openAvailabilityModal={openAvailabilitiesModal}
          />
        </React.Fragment>
      )
    })}

    {filteredMatches.length === 0 && !loading && (
      <div>
        <p>
          There are no other members with this topic of interest right now.{' '}
        </p>
        <p>
          Please visit your profile to add another topic or try to schedule
          another conversation with a different topic.{' '}
        </p>
      </div>
    )}
  </>
)

export default MatchesList
