import React, { useCallback, useState, useEffect, useMemo } from 'react'
import compose from 'just-compose'

import { withErrorHandler } from '../../../errors'

// Elements
import CardContainer from '../../../elemets/CardContainer'
import CustomTextArea from '../../../elemets/CustomTextArea'
import Divider from '../../../elemets/Divider'
import CardTitle from '../../../elemets/CardTitle'

// ApiError
import { useApiSubmit } from '../../../api'
import HamburgerContainer from './HamburgerContainer'
import Icon from '../../Icons'
import { createUseStyles } from 'react-jss'
import { headings } from '../../../theme/theme2'
import ActionButton from '../../../elemets/ActionButton'

const useStyles = createUseStyles({
  sharedText: {
    ...headings.text,
    fontSize: 14,
    color: '#8a8a8a',
  },
})
const GoalsCard = ({
  show,
  goals,
  loading,
  reload,
  conversationId,
  handleError,
  currentProfile,
}) => {
  const classes = useStyles()
  const [myGoal, setMyGoal] = useState({
    text: '',
    itemId: null,
  })
  const [editMode, setEditMode] = useState(false)

  const {
    submitted,
    submit: addConversationItem,
    submitting,
    err,
  } = useApiSubmit('addConversationItem')

  const {
    submitted: updated,
    submit: updateConversationItem,
    submitting: updateLoading,
    err: updateErr,
  } = useApiSubmit('updateConversationItem')

  useEffect(() => {
    if (!loading && goals && currentProfile) {
      const myGoal = goals.find((goal) => goal.user.id === currentProfile.id)
      if (myGoal) {
        setMyGoal(myGoal)
      }
    }
  }, [goals, currentProfile, loading])

  // Added
  useEffect(() => {
    if (submitted && !err && !submitting) {
      reload()
    } else if (err && !submitting) {
      handleError(err, true, {
        message: 'There was a problem adding the goal.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, err, submitting])

  // Updated
  useEffect(() => {
    if (updated && !updateErr && !updateLoading) {
      setEditMode(false)
      reload()
    } else if (updateErr && !updateLoading) {
      handleError(updateErr, true, {
        message: 'There was a problem updating the goal.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated, updateErr, updateLoading])

  const othersGoal = useMemo(() => {
    if (goals && currentProfile) {
      const goal = goals.find((g) => g.user.id !== currentProfile.id)
      if (goal) {
        return goal
      }
    }
    return null
  }, [goals, currentProfile])

  const save = useCallback(() => {
    const body = {
      text: myGoal.text,
      type: 'goal',
      userId: currentProfile.id,
    }
    // Update
    if (myGoal && myGoal.itemId) {
      body.updatedBy = currentProfile.id
      body.completed = false
      updateConversationItem({
        params: { conversationId, itemId: myGoal.itemId },
        body,
      })
    } else {
      // Add
      addConversationItem({
        params: { conversationId },
        body,
      })
    }
  }, [
    myGoal,
    currentProfile,
    updateConversationItem,
    conversationId,
    addConversationItem,
  ])

  return (
    show && (
      <CardContainer>
        <div className="d-flex justify-content-between align-items-center mb-0">
          <CardTitle title="My Conversation Goals" />
          <div className="d-flex align-items-center">
            <div className={classes.sharedText}>
              <Icon icon="fas fa-sync-alt" />
              <span className={'pl-2'}>Shared</span>
            </div>
            <HamburgerContainer
              buttons={[
                {
                  title: 'Edit',
                  onClick: () => {
                    setEditMode(true)
                  },
                  icon: 'fas fa-edit',
                  color: 'grey',
                },
              ]}
            />
          </div>
        </div>
        <CustomTextArea
          name="text"
          value={myGoal.text}
          onChange={(e) => {
            setMyGoal({ ...myGoal, text: e.target.value })
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter' || event.key === 'NumpadEnter') {
              save()
            }
          }}
          editMode={editMode}
          enabled={false}
          className="mt-2"
          maxLength={500}
          placeholder={'Add your goal'}
        />
        {editMode ? (
          <div className="d-flex justify-content-end mt-2">
            <ActionButton
              text="SAVE"
              onClick={() => save()}
              type={'LIGHT_GREEN'}
            />
          </div>
        ) : null}

        {othersGoal ? (
          <>
            <Divider />
            <CardTitle
              title={`${othersGoal.user.firstName} ${othersGoal.user.lastName} - Conversation Goals`}
            />
            <CustomTextArea value={othersGoal.text} editMode={false} />
          </>
        ) : null}
      </CardContainer>
    )
  )
}

export default compose(withErrorHandler)(GoalsCard)
