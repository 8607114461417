import React from 'react'
import GroupIcon from '../../Icons/Group'
import { createUseStyles } from 'react-jss'

interface GroupLabelProps {
  name: string
}

const useStyles = createUseStyles(() => ({
  root: {
    overflow: 'hidden',
  },
  name: {
    marginLeft: '8px',
  },
  icon: {
    cursor: 'initial',
  },
}))

const GroupLabel = ({ name }: GroupLabelProps): JSX.Element => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <GroupIcon classstyle={classes.icon} />
      <span className={classes.name}>{name}</span>
    </div>
  )
}

export default GroupLabel
