import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { Container, Row, Col } from 'react-bootstrap'

import colors from '../../theme/theme2'
import SuggestedMatches from './SuggestedMatches'
import AvailabilityCard from './AvailabilityCard'
import UpcomingConversation from './UpcomingConversation'
import Topics from './Topics'
import Welcome from './Welcome'
import ActionItems from './ActionItems'
import { useApiFetch, useApiSubmit } from '../../api'
import { withErrorHandler } from '../../errors'
import compose from 'just-compose'
import { isMobile } from '../../utils/common'
import ScrollLock from 'react-scrolllock'
import FirstStepsCard from './FirstSteps/FirstStepsCard'
import HelpButton from './HelpButton'

const padding24 = 24
const padding32 = 32

const useStyles = createUseStyles({
  header: {
    display: 'block',
    paddingTop: padding32,
  },
  welcomeFont: {
    fontSize: '2em',
    paddingBottom: padding32,
    margin: 0,
  },
  link: {
    color: colors.primary,
    '&:hover': {
      color: colors.primary,
    },
  },
  paragraph: {
    fontSize: 20,
    paddingBottom: padding24,
    margin: 0,
  },
  card: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    marginBottom: 30,
  },
  cover: {
    position: 'fixed',
    zIndex: 8,
    left: 0,
    top: 0,
    width: '100%' /* Full width */,
    height: '100%' /* Full height */,
    overflow: 'auto' /* Enable scroll if needed */,
    backgroundColor: 'black' /* Black w/ opacity */,
    opacity: '0.5',
  },
})

const OnBoardingTemplate = ({
  profile,
  showTooltip,
  setShowTooltip,
  handleError,
  groupId,
}) => {
  const classes = useStyles()

  const {
    submitted,
    submit: updateProfile,
    err: updateErr,
    submitting,
  } = useApiSubmit('updateProfileV1')

  const {
    data: conversations,
    loading: areConversationsLoading,
    err: conversationsErr,
    reload: reloadConversations,
  } = useApiFetch('listConversations', {
    query: { count: 5, status: 'scheduled' },
  })

  const { data: conversations2 } = useApiFetch('listConversations', {
    query: { count: 5, status: 'completed' },
  })

  const {
    data: matches,
    loading,
    err,
    fetch: fetchMatches,
  } = useApiFetch('listMatches', {}, false)

  useEffect(() => {
    if (profile) {
      const skills = profile.topics.map((x) => x.skillId)
      const groups = groupId ? [groupId] : profile.groups.map((x) => x.id)

      if (skills.length === 0) return

      const query = {
        skillId: skills,
        groupId: groups,
      }

      fetchMatches({
        query: query,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, groupId])

  useEffect(
    () => {
      if (err && !loading)
        handleError(err, true, {
          message: 'There was a problem loading conversations.',
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, err],
  )

  useEffect(
    () => {
      if (conversationsErr)
        handleError(conversationsErr, true, {
          message: 'There was a problem loading conversations.',
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [areConversationsLoading, conversationsErr],
  )

  useEffect(() => {
    if (submitted && !updateErr && !submitting) {
      setShowTooltip(0)
    } else if (updateErr && !submitting) {
      handleError(updateErr, true, {
        message: 'There was a problem updating your profile',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, updateErr, submitting])

  const closeTooltip = () => {
    if (profile?.showTour) {
      const allowed = [
        'bio',
        'birthDate',
        'conversationFrequencyId',
        'email',
        'firstName',
        'funFact',
        'highSchool',
        'homeTown',
        'lastName',
        'linkedIn',
        'location',
        'maxConversations',
        'notificationFrequencyId',
        'phoneNumber',
        'regionId',
        'showTour',
        'title',
      ]

      const filtered = Object.keys(profile)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = profile[key]
          return obj
        }, {})

      updateProfile({
        params: { profileId: profile.id },
        body: { ...filtered, showTour: false },
      })
    } else {
      setShowTooltip(0)
    }
  }

  return (
    <>
      {showTooltip !== 0 && <div className={classes.cover} />}
      <ScrollLock isActive={showTooltip}>
        <Container className={`${classes.header} ${isMobile() ? 'p-0' : ''}`}>
          {!isMobile() ? (
            <Row rowSpan="3">
              <Col xs={12} md={4} style={{ paddingBottom: 20 }}>
                <FirstStepsCard
                  conversations={conversations}
                  conversations2={conversations2}
                  reloadUpcoming={reloadConversations}
                  showTooltip={showTooltip}
                  setTooltip={setShowTooltip}
                  closeTooltip={closeTooltip}
                ></FirstStepsCard>
                <AvailabilityCard
                  showTooltip={showTooltip}
                  setTooltip={setShowTooltip}
                  closeTooltip={closeTooltip}
                />
                <UpcomingConversation
                  conversations={conversations}
                  reloadUpcoming={reloadConversations}
                  groupId={groupId}
                />
                <Topics
                  showTooltip={showTooltip}
                  setTooltip={setShowTooltip}
                  closeTooltip={closeTooltip}
                />
                {/*<ActionItems />*/}
              </Col>
              <Col xs={12} md={8}>
                <Welcome />
                <SuggestedMatches
                  showTooltip={showTooltip}
                  setTooltip={setShowTooltip}
                  closeTooltip={closeTooltip}
                  reloadUpcoming={reloadConversations}
                  matches={matches}
                  profileTopics={profile.topics}
                />
              </Col>
            </Row>
          ) : (
            <Row rowSpan="3" noGutters>
              <Col xs={12} style={{ paddingBottom: 20 }}>
                <Welcome />
                <FirstStepsCard
                  conversations={conversations}
                  conversations2={conversations2}
                  reloadUpcoming={reloadConversations}
                  showTooltip={showTooltip}
                  setTooltip={setShowTooltip}
                  closeTooltip={closeTooltip}
                ></FirstStepsCard>
                <SuggestedMatches
                  showTooltip={showTooltip}
                  setTooltip={setShowTooltip}
                  closeTooltip={closeTooltip}
                  reloadUpcoming={reloadConversations}
                  matches={matches}
                  profileTopics={profile.topics}
                />
                <AvailabilityCard
                  showTooltip={showTooltip}
                  setTooltip={setShowTooltip}
                  closeTooltip={closeTooltip}
                />
                <UpcomingConversation
                  conversations={conversations}
                  reloadUpcoming={reloadConversations}
                  groupId={groupId}
                />
                <Topics
                  showTooltip={showTooltip}
                  setTooltip={setShowTooltip}
                  closeTooltip={closeTooltip}
                />
                <ActionItems />
              </Col>
            </Row>
          )}
          <Row noGutters>
            <Col xs={12} className={`d-flex justify-content-end pr-4`}>
              <HelpButton
                showTooltip={showTooltip}
                setTooltip={setShowTooltip}
                closeTooltip={closeTooltip}
              />
            </Col>
          </Row>
        </Container>
      </ScrollLock>
    </>
  )
}

export default compose(withErrorHandler)(OnBoardingTemplate)
