import React, {
  ComponentType,
  ReactNode,
  createContext,
  useState,
  useCallback,
  useContext,
} from 'react'

type ContextValue = {
  open: () => void
  close: () => void
}

const notImplemented = () => {
  throw new Error('not implemented')
}

export const Context = createContext<ContextValue>({
  open: notImplemented,
  close: notImplemented,
})

interface ModalProps {
  open: boolean
  onClose: () => void
  groupId?: number
}

type ModalProviderProps = {
  Modal: ComponentType<ModalProps>
  children: ReactNode
  groupId?: number
}

const ModalProvider = ({
  Modal,
  children,
  groupId,
}: ModalProviderProps): JSX.Element => {
  const [opened, setOpened] = useState(false)

  const open = useCallback(() => {
    setOpened(true)
  }, [])

  const close = useCallback(() => {
    setOpened(false)
  }, [])

  return (
    <Context.Provider value={{ open, close }}>
      {children}
      <Modal open={opened} onClose={close} groupId={groupId} />
    </Context.Provider>
  )
}

export default ModalProvider

export const useModalProvider = (): ContextValue => useContext(Context)
