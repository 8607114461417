import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from 'react'
import { useApi } from '../../../api'

// TODO provide more concrete definition of this data model.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Conversation = Record<string, any>

// TODO use union for more declarative type definition.
type ConversationContextValue = {
  conversation: Conversation | null
  loading: boolean
  err: Error | undefined
}

const ConversationContext = createContext<ConversationContextValue>({
  conversation: null,
  loading: false,
  err: undefined,
})

export const useConversation = (): ConversationContextValue =>
  useContext(ConversationContext)

type LoadConversationProviderProps = {
  conversationId: number | undefined
  load: boolean
  children: ReactNode
}

const LoadConversationProvider = ({
  conversationId,
  load,
  children,
}: LoadConversationProviderProps): JSX.Element => {
  const [conversation, setConversation] = useState<Conversation | null>(null)
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState<Error | undefined>()
  const { invoke } = useApi()

  useEffect(() => {
    if (!load) {
      return
    }

    if (!conversationId && conversation) {
      setConversation(null)
      return
    }

    if (!conversationId) {
      return
    }

    if (conversation && conversationId === conversation.id) {
      return
    }

    let cancelled = false
    const loadConversation = async () => {
      setLoading(true)
      try {
        const conversation = await invoke('getConversation', {
          params: { conversationId },
        })
        if (cancelled) {
          return
        }
        if (typeof conversation === 'object') {
          setConversation(conversation)
        }
      } finally {
        if (cancelled) {
          return
        }
        setLoading(false)
      }
    }

    loadConversation().catch(setErr)

    return () => {
      cancelled = true
    }
  }, [conversationId, invoke, load, conversation])

  return (
    <ConversationContext.Provider value={{ conversation, loading, err }}>
      {children}
    </ConversationContext.Provider>
  )
}

export default LoadConversationProvider
