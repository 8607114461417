import React, { useEffect, useMemo, useState } from 'react'
import CardContainer from '../../../../elemets/CardContainer'
import { isMobile } from '../../../../utils/common'
import Icon from '../../../Icons'
import CardTitle from '../../../../elemets/CardTitle'
import { createUseStyles } from 'react-jss'
import colors from '../../../../theme/theme2'
import ProfileCard from './ProfileCard'
import { useApiFetch } from '../../../../api'
import { Row, Col } from 'react-bootstrap'
import Filter from '../../../Groups/Filter'
import LoaderPage from '../../../../elemets/LoaderPage'
import AddUserModal from './AddUserModal'

const useStyles = createUseStyles({
  containers: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
  },
  header: {
    '& i': {
      cursor: 'auto',
      '&:before': {
        fontSize: 20,
        color: colors.primary,
      },
    },
  },
  label: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: '#707575',
    '& span': {
      marginRight: '5%',
      cursor: 'pointer',
    },
    '& i': {
      cursor: 'pointer',
      '&:before': {
        fontSize: 12,
        color: '#707575',
      },
    },
  },
  addUser: {
    '& i': {
      cursor: 'pointer',
      '&:before': {
        fontSize: 30,
      },
    },
  },
  floatUser: {
    position: 'absolute',
    top: -13,
    right: 35,
  },
  iconBg: {
    position: 'absolute',
    height: 18,
    width: 6,
    top: 5,
    left: 12,
    backgroundColor: '#ffffff',
  },
  iconBgPlus: {
    position: 'absolute',
    height: 5,
    width: 18,
    top: 12,
    left: 6,
    backgroundColor: '#ffffff',
  },
})

const GroupUserAdministration = ({ group }) => {
  const classes = useStyles()
  const [members, setMembers] = useState([])
  const [filterState, setFilterState] = useState({
    people: [],
  })
  const [addUserModal, setAddUserModal] = useState(false)

  const filters = useMemo(
    () => [
      {
        name: 'people',
        placeholder: 'Filter by Person',
        options: [...members.map((x) => x.firstName + ' ' + x.lastName)],
      },
    ],
    [members],
  )

  const [sortField, setSortField] = useState({
    field: 'firstName',
    order: true,
  })
  const [sorted, setSorted] = useState([])
  const [firstLoad, setFirstLoad] = useState(true)

  const {
    data: getMembers,
    loading: loadingMembers,
    reload: getMembersReload,
    fetch: fetchMembers,
  } = useApiFetch('getGroupMembersV1', {}, false)

  // update filters state
  const onFilterData = ({ filterName, selection }) => {
    const filterObj = {
      [filterName]: selection,
    }
    setFilterState({ ...filterState, ...filterObj })
  }

  useEffect(() => {
    let filtered = members

    if (filterState.people.length > 0) {
      filtered = filtered.filter((x) =>
        filterState.people.includes(x.firstName + ' ' + x.lastName),
      )
    }
    setSorted(filtered)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState])

  useEffect(() => {
    if (group.id) {
      fetchMembers({
        params: { groupId: group.id },
        query: { count: 1000 },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group.id])

  useEffect(() => {
    if (getMembers) {
      setMembers(
        getMembers.filter((match) => match.hasOwnProperty('firstName')),
      )
      setSorted(getMembers.filter((match) => match.hasOwnProperty('firstName')))
      setFirstLoad(false)
    }
  }, [getMembers, firstLoad])

  //LOADING
  const isLoading = useMemo(
    () => firstLoad && loadingMembers,
    [firstLoad, loadingMembers],
  )

  const header = (
    <Row noGutters={isMobile()} className={`${classes.header}`}>
      <Col
        xs={isMobile() ? 12 : 6}
        className={isMobile() ? 'd-flex justify-content-center' : ''}
      >
        <Icon icon="fas fa-user" />
        <CardTitle title={'User Administration'} />
      </Col>
      {!isMobile() ? (
        <>
          <Col xs={5}>
            <Filter
              className={classes.filter}
              filters={filters}
              data={members}
              filterFunction={onFilterData}
            />
          </Col>
          <Col xs={1} className={`${classes.addUser} pl-0`}>
            <Icon
              icon="fas fa-plus-circle"
              onClick={() => {
                setAddUserModal(!addUserModal)
              }}
            />
          </Col>
        </>
      ) : (
        <div className={`${classes.addUser} ${classes.floatUser}`}>
          <div
            className={classes.iconBg}
            onClick={() => {
              setAddUserModal(!addUserModal)
            }}
          />
          <div
            className={classes.iconBgPlus}
            onClick={() => {
              setAddUserModal(!addUserModal)
            }}
          />
          <Icon
            icon="fas fa-plus-circle"
            onClick={() => {
              setAddUserModal(!addUserModal)
            }}
          />
        </div>
      )}
    </Row>
  )

  useEffect(() => {
    const ordered = members.slice().sort((profileA, profileB) => {
      if (
        profileA[sortField.field]?.toString().toLowerCase() >
        profileB[sortField.field]?.toString().toLowerCase()
      ) {
        return 1
      }
      if (
        profileA[sortField.field]?.toString().toLowerCase() <
        profileB[sortField.field]?.toString().toLowerCase()
      ) {
        return -1
      }
      // a must be equal to b
      return 0
    })
    setSorted(sortField.order ? ordered : ordered.slice().reverse())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortField])

  const toggleSort = (field) => {
    setSortField({
      field: field,
      order: !sortField.order,
    })
  }

  return (
    <>
      {isLoading && <LoaderPage />}
      {addUserModal && (
        <AddUserModal
          group={group}
          setDisplayModal={setAddUserModal}
          reload={getMembersReload}
        />
      )}
      <CardContainer
        className={`${classes.containers} pb-1`}
        header={header}
        headerDivider={true}
      >
        <Row className={classes.label} noGutters>
          <Col
            xs={{ span: isMobile() ? 2 : 4, offset: 1 }}
            className={isMobile() ? 'pt-1' : ''}
          >
            <div onClick={() => toggleSort('firstName')}>
              <span>A-Z</span>
              <Icon className={'fas fa-sort'} />
            </div>
          </Col>
          {!isMobile() ? (
            <>
              <Col xs={2}>
                <div onClick={() => toggleSort('maxConversations')}>
                  <span>Conversations</span>
                  <Icon className={'fas fa-sort'} />
                </div>
              </Col>
              <Col xs={2}>
                <div onClick={() => toggleSort('lastLogin')}>
                  <span>Last Login</span>
                  <Icon className={'fas fa-sort'} />
                </div>
              </Col>
              <Col xs={2}>
                <div onClick={() => toggleSort('dateCreated')}>
                  <span>Created</span>
                  <Icon className={'fas fa-sort'} />
                </div>
              </Col>
            </>
          ) : (
            <Col xs={9}>
              <Filter
                className={classes.filter}
                filters={filters}
                data={members}
                filterFunction={onFilterData}
              />
            </Col>
          )}
        </Row>
      </CardContainer>

      {sorted.map((user, i) => {
        return (
          <ProfileCard
            key={`group-user-${i}`}
            user={user}
            group={group}
            reload={getMembersReload}
          />
        )
      })}
    </>
  )
}

export default GroupUserAdministration
