import React from 'react'
import { createUseStyles } from 'react-jss'

// Elements
import Modal from '../../elemets/Modal'
import { Row } from 'react-bootstrap'
import LinkButton from '../../elemets/LinkButton'
import colors, { headings } from '../../theme/theme2'
import { isMobile } from '../../utils/common'

const useStyles = createUseStyles({
  danger: {
    ...headings.paragraph,
    fontWeight: 'bold',
    color: colors.red,
  },
  text: {
    ...headings.paragraph,
    color: colors.dark,
    marginBottom: 55,
  },
  chrome: {
    color: colors.primary,
    '&:hover, &:active, &:focus': {
      color: colors.primary,
    },
    padding: 0,
  },
  body: {
    padding: '48px 17px 69px 17px',
    backgroundColor: '#ffffff !important',
  },

  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
})

const ChormeNotSupportedModal = ({ show, onClose, ...rest }) => {
  const classes = useStyles()

  return (
    <Modal
      show={show}
      close={onClose}
      title={' '}
      closeIcon
      closeOutside={false}
      dialogClassName={isMobile() ? classes.dialogMobile : ''}
      bodyClass={classes.body}
      {...rest}
    >
      <p className={classes.danger}>Warning: Unsupported Browser Detected</p>
      <p className={classes.text}>
        It looks like you are using a browser that is not fully supported.
        Please use a destkop browser or open the app in Safari while using Mac
        iOS.
      </p>
      {/* <Row className="justify-content-center mb-3">
        <ActionButton text="LAUNCH IN SAFARI" type="STRONG_GREEN"/>
      </Row> */}
      <Row className="justify-content-center">
        <LinkButton
          className={classes.chrome}
          text="Continue with Chrome"
          onClick={onClose}
        />
      </Row>
    </Modal>
  )
}

export default ChormeNotSupportedModal
