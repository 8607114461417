import { gapi } from 'gapi-script'
import React, { useEffect } from 'react'
import GoogleLogin from 'react-google-login'
import { useGoogleConfig } from '../../../config/useConfig'
import { useErrorHandler } from '../../../errors'

const GoogleLoginButton = ({ linkAccount }) => {
  const handleError = useErrorHandler()
  const { clientId } = useGoogleConfig()

  const handleGoogleLogin = (response) => {
    if (response.error) {
      handleError(response.error, true, {
        message: `There was a problem linking your account: ${response.error}.`,
      })
    } else {
      linkAccount(response.code)
    }
  }

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope:
          'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
      })
    }

    gapi.load('client:auth2', start)

    // TODO probably need to find a way to unload the google API start listener when this component
    // unmounts, otherwise the listener will remain attached to the gapi client, and new listeners
    // will continue to be attached each time the component is re-mounted.
    // (Speculating on the behavior of gapi and the listeners here.)
  }, [clientId])

  return (
    <GoogleLogin
      clientId={clientId}
      buttonText="Sign in with Google"
      onSuccess={handleGoogleLogin}
      onFailure={handleGoogleLogin}
      scope="https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
      prompt="consent"
      responseType="code"
    />
  )
}

export default GoogleLoginButton
