import { Avatar } from '@material-ui/core'
import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useChatConversationProvider } from '../Context/ChatConversationProvider'
import { Input } from '../Forms/Input'
import Icon from '../Icons'
import ConfirmationModal from '../Modals/ConfirmationModal'

const useStyles = createUseStyles({
  wrapper: {
    display: 'grid',
    grid: '85vh / 18rem 1fr',
    gap: '1rem',
  },
  // LEFT SIDE
  wrapperUserList: {
    display: 'grid',
    grid: '3rem 3rem 1fr / 1fr',
    gap: '0.5rem',

    width: '100%',
    height: '100%',
    boxShadow: '0 6px 16px 0 rgb(153 155 168 / 30%)',
    padding: '1rem 0.5rem',
  },
  // LEFT SIDE

  // RIGHT SIDE
  wrapperChat: {
    display: 'grid',
    grid: '3rem 1fr 10rem / 1fr',

    width: '100%',
    height: '100%',
    boxShadow: '0 6px 16px 0 rgb(153 155 168 / 30%)',
    padding: '1rem',
  },
  // RIGHT SIDE

  buttonChat: {
    background: '#ededed',
    border: '1px solid red',
    height: '3rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonSearch: {
    background: '#ededed',
  },

  usersListModal: {
    height: '50vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '1px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#ededed',
      borderRadius: '1px',
      border: `1px solid #ededed`,
    },
  },
  chatListUsers: {
    background: '#ededed',
    display: 'grid',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '1px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#ededed',
      borderRadius: '1px',
      border: `1px solid #ededed`,
    },
    grid: 'repeat(auto-fill, 3.5rem) / auto',
  },
  chatListUsersCard: {
    display: 'grid',
    grid: '3.5rem /25% 1fr 15%',
    alignItems: 'center',
    justifyItems: 'center',
  },
  chatListUsersCardImg: {
    border: '2px solid grey',
    borderRadius: '50%',
    width: '2.5rem',
    height: '2.5rem',
  },
  chatListUsersCardName: {
    justifySelf: 'start',
  },
  chatListUsersCardIcon: {
    color: '#9b9b9b',
    transform: 'rotate(90deg)',
    cursor: 'pointer',
  },
  // chatListUsers

  inputIcon: {
    color: '#191919',
    position: 'absolute',
    width: '20px',
    height: '20px',
    left: '12px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  inputWrapper: {
    position: 'relative',
    width: '271px',
    background: '#ededed',
  },
  input: {
    boxSizing: 'border-box',
    color: '#191919',
    padding: '10px 35px',
    width: '100%',
  },
})

export default function UserListModal({
  onClose,
  users,
  onClickUser,
  statusUserModal,
  setSearch,
}) {
  const classes = useStyles()

  const { chatConversation } = useChatConversationProvider()

  const handleSearch = useCallback(
    (e) => {
      setSearch(e.target.value)
    },
    [setSearch],
  )

  return (
    <ConfirmationModal
      style={{ maxHeight: '100vh' }}
      show={statusUserModal}
      onClose={() => onClose((value) => !value)}
      closeIcon={true}
      title={'Users'}
      content={
        <>
          <Input
            label="Search"
            placeholder="Type a name..."
            onChange={handleSearch}
          />
          <div className={classes.usersListModal}>
            {users?.map((user) => (
              <div
                key={`confirm-user-modal-item-${user.ur_id}`}
                className={classes.chatListUsersCard}
                onClick={() => {
                  onClickUser(
                    user,
                    () => onClose((value) => !value),
                    chatConversation,
                  )
                }}
              >
                <Avatar alt="avatar" src={user.ur_avatar_uri} />

                <div className={classes.chatListUsersCardName}>
                  {user.ur_first} {user.ur_last}
                </div>
                <Icon
                  classstyle={classes.chatListUsersCardIcon}
                  icon="fas fa-ellipsis-v"
                />
              </div>
            ))}
          </div>
        </>
      }
    />
  )
}
