import { ReactNode, useReducer, useEffect } from 'react'
import { Participant } from './Participant'
import { Status } from './Status'
import { useApi } from '../../../../../../api'
import ParticipantsProvider from './ParticipantsProvider'
import { getAllGroupMembers } from './getGroupMembersV1'

type UnsetState = {
  status: Status.Unset
}

type LoadingState = {
  status: Status.Loading
  groupId: number
}

type SuccessState = {
  status: Status.Loaded
  groupId: number
  candidates: Participant[]
}

type ErrorState = {
  status: Status.Error
  error: Error
}

type State = UnsetState | LoadingState | SuccessState | ErrorState

type LoadingAction = { type: 'LOADING'; payload: number }

const loading = (groupId: number): LoadingAction => ({
  type: 'LOADING',
  payload: groupId,
})

type LoadedAction = { type: 'LOADED'; payload: Participant[] }

const loaded = (participants: Participant[]): LoadedAction => ({
  type: 'LOADED',
  payload: participants,
})

type ErrorAction = { type: 'ERROR'; payload: Error }

const error = (err: Error): ErrorAction => ({ type: 'ERROR', payload: err })

type ClearAction = { type: 'CLEAR' }

const clear = (): ClearAction => ({ type: 'CLEAR' })

type Action = LoadingAction | LoadedAction | ErrorAction | ClearAction

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'LOADING': {
      const { payload: groupId } = action
      return { status: Status.Loading, groupId }
    }
    case 'LOADED': {
      if (state.status !== Status.Loading) {
        // invalid state
        return state
      }

      const { payload: candidates } = action
      const { groupId } = state
      return { status: Status.Loaded, candidates, groupId }
    }
    case 'ERROR': {
      const { payload: err } = action
      return { status: Status.Error, error: err }
    }
    case 'CLEAR': {
      return { status: Status.Unset }
    }
    default: {
      return state
    }
  }
}

type GroupParticipantsProps = {
  groupId: number
  children: ReactNode
}

interface GroupMemberData {
  profileId: number
  firstName: string
  lastName: string
}

const GroupParticipants = ({
  groupId,
  children,
}: GroupParticipantsProps): JSX.Element => {
  const { invoke } = useApi()
  const [state, dispatch] = useReducer(reducer, { status: Status.Unset })
  const existingGroupId =
    state.status === Status.Loaded ? state.groupId : undefined

  useEffect(() => {
    if (existingGroupId === groupId) {
      // group hasn't changed, do nothing
      return () => {}
    }

    const controller = new AbortController()

    dispatch(loading(groupId))

    getAllGroupMembers(groupId, controller.signal)
      .then((groupMembers) => {
        if (controller.signal.aborted) {
          return
        }

        const candidates = groupMembers.map(
          ({
            profileId,
            firstName,
            lastName,
          }: GroupMemberData): Participant => ({
            id: profileId,
            name: [firstName, lastName].filter(Boolean).join(' '),
          }),
        )
        dispatch(loaded(candidates))
      })
      .catch((err: Error) => {
        if (controller.signal.aborted || err.name === 'AbortError') {
          return
        }

        dispatch(error(err))
      })

    return () => {
      controller.abort()
    }
  }, [groupId, existingGroupId, invoke])

  useEffect(
    () => () => {
      dispatch(clear())
    },
    [],
  )

  const ready = state.status === Status.Loaded || state.status === Status.Error
  const candidates = state.status === Status.Loaded ? state.candidates : []

  return (
    <ParticipantsProvider candidates={candidates} ready={ready}>
      {children}
    </ParticipantsProvider>
  )
}

export default GroupParticipants
