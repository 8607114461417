import React from 'react'
import Icon from './Icon'

type KebabIconProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  classstyle?: any
}

const KebabIcon = (props: KebabIconProps): JSX.Element => (
  <Icon icon="fas fa-ellipsis-v" {...props} />
)

export default KebabIcon
