export const assessmentInfoUsphs = {
  title:
    'Commissioned Corps of the US Public Health Service Mentor Matching Questionnaire',
  description: '',
  completedHtml:
    '<h3>Your responses have been saved and submitted.  Thank you for your input.</h3>',
  // completedHtmlOnCondition: [],
}

export const BR_customPage1 = {
  name: 'page01',
  showTitle: false,
  checkErrorsMode: 'onValueChanged',
  elements: [
    {
      name: 'empid',
      type: 'text',
      title: 'Please enter your 5 digit Serno #:',
      isRequired: true,
      validators: [
        {
          type: 'text',
          minLength: 5,
          maxLength: 5,
        },
      ],
    },
    {
      name: 'empidconfirm',
      type: 'text',
      title: 'Please Re-enter your 5 digit Serno #:',
      isRequired: true,
      validators: [
        {
          type: 'text',
          minLength: 5,
          maxLength: 5,
        },
      ],
    },
    {
      type: 'dropdown',
      name: 'discipline',
      description: ``,
      title: 'Discipline',
      isRequired: true,
      showNoneItem: false,
      showOtherItem: false,
      choices: [
        { text: 'DENTAL', value: 'discipline_dental' },
        { text: 'DIETITIAN', value: 'discipline_dietitian' },
        { text: 'ENGINEER', value: 'discipline_engineer' },
        {
          text: 'ENVIRONMENTAL HEALTH',
          value: 'discipline_environmental_health',
        },
        { text: 'HEALTH SERVICES', value: 'discipline_health_services' },
        { text: 'MEDICAL', value: 'discipline_medical' },
        { text: 'NURSE', value: 'discipline_nurse' },
        { text: 'PHARMACY', value: 'discipline_pharmacy' },
        { text: 'SCIENTIST', value: 'discipline_scientist' },
        { text: 'THERAPIST', value: 'discipline_therapist' },
        { text: 'VETERINARY', value: 'discipline_veterinary' },
      ],
    },
    {
      type: 'dropdown',
      name: 'career_pathway',
      description: ``,
      title: 'Career Pathway',
      isRequired: true,
      showNoneItem: false,
      showOtherItem: false,
      choices: [
        { text: `Healthcare Adminstrator`, value: `healthcare_admin` },
        { text: `Regulatory`, value: `regulatory` },
        { text: `Clinical`, value: `clinical` },
        { text: `Leadership`, value: `leadership` },
        { text: `Non-Clinical`, value: `non_clinical` },
      ],
    },
    {
      type: 'dropdown',
      name: 'rank_level',
      description: ``,
      title: 'Rank/Level',
      isRequired: true,
      showNoneItem: false,
      showOtherItem: false,
      choices: [
        { text: `O-1 Ensign Ensign – ENS `, value: `rank_O1` },
        {
          text: `O-2 Lieutenant (junior grade) Lt. (j.g.) – LTJG`,
          value: `rank_O2`,
        },
        { text: `O-3 Lieutenant -Lt. – LT`, value: `rank_O3` },
        {
          text: `O-4 Lieutenant Commander -Lt. Cmdr. – LCDR`,
          value: `rank_O4`,
        },
        { text: `O-5 Commander -Cmdr. – CDR`, value: `rank_O5` },
        { text: `O-6 Captain -Capt. – CAPT `, value: `rank_O6` },
        {
          text: `O-7 Rear Admiral (lower-half) - Rear Adm. – RADM`,
          value: `rank_O7`,
        },
        {
          text: `O-8 Rear Admiral (upper-half) - Rear Adm. RADM`,
          value: `rank_O8`,
        },
        { text: `O-9 Vice Admiral - Vice Adm. – VADM`, value: `rank_O9` },
        { text: `O-10 Admiral - Adm. - ADM`, value: `rank_O10` },
      ],
    },
    {
      type: 'dropdown',
      name: 'interest',
      description: ``,
      title: 'Interest',
      isRequired: true,
      showNoneItem: false,
      showOtherItem: false,
      choices: [
        { text: `Call to Active Duty`, value: `interest_calltoactiveduty` },
        {
          text: `Peer to Peer Mentoring`,
          value: `interest_peertopeermentoring`,
        },
        { text: `Ready Reserve`, value: `interest_readyreserve` },
        {
          text: `Traditional Mentoring`,
          value: `interest_traditionalmentoring`,
        },
      ],
    },
    {
      type: 'dropdown',
      name: 'agency',
      description: ``,
      title: 'Agency',
      isRequired: true,
      showNoneItem: false,
      showOtherItem: true,
      choices: [
        {
          text: 'Administration for Children and Families (ACF)',
          value: 'agency_acf',
        },
        {
          text: 'Administration for Community Living (ACL)',
          value: 'agency_acl',
        },
        {
          text: 'Administration for Strategic Preparedness and Response (ASPR)',
          value: 'agency_aspr',
        },
        {
          text: 'Agency for Healthcare Research and Quality (AHRQ)',
          value: 'agency_ahrq',
        },
        {
          text: 'Agency for Toxic Substances and Disease Registry (ATSDR)',
          value: 'agency_atsdr',
        },
        {
          text: 'Assistant Secretary for Administration (ASA)',
          value: 'agency_asa',
        },
        {
          text: 'Assistant Secretary for Financial Resources (ASFR)',
          value: 'agency_asfr',
        },
        { text: 'Assistant Secretary for Health (ASH)', value: 'agency_ash' },
        {
          text: 'Assistant Secretary for Legislation (ASL)',
          value: 'agency_asl',
        },
        {
          text: 'Assistant Secretary for Planning and Evaluation (ASPE)',
          value: 'agency_aspe',
        },
        {
          text: 'Assistant Secretary for Public Affairs (ASPA)',
          value: 'agency_aspa',
        },
        {
          text: 'Center for Faith-Based and Neighborhood Partnerships (CFBNP)',
          value: 'agency_cfbnp',
        },
        {
          text: 'Centers for Disease Control and Prevention (CDC)',
          value: 'agency_cdc',
        },
        {
          text: 'Centers for Medicare & Medicaid Services (CMS)',
          value: 'agency_cms',
        },
        { text: 'Departmental Appeals Board (DAB)', value: 'agency_dab' },
        { text: 'Food and Drug Administration (FDA)', value: 'agency_fda' },
        {
          text: 'Health Resources and Services Administration (HRSA)',
          value: 'agency_hrsa',
        },
        {
          text: 'Immediate Office of the Secretary (IOS)',
          value: 'agency_ios',
        },
        { text: 'Indian Health Service (IHS)', value: 'agency_ihs' },
        { text: 'National Institutes of Health (NIH)', value: 'agency_nih' },
        { text: 'Office for Civil Rights (OCR)', value: 'agency_ocr' },
        { text: 'Office of Global Affairs (OGA)', value: 'agency_oga' },
        { text: 'Office of Inspector General (OIG)', value: 'agency_oig' },
        {
          text: 'Office of Intergovernmental and External Affairs (IEA)',
          value: 'agency_iea',
        },
        {
          text: 'Office of Medicare Hearings and Appeals (OMHA)',
          value: 'agency_omha',
        },
        { text: 'Office of National Security (ONS)', value: 'agency_ons' },
        { text: 'Office of the General Counsel (OGC)', value: 'agency_ogc' },
        {
          text: 'Office of the National Coordinator for Health Information Technology (ONC)',
          value: 'agency_onc',
        },
        {
          text: 'Substance Abuse and Mental Health Services Administration (SAMHSA)',
          value: 'agency_samhsa',
        },
      ],
    },
    {
      type: 'dropdown',
      name: 'timezone',
      description: `Note that daylight savings/standard notations have been removed for simplicity`,
      title: 'Which time zone is closest to yours?',
      isRequired: true,
      showNoneItem: false,
      showOtherItem: false,
      choices: [
        {
          value: -8,
          text: `California / Vancouver, BC, Canada - Pacific Time`,
        },
        { value: -7, text: `Denver, CO, USA - Mountain Time` },
        { value: -6, text: `Kansas City, MO, USA - Central Time` },
        {
          value: -5,
          text: `New York, NY, USA / Toronto, ON, Canada - Eastern Time`,
        },
        { value: 0, text: `London, UK - GMT` },
        { value: 1, text: `Frankfurt, HE, Germany - Central Europe Time` },
        { value: 3, text: `St. Petersburg City, Russia - Russian Time` },
        { value: 8, text: `Singapore, Singapore - Malay Peninsula Time` },
        { value: 11, text: `Sydney, Australia - E. Australian Time` },
      ],
    },
    {
      type: 'radiogroup',
      name: 'mentoris1',
      title:
        'Please select how you would like to participate in the mentorship program:',
      isRequired: true,
      choices: [
        {
          value: 'mentor',
          text: 'I would like to be a **mentor**, and *provide mentorship* to someone',
        },
        {
          value: 'mentee',
          text: 'I would like to be a **mentee**, and *be mentored* by someone',
        },
        {
          value: 'both',
          text: 'I would like to be a **mentor**, and *provide mentorship* to someone & I would like to be a **mentee**, and *be mentored* by someone',
        },
      ],
    },
  ],
  showQuestionNumbers: false,
}

export const questionGroup2_1 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 1 of 8`,
  [
    [
      `gp1`,
      `I'd feel bad keeping extra change I shouldn't have received in a transaction, even if no one else noticed.`,
    ],
    [
      `gp2`,
      `I'd be disappointed in myself for breaking someone's decorative vase and not owning up to it, even if I didn't get caught.`,
    ],
    [
      `gp3`,
      `I'd be upset with myself for lying to cover up for a mistake I made, even if no one ever found out.`,
    ],
  ],
]
export const questionGroup2_2 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 2 of 8`,
  [
    [`e1`, `I would never go down a stretch of rapids in a kayak.`],
    [`a1`, `I am not easily satisfied.`],
    [
      `c1`,
      `At home and work, I make sure everything is always well-organized and back in its place.`,
    ],
    [`o1`, `Going to an art gallery is my idea of a very good time.`],
    [`h1`, `I would like to show off expensive things I own to others.`],
    [`e2`, `People would say that I worry a lot.`],
  ],
]
export const questionGroup2_3 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 3 of 8`,
  [
    [`a2`, `It is easy for me to forgive others who have wronged me.`],
    [`c2`, `I tend to put things off, sometimes to the last minute.`],
    [`x1`, `I tend to have a very high energy level throughout the day.`],
    [`h2`, `I find it very difficult to tell a lie.`],
    [`o3`, `I'm bored by subjects like science and history.`],
    [`e3`, `It matters a lot to me that I am liked.`],
  ],
]
export const questionGroup2_4 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 4 of 8`,
  [
    [`a3`, `I rarely feel irritated.`],
    [`c3`, `I am not satisfied until my work product is absolutely perfect.`],
    [`h3`, `It is ok to cheat a little to win.`],
    [`x2`, `I am very comfortable in social situations.`],
    [
      `o2`,
      `I often find myself believing or doing things very differently from most other people I know.`,
    ],
    [`x3`, `I tend to talk and express myself a lot with people.`],
  ],
]
export const questionGroup2_5 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 5 of 8`,
  [
    [`h1`, `I would like to show off expensive things I own to others.`],
    [`h2`, `Lying is extremely uncomfortable for me to do.`],
    [`h3`, `It is ok to cheat a little to win.`],
  ],
]
export const questionGroup2_6 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 6 of 8`,
  [
    [
      `c1`,
      `At home and work, I make sure everything is always well-organized and back in its place.`,
    ],
    [`c2`, `I tend to put things off, sometimes to the last minute.`],
    [`c3`, `I am not satisfied until my work product is absolutely perfect.`],
    [`o1`, `Going to an art gallery is my idea of a very good time.`],
    [
      `o2`,
      `I often find myself believing or doing things very differently from most other people I know.`,
    ],
    [`o3`, `I'm bored by subjects like science and history.`],
  ],
]
export const questionGroup2_7 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 7 of 8`,
  [
    [
      `pt1`,
      `I believe there are many relevant sides to every issue, and I put time into considering both.`,
    ],
    [
      `pt2`,
      `Any time I am upset with someone, I try to imagine the situation from their point of view.`,
    ],
    [
      `pt3`,
      `When I am sure I am right about something, I don't put too much stock into their arguments.`,
    ],
    [
      `pt4`,
      `When I provide feedback, I first consider what it would be like to receive the feedback I am giving.`,
    ],
  ],
]
export const questionGroup2_8 = [
  ``,
  `Please indicate how much you agree or disagree that each of the following statements describes you:`,
  `part 8 of 8`,
  [
    [
      `ec1`,
      `I don't generally feel very sorry for other people and their problems.`,
    ],
    [
      `ec2`,
      `I often feel great tenderness for people in difficult situations.`,
    ],
    [`ec3`, `Other people's losses are not of particular concern to me.`],
    [
      `ec4`,
      `I feel a strong sense of protectiveness toward others when they are wronged.`,
    ],
  ],
]

export const questionGroupMentor1 = [
  ``,
  `For each of the following, please indicate your level of agreement/disagreement with **how much each describes why you want to be a mentor for someone:**`,
  ``,
  [
    [
      `m_goal1`,
      `Help someone advance in their career (e.g., work toward a promotion)`,
    ],
    [`m_goal2`, `Help someone learn more about the industry`],
    [`m_goal3`, `Help someone learn new skills or develop their skills`],
    [`m_goal4`, `Meet new people across our company`],
  ],
]

export const questionGroupMentor2_1 = [
  ``,
  `For each of the following, **indicate how much you would like to mentor someone in each of the following areas.** <br>Please keep in mind your own skillset and **what you believe you would *excel* at mentoring.**`,
  `part 1 of 2`,
  [
    [`m_skill1`, `Business Acumen`],
    [`m_skill2`, `Industry Knowledge`],
    [`m_skill3`, `Collaboration and Teamwork`],
    [`m_skill4`, `Effective Communication`],
    [`m_skill5`, `Data/Business Analysis`],
    [`m_skill6`, `Innovation and Creativity`],
  ],
]
export const questionGroupMentor2_2 = [
  ``,
  `For each of the following, **indicate how much you would like to mentor someone in each of the following areas.** <br>Please keep in mind your own skillset and **what you believe you would *excel* at mentoring.**`,
  `part 2 of 2`,
  [
    [`m_skill7`, `Leadership`],
    [`m_skill8`, `Managing Change`],
    [`m_skill9`, `Negotiation and Influencing`],
    [`m_skill10`, `Presentation Skills`],
    [`m_skill11`, `Project Management`],
    [`m_skill12`, `Time Management`],
  ],
]

export const questionGroupMentee1 = [
  ``,
  `For each of the following, please indicate your level of agreement/disagreement with **how much each describes why you want to be mentored by someone:**`,
  ``,
  [
    [`p_goal1`, `Help me advance in my career (e.g., work toward a promotion)`],
    [`p_goal2`, `Help me learn more about the industry`],
    [`p_goal3`, `Help me learn new skills or develop my skills`],
    [`p_goal4`, `Meet new people across our company`],
  ],
]

export const questionGroupMentee2_1 = [
  ``,
  `For each of the following, indicate **how much you would like to be mentored by someone in each of the following areas. <br>** Please keep in mind your own skillset and **what you believe you could learn the most about in a mentorship program.**`,
  `part 1 of 2`,
  [
    [`p_skill1`, `Business Acumen`],
    [`p_skill2`, `Industry Knowledge`],
    [`p_skill3`, `Collaboration and Teamwork`],
    [`p_skill4`, `Effective Communication`],
    [`p_skill5`, `Data/Business Analysis`],
    [`p_skill6`, `Innovation and Creativity`],
  ],
]
export const questionGroupMentee2_2 = [
  ``,
  `For each of the following, indicate **how much you would like to be mentored by someone in each of the following areas. <br>** Please keep in mind your own skillset and **what you believe you could learn the most about in a mentorship program.**`,
  `part 2 of 2`,
  [
    [`p_skill7`, `Leadership`],
    [`p_skill8`, `Managing Change`],
    [`p_skill9`, `Negotiation and Influencing`],
    [`p_skill10`, `Presentation Skills`],
    [`p_skill11`, `Project Management`],
    [`p_skill12`, `Time Management`],
  ],
]
