import { useReducer, Dispatch } from 'react'
import { Params } from './params'

export enum Status {
  Unset,
  Loading,
  Loaded,
  Error,
}

export type InvokeOptions<Query> = RequestInit & {
  query?: Record<string, Query>
  version?: string
  params?: Params
  signal?: AbortSignal
  headers?: Headers
}

type BaseState<Query> = {
  count: number
  options?: InvokeOptions<Query>
}

type InitialState<Query> = BaseState<Query> & {
  status: Status.Unset
}

type LoadingState<Query> = BaseState<Query> & {
  status: Status.Loading
}

type LoadedState<Data, Query> = BaseState<Query> & {
  status: Status.Loaded
  data: Data
}

type ErrorState<Query> = BaseState<Query> & {
  status: Status.Error
  error: Error
}

export type FetchState<Data, Query> =
  | InitialState<Query>
  | LoadingState<Query>
  | LoadedState<Data, Query>
  | ErrorState<Query>

type LoadingAction<Query> = {
  type: 'LOADING'
  payload: InvokeOptions<Query>
}

export const loading = <Query>(
  payload: InvokeOptions<Query>,
): LoadingAction<Query> => ({ type: 'LOADING', payload })

type LoadedAction<T> = {
  type: 'LOADED'
  payload: T
}

export const loaded = <T>(payload: T): LoadedAction<T> => ({
  type: 'LOADED',
  payload,
})

type ErrorAction = {
  type: 'ERROR'
  payload: Error
}

export const errored = (payload: Error): ErrorAction => ({
  type: 'ERROR',
  payload,
})

type FetchAction<Data, Query> =
  | LoadingAction<Query>
  | LoadedAction<Data>
  | ErrorAction

const reducer = <Data, Query>(
  state: FetchState<Data, Query>,
  action: FetchAction<Data, Query>,
): FetchState<Data, Query> => {
  switch (action.type) {
    case 'LOADING': {
      const { payload: options } = action
      const { count } = state
      return { status: Status.Loading, count: count + 1, options }
    }
    case 'LOADED': {
      const { payload: data } = action
      const { count, options } = state
      return { status: Status.Loaded, count, data, options }
    }
    case 'ERROR': {
      const { payload: error } = action
      const { count, options } = state
      return { status: Status.Error, count, error, options }
    }
    default: {
      const { count, options } = state
      return { status: Status.Unset, count, options }
    }
  }
}

export type FetchDispatch<Data, Query> = Dispatch<FetchAction<Data, Query>>

export type UseFetchStateResult<Data, Query> = [
  FetchState<Data, Query>,
  FetchDispatch<Data, Query>,
]

export const useFetchState = <Data, Query>(
  options: InvokeOptions<Query> = {},
): UseFetchStateResult<Data, Query> => {
  const [state, dispatch] = useReducer(reducer<Data, Query>, {
    status: Status.Unset,
    count: 0,
    options,
  })

  return [state, dispatch]
}
