import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from '../../../elemets/Modal'
import colors, { headings } from '../../../theme/theme2'
import { isMobile } from '../../../utils/common'
import Icon from '../../Icons'
import { useApiFetch, useApiSubmit } from '../../../api'
import CustomSelect from '../../../elemets/CustomSelect'
import ActionButton from '../../../elemets/ActionButton'
import { Col, Row } from 'react-bootstrap'
import compose from 'just-compose'
import { withErrorHandler } from '../../../errors'
import { withNotifications } from '../../../notifications'
import CustomTextArea from '../../../elemets/CustomTextArea'
import moment from 'moment'
import CustomDate from '../../../elemets/CustomDate'
import { useCurrentUser } from '../../Context/CurrentUser'

const useStyles = createUseStyles({
  body: {
    padding: 10,
    backgroundColor: '#f8fafb',
  },
  label: {
    ...headings.title,
    fontSize: 12,
  },
  title: {
    ...headings.title,
    paddingRight: 5,
  },
  icon: {
    '& i': {
      cursor: 'default',
      paddingTop: 3,
      '&:before': {
        color: colors.primary,
      },
    },
  },
  subtitle: {
    ...headings.paragraph,
    color: colors.dark,
    fontWeight: 'bold',
  },
  bgGrey: {
    backgroundColor: colors.grey1,
  },
  modal: {
    '& .modal-content': {
      boxShadow: '0px 4px 5px 5px rgb(0 0 0 / 10%)',
    },
  },
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  select: {
    '& *': {
      fontSize: '12px!important',
    },
  },
  dateMobile: {
    '& input': {
      width: '100%',
      minWidth: isMobile() ? 180 : 220,
      fontSize: isMobile() ? '12px!important' : 'inherit',
      fontWeight: 'normal',
      height: isMobile() ? 24.5 : '',
    },
    '& .react-datepicker-wrapper': {
      width: '100%',
    },
  },
  timeMobile: {
    paddingLeft: 10,
    minWidth: isMobile() ? 122 : 135,
  },
  withoutBold: {
    minHeight: 60,
    fontWeight: 500,
  },
  textMain: {
    ...headings,
    fontSize: 14,
    paddingTop: 10,
    color: colors.dark,
  },
})

const formatTimeSelect = (timestamp) => ({
  label: timestamp.format('hh:mma'),
  value: timestamp.format('HH:mm'),
})

const getNextHalfHour = (from = moment(new Date())) => {
  const hour = from.hour()
  const minutes = from.minutes()
  if (minutes < 30) {
    return from.minutes(30)
  }
  return from.minutes(0).hour(hour + 1)
}

const valueStrToMoment = (valueStr, date) => {
  const [hour, minute] = valueStr.split(':', 2)
  return moment(date).set({ hour, minute })
}

const isFutureTime =
  (date) =>
  ({ value }) => {
    const timestamp = valueStrToMoment(value, date)
    const now = moment(new Date())
    return timestamp.isAfter(now)
  }

const SuggestedTimeRow = ({ time, setTime, index }) => {
  const classes = useStyles()
  const [hour, setHour] = useState(formatTimeSelect(getNextHalfHour()))
  const [date, setDate] = useState(time.toDate())

  const timeList = useMemo(() => {
    return [
      { label: '12:00am', value: '00:00' },
      { label: '12:30am', value: '00:30' },
      { label: '01:00am', value: '01:00' },
      { label: '01:30am', value: '01:30' },
      { label: '02:00am', value: '02:00' },
      { label: '02:30am', value: '02:30' },
      { label: '03:00am', value: '03:00' },
      { label: '03:30am', value: '03:30' },
      { label: '04:00am', value: '04:00' },
      { label: '04:30am', value: '04:30' },
      { label: '05:00am', value: '05:00' },
      { label: '05:30am', value: '05:30' },
      { label: '06:00am', value: '06:00' },
      { label: '06:30am', value: '06:30' },
      { label: '07:00am', value: '07:00' },
      { label: '07:30am', value: '07:30' },
      { label: '08:00am', value: '08:00' },
      { label: '08:30am', value: '08:30' },
      { label: '09:00am', value: '09:00' },
      { label: '09:30am', value: '09:30' },
      { label: '10:00am', value: '10:00' },
      { label: '10:30am', value: '10:30' },
      { label: '11:00am', value: '11:00' },
      { label: '11:30am', value: '11:30' },
      { label: '12:00pm', value: '12:00' },
      { label: '12:30pm', value: '12:30' },
      { label: '01:00pm', value: '13:00' },
      { label: '01:30pm', value: '13:30' },
      { label: '02:00pm', value: '14:00' },
      { label: '02:30pm', value: '14:30' },
      { label: '03:00pm', value: '15:00' },
      { label: '03:30pm', value: '15:30' },
      { label: '04:00pm', value: '16:00' },
      { label: '04:30pm', value: '16:30' },
      { label: '05:00pm', value: '17:00' },
      { label: '05:30pm', value: '17:30' },
      { label: '06:00pm', value: '18:00' },
      { label: '06:30pm', value: '18:30' },
      { label: '07:00pm', value: '19:00' },
      { label: '07:30pm', value: '19:30' },
      { label: '08:00pm', value: '20:00' },
      { label: '08:30pm', value: '20:30' },
      { label: '09:00pm', value: '21:00' },
      { label: '09:30pm', value: '21:30' },
      { label: '10:00pm', value: '22:00' },
      { label: '10:30pm', value: '22:30' },
      { label: '11:00pm', value: '23:00' },
      { label: '11:30pm', value: '23:30' },
    ].filter(isFutureTime(date))
  }, [date])

  useEffect(() => {
    if (time) {
      setHour(formatTimeSelect(time))
    }
  }, [time])

  const handleDateChange = useCallback((date) => {
    setDate(date)
    setTime((s) =>
      s.map((d, i) =>
        i === index
          ? moment(date).set({ hour: d.hours(), minute: d.minutes() })
          : d,
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelect = useCallback((key, value) => {
    setHour(value)
    setTime((s) =>
      s.map((d, i) => (i === index ? valueStrToMoment(value.value, d) : d)),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`d-flex justify-content-center p-2`}>
      <CustomDate
        name="startDate"
        placeholder="Start Date"
        value={date}
        onChange={(date) => {
          handleDateChange(date)
        }}
        mask={false}
        containerStyle={classes.dateMobile}
        valueToDisplay={moment(date).format('dddd, MMM DD, YYYY')}
      />
      <CustomSelect
        options={timeList.slice(0, -1)}
        name="startTime"
        value={isFutureTime(date)(hour) ? hour : null}
        onSelect={handleSelect}
        className={`${classes.timeMobile}`}
        isMobile={isMobile()}
      />
    </div>
  )
}

const RequestAvailabilityModal = ({
  match,
  setRequestedAvailability,
  show,
  onClose,
  handleError,
  pushNotification,
}) => {
  const classes = useStyles()
  const [topic, setTopic] = useState(null)
  const [notes, setNotes] = useState('')
  const [suggested, setSuggested] = useState([getNextHalfHour()])
  const { currentUser } = useCurrentUser()

  const {
    submitted: submittedAvailability,
    submit: addAvailability,
    submitting: addingAvailability,
    err: errorAvailability,
  } = useApiSubmit('addAvailabilityV1')

  const {
    data: requestAvailabilityResult,
    loading: requestingAvailability,
    err: errorMatchAvailability,
    fetch: fetchMatchAvailability,
  } = useApiFetch('requestAvailability', {}, false)

  const canSubmit = useMemo(() => {
    const now = moment(new Date())
    return (
      match &&
      topic &&
      suggested?.length > 0 &&
      suggested.every((suggestion) => suggestion.isAfter(now))
    )
  }, [match, topic, suggested])

  const submitting = useMemo(
    () => addingAvailability || requestingAvailability,
    [addingAvailability, requestingAvailability],
  )

  const handleRequestAvailability = useCallback(() => {
    if (canSubmit) {
      fetchMatchAvailability({
        query: { token: match.token },
        body: {
          skillId: parseInt(topic.value, 10),
          notes: notes,
          suggestedDates: suggested.map((d) =>
            d.set({ seconds: 0 }).utc().format(),
          ),
        },
      })

      suggested.forEach((timestamp) => {
        addAvailability({
          body: {
            startTime: timestamp.set({ seconds: 0 }).format(),
            endTime: timestamp.add(30, 'minutes').set({ seconds: 0 }).format(),
          },
        })
      })
    }
  }, [
    fetchMatchAvailability,
    addAvailability,
    canSubmit,
    match?.token,
    topic?.value,
    notes,
    suggested,
  ])

  useEffect(() => {
    if (
      show &&
      submittedAvailability &&
      requestAvailabilityResult !== null &&
      requestAvailabilityResult !== undefined
    ) {
      pushNotification({
        level: 'success',
        subject: 'Request sent.',
        timeout: 10,
      })
      setRequestedAvailability(true)
      onClose()
    }
  }, [
    show,
    submittedAvailability,
    requestAvailabilityResult,
    pushNotification,
    setRequestedAvailability,
    onClose,
  ])

  const matchingTopics = useMemo(
    () =>
      match?.topics
        ?.filter(
          // filter topics so that only the intersection of topics from current user and matched user are shown
          ({ skillId: matchSkillId }) =>
            currentUser?.topics?.find(
              ({ skillId: userSkillId }) => matchSkillId === userSkillId,
            ),
        )
        .map(({ skillName, skillId }) => ({
          label: skillName,
          value: skillId,
        })),
    [match?.topics, currentUser?.topics],
  )

  useEffect(() => {
    if (errorAvailability) {
      handleError(errorAvailability, true, {
        message: 'There was a problem adding the availability.',
      })
    }
  }, [errorAvailability, handleError])

  useEffect(() => {
    if (errorMatchAvailability) {
      handleError(errorMatchAvailability, true, {
        message: 'Unable to request additional availability.',
      })
    }
  }, [errorMatchAvailability, handleError])

  useEffect(() => {
    if (matchingTopics.length > 0) {
      setTopic(matchingTopics[0])
    } else {
      setTopic({
        label: match.topics[0].skillName,
        value: match.topics[0].skillId,
      })
    }
  }, [match.topics, matchingTopics])

  const handleSelect = useCallback((key, value) => {
    setTopic(value)
  }, [])

  const handleAddMoreTimeSlot = useCallback(() => {
    setSuggested((s) => {
      const last = s.length > 0 ? s[s.length - 1] : null
      const fromTime = last || moment(new Date())
      return [...s, getNextHalfHour(fromTime)]
    })
  }, [])

  return (
    // TODO: meet now.
    <Modal
      show={show}
      close={onClose}
      title={
        <div
          className={`${classes.icon} ${
            isMobile() ? 'd-flex justify-content-center' : ''
          }`}
        >
          <label className={`${classes.title}`}>Request Availability </label>
          <Icon icon="fas fa-calendar-alt" />
        </div>
      }
      closeIcon={true}
      bodyClass={classes.body}
      modalClass={`${!isMobile() ? classes.modal : ''}`}
      dialogClassName={isMobile() ? classes.dialogMobile : ''}
    >
      <span className={`${classes.subtitle} ml-2`}>
        What topic do you want to have a conversation about?
      </span>
      <Row className={`my-3`}>
        <Col
          xs={isMobile() ? 12 : 4}
          className={`pr-0 ${isMobile() ? 'pl-0' : ''}`}
        >
          <span className={`${classes.subtitle} ml-4 mr-2`}>
            Topic of Interest?
          </span>
        </Col>
        <Col
          xs={isMobile() ? { span: 10, offset: 1 } : 7}
          className={`p-0 pb-2 ${isMobile() ? 'pt-2' : ''}`}
        >
          <CustomSelect
            options={
              matchingTopics.length > 0
                ? matchingTopics
                : match.topics.map((topic) => {
                    return { label: topic.skillName, value: topic.skillId }
                  })
            }
            className={classes.select}
            value={topic}
            onSelect={handleSelect}
          />
        </Col>
      </Row>

      <span className={`${classes.subtitle} ml-2`}>
        Would you like to suggest a Date and Time?
      </span>
      <Row className={`mb-3`}>
        <Col xs={12} className={'px-4 py-2'}>
          {suggested.map((time, index) => {
            return (
              <SuggestedTimeRow
                key={index}
                index={index}
                time={time}
                setTime={setSuggested}
              />
            )
          })}
          {suggested.length < 3 && (
            <div
              className={`d-flex justify-content-center ${classes.icon} pt-2`}
            >
              <span className={`${classes.subtitle} mr-3`}>
                Suggest another Date and Time
              </span>
              <Icon
                icon="fas fa-plus-circle"
                onClick={() => {
                  handleAddMoreTimeSlot()
                }}
              />
            </div>
          )}
        </Col>
      </Row>
      <span className={`${classes.subtitle} ml-2`}>
        Include a note with your request.
      </span>
      <Row>
        <Col xs={12}>
          <CustomTextArea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            editMode={true}
            className={`ml-2 ${classes.withoutBold}`}
            maxLength={200}
          />
        </Col>
      </Row>
      <div className={`mt-3 d-flex justify-content-end`}>
        <ActionButton
          className={`${classes.actionButton}`}
          type={'STRONG_GREEN'}
          text={'SEND REQUEST'}
          onClick={handleRequestAvailability}
          disabled={!canSubmit || submitting}
        />
      </div>
    </Modal>
  )
}
export default compose(
  withErrorHandler,
  withNotifications,
)(RequestAvailabilityModal)
