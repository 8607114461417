import React, { useMemo, useState, useCallback, useEffect } from 'react'
import CardContainer from '../../elemets/CardContainer'
import { createUseStyles } from 'react-jss'
import { Row, Col } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

// Theme
import colors from '../../theme/theme2'

// Elements
import ActionButton from '../../elemets/ActionButton'
import { Typeaheads } from '../Forms/Input'
import { isMobile } from '../../utils/common'

const useStyles = createUseStyles({
  card: {
    backgroundColor: colors.primary,
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    borderRadius: isMobile() ? 0 : 4,
  },
  newTitle: {
    fontFamily: 'Lato',
    fontSize: 18,
    fontWeight: 'bold',
    color: '#ffffff',
  },
  selectTitle: {
    color: '#ffffff',
    paddingLeft: 14,
    fontFamily: 'Lato',
    fontSize: 14,
    lineHeight: 1.5,
  },
})

const NewConversationCard = ({
  user,
  defaultValues,
  onFind,
  buttonText,
  setParams,
  history,
}) => {
  const classes = useStyles()
  const [formState, setFormState] = useState({
    topic: '',
    group: '',
  })

  // MEMOS
  const interestsOptions = useMemo(() => {
    let options = []
    if (user && user.topics) {
      options = user.topics.map((topic) => ({
        label: topic.skillName,
        value: topic.skillId,
      }))
    }
    return options
  }, [user])

  const groupsOptions = useMemo(() => {
    let options = []
    if (user && user.groups) {
      options = user.groups.map((topic) => ({
        label: topic.name,
        value: topic.id,
      }))
    }
    return options
  }, [user])

  useEffect(() => {
    if (defaultValues && interestsOptions && groupsOptions) {
      const selectedInterest = interestsOptions.find(
        (option) => option.value === parseInt(defaultValues.skillId, 10),
      )
      const selectedGroup = groupsOptions.find(
        (option) => option.value === parseInt(defaultValues.groupId, 10),
      )
      setFormState({
        topic: selectedInterest || '',
        group: selectedGroup || '',
      })
    }
  }, [defaultValues, interestsOptions, groupsOptions])

  // CALLBACKS
  const handleSelect = useCallback((key, select) => {
    if (select && select.length > 0) {
      setFormState((f) => ({
        ...f,
        [key]: select[0],
      }))
    } else {
      setFormState((f) => ({
        ...f,
        [key]: '',
      }))
    }
  }, [])

  const handleFindMatch = useCallback(() => {
    if (setParams) {
      if (
        formState.topic === '' ||
        formState.group === '' ||
        formState.topic === undefined ||
        formState.group === undefined
      ) {
        history.push(`/conversations`)
      } else if (formState.topic !== '' && formState.group !== '') {
        setParams({
          skillId: formState.topic.value,
          groupId: formState.group.value,
        })
      }
    }
    if (onFind) {
      onFind({
        skillId: formState.topic.value,
        groupId: formState.group.value,
      })
    } else {
      history.push(
        `/matches?skillId=${formState.topic.value}&groupId=${formState.group.value}`,
      )
    }
  }, [formState, history, onFind, setParams])

  return (
    <CardContainer className={classes.card}>
      {isMobile() ? (
        <Row className="mt-5">
          <Col>
            <Row noGutters className={`${classes.newTitle} mb-3`}>
              <i className="fa fa-comments"></i>
              <span className="ml-2">New Conversation</span>
            </Row>
            <div className="mb-3">
              <span className={classes.selectTitle}>Topic of Interest</span>
              <Typeaheads
                id="filter-topic"
                placeholder="Select"
                options={interestsOptions}
                defaultSelected={[formState.topic]}
                onChange={(selected) => handleSelect('topic', selected)}
                clearButton={true}
                className={classes.input}
                selected={[formState.topic]}
              />
            </div>
            <div className="mb-3">
              <span className={classes.selectTitle}>Group</span>
              <Typeaheads
                id="filter-topic"
                placeholder="Select"
                options={groupsOptions}
                defaultSelected={[formState.group]}
                onChange={(selected) => handleSelect('group', selected)}
                clearButton={true}
                className={classes.input}
                selected={[formState.group]}
              />
            </div>
            <div className="d-flex justify-content-end">
              <ActionButton
                className="align-self-end"
                text={buttonText}
                type="LIGHT_GREEN"
                disabled={!formState.topic || !formState.group}
                onClick={handleFindMatch}
              />
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={3}>
            <Row noGutters className={classes.newTitle}>
              <i className="fa fa-comments"></i>
              <span className="ml-2">New Conversation</span>
            </Row>
          </Col>
          <Col>
            <span className={classes.selectTitle}>Topic of Interest</span>
            <Typeaheads
              id="filter-topic"
              placeholder="Select"
              options={interestsOptions}
              defaultSelected={[formState.topic]}
              onChange={(selected) => handleSelect('topic', selected)}
              clearButton={true}
              className={classes.input}
              selected={[formState.topic]}
            />
          </Col>
          <Col>
            <span className={classes.selectTitle}>Group</span>
            <Typeaheads
              id="filter-topic"
              placeholder="Select"
              options={groupsOptions}
              defaultSelected={[formState.group]}
              onChange={(selected) => handleSelect('group', selected)}
              clearButton={true}
              className={classes.input}
              selected={[formState.group]}
            />
          </Col>
          <Col xs={2} className="d-flex">
            <ActionButton
              className="align-self-end"
              text={buttonText}
              type="LIGHT_GREEN"
              // disabled={!formState.topic || !formState.group}
              onClick={handleFindMatch}
            />
          </Col>
        </Row>
      )}
    </CardContainer>
  )
}

export default withRouter(NewConversationCard)
