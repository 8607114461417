import { Badge } from '@material-ui/core'
import React from 'react'
import { useChatConversationProvider } from '../Context/ChatConversationProvider'
import Icon from './Icon'

const Message = props => {
  const { notification, setNotification } = useChatConversationProvider()
  return (
    <Badge
      color="secondary"
      badgeContent={notification}
      max={99}
      onClick={() => setNotification(0)}
    >
      <Icon icon="far fa-comment-alt" {...props} />
    </Badge>
  )
}
export default Message
