// @flow
import React, { Component, ErrorInfo, ReactNode } from 'react'
// import { withScope, getClient } from '@sentry/react'
import { datadogRum } from '@datadog/browser-rum'
import Container from 'react-bootstrap/Container'
import useErrorHandler from './useErrorHandler'

type ErrorHandler = (err: Error, errInfo: { componentStack: string }) => void

type ErrorBoundaryHandlerProps = {
  onError?: ErrorHandler | null | undefined
  children: ReactNode
}

type ErrorBoundaryHandlerState = {
  hasError: boolean
}

const ErrorBoundaryHandler = class extends Component<
  ErrorBoundaryHandlerProps,
  ErrorBoundaryHandlerState
> {
  constructor(props: ErrorBoundaryHandlerProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  static defaultProps = {
    hasError: null,
  }

  componentDidCatch(err: Error, info: ErrorInfo) {
    // Datadog error handling
    const renderingError = new Error(err.message)
    renderingError.name = `ReactRenderingError`
    renderingError.stack = info?.componentStack ?? ''
    renderingError.cause = err
    datadogRum.addError(renderingError)

    // // Sentry error handling
    // if (getClient()) {
    //   withScope((scope) => {
    //     scope.setExtras({ componentStack: info.componentStack })
    //     const { onError } = this.props
    //     if (typeof onError === 'function')
    //       onError(err, { componentStack: info?.componentStack ?? '' })
    //   })
    // }
  }

  render() {
    const { hasError } = this.state
    if (hasError) {
      return (
        <Container style={{ paddingTop: 75 }}>
          <h1>Something went wrong</h1>
          <p>
            Whoops! An error has occurred. Please refresh the page and try
            again.
          </p>
        </Container>
      )
    }
    const { children } = this.props
    return children || null
  }
}

type ErrorBoundaryProps = {
  children: ReactNode
}

const ErrorBoundary = ({ children }: ErrorBoundaryProps): JSX.Element => {
  const errorHandler = useErrorHandler()
  const onError = (err: Error) => errorHandler(err, false)
  return (
    <ErrorBoundaryHandler onError={onError}>{children}</ErrorBoundaryHandler>
  )
}

export default ErrorBoundary
