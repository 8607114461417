import React from 'react'
import colors, { headings } from '../../theme/theme2'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  text: {
    ...headings.paragraph,
    fontWeight: 'bold',
    color: colors.primary,
  },
  icon: {
    color: colors.primary,
    fontSize: 18,
  },
})

const MatchIcon = ({ score }) => {
  const classes = useStyles()

  if (score >= 3) {
    return (
      <div className="d-flex">
        <i className={`fa fa-bookmark ${classes.icon} mr-1`}></i>{' '}
        <span className={classes.text}>Exact Match</span>
      </div>
    )
  }
  return null
}

export default MatchIcon
