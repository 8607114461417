import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'

// Theme
import colors from '../../theme/theme2'
import ConfirmationModal from '../Modals/ConfirmationModal'
import { isMobile } from '../../utils/common'
import moment from 'moment'

const useStyles = createUseStyles({
  modal: {
    '& .modal-content': {
      width: '70%!important',
    },
    '& .modal-content > div > div': {
      backgroundColor: 'transparent',
      margin: 0,
      paddingBottom: 0,
    },
    '& label:first-child': {
      width: '100%',
      textAlign: 'center',
    },
  },
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  dialogDesktop: {
    minWidth: 600,
  },
  lattusCard: {
    backgroundColor: colors.primary,
  },
  textBold: {
    fontWeight: 'bold',
  },
  lattusText: {
    marginBottom: 0,
    color: 'white',
    fontSize: 12,
    fontFamily: 'Lato',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  calendarCard: {
    backgroundColor: colors.grey1,
  },
  calendarText: {
    marginBottom: 0,
    color: colors.grey3,
    fontSize: 12,
    fontFamily: 'Lato',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  availabilityCard: {
    backgroundColor: colors.primary100,
    height: '100%',
  },
  availabilityText: {
    marginBottom: 0,
    color: colors.primary,
    fontSize: 12,
    fontFamily: 'Lato',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  lightGreenDot: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: '#9ad5b2',
    marginRight: 4,
  },
  strongGreenDot: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: colors.primary,
    marginRight: 4,
  },
  greyDot: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: '#d8d8d8',
    marginRight: 4,
  },
  weekItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  weekItemText: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: colors.grey3,
    marginBottom: 0,
  },
  dayName: {
    fontSize: 12,
    fontWeight: 'normal',
    margin: 0,
  },
  dayNumberText: {
    fontSize: 32,
    margin: 0,
  },
  title: {
    textAlign: 'center',
  },
})

const ShowMoreModal = ({ show, onClose, currentDay, availabilityList }) => {
  const classes = useStyles()
  const getMonthCustomEvent = useCallback(
    event => {
      let coloredDot = {
        card: classes.calendarCard,
        text: classes.calendarText,
      }
      switch (event.type) {
        case 'availability':
          coloredDot = classes.lightGreenDot
          break
        case 'conversation':
          coloredDot = classes.strongGreenDot
          break
        case 'calendar':
          coloredDot = classes.greyDot
          break
        default:
          break
      }

      return (
        <div className={classes.weekItem}>
          <div className={coloredDot} />
          <label className={classes.weekItemText}>
            {moment(event.start).format('Ha')} {event.title}
          </label>
        </div>
      )
    },
    [
      classes.calendarCard,
      classes.calendarText,
      classes.greyDot,
      classes.lightGreenDot,
      classes.strongGreenDot,
      classes.weekItem,
      classes.weekItemText,
    ],
  )

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={
        <div className={`${classes.title}`}>
          <p className={classes.dayName}>
            {moment(currentDay).format('dddd').substr(0, 3).toUpperCase()}
          </p>
          <p className={classes.dayNumberText}>
            {moment(currentDay).format('DD')}
          </p>
        </div>
      }
      content={
        <>
          {availabilityList.map(event => {
            return getMonthCustomEvent(event)
          })}
        </>
      }
      closeIcon={true}
      className={`${classes.modal} ${
        isMobile() ? classes.dialogMobile : classes.dialogDesktop
      }`}
    />
  )
}

export default ShowMoreModal
