import React from 'react'
import Button from 'react-bootstrap/Button'
import { createUseStyles } from 'react-jss'

type CancelProps = {
  onClick: () => void
  disabled: boolean
}

const useStyles = createUseStyles({
  root: {
    border: 'none',
    '&:hover': {
      boxShadow: '0 3px 6px 0 rgba(153, 155, 168, 0.61)',
    },
  },
})

const Cancel = ({ onClick, disabled }: CancelProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Button
      className={classes.root}
      variant="secondary"
      onClick={onClick}
      disabled={disabled}
    >
      Cancel
    </Button>
  )
}

export default Cancel
