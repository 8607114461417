// src/components/MyProfile.js
import React from 'react'
import { Container } from 'react-bootstrap'
import { useApiFetch } from '../../api'
import Personal from './Personal'
import { useErrorHandler } from '../../errors'
import LoaderPage from '../../elemets/LoaderPage'

const MyProfile = () => {
  const {
    data: profile,
    err: profileErr,
    loading: profileLoading,
    reload: reloadProfile,
  } = useApiFetch('getCurrentProfile')
  const handleError = useErrorHandler()

  if (profileErr) {
    handleError(profileErr, true, {
      message: 'There was a problem loading your profile.',
    })
  }

  return (
    <Container>
      {profileLoading && <LoaderPage />}
      {profile && (
        <Personal user={profile} type="edit" reload={reloadProfile} />
      )}
    </Container>
  )
}

export default MyProfile
