import React, { ReactNode, createContext, useContext } from 'react'
import { Participant } from './Participant'

type ContextValue = {
  candidates: Participant[]
  ready: boolean
}

export const Context = createContext<ContextValue>({
  candidates: [],
  ready: false,
})

type ParticipantsProviderProps = {
  candidates: Participant[]
  ready?: boolean
  children: ReactNode
}

const ParticipantsProvider = ({
  candidates,
  ready = true,
  children,
}: ParticipantsProviderProps): JSX.Element => (
  <Context.Provider value={{ candidates, ready }}>{children}</Context.Provider>
)

export default ParticipantsProvider

export const useParticipants = (): ContextValue => useContext(Context)
