import React from 'react'
import { useGroups } from '@/groups/GroupsContext'
import GroupLabel from './GroupLabel'

const CurrentGroup = (): JSX.Element | null => {
  const { active } = useGroups()
  if (!active) {
    return null
  }

  const { name } = active
  return <GroupLabel name={name} />
}

export default CurrentGroup
