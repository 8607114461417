import React from 'react'
import Notifications from './Notifications'
import { usePolling } from '../hooks'

type AppNotificationsProps = {
  maxNotifications?: number
}

const AppNotifications = ({
  maxNotifications = 3,
}: AppNotificationsProps): JSX.Element | null => {
  const [{ notifications = [] }, actions] = usePolling()
  if (notifications.length < 1) return null
  return (
    <Notifications
      notifications={notifications.slice(0, maxNotifications)}
      clearNotification={actions.clearNotification}
    />
  )
}

AppNotifications.defaultProps = {
  maxNotifications: 3,
}

export default AppNotifications
