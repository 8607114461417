import React from 'react'
import { createUseStyles } from 'react-jss'
import clsx from 'clsx'
import CallButton from './CallButton'
import DeviceStatus from './DeviceStatus'

type StatusProps = {
  deviceStatus: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  device: Record<string, any> | undefined | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  connection: Record<string, any> | undefined | null
  warningDetail: string | undefined | null
  close: () => void
}

const labels = new Map([
  [DeviceStatus.Unset, 'Loading...'],
  [DeviceStatus.Waiting, 'Waiting for the other user to connect...'],
  [DeviceStatus.Ready, 'Ready to start!'],
  [DeviceStatus.IncomingCall, 'Incoming conversation'],
  [DeviceStatus.Rejected, 'Conversation rejected'],
  [DeviceStatus.Cancelled, 'Conversation cancelled'],
  [DeviceStatus.Connected, 'Conversation in progress'],
  [DeviceStatus.Disconnected, 'Conversation ended'],
  [DeviceStatus.Reconnecting, 'Connection lost; retrying...'],
  [DeviceStatus.Warning, 'There may be issues with the conversation'],
  [DeviceStatus.Offline, 'You are offline'],
  [DeviceStatus.Error, 'An error occurred'],
])

const styles = new Map([
  [DeviceStatus.Ready, 'connected'],
  [DeviceStatus.IncomingCall, 'connected'],
  [DeviceStatus.Connected, 'connected'],
  [DeviceStatus.Rejected, 'problem'],
  [DeviceStatus.Cancelled, 'problem'],
  [DeviceStatus.Reconnecting, 'problem'],
  [DeviceStatus.Warning, 'problem'],
  [DeviceStatus.Error, 'problem'],
])

const useStyles = createUseStyles<string>(() => ({
  root: {
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    height: '70px',
  },
  message: {
    flexGrow: 1,
    '& > p': {
      margin: 0,
      fontSize: '1.1rem',
      letterSpacing: '-.05rem',
    },
  },
  waiting: {
    backgroundColor: '#21a7f2',
    color: '#343434',
  },
  problem: {
    backgroundColor: '#fe4164',
    color: '#343434',
  },
  connected: {
    backgroundColor: '#4eba7e',
    color: '#343434',
  },
}))

const Status = ({
  deviceStatus,
  device,
  connection,
  close,
  warningDetail,
}: StatusProps): JSX.Element => {
  const classes = useStyles()
  const hasDevice = !!device
  const hasConnection = !!connection
  const message = labels.get(deviceStatus) || 'Unknown'

  return (
    <div
      className={clsx(
        classes.root,
        classes[styles.get(deviceStatus) || 'waiting'],
      )}
    >
      <div className={classes.message}>
        <p>{message}</p>
        {warningDetail && (
          <p>
            <small>{warningDetail}</small>
          </p>
        )}
      </div>
      <div>
        {deviceStatus === DeviceStatus.Ready && (
          <CallButton
            disabled={!hasDevice}
            onClick={() => device && device.connect()}
          >
            Start conversation
          </CallButton>
        )}
        {deviceStatus === DeviceStatus.IncomingCall && (
          <>
            <CallButton
              disabled={!hasConnection}
              onClick={() => connection && connection.accept()}
            >
              Join conversation
            </CallButton>
            <CallButton
              disabled={!hasConnection}
              onClick={() => connection && connection.reject()}
            >
              Reject conversation
            </CallButton>
          </>
        )}
        {[DeviceStatus.Connected, DeviceStatus.Warning].includes(
          deviceStatus,
        ) && (
          <CallButton
            disabled={!hasConnection}
            onClick={() => connection && connection.disconnect()}
          >
            End conversation
          </CallButton>
        )}
        {[
          DeviceStatus.Waiting,
          DeviceStatus.Ready,
          DeviceStatus.Cancelled,
          DeviceStatus.Disconnected,
          DeviceStatus.Offline,
          DeviceStatus.Error,
        ].includes(deviceStatus) && (
          <CallButton onClick={() => close()}>Close</CallButton>
        )}
      </div>
    </div>
  )
}

export default Status
