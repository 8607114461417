import React, { useState, useCallback } from 'react'
import colors, { actionButtonStyles, headings } from '../../theme/theme2'
import { createUseStyles } from 'react-jss'
import { Row, Col, Button } from 'react-bootstrap'
import compose from 'just-compose'

import { withErrorHandler } from '../../errors'
import { withNotifications } from '../../notifications'

import ActionButton from '../../elemets/ActionButton'
import CardContainer from '../../elemets/CardContainer'
import Divider from '../../elemets/Divider'
import { decodeAtoBInputs, isMobile } from '../../utils/common'
import MatchIcon from './MatchIcon'
import ClockIcon from '../Icons/Clock'
import RequestAvailabilityModal from '../Modals/Conversation/RequestAvailabilityModal'
import TopicsContainer from '../Topics/TopicsContainer'
import GravatarContainer from '../Users/GravatarContainer'

const useStyles = createUseStyles({
  card: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    marginBottom: 8,
  },
  mobile: {
    minHeight: 90,
  },
  expandMobile: {
    top: '30px!important',
  },
  expand: {
    position: 'absolute',
    top: 10,
    right: 20,
    zIndex: 500,
  },
  heart: {
    position: 'absolute',
    top: 10,
    left: 0,
    zIndex: 500,
  },
  arrows: {
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },
  experience: {
    top: -6,
    right: '25%',
    position: 'absolute',
    color: colors.primary,
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: 'bold',
  },
  avatar: {
    fontSize: '24px !important',
    fontWeight: 'bold !important',
    color: `${colors.primary} !important`,
    backgroundColor: `#eff9f3!important`,
  },
  name: {
    ...headings.title,
    marginTop: 10,
  },
  position: {
    ...headings.paragraph,
  },
  bioTitle: {
    ...headings.paragraph,
    fontWeight: 'bold!important',
    color: colors.dark,
  },
  bio: {
    ...headings.paragraph,
    color: colors.grey3,
  },
  bioRow: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  expandButton: {
    fontFamily: 'Lato',
    fontSize: 12,
    textAlign: 'right',
  },
  matchMobile: {
    marginTop: -15,
  },
  matchIcon: {
    width: 95,
  },
  counter: {
    color: colors.primary,
  },
})

const MatchRow = ({ user, setSelectedOption, fetchScheduled }) => {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)
  //const [heart, setHeart] = useState(false)
  const [showRequestModal, setShowRequestModal] = useState(false)
  const [requested, setRequested] = useState(false)

  const handleSchedule = useCallback(() => {
    setSelectedOption({
      user,
    })
    fetchScheduled({ query: { token: user.token } })
  }, [setSelectedOption, user, fetchScheduled])

  const renderButton = useCallback(() => {
    if (user.availabilities > 0) {
      return (
        <ActionButton
          height="34px"
          text={
            <>
              <ClockIcon
                style={{
                  color: actionButtonStyles.secondary.text,
                  cursor: 'default',
                  marginRight: 10,
                }}
              />
              SCHEDULE
            </>
          }
          onClick={handleSchedule}
          type="LIGHT_GREEN"
          className="text-nowrap"
        />
      )
    }
    return (
      <ActionButton
        height="34px"
        text={!requested ? 'REQUEST AVAILABILITY' : 'AVAILABILITY REQUESTED'}
        onClick={() => {
          setShowRequestModal(true)
        }}
        type="LIGHT_GREEN"
        className="text-nowrap"
        disabled={requested}
      />
    )
  }, [user, handleSchedule, requested])

  return (
    <CardContainer
      className={`${classes.card} ${isMobile() ? classes.mobile : 'pb-0'} `}
    >
      {showRequestModal && (
        <RequestAvailabilityModal
          show={showRequestModal}
          match={user}
          setRequestedAvailability={setRequested}
          onClose={() => setShowRequestModal(false)}
        />
      )}
      {!isMobile() ? (
        <>
          <div className={`${classes.expand} d-flex align-items-center`}>
            <MatchIcon score={user.score} />
          </div>
          {/*<Button
        className={`${classes.heart} ${classes.arrows}`}
        onClick={() => {
          setHeart(!heart)
        }}
      >
        <span>
          <i className={` ${heart ? 'fas' : 'far'} fa-heart`} />
        </span>
      </Button>*/}
          <Row>
            <Col xs={5} md={4} className={'pt-2 d-flex'}>
              <GravatarContainer size={70} profile={user} />
            </Col>
            <Col xs={4} md={5} className={'pt-3 px-0'}>
              <TopicsContainer topics={user.topics} displaySummary={false} />
            </Col>
            <Col xs={3} className={'pt-4 pl-0'}>
              <div className={'d-flex justify-content-end'}>
                {renderButton()}
              </div>
              <div className={'d-flex justify-content-end'}>
                <Button
                  className={`${classes.arrows}`}
                  onClick={() => {
                    setExpand(!expand)
                  }}
                >
                  <span className={classes.expandButton}>
                    {expand ? 'Show Less' : 'Show More'}
                    <i
                      className={`ml-1 fas ${
                        expand ? 'fa-chevron-down' : 'fa-chevron-right'
                      }`}
                    />
                  </span>
                </Button>
              </div>
            </Col>
          </Row>
          {expand && (
            <Row>
              <Col xs={12} className={classes.bioRow}>
                <Divider className="mt-3" />
                <span className={classes.bioTitle}>
                  Bio / Executive Summary
                </span>
                <p className={`mt-1 ${classes.bio}`}>
                  {decodeAtoBInputs(user.bio)}
                </p>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <>
          <Row className="align-items-center justify-content-end">
            {user.availabilities > 0 && (
              <ClockIcon
                style={{
                  color: colors.primary,
                  cursor: 'default',
                  marginRight: 5,
                }}
              />
            )}
            <div className={classes.matchIcon}>
              <MatchIcon score={user.score} />
            </div>
            <div className={classes.expandButton}>
              <Button
                className={classes.arrows}
                onClick={() => {
                  setExpand(!expand)
                }}
              >
                <span>
                  <i
                    className={`fas ${
                      expand ? 'fa-chevron-down' : 'fa-chevron-right'
                    }`}
                  />
                </span>
              </Button>
            </div>
          </Row>
          {/*<Button
        className={`${classes.heart} ${classes.arrows}`}
        onClick={() => {
          setHeart(!heart)
        }}
      >
        <span>
          <i className={` ${heart ? 'fas' : 'far'} fa-heart`} />
        </span>
      </Button>*/}
          <Row className={classes.matchMobile}>
            <Col className="pt-2 pl-4">
              <GravatarContainer profile={user} size={60} />
            </Col>
          </Row>
          {!expand && (
            <div className={'d-flex justify-content-end mt-3'}>
              {renderButton()}
            </div>
          )}
          {expand && (
            <Row>
              <Col xs={12} className={`${classes.bioRow} pt-3`}>
                <TopicsContainer topics={user.topics} displaySummary={true} />
                {user.bio && (
                  <>
                    <Divider className="mt-3 mb-4" />
                    <span className={classes.bioTitle}>
                      Bio / Executive Summary
                    </span>
                    <p className={`mt-1 ${classes.bio}`}>
                      {decodeAtoBInputs(user.bio)}
                    </p>
                  </>
                )}
                <div className={'d-flex justify-content-end'}>
                  {renderButton()}
                </div>
              </Col>
            </Row>
          )}
        </>
      )}
    </CardContainer>
  )
}

export default compose(withErrorHandler, withNotifications)(MatchRow)
