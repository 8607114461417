import React, { type FC, useCallback, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { createUseStyles } from 'react-jss'
import { format } from 'date-fns'

import { useErrorHandler } from '../../../errors'
import { useNotifications } from '../../../notifications'
import Modal from '../../../elemets/Modal'
import ActionButton from '../../../elemets/ActionButton'
import colors, { headings } from '../../../theme/theme2'
import { isMobile } from '@/utils/common.js'
import Icon from '../../Icons'
import CustomTextArea from '../../../elemets/CustomTextArea'
import { useCurrentUser } from '../../Context/CurrentUser'
import { useGroups } from '@/groups/GroupsContext'
import moment from 'moment'
import { useApiSubmit } from '@/api'

const useStyles = createUseStyles({
  body: {
    margin: 0,
    padding: 0,
  },
  label: {
    ...headings.title,
    fontSize: 12,
  },
  title: {
    ...headings.title,
    paddingRight: 5,
  },
  subtitle: {
    ...headings.paragraph,
    color: colors.dark,
    fontWeight: 'bold',
  },
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  modal: {
    '& .modal-dialog': {
      minWidth: 730,
    },
  },
  icon: {
    '& i': {
      cursor: 'default',
      paddingTop: 3,
      '&:before': {
        color: colors.primary,
      },
    },
  },
  customTextArea: {
    minHeight: 100,
  },
})

type Props = {
  match: any
  show: boolean
  onClose: () => void
  reloadUpcoming?: () => void
}

const MeetNowModal: FC<Props> = ({
  match,
  show,
  onClose,
  reloadUpcoming,
}: Props) => {
  const classes = useStyles()
  const [requested, setRequested] = useState(false)
  const { currentUser } = useCurrentUser()
  const { active: currentGroup } = useGroups()
  const history = useHistory()
  const {
    submit: scheduleConversation,
    err: scheduleError,
    submitting: submittingSchedule,
    result,
  } = useApiSubmit<
    { conversationId: string },
    {
      query: {
        token: string
      }
      body: {
        skillId: number
        startTime: moment.Moment
        ignoreAvailability: boolean
        emailNote: string
      }
    }
  >('scheduleConversation')
  const handleError = useErrorHandler()
  const [, { pushNotification }] = useNotifications()
  const { defaultSkillSettings } = useGroups()

  const now = new Date()
  const formattedDate = format(now, 'MMMM do, yyyy, h:mm a')
  const initialMessage = [
    `Hello, it’s ${currentUser.firstName} ${currentUser.lastName} from ${currentGroup?.name}.`,
    `Are you available to meet now to talk about Networking and Building Effective Relationships on ${formattedDate}?`,
  ]
  const [message, setMessage] = useState('')

  const navigateToPage = useCallback(
    (conversationId) => {
      history.push(`/conversation/${conversationId}`) // Use the route of the page you want to navigate to.
    },
    [history],
  )

  useEffect(() => {
    if (!submittingSchedule && result) {
      setRequested(true)
      if (typeof reloadUpcoming === 'function') {
        reloadUpcoming()
      }
      pushNotification({
        subject: 'Conversation requested!',
        message: 'Please wait a few moments for the other person to join.',
        timeout: 20, // 10 seconds
        level: 'success',
      })
      onClose()
      navigateToPage(result.conversationId)
    } else if (scheduleError) {
      pushNotification({
        subject: 'Error',
        message: 'Unable to Meet Now. Please try again.',
        timeout: 10, // 10 seconds
        level: 'error',
      })
      handleError(scheduleError, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    result,
    submittingSchedule,
    scheduleConversation,
    scheduleError,
    pushNotification,
    navigateToPage,
  ])

  const handleSchedule = useCallback(
    (now) => {
      if (match?.token && defaultSkillSettings.length > 0) {
        const query = {
          token: match.token,
        }
        const body = {
          skillId: defaultSkillSettings[0].skillId,
          startTime: moment(now).utc(),
          ignoreAvailability: true,
          emailNote: message,
        }
        scheduleConversation({ query, body } as any)
      }
    },
    [defaultSkillSettings, scheduleConversation, match, message],
  )

  return (
    <>
      <Modal
        show={show}
        close={onClose}
        title={
          <div
            className={`${classes.icon} ${
              isMobile() ? 'd-flex justify-content-center' : ''
            }`}
          >
            <label className={`${classes.title}`}>Meet Now</label>
            <Icon icon="fas fa-calendar-alt" />
          </div>
        }
        closeIcon={true}
        bodyClass={classes.body}
        modalClass={`${!isMobile() ? classes.modal : ''}`}
        // dialogClassName={isMobile() ? classes.dialogMobile : ''}
      >
        <Row noGutters className={`pb-2`}>
          <div className={`w-100`}>
            <Col xs={12}>
              <CustomTextArea
                name={'message'}
                maxLength={2000}
                className={classes.customTextArea}
                label="Message"
                placeholder={initialMessage.join(' ')}
                value={message}
                onChange={(e: any) => setMessage(e.target.value)}
              />
              <div className={'d-flex justify-content-end w-100'}>
                <ActionButton
                  height="34px"
                  text={requested ? 'REQUEST SENT' : 'SEND REQUEST'}
                  // onClick={saveConversationDetails}
                  onClick={() => handleSchedule(now)}
                  type="STRONG_GREEN"
                  className="text-nowrap"
                  disabled={requested}
                />
              </div>
            </Col>
          </div>
        </Row>
      </Modal>
    </>
  )
}

export default MeetNowModal
