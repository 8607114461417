import React, { useCallback, useState, useEffect } from 'react'
import compose from 'just-compose'
import { withRouter } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

import { withErrorHandler } from '../../errors'

// Elements
import CardContainer from '../../elemets/CardContainer'
import CustomTextArea from '../../elemets/CustomTextArea'
import CardTitle from '../../elemets/CardTitle'
import ActionButton from '../../elemets/ActionButton'
import LoaderPage from '../../elemets/LoaderPage'

// ApiError
import { useApiSubmit } from '../../api/hooks'

// Theme
import { buttonStyles } from '../../theme/theme2'

// Utils
import { decodeAtoBInputs, isMobile } from '../../utils/common'

const useStyles = createUseStyles({
  close: {
    ...buttonStyles.close,
  },
})

const BioIceBreaker = ({ handleError, history, location }) => {
  const classes = useStyles()
  const [user, setUser] = useState()
  const [formState, setFormState] = useState({
    email: '',
    phone: '',
    linkedIn: '',
    location: '',
    homeTown: '',
    schoon: '',
    bio: '',
    funFact: '',
  })
  const {
    submitted,
    submit: updateProfile,
    err: updateErr,
    submitting,
  } = useApiSubmit('updateProfileV1')

  useEffect(() => {
    if (!isMobile()) {
      history.push('/profile')
    }
  }, [history])

  const close = useCallback(() => {
    history.push('/profile')
  }, [history])

  useEffect(() => {
    if (location && location.state && location.state && location.state.user) {
      setUser(location.state.user)
    } else {
      close()
    }
  }, [close, location])

  useEffect(() => {
    if (user) {
      setFormState({
        email: user.email || '',
        phone: user.phone || '',
        linkedIn: user.linkedIn || '',
        location: user.location || '',
        homeTown: user.homeTown || '',
        schoon: user.schoon || '',
        bio: decodeAtoBInputs(user.bio),
        funFact: decodeAtoBInputs(user.funFact),
      })
    }
  }, [user])

  useEffect(() => {
    if (submitted && !updateErr && !submitting) {
      close()
    } else if (updateErr && !submitting) {
      handleError(updateErr, true, {
        message: 'There was a problem updating your profile.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, updateErr, submitting])

  const save = useCallback(() => {
    try {
      const bio = window.btoa(encodeURIComponent(formState.bio || ''))
      const funFact = window.btoa(encodeURIComponent(formState.funFact || ''))

      updateProfile({
        params: {
          profileId: user.id,
        },
        body: {
          firstName: user.firstName,
          lastName: user.lastName,
          title: user.title,
          conversationFrequencyId: user.conversationFrequencyId,
          notificationFrequencyId: user.notificationFrequencyId,
          maxConversations: Number(user.maxConversations),
          email: user.email,
          regionId: user.region,
          showTour: user.showTour,
          bio: bio,
          funFact: funFact,
          homeTown: user.homeTown,
          highSchool: user.highSchool,
          linkedIn: user.linkedIn,
          location: user.location,
          phoneNumber: user.phoneNumber,
        },
      })
    } catch (err) {
      const { handleError } = this.props
      handleError(err, true, {
        message: 'There was a problem updating your profile.',
      })
    }
  }, [formState, updateProfile, user])

  const handleInput = useCallback((e) => {
    const value = e.target.value
    const name = e.target.name
    setFormState((f) => ({
      ...f,
      [name]: value,
    }))
  }, [])

  return (
    <CardContainer
      header={
        <div className="d-flex justify-content-between align-items-center">
          <CardTitle title="Bio / Executive Summary" />
          <Button className={classes.close} onClick={close}>
            <i className="far fa-times-circle" />
          </Button>
        </div>
      }
      footer={
        <div className="d-flex justify-content-end">
          <ActionButton
            type="STRONG_GREEN"
            text="SAVE"
            onClick={save}
            width={150}
            loading={submitting}
            disabled={
              formState.bio.length > 5000 || formState.funFact.length > 300
            }
          />
        </div>
      }
    >
      {submitted && <LoaderPage />}
      <CustomTextArea
        name="bio"
        value={formState.bio}
        onChange={handleInput}
        className="mt-2"
        rows={4}
        maxLength={5000}
      />
      <CardTitle title="Fun Fact / Ice Breaker" className="mt-4" />
      <CustomTextArea
        name="funFact"
        value={formState.funFact}
        onChange={handleInput}
        rows={4}
        className="mt-2 mb-4"
        maxLength={300}
      />
    </CardContainer>
  )
}

export default compose(withErrorHandler, withRouter)(BioIceBreaker)
