import React, { useState } from 'react'
import CardContainer from '../../elemets/CardContainer'
import { Button, Col, Collapse, Row } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import colors from '../../theme/theme2'
import Divider from '../../elemets/Divider'
import { decodeAtoBInputs } from '../../utils/common'

const useStyles = createUseStyles({
  name: {
    fontFamily: 'lato',
    fontWeight: 'bold',
    fontSize: 20,
  },
  title: {
    fontFamily: 'lato',
    fontWeight: 'bold',
    fontSize: 14,
  },
  bioTitle: {
    fontFamily: 'lato',
    fontWeight: 'bold',
    fontSize: 18,
  },
  bio: {
    fontFamily: 'lato',
    fontSize: 14,
    fontWeight: '400',
  },
  funFact: {
    fontFamily: 'lato',
    fontWeight: 'bold',
    fontSize: 16,
  },
  linkedIn: {
    fontFamily: 'lato',
    fontSize: 14,
    color: '#626566',
    marginBottom: 0,
  },
  expandButton: {
    fontFamily: 'Lato',
    fontSize: 12,
    textAlign: 'right',
    color: colors.primary,
  },
  arrows: {
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },
})
const BioInfo = ({ profile }) => {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)

  if (!profile) {
    return null
  }

  if (
    profile.bio === undefined &&
    profile.funFact === undefined &&
    profile.linkedIn === undefined
  ) {
    return null
  }

  return (
    <CardContainer>
      <Row>
        {profile?.bio && profile?.bio !== '' && (
          <Col xs={12} style={{ textAlign: 'justify' }}>
            <p className={classes.bioTitle}>Bio / Executive Summary</p>
            <span className={classes.bio}>{decodeAtoBInputs(profile.bio)}</span>
          </Col>
        )}
        {(profile?.funFact || profile?.linkedIn) && (
          <Col xs={12} className={`d-flex justify-content-end`}>
            <Button
              className={`${classes.arrows}`}
              onClick={() => {
                setExpand(!expand)
              }}
            >
              <span className={classes.expandButton}>
                {expand ? 'Show Less' : 'Show More'}
                <i
                  className={`ml-1 fas ${
                    expand ? 'fa-chevron-up' : 'fa-chevron-down'
                  }`}
                />
              </span>
            </Button>
          </Col>
        )}
      </Row>
      <Collapse in={expand}>
        <Row>
          <Divider />
          {profile?.funFact && (
            <Col xs={12} style={{ textAlign: 'justify' }} className={`pb-3`}>
              <p className={classes.funFact}>Fun Fact / Ice Breaker</p>
              <span className={classes.bio}>
                {decodeAtoBInputs(profile.funFact)}
              </span>
            </Col>
          )}
          {profile?.linkedIn && (
            <Col xs={12} style={{ textAlign: 'justify' }}>
              <p className={classes.linkedIn}>LinkedIn Profile</p>
              <span className={classes.bio}>{profile?.linkedIn}</span>
            </Col>
          )}
        </Row>
      </Collapse>
    </CardContainer>
  )
}

export default BioInfo
