import React, { useEffect, useState } from 'react'
import * as moment from 'moment'
import { createUseStyles } from 'react-jss'
import colors, { headings } from '../../../theme/theme2'
import { ArrowDownIcon, ArrowUpIcon } from '../../Icons'
import Divider from '../../../elemets/Divider'

const useStyles = createUseStyles({
  noteContainer: {
    margin: 5,
    border: `1px solid ${colors.grey1}`,
    borderRadius: 4,
    position: 'relative',
    width: '100%',
    display: 'flex',
  },
  noteBaseColor: {
    width: 4,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
    backgroundColor: '#d8d8d8',
  },
  arrow: {
    width: 20,
    paddingTop: 8,
  },
  participantInitials: {
    color: colors.primary,
  },
  notes: {
    width: '98%',
  },
  text: {
    ...headings.text,
    fontSize: 14,
    color: '#6d6d6d',
  },
  divider: {
    backgroundColor: '#e8ecef',
    borderColor: '#e8ecef',
  },
})

const HistoryRow = ({ conversation, participant }) => {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)

  return (
    <div className={classes.noteContainer}>
      <div className={`${classes.noteBaseColor}`} />
      <div
        className={`${classes.notes}`}
        onClick={() => {
          setExpand(s => !s)
        }}
      >
        <div className={'w-100 d-flex'}>
          <div className={'p-2 w-100'}>
            {moment(conversation.schedule).format('dddd, MMMM DD, YYYY')}
          </div>
          <div className={`${classes.arrow}`}>
            {!expand ? <ArrowDownIcon /> : <ArrowUpIcon />}
          </div>
        </div>
        {expand &&
          conversation.notes.map((note, index) => {
            return (
              <>
                <div className={`p-2 ${classes.text}`}>
                  {note.userId === participant.profileId && (
                    <>
                      <span className={`${classes.participantInitials} pr-1`}>
                        {participant.firstName[0] +
                          participant.lastName[0] +
                          ' '}
                      </span>
                      -
                    </>
                  )}
                  <span className={'pl-1'}>{note.text}</span>
                </div>
                {index !== conversation.notes.length - 1 && (
                  <Divider className={`m-1 ${classes.divider}`} />
                )}
              </>
            )
          })}
      </div>
    </div>
  )
}

const HistoryCard = ({ participant, notes }) => {
  const [conversations, setConversations] = useState([])

  const compare = (a, b) => {
    if (a.conversationId < b.conversationId) {
      return -1
    }
    if (a.conversationId > b.conversationId) {
      return 1
    }
    return 0
  }

  useEffect(() => {
    if (notes) {
      const conv = []
      notes.map(note => {
        const conversationItem = conv.find(
          conv => conv.conversationId === note.conversationId,
        )
        if (conversationItem) {
          conversationItem.notes.push(note)
        } else {
          conv.push({
            conversationId: note.conversationId,
            schedule: note.conversationScheduled,
            notes: [note],
          })
        }
        return note
      })
      setConversations(conv)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes])

  return (
    <div>
      {conversations.sort(compare).map(conversation => {
        return (
          <HistoryRow conversation={conversation} participant={participant} />
        )
      })}
    </div>
  )
}

export default HistoryCard
