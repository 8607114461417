import React, { useState, useCallback } from 'react'
import { NavDropdown, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { createUseStyles } from 'react-jss'

// Theme
import colors from '../../theme/theme2'

// Components
import SignOutIcon from '../Icons/SignOut'
import ProfileIcon from '../Icons/Profile'
import SettingsIcon from '../Icons/Settings'
import AccountSettingsModal from '../Profile/AccountSettingsModal'
import CircleImage from '../../elemets/CircleImage'
import CircleName from '../../elemets/CircleName'
import ActiveGroup from './ActiveGroup/ActiveGroup'
import GroupSelector from './GroupSelector/GroupSelector'

const useStyles = createUseStyles({
  dropdown: {
    borderStyle: 'none',
  },
  dropdownItem: {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '35px',
  },
  darkText: {
    color: colors.dark,
    fontSize: 16,
    marginBottom: 0,
    fontFamily: 'Lato',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  lightText: {
    fontFamily: 'Lato',
    marginBottom: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  link: {
    color: colors.dark,
    '&:focus, &:active, &:after': {
      color: colors.primary,
      backgroundColor: 'white',
    },
    '&:hover': {
      color: colors.primary,
      textDecoration: 'none',
    },
  },
  mobile: {
    '@media (min-width: 599px)': {
      display: 'none',
    },
    '@media (max-width: 600px)': {
      display: 'block',
      // paddingBottom: '134px',
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
    },
  },
  regular: {
    '& .dropdown-menu': {
      width: 290,
      border: 'none',
      left: '-220px',
      top: '120%',
      boxShadow: '0 2px 16px 0 rgba(153, 155, 168, 0.5)',
    },
    '& .dropdown-toggle::after': {
      display: 'none',
    },
    '@media (min-width: 599px)': {
      display: 'block',
      margin: '0 auto',
      paddingBottom: '10px',
    },
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
  divider: {
    margin: '10px 20px',
  },
  arrowUp: {
    position: 'absolute',
    top: '-8px',
    right: '25px',
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderBottom: '8px solid white',
  },
  avatar: {
    fontSize: '26px!important',
    fontWeight: 'bold !important',
    color: `${colors.primary} !important`,
    backgroundColor: `#eff9f3!important`,
  },
  navItem: {
    color: '#343434',
    padding: '8px 24px 8px 35px',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: '700',
  },
})

const ProfileDropdown = (props) => {
  const [displaySettingsMoldal, setDisplaySettingsMoldal] = useState(false)
  const classes = useStyles()
  const testid = props['data-testid']

  const openAccountSettings = useCallback(
    () => setDisplaySettingsMoldal(true),
    [],
  )

  return (
    <>
      <AccountSettingsModal
        data-testid={`${testid}-accountsettings-modal`}
        show={displaySettingsMoldal}
        onClose={() => setDisplaySettingsMoldal(false)}
        logout={props.logout}
      />

      <div className={classes.mobile}>
        <NavDropdown
          style={{
            background: 'rgb(255, 255, 255, 1)',
            textAlign: 'center',
          }}
          className={classes.link}
          title={
            <span
              data-testid={`${testid}-dropdown-mobile`}
              className={classes.link}
            >
              Settings
            </span>
          }
          id="basic-nav-dropdown"
        >
          <DropdownOptions
            data-testid={testid}
            onClick={props.onClick}
            logout={props.logout}
            className={classes.dropdown}
            user={props.currentProfile}
          />
        </NavDropdown>
      </div>

      <NavDropdown
        data-testid={`${testid}-dropdown`}
        className={classes.regular}
        title={
          props.currentProfile?.avatar ? (
            <CircleImage
              src={props.currentProfile.avatar}
              size={54}
              className={classes.image}
            />
          ) : (
            <CircleName
              size={54}
              text={
                props.currentProfile?.firstName &&
                props.currentProfile?.lastName
                  ? props.currentProfile?.firstName[0] +
                    props.currentProfile?.lastName[0]
                  : ''
              }
              className={classes.avatar}
            />
          )
        }
        id="basic-nav-dropdown"
      >
        <div className={classes.arrowUp}></div>
        <DropdownOptions
          data-testid={testid}
          onClick={props.onClick}
          className={classes.dropdown}
          logout={props.logout}
          user={props.currentProfile}
          openAccountSettings={openAccountSettings}
        />
      </NavDropdown>
    </>
  )
}

const DropdownOptions = (props) => {
  const classes = useStyles()
  const testid = props['data-testid']

  if (props.user) {
    return (
      <>
        <NavDropdown.Header>
          <Row>
            <Col xs={3}>
              {props.user.avatar ? (
                <CircleImage
                  src={props.user.avatar}
                  size={36}
                  className={classes.image}
                />
              ) : (
                <CircleName
                  size={36}
                  text={
                    props.user.firstName && props.user.lastName
                      ? props.user.firstName[0] + props.user?.lastName[0]
                      : ''
                  }
                  className={classes.avatar}
                />
              )}
            </Col>
            <Col xs={9}>
              <p
                data-testid={`${testid}-display-user-fullname`}
                className={classes.darkText}
              >
                {props.user.firstName} {props.user.lastName}
              </p>
              <p
                data-testid={`${testid}-display-user-username`}
                className={classes.lightText}
              >
                {props.user.email}
              </p>
              <ActiveGroup />
            </Col>
          </Row>
        </NavDropdown.Header>
        <NavDropdown.Item
          data-testid={`${testid}-option-profile`}
          as={Link}
          to="/profile"
          className={[classes.link, classes.dropdownItem, classes.darkText]}
          onClick={props.onClick}
        >
          <ProfileIcon /> <span className="ml-2">Your Profile</span>
        </NavDropdown.Item>
        <NavDropdown.Item
          data-testid={`${testid}-option-accountsettings`}
          className={[classes.link, classes.dropdownItem, classes.darkText]}
          onClick={props.openAccountSettings}
        >
          <SettingsIcon /> <span className="ml-2">Account Settings</span>
        </NavDropdown.Item>

        <div className={classes.navItem}>
          <GroupSelector />
        </div>

        <NavDropdown.Divider className={classes.divider} />
        <NavDropdown.Item
          data-testid={`${testid}-option-logout`}
          onClick={props.logout}
          className={[classes.link, classes.dropdownItem, classes.darkText]}
        >
          <SignOutIcon /> <span className="ml-2">Sign Out</span>
        </NavDropdown.Item>
      </>
    )
  }

  return null
}

export default ProfileDropdown
