import React, { useContext, useEffect, useState } from 'react'
import { useBaseUri } from './config/useConfig'
import LoaderPage from './elemets/LoaderPage'
import { useWafToken } from '@/components/Context/WafTokenProvider'

const BrandedContext = React.createContext({ isBranded: false })

const subdomain = window.location.host.split('.')[0]

const isBranded = (() => {
  return !['app', 'admin', 'localhost'].find((x) => subdomain.includes(x))
})()

export const useBrand = () => useContext(BrandedContext)

const BrandHandler = ({ children }) => {
  const [brandInfo, setBrandInfo] = useState({ loading: isBranded })
  const [loadLanding, setLoadLanding] = useState(isBranded)
  const { wafToken } = useWafToken()
  const baseUriLattusApp = useBaseUri('lattusApp')
  const baseUriLattusApi = useBaseUri('lattusApi')

  useEffect(() => {
    const controller = new AbortController()

    if (isBranded && wafToken) {
      const url = `${baseUriLattusApi}/v1/brand?prefix=${subdomain}`
      fetch(url, {
        signal: controller.signal,
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'x-aws-waf-token': wafToken,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json()
          } else {
            window.location.href = baseUriLattusApp
          }
        })
        .then((data) => {
          setBrandInfo({ ...data, loading: false })
        })
        .catch((err) => {
          if (err.name === 'AbortError') {
            return
          }
          setBrandInfo({ loading: false })
        })
    }

    return () => {
      controller.abort()
    }
  }, [baseUriLattusApi, baseUriLattusApp, wafToken])

  return (
    <BrandedContext.Provider
      value={{
        isBranded: isBranded,
        brandInfo: brandInfo,
        loadLanding: loadLanding,
        setLoadLanding: (load) => {
          setLoadLanding(load)
        },
      }}
    >
      {isBranded ? brandInfo.loading ? <LoaderPage /> : children : children}
    </BrandedContext.Provider>
  )
}

export default BrandHandler
