import React, { useCallback, useState, useEffect } from 'react'
import compose from 'just-compose'
import { withRouter } from 'react-router-dom'

import { withErrorHandler } from '../../errors'

// Elements
import CardContainer from '../../elemets/CardContainer'
import SquareButton from '../../elemets/SquareButton'
import CustomTextArea from '../../elemets/CustomTextArea'
import Divider from '../../elemets/Divider'
import CardTitle from '../../elemets/CardTitle'

// ApiError
import { useApiSubmit } from '../../api/hooks'

// Utils
import { decodeAtoBInputs, isMobile } from '../../utils/common'

const BioIceBreakerCard = ({ user, reload, handleError, history }) => {
  const [formState, setFormState] = useState({
    email: '',
    phone: '',
    linkedIn: '',
    location: '',
    homeTown: '',
    school: '',
    bio: '',
    funFact: '',
  })
  const [editMode, setEditMode] = useState(false)
  const {
    submitted,
    submit: updateProfile,
    err: updateErr,
    submitting,
  } = useApiSubmit('updateProfileV1')

  useEffect(() => {
    setFormState({
      email: user.email || '',
      phone: user.phone || '',
      linkedIn: user.linkedIn || '',
      location: user.location || '',
      homeTown: user.homeTown || '',
      school: user.school || '',
      bio: decodeAtoBInputs(user.bio),
      funFact: decodeAtoBInputs(user.funFact),
    })
  }, [user])

  useEffect(() => {
    if (submitted && !updateErr && !submitting) {
      reload()
      setEditMode(false)
    } else if (updateErr && !submitting) {
      handleError(updateErr, true, {
        message: 'There was a problem updating your profile.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, updateErr, submitting])

  const save = useCallback(() => {
    try {
      const bio = window.btoa(encodeURIComponent(formState.bio || ''))
      const funFact = window.btoa(encodeURIComponent(formState.funFact || ''))

      updateProfile({
        params: {
          profileId: user.id,
        },
        body: {
          firstName: user.firstName,
          lastName: user.lastName,
          title: user.title,
          conversationFrequencyId: user.conversationFrequencyId,
          notificationFrequencyId: user.notificationFrequencyId,
          maxConversations: Number(user.maxConversations),
          email: user.email,
          regionId: user.region,
          showTour: user.showTour,
          bio: bio,
          funFact: funFact,
          homeTown: user.homeTown,
          highSchool: user.highSchool,
          linkedIn: user.linkedIn,
          location: user.location,
          phoneNumber: user.phoneNumber,
        },
      })
    } catch (err) {
      handleError(err, true, {
        message: 'There was a problem updating your profile.',
      })
    }
  }, [
    formState.bio,
    formState.funFact,
    handleError,
    updateProfile,
    user.conversationFrequencyId,
    user.email,
    user.firstName,
    user.highSchool,
    user.homeTown,
    user.id,
    user.lastName,
    user.linkedIn,
    user.location,
    user.maxConversations,
    user.notificationFrequencyId,
    user.phoneNumber,
    user.region,
    user.showTour,
    user.title,
  ])

  const handleInput = useCallback((e) => {
    const value = e.target.value
    const name = e.target.name
    setFormState((f) => ({
      ...f,
      [name]: value,
    }))
  }, [])

  const handleChangeEditState = useCallback(() => {
    if (editMode) {
      save()
    } else {
      if (isMobile()) {
        history.push('/profile/bio', { user })
      } else {
        setEditMode(true)
      }
    }
  }, [editMode, history, save, user])

  return (
    <CardContainer
      header={
        <div className="d-flex justify-content-between align-items-center">
          <CardTitle title="Bio / Executive Summary" />
          <SquareButton
            onClick={handleChangeEditState}
            disabled={
              formState.bio.length > 5000 || formState.funFact.length > 300
            }
          >
            {editMode ? (
              <span>Save</span>
            ) : (
              <i className="material-icons">edit</i>
            )}
          </SquareButton>
        </div>
      }
    >
      <CustomTextArea
        name="bio"
        value={formState.bio}
        onChange={handleInput}
        editMode={editMode}
        className="mt-2"
        maxLength={5000}
        rows={4}
      />
      <Divider />
      <CardTitle title="Fun Fact / Ice Breaker" />
      <CustomTextArea
        name="funFact"
        value={formState.funFact}
        onChange={handleInput}
        editMode={editMode}
        maxLength={300}
        rows={4}
      />
    </CardContainer>
  )
}

export default compose(withErrorHandler, withRouter)(BioIceBreakerCard)
