import React, { useCallback, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { invokeOperation } from '../../api'
import { Container, Image } from 'react-bootstrap'
import { withRouter } from 'react-router'
import LoaderPage from '../../elemets/LoaderPage'
import CardContainer from '../../elemets/CardContainer'
import ActionButton from '../../elemets/ActionButton'

const useStyles = createUseStyles({
  container: {
    display: 'grid',
    placeItems: 'center',
    height: '80vh',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '15rem',
    width: '20rem',
    textAlign: 'center',
  },
})

const UnsubscribeEmail = (props) => {
  const {
    match: {
      params: { emailKey, id },
    },
    history,
  } = props
  const classes = useStyles()
  const [loader, setLoader] = useState(true)
  const [, setStatus] = useState()

  const update = useCallback(async () => {
    try {
      await invokeOperation('updateEmailSubscriptions', {
        body: {
          emailKey,
          id: parseInt(id),
        },
      })
      setStatus(true)
    } catch (error) {
      setStatus(error && false)
    } finally {
      setLoader(false)
    }
  }, [emailKey, id])

  useEffect(() => {
    if (!emailKey || !id) return
    update()
  }, [emailKey, id, update])

  return (
    <Container className={classes.container}>
      <CardContainer>
        {loader ? (
          <LoaderPage />
        ) : (
          <div className={classes.card}>
            <Image
              src={
                'https://assets.golattitude.com/Lattitude-Logo_FullColor_Slogan_Cropped.png'
              }
              link="/home"
              height={50}
            />
            <p>
              You have successfully unsubscribed from this email. You will no
              longer receive this nudge.
            </p>
            <ActionButton
              text="Ok"
              type="STRONG_GREEN"
              onClick={() => history.push('/')}
            />
          </div>
        )}
      </CardContainer>
    </Container>
  )
}

export default withRouter(UnsubscribeEmail)
