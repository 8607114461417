import React from 'react'
import { useMethods } from './data/MethodsProvider'
import { MethodKey } from './data/Method'
import SelectField from '../SelectField'

type MethodFieldProps = {
  value?: MethodKey
  onChange: (value: MethodKey) => void
}

const MethodField = ({ value, onChange }: MethodFieldProps): JSX.Element => {
  const { methods, ready } = useMethods()
  return (
    <SelectField
      label="Method of conversation"
      labelId="method"
      required
      value={value}
      onChange={onChange}
      options={methods}
      disabled={!ready}
    />
  )
}

export default MethodField
