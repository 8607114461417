type CSSValue = string | number

type TypographyBaseStyles = {
  fontFamily: CSSValue
  fontWeight?: CSSValue
  fontSize?: CSSValue
  lineHeight?: CSSValue
}

type TypographyElements = Record<
  'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body' | 'button',
  TypographyBaseStyles
>

export type TypographyStyles = TypographyBaseStyles &
  TypographyElements & {
    fontWeightLight: CSSValue
    fontWeightMedium: CSSValue
    fontWeightRegular: CSSValue
  }

const generateTypography = ({
  body,
  header,
}: {
  body: string
  header: string
}): TypographyStyles => {
  const fontFamily = body
  const fontSize = 16
  const fontWeightLight = 300
  const fontWeightRegular = 400
  const fontWeightSemiMedium = 500
  const fontWeightMedium = 700

  return {
    fontFamily,
    fontSize,
    fontWeightLight,
    fontWeightMedium,
    fontWeightRegular,
    h1: {
      fontFamily: header,
      fontWeight: fontWeightSemiMedium,
      fontSize: '2.5rem',
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: header,
      fontWeight: fontWeightSemiMedium,
      fontSize: '2rem',
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: header,
      fontWeight: fontWeightSemiMedium,
      fontSize: '1.75rem',
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: header,
      fontWeight: fontWeightSemiMedium,
      fontSize: '1.5rem',
      lineHeight: 1.2,
    },
    h5: {
      fontFamily: header,
      fontWeight: fontWeightSemiMedium,
      fontSize: '1.25rem',
      lineHeight: 1.2,
    },
    h6: {
      fontFamily: header,
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.2,
    },
    body: {
      fontFamily: body,
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.2,
    },
    button: {
      fontFamily: header,
    },
  }
}

export default generateTypography
