import React from 'react'
import Field from '../Field'
import TimeControl, { TimeValue } from '../../controls/TimeControl'

type StartTimeFieldProps = {
  value?: TimeValue
  onChange: (value: TimeValue) => void
}

const StartTimeField = ({
  value,
  onChange,
}: StartTimeFieldProps): JSX.Element => (
  <Field label="Start time" labelId="startTime">
    <TimeControl value={value} labelId="startTime" onChange={onChange} />
  </Field>
)

export default StartTimeField
export type { TimeValue }
