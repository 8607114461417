import pino, { Logger, LoggerOptions } from 'pino'

// see docs: https://getpino.io/#/docs/browser

import { defaultConfig } from '@/config/Config'
// eslint-disable-next-line import/no-mutable-exports
export let logger: Logger

export const makeGlobalLogger = (opts: LoggerOptions = {}): Logger => {
  if (logger) return logger
  logger = pino({
    level: opts.level ?? defaultConfig.logLevel,
  })
  return logger
}

// Function to create a child logger with additional properties
export const createChildLogger = (
  properties: Record<string, any>,
): pino.Logger => {
  if (logger) {
    return logger.child(properties)
  }
  logger = makeGlobalLogger()
  return logger.child(properties)
}
