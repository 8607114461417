import React from 'react'
import { useConversationDetailsBuilder } from '../../builder/ConversationBuilder'
import { PartialConversation } from '../../builder/state'
import { DurationKey } from './data/Duration'
import AvailableDurations from './data/AvailableDurations'
import DurationField from './DurationField'

const selectDuration = (value: PartialConversation): DurationKey | undefined =>
  value.duration

const SetDuration = (): JSX.Element => {
  const { value, setDuration } = useConversationDetailsBuilder()
  const duration = selectDuration(value)

  return (
    <AvailableDurations>
      <DurationField value={duration} onChange={setDuration} />
    </AvailableDurations>
  )
}

export default SetDuration
