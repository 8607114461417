import React from 'react'
import { createUseStyles } from 'react-jss'
import Divider from '@material-ui/core/Divider'

type SectionTitleProps = {
  value: string
}

const useStyles = createUseStyles({
  root: {
    marginBottom: '24px',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: '600',
    fontFamily: 'Lato',
    marginBottom: '16px',
  },
})

const SectionTitle = ({ value }: SectionTitleProps): JSX.Element => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <h3 className={classes.title}>{value}</h3>
      <Divider />
    </div>
  )
}

export default SectionTitle
