import React from 'react'
import CardContainer from '../../elemets/CardContainer'
import CardTitle from '../../elemets/CardTitle'
import { createUseStyles } from 'react-jss'
import { isMobile } from '../../utils/common'
import colors, { headings } from '../../theme/theme2'
import compose from 'just-compose'
import { withRouter } from 'react-router-dom'
import { Row } from 'react-bootstrap'
import CircleImage from '../../elemets/CircleImage'
import LinkButton from '../../elemets/LinkButton'
import { withErrorHandler } from '../../errors'
import UpcomingConvoCard from './Conversations/UpcomingConvoCard'
import Divider from '../../elemets/Divider'
import ActionButton from '../../elemets/ActionButton'
import UpcomingConversationMenu from './UpcomingConversationMenu'

const useStyles = createUseStyles({
  title: {
    padding: 0,
  },
  container: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
  },
  mobile: {
    paddingTop: 0,
    minHeight: 'unset',
  },
  icon: {
    '& i': {
      fontSize: 20,
      color: colors.primary,
    },
    position: 'absolute',
    left: 10,
  },
  iconText: {
    paddingLeft: 10,
  },
  actionButton: {
    width: '100%!important',
  },
  activityRow: {
    '&:nth-child(2)': {
      marginTop: 15,
    },
  },
  activity: {
    width: '60%',
    marginBottom: 0,
    lineHeight: '1.2',
    marginLeft: 20,
    textAlign: 'left',
    ...headings.text,
  },
  activityTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    color: colors.dark,
  },
  activityInfo: {
    fontWeight: 'bold',
    fontSize: 12,
    color: colors.dark,
    paddingLeft: 15,
    display: 'flex',
  },
  activityLink: {
    padding: '0 0 0 4px',
    margin: 0,
    position: 'relative',
    top: -2,
    fontWeight: 'bold',
    fontSize: 12,
    color: colors.primary,
    '&:hover': {
      cursor: 'pointer',
      color: colors.primary,
    },
  },
})

const UpcomingConversation = ({
  conversations,
  history,
  reloadUpcoming,
  groupId,
}) => {
  const classes = useStyles()
  const lattusLogo =
    'https://assets.golattitude.com/Lattitude_Logo_FullColor_Icon.png'
  const header = (
    <>
      <CardTitle
        className={`${classes.title} ${isMobile() ? 'pt-3' : ''}`}
        title={'Upcoming Conversation'}
        contextMenu={<UpcomingConversationMenu groupId={groupId} />}
      />
    </>
  )

  return (
    <CardContainer
      className={`${classes.container} ${
        isMobile() ? classes.mobile : ''
      } pb-0`}
      header={header}
    >
      <Divider className={`mt-0 mb-1`} />
      {conversations?.slice(0, 5).map((item, index) => {
        const user = item.participants?.find((x) => x.currentUser === false)
        return (
          <div key={index}>
            <UpcomingConvoCard
              conversation={item}
              user={user}
              reloadUpcoming={reloadUpcoming}
            />
            {index !== conversations?.slice(0, 5).length - 1 && (
              <Divider className={`mt-0 mb-1`} />
            )}
          </div>
        )
      })}
      {conversations?.length > 0 && (
        <>
          <Divider className={`mt-0`} />
          <Row
            noGutters={isMobile()}
            className={`d-flex justify-content-end pr-4 ${classes.activityRow} pb-3`}
          >
            <ActionButton
              height="37px"
              text="ALL CONVERSATIONS"
              onClick={() => history.push('/conversations')}
              type="LIGHT_GREEN"
            />
          </Row>
        </>
      )}
      {conversations?.length === 0 && (
        <Row
          noGutters={isMobile()}
          className={`pl-4 ${classes.activityRow} pb-3`}
        >
          <CircleImage size={60} src={lattusLogo} />
          <p className={`${classes.activity} pt-2`}>
            <span className={classes.activityTitle}>
              No Conversations Scheduled
            </span>
            <br />
            <span className={classes.activityInfo}>
              Visit
              <LinkButton
                text="My Network"
                className={classes.activityLink}
                onClick={() => {
                  history.push('/network')
                }}
              />
            </span>
          </p>
        </Row>
      )}
    </CardContainer>
  )
}

export default compose(withRouter, withErrorHandler)(UpcomingConversation)
