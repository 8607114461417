import React, { useState } from 'react'

import { createUseStyles } from 'react-jss'
import { Button, Col, Row } from 'react-bootstrap'
import CircleName from '../../../elemets/CircleName'
import { withRouter } from 'react-router'
import compose from 'just-compose'
import colors from '../../../theme/theme2'
import ActionButton from '../../../elemets/ActionButton'
import moment from 'moment'
import { withErrorHandler } from '../../../errors'
import ActionItems from './ActionItems'
import CircleImage from '../../../elemets/CircleImage'

const useStyles = createUseStyles({
  title: {
    padding: 0,
  },
  container: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
  },
  mobile: {
    paddingTop: 0,
    minHeight: 'unset',
  },
  icon: {
    '& i': {
      fontSize: 20,
      color: colors.primary,
    },
    position: 'absolute',
    left: 10,
  },
  iconText: {
    paddingLeft: 10,
  },
  actionButton: {
    width: '100%!important',
  },
  arrows: {
    color: colors.primary,
    backgroundColor: 'transparent!important',
    border: 0,
    '&:focus': {
      boxShadow: 'unset!important',
      outline: 0,
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:hover': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&:active': {
      color: [colors.primary700, '!important'],
      border: 0,
      backgroundColor: 'transparent!important',
    },
    '&.right': {
      top: 10,
      right: 30,
    },
  },
  avatar: {
    fontWeight: 'bold!important',
    position: 'relative',
    top: -10,
  },
  name: {
    fontWeight: 'bold!important',
    fontSize: 18,
  },
  time: {
    fontWeight: 'bold!important',
    fontSize: 11,
    color: colors.primary,
  },
  icons: {
    position: 'relative',
    top: -12,
  },
  expandedInfo: {
    paddingLeft: 20,
    paddingRight: 45,
    width: '100%',
  },
})

const ConversationList = ({ conversation = {}, actionItems, history }) => {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)
  let participant = conversation.participants?.find(
    (x) => x.currentUser === false,
  )

  return (
    <>
      <Row>
        <Col xs={8} className={'p-0 pl-2 pr-2'}>
          <Row noGutters className="align-items-center pl-2 pr-2 ">
            {participant.avatar ? (
              <CircleImage
                src={participant.avatar}
                size={40}
                className={classes.avatar}
              />
            ) : (
              <CircleName
                id="avatar-name"
                size={40}
                text={
                  participant.firstName && participant.lastName
                    ? participant.firstName[0] + participant?.lastName[0]
                    : ''
                }
                className={classes.avatar}
              />
            )}
            <div className="pl-3">
              <p className={`mb-0 ${classes.name}`}>
                {participant.firstName} {participant.lastName}
              </p>
              <p className={classes.time}>
                {conversation.scheduled
                  ? moment
                      .utc(conversation.scheduled)
                      .local()
                      .format('dddd, MMM DD, YYYY HH:MM')
                  : ''}
              </p>
            </div>
          </Row>
        </Col>
        <Col xs={3} className={`d-flex justify-content-end pr-0 mr-0`}>
          <ActionButton
            height="37px"
            text="CONVO DETAILS"
            onClick={() => {
              history.push(`/conversation/${conversation.id}`, {
                params: { conversation },
              })
            }}
            type="LIGHT_GREEN"
          />
        </Col>
        <Col
          xs={1}
          className={`pl-0 ml-0 pr-1 d-flex justify-content-end ${classes.icons}`}
        >
          <Button
            className={`${classes.arrows}`}
            onClick={() => {
              setExpand(!expand)
            }}
          >
            <span>
              <i
                className={`fas ${
                  expand ? 'fa-chevron-down' : 'fa-chevron-right'
                }`}
              />
            </span>
          </Button>
        </Col>
      </Row>
      {expand && (
        <>
          <Row noGutters className="align-items-center pb-2 ">
            <div className={classes.expandedInfo}>
              <ActionItems items={actionItems} />
            </div>
          </Row>
        </>
      )}
    </>
  )
}

export default compose(withRouter, withErrorHandler)(ConversationList)
