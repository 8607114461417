import React, { useCallback, useState, useMemo, useEffect } from 'react'
import moment from 'moment'
import { Row, Col } from 'react-bootstrap'
import compose from 'just-compose'
import { createUseStyles } from 'react-jss'

// Components
import ActionButton from '../../elemets/ActionButton'
import CustomSelect from '../../elemets/CustomSelect'
import CustomDate from '../../elemets/CustomDate'

// API
import { useApiSubmit } from '../../api/hooks'

// Error
import { withErrorHandler } from '../../errors'

// Utils
import { isMobile, getDateToAPI, getPeriodsWithDST } from '../../utils/common'

// Theme
import colors, { headings } from '../../theme/theme2'
import CardContainer from '../../elemets/CardContainer'
import CardTitle from '../../elemets/CardTitle'
import Tooltip from './Tooltip'
import { withNotifications } from '../../notifications'

const useStyles = createUseStyles({
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  dialogDesktop: {
    minWidth: 600,
  },
  container: {
    padding: `20px !important`,
  },
  time: {
    minWidth: 120,
  },
  endTime: {
    width: '100%',
    maxWidth: 170,
  },
  startDate: {
    width: '100%',
    '& .react-datepicker-wrapper': {
      width: '100%',
    },
  },
  timeMobile: {
    minWidth: 135,
  },
  to: {
    ...headings.paragraph,
    fontSize: 10,
    margin: '0 10px',
    top: 5,
    position: 'relative',
  },
  toMobile: {
    ...headings.paragraph,
  },
  dateMobile: {
    '& .react-datepicker-wrapper': {
      width: '100%',
    },
  },
  delete: {
    color: colors.red,
    '&:hover': {
      color: colors.red,
    },
  },
  card: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    marginBottom: 30,
  },
  insideCard: {
    backgroundColor: '#f8fafb',
    padding: 10,
    '& *': {
      fontWeight: '500!important',
    },
  },
  tooltipPosition: {
    right: -450,
    top: -20,
  },
  tooltipPositionMobile: {
    top: -240,
  },
})
/* const weekNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] */
const timeList = [
  { label: '12:00am', value: '00:00' },
  { label: '12:30am', value: '00:30' },
  { label: '01:00am', value: '01:00' },
  { label: '01:30am', value: '01:30' },
  { label: '02:00am', value: '02:00' },
  { label: '02:30am', value: '02:30' },
  { label: '03:00am', value: '03:00' },
  { label: '03:30am', value: '03:30' },
  { label: '04:00am', value: '04:00' },
  { label: '04:30am', value: '04:30' },
  { label: '05:00am', value: '05:00' },
  { label: '05:30am', value: '05:30' },
  { label: '06:00am', value: '06:00' },
  { label: '06:30am', value: '06:30' },
  { label: '07:00am', value: '07:00' },
  { label: '07:30am', value: '07:30' },
  { label: '08:00am', value: '08:00' },
  { label: '08:30am', value: '08:30' },
  { label: '09:00am', value: '09:00' },
  { label: '09:30am', value: '09:30' },
  { label: '10:00am', value: '10:00' },
  { label: '10:30am', value: '10:30' },
  { label: '11:00am', value: '11:00' },
  { label: '11:30am', value: '11:30' },
  { label: '12:00pm', value: '12:00' },
  { label: '12:30pm', value: '12:30' },
  { label: '01:00pm', value: '13:00' },
  { label: '01:30pm', value: '13:30' },
  { label: '02:00pm', value: '14:00' },
  { label: '02:30pm', value: '14:30' },
  { label: '03:00pm', value: '15:00' },
  { label: '03:30pm', value: '15:30' },
  { label: '04:00pm', value: '16:00' },
  { label: '04:30pm', value: '16:30' },
  { label: '05:00pm', value: '17:00' },
  { label: '05:30pm', value: '17:30' },
  { label: '06:00pm', value: '18:00' },
  { label: '06:30pm', value: '18:30' },
  { label: '07:00pm', value: '19:00' },
  { label: '07:30pm', value: '19:30' },
  { label: '08:00pm', value: '20:00' },
  { label: '08:30pm', value: '20:30' },
  { label: '09:00pm', value: '21:00' },
  { label: '09:30pm', value: '21:30' },
  { label: '10:00pm', value: '22:00' },
  { label: '10:30pm', value: '22:30' },
  { label: '11:00pm', value: '23:00' },
  { label: '11:30pm', value: '23:30' },
]
const AvailabilityCard = React.memo(
  ({
    event,
    onClose,
    handleError,
    showTooltip,
    setTooltip,
    closeTooltip,
    pushNotification,
  }) => {
    const classes = useStyles()
    const [formState, setFormState] = useState({
      daysOfWeek: [],
      startDate: new Date(),
      endDate: new Date(),
      startTime: { label: '08:00am', value: '08:00' },
      endTime: { label: '08:30am', value: '08:30' },
      weekFrequency: { label: 'Does Not Repeat', value: 0 },
    })
    const [isEditMode, setIsEditMode] = useState(false)

    const {
      submitted,
      submit: addRecurringAvailability,
      err,
      submitting,
    } = useApiSubmit('addRecurringAvailability')

    const {
      submitted: submittedAvailability,
      submit: addAvailability,
      err: errorAvailability,
      submitting: submittingAvailability,
    } = useApiSubmit('addAvailabilityV1')

    const {
      submitted: updated,
      submit: updateAvailability,
      err: errUpdate,
      submitting: updateLoading,
    } = useApiSubmit('updateAvailability')

    const weekFrequencyOptions = useMemo(() => {
      return [
        { label: 'Does Not Repeat', value: 0 },
        { label: 'Daily', value: 1 },
        { label: 'Weekly', value: 2 },
        { label: 'Monthly', value: 3 },
        { label: 'Weekdays', value: 4 },
      ]
    }, [])

    useEffect(() => {
      if (event) {
        const posStartTime = timeList.findIndex(
          (t) => t.value === moment(event.startTime).format('HH:mm'),
        )
        const posEndTime = timeList.findIndex(
          (t) => t.value === moment(event.endTime).format('HH:mm'),
        )
        setFormState((f) => ({
          ...f,
          startDate: event.startTime,
          endDate: event.endTime,
          startTime: timeList[posStartTime],
          endTime: timeList[posEndTime],
        }))
        if (event.id) {
          setIsEditMode(true)
        }
      } else {
        setFormState({
          daysOfWeek: [],
          startDate: new Date(),
          endDate: new Date(),
          startTime: { label: '08:00am', value: '08:00' },
          endTime: { label: '08:30am', value: '08:30' },
          weekFrequency: { label: 'Does Not Repeat', value: 0 },
        })
        setIsEditMode(false)
      }
    }, [event])

    // Created
    useEffect(() => {
      if (
        (submitted && !err && !submitting) ||
        (submittedAvailability && !errorAvailability && !submittingAvailability)
      ) {
        pushNotification({
          level: 'success',
          timeout: 10,
          subject: 'Availability Added Successfully.',
        })
      } else if (
        (err && !submitting) ||
        (errorAvailability && !submittingAvailability)
      ) {
        handleError(err, true, {
          message: 'There was a problem adding the action.',
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      submitted,
      err,
      submitting,
      submittedAvailability,
      errorAvailability,
      submittingAvailability,
    ])

    // When Updated
    useEffect(() => {
      if (updated && !updateLoading && !errUpdate) {
        window.location.reload()
        onClose()
      } else if (errUpdate && !updateLoading) {
        handleError(errUpdate, true, {
          message: 'There was a problem removing your Education.',
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updated, updateLoading, errUpdate])

    const getDiffLabel = useCallback((start, end) => {
      const day = moment().format('YYYY-DD-MM')
      const diff = moment(moment(day + ' ' + end)).diff(
        moment(day + ' ' + start),
        'minutes',
      )
      const hours = diff / 60
      if (hours > 0) {
        if (hours < 1) {
          return '(30min)'
        }
        if (hours < 2) {
          return `(${hours}hr)`
        } else {
          return `(${hours}hrs)`
        }
      } else return ''
    }, [])

    const endTimeOptions = useMemo(() => {
      const result = []
      if (formState.startTime) {
        const pos = timeList.findIndex(
          (t) => t.value === formState.startTime.value,
        )
        for (let index = pos + 1; index < timeList.length; index++) {
          const element = timeList[index]
          result.push({
            ...element,
            label: `${element.label}${getDiffLabel(
              formState.startTime.value,
              element.value,
            )}`,
          })
        }
      }
      return result
    }, [formState.startTime, getDiffLabel])

    const handleDateChange = useCallback((key, date) => {
      setFormState((f) => {
        const updated = {
          ...f,
          [key]: date,
        }
        if (key === 'startDate') {
          updated.endDate = date
        }
        return updated
      })
    }, [])

    const handleSelect = useCallback((key, value) => {
      setFormState((f) => {
        const updated = {
          ...f,
          [key]: value,
        }
        if (key === 'startTime') {
          const pos = timeList.findIndex((t) => t.value === value.value)
          updated.endTime = timeList[pos + 1]
        }
        updated.endDate = new Date(moment(f.startDate).add(3, 'months'))
        return updated
      })
    }, [])

    const handleUpdate = useCallback(() => {
      /* if (
        moment().diff(
          moment(
            getDateToAPI(formState.startDate) + ' ' + formState.startTime.value,
          ),
        ) < 0
      ) { */
      const body = {
        startTime: moment
          .utc(
            moment(
              getDateToAPI(formState.startDate) +
                ' ' +
                formState.startTime.value,
            ),
          )
          .format(),
        endTime: moment
          .utc(
            moment(
              getDateToAPI(formState.startDate) + ' ' + formState.endTime.value,
            ),
          )
          .format(),
      }

      updateAvailability({
        params: { availabilityId: event.id },
        body,
      })
      /* } else {
        handleError(err, true, {
          message: 'You have to select availability greater than current time.',
        })
      } */
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState])

    const handleCreate = useCallback(() => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      // one time
      if (formState.weekFrequency.value === 0) {
        const body = {
          startTime: moment
            .utc(
              moment(
                getDateToAPI(formState.startDate) +
                  ' ' +
                  formState.startTime.value,
              ),
            )
            .format(),
          endTime: moment
            .utc(
              moment(
                getDateToAPI(formState.startDate) +
                  ' ' +
                  formState.endTime.value,
              ),
            )
            .format(),
          timezone,
        }
        addAvailability({ body })
      } else {
        const periods = getPeriodsWithDST(
          formState.startDate,
          formState.endDate,
          formState.weekFrequency.label.toLowerCase(),
        )
        const fix = periods.map((period) => {
          const startDate = moment(period.startDate)
          const endDate = moment(period.endDate)
          const availabilityStart = moment(
            `${getDateToAPI(period.startDate)} ${formState.startTime.value}`,
          )
          const availabilityEnd = moment(
            `${getDateToAPI(period.startDate)} ${formState.endTime.value}`,
          )
          // end time is on the next day
          if (availabilityEnd.isBefore(availabilityStart)) {
            availabilityEnd.add(1, 'day')
          }
          return {
            startDate: startDate.utc().format('YYYY-MM-DD'),
            endDate: endDate.utc().format('YYYY-MM-DD'),
            startTime: availabilityStart.utc().format(),
            endTime: availabilityEnd.utc().format(),
            repeats: formState.weekFrequency.label.toLowerCase(),
            timezone,
          }
        })

        const body = fix[0]
        addRecurringAvailability({ body })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState])

    return (
      <CardContainer
        className={classes.card}
        header={<CardTitle className={`pl-0`} title="Add Availability" />}
        headerDivider={true}
        footer={
          <div
            className={`d-flex ${
              isEditMode ? 'justify-content-between' : 'justify-content-end'
            } mt-3`}
          >
            <ActionButton
              text={isEditMode ? 'UPDATE' : 'CREATE'}
              type="STRONG_GREEN"
              className="w-40"
              onClick={isEditMode ? handleUpdate : handleCreate}
              loading={updateLoading || submittingAvailability || submitting}
            />
            {showTooltip === 2 && (
              <Tooltip
                activeNumber={showTooltip}
                title={'Lattitude Availability'}
                content={
                  'Add Lattitude Availability so members of your group can find a \n' +
                  'time that works best for you to have a conversation!\n' +
                  '\n' +
                  'Link your Google or Microsoft calendar to keep in sync.'
                }
                setTooltip={setTooltip}
                close={closeTooltip}
                arrow={isMobile() ? 'arrowDownMobile' : 'arrowLeft'}
                className={
                  isMobile()
                    ? classes.tooltipPositionMobile
                    : classes.tooltipPosition
                }
              />
            )}
          </div>
        }
      >
        <Row noGutters className={classes.insideCard}>
          {isMobile() ? (
            <>
              <Col xs={12} className="mb-3">
                <CustomDate
                  name="startDate"
                  placeholder="Start Date"
                  value={formState.startDate}
                  onChange={(date) => handleDateChange('startDate', date)}
                  mask={false}
                  containerStyle={classes.dateMobile}
                  valueToDisplay={
                    formState.startDate
                      ? moment(formState.startDate).format('dddd, MMM DD, YYYY')
                      : ''
                  }
                />
              </Col>
              {!isEditMode && formState.weekFrequency.value !== 0 && (
                <Col xs={12} className="mb-3">
                  <CustomDate
                    name="endDate"
                    placeholder="End Date"
                    title="End Date"
                    value={formState.endDate}
                    onChange={(date) => handleDateChange('endDate', date)}
                    mask={false}
                    containerStyle={classes.dateMobile}
                    valueToDisplay={
                      formState.endDate
                        ? moment(formState.endDate).format('dddd, MMM DD, YYYY')
                        : ''
                    }
                  />
                </Col>
              )}
              <Col xs={12} className="mb-3">
                <div className="d-flex justify-content-between">
                  <CustomSelect
                    options={timeList.slice(0, -1)}
                    name="startTime"
                    value={formState.startTime}
                    onSelect={handleSelect}
                    className={`${classes.timeMobile}`}
                  />
                  <label className={classes.toMobile}>to</label>
                  <CustomSelect
                    options={endTimeOptions}
                    name="endTime"
                    value={formState.endTime}
                    onSelect={handleSelect}
                    className={classes.timeMobile}
                  />
                </div>
              </Col>
              {!isEditMode && (
                <Col xs={12} className="mb-3">
                  <CustomSelect
                    options={weekFrequencyOptions}
                    name="weekFrequency"
                    value={formState.weekFrequency}
                    onSelect={handleSelect}
                  />
                </Col>
              )}
            </>
          ) : (
            <>
              <Col xs={12} className="pr-0">
                <div className="d-flex mb-3">
                  <CustomDate
                    name="startDate"
                    placeholder="Start Date"
                    value={formState.startDate}
                    onChange={(date) => handleDateChange('startDate', date)}
                    mask={false}
                    valueToDisplay={
                      formState.startDate
                        ? moment(formState.startDate).format(
                            'dddd, MMM DD, YYYY',
                          )
                        : ''
                    }
                    containerStyle={classes.startDate}
                  />
                </div>
                <div className="d-flex mb-3">
                  <CustomSelect
                    options={timeList.slice(0, -1)}
                    name="startTime"
                    value={formState.startTime}
                    onSelect={handleSelect}
                    className={`${classes.time}`}
                  />
                  <label className={classes.to}>to</label>
                  <CustomSelect
                    options={endTimeOptions}
                    name="endTime"
                    value={formState.endTime}
                    onSelect={handleSelect}
                    className={`${classes.time} ${classes.endTime}`}
                  />
                </div>
                <CustomSelect
                  options={weekFrequencyOptions}
                  name="weekFrequency"
                  value={formState.weekFrequency}
                  onSelect={handleSelect}
                  className="mb-3"
                />
                {!isEditMode && formState.weekFrequency.value !== 0 && (
                  <CustomDate
                    name="endDate"
                    title="End Date"
                    placeholder="End Date"
                    value={formState.endDate}
                    onChange={(date) => handleDateChange('endDate', date)}
                    mask={false}
                    valueToDisplay={
                      formState.endDate
                        ? moment(formState.endDate).format('dddd, MMM DD, YYYY')
                        : ''
                    }
                  />
                )}
              </Col>
            </>
          )}
        </Row>
      </CardContainer>
    )
  },
)

export default compose(withErrorHandler, withNotifications)(AvailabilityCard)
