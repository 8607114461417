import React, { type FC, useEffect, useState, useCallback } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import moment from 'moment/moment'
import { useInterval, useIsMounted } from 'usehooks-ts'

import CallDetailsCard from '../Components/CallDetailsCard'
import ActionItemsCard from '../Components/ActionItemsCard'
import AgendaItemsCard from '../Components/AgendaItemsCard'
import SuggestedQuestionCard from '../Components/SuggestedQuestionCard'
import NotesHistory from '../Components/NotesHistory'
import GoalsCard from '../Components/GoalsCard'
import { useErrorHandler } from '../../../errors'
import { useNotifications } from '../../../notifications'
import AgendaItemsInCallCard from '../Components/AgendaItemInCallCard'
import LeftPanelTabs from '../Components/LeftPanelTabs'
import { useApiFetch, useApiSubmit } from '@/api'
import Call from '../InCall/Call'
import ScheduleNextCallCard from '../Components/ScheduleNextCallCard'
import {
  CALL_LAYOUT,
  CallLayout,
  ConvoStatus,
} from '../conversations.constants'
import {
  calculateTimes,
  MAX_EXTEND_DURATION_MINUTES,
  THIRTY_SECONDS_MILLI,
} from './conversation-utils'
import {
  ConversationFromApi,
  ConvoTimes,
  Participant,
} from '@/components/Conversations/conversation.types'
import { log } from '@/utils/logger'
import { IUserProfile } from '@/types/UserProfile.typings'

type Props = {
  currentProfile: IUserProfile
  conversation: ConversationFromApi
  me: Participant
  otherParticipant: Participant
  showNavBar: (b: boolean) => void
  reload: () => void
}
type ActionItem = {
  text: string
  completed: boolean
}
const ConversationLayout: FC<Props> = ({
  me,
  otherParticipant,
  currentProfile,
  conversation,
  showNavBar,
  reload,
}: Props) => {
  const conversationId = conversation.id
  const isMounted = useIsMounted()
  const [tabs, setTabs] = useState('0')
  const [selectedLayout, setSelectedLayout] = useState<CallLayout>(
    CALL_LAYOUT.PRE,
  )
  const [startTime] = useState(conversation.scheduled)
  const [endTime] = useState(conversation.scheduledEndTime)
  const [, { pushNotification }] = useNotifications()
  const handleError = useErrorHandler()
  // const { value: isNavShown, toggle: toggleNav } = useBoolean(
  //   selectedLayout !== CALL_LAYOUT.IN,
  // )
  const [convoTimes, setConvoTimes] = useState<ConvoTimes>(
    calculateTimes(conversation.scheduled, conversation.scheduledEndTime),
  )
  const [currentNote, setCurrentNote] = useState<string>('')
  const [currentActionItem, setCurrentActionItem] = useState<ActionItem>({
    text: '',
    completed: false,
  })

  // useEffect(() => {
  //   showNavBar(isNavShown)
  // }, [isNavShown, showNavBar])

  //
  // API Fetches
  //
  const {
    data: actions,
    reload: reloadActions,
    loading: loadingActions,
  } = useApiFetch('listConversationItems', {
    params: { conversationId: conversation.id },
    query: { type: 'action' },
  })

  const {
    data: agendas,
    reload: reloadAgendas,
    loading: loadingAgendas,
  } = useApiFetch('listConversationItems', {
    params: { conversationId },
    query: { type: 'agenda' },
  })

  const { data: notes, reload: reloadNotes } = useApiFetch(
    'getConversationNotes',
    {
      params: { conversationId },
    },
  )

  const {
    data: goals,
    reload: reloadGoals,
    loading: loadingGoal,
  } = useApiFetch('listConversationItems', {
    params: { conversationId },
    query: { type: 'goal' },
  })

  const { data: notesHistory } = useApiFetch('getNotesHistory', {
    query: {
      participantId: otherParticipant.profileId,
    },
  })

  //
  // API Submit
  //
  const {
    submitted: endTimeUpdated,
    submit: newEndTime,
    submitting: endTimeLoading,
    err: endTimeError,
  } = useApiSubmit('setConversationEndTime')

  useEffect(() => {
    if (endTimeUpdated && !endTimeError && !endTimeLoading) {
      const { allowedBeforeTime, allowedAfterTime, maxEndTime } =
        calculateTimes(conversation.scheduled, conversation.scheduledEndTime)
      setConvoTimes({
        scheduledStart: conversation.scheduled,
        scheduledEnd: conversation.scheduledEndTime,
        allowedBeforeTime,
        allowedAfterTime,
        maxEndTime,
      })
      pushNotification({
        level: 'success',
        subject: 'Conversation Extended.',
        message: `The conversation has been extended.`,
        // until ${moment
        //   .utc(endTime)
        //   .local()
        //   .format('hh:mm A')}.`,
        timeout: 10,
      })
    } else if (endTimeError && !endTimeLoading) {
      handleError(endTimeError, true, {
        message: 'There was a problem extending the conversation.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    endTimeUpdated,
    endTimeError,
    endTimeLoading,
    pushNotification,
    endTime,
    handleError,
  ])

  //
  // API Interaction Handlers
  //
  const extendConversationEndTime = useCallback(
    async (until) => {
      newEndTime({
        params: { conversationId: conversation.id, until },
      })
    },
    [conversation.id, newEndTime],
  )
  const handleExtendConversationEndTime = useCallback(
    async (currentEndTime) => {
      // make sure we cannot extend the conversation past 65 minutes total.
      const remainingTime = moment(convoTimes.maxEndTime).diff(
        moment.utc(currentEndTime),
        'minutes',
      )
      if (remainingTime <= 0) {
        // The current end time is already equal or after the maximum duration.
        pushNotification({
          level: 'warning',
          subject: 'Cannot Extend Conversation',
          message:
            'Conversations cannot be extended past the maximum duration of 60 minutes.',
          timeout: 10,
        })
        return
      }
      // Determine how much we can extend, it would be either 15 minutes or the remaining time, whichever is less.
      const extension = Math.min(MAX_EXTEND_DURATION_MINUTES, remainingTime)
      // Extend by allowable amount
      const utcTimeUntil = moment.utc(currentEndTime).add(extension, 'minutes')
      // Convert the Moment.js object to an ISO8601 string
      const until = utcTimeUntil.toISOString()
      await extendConversationEndTime(until)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conversation.id, newEndTime, convoTimes.maxEndTime],
  )

  //
  // on End Time Change
  //
  const updateEndTimeState = useCallback(
    (endTime) => {
      const { allowedAfterTime } = calculateTimes(startTime, endTime)
      setConvoTimes({
        ...convoTimes,
        allowedAfterTime,
        scheduledEnd: endTime,
      })
    },
    [startTime, convoTimes],
  )

  //
  // Update Notes (long-polling)
  //
  useInterval(
    () => {
      if (selectedLayout === CALL_LAYOUT.IN && isMounted()) {
        log.info('interval: reload notes')
        reloadNotes()
        reloadAgendas()
      }
    },
    // Delay in milliseconds or null to stop it
    isMounted() && selectedLayout === CALL_LAYOUT.IN
      ? THIRTY_SECONDS_MILLI
      : null,
  )

  //
  // Determine Layout
  //
  useEffect(() => {
    const { now, allowedBeforeTime, allowedAfterTime } = calculateTimes(
      startTime,
      endTime,
    )
    const statusName = conversation.statusName
    if (
      statusName === ConvoStatus.Completed ||
      statusName === ConvoStatus.Cancelled
    ) {
      setSelectedLayout(CALL_LAYOUT.POST)
    } else if (
      statusName === ConvoStatus.Scheduled &&
      now.isSameOrAfter(allowedBeforeTime) &&
      now.isSameOrBefore(allowedAfterTime)
    ) {
      setSelectedLayout(CALL_LAYOUT.IN)
    } else if (now.isSameOrAfter(allowedAfterTime)) {
      setSelectedLayout(CALL_LAYOUT.POST)
    } else {
      setSelectedLayout(CALL_LAYOUT.PRE)
    }
  }, [conversation, startTime, endTime])

  //
  // render components
  //
  return (
    <Container>
      <Row>
        {!(selectedLayout === CALL_LAYOUT.IN && isMobile) && (
          <Col xs={12} md={5} lg={4} className="px-0 px-md-3">
            {/*<button onClick={toggleNav}>toggleNav</button>*/}
            <CallDetailsCard
              me={me}
              otherParticipant={otherParticipant}
              startTime={convoTimes.scheduledStart}
              endTime={convoTimes.scheduledEnd}
              allowedBeforeTime={convoTimes.allowedBeforeTime}
              allowedAfterTime={convoTimes.allowedAfterTime}
              conversation={conversation}
              reload={reload}
              callLayout={selectedLayout}
              setSelectedLayout={setSelectedLayout}
            />

            <LeftPanelTabs
              tab={tabs}
              setTabs={setTabs}
              show={selectedLayout === CALL_LAYOUT.IN || isMobile}
            />

            <ActionItemsCard
              currentActionItem={currentActionItem}
              setCurrentActionItem={setCurrentActionItem}
              actions={actions}
              reload={reloadActions}
              loading={loadingActions}
              conversationId={conversationId}
              currentProfile={currentProfile}
              show={
                ((isMobile || selectedLayout === CALL_LAYOUT.IN) &&
                  tabs === '1') ||
                (selectedLayout !== CALL_LAYOUT.IN && !isMobile)
              }
            />
            <AgendaItemsInCallCard
              agendas={agendas}
              reload={reloadAgendas}
              loading={loadingAgendas}
              conversationId={conversationId}
              currentProfile={currentProfile}
              show={
                (selectedLayout === CALL_LAYOUT.IN && tabs === '0') ||
                (selectedLayout === CALL_LAYOUT.POST && tabs === '0') ||
                (selectedLayout === CALL_LAYOUT.POST && !isMobile)
              }
            />
            <NotesHistory
              currentNote={currentNote}
              setCurrentNote={setCurrentNote}
              notes={notes}
              reload={reloadNotes}
              notesHistory={notesHistory}
              participant={conversation.participants.find(
                (participant) => !participant.currentUser,
              )}
              conversationId={conversationId}
              currentProfile={currentProfile}
              show={
                (selectedLayout === CALL_LAYOUT.IN && tabs === '2') ||
                (isMobile && tabs === '2')
              }
            />
          </Col>
        )}
        <Col xs={12} md={7} lg={8} className="px-0 px-md-3">
          {selectedLayout === CALL_LAYOUT.IN && (
            <Call
              me={me}
              otherParticipant={otherParticipant}
              conversation={conversation}
              startTime={convoTimes.scheduledStart}
              endTime={convoTimes.scheduledEnd}
              allowedBeforeTime={convoTimes.allowedBeforeTime}
              allowedAfterTime={convoTimes.allowedAfterTime}
              updateEndTimeOnly={updateEndTimeState}
              onTimeChange={handleExtendConversationEndTime}
              isExtendingConversation={endTimeLoading}
              selectedLayout={selectedLayout}
              setSelectedLayout={setSelectedLayout}
            />
          )}

          <LeftPanelTabs
            tab={tabs}
            setTabs={setTabs}
            show={selectedLayout === CALL_LAYOUT.IN && isMobile}
          />

          <AgendaItemsCard
            agendas={agendas}
            reload={reloadAgendas}
            loading={loadingAgendas}
            conversationId={conversationId}
            currentProfile={currentProfile}
            show={selectedLayout === CALL_LAYOUT.PRE}
          />

          <AgendaItemsInCallCard
            agendas={agendas}
            reload={reloadAgendas}
            loading={loadingAgendas}
            conversationId={conversationId}
            currentProfile={currentProfile}
            show={
              selectedLayout !== CALL_LAYOUT.PRE &&
              selectedLayout === CALL_LAYOUT.IN &&
              isMobile &&
              tabs === '0'
            }
          />

          <ActionItemsCard
            currentActionItem={currentActionItem}
            setCurrentActionItem={setCurrentActionItem}
            actions={actions}
            reload={reloadActions}
            loading={loadingActions}
            conversationId={conversationId}
            currentProfile={currentProfile}
            show={isMobile && selectedLayout === CALL_LAYOUT.IN && tabs === '1'}
          />

          <SuggestedQuestionCard
            conversationId={conversationId}
            reload={reloadNotes}
            show={selectedLayout === CALL_LAYOUT.PRE}
          />

          <NotesHistory
            currentNote={currentNote}
            setCurrentNote={setCurrentNote}
            notes={notes}
            reload={reloadNotes}
            notesHistory={notesHistory}
            participant={otherParticipant}
            conversationId={conversationId}
            currentProfile={currentProfile}
            show={
              (selectedLayout !== CALL_LAYOUT.IN && !isMobile) ||
              (selectedLayout === CALL_LAYOUT.IN && isMobile && tabs === '2')
            }
          />

          <GoalsCard
            goals={goals}
            reload={reloadGoals}
            loading={loadingGoal}
            conversationId={conversationId}
            currentProfile={currentProfile}
            show
          />

          {selectedLayout === CALL_LAYOUT.IN && (
            <ScheduleNextCallCard
              token={conversation.availabilityToken}
              topic={{
                skillId: conversation.skillId,
                skillName: conversation.skillName,
              }}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default ConversationLayout
