import React, { createContext, useCallback, useState } from 'react'
import { useIsMounted } from 'usehooks-ts'

export interface NotificationData {
  id?: number
  subject?: string
  created?: Date
  message?: string
  timeout?: number
  level?: 'info' | 'success' | 'warning' | 'error'
}

type Callback = () => void

export type Notification = NotificationData & {
  id: number
  created: Date
  callback: Callback | undefined
}

type Cancel = () => void

export type Context = {
  notifications: Notification[]
  clearNotification: (id: number) => void
  pushNotification: (data: NotificationData, callback?: Callback) => Cancel
}

export const NotificationsContext = createContext<Context>({
  notifications: [],
  clearNotification: () => {
    throw new Error('not initialized')
  },
  pushNotification: () => {
    throw new Error('not initialized')
  },
})

const idFactory = function* (startAt = 0) {
  for (let i = startAt; true; i += 1) {
    yield i
  }
}

// TODO remove this "hack" (remnant from the original implementation)
const nextId = idFactory(Number.MIN_SAFE_INTEGER) // bit of a hack to avoid conflicting with API IDs

interface ProviderProps {
  children: React.ReactNode
}

export const NotificationsProvider = ({
  children,
}: ProviderProps): JSX.Element => {
  const isMounted = useIsMounted()
  const [notifications, setNotifications] = useState<Notification[]>([])

  const pushNotification = useCallback(
    (data: NotificationData, callback?: Callback) => {
      const id = data.id ?? nextId.next().value
      const cancel = () => {
        if (!isMounted()) {
          return
        }
        setNotifications((current) =>
          current.filter(({ id: notificationId }) => notificationId !== id),
        )
      }

      setNotifications((current) => {
        const existing = current.some(
          ({ id: notificationId }) => notificationId === id,
        )
        if (existing) {
          return current
        }

        return [
          ...current,
          {
            created: new Date(),
            callback,
            ...data,
            id,
          },
        ]
      })

      return cancel
    },
    [isMounted],
  )

  const clearNotification = useCallback(
    (id: number) => {
      if (!isMounted()) {
        return
      }
      setNotifications((current) => {
        const notification = current.find(
          ({ id: notificationId }) => notificationId === id,
        )

        if (!notification) {
          return current
        }

        const { callback } = notification
        if (typeof callback === 'function') {
          callback()
        }

        return current.filter(({ id: notificationId }) => notificationId !== id)
      })
    },
    [isMounted],
  )

  return (
    <NotificationsContext.Provider
      value={{ notifications, clearNotification, pushNotification }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}
