import React, { useCallback, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import ScheduleModal from '../../components/Modals/Conversation/ScheduleModal'

type ScheduleAgainProps = {
  availabilityToken: string
  skill: { id: number; name: string }
  disabled?: boolean
  onScheduled: () => void
  className?: string
}

const ScheduleAgain = ({
  availabilityToken,
  skill: { id: skillId, name: skillName },
  disabled = false,
  onScheduled,
  className,
}: ScheduleAgainProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  return (
    <>
      <Dropdown.Item
        onClick={handleOpen}
        disabled={disabled}
        className={className}
      >
        Schedule again
      </Dropdown.Item>
      <ScheduleModal
        show={open}
        profileTopics={[{ skillId, skillName }]}
        match={{
          token: availabilityToken,
          topics: [{ skillId, skillName }],
        }}
        reloadUpcoming={() => {
          // this is called on success; success does not automatically close the modal
          onScheduled()
          handleClose()
        }}
        onClose={handleClose}
      />
    </>
  )
}

export default ScheduleAgain
