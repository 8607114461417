/** Auth0Config defines the configuration for initializing the Auth0 client. */
export type Auth0Config = {
  domain: string
  clientId: string
  audience: string
}

/** BaseUri defines the base URI values for different subsystems and services. */
export type BaseUri = {
  assessmentsApi: string
  graph: string
  lattusApi: string
  lattusApp: string
  helpdesk: string
  redirect: string
}

/** ConfigCat defines the configuration for initializing the ConfigCat client. */
export type ConfigCat = {
  sdkKey: string
}

/** GoogleConfig defines the configuration for integrating with Google Calendars. */
export type GoogleConfig = {
  clientId: string
}

/** MicrosoftConfig defines the configuration for integrating with Microsoft 365 Calendars. */
export type MicrosoftConfig = {
  clientId: string
}

/** DataDogConfig defines the configuration for integrating with Microsoft 365 Calendars. */
export type DataDogPrivacyLevel = 'mask' | 'mask-user-input' | 'allow'
export type DataDogConfig = {
  allowedTracingUrls?: string[] | RegExp[] | Function[]
  applicationId?: string
  clientToken?: string
  site?: string
  service?: string
  env?: string
  version?: string
  sessionSampleRate?: number
  sessionReplaySampleRate?: number
  trackResources?: boolean
  trackLongTasks?: boolean
  trackUserInteractions?: boolean
  defaultPrivacyLevel?: DataDogPrivacyLevel
}

/** LogLevel are the available logger levels. */
export type LogLevel = 'debug' | 'info' | 'warn' | 'error'

/** Config is the entire application configuration object. */
export type Config = {
  logLevel: LogLevel
  auth0: Auth0Config
  baseUri: BaseUri
  configCat: ConfigCat
  environment: string // TODO can this be a more known enumeration?
  google: GoogleConfig
  microsoft: MicrosoftConfig
  sentry: {
    dsn?: string
    environment?: string
    tracesSampleRate?: number
    replaySampleRate?: number
  }
  datadog: DataDogConfig
}

/**
 * defaultConfig is a reasonable default configuration.
 * Note that "reasonable" does not mean "usable". This provides a fallback for type safety, but
 * it will not necessarily work in a deployed environment.
 */
export const defaultConfig: Config = Object.freeze({
  logLevel: 'info',
  auth0: {
    domain: '',
    clientId: '',
    audience: '',
  },
  baseUri: {
    assessmentsApi: '',
    graph: '',
    lattusApi: '',
    lattusApp: '',
    helpdesk: '',
    redirect: '',
  },
  configCat: {
    sdkKey: '',
  },
  environment: '',
  google: {
    clientId: '',
  },
  microsoft: {
    clientId: '',
  },
  sentry: {},
  datadog: {},
})
