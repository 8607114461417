import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { buttonStyles } from '../theme/theme2'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  root: {
    paddingBottom: 20,
    textAlign: 'center',
  },
  button: {
    padding: 10,
    display: 'inline-block',
    margin: '0 auto',
  },
  primaryButton: {
    ...buttonStyles.primary,
    '&:hover': {
      backgroundColor: '#7BCC9F',
      borderColor: '#7BCC9F',
    },
    '&:disabled': {
      backgroundColor: '#7BCC9F',
      borderColor: '#7BCC9F',
    },
  },
})

const SaveCancel = props => {
  const classes = useStyles()
  const {
    saveText = 'Save',
    cancelText = 'Cancel',
    showSaveButton = true,
    showCancelButton = false,
    disabled = false,
  } = props

  function cancel(e) {
    e.preventDefault()
    if (props.onCancelClick != null) {
      props.onCancelClick()
    } else {
      const { history } = props
      history.goBack()
    }
  }

  function save(e) {
    e.preventDefault()
    if (props.onSaveClick != null) {
      props.onSaveClick()
    }
  }

  return (
    <Container>
      <div className={classes.root}>
        {showSaveButton && (
          <div className={classes.button}>
            <Button
              className={classes.primaryButton}
              type={props.type}
              onClick={save}
              disabled={disabled}
            >
              {saveText}
            </Button>
          </div>
        )}
        {showCancelButton && (
          <div className={classes.button}>
            <Button variant="danger" onClick={cancel} disabled={disabled}>
              {cancelText}
            </Button>
          </div>
        )}
      </div>
    </Container>
  )
}

export default withRouter(SaveCancel)
