import React from 'react'
import { SkillLevelIcon } from '../Icons'
import Dropdown from 'react-bootstrap/Dropdown'
import { Form } from 'react-bootstrap'
import Asterisk from './Asterisk'

const style = {
  input: {
    background: 'white',
    color: '#495057',
    border: '1px solid #ced4da',
    width: '100%',
  },
  options: {
    width: '100%',
  },
}

const ratingValues = [
  {
    value: 1,
    text: 'Little experience',
  },
  {
    value: 2,
    text: 'Some experience',
  },
  {
    value: 3,
    text: 'Significant experience',
  },
  {
    value: 4,
    text: 'Can teach topic',
  },
  {
    value: 5,
    text: 'Subject matter expert',
  },
]

class Ratings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ratings: ratingValues,
      toggleContents: 'Select a Rating',
      selectedOption: { value: 1, text: 'Little Experience' },
    }
  }

  componentDidMount() {
    const { ratings } = this.state
    const { name, value = 1 } = this.props
    const selectedOption = ratings.find(x => x.value === value) || {}
    if (selectedOption) {
      this.setState(
        {
          selectedOption: selectedOption,
          toggleContents: (
            <>
              <SkillLevelIcon rating={value} />
              {value} {selectedOption.text}
            </>
          ),
        },
        this.props.onChange({ target: { name, value } }),
      )
    }
  }

  render() {
    const { ratings, toggleContents } = this.state
    const { label, name } = this.props

    return (
      <Form.Group controlId="fmRating">
        {label && (
          <Form.Label>
            {this.props.label} {this.props.required && <Asterisk />}
          </Form.Label>
        )}

        <Dropdown
          onSelect={eventKey => {
            const { value, text } = ratings.find(
              rating => Number(eventKey) === rating.value,
            )

            this.setState(
              {
                selectedOption: eventKey,
                toggleContents: (
                  <>
                    <SkillLevelIcon rating={value} />
                    {value} {text}
                  </>
                ),
              },
              this.props.onChange({ target: { name, value } }),
            )
          }}
        >
          <Dropdown.Toggle
            id="dropdown-flags"
            className="text-left"
            style={style.input}
          >
            {toggleContents}
          </Dropdown.Toggle>

          <Dropdown.Menu style={style.options}>
            {ratings.map(({ value, text }) => (
              <Dropdown.Item key={value} eventKey={value}>
                <SkillLevelIcon rating={value} />
                {value} {text}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>
    )
  }
}

export default Ratings
export { ratingValues }
