// eslint-disable-next-line @typescript-eslint/no-explicit-any
const flatten = (into: any[], from: any[]) => [...into, ...from]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const encodeEntry = (key: string, value: any): string[] => {
  if (typeof value === 'undefined') {
    return []
  }

  if (Array.isArray(value)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return value.map((val: any) => encodeEntry(key, val)).reduce(flatten, [])
  }

  if (typeof value === 'object') {
    if (value !== null) {
      return Object.entries(value)
        .map(([objKey, objValue]) => encodeEntry(`${key}.${objKey}`, objValue))
        .reduce(flatten, [])
    }

    return []
  }

  return [`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toQuerystring = (obj: Record<string, any>): string => {
  const qs = Object.entries(obj)
    .map(([key, value]) => encodeEntry(key, value))
    .reduce(flatten, [])
    .filter(Boolean)
    .join('&')
  if (qs.length > 0) return `?${qs}`
  return ''
}

export default toQuerystring
