import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  required: {
    color: 'red',
  },
})

const Asterisk = () => {
  const classes = useStyles()

  return <sup className={classes.required}>*</sup>
}

export default Asterisk
