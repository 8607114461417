import React from 'react'
import { createUseStyles } from 'react-jss'

// Elements
import Modal from '../../elemets/Modal'

const useStyles = createUseStyles({
  content: {
    fontFamily: 'Lato',
  },
})

const ConfirmationModal = ({
  show,
  onClose,
  title,
  content,
  closeIcon = false,
  footer,
  closeOutside = true,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <Modal
      show={show}
      close={onClose}
      title={title}
      footer={footer}
      closeIcon={closeIcon}
      closeOutside={closeOutside}
      {...rest}
    >
      <div className={classes.content}>{content}</div>
    </Modal>
  )
}

export default ConfirmationModal
