import React, { useCallback, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import compose from 'just-compose'
import { createUseStyles } from 'react-jss'
import createAuth0Client from '@auth0/auth0-spa-js'

// Elements
import CardContainer from '../../elemets/CardContainer'
import CustomSelect from '../../elemets/CustomSelect'
import { conversationOptions } from '../Profile/TopicsOfInterest'
import CardTitle from '../../elemets/CardTitle'
import RoundButton from '../../elemets/RoundButton'
import IntegrationRow from './IntegrationRow'

// Errors
import { withErrorHandler } from '../../errors'

// Modals
import IntegrationModal from '../Modals/IntegrationModal'

// API
import { useApiSubmit } from '../../api'
import { useAuth0 } from '../../config/useConfig'

const useStyles = createUseStyles({
  selector: {
    width: 75,
  },
})
const MyCalendarsCard = React.memo(
  ({ calendars, checkedList, onCheckIntegration, user, handleError }) => {
    const classes = useStyles()
    const [displayIntegrationModal, setDisplayIntegrationModal] =
      useState(false)
    const [maxConversations, setMaxConversations] = useState()
    const auth0Config = useAuth0()

    const { submitted, submit, err, submitting } =
      useApiSubmit('addIntegrationV1')
    const {
      submitted: updatedProfile,
      submit: updateProfile,
      err: updateErr,
      submitting: updatingProfile,
    } = useApiSubmit('updateProfileV1')

    useEffect(() => {
      if (user) {
        setMaxConversations(
          conversationOptions.find(
            (item) => item.value === user.maxConversations,
          ),
        )
      }
    }, [user])

    const authenticateUser = async (provider) => {
      // TODO refactor this to use the existing auth0 client already instantiated
      const a0 = await createAuth0Client({
        domain: auth0Config.domain,
        client_id: auth0Config.clientId,
      })
      await a0.loginWithPopup({ connection: provider, access_type: 'offline' })
      return await a0.getIdTokenClaims()
    }

    const linkAccount = async (provider) => {
      const secondaryUser = await authenticateUser(provider)
      const { sub: secondaryUserId, email } = secondaryUser

      const body = {
        email,
        provider,
        auth0UserId: secondaryUserId,
      }
      submit({ body })
      setDisplayIntegrationModal(false)
    }

    // Listen to Link
    useEffect(() => {
      if (submitted && !err && !submitting) {
        window.location.reload()
      } else if (err && !submitting) {
        handleError(err, true, {
          message: `There was a problem linking your account: ${err}.`,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitted, err, submitting])

    // Listen to update profile
    useEffect(() => {
      if (updatedProfile && !updateErr && !updatingProfile) {
        // reload()
      } else if (updateErr && !updatingProfile) {
        handleError(updateErr, true, {
          message: 'There was a problem updating your max conversation.',
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedProfile, updateErr, updatingProfile])

    const save = useCallback(
      (maxConversations) => {
        try {
          updateProfile({
            params: {
              profileId: user.id,
            },
            body: {
              firstName: user.firstName,
              lastName: user.lastName,
              conversationFrequencyId: user.conversationFrequencyId,
              notificationFrequencyId: user.notificationFrequencyId,
              maxConversations: Number(maxConversations),
              email: user.email,
              regionId: user.region,
              showTour: user.showTour,
              bio: user.bio,
              funFact: user.funFact,
              homeTown: user.homeTown,
              highSchool: user.highSchool,
              linkedIn: user.linkedIn,
              location: user.location,
              phoneNumber: user.phoneNumber,
            },
          })
        } catch (err) {
          const { handleError } = this.props
          handleError(err, true, {
            message: 'There was a problem updating your profile.',
          })
        }
      },
      [updateProfile, user],
    )

    const handleSelectMax = useCallback(
      (key, value) => {
        setMaxConversations(value)
        save(value.value)
      },
      [save],
    )

    return (
      <>
        <IntegrationModal
          show={displayIntegrationModal}
          onClose={() => setDisplayIntegrationModal(false)}
          linkAccount={linkAccount}
        />

        <CardContainer
          header={
            <div className="d-flex justify-content-between">
              <CardTitle title="Integrations" />
              <RoundButton
                icon="fa fa-plus-circle"
                onClick={() => {
                  setDisplayIntegrationModal(true)
                }}
                type="GREEN"
              />
            </div>
          }
          footerDivider
          footer={
            <Row noGutters className="pl-3">
              <label className={classes.text}>
                Max Scheduled Conversations
              </label>
              <CustomSelect
                options={conversationOptions}
                onSelect={handleSelectMax}
                value={maxConversations}
                border={false}
                className={classes.selector}
              />
            </Row>
          }
        >
          {calendars.map((calendar) => (
            <IntegrationRow
              key={calendar.email}
              calendar={calendar}
              checked={checkedList.includes(calendar.email)}
              onCheck={onCheckIntegration}
            />
          ))}
        </CardContainer>
      </>
    )
  },
)

export default compose(withErrorHandler)(MyCalendarsCard)
