import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import CircleImage from '../../elemets/CircleImage'
import PublicProfileModal from '../Modals/PublicProfileModal'

const useStyles = createUseStyles({
  name: {
    fontFamily: 'lato',
    fontWeight: 'bold!important',
    fontSize: 18,
    cursor: 'pointer',
  },
  title: {
    fontFamily: 'lato',
    fontSize: 14,
  },
})

const GravatarContainer = ({
  profile,
  size,
  enableGravatar = true,
  enableName = true,
  customRightSide,
}) => {
  const classes = useStyles()
  const [showProfile, setShowProfile] = useState(false)
  return (
    <Row noGutters className={``}>
      {showProfile && (
        <PublicProfileModal
          show={showProfile}
          profileId={profile.profileId}
          onClose={() => {
            setShowProfile(false)
          }}
        />
      )}
      <Col
        xs={12}
        className={`d-flex justify-content-start`}
        style={{ cursor: 'pointer' }}
      >
        {enableGravatar && (
          <div
            className={`align-self-center`}
            onClick={() => {
              setShowProfile(true)
            }}
          >
            <CircleImage size={size} src={profile?.avatar} />
          </div>
        )}
        {enableName && (
          <div className={`pl-2 align-self-center`}>
            <p
              className={`mb-0 ${classes.name}`}
              onClick={() => {
                setShowProfile(true)
              }}
            >
              {profile.firstName} {profile.lastName}
            </p>
            {customRightSide ? (
              customRightSide
            ) : (
              <p className={`mb-0 ${classes.title}`}>{profile.title}</p>
            )}
          </div>
        )}
      </Col>
    </Row>
  )
}

export default GravatarContainer
