import moment from 'moment'

const MAX_DURATION_MINUTES = 60 // minutes
export const THIRTY_SECONDS_MILLI = 30000 // milliseconds
export const MAX_EXTEND_DURATION_MINUTES = 15 // minutes

export function calculateTimes(
  scheduledStart: string,
  scheduledEnd: string,
): {
  now: moment.Moment
  allowedBeforeTime: string
  allowedAfterTime: string
  scheduledStart: string
  scheduledEnd: string
  maxEndTime: string
} {
  const now = moment.utc()
  const allowedBeforeTime = moment
    .utc(scheduledStart)
    .clone()
    .subtract(5, 'minutes')
    .format()
  const allowedAfterTime = moment
    .utc(scheduledEnd)
    .clone()
    .add(5, 'minutes')
    .format()
  const maxEndTime = moment
    .utc(scheduledStart)
    .clone()
    .add(MAX_DURATION_MINUTES, 'minutes')
    .format()
  return {
    now,
    allowedBeforeTime,
    allowedAfterTime,
    scheduledStart: moment.utc(scheduledStart).format(),
    scheduledEnd: moment.utc(scheduledEnd).format(),
    maxEndTime,
  }
}
