import { createElement, ReactNode, ReactElement } from 'react'
import { ThemeProvider } from 'react-jss'
import calculateBreakpoints from './breakpoints'
import generateTypography from './typography'

const spacingUnit = 8

const theme = {
  breakpoints: calculateBreakpoints({
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  }),
  palette: {
    error: {
      contrastText: '#721c24',
      light: '#f8d7da',
      dark: '#f5c6cb',
    },
    warning: {
      contrastText: '#856404',
      light: '#fff3cd',
      dark: '#ffeeba',
    },
    info: {
      contrastText: '#0c5460',
      light: '#d1ecf1',
      dark: '#bee5eb',
    },
    success: {
      contrastText: '#155724',
      light: '#d4edda',
      dark: '#c3e6cb',
    },
    background: {
      default: 'white', //'#ECF0F5',
    },
    primary: {
      light: '#CCCCB8',
      main: '#33332E',
    },
    secondary: {
      main: '#DEDEDE',
    },
  },
  spacing: (quantity: number): string => `${spacingUnit * quantity}px`,
  typography: generateTypography({
    body: "'Nunito Sans', sans-serif", // "'Work Sans', sans-serif",
    header: "'Nunito Sans', sans-serif", //"'Roboto Condensed', sans-serif",
  }),
  zIndex: {
    toast: 1100,
    nav: 1200,
    // modal: 1400,
    // tooltip: 1500,
  },
}

export type LattusTheme = typeof theme

const Theme = ({ children }: { children: ReactNode }): ReactElement =>
  createElement(ThemeProvider, { theme, children }, children)

export default Theme
