import React from 'react'
import { createUseStyles } from 'react-jss'
import { useAuth0 } from '../../auth0'
import MobileFooter from './MobileFooter'
import RegularFooter from './RegularFooter'

const useStyles = createUseStyles({
  mobile: {
    '@media (min-width: 599px)': {
      display: 'none',
    },
    '@media (max-width: 600px)': {
      display: 'block',
    },
  },
  regular: {
    '@media (min-width: 599px)': {
      display: 'block',
    },
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
})

const Footer = () => {
  const classes = useStyles()
  const { isAuthenticated } = useAuth0()

  if (!isAuthenticated) return null

  return (
    <div>
      <div className={classes.mobile}>
        <MobileFooter />
      </div>
      <div className={classes.regular}>
        <RegularFooter />
      </div>
    </div>
  )
}

export default Footer
