import React, { useEffect, useState } from 'react'
import CardContainer from '../../elemets/CardContainer'
import CardTitle from '../../elemets/CardTitle'

import { createUseStyles } from 'react-jss'
import { isMobile } from '../../utils/common'
import colors, { headings } from '../../theme/theme2'
import compose from 'just-compose'
import { withRouter } from 'react-router-dom'
import { Row } from 'react-bootstrap'
import { useBrand } from '../../BrandHandler'

const useStyles = createUseStyles({
  title: {
    padding: 0,
  },
  container: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
  },
  mobile: {
    paddingTop: 0,
    minHeight: 'unset',
  },
  icon: {
    '& i': {
      fontSize: 20,
      color: colors.primary,
    },
    position: 'absolute',
    left: 10,
  },
  iconText: {
    paddingLeft: 10,
  },
  actionButton: {
    width: '100%!important',
  },
  activityRow: {
    '&:nth-child(2)': {
      marginTop: 15,
    },
  },
  activity: {
    width: '60%',
    marginBottom: 0,
    lineHeight: '1.2',
    marginLeft: 20,
    textAlign: 'left',
    ...headings.text,
  },
  activityTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    color: colors.dark,
  },
  activityInfo: {
    fontWeight: 'bold',
    fontSize: 12,
    color: colors.dark,
    paddingLeft: 15,
  },
  activityLink: {
    fontWeight: 'bold',
    fontSize: 12,
    color: colors.primary,
  },
})

const Welcome = () => {
  const classes = useStyles()
  const [videoUrl, setVideoUrl] = useState(
    'https://www.youtube.com/embed/MlqLS-QvT9w',
  )
  const { isBranded, brandInfo } = useBrand()
  const header = (
    <CardTitle
      className={`${classes.title}`}
      title={
        isBranded ? 'Welcome to ' + brandInfo?.name : 'Welcome to Lattitude'
      }
    />
  )

  useEffect(() => {
    if (isBranded && brandInfo && brandInfo['whiteLabelVideoURL']) {
      setVideoUrl(brandInfo['whiteLabelVideoURL'])
    }
  }, [isBranded, brandInfo])

  return (
    <CardContainer
      className={`${classes.container} pt-3`}
      header={header}
      headerDivider={true}
    >
      <Row
        noGutters={isMobile()}
        className={`${!isMobile() ? 'pl-4 pr-4' : ''} ${classes.activityRow}`}
      >
        <iframe
          title="welcome-video"
          width="100%"
          height={isMobile() ? '195' : '315'}
          src={videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Row>
    </CardContainer>
  )
}

export default compose(withRouter)(Welcome)
