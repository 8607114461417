import React, { useState } from 'react'
import AutoSuggest from 'react-autosuggest'
import { createUseStyles } from 'react-jss'
import colors, { headings } from '../theme/theme2'

const useStyles = createUseStyles({
  title: {
    fontSize: 14,
    fontFamily: 'Lato',
    color: colors.grey2,
    paddingLeft: 16,
    marginBottom: 0,
    width: '100%',
  },
  autoSuggest: {
    '& .react-autosuggest__container--open': {
      position: 'relative',
    },
    '& .react-autosuggest__input': {
      width: '100%',
      padding: '0 13px',
      border: `1px solid ${colors.grey1}`,
      borderRadius: 4,
      color: colors.dark,
      fontFamily: 'Lato',
      fontWeight: 'bold',
      fontSize: 16,
      '&:disabled': {
        color: colors.dark,
        cursor: 'default',
        border: '1px solid transparent',
        backgroundColor: 'transparent',
      },
      '&:focus': {
        outline: 'none',
      },
      '&::placeholder': {
        color: colors.dark,
      },
    },
    '& .react-autosuggest__container--open .react-autosuggest__input': {
      //borderBottomLeftRadius: 0,
      //borderBottomRightRadius: 0,
    },
    '& .react-autosuggest__container--open .react-autosuggest__suggestions-container':
      {
        position: 'absolute',
        left: 10,
        top: 30,
        width: '95%',
        maxHeight: 250,
        border: `1px solid ${colors.grey2}`,
        borderRadius: 5,
        backgroundColor: colors.whiteBackground,
        ...headings.text,
        zIndex: 2,
        overflowY: 'scroll',
        fontSize: 16,
        fontFamily: 'Lato',
        fontWeight: 'Bold',
      },
    '& .react-autosuggest__suggestions-list': {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    '& .react-autosuggest__suggestion': {
      cursor: 'pointer',
      padding: '3px 12px',
    },
    '& .react-autosuggest__suggestion:not(:first-child)': {
      paddingTop: 0,
    },
    '& .react-autosuggest__suggestion--highlighted': {
      backgroundColor: colors.primary100,
      color: colors.primary,
    },

    // if you want to highlight the character matches, change this color
    '& .highlight': {
      //color: colors.dark,
    },
    '& .react-autosuggest__suggestion--focused .highlight': {
      //color: "#120000"
    },
  },
})

const AutoSuggestInput = ({
  title,
  placeHolder,
  setFormValue,
  data,
  filterFunction,
}) => {
  const classes = useStyles()

  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])

  function getSuggestions(value) {
    return filterFunction(data, value)
  }

  return (
    <div className={classes.autoSuggest}>
      {title && <span>{title}</span>}
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionsFetchRequested={({ value }) => {
          setValue(value)
          setSuggestions(getSuggestions(value))
        }}
        onSuggestionSelected={(_, { suggestion }) => setFormValue(suggestion)}
        getSuggestionValue={suggestion => suggestion.label}
        renderSuggestion={suggestion => <span>{suggestion.label}</span>}
        inputProps={{
          placeholder: placeHolder,
          value: value,
          onChange: (_, { newValue }) => {
            setValue(newValue)
          },
        }}
        highlightFirstSuggestion={true}
      />
    </div>
  )
}

export default AutoSuggestInput
