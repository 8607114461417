import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Container, ListGroup } from 'react-bootstrap'
import { useAuth0 } from '../auth0'

const items = [
  {
    title: 'Manage Availability',
    link: '/availability',
    description:
      'Set and manage your availability for others to schedule conversations with you',
  },
  {
    title: 'Manage Topics of Interest',
    link: '/topics',
    description:
      'Set and manage your topics for others to see your skills and interests',
  },
  // {
  //   title: 'Lattitude Status',
  //   link: '/comingsoon',
  //   description:
  //     'Follow along with our blog to read about our most exciting conversations',
  // },
  // {
  //   title: 'Invite/Refer Users',
  //   link: '/comingsoon',
  //   description:
  //     "'Everyone you will ever meet knows something you don’t.' -Bill Nye",
  // },
  // {
  //   title: 'Manage/Create Groups',
  //   link: '/comingsoon',
  //   description:
  //     'Create a Group in order to gain access to more in-depth usage metrics & facilitate broader conversations',
  // },
  // {
  //   title: 'Admin Metrics',
  //   link: '/comingsoon',
  //   description:
  //     'View important metrics related to your Lattitude group, including conversation, user, and topic totals.',
  // },
  // {
  //   title: 'Welcome Video',
  //   link: '/comingsoon',
  //   description: 'Replay the Welcome Video',
  // },
  // {
  //   title: 'How It Works',
  //   link: '/comingsoon',
  //   description:
  //     'Check out what makes Lattitude different and the work that brought us here today',
  // },
  {
    title: 'Report an Issue',
    link: 'https://golattitude.atlassian.net/servicedesk/customer/portal/3/group/5',
    description: 'Report an issue with the application',
  },
]

const style2 = {
  background: '#ECF0F5',
  fontSize: 9,
}

const Links = () => {
  const { logout } = useAuth0()

  return (
    <Container style={{ paddingTop: 15 }}>
      <h1>Settings</h1>
      <ListGroup>
        {items.map(function (item) {
          const props =
            item.link.startsWith('http') || item.link.startsWith('mailto')
              ? { href: item.link, target: item.target }
              : { as: Link, to: item.link }
          return [
            <Fragment key={item.link}>
              <ListGroup.Item action {...props}>
                {item.title}
              </ListGroup.Item>
              <ListGroup.Item style={style2}>{item.description}</ListGroup.Item>
            </Fragment>,
          ]
        })}
      </ListGroup>

      <ListGroup style={{ paddingTop: 20 }}>
        <ListGroup.Item onClick={logout}>Logout</ListGroup.Item>
      </ListGroup>
    </Container>
  )
}

export default Links
