/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import compose from 'just-compose'
import { createUseStyles } from 'react-jss'

// Theme
import { withErrorHandler } from '../../../errors'
import { withNotifications } from '../../../notifications'
import Modal from '../../../elemets/Modal'
import ActionButton from '../../../elemets/ActionButton'
import colors, { headings } from '../../../theme/theme2'
import { useApiFetch, useApiSubmit } from '../../../api'
import { isMobile } from '../../../utils/common'
import moment from 'moment'
import { Row, Col } from 'react-bootstrap'
import Icon from '../../Icons'
import { withRouter } from 'react-router'
import RequestRescheduleModal from './RequestRescheduleModal'

const useStyles = createUseStyles({
  body: {
    margin: 0,
    padding: 0,
  },
  label: {
    ...headings.title,
    fontSize: 12,
  },
  title: {
    ...headings.title,
    paddingRight: 5,
  },
  icon: {
    '& i': {
      '&:before': {
        color: colors.primary,
      },
    },
  },
  dateRow: {
    padding: isMobile() ? '18px 9px 14px 9px' : '13px 15px 13px 31px',
    width: '100%',
  },
  time: {
    ...headings.paragraph,
    fontSize: 12,
    color: colors.dark,
    fontWeight: 'bold',
  },
  subtitle: {
    ...headings.paragraph,
    color: colors.dark,
    fontWeight: 'bold',
  },
  bgGrey: {
    backgroundColor: colors.grey1,
  },
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  availabilitiesMobile: {
    width: '100%',
  },
  modal: {
    '& .modal-dialog': {
      minWidth: 730,
    },
  },
  select: {
    width: '100%',
  },
  arrow: {
    minWidth: 10,
  },
})

const AvailabilityRow = ({
  index,
  availability,
  handleError,
  pushNotification,
  conversationId,
  bannerId,
  close,
}) => {
  const classes = useStyles()
  const [selectedAvailability, setSelectedAvailability] = useState(false)

  const {
    submitted,
    submit: scheduleConversation,
    err,
    submitting,
  } = useApiSubmit('rescheduleConversation')

  useEffect(() => {
    if (submitted && !submitting && !err) {
      pushNotification({
        level: 'success',
        subject: 'Conversation Rescheduled.',
        timeout: 10,
      })
      setSelectedAvailability(true)
      // reloadUpcoming()
      // eslint-disable-next-line no-console
      close()
    } else if (!submitting && err) {
      handleError(err, true, {
        message: 'Unable to request rescheduled a conversation.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, submitting, err])

  const handleSchedule = useCallback(
    availability => {
      if (conversationId) {
        const params = {
          conversationId: conversationId,
        }
        const body = {
          scheduled: moment(availability.startTime).utc(),
          bannerId: bannerId,
        }
        scheduleConversation({ params, body })
      }
    },
    [conversationId, bannerId, scheduleConversation],
  )

  const renderButton = useCallback(
    availability => {
      return selectedAvailability ? (
        <ActionButton
          height="34px"
          text="CONVO RESCHEDULED"
          type="LIGHT_GREEN"
          className="text-nowrap"
          disabled
        />
      ) : (
        <ActionButton
          height="34px"
          text="RESCHEDULE"
          onClick={() => handleSchedule(availability)}
          type="LIGHT_GREEN"
          className="text-nowrap"
        />
      )
    },
    [selectedAvailability, handleSchedule],
  )

  return (
    <div
      key={`${availability.id}_${availability.startTime}`}
      className={`${classes.dateRow} ${index % 2 ? '' : classes.bgGrey} ${
        isMobile() ? classes.availabilitiesMobile : ''
      }`}
    >
      {!isMobile() ? (
        <Row className="align-items-center">
          <Col xs={4} className={classes.time}>
            {moment(availability.startTime).format('dddd, MMMM DD, YYYY')}
          </Col>
          <Col xs={4} className={`text-center ${classes.time}`}>
            {moment(availability.startTime).format('hh:mm A')} to{' '}
            {moment(availability.endTime).format('hh:mm A')}
          </Col>
          <Col xs={4} className="d-flex justify-content-end">
            {renderButton(availability)}
          </Col>
        </Row>
      ) : (
        <Row className="align-items-center">
          <Col xs={7} className={classes.time}>
            {moment(availability.startTime).format('dddd, MMM DD, YYYY')}
            <br />
            {moment(availability.startTime).format('hh:mm A')} to{' '}
            {moment(availability.endTime).format('hh:mm A')}
          </Col>
          <Col xs={5} className="d-flex justify-content-end">
            {renderButton(availability)}
          </Col>
        </Row>
      )}
    </div>
  )
}

const ReScheduleModal = ({
  conversation,
  show,
  onClose,
  bannerId = undefined,
  handleError,
  reloadUpcoming,
  pushNotification,
}) => {
  const classes = useStyles()
  const [pagination, setPagination] = useState(0)
  const [upperLimit, setUpperLimit] = useState(0)
  const [showRequestReschedule, setShowRequestReschedule] = useState(false)
  const [requested, setRequested] = useState(false)
  const {
    data: matchAvailability,
    loading: loadingMatchAvailability,
    err: errorMatchAvailability,
    fetch: listMatchAvailability,
  } = useApiFetch('listMatchAvailability', {}, false)

  useEffect(() => {
    if (!loadingMatchAvailability && errorMatchAvailability) {
      handleError(errorMatchAvailability, true, {
        message: 'Unable to load participant availability.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchAvailability, loadingMatchAvailability, errorMatchAvailability])

  useEffect(() => {
    if (show && conversation) {
      listMatchAvailability({
        query: { token: conversation.availabilityToken },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, show])

  useEffect(() => {
    if (matchAvailability) {
      setUpperLimit(Math.ceil(matchAvailability.length / 5))
    }
  }, [matchAvailability])

  return (
    <>
      {showRequestReschedule && (
        <RequestRescheduleModal
          show={showRequestReschedule}
          onClose={() => setShowRequestReschedule(false)}
          conversation={conversation}
          setRequestedAvailability={setRequested}
        />
      )}
      <Modal
        show={show}
        close={() => {
          setPagination(0)
          onClose()
        }}
        title={
          <div
            className={`${classes.icon} ${
              isMobile() ? 'd-flex justify-content-center' : ''
            }`}
          >
            <label className={`${classes.title}`}>
              Reschedule Conversation{' '}
            </label>
            <Icon icon="fas fa-calendar-alt" />
          </div>
        }
        closeIcon={true}
        bodyClass={classes.body}
        modalClass={`${!isMobile() ? classes.modal : ''}`}
        dialogClassName={isMobile() ? classes.dialogMobile : ''}
      >
        <Row noGutters className={`pb-2`}>
          {matchAvailability
            ?.slice(pagination * 5, pagination * 5 + 5)
            .map((av, index) => (
              <AvailabilityRow
                key={index}
                availability={av}
                index={index}
                reloadUpcoming={reloadUpcoming}
                conversationId={conversation.id}
                bannerId={bannerId}
                handleError={handleError}
                pushNotification={pushNotification}
                close={() => {
                  setPagination(0)
                  onClose()
                }}
              />
            ))}
          {(!matchAvailability || matchAvailability?.length === 0) && (
            <div className={`w-100`}>
              <p className={`${classes.subtitle} p-3`}>
                {' '}
                Sorry, the match doesn't have any availabilities at the moment{' '}
              </p>
              <div className={'d-flex justify-content-end w-100'}>
                <ActionButton
                  height="34px"
                  text={
                    requested ? 'RESCHEDULE REQUESTED' : 'REQUEST RESCHEDULE'
                  }
                  onClick={() => {
                    setShowRequestReschedule(true)
                  }}
                  type="STRONG_GREEN"
                  className="text-nowrap"
                  disabled={requested}
                />
              </div>
            </div>
          )}
        </Row>
        {matchAvailability?.length > 0 && matchAvailability?.length <= 5 && (
          <div className={'d-flex justify-content-end w-100 pr-3'}>
            <ActionButton
              height="34px"
              text={requested ? 'RESCHEDULE REQUESTED' : 'REQUEST RESCHEDULE'}
              onClick={() => {
                setShowRequestReschedule(true)
              }}
              type="LIGHT_GREEN"
              className="text-nowrap"
              disabled={requested}
            />
          </div>
        )}

        {matchAvailability?.length > 5 && (
          <Row noGutters className={`pb-2 pr-3`}>
            <Col xs={12} className={'d-flex justify-content-center'}>
              <div className={`d-flex ${classes.icon}`}>
                <div className={classes.arrow}>
                  {pagination !== 0 && (
                    <Icon
                      icon={'fas fa-chevron-left'}
                      onClick={() => setPagination(s => s - 1)}
                    />
                  )}
                </div>
                <span className={'pr-2 pl-2'}>{pagination + 1}</span>
                <div className={classes.arrow}>
                  {pagination + 1 < upperLimit && (
                    <Icon
                      icon={'fas fa-chevron-right'}
                      onClick={() => setPagination(s => s + 1)}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  )
}

export default compose(
  withErrorHandler,
  withNotifications,
  withRouter,
)(ReScheduleModal)
