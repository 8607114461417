import React from 'react'
import { Row } from 'react-bootstrap'

import { createUseStyles } from 'react-jss'
import { responsive } from '@/theme/theme2.js'
import { isMobile } from '@/utils/common.js'

const useStyles = createUseStyles({
  shape: {
    marginLeft: 5,
    width: 30,
    height: 5,
    '@media (min-width: 768px)': {
      width: 26,
    },
  },
  progressMobile: {
    marginLeft: 3,
  },
  progressLeft: {
    backgroundColor: '#d8d8d8',
  },
  ...responsive,
})

type Props = {
  cols: number
  active: number
  status: {
    icon: string
    bg: string
  }
}
const ProgressBar = ({ cols, active, status }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Row className={isMobile() ? classes.progressMobile : ''}>
      {[...Array(cols)].map((e, i) => (
        <div
          className={`${classes.shape} ${
            active > i ? status.bg : classes.progressLeft
          }`}
          key={i}
        />
      ))}
    </Row>
  )
}

export default ProgressBar
