import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import compose from 'just-compose'
import { createUseStyles } from 'react-jss'

import { withErrorHandler } from '../../errors'

// Elements
import Modal from '../../elemets/Modal'
import CustomSelect from '../../elemets/CustomSelect'
import ActionButton from '../../elemets/ActionButton'
import LinkButton from '../../elemets/LinkButton'
import CustomCheckbox from '../../elemets/CustomCheckbox'

// Api
import { useApiFetch, useApiSubmit } from '../../api/index'

// Theme
import colors from '../../theme/theme2'

// Utils
import { isMobile } from '../../utils/common'
import LoaderPage from '../../elemets/LoaderPage'
import CustomInput from '../../elemets/CustomInput'
import moment from 'moment'

const YEARS = () => {
  const years = []
  const dateStart = moment()
  const dateEnd = moment().subtract(100, 'y')
  while (dateEnd.diff(dateStart, 'years') < 0) {
    years.push({
      label: dateStart.format('YYYY'),
      value: dateStart.format('YYYY'),
    })
    dateStart.subtract(1, 'year')
  }
  return years
}

const useStyles = createUseStyles({
  select: {
    marginBottom: 14,
  },
  delete: {
    color: colors.red,
    '&:hover': {
      color: colors.red,
    },
  },
  present: {
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.greyParagraph,
    marginLeft: 10,
  },
  addButton: {
    float: 'right',
    color: colors.primary,
    '&:hover, &:active, &:focus': {
      color: colors.primary,
    },
  },
  addSchool: {
    marginBottom: 14,
  },
  input: {
    width: '100%',
    padding: '0 16px',
    border: `1px solid ${colors.grey1}`,
    borderRadius: 4,
    color: colors.dark,
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: 16,
    '&:disabled': {
      marginTop: 24,
      color: colors.grey2,
      cursor: 'default',
      border: '1px solid transparent',
      backgroundColor: 'white',
    },
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: colors.dark,
      opacity: 1,
    },
  },
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
})

const EducationModal = ({
  show,
  education,
  user,
  onClose,
  handleError,
  onFinish,
}) => {
  const classes = useStyles()
  const [formState, setFormState] = useState({
    level: '',
    school: '',
    vocation: '',
    startDate: '',
    endDate: '',
  })
  const [checkPresent, setCheckPresent] = useState(false)

  const { loading: loadingLevels, data: levels } = useApiFetch(
    'listEducationLevels',
    { query: { count: 1000 } },
  )

  const {
    submitted: added,
    submit: addEducation,
    err: errAdd,
    submitting: addLoading,
  } = useApiSubmit('addEducation')

  const {
    submitted: updated,
    submit: updateEducation,
    err: errUpdate,
    submitting: updateLoading,
  } = useApiSubmit('updateEducation')

  const {
    submitted: removed,
    submit: removeEducation,
    err: errRemove,
    submitting: removeLoading,
  } = useApiSubmit('removeEducation')

  useEffect(() => {
    if (education) {
      setFormState({
        school: education.schoolName,
        level: { value: education.levelId, label: education.levelName },
        vocation: education.major,
        startDate: education.startDate
          ? moment(education.startDate).utc().format()
          : '',
        endDate: education.endDate
          ? moment(education.endDate).utc().format()
          : '',
      })
      if (!education.endDate) {
        setCheckPresent(true)
      }
    } else {
      setFormState({
        level: '',
        school: '',
        vocation: '',
        startDate: '',
        endDate: '',
      })
    }
  }, [education])

  // When saved
  useEffect(() => {
    if (
      (added && !addLoading && !errAdd) ||
      (updated && !updateLoading && !errUpdate)
    ) {
      setFormState({
        level: '',
        school: '',
        vocation: '',
        startDate: '',
        endDate: '',
      })
      setCheckPresent(false)
      onFinish()
    } else if (errAdd && !addLoading) {
      handleError(errAdd, true, {
        message: 'There was a problem saving your Education.',
      })
    } else if (errUpdate && !updateLoading) {
      handleError(errUpdate, true, {
        message: 'There was a problem saving your Education.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [added, updated, addLoading, updateLoading, errAdd, errUpdate])

  // When Removed
  useEffect(() => {
    if (removed && !removeLoading && !errRemove) {
      setCheckPresent(false)
      onFinish()
    } else if (errRemove && !removeLoading) {
      handleError(errRemove, true, {
        message: 'There was a problem removing your Education.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removed, removeLoading, errRemove])

  const handleSelect = useCallback((key, value) => {
    setFormState((f) => ({
      ...f,
      [key]: value,
    }))
  }, [])

  const isEditMode = useMemo(() => education && education.id, [education])
  const isFormInvalid = useMemo(
    () => !formState.level || !formState.school || !formState.vocation,
    [formState],
  )

  const handleSave = useCallback(() => {
    const body = {
      schoolName: formState.school,
      levelId: parseInt(formState.level.value, 10),
      major: formState.vocation,
    }
    if (formState.startDate !== '') {
      body['startDate'] = moment(formState.startDate).utc().format('YYYY-MM-DD')
    }
    if (formState.endDate) {
      body['endDate'] = moment(formState.endDate).utc().format('YYYY-MM-DD')
      if (moment(body.endDate).isBefore(moment(body.startDate))) {
        handleError(errRemove, true, {
          message: 'End Date should be after Start Date',
        })
        return
      }
    }

    if (formState.school === '' || formState.vocation === '') {
      handleError(null, true, {
        message: 'Please fill all required fields',
      })
      return
    }

    if (isEditMode) {
      updateEducation({
        params: { profileId: user.id, educationId: education.id },
        body,
      })
    } else {
      addEducation({
        params: { profileId: user.id },
        body,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, updateEducation, user, education, formState, addEducation])

  const handleDelete = useCallback(() => {
    removeEducation({
      params: { profileId: user.id, educationId: education.id },
    })
  }, [removeEducation, user, education])

  return (
    <Modal
      show={show}
      close={() => {
        setFormState({
          level: '',
          school: '',
          vocation: '',
          startDate: '',
          endDate: '',
        })
        setCheckPresent(false)
        return onClose()
      }}
      title="Education"
      footer={
        <div
          className={`d-flex ${
            isEditMode ? 'justify-content-between' : 'justify-content-end'
          } `}
        >
          {isEditMode && (
            <LinkButton
              icon="las la-minus-circle"
              text="Delete"
              className={classes.delete}
              onClick={handleDelete}
              dataTestId="myprofile-education-modal-deletebutton"
            />
          )}
          <ActionButton
            text="SAVE"
            onClick={handleSave}
            type="STRONG_GREEN"
            disabled={isFormInvalid}
            loading={addLoading || updateLoading}
            dataTestId="myprofile-education-modal-savebutton"
          />
        </div>
      }
      dialogClassName={isMobile() ? classes.dialogMobile : ''}
      dataTestId={'myprofile-education-modal'}
    >
      <Container data-testid="myprofile-education-modal-form">
        {removeLoading && <LoaderPage />}
        {loadingLevels ? null : (
          <>
            <CustomInput
              isRequired
              inputStyle="pr-0"
              value={formState.school}
              title="School Name"
              onChange={(e) =>
                setFormState({ ...formState, school: e.target.value })
              }
              className={classes.addSchool}
              placeholder={'School Name'}
              dataTestId={'myprofile-education-modal-schoolname'}
            />
            <CustomInput
              isRequired
              inputStyle="pr-0"
              value={formState.vocation}
              title="Major / Vocation"
              onChange={(e) =>
                setFormState({ ...formState, vocation: e.target.value })
              }
              className={classes.addSchool}
              placeholder={'Area of Study/Vocation'}
              dataTestId={'myprofile-education-modal-major'}
            />
            <CustomSelect
              isRequired
              options={levels?.map((level) => ({
                value: level.id,
                label: level.name,
              }))}
              name="level"
              value={formState.level}
              onSelect={handleSelect}
              className={classes.select}
              title="Degree / Level"
              placeholder={'Education Level'}
              dataTestId={'myprofile-education-modal-degreedropdown'}
            />
            <Row>
              <Col xs={6}>
                <CustomSelect
                  options={moment.months().map((month, i) => ({
                    value: i,
                    label: month,
                  }))}
                  name="startDateMonth"
                  value={
                    formState.startDate !== ''
                      ? {
                          value: moment(formState.startDate).utc().format('MM'),
                          label: moment(formState.startDate)
                            .utc()
                            .format('MMMM'),
                        }
                      : ''
                  }
                  onSelect={(key, value) => {
                    const date =
                      formState.startDate !== ''
                        ? moment(formState.startDate)
                        : moment()
                    date.set({ date: '10', month: value.value })
                    setFormState({ ...formState, startDate: date.format() })
                  }}
                  className={classes.select}
                  title={'Start Date'}
                  placeholder={'Month'}
                  dataTestId={'myprofile-education-modal-startmonth'}
                />
              </Col>
              <Col xs={6}>
                <CustomSelect
                  options={YEARS()}
                  value={
                    formState.startDate !== ''
                      ? {
                          value: moment(formState.startDate).utc().format('YY'),
                          label: moment(formState.startDate)
                            .utc()
                            .format('YYYY'),
                        }
                      : ''
                  }
                  onSelect={(key, value) => {
                    const date =
                      formState.startDate !== ''
                        ? moment(formState.startDate)
                        : moment()
                    date.set({ date: '10', year: value.value })
                    setFormState({ ...formState, startDate: date.format() })
                  }}
                  className={classes.select}
                  title={' '}
                  placeholder={'Year'}
                  dataTestId={'myprofile-education-modal-startyear'}
                />
              </Col>
            </Row>
            {!checkPresent && (
              <Row>
                <Col xs={6}>
                  <CustomSelect
                    options={moment.months().map((month, i) => ({
                      value: i,
                      label: month,
                    }))}
                    name="endDateMonth"
                    //value={}
                    value={
                      formState.endDate !== ''
                        ? {
                            value: moment(formState.endDate).utc().format('MM'),
                            label: moment(formState.endDate)
                              .utc()
                              .format('MMMM'),
                          }
                        : ''
                    }
                    onSelect={(key, value) => {
                      const date =
                        formState.endDate !== ''
                          ? moment(formState.endDate)
                          : moment()
                      date.set({ date: '10', month: value.value })
                      setFormState({ ...formState, endDate: date.format() })
                    }}
                    className={classes.select}
                    title={'End Date'}
                    placeholder={'Month'}
                    dataTestId={'myprofile-education-modal-endmonth'}
                  />
                </Col>
                <Col xs={6}>
                  <CustomSelect
                    options={YEARS()}
                    value={
                      formState.endDate !== ''
                        ? {
                            value: moment(formState.endDate).utc().format('YY'),
                            label: moment(formState.endDate)
                              .utc()
                              .format('YYYY'),
                          }
                        : ''
                    }
                    onSelect={(key, value) => {
                      const date =
                        formState.endDate !== ''
                          ? moment(formState.endDate)
                          : moment()
                      date.set({ date: '10', year: value.value })
                      setFormState({ ...formState, endDate: date.format() })
                    }}
                    className={classes.select}
                    title={' '}
                    placeholder={'Year'}
                    dataTestId={'myprofile-education-modal-endyear'}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <div className="d-flex mt-2 pl-4">
                  <CustomCheckbox
                    checked={checkPresent}
                    onClick={() => {
                      setCheckPresent((c) => !c)
                      setFormState((f) => ({ ...f, endDate: '' }))
                    }}
                    dataTestId={'myprofile-education-modal-checkbox'}
                  />
                  <span className={classes.present}>Present</span>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Modal>
  )
}

export default compose(withErrorHandler)(EducationModal)
