import React, { useContext, useEffect, FC } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { useApiFetch } from '@/api'
import { IUserProfile } from '@/types/UserProfile.typings'
import { useWafToken } from '@/components/Context/WafTokenProvider'

type CurrentUserContextType = {
  currentUser: IUserProfile
  reload: () => void
  loading: boolean
  loadingUserError: Error | undefined | null
}
const CurrentUserContext = React.createContext<CurrentUserContextType>({
  currentUser: {} as IUserProfile,
  reload: () => {},
  loading: true,
  loadingUserError: null,
})

export const useCurrentUser = (): CurrentUserContextType =>
  useContext(CurrentUserContext)

type Props = {
  children: React.ReactNode
}

const CurrentUser: FC<Props> = ({ children }: Props) => {
  const { wafToken } = useWafToken()
  const {
    data: userProfile,
    reload: reloadUser,
    loading: loadingUser,
    err: loadingUserError,
    fetch: fetchUser,
  } = useApiFetch<IUserProfile>('getCurrentProfile', {}, false)

  useEffect(() => {
    // if (!isMounted()) return
    if (wafToken && !userProfile && !loadingUser && !loadingUserError) {
      // eslint-disable-next-line no-console
      console.debug('CurrentUser: fetching user')
      fetchUser()
    }
  }, [wafToken, userProfile, fetchUser, loadingUser, loadingUserError])

  useEffect(() => {
    if (userProfile?.id) {
      datadogRum.setUser({
        id: `${userProfile.id}`,
        email: userProfile.email,
      })
    }
  }, [userProfile?.id, userProfile?.email])

  // TODO: instrument RUM with Current User
  // TODO: find other usages of `getCurrentProfile` and replace with this context
  // TODO: refactor this to use: useApi() and `invoke` and AbortController similar to "src/groups/GroupsContext.tsx"
  // TODO: similarly think about refactoring the PublicProfile page as well

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser: userProfile ? userProfile : ({} as IUserProfile),
        reload: () => {
          reloadUser()
        },
        loading: loadingUser,
        loadingUserError: loadingUserError ?? null,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  )
}

export default CurrentUser
