export const CALL_LAYOUT = {
  POST: 'POST-CALL',
  PRE: 'PRE-CALL',
  IN: 'IN-CALL',
} as const

export type CallLayout = (typeof CALL_LAYOUT)[keyof typeof CALL_LAYOUT]
export const ConvoStatus = {
  Completed: 'Completed',
  Scheduled: 'Scheduled',
  Cancelled: 'Cancelled',
}
