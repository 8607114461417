import React, { useState } from 'react'
import CardContainer from '../../elemets/CardContainer'
import CardTitle from '../../elemets/CardTitle'

import { createUseStyles } from 'react-jss'
import { isMobile } from '../../utils/common'
import ActionButton from '../../elemets/ActionButton'
import colors, { headings } from '../../theme/theme2'
import compose from 'just-compose'
import { withRouter } from 'react-router-dom'
import Icon from '../Icons'
import TopicsModal from '../Topics/TopicsModal'
import Tooltip from './Tooltip'
import MentorBadge from '../Topics/MentorBadge'
import { useCurrentUser } from '../Context/CurrentUser'

const useStyles = createUseStyles({
  title: {
    padding: 0,
  },
  container: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
  },
  mobile: {
    paddingTop: 0,
    minHeight: 'unset',
  },
  icon: {
    '& i': {
      fontSize: 20,
      color: colors.primary,
    },
  },
  actionButton: {
    width: '100%!important',
  },
  textMain: {
    ...headings,
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.dark,
  },
  textSubtitle: {
    ...headings,
    fontSize: 14,
    fontWeight: 'normal',
    color: colors.grey2,
  },
  tooltipPosition: {
    right: -450,
    top: -80,
  },
  tooltipPositionMobile: {
    top: -240,
  },
})

const Topics = ({ setTooltip, showTooltip, closeTooltip, history }) => {
  const classes = useStyles()
  const [topicModal, setTopicModal] = useState(false)
  const { currentUser } = useCurrentUser()
  const header = (
    <>
      <CardTitle
        className={`${classes.title} ${isMobile() ? 'pt-3' : ''}`}
        title={'Topics'}
      />
      {showTooltip === 1 && (
        <Tooltip
          activeNumber={showTooltip}
          title={'Add Topics of Interest'}
          content={
            'To connect with the right people, choose some topics you’d \n' +
            'like to talk about. Add subtopics and your experience level\n' +
            'so Lattitude can help you become a better mentor or mentee.\n'
          }
          setTooltip={setTooltip}
          close={closeTooltip}
          arrow={isMobile() ? 'arrowDownMobile' : 'arrowLeft'}
          className={
            isMobile() ? classes.tooltipPositionMobile : classes.tooltipPosition
          }
        />
      )}
    </>
  )
  const footer = (
    <div className={`d-flex justify-content-end`}>
      <ActionButton
        className="w-40"
        type={'STRONG_GREEN'}
        text={'ADD'}
        onClick={() => {
          setTopicModal(true)
        }}
      />
      <br />
    </div>
  )

  if (!currentUser?.id) {
    return null
  }

  return (
    <CardContainer
      className={`${classes.container} ${isMobile() ? classes.mobile : ''}`}
      header={header}
      headerDivider={true}
      footer={footer}
    >
      {topicModal && (
        <TopicsModal
          show={topicModal}
          onClose={() => {
            setTopicModal(false)
          }}
          onSuggest={() => {
            setTopicModal(false)
            history.push('/topics', { openModal: true })
          }}
          title={'Add Topic of Interest'}
        />
      )}
      {currentUser.topics && currentUser.topics.length > 0 ? (
        currentUser.topics?.map((topic, index) => {
          return (
            <div key={index} className={`p-2`}>
              <p className={`${classes.textMain} mb-0`}>
                {topic.skillName}
                <MentorBadge isMentor={topic.isMentor} />
              </p>
              <p className={`${classes.textSubtitle} mb-0 pl-3`}>
                {topic.groupName}
              </p>
            </div>
          )
        })
      ) : (
        <div className={`d-flex justify-content-left p-2`}>
          <div className={`pt-2 ${classes.icon}`}>
            <Icon icon={'fas fa-exclamation-triangle'} />
          </div>
          <div className={`pl-3`}>
            <p className={`${classes.textMain} mb-0`}>No Topics of Interest</p>
            <p className={classes.textSubtitle}>
              Add a topic to start a conversation
            </p>
          </div>
        </div>
      )}
    </CardContainer>
  )
}

export default compose(withRouter)(Topics)
