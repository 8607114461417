import React, { createContext, useEffect } from 'react'
import { Config, defaultConfig } from './Config'
import { LoadStatus } from './LoadStatus'
import useConfigState, { loading, loadedConfig, error } from './reducer'

/** Context provides the application configuration. */
export const Context = createContext<Config>(defaultConfig)

type ProviderProps = {
  children: React.ReactNode
  config: Promise<Config>
}

/**
 * Provider handles loading the application configuration and making it available to child
 * components. While the configuration is loading, children will NOT be rendered.
 *
 * If an error occurs loading the configuration, then the user will be shown an error, since it
 * is assumed that the application cannot reliably run without its configuration.
 */
const Provider = ({ children, config }: ProviderProps): JSX.Element | null => {
  const [state, dispatch] = useConfigState()

  useEffect(() => {
    let canceled = false

    dispatch(loading())
    config
      .then((data) => {
        if (canceled) {
          return
        }
        dispatch(loadedConfig(data))
      })
      .catch((err) => {
        if (canceled) {
          return
        }
        dispatch(error(err))
      })

    return () => {
      canceled = true
    }
  }, [config, dispatch])

  const { status } = state
  if (status === LoadStatus.Loading) {
    return null
  }

  if (status === LoadStatus.Error) {
    const { err } = state
    return (
      <p>{`Encountered a problem loading the application configuration: ${err.message}`}</p>
    )
  }

  return <Context.Provider value={state.config}>{children}</Context.Provider>
}

export default Provider
