export const assessment1Info = {
  title: 'ReLACE Assessment',
  description:
    'In this survey, you will answer questions about your experiences at this company. This survey usually takes about 8 to 12 minutes. These questions help Lattitude get to know about your work at this company better to understand overall connection and satisfaction. Your individual answer details and specific responses will be kept confidential, and will not be visible to anyone else in your organization. Your organization will receive general statistics only, like company averages based on your responses.  This assessment will be available for you to update and take again after ~3 months.',
  completedHtml:
    '<h3>Your responses have been saved and submitted.  Thank you for your input.</h3>',
  // completedHtmlOnCondition: [],
}

export const questionGroup0 = [
  ``,
  `For the following statements, indicate how much you agree or disagree based on your experiences with others at this company.`,
  `part 1 of 2`,
  [
    [
      `trust1`,
      `I can count on people in this company to help me if I experience challenges with my job.`,
    ],
    [`trust2`, `I can rely on people in this company to keep their promises.`],
    [`trust3`, `I trust the people in this company.`],
  ],
]

export const questionGroup1 = [
  ``,
  `For the following statements, indicate how much you agree or disagree based on your experiences with others at this company.`,
  `part 2 of 2`,
  [
    [
      `socon1`,
      `I feel strongly socially connected to others within this company.`,
    ],
    [`socon2`, `Sometimes, I can feel isolated from others in the company.`],
    [`socon3`, `I feel united with others in the company.`],
  ],
]

export const questionGroup2 = function questionGroup4() {
  return {
    name: 'page3',
    // @todo: Angelo. Use the default props fn like others.
    description: '',
    showQuestionNumbers: 'off',
    showTitle: false,
    elements: [
      {
        type: 'imagepicker',
        colCount: 4,
        name: 'closeness1',
        title:
          'Choose the image below that best describes the level of closeness you feel with others in the company. (click to select)',
        isRequired: true,
        choices: [
          {
            value: '1',
            imageLink:
              'https://d35n0654afng3w.cloudfront.net/assessments-closeness-circle-1.png',
          },
          {
            value: '2',
            imageLink:
              'https://d35n0654afng3w.cloudfront.net/assessments-closeness-circle-2.png',
          },
          {
            value: '3',
            imageLink:
              'https://d35n0654afng3w.cloudfront.net/assessments-closeness-circle-3.png',
          },
          {
            value: '4',
            imageLink:
              'https://d35n0654afng3w.cloudfront.net/assessments-closeness-circle-4.png',
          },
          {
            value: '5',
            imageLink:
              'https://d35n0654afng3w.cloudfront.net/assessments-closeness-circle-5.png',
          },
          {
            value: '6',
            imageLink:
              'https://d35n0654afng3w.cloudfront.net/assessments-closeness-circle-6.png',
          },
          {
            value: '7',
            imageLink:
              'https://d35n0654afng3w.cloudfront.net/assessments-closeness-circle-7.png',
          },
        ],
      },
      {
        type: 'text',
        name: 'closeness2',
        title: 'How many people do you feel strongly connected to at work?',
        description: 'Please enter a number between 0 and 999.',
        isRequired: true,
        inputType: 'number',
        min: 0,
        max: 999,
        step: 1,
      },
    ],
  }
}

export const questionGroup4 = [
  ``,
  `For the following statements. Indicate how you feel from your own experience **connecting with others** at this company.`,
  ``,
  [
    [
      `selfconnect1`,
      `I am confident in my ability to connect with others in the company.`,
    ],
    [
      `selfconnect2`,
      `I feel comfortable setting up a meeting to connect with someone I don't know yet in the company.`,
    ],
    [
      `selfconnect3`,
      `I am able to tap into my network at this company for help and resources.`,
    ],
    [
      `selfconnect4`,
      `I find it difficult to find people who can help me grow at this company.`,
    ],
  ],
]

export const questionGroup5 = [
  ``,
  `For the following statements. Indicate how you feel from your own experience **helping to connect others** at this company.`,
  ``,
  [
    [
      `peerconnect1`,
      `I am confident in my ability to help others connect at this company.`,
    ],
    [
      `peerconnect2`,
      `I feel comfortable setting up a meeting to connect two others in the company who haven't met each other yet.`,
    ],
    [
      `peerconnect3`,
      `I am able to help others tap into their networks at this company for help and resources.`,
    ],
    [
      `peerconnect4`,
      `I find it difficult to connect others together for growth opportunities at this company.`,
    ],
  ],
]

export const questionGroup6 = [
  ``,
  `For the following questions, we are asking **specifically and only about connections that are for your professional benefit** (for example, mentorship, development, friendship at work). Please respond to the following thinking about connections that benefit you personally or professionally.`,
  `part 1 of 2`,
  [
    [
      `resourcecon1`,
      `I currently have all the resources I need to make connections with other coworkers.`,
    ],
    [
      `resourcecon2`,
      `Our company does enough to foster connections between coworkers.`,
    ],
    [
      `resourcecon3`,
      `Employees at this company have enough time to connect with coworkers.`,
    ],
  ],
]

export const questionGroup7 = [
  ``,
  `For the following questions, we are asking **specifically and only about connections that are for your professional benefit** (for example, mentorship, development, friendship at work). Please respond to the following thinking about connections that benefit you personally or professionally.`,
  `part 2 of 2`,
  [
    [
      `effortcon1`,
      `It takes a great deal of effort to make connections at this company.`,
    ],
    [
      `effortcon2`,
      `There are significant barriers to making or maintaining connections at this company.`,
    ],
    [
      `effortcon3`,
      `It is easy to connect with many coworkers at this company.`,
    ],
    [`connectsatis`, `I am completely satisfied with my connections at work.`],
  ],
]

export const questionGroup8 = [
  ``,
  `*Mentors* are people in your company who invest time and resources into your development as a professional. Importantly, mentorship should provide you with <u>*both* positive and constructive feedback.</u> A mentor helps develop <u>your skills.</u> Considering this definition, please respond to the items below.`,
  ``,
  [
    [`na_mentor1`, `All my mentorship needs are met.`],
    [
      `na_mentor2`,
      `I have grown substantially as a result of my mentorship relationships.`,
    ],
    [`na_mentor3`, `I want to receive more mentorship than I currently am.`],
    [
      `na_mentor4`,
      `The mentorship I receive at this company is very high quality.`,
    ],
  ],
]

export const questionGroup9 = [
  ``,
  `*Advocates* are people in your company who do what they can to support your advancement (for example, getting promotions). For example, advocates may connect you with new opportunities that stretch your skills, they may introduce you to people who can offer you growth opportunities, or they may recommend or refer you for advancement. An advocate helps you <u>advance in your career goals.</u> Considering this definition, please respond to the items below.`,
  ``,
  [
    [`na_advocacy1`, `All my advocacy needs are met.`],
    [
      `na_advocacy2`,
      `Advocacy relationships have helped me advance substantially toward my career goals.`,
    ],
    [
      `na_advocacy3`,
      `I want to receive more advocacy than I currently am to meet my near-term career goals.`,
    ],
    [
      `na_advocacy4`,
      `The advocacy I receive at this company is very high quality.`,
    ],
  ],
]

export const questionGroup10 = [
  ``,
  `*Advisors* are people in your company who have more knowledge than you in a particular area, who you can lean on to provide you with good advice when you have a question or concern. Advisors are able to speak from experience about issues and guide you in a direction, but do not make decisions for you. Advisors help you <u>make good decisions.</u> Considering this definition, please respond to the items below.`,
  ``,
  [
    [`na_advising1`, `All my advising needs are met.`],
    [
      `na_advising2`,
      `My advisor relationships have helped me substantially with difficult questions and/or decisions.`,
    ],
    [`na_advising3`, `I want to receive more advising than I currently am.`],
    [
      `na_advising4`,
      `The advising I receive at this company is very high quality.`,
    ],
  ],
]

export const questionGroup11 = [
  ``,
  `*Allies* are people in your company who provide trustworthy social support and friendship to you. They are people you can trust with concerns, they listen well when you need to vent, and they are able to help you feel better when a situation gets tough. They make you feel integrated and accepted. Allies <u>make work more enjoyable.</u> Considering this definition, please respond to the items below.`,
  ``,
  [
    [
      `na_ally1`,
      `All my needs are met in terms of allies and friendship at work.`,
    ],
    [
      `na_ally2`,
      `My allies and friends have helped me substantially in terms of enjoying my job day-to-day.`,
    ],
    [`na_ally3`, `I want more allies at work than I currently have.`],
    [
      `na_ally4`,
      `The allyship I receive at this company is very high quality.`,
    ],
  ],
]

export const questionGroup12 = [
  ``,
  `Indicate your level of agreement or disagreement with each of the following statements about your experience working at this company.`,
  `part 1 of 3`,
  [
    [`jobsatis1`, `Overall, I am satisfied with my job.`],
    [`jobsatis2`, `In general, I do not like my job.`],
    [`jobsatis3`, `All things considered, I like working at this company.`],
  ],
]

export const questionGroup13 = [
  ``,
  `Indicate your level of agreement or disagreement with each of the following statements about your experience working at this company.`,
  `part 2 of 3`,
  [
    [
      `coworkersatis1`,
      `I do not get enough support from the people I work with.`,
    ],
    [`coworkersatis2`, `I work with good people.`],
    [`coworkersatis3`, `It is enjoyable to work with the people here.`],
  ],
]

export const questionGroup14 = [
  ``,
  `Indicate your level of agreement or disagreement with each of the following statements about your experience working at this company.`,
  `part 3 of 3`,
  [
    [`stay1`, `I often think about quitting my current job at this company. `],
    [`stay2`, `I intend to search for a new job within the next year.`],
    [`stay3`, `Many aspects of this job make it attractive to stay here.`],
  ],
]

export const questionGroup15 = [
  ``,
  `How often have you done each of the following things **in the past two weeks?**`,
  `part 1 of 2`,
  [
    [
      `ocb1`,
      `I advised, coached, or mentored a coworker. (in the past two weeks)`,
    ],
    [
      `ocb2`,
      `I helped a coworker with a problem that was outside of my job duties. (in the past two weeks)`,
    ],
    [`ocb3`, `I helped a newer employee fit in. (in the past two weeks)`],
    [
      `ocb4`,
      `I listened to someone compassionately when they had a problem. (in the past two weeks)`,
    ],
  ],
]

export const questionGroup16 = [
  ``,
  `How often have you done each of the following things **in the past two weeks?**`,
  `part 2 of 2`,
  [
    [
      `ocb5`,
      `I suggested ways to do work better or more efficiently. (in the past two weeks)`,
    ],
    [
      `ocb6`,
      `I volunteered to take, and did take, on work from a coworker with too much on their plate. (in the past two weeks)`,
    ],
    [`ocb7`, `I connected with someone new. (in the past two weeks)`],
    [`ocb8`, `I helped others connect. (in the past two weeks)`],
  ],
]

export const questionGroup17 = function questionGroup14() {
  return {
    name: 'page14',
    // @todo: Angelo. Use the default props fn like others.
    description: '',
    showQuestionNumbers: 'off',
    showTitle: false,
    elements: [
      {
        type: 'comment',
        name: 'free',
        title:
          'What else would you like to share about  the connection, satisfaction, and organizational well-being at your company?',
        description: '*Character limit: 2000*',
        isRequired: true,
        maxLength: 2000,
        placeholder: 'It would be great if...',
      },
    ],
  }
}
