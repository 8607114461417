import React, { useCallback, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useFeatureFlag } from 'configcat-react'

import MeetNowModal from '../../components/Conversations/MeetNow/MeetNowModal'
import colors from '../../theme/theme2'
import { FeatureFlagDefaults, FeatureFlags } from '@/config/ConfigEnums'

type Props = {
  availabilityToken: string
  skill: { id: number; name: string }
  disabled?: boolean
  onScheduled: () => void
  className?: string
}

const MeetNow = ({
  availabilityToken,
  skill: { id: skillId, name: skillName },
  disabled = false,
  onScheduled,
  className,
}: Props): JSX.Element | null => {
  const { value: meetNowValue, loading: meetNowLoading } = useFeatureFlag(
    FeatureFlags.meetNow,
    FeatureFlagDefaults.meetNow,
  )
  const [open, setOpen] = useState(false)
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  if (meetNowLoading || !meetNowValue) {
    return null
  }

  return (
    <>
      <Dropdown.Item
        onClick={handleOpen}
        disabled={disabled}
        className={className}
      >
        <span style={{ color: colors.orange }}>Meet now</span>
      </Dropdown.Item>
      <MeetNowModal
        show={open}
        match={{
          token: availabilityToken,
          topics: [{ skillId, skillName }],
        }}
        onClose={handleClose}
        reloadUpcoming={() => {
          // // this is called on success; success does not automatically close the modal
          // onScheduled()
          handleClose()
        }}
      />
    </>
  )
}

export default MeetNow
