/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react'
import ConfirmationModal from '../Modals/ConfirmationModal'
import ActionButton from '../../elemets/ActionButton'
import { isMobile } from '../../utils/common'
import { createUseStyles } from 'react-jss'
import CustomInput from '../../elemets/CustomInput'
import CustomTextArea from '../../elemets/CustomTextArea'
import { useApiSubmit } from '../../api'
import { useCurrentUser } from '../Context/CurrentUser'
import compose from 'just-compose'
import { withErrorHandler } from '../../errors'
import colors, { headings } from '../../theme/theme2'
import Icon from '../Icons'

const useStyles = createUseStyles({
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  inputContainerStyle: {
    marginBottom: 10,
    '& label': {
      color: '#797575',
    },
  },
  text: {
    height: 90,
  },
  title: {
    ...headings.title,
    fontSize: 18,
    paddingRight: 10,
    marginBottom: 0,
  },
  icon: {
    '& i': {
      cursor: 'unset',
      '&:before': {
        backgroundColor: colors.primary,
        color: colors.white,
        padding: [1, 2.5],
        fontSize: 12,
      },
    },
  },
  label: {
    ...headings.title,
    fontSize: 12,
  },
  formInputs: {
    '& .form-group': {
      margin: 0,
    },
    '& input': {
      fontSize: 14,
      padding: [11, 17],
      '&::placeholder': {
        color: colors.grey2,
        opacity: 1,
      },
    },
    '& textarea': {
      fontSize: 14,
      padding: [6, 15],
      '&::placeholder': {
        color: colors.grey2,
        opacity: 1,
      },
    },
  },
})

const AddTopicModal = ({ show, setDisplayModal, handleError }) => {
  const classes = useStyles()
  const [formState, setFormState] = useState({
    newTopic: '',
    reason: '',
  })
  const { currentUser } = useCurrentUser()

  const {
    submitted,
    submit: suggestTopic,
    err: updateErr,
    submitting,
  } = useApiSubmit('suggestTopic')

  const handleInput = useCallback((e) => {
    const value = e.target.value
    const name = e.target.name
    setFormState((f) => ({
      ...f,
      [name]: value,
    }))
  }, [])

  useEffect(() => {
    if (submitted && !updateErr && !submitting) {
      setDisplayModal(false)
      setFormState({
        newTopic: '',
        reason: '',
      })
    } else if (updateErr && !submitting) {
      handleError(updateErr, true, {
        message: 'There was a problem adding the suggested topic.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, updateErr, submitting, setDisplayModal])

  const save = useCallback(() => {
    if (!currentUser?.id) return
    suggestTopic({
      body: {
        suggestorId: currentUser.id,
        ...formState,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, setDisplayModal])

  return (
    <ConfirmationModal
      show={show}
      onClose={() => setDisplayModal(false)}
      closeIcon={true}
      title={
        <div
          className={`${classes.icon} ${
            isMobile() ? 'd-flex justify-content-center' : ''
          }`}
        >
          <label className={`${classes.title}`}>Suggest New Topic</label>
          <Icon icon="fas fa-list" />
        </div>
      }
      content={
        <div className={`${classes.content} ${classes.formInputs}`}>
          <CustomInput
            containerStyle={classes.inputContainerStyle}
            name="newTopic"
            value={formState.newTopic}
            onChange={handleInput}
            placeholder="New Topic of Interest"
            error={formState.newTopic.length > 255}
            errorMessage="Topic max. lenght is 255 characters"
          />
          <CustomTextArea
            placeholder="Please share why this is a topic of interest for you..."
            name="reason"
            value={formState.reason}
            onChange={handleInput}
            className={classes.text}
            maxLength={300}
          />
        </div>
      }
      footer={
        <div className="d-flex justify-content-end">
          <ActionButton
            text="SUGGEST"
            className="w-40"
            onClick={save}
            type="STRONG_GREEN"
            disabled={
              formState.newTopic === '' ||
              formState.newTopic.length > 255 ||
              formState.reason.length > 300
            }
          />
        </div>
      }
      dialogClassName={isMobile() ? classes.dialogMobile : ''}
    />
  )
}

export default compose(withErrorHandler)(AddTopicModal)
