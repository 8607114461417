import React from 'react'
import { Row, Col } from 'react-bootstrap'
import compose from 'just-compose'
import { createUseStyles } from 'react-jss'

import { isMobile } from '../../utils/common'
import { withErrorHandler } from '../../errors'
import { withNotifications } from '../../notifications'
import PersonalCard from './PersonalCard'
import BioIceBreakerCard from './BioIceBreakerCard'
import ExperienceCard from './ExperienceCard'
import EducationCard from './EducationCard'
import TopicsOfInterest from './TopicsOfInterest'
import MyGroupsCard from './MyGroupsCard'
import IntegrationsCard from './IntegrationsCard'

const useStyles = createUseStyles({
  mobile: {
    '@media (min-width: 599px)': {
      display: 'none',
    },
    '@media (max-width: 600px)': {
      // display: 'flex',
    },
  },
  regular: {
    '@media (min-width: 599px)': {
      // display: 'flex',
    },
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
})

const PersonalForm = ({ user, type, reload }) => {
  const classes = useStyles()
  return (
    <>
      {!isMobile() ? (
        <div className={classes.regular} data-testid="profile-mode-desktop">
          <Row>
            <Col xs={12} md={5} lg={4} className="px-0 px-md-3">
              <PersonalCard user={user} type={type} reload={reload} />
              <IntegrationsCard />
              <MyGroupsCard user={user} />
            </Col>
            <Col xs={12} md={7} lg={8} className="px-0 px-md-3">
              <BioIceBreakerCard user={user} type={type} reload={reload} />
              <ExperienceCard user={user} reload={reload} />
              <EducationCard user={user} reload={reload} />
              <TopicsOfInterest user={user} reload={reload} />
            </Col>
          </Row>
        </div>
      ) : (
        <div className={classes.mobile} data-testid="profile-mode-mobile">
          <Row>
            <Col xs={12} md={5} lg={4} className="px-0">
              <PersonalCard user={user} type={type} reload={reload} />
              <IntegrationsCard />
              <BioIceBreakerCard user={user} type={type} reload={reload} />
              <ExperienceCard user={user} reload={reload} />
              <EducationCard user={user} reload={reload} />
              <MyGroupsCard user={user} />
              <TopicsOfInterest user={user} reload={reload} />
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default compose(withErrorHandler, withNotifications)(PersonalForm)
