import React, { useState } from 'react'
import CardContainer from '../../../elemets/CardContainer'
import { createUseStyles } from 'react-jss'
import colors, { headings } from '../../../theme/theme2'

const useStyles = createUseStyles({
  text: {
    ...headings.text,
    fontSize: 14,
    marginBottom: 0,
    color: '#f3f3f2',
    fontWeight: 'bold',
  },
  inactiveTab: {
    ...headings.text,
    paddingLeft: 10,
    marginBottom: 0,
    color: 'rgba(0,0,0,.5)',
    fontWeight: 'bold',
  },
  activeTab: {
    ...headings.text,
    paddingLeft: 10,
    marginBottom: 0,
    color: colors.primary,
    fontWeight: 'bold',
  },
  tabsTitle: {
    paddingRight: 10,
    cursor: 'pointer',
  },
})

const TabCardContainer = ({ tabs = [] }) => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)

  return (
    <CardContainer
      header={
        <div className={`${classes.text}`}>
          {tabs
            .filter(tab => tab !== null)
            .map((tab, index, array) => {
              return (
                <React.Fragment key={index}>
                  <span
                    onClick={() => setActiveTab(index)}
                    className={`${classes.tabsTitle} ${
                      index === activeTab
                        ? classes.activeTab
                        : classes.inactiveTab
                    } `}
                  >
                    {tab.title}
                  </span>
                  {index !== array.length - 1 && '|'}
                </React.Fragment>
              )
            })}
        </div>
      }
      headerDivider={true}
    >
      {tabs.length > 0 && tabs[activeTab].component}
    </CardContainer>
  )
}

export default TabCardContainer
