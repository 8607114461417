import React from 'react'
import { PartialConversation } from '../../builder/state'
import { useConversationDetailsBuilder } from '../../builder/ConversationBuilder'
import GoalField from './GoalField'

const selectGoal = (value: PartialConversation): string | undefined =>
  value.goal

const SetGoal = (): JSX.Element => {
  const { value, setGoal } = useConversationDetailsBuilder()
  const goal = selectGoal(value)
  return <GoalField value={goal} onChange={setGoal} />
}

export default SetGoal
