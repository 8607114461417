import React from 'react'
import BannerContainer, { BannerDescription } from './BannerContainer'
import ActionButton from '../../../elemets/ActionButton'
import compose from 'just-compose'
import { withRouter } from 'react-router'
import { createUseStyles } from 'react-jss'
import { actionButtonStyles } from '../../../theme/theme2'

const useStyles = createUseStyles({
  button: {
    '& button': {
      color: '#4ea7ec',
      borderColor: '#4ea7ec',
      '&:focus': {
        color: '#4ea7ec!important',
        border: `1px solid #4ea7ec!important`,
      },
      '&:hover': {
        backgroundColor: actionButtonStyles.secondary.hover,
        color: '#4ea7ec!important',
        border: `1px solid #4ea7ec!important`,
      },
      '&:active': {
        backgroundColor: `#ffffff50!important`,
        color: 'white!important',
        border: `1px solid #4ea7ec!important`,
      },
    },
  },
})

const IntegrateCalendarBanner = ({ onClose, history }) => {
  const classes = useStyles()

  const header = (
    <BannerDescription
      icon={'fas fa-calendar-alt'}
      title={'INTEGRATE YOUR CALENDAR'}
    />
  )
  const description = (
    <p className={`m-0`}>
      Don’t miss a thing, add your Google or Microsoft Calendar to Lattitude to
      make scheduling your next Lattitude conversation easier.
    </p>
  )

  const buttons = (
    <div className={classes.button}>
      <ActionButton
        height="26px"
        text={'ADD CALENDAR'}
        onClick={() => {
          history.push('/profile')
        }}
        type="LIGHT_GREEN"
        className="text-nowrap"
      />
    </div>
  )

  return (
    <BannerContainer
      header={header}
      description={description}
      buttons={buttons}
      type={'BLUE'}
      onClose={onClose}
    />
  )
}

export default compose(withRouter)(IntegrateCalendarBanner)
