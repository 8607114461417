import React, { useEffect, useCallback, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import compose from 'just-compose'
import { createUseStyles } from 'react-jss'

// Components
import CardContainer from '../../elemets/CardContainer'
import ActionButton from '../../elemets/ActionButton'
import CustomSelect from '../../elemets/CustomSelect'
import AddAvailabilityModal from './AddAvailabilityModal'
import { conversationOptions } from '../Profile/TopicsOfInterest'

// Errors
import { withErrorHandler } from '../../errors'

// API
import { useApiSubmit } from '../../api/hooks'

// Theme
import { headings } from '../../theme/theme2'

const useStyles = createUseStyles({
  selector: {
    minWidth: 65,
  },
  text: {
    ...headings.paragraph,
  },
})
const AddAvailabilityCard = React.memo(
  ({ reload, reloadEvents, handleError, user }) => {
    const classes = useStyles()
    const [maxConversations, setMaxConversations] = useState()
    const [displayModal, setDisplayModal] = useState(false)
    const {
      submitted,
      submit: updateProfile,
      err: updateErr,
      submitting,
    } = useApiSubmit('updateProfileV1')

    useEffect(() => {
      if (user) {
        setMaxConversations(
          conversationOptions.find(
            (item) => item.value === user.maxConversations,
          ),
        )
      }
    }, [user])

    useEffect(() => {
      if (submitted && !updateErr && !submitting) {
        reload()
      } else if (updateErr && !submitting) {
        handleError(updateErr, true, {
          message: 'There was a problem updating your profile.',
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitted, updateErr, submitting])

    const save = useCallback(
      (maxConversations) => {
        try {
          updateProfile({
            params: {
              profileId: user.id,
            },
            body: {
              firstName: user.firstName,
              lastName: user.lastName,
              conversationFrequencyId: user.conversationFrequencyId,
              notificationFrequencyId: user.notificationFrequencyId,
              maxConversations: Number(maxConversations),
              email: user.email,
              regionId: user.region,
              showTour: user.showTour,
              bio: user.bio,
              funFact: user.funFact,
              homeTown: user.homeTown,
              highSchool: user.highSchool,
              linkedIn: user.linkedIn,
              location: user.location,
              phoneNumber: user.phoneNumber,
            },
          })
        } catch (err) {
          const { handleError } = this.props
          handleError(err, true, {
            message: 'There was a problem updating your profile.',
          })
        }
      },
      [updateProfile, user],
    )
    const handleSelectMax = useCallback(
      (key, value) => {
        setMaxConversations(value)
        save(value.value)
      },
      [save],
    )

    return (
      <>
        <AddAvailabilityModal
          show={displayModal}
          onClose={() => setDisplayModal(false)}
          reload={reloadEvents}
        />
        <CardContainer>
          <ActionButton
            text="ADD AVAILABILITY"
            onClick={() => setDisplayModal(true)}
            type="LIGHT_GREEN"
          />
          <Col xs={12}>
            <Row className="mt-3">
              <label className={classes.text}>
                Max Scheduled Conversations
              </label>
              <CustomSelect
                options={conversationOptions}
                onSelect={handleSelectMax}
                value={maxConversations}
                border={false}
                className={classes.selector}
              />
            </Row>
          </Col>
        </CardContainer>
      </>
    )
  },
)

export default compose(withErrorHandler)(AddAvailabilityCard)
