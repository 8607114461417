import React from 'react'
import { ButtonGroup, Dropdown } from 'react-bootstrap'
import ManuallyAddConversation from './ManuallyLogConversation'
import RequestAvailability from './RequestAvailability'
import ScheduleAgain from './ScheduleAgain'
import ViewConversationDetails from './ViewConversationDetails'
import useActionsStyles from './actionsStyles'
import MeetNow from './MeetNow'

type PastConversationActionsProps = {
  conversationId: number
  participantId: number
  groupId: number | undefined
  availabilityToken: string
  skill: { id: number; name: string }
  onScheduled: () => void
}

const PastConversationActions = ({
  conversationId,
  participantId,
  groupId,
  availabilityToken,
  skill,
  onScheduled,
}: PastConversationActionsProps): JSX.Element => {
  const classes = useActionsStyles()
  return (
    <Dropdown as={ButtonGroup}>
      <ViewConversationDetails
        conversationId={conversationId}
        className={classes.button}
      />
      <Dropdown.Toggle split className={classes.toggle} />
      <Dropdown.Menu>
        <ManuallyAddConversation
          participantId={participantId}
          groupId={groupId}
          skillId={skill.id}
          disabled={!groupId}
          className={classes.menuItem}
        />
        <RequestAvailability
          availabilityToken={availabilityToken}
          skill={skill}
        />
        <ScheduleAgain
          availabilityToken={availabilityToken}
          skill={skill}
          onScheduled={onScheduled}
        />
        <MeetNow
          availabilityToken={availabilityToken}
          skill={skill}
          onScheduled={onScheduled}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default PastConversationActions
