import React, { useContext, useEffect, useState, useRef } from 'react'
import { useIsMounted } from 'usehooks-ts'

import { useApiFetch } from '../../api'
import { useAuth0 } from '../../auth0'
import { useLocation } from 'react-router-dom'
import { useCurrentUser } from './CurrentUser'
import LoaderPage from '../../elemets/LoaderPage'

const GroupMemberContext = React.createContext({
  groupMembers: [],
  setGroups: () => {},
  loading: false,
})

export const useGroupMembers = () => useContext(GroupMemberContext)

const GroupMembers = ({ children }) => {
  const isMounted = useIsMounted()
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  const location = useLocation()
  const [groups, setGroups] = useState([])
  const [allMembers, setAllMembers] = useState([])
  const [filterMembers, setFilterMembers] = useState([])
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({
    subtopics: null,
    ratings: null,
    isMentor: null,
    fullName: null,
  })
  const [sortField, setSortField] = useState({
    field: 'name',
    asc: true,
  })
  const hasMoreItems = useRef(false)
  const { currentUser, loading: isLoadingCurrentUser } = useCurrentUser()
  const {
    data: groupMembers,
    loading: loadingGroupMembers,
    err,
    fetch: fetchGroupMembers,
  } = useApiFetch('listProfiles', {}, false)
  const {
    data: groupFilterMembersData,
    fetch: fetchFilterMembers,
    loading: loadingFilterMembers,
  } = useApiFetch('listFilterProfiles', {}, false)

  useEffect(() => {
    if (!isMounted()) return
    if (!isAuthenticated) {
      loginWithRedirect({ appState: { targetUrl: location.pathname } })
    }
  }, [isMounted, isAuthenticated, loginWithRedirect, location])

  useEffect(() => {
    if (!isMounted()) return
    if (currentUser?.id && groups.length !== 0) {
      fetchGroupMembers({
        body: { user_id: currentUser.id, groups, filters, sortField },
        query: { count: 25, page },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, groups, page, filters, currentUser, sortField])

  useEffect(() => {
    if (!isMounted()) return
    if (currentUser?.id && groups.length !== 0) {
      fetchFilterMembers({
        body: { user_id: currentUser.id, groups },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups, currentUser])

  useEffect(() => {
    if (!isMounted()) return
    if (groupMembers?.length > 0) {
      setAllMembers((current) => {
        const existing = current?.map(({ profileId }) => profileId) ?? []
        const delta =
          groupMembers?.filter(
            ({ profileId }) => !existing.includes(profileId),
          ) ?? []
        if (delta.length < 1) {
          return current
        }
        return [...current, ...delta]
      })
    }
  }, [isMounted, groupMembers])

  useEffect(() => {
    if (!isMounted()) return
    if (groupFilterMembersData) {
      setFilterMembers(groupFilterMembersData)
    }
  }, [isMounted, groupFilterMembersData])

  useEffect(() => {
    if (!isMounted()) return
    if (
      allMembers.length > 0 &&
      allMembers.length === allMembers[0].fullCount
    ) {
      hasMoreItems.current = false
    } else if (
      allMembers.length > 0 &&
      allMembers.length < allMembers[0].fullCount
    ) {
      hasMoreItems.current = true
    }
  }, [isMounted, allMembers])

  return currentUser?.id ? (
    <GroupMemberContext.Provider
      value={{
        groups: groups,
        groupMembers: allMembers || [],
        filterMembers,
        loading: loadingGroupMembers || isLoadingCurrentUser,
        error: err,
        setGroups: (groups) => {
          hasMoreItems.current = false
          setPage(1)
          setAllMembers([])
          setGroups(groups)
        },
        setPage: () => {
          if (!loadingGroupMembers && hasMoreItems.current) {
            hasMoreItems.current = false
            setPage((s) => s + 1)
          }
        },
        hasMoreItems,
        setFilters: (filters) => {
          hasMoreItems.current = false
          setPage(1)
          setAllMembers([])
          setFilters(filters)
        },
        setSortField: (field) => {
          hasMoreItems.current = false
          setPage(1)
          setAllMembers([])
          setSortField((f) => ({
            field,
            asc: f.field === field ? !f.asc : true,
          }))
        },
      }}
    >
      {children}
    </GroupMemberContext.Provider>
  ) : (
    <LoaderPage />
  )
}

export default GroupMembers
