import React, { ReactNode } from 'react'
import { Relationship } from './Relationship'
import RelationshipsProvider from './RelationshipsProvider'

const relationships: Relationship[] = [
  {
    key: 'Mentor',
    label: 'Mentor',
  },
  {
    key: 'Mentee',
    label: 'Mentee',
  },
  {
    key: 'Manager',
    label: 'Boss/Manager',
  },
  {
    key: 'Sponsor',
    label: 'Sponsor',
  },
  {
    key: 'Champion',
    label: 'Champion',
  },
  {
    key: 'AccountabilityPartner',
    label: 'Accountability Partner',
  },
  {
    key: 'Ally',
    label: 'Ally',
  },
  {
    key: 'Peer',
    label: 'Peer/Buddy',
  },
  {
    key: 'HR',
    label: 'HR',
  },
  {
    key: 'LifeCoach',
    label: 'Life Coach',
  },
  {
    key: 'Therapist',
    label: 'Therapist',
  },
  {
    key: 'FitnessCoach',
    label: 'Fitness Coach',
  },
  {
    key: 'Successor',
    label: 'Successor',
  },
]

type AvailableRelationshipsProps = {
  children: ReactNode
}

const AvailableRelationships = ({
  children,
}: AvailableRelationshipsProps): JSX.Element => (
  <RelationshipsProvider relationships={relationships} ready>
    {children}
  </RelationshipsProvider>
)

export default AvailableRelationships
