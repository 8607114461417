import React, { Fragment, useState, useRef } from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import TimePicker from 'react-bootstrap-time-picker'
import moment from 'moment'
import { Typeahead } from 'react-bootstrap-typeahead' // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css'

const maxWidth = 300

class Typeaheads extends React.Component {
  render() {
    return (
      <>
        <Typeahead {...this.props} />
      </>
    )
  }
}

class Date extends React.Component {
  constructor(props) {
    super(props)
    this.formatDate = this.formatDate.bind(this)
  }

  formatDate() {
    const date = moment(this.props.value)

    if (date.isValid() === true) {
      this.setState({
        value: date,
      })
    }
  }

  render() {
    return (
      <Form.Group controlId="formFirstName">
        <Form.Label>{this.props.label}</Form.Label>
        <br />
        <DatePicker {...this.props} dateFormat="MM/dd/yyyy" width={300} />
      </Form.Group>
    )
  }
}

class Label extends React.Component {
  render() {
    return (
      <Form.Group>
        <Form.Label>{this.props.label}</Form.Label>
        {this.props.alignment === 'left' ? ': ' : <br />}
        <Form.Label>{this.props.value}</Form.Label>
      </Form.Group>
    )
  }
}

class Email extends React.Component {
  render() {
    return (
      <Form.Group controlId="formFirstName">
        <Form.Label>{this.props.label}</Form.Label>
        <Form.Control
          type="email"
          sm="10"
          {...this.props}
          style={{ maxWidth: maxWidth }}
        />
      </Form.Group>
    )
  }
}

const Input = props => {
  const { label = '', toolTip } = props
  function renderTooltip(text) {
    return <Tooltip>{text}</Tooltip>
  }

  const [show, setShow] = useState(true)
  const target = useRef(null)

  return (
    <Form.Group controlId="formFirstName">
      {label && <Form.Label>{label}</Form.Label>}
      {toolTip && (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 250 }}
          overlay={renderTooltip(props.toolTip)}
        >
          <i
            ref={target}
            onClick={() => setShow(!show)}
            className={`fas fa-info-circle fa-lg info`}
            style={{ fontSize: 12 }}
          ></i>
        </OverlayTrigger>
      )}
      <Form.Control
        type="text"
        sm="10"
        lg="6"
        {...props}
        style={{ maxWidth: maxWidth }}
      />
    </Form.Group>
  )
}

class TextArea extends React.Component {
  render() {
    return (
      <Form.Group style={{ maxWidth: 600 }}>
        {this.props.label && <Form.Label>{this.props.label}</Form.Label>}
        <Form.Control as="textarea" sm="10" rows={3} {...this.props} required />
      </Form.Group>
    )
  }
}

class Time extends React.Component {
  render() {
    return (
      <Form.Group controlId="formBasicEmail">
        <Form.Label>{this.props.label}</Form.Label>
        <TimePicker
          start="10:00"
          end="21:00"
          step={30}
          {...this.props}
          style={{ maxWidth: maxWidth }}
        />
      </Form.Group>
    )
  }
}

class RadioList extends React.Component {
  render() {
    return (
      <Form.Group>
        <Form.Label>{this.props.label}</Form.Label>
        <br />
        {this.props.options.map(option => {
          return (
            <Fragment key={option.name}>
              <Form.Check
                inline
                label={
                  option[this.props.textField] !== null
                    ? option[this.props.textField]
                    : option.name
                }
                type="radio"
                id="inline-1"
                name={this.props.name}
              />
              <br />
            </Fragment>
          )
        })}
      </Form.Group>
    )
  }
}

class CheckboxList extends React.Component {
  render() {
    return (
      <Form.Group>
        <Form.Label>{this.props.label}</Form.Label>
        <br />
        {this.props.options.map(option => {
          return (
            <Fragment key={option.name}>
              <Form.Check
                inline
                label={
                  option[this.props.textField] !== null
                    ? option[this.props.textField]
                    : option.name
                }
                value={option.id}
                onChange={this.props.onChange}
                id="inline-1"
                name={this.props.name}
              />
              <br />
            </Fragment>
          )
        })}
      </Form.Group>
    )
  }
}

export {
  Date,
  Input,
  Email,
  TextArea,
  Time,
  RadioList,
  CheckboxList,
  Label,
  Typeaheads,
}
