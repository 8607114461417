import React, { ReactNode, createContext, useContext } from 'react'
import { Duration } from './Duration'

type ContextValue = {
  durations: Duration[]
  ready: boolean
}

export const Context = createContext<ContextValue>({
  durations: [],
  ready: false,
})

type DurationsProviderProps = {
  durations: Duration[]
  ready?: boolean
  children: ReactNode
}

const DurationsProvider = ({
  durations,
  ready = false,
  children,
}: DurationsProviderProps): JSX.Element => (
  <Context.Provider value={{ durations, ready }}>{children}</Context.Provider>
)

export default DurationsProvider

export const useDurations = (): ContextValue => useContext(Context)
