import React, { useMemo } from 'react'
import { Button } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import colors, { buttonStyles } from '../theme/theme2'

const useStyles = createUseStyles({
  button: {
    ...buttonStyles.roundButton,
    borderRadius: 10000,
    '&:disabled': {
      backgroundColor: 'transparent',
      border: 'none',
      color: '#d8d8d8',
    },
    '&:disabled:hover': {
      backgroundColor: 'transparent',
      border: 'none',
      color: '#d8d8d8',
    },
  },
  red: {
    color: colors.red,
    '&:hover': {
      color: colors.red700,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&:active': {
      color: colors.red100,
      backgroundColor: 'transparent !important',
    },
  },
  green: {
    color: colors.primary,
    '&:hover': {
      color: colors.primary700,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&:active, &:focus': {
      // boxShadow: '0 3px 9px 0 #9e9e9e !important',
      color: `${colors.greenDark} !important`,
    },
  },
  black: {
    color: colors.dark,
    '&:focus': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      color: colors.dark,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&:active': {
      color: `${colors.dark}`,
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
})

const RoundButton = ({
  icon,
  className,
  type = 'GREEN',
  onClick,
  disabled,
}) => {
  const classes = useStyles()

  const buttonColor = useMemo(() => {
    switch (type) {
      case 'RED':
        return classes.red
      case 'GREEN':
        return classes.green
      case 'BLACK':
        return classes.black
      default:
        break
    }
  }, [classes, type])

  return (
    <Button
      className={`${classes.button} ${buttonColor} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <i className={icon}></i>
    </Button>
  )
}

export default RoundButton
