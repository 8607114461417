import { createLikertMatrix, defaultTopLevelProps } from '../assessment-utils'
import {
  assessment1Info,
  questionGroup0,
  questionGroup1,
  questionGroup2,
  questionGroup4,
  questionGroup5,
  questionGroup6,
  questionGroup7,
  questionGroup8,
  questionGroup9,
  questionGroup10,
  questionGroup11,
  questionGroup12,
  questionGroup13,
  questionGroup14,
  questionGroup15,
  questionGroup16,
  questionGroup17,
} from './AssessmentSchema.relace.questions'

export const surveyJsonRelace = {
  ...defaultTopLevelProps,
  ...assessment1Info,
  pages: [
    createLikertMatrix('page1', questionGroup0, '7p-SD-SA'),
    createLikertMatrix('page2', questionGroup1, '7p-SD-SA'),
    questionGroup2(),
    createLikertMatrix('page4', questionGroup4, '7p-SD-SA'),
    createLikertMatrix('page5', questionGroup5, '7p-SD-SA'),
    createLikertMatrix('page6', questionGroup6, '7p-SD-SA'),
    createLikertMatrix('page7', questionGroup7, '7p-SD-SA'),
    createLikertMatrix('page8', questionGroup8, '7p-SD-SA'),
    createLikertMatrix('page9', questionGroup9, '7p-SD-SA'),
    createLikertMatrix('page10', questionGroup10, '7p-SD-SA'),
    createLikertMatrix('page11', questionGroup11, '7p-SD-SA'),
    createLikertMatrix('page12', questionGroup12, '7p-SD-SA'),
    createLikertMatrix('page13', questionGroup13, '7p-SD-SA'),
    createLikertMatrix('page14', questionGroup14, '7p-SD-SA'),
    createLikertMatrix('page15', questionGroup15, '6-NA-FM'),
    createLikertMatrix('page16', questionGroup16, '6-NA-FM'),
    questionGroup17(),
  ],
}
