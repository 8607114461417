import React, { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import clsx from 'clsx'
import colors from '../theme/theme2'

type CardTitleProps = {
  title: string | ReactNode
  className?: string
  contextMenu?: ReactNode
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 0,
  },
  title: {
    fontFamily: 'Lato',
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.dark,
    margin: 0,
    flex: 1,
  },
  contextMenu: {},
})

const CardTitle = ({
  title,
  className,
  contextMenu,
}: CardTitleProps): JSX.Element => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <label className={clsx(classes.title, className)}>{title}</label>
      {contextMenu ? (
        <div className={classes.contextMenu}>{contextMenu}</div>
      ) : null}
    </div>
  )
}

export default CardTitle
