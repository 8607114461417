import React from 'react'
import { Container } from 'react-bootstrap'

class Unauthorized extends React.Component {
  render() {
    return (
      <Container>
        <h1>Unauthorized</h1>
        <p>You are not authorized to perform this action.</p>
      </Container>
    )
  }
}

export default Unauthorized
