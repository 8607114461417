import React from 'react'
import { Table, Row, Col } from 'react-bootstrap'
import UnlinkAccountButton from './Buttons/UnlinkAccountButton'
import LinkAccountButton from './Buttons/LinkAccountButton'
import { withErrorHandler } from '../../errors'
import lattusApiClient from '../../lattusApi'

const providers = [
  {
    name: 'Google',
    connection: 'google-oauth2',
    provider: 'google',
  },
]

const style = {
  tableHeader: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}

const { getIdentities } = lattusApiClient

class Dashboard extends React.Component {
  constructor() {
    super()
    this.state = {
      identities: [],
    }
  }

  componentDidMount() {
    getIdentities()
      .then(result => {
        this.setState({ identities: result })
      })
      .catch(err => {
        const { handleError } = this.props
        handleError(err, true, {
          message: 'There was a problem loading your identities.',
        })
      })
  }

  render() {
    const { identities = [] } = this.state

    return (
      <div>
        <h3>Accounts</h3>
        <Table>
          <Row style={style.tableHeader}>
            <Col>Provider</Col>
            <Col>Email</Col>
            <Col>Action</Col>
          </Row>
          {providers.map(x => {
            const identity = identities.find(y => y.provider === x.connection)
            const profileData = identity ? identity.profileData : {}
            const { email = '' } = profileData

            return (
              <Row>
                <Col>{x.name}</Col>
                <Col>{email}</Col>
                <Col>
                  {!identity && <LinkAccountButton provider={x.connection} />}
                  {identity && (
                    <UnlinkAccountButton
                      provider={x.connection}
                      secondaryUserId={identity.user_id}
                    />
                  )}
                </Col>
              </Row>
            )
          })}
        </Table>
      </div>
    )
  }
}

export default withErrorHandler(Dashboard)
