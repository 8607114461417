import React, { useState, useEffect, ReactNode } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const detectRTC = () =>
  [
    'RTCPeerConnection',
    'webkitRTCPeerConnection',
    'mozRTCPeerConnection',
    'RTCIceGatherer',
  ].some((mechanism) => mechanism in window)

const availableDevices = async () => {
  if (
    !navigator.mediaDevices ||
    typeof navigator.mediaDevices.enumerateDevices !== 'function'
  ) {
    return []
  }
  const devices = await navigator.mediaDevices.enumerateDevices()
  return devices
}

// TODO improve type definition
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasMicrophone = ({ kind }: Record<string, any>) => kind === 'audioinput'

type WebRTCProps = {
  children: ReactNode
}

const WebRTC = ({ children }: WebRTCProps): JSX.Element | null => {
  // TODO convert stateful values to use useReducer
  const [showUnsupported, setShowUnsupported] = useState(false)
  const [supported, setSupported] = useState<boolean | null>(null)
  const [hasMic, setHasMic] = useState<boolean | null>(null)
  const [err, setErr] = useState<Error | null>(null)

  useEffect(() => {
    setSupported(detectRTC())
  }, [])

  useEffect(() => {
    if (!supported) {
      return
    }

    let cancelled = false
    const checkMic = async () => {
      try {
        const devices = await availableDevices()
        if (cancelled) {
          return
        }
        setHasMic(devices.some(hasMicrophone))
      } catch (err) {
        if (cancelled || !(err instanceof Error)) {
          return
        }
        setErr(err)
      }
    }

    checkMic()

    return () => {
      cancelled = true
    }
  }, [supported])

  useEffect(() => {
    setShowUnsupported(!!err || !supported || !hasMic)
  }, [err, supported, hasMic])

  if (supported === null || hasMic === null) {
    // loading
    return null
  }

  const supportRTC = !err && supported && hasMic
  return (
    <>
      <Modal show={showUnsupported} onHide={() => setShowUnsupported(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Browser not supported</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            We're unable to connect you to the other party, because your browser
            is not supported.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowUnsupported(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {supportRTC && children}
    </>
  )
}

export default WebRTC
