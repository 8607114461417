import React, { useCallback, useEffect, useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import compose from 'just-compose'

import { withErrorHandler } from '../../../errors'

// Elements
import CardContainer from '../../../elemets/CardContainer'
import CardTitle from '../../../elemets/CardTitle'
import CustomCheckbox from '../../../elemets/CustomCheckbox'
import Divider from '../../../elemets/Divider'

// Theme
import colors, { headings, buttonStyles } from '../../../theme/theme2'
import CustomTextArea from '../../../elemets/CustomTextArea'

// API
import { useApiSubmit } from '../../../api'

// Utils
import Icon from '../../Icons'

const useStyles = createUseStyles({
  text: {
    ...headings.text,
    fontSize: 14,
    paddingLeft: 16,
    marginBottom: 0,
    fontWeight: 'bold',
    color: '#343434 !important',
  },
  roundButton: {
    ...buttonStyles.roundButton,
  },
  greyText: {
    ...headings.text,
    fontSize: 14,
    paddingLeft: 16,
    marginBottom: 0,
    opacity: 0.4,
    fontWeight: 'bold',
  },
  sharedText: {
    ...headings.text,
    fontSize: 14,
    color: '#8a8a8a',
  },
  checkboxCheck: {
    backgroundColor: `${colors.primary} !important`,
  },
  checkboxUncheck: {
    backgroundColor: '#e8ecef !important',
  },
  checkboxIcon: {
    color: '#ffffff !important',
  },
  initials: {
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.primary,
  },
  divider: {
    backgroundColor: '#e8ecef',
  },
})

const ActionRow = ({
  conversationId,
  action,
  divider,
  reload,
  currentProfile,
  handleError,
}) => {
  const classes = useStyles()

  const {
    submitted: updated,
    submit: updateConversationItem,
    submitting: updateLoading,
    err: updateErr,
  } = useApiSubmit('updateConversationItem')

  // Updated
  useEffect(() => {
    if (updated && !updateErr && !updateLoading) {
      reload()
    } else if (updateErr && !updateLoading) {
      handleError(updateErr, true, {
        message: 'There was a problem updating the action.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated, updateErr, updateLoading])

  const checkboxBackground = useMemo(() => {
    return action.completed ? classes.checkboxCheck : classes.checkboxUncheck
  }, [action, classes])

  const handleChangeCheck = useCallback(
    () => {
      updateConversationItem({
        params: { conversationId, itemId: action.itemId },
        body: {
          text: action.text,
          type: 'agenda',
          userId: action.user.id,
          completed: !action.completed,
          updatedBy: action.user.id,
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [action, conversationId, updateConversationItem],
  )

  return (
    <>
      <Row className="">
        <Col>
          <div className="d-flex align-items-center">
            <CustomCheckbox
              checked={action.completed}
              backgroundColor={checkboxBackground}
              iconColor={classes.checkboxIcon}
              onClick={() => {
                if (currentProfile.id === action.user.id) {
                  handleChangeCheck(action)
                }
              }}
            />
            <CustomTextArea
              editMode={false}
              value={action.text}
              className={`ml-2 ${
                action.completed ? classes.greyText : classes.text
              }`}
            />
          </div>
        </Col>
        <Col xs={1} className="d-flex justify-content-end">
          <span className={`${classes.initials} pt-2`}>
            {action.user.firstName[0]}
            {action.user.lastName[0]}
          </span>
        </Col>
      </Row>
      {divider && <Divider className={classes.divider} />}
    </>
  )
}

const AgendaItemsInCallCard = ({
  agendas,
  reload,
  conversationId,
  currentProfile,
  show = false,
  handleError,
  loading,
}) => {
  const classes = useStyles()

  const header = (
    <div className="d-flex justify-content-between">
      <CardTitle title="Agenda Items" />
      <div className={classes.sharedText}>
        <Icon icon="fas fa-sync-alt" />
        <span className={'pl-2'}>Shared</span>
      </div>
    </div>
  )

  const compare = (a, b) => {
    if (a.itemId < b.itemId) {
      return -1
    }
    if (a.itemId > b.itemId) {
      return 1
    }
    return 0
  }

  return agendas && show && !loading ? (
    <>
      <CardContainer header={header} headerDivider={agendas.length > 0}>
        {agendas.sort(compare).map((action, index) => (
          <ActionRow
            key={action.itemId}
            action={action}
            conversationId={conversationId}
            divider={index < agendas.length - 1}
            currentProfile={currentProfile}
            reload={reload}
            handleError={handleError}
          />
        ))}
      </CardContainer>
    </>
  ) : null
}

export default compose(withErrorHandler)(AgendaItemsInCallCard)
