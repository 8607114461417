import React from 'react'
import { createUseStyles } from 'react-jss'
import Avatar from './Avatar'

type UserProps = {
  firstName: string
  lastName: string
  avatar: string
  role: string
  topic: string
}

const useStyles = createUseStyles<string>(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
  },
  avatar: {
    marginRight: '16px',
    width: '96px',
  },
  username: {
    fontSize: '1.4rem',
    marginBottom: 0,
    paddingBottom: 0,
  },
  details: {
    fontSize: '1rem',
  },
}))

const User = ({
  firstName,
  lastName,
  avatar,
  role,
  topic,
}: UserProps): JSX.Element => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.avatar}>
        <Avatar src={avatar} />
      </div>
      <div>
        <p className={classes.username}>{`${firstName} ${lastName}`}</p>
        <p className={classes.details}>
          {[role, topic].filter(Boolean).join(', ')}
        </p>
      </div>
    </div>
  )
}

export default User
