import React from 'react'
import Field from '../Field'
import { TopicId } from './data/Topic'
import TopicsSelector from './TopicsSelector'

type TopicsFieldProps = {
  value?: TopicId[]
  onChange: (value: TopicId[]) => void
  onClear: () => void
}

const TopicsField = ({
  value,
  onChange,
  onClear,
}: TopicsFieldProps): JSX.Element => (
  <Field label="Topics" labelId="topics" multiLine required>
    <TopicsSelector value={value} onChange={onChange} onClear={onClear} />
  </Field>
)

export default TopicsField
