import React, { useCallback, useEffect, useState } from 'react'
import ConfirmationModal from '../../../Modals/ConfirmationModal'
import ActionButton from '../../../../elemets/ActionButton'
import { isMobile } from '../../../../utils/common'
import { createUseStyles } from 'react-jss'
import { useApiSubmit } from '../../../../api'
import compose from 'just-compose'
import { withErrorHandler } from '../../../../errors'
import { headings } from '../../../../theme/theme2'
import CustomCheckbox from '../../../../elemets/CustomCheckbox'

const useStyles = createUseStyles({
  dialogMobile: {
    margin: 0,
    '& .modal-content': {
      borderRadius: 0,
    },
  },
  name: {
    ...headings.title,
    marginTop: 10,
  },
  description: {
    ...headings.text,
    fontSize: 14,
    marginLeft: 10,
  },
})

const RemoveUserModal = ({
  user,
  setDisplayModal,
  group,
  reload,
  handleError,
}) => {
  const classes = useStyles()
  const [deleteAccount, setDeleteAccount] = useState(false)

  const {
    submitted,
    submit: removeProfile,
    err: updateErr,
    submitting,
  } = useApiSubmit('removeGroupMember')

  useEffect(() => {
    if (submitted && !updateErr && !submitting) {
      setDisplayModal(false)
      reload()
    } else if (updateErr && !submitting) {
      handleError(updateErr, true, {
        message: 'There was a problem removing this profile.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, updateErr, submitting])

  const save = useCallback(() => {
    removeProfile({
      params: {
        groupId: group.id,
        userId: user.profileId,
      },
    })
  }, [removeProfile, user, group])

  return (
    <ConfirmationModal
      show={true}
      onClose={() => setDisplayModal(false)}
      closeIcon={true}
      title={'Account Settings'}
      content={
        <>
          <div>
            <p className={`mt-0 mb-0 ${classes.name}`}>
              {user.firstName} {user.lastName}
            </p>
          </div>
          <div className="pt-4 pl-3 d-flex align-items-start">
            <CustomCheckbox
              checked={deleteAccount}
              backgroundColor={deleteAccount ? classes.checkboxCheck : ''}
              iconColor={classes.icon}
              onClick={() => {
                setDeleteAccount(!deleteAccount)
              }}
            />
            <label className={classes.description}>
              Yes, I want to permanently delete this user
            </label>
          </div>
        </>
      }
      footer={
        <div className="d-flex justify-content-end">
          <ActionButton
            text="DELETE USER"
            className="w-40"
            onClick={save}
            type="STRONG_RED"
            disabled={!deleteAccount}
          />
        </div>
      }
      dialogClassName={isMobile() ? classes.dialogMobile : ''}
    />
  )
}

export default compose(withErrorHandler)(RemoveUserModal)
