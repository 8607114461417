import React, { ReactNode } from 'react'
import { Method } from './Method'
import MethodsProvider from './MethodsProvider'

const methods: Method[] = [
  {
    key: 'PhoneCall',
    label: 'Phone call',
  },
  {
    key: 'InPerson',
    label: 'In person',
  },
  {
    key: 'Slack',
    label: 'Slack',
  },
  {
    key: 'Teams',
    label: 'Teams',
  },
  {
    key: 'Zoom',
    label: 'Zoom',
  },
  {
    key: 'WebEx',
    label: 'WebEx',
  },
  {
    key: 'Other',
    label: 'Other',
  },
]

type AvailableMethodsProps = {
  children: ReactNode
}

const AvailableMethods = ({ children }: AvailableMethodsProps): JSX.Element => (
  <MethodsProvider methods={methods} ready>
    {children}
  </MethodsProvider>
)

export default AvailableMethods
