import React, {
  useState,
  useCallback,
  useMemo,
  ReactNode,
  MouseEvent,
  createContext,
  useContext,
} from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import KebabIcon from '../components/Icons/Kebab'

type KebabMenuProps = {
  id: string
  label: string
  children: ReactNode
}

type ContextValue = {
  close: () => void
}

export const KebabMenuContext = createContext<ContextValue>({
  close: () => {
    throw new Error('not implemented')
  },
})

const KebabMenu = ({ label, id, children }: KebabMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const openMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const contextValue = useMemo(
    () => ({
      close: handleClose,
    }),
    [handleClose],
  )

  return (
    <KebabMenuContext.Provider value={contextValue}>
      <IconButton aria-label={label} onClick={openMenu}>
        <KebabIcon />
      </IconButton>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </KebabMenuContext.Provider>
  )
}

export default KebabMenu

export const useKebabMenu = (): ContextValue => useContext(KebabMenuContext)
