import React, { useCallback } from 'react'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import { createUseStyles } from 'react-jss'
import CreateConversation from './CreateConversation'
import { useNotifications } from '../../notifications'
import { PartialConversation } from './form/builder/state'

type CreateConversationModalProps = {
  open: boolean
  onClose: () => void
  data?: PartialConversation
  groupId?: number
}

const useStyles = createUseStyles({
  wrapper: {
    margin: '16px auto',
    padding: '8px',
    width: '90vw',
    maxHeight: 'calc(100vh - 32px)',
    overflowX: 'scroll',
  },
})

const CreateConversationModal = ({
  open,
  onClose,
  data,
  groupId,
}: CreateConversationModalProps): JSX.Element => {
  const classes = useStyles()
  const [, { pushNotification }] = useNotifications()
  const handleCreate = useCallback(() => {
    pushNotification({
      subject: 'Success!',
      message: 'Conversation details saved.',
      timeout: 10, // 10 seconds
      level: 'success',
    })

    onClose()
  }, [pushNotification, onClose])

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.wrapper} square>
        <CreateConversation
          onCancel={onClose}
          onCreate={handleCreate}
          data={data}
          groupId={groupId}
        />
      </Paper>
    </Modal>
  )
}

export default CreateConversationModal
