import React, { ReactNode } from 'react'
import Button from 'react-bootstrap/Button'
import { createUseStyles } from 'react-jss'
import { LattusTheme } from '../../../theme'

type CallButtonProps = {
  disabled?: boolean
  onClick: () => null | void
  children: ReactNode
}

const useStyles = createUseStyles<string, unknown, LattusTheme>((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
  },
}))

const CallButton = ({
  disabled,
  onClick,
  children,
}: CallButtonProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Button
      variant="light"
      disabled={disabled}
      onClick={onClick}
      className={classes.button}
    >
      {children}
    </Button>
  )
}

CallButton.defaultProps = {
  disabled: false,
  children: null,
}

export default CallButton
