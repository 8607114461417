import React, { useCallback, useEffect, useState } from 'react'
import CardContainer from '../../../elemets/CardContainer'
import { Col, Row } from 'react-bootstrap'
import { useApiFetch, useApiSubmit } from '../../../api'
import moment from 'moment'
import ActionButton from '../../../elemets/ActionButton'
import { isMobile } from '../../../utils/common'
import CustomSelect from '../../../elemets/CustomSelect'
import { createUseStyles } from 'react-jss'
import { withErrorHandler } from '../../../errors'
import { withNotifications } from '../../../notifications'
import compose from 'just-compose'
import colors, { headings } from '../../../theme/theme2'
import CardTitle from '../../../elemets/CardTitle'
import Icon from '../../Icons'
import RequestAvailabilityModal from '../../Modals/Conversation/RequestAvailabilityModal'

const useStyles = createUseStyles({
  card: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    marginBottom: 8,
  },
  name: {
    ...headings.title,
    marginTop: 10,
  },
  subtitle: {
    ...headings.paragraph,
    color: colors.dark,
    fontWeight: 'bold',
  },
  dateRow: {
    padding: isMobile() ? '18px 9px 14px 9px' : '13px 15px 13px 31px',
  },
  time: {
    ...headings.paragraph,
    color: colors.dark,
    fontWeight: 'bold',
  },
  bgGrey: {
    backgroundColor: colors.grey1,
  },
  select: {
    '& *': {
      fontSize: '13px!important',
    },
  },
  arrow: {
    minWidth: 10,
  },
  icon: {
    '& i': {
      '&:before': {
        color: colors.primary,
      },
    },
  },
})

const AvailabilityRow = ({
  index,
  token,
  dayAvailability,
  topic,
  handleError,
  pushNotification,
}) => {
  const classes = useStyles()
  const [scheduled, setScheduled] = useState(false)
  const [selected, setSelected] = useState()
  const [count, setCount] = useState(1)

  const {
    submitted,
    submit: scheduleConversation,
    err,
    submitting,
  } = useApiSubmit('scheduleConversation')

  useEffect(() => {
    if (submitted && !submitting && !err) {
      setScheduled(true)
    } else if (!submitting && err) {
      handleError(err, true, {
        message: 'Unable to request scheduled a conversation.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, submitting, err])

  useEffect(() => {
    if (scheduled) {
      pushNotification({
        level: 'success',
        subject: 'Conversation Scheduled.',
        timeout: 10,
      })
    }
  }, [pushNotification, scheduled])

  useEffect(() => {
    if (dayAvailability.hasOwnProperty('availabilities')) {
      setSelected(dayAvailability.availabilities[0])
    }
  }, [dayAvailability])

  const handleSchedule = useCallback(() => {
    const query = {
      token: token,
    }
    const body = {
      skillId: parseInt(topic.skillId, 10),
      startTime: moment(selected.value).utc(),
    }
    scheduleConversation({ query, body })
  }, [token, topic, selected, scheduleConversation])

  const renderButton = useCallback(() => {
    return scheduled ? (
      <ActionButton
        height="34px"
        text="CONVO SCHEDULED"
        type="LIGHT_GREEN"
        className="text-nowrap"
        disabled
      />
    ) : (
      <ActionButton
        height="34px"
        text="SCHEDULE"
        onClick={handleSchedule}
        type="LIGHT_GREEN"
        className="text-nowrap"
      />
    )
  }, [handleSchedule, scheduled])
  const handleSelect = useCallback(
    (key, value) => {
      if (value && value.value !== selected.value) {
        if (scheduled) {
          setScheduled(false)
          dayAvailability.availabilities.splice(
            dayAvailability.availabilities.findIndex(
              (availability) => availability.value === selected.value,
            ),
            1,
          )
          setCount((c) => c + 1)
        }
        setSelected(value)
      }
    },
    [dayAvailability.availabilities, scheduled, selected],
  )

  return (
    <div
      key={index}
      className={`${classes.dateRow} ${index % 2 ? '' : classes.bgGrey}`}
    >
      {isMobile() ? (
        <>
          <Row className="align-items-center mb-3">
            <Col xs={12} className={classes.time}>
              {moment(dayAvailability.day).format('dddd, MMM DD, YYYY')}
            </Col>
          </Row>
          <Row className={`mt-3`}>
            <Col xs={6}>
              <CustomSelect
                id={count}
                options={dayAvailability.availabilities}
                name="startTime"
                value={selected}
                onSelect={handleSelect}
                className={`${classes.select}`}
              />
            </Col>
            <Col xs={6} className="d-flex justify-content-end">
              {renderButton()}
            </Col>
          </Row>
        </>
      ) : (
        <Row className="align-items-center">
          <Col xs={3} className={classes.time}>
            {moment(dayAvailability.day).format('dddd, MMM DD, YYYY')}
          </Col>
          <Col xs={4} className={classes.time}>
            <CustomSelect
              id={count}
              options={dayAvailability.availabilities}
              name="startTime"
              value={selected}
              onSelect={handleSelect}
              className={`${classes.select}`}
            />
          </Col>
          <Col className="d-flex justify-content-end">{renderButton()}</Col>
        </Row>
      )}
    </div>
  )
}

const ScheduleNextCallCard = ({
  token,
  topic,
  pushNotification,
  handleError,
}) => {
  const classes = useStyles()

  const [availabilities, setAvailabilities] = useState([])
  const [showRequestAvailability, setShowRequestAvailability] = useState(false)
  const [requested, setRequested] = useState(false)
  const [pagination, setPagination] = useState(0)
  const [upperLimit, setUpperLimit] = useState(0)

  const { data: matchAvailability, fetch: listMatchAvailability } = useApiFetch(
    'listMatchAvailability',
    {},
    false,
  )

  useEffect(() => {
    if (token) {
      listMatchAvailability({ query: { token: token } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    if (matchAvailability) {
      let availabilitiesDays = []
      matchAvailability.forEach((availability) => {
        availabilitiesDays.push(
          moment(availability.startTime).format('dddd, MMM DD, YYYY'),
        )
      })
      availabilitiesDays = availabilitiesDays.filter(
        (item, index) => availabilitiesDays.indexOf(item) === index,
      )
      setAvailabilities(
        availabilitiesDays.map((day) => {
          return {
            day: day,
            availabilities: matchAvailability
              .filter(
                (av) =>
                  moment(av.startTime).format('dddd, MMM DD, YYYY') === day,
              )
              .map((av) => {
                return {
                  label: `${moment(av.startTime).format('hh:mm')} to ${moment(
                    av.endTime,
                  ).format('hh:mm A')}`,
                  value: av.startTime,
                  availability: av,
                }
              }),
          }
        }),
      )
    }
  }, [matchAvailability])

  useEffect(() => {
    if (availabilities) {
      setUpperLimit(Math.ceil(availabilities.length / (isMobile() ? 4 : 5)))
    }
  }, [availabilities])

  return (
    <>
      {showRequestAvailability && (
        <RequestAvailabilityModal
          setRequestedAvailability={setRequested}
          show={showRequestAvailability}
          match={{ token: token, topics: [topic] }}
          onClose={() => {
            setShowRequestAvailability(false)
          }}
        />
      )}
      <CardContainer
        headerDivider={true}
        header={<CardTitle title="Schedule Next Conversation" />}
      >
        {availabilities
          ?.slice(
            pagination * (isMobile() ? 4 : 5),
            pagination * (isMobile() ? 4 : 5) + (isMobile() ? 4 : 5),
          )
          .map((availability, index) => {
            return (
              <AvailabilityRow
                key={`availability-${index}`}
                index={index}
                dayAvailability={availability}
                topic={topic}
                token={token}
                handleError={handleError}
                pushNotification={pushNotification}
              />
            )
          })}

        {availabilities?.length > 5 && (
          <Row noGutters className={`pt-2 pr-3`}>
            <Col xs={12} className={'d-flex justify-content-center'}>
              <div className={`d-flex ${classes.icon}`}>
                <div className={classes.arrow}>
                  {pagination !== 0 && (
                    <Icon
                      icon={'fas fa-chevron-left'}
                      onClick={() => setPagination((s) => s - 1)}
                    />
                  )}
                </div>
                <span className={'pr-2 pl-2'}>{pagination + 1}</span>
                <div className={classes.arrow}>
                  {pagination + 1 < upperLimit && (
                    <Icon
                      icon={'fas fa-chevron-right'}
                      onClick={() => setPagination((s) => s + 1)}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}

        {(!matchAvailability || matchAvailability?.length === 0) && (
          <p className={`${classes.subtitle} p-3`}>
            {' '}
            Sorry, the match doesn't have any availabilities at the moment{' '}
          </p>
        )}
        <div className={`p-3 d-flex justify-content-end`}>
          <ActionButton
            height="34px"
            text={requested ? 'REQUEST SENT' : 'SEND REQUEST'}
            onClick={() => {
              setShowRequestAvailability(true)
            }}
            type="LIGHT_GREEN"
            className="text-nowrap"
            disabled={requested}
          />
        </div>
      </CardContainer>
    </>
  )
}

export default compose(
  withErrorHandler,
  withNotifications,
)(ScheduleNextCallCard)
