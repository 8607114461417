import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col, Container } from 'react-bootstrap'
import compose from 'just-compose'
import { createUseStyles } from 'react-jss'

import { withErrorHandler } from '../../errors'

// Hooks
import { useCurrentUser } from '../Context/CurrentUser'

// Api
import { useApiFetch, useApiSubmit } from '../../api/index'

// Utils
import { isMobile } from '../../utils/common'

// Theme
import { headings } from '../../theme/theme2'

// Elements
import CardContainer from '../../elemets/CardContainer'
import CustomSelect from '../../elemets/CustomSelect'
import { conversationOptions } from '../Profile/TopicsOfInterest'
import NewConversationCard from './NewConversationCard'
import ConversationFilter from './ConversationFilter'
import ConversationRow from './ConversationRow'
import LoaderPage from '../../elemets/LoaderPage'

const useStyles = createUseStyles({
  mobile: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  title: {
    ...headings.title,
  },
  card: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    marginBottom: 8,
  },
  selector: {
    minWidth: 65,
  },
})

const ConversationsPage = React.memo(() => {
  const classes = useStyles()
  const [maxConversations, setMaxConversations] = useState()
  const [page, setPage] = useState(1)
  const [completedConversations, setCompletedConversations] = useState([])
  const [filters, setFilters] = useState({
    topic: null,
    person: null,
  })
  const [sortField, setSortField] = useState({
    field: 'co_datescheduled',
    asc: false,
  })
  const hasMoreItems = useRef(false)
  const { currentUser, loadingUser } = useCurrentUser()

  const { submit: updateProfile, submitting: updatingProfile } =
    useApiSubmit('updateProfileV1')

  const {
    data: completedConversationsData,
    loading,
    fetch: fetchCompleted,
  } = useApiFetch('listConversations', {}, null)

  const {
    data: scheduledConversationsData,
    loading: loadingScheduled,
    fetch: fetchScheduled,
  } = useApiFetch('listConversations', {}, null)

  const {
    data: filterConversations,
    loading: loadingFilter,
    fetch: fetchFilter,
  } = useApiFetch('listFilterConversations', {}, null)

  const timestampNow = useMemo(() => new Date().toISOString(), [])

  useEffect(() => {
    if (currentUser?.id) {
      const query = {
        count: 10,
        page,
        before: timestampNow,
        ...filters,
        ...sortField,
      }
      fetchScheduled({
        query: { status: 'scheduled' },
      })
      fetchCompleted({ query })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentUser, filters, sortField, timestampNow])

  useEffect(() => {
    if (completedConversationsData && scheduledConversationsData) {
      let scheduledConversationsSet = new Set(
        scheduledConversationsData.map((item) => item.id),
      )
      let filteredCompletedConversations = completedConversationsData.filter(
        (conversation) => !scheduledConversationsSet.has(conversation.id),
      )

      if (
        JSON.stringify(filteredCompletedConversations) !==
        JSON.stringify(completedConversations)
      ) {
        setCompletedConversations(filteredCompletedConversations)
      }
    }
    // eslint-disable-next-line
  }, [completedConversationsData, scheduledConversationsData])

  const handleScroll = () => {
    if (
      Math.ceil(window?.innerHeight + document?.documentElement?.scrollTop) ===
        document?.documentElement?.offsetHeight &&
      hasMoreItems?.current
    ) {
      document.activeElement.blur()
      hasMoreItems.current = false
      setPage((p) => p + 1)
    }
  }

  const setFilter = (key, value) => {
    hasMoreItems.current = false
    setPage(1)
    setCompletedConversations([])
    setFilters((f) => ({
      ...f,
      [key]: value,
    }))
  }

  const setOrder = (key) => {
    hasMoreItems.current = false
    setPage(1)
    setCompletedConversations([])
    setSortField((f) => ({
      field: key,
      asc: f.field === key ? !f.asc : true,
    }))
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentUser?.id) {
      setMaxConversations(
        conversationOptions.find(
          (item) => item.value === currentUser.maxConversations,
        ),
      )
    }
  }, [currentUser])

  // CALLBACKS
  const save = useCallback(
    (maxConversations) => {
      if (!currentUser.id) return
      try {
        updateProfile({
          params: {
            profileId: currentUser.id,
          },
          body: {
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            conversationFrequencyId: currentUser.conversationFrequencyId,
            notificationFrequencyId: currentUser.notificationFrequencyId,
            maxConversations: Number(maxConversations),
            email: currentUser.email,
            regionId: currentUser.region,
            showTour: currentUser.showTour,
            bio: currentUser.bio,
            funFact: currentUser.funFact,
            homeTown: currentUser.homeTown,
            highSchool: currentUser.highSchool,
            linkedIn: currentUser.linkedIn,
            location: currentUser.location,
            phoneNumber: currentUser.phoneNumber,
          },
        })
      } catch (err) {
        const { handleError } = this.props
        handleError(err, true, {
          message: 'There was a problem updating your profile.',
        })
      }
    },
    [updateProfile, currentUser],
  )

  const handleSelectMax = useCallback(
    (key, value) => {
      setMaxConversations(value)
      save(value.value)
      fetchScheduled({
        query: { count: value.value, status: 'scheduled' },
      })
    },
    [fetchScheduled, save],
  )

  //LOADING
  const isLoading = useMemo(
    () => loading || loadingScheduled || loadingFilter || loadingUser,
    [loading, loadingFilter, loadingScheduled, loadingUser],
  )

  return (
    <Container className={isMobile() ? classes.mobile : ''}>
      {isLoading && <LoaderPage />}
      {isMobile() && !isLoading ? (
        <>
          <NewConversationCard user={currentUser} buttonText="FIND MATCH" />
          <CardContainer className={classes.card}>
            <Row>
              <Col xs={7} className="pr-0">
                <span className={classes.title}>Scheduled Conversations</span>
              </Col>
              <Col>
                <div className="d-flex align-items-center justify-content-end">
                  <label className="mb-0">Max.</label>
                  <div className={classes.selector}>
                    <CustomSelect
                      options={conversationOptions}
                      onSelect={handleSelectMax}
                      value={maxConversations}
                      border={false}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </CardContainer>

          {!isLoading &&
            scheduledConversationsData &&
            scheduledConversationsData.map((i, index) => (
              <ConversationRow
                conversation={i}
                isLast={scheduledConversationsData.length - 1 === index}
                type="SCHEDULED"
                key={`mobile-scheduled-${i.id}`}
                reload={loading}
              />
            ))}
          <ConversationFilter
            setFilters={setFilter}
            filterConversations={filterConversations}
            setOrder={setOrder}
          />
          {!isLoading &&
            completedConversations &&
            completedConversations.map((i) => (
              <ConversationRow
                key={`mobile-completed-${i.id}`}
                conversation={i}
                type="COMPLETED"
              />
            ))}
        </>
      ) : (
        <>
          {!isLoading ? (
            <>
              <NewConversationCard user={currentUser} buttonText="FIND MATCH" />

              <CardContainer className={classes.card}>
                <Row>
                  <Col xs={3}>
                    <span className={classes.title}>
                      Scheduled Conversations
                    </span>
                  </Col>
                  <Col>
                    <div className="d-flex align-items-center">
                      <label className="mb-0">
                        Max Scheduled Conversations
                      </label>
                      <div className={classes.selector}>
                        <CustomSelect
                          options={conversationOptions}
                          onSelect={handleSelectMax}
                          value={maxConversations}
                          border={false}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardContainer>
            </>
          ) : null}
          {!isLoading &&
            scheduledConversationsData &&
            scheduledConversationsData.map((i, index) => (
              <ConversationRow
                key={`scheduled-${i.id}`}
                conversation={i}
                isLast={scheduledConversationsData.length - 1 === index}
                type="SCHEDULED"
                reload={loading}
              />
            ))}

          <ConversationFilter
            setFilters={setFilter}
            filterConversations={filterConversations}
            setOrder={setOrder}
          />
          {!isLoading &&
            completedConversations &&
            completedConversations.map((i) => (
              <ConversationRow
                key={`completed-${i.id}`}
                conversation={i}
                type="COMPLETED"
              />
            ))}
          {/*{isLoading && <LoaderPage />}*/}
        </>
      )}
    </Container>
  )
})

export default compose(withRouter, withErrorHandler)(ConversationsPage)
