import { useEffect, useCallback } from 'react'
import useNotifications, { State, Actions } from './useNotifications'
import { useAuth0 } from '../../auth0'
import { invokeOperationWithAuth } from '../../api'

const seconds = 1000
const minutes = seconds * 60

const usePolling = ({ frequency = 2 * minutes }: { frequency?: number } = {}): [
  State,
  Actions,
] => {
  const { isAuthenticated, getTokenSilently, loading } = useAuth0()
  const [state, actions] = useNotifications()
  const update = useCallback(async () => {
    const invoke = invokeOperationWithAuth(getTokenSilently)
    const apiNotifications = await invoke('listNotificationsV1', {
      query: { type: 'web' },
    })
    if (Array.isArray(apiNotifications)) {
      apiNotifications.map((notification) => {
        const clear = async () =>
          invoke('clearNotification', {
            params: { notificationId: notification.id },
          })
        // TODO provide typings for actions
        if (
          typeof actions === 'object' &&
          actions &&
          'pushNotification' in actions &&
          typeof actions.pushNotification === 'function'
        ) {
          return actions.pushNotification(notification, clear)
        }
        return undefined
      })
    }
  }, [actions, getTokenSilently])

  useEffect(() => {
    if (loading || !isAuthenticated) return
    update()
    const intervalId = setInterval(update, frequency)
    return () => {
      clearInterval(intervalId)
    }
  }, [loading, isAuthenticated, update, frequency])

  return [state, actions]
}

export default usePolling
