/* eslint-disable no-console */
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { createUseStyles } from 'react-jss'

// Styles
import colors from '../theme/theme2'

const imagesMimeTypes = [
  'image/jpeg', // JPEG
  'image/png', // PNG
  'image/gif', // GIF
  'image/svg+xml', // SVG
]

const useStyles = createUseStyles({
  root: {
    outline: 'none !important',
    cursor: 'pointer',
  },
  input: {
    outline: 'none !important',
  },
  dropBox: {
    width: '100%',
    height: 300,
    border: `dashed 1px ${colors.grey2}`,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'border-color 0.5s, background-color 0.5',
  },
  dropBoxActive: {
    borderColor: colors.grey1,
    borderStyle: 'solid',
  },
  dropBoxWithContent: {
    borderStyle: 'none',
  },
  icon: {
    transform: 'translateY(-1px)',
    color: '#9b9b9b',
    transition: 'color 0.5s',
    fontSize: 75,
  },
  errorIcon: {
    color: colors.warningOrange,
  },
})

const ImageDropZone = ({
  className,
  displayComponent,
  placeholder = null,
  icon,
  mimeTypes = imagesMimeTypes,
  sizeDimensionsLimit,
  value,
  onChange,
  maxSize,
  errorMessage,
  errorIcon,
  inputFileRef = null,
  onClick = null,
}) => {
  const classes = useStyles()
  // States
  const [error, setError] = React.useState(false)

  // DND
  const handleOnDropRejected = React.useCallback(() => {
    console.log('error')
    setError(true)
  }, [])

  const onDrop = React.useCallback(
    acceptedFiles => {
      setError(false)
      if (acceptedFiles && acceptedFiles.length === 1) {
        const newFile = acceptedFiles[0]

        if (sizeDimensionsLimit) {
        } else {
          const imageUrl = URL.createObjectURL(newFile)
          onChange({ file: newFile, url: imageUrl })
        }
      }
    },
    [sizeDimensionsLimit, onChange],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // noClick: !!value.url,
    // noDrag: !!value.url,
    multiple: false,
    accept: mimeTypes,
    maxSize,
    onDropRejected: handleOnDropRejected,
  })

  return (
    <div className={className}>
      {/* Root */}
      <div className={classes.root} {...getRootProps()}>
        <input
          className={classes.input}
          {...getInputProps()}
          ref={inputFileRef}
        />

        {/* Drop Box */}
        <div
          className={`${classes.dropBox} ${
            isDragActive ? classes.dropBoxActive : ''
          } ${value.url ? classes.dropBoxWithContent : ''}`}
          onClick={onClick}
        >
          {value.url ? (
            displayComponent
          ) : (
            <>
              {(placeholder || icon) && (
                <>
                  {icon && (
                    <div>
                      <i
                        className={`${classes.icon} ${
                          error ? `${errorIcon} ${classes.errorIcon}` : icon
                        }`}
                      />
                    </div>
                  )}
                  {error ? errorMessage : placeholder}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(ImageDropZone)
