import React, { useEffect, useState } from 'react'
import { useApiFetch, useApiSubmit } from '../api'
import { useErrorHandler } from '../errors'
import { createUseStyles } from 'react-jss'
import colors, { buttonStyles, headings } from '../theme/theme2'
import { withNotifications } from '../notifications'
import compose from 'just-compose'

import ActionButton from '../elemets/ActionButton'
import { isMobile } from 'react-device-detect'
import Divider from '../elemets/Divider'
import moment from 'moment'
import CustomCheckbox from '../elemets/CustomCheckbox'
import Modal from '../elemets/Modal'

const useStyles = createUseStyles({
  primaryButton: {
    ...buttonStyles.primary,
  },
  secondaryButton: {
    ...buttonStyles.secondary,
  },
  modal: {
    '& .modal-dialog': {
      minWidth: '750px!important',
      '@media (max-width: 767px)': {
        minWidth: '500px!important',
      },
      '@media (max-width: 575px)': {
        minWidth: 'unset!important',
        margin: 0,
        '& .modal-content': {
          borderRadius: 0,
        },
      },
      '& .modal-content': {
        '& > div': {
          padding: 0,
          paddingBottom: 10,
          '& > div:first-child': {
            margin: 0,
          },
          '& > div:nth-child(2)': {
            borderRadius: 5,
            padding: 10,
          },
        },
      },
    },
  },
  updated: {
    ...headings.paragraph,
    paddingTop: 5,
    fontSize: 12,
  },
  description: {
    ...headings.paragraph,
    fontSize: 14,
    paddingLeft: 10,
    color: '#343434',
    fontWeight: 'bold',
  },
  checkboxCheck: {
    backgroundColor: [colors.primary, '!important'],
  },
  icon: { color: ['white', '!important'] },
  title: {
    ...headings.paragraph,
    fontSize: 18,
    fontWeight: 'bold',
    color: 'black',
  },
  iframe: {
    position: 'absolute',
    top: -80,
    left: -75,
    width: '135%',
    height: '150%',
  },
  container: {
    overflow: 'hidden',
    position: 'relative',
    height: 300,
  },
})

const TermsConditions = ({ pushNotification }) => {
  const {
    loading,
    data: legalDocs,
    err,
  } = useApiFetch('getUnacceptedLegalDocs')
  const handleError = useErrorHandler()

  useEffect(() => {
    if (err && !loading) {
      handleError(err, true, {
        message: 'There was a problem getting the legal documents to sign.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalDocs, err, loading])

  // return legalDocs  && legalDocs.length > 0 ? legalDocs.map(doc => <TermsConditionsModal key={doc.id} terms={doc} />) : null
  return legalDocs && legalDocs.length > 0
    ? legalDocs.map((doc) => (
        <TermsConditionsModal
          key={doc.id}
          terms={doc}
          pushNotification={pushNotification}
        />
      ))
    : null
}

const TermsConditionsModal = ({
  terms: { id, title, text: uri, updated } = {},
  pushNotification,
}) => {
  const classes = useStyles()
  const handleError = useErrorHandler()
  const [show, setShow] = useState(true)
  const [accepted, setAccepted] = useState(false)
  const {
    submitted,
    submit: acceptLegalDoc,
    err: acceptErr,
    submitting,
  } = useApiSubmit('acceptLegalDoc')

  useEffect(() => {
    if (submitted && !acceptErr && !submitting) {
      setShow(false)
      pushNotification({
        subject: 'Document accepted.',
        level: 'success',
        timeout: 10,
      })
    } else if (acceptErr && !submitting) {
      handleError(acceptErr, true, {
        message: 'There was a problem accepting this document.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, acceptErr, submitting])

  return (
    <Modal
      modalClass={classes.modal}
      show={show}
      close={false}
      closeIcon={false}
      footer={
        <div className={`d-flex justify-content-end mr-3`}>
          <ActionButton
            onClick={() => acceptLegalDoc({ body: { id } })}
            text={'ACCEPT'}
            type={'STRONG_GREEN'}
            disabled={!accepted}
          />
        </div>
      }
      closeOutside={false}
      dialogClassName={classes.dialogMobile}
    >
      <div className={`d-flex justify-content-between w-100`}>
        <span className={classes.title}>
          {isMobile ? title.replace('Lattus ', '') : title}
        </span>
        <span className={classes.updated}>
          Last Updated: {moment.utc(updated).local().format('MMMM DD, YYYY')}
        </span>
      </div>
      <Divider />
      <div className={`d-flex ${classes.container}`}>
        <iframe
          src={uri}
          width={'100%'}
          height={'100%'}
          frameBorder={0}
          title={title}
          className={classes.iframe}
        />
      </div>
      <div className={`d-flex justify-content-end pt-3`}>
        <CustomCheckbox
          checked={accepted}
          backgroundColor={accepted ? classes.checkboxCheck : ''}
          iconColor={classes.icon}
          onClick={() => {
            setAccepted(!accepted)
          }}
        />
        <span className={classes.description}>I agree to {title}</span>
      </div>
    </Modal>
  )
}

export default compose(withNotifications)(TermsConditions)
