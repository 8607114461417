import React, { useCallback, ChangeEvent } from 'react'
import { FormControl } from 'react-bootstrap'

export interface Option<T extends string> {
  key: T
  label: string
}

type SelectControlProps<T extends string> = {
  labelId: string
  options: Option<T>[]
  value?: T
  disabled?: boolean
  onChange: (newValue: T) => void
}

const SelectControl = function SelectControl<T extends string>({
  labelId,
  options,
  value,
  disabled = false,
  onChange,
}: SelectControlProps<T>): JSX.Element {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const {
        currentTarget: { value: newValue },
      } = event
      onChange(newValue as T)
    },
    [onChange],
  )

  return (
    <FormControl
      as="select"
      disabled={disabled}
      onChange={handleChange}
      value={value ?? ''}
      aria-labelledby={labelId}
    >
      {!value ? <option disabled value="" /> : null}
      {options.map(({ key, label }) => (
        <option key={key} value={key}>
          {label}
        </option>
      ))}
    </FormControl>
  )
}

export default SelectControl
