import React from 'react'
import { Button } from 'react-bootstrap'
import lattusApi from '../../../lattusApi'
import { useErrorHandler } from '../../../errors'

const UnlinkAccountButton = props => {
  const { unlinkAccountV1 } = lattusApi
  const { secondaryUserId = '' } = props
  const handleError = useErrorHandler()

  const unlinkAccount = async secondaryUserId => {
    unlinkAccountV1({ secondaryUserId }).catch(err => {
      handleError(err, true, {
        message: `There was a problem unlinking your account: ${err}.`,
      })
    })
  }

  return (
    <Button
      variant="danger"
      onClick={() => unlinkAccount(secondaryUserId)}
      {...props}
    >
      Unlink Account
    </Button>
  )
}

export default UnlinkAccountButton
