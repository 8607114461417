import React, { useState } from 'react'
import CardContainer from '../../elemets/CardContainer'
import CardTitle from '../../elemets/CardTitle'

import { createUseStyles } from 'react-jss'
import { isMobile } from '../../utils/common'
import ActionButton from '../../elemets/ActionButton'
import colors from '../../theme/theme2'
import compose from 'just-compose'
import { withRouter } from 'react-router-dom'
import ActionItemsModal from './ActionItems/ActionItemsModal'
import { withErrorHandler } from '../../errors'

const useStyles = createUseStyles({
  title: {
    padding: 0,
  },
  container: {
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
  },
  mobile: {
    paddingTop: 0,
    minHeight: 'unset',
  },
  icon: {
    '& i': {
      fontSize: 20,
      color: colors.primary,
    },
    position: 'absolute',
    left: 10,
  },
  iconText: {
    paddingLeft: 10,
  },
  actionButton: {
    width: '100%!important',
  },
})

const ActionItems = () => {
  const classes = useStyles()
  const [showModal, setShowModal] = useState(false)
  const [dueItems, setDueItems] = useState(0)
  const [showUpcomingModal, setShowUpcomingModal] = useState(false)
  const [upcomingItems, setUpcomingItems] = useState(0)

  const header = (
    <CardTitle
      className={`${classes.title} ${isMobile() ? 'pt-3' : ''}`}
      title={'Action Items'}
    />
  )
  const footer = (
    <div>
      <ActionItemsModal
        show={showModal}
        onClose={() => {
          setShowModal(false)
        }}
        setDueItems={setDueItems}
      />
      <ActionItemsModal
        show={showUpcomingModal}
        onClose={() => {
          setShowUpcomingModal(false)
        }}
        status={'scheduled'}
        setDueItems={setUpcomingItems}
      />
      <ActionButton
        className={`${classes.actionButton}`}
        type={'LIGHT_RED'}
        text={
          <>
            <span className={classes.iconText}>PAST DUE ITEMS {dueItems}</span>
          </>
        }
        onClick={() => {
          if (dueItems > 0) {
            setShowModal(true)
          }
        }}
      />
      <br />
      <ActionButton
        className={`${classes.actionButton}`}
        type={'LIGHT_GREEN'}
        text={
          <>
            <span className={classes.iconText}>
              UPCOMING ITEMS {upcomingItems}
            </span>
          </>
        }
        onClick={() => {
          if (upcomingItems > 0) {
            setShowUpcomingModal(true)
          }
        }}
      />
    </div>
  )

  return (
    <CardContainer
      className={`${classes.container} ${isMobile() ? classes.mobile : ''}`}
      header={header}
      headerDivider={true}
      footer={footer}
    />
  )
}

export default compose(withRouter, withErrorHandler)(ActionItems)
