export type Params = Record<string, string | number>

const setParams = (path: string, values: Params): string => {
  const pathParam = /\{(\w+)\}/
  let endpoint = path
  let match
  while ((match = pathParam.exec(endpoint)) !== null) {
    const [fullMatch, identifier] = match || []
    if (!(identifier in values)) {
      throw new Error(`missing required parameter: ${identifier}`)
    }

    const replaceVal = values[identifier]
    endpoint = endpoint.replace(fullMatch, String(replaceVal))
  }
  return endpoint
}

export default setParams
