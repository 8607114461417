import React, { useState, useEffect } from 'react'
import { Device, Call as TwilioCall } from '@twilio/voice-sdk'
import { createUseStyles } from 'react-jss'
import Slide from '@material-ui/core/Slide'
import clsx from 'clsx'
import DeviceStatus from './DeviceStatus'
import Status from './Status'
import User from './User'
import { useConversation } from './ConversationProvider'
import { useApi } from '../../../api'
import { LattusTheme } from '../../../theme'

type CallProps = {
  onClose: () => void
}

const useStyles = createUseStyles<string, unknown, LattusTheme>((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    paddingBottom: '16px',
    backgroundColor: '#414a4c',
    color: '#f3f3f2',
  },
  footer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& hr': {
      marginTop: 0,
    },
  },
}))

const Call = ({ onClose }: CallProps): JSX.Element => {
  const classes = useStyles()
  const { invoke } = useApi()
  const { conversation, err: conversationErr } = useConversation()
  // TODO use useReducer for state management
  const [token, setToken] = useState(null)
  const [err, setErr] = useState(null)
  const [connErr, setConnErr] = useState<Error | null>(null)
  const [warning, setWarning] = useState<string | null>(null)
  const [deviceStatus, setDeviceStatus] = useState(DeviceStatus.Unset)
  const [device, setDevice] = useState<Device | null>(null)
  const [connection, setConnection] = useState<TwilioCall | null>(null)

  if (conversationErr && !connErr) {
    setConnErr(conversationErr)
  }

  useEffect(() => {
    if (!conversation) {
      return
    }

    const { id: conversationId } = conversation
    let cancelled = false

    const createPresence = async () => {
      try {
        const { token } = await invoke('rtc.createPresence', {
          params: { conversationId },
        })
        if (cancelled) {
          return
        }
        setToken(token)
      } catch (err) {
        if (cancelled || !(err instanceof Error)) {
          return
        }
        setConnErr(err)
      }
    }

    createPresence()

    return () => {
      invoke('rtc.deletePresence', { params: { conversationId } }).catch(
        setConnErr,
      )

      cancelled = true
    }
  }, [conversation, invoke])

  useEffect(() => {
    if (!token) {
      return
    }

    const device = new Device(token, {
      codecPreferences: [TwilioCall.Codec.Opus, TwilioCall.Codec.PCMU],
      closeProtection: false,
    })

    device.on('error', (deviceErr) => {
      setErr(deviceErr)
      setDeviceStatus(DeviceStatus.Error)
    })

    device.on('connect', (call: TwilioCall) => {
      setConnection(call)
      setDeviceStatus(DeviceStatus.Connected)
      call.on('warning', (warningName) => {
        setWarning(warningName)
        setDeviceStatus(DeviceStatus.Warning)
      })
      call.on('warning-cleared', () => {
        setWarning(null)
        setDeviceStatus(DeviceStatus.Connected)
      })
      call.on('reconnecting', (connErr) => {
        setErr(connErr)
        setDeviceStatus(DeviceStatus.Reconnecting)
      })
      call.on('reconnected', () => {
        setDeviceStatus(DeviceStatus.Connected)
      })
    })

    device.on('incoming', (call) => {
      setConnection(call)
      setDeviceStatus(DeviceStatus.IncomingCall)
      call.on('disconnect', () => {
        setDeviceStatus(DeviceStatus.Disconnected)
        setConnection(null)
      })
      call.on('reject', () => {
        setDeviceStatus(DeviceStatus.Rejected)
        setConnection(null)
      })
    })

    device.on('cancel', () => {
      setDeviceStatus(DeviceStatus.Cancelled)
    })

    device.on('disconnect', () => {
      setDeviceStatus(DeviceStatus.Disconnected)
      setConnection(null)
    })

    device.on('offline', () => {
      setDeviceStatus(DeviceStatus.Offline)
    })

    device.on('ready', () => {
      setDeviceStatus(DeviceStatus.Waiting)
    })

    setDevice(device)

    return () => {
      device.destroy()
    }
  }, [token])

  useEffect(() => {
    if (deviceStatus === DeviceStatus.Waiting) {
      setDeviceStatus(DeviceStatus.Ready)
    }
  }, [deviceStatus])

  if (err && deviceStatus !== DeviceStatus.Error) {
    setDeviceStatus(DeviceStatus.Error)
  }

  if (connErr && deviceStatus !== DeviceStatus.Error) {
    setDeviceStatus(DeviceStatus.Error)
    setWarning(`unable to setup conversation: ${connErr.message}`)
  }

  // TODO improve type definition of participant and conversations.participants
  const otherUser = conversation
    ? conversation.participants.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ({ currentUser }: Record<string, any>) => !currentUser,
      )
    : null
  const ready = !!(conversation && otherUser)

  return (
    <Slide direction="up" in={ready}>
      <div className={clsx({ [classes.root]: true })}>
        <Status
          deviceStatus={deviceStatus}
          device={device}
          connection={connection}
          warningDetail={warning}
          close={onClose}
        />
        {otherUser && conversation && (
          <User
            firstName={otherUser.firstName}
            lastName={otherUser.lastName}
            avatar={otherUser.avatar}
            role={otherUser.roleName}
            topic={conversation.skillName}
          />
        )}
        <footer className={classes.footer}>
          <hr />
          <p>The conversation will end automatically after 30 minutes.</p>
          <p>
            If you connect or disconnect headphones the conversation will end
            (connect or disconnect before you join).
          </p>
        </footer>
      </div>
    </Slide>
  )
}

export default Call
