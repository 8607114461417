import React, { useState, useEffect } from 'react'
import moment from 'moment'
import JoinCall from './JoinCall'
import ViewConversationDetails from './ViewConversationDetails'

type JoinOrViewConversationProps = {
  conversationId: number
  startTime: Date
  className?: string
  disabled?: boolean
  gracePeriod?: number
}

const getSecondsFromNow = (toTime: Date): number =>
  moment(toTime).diff(new Date(), 'seconds')

const JoinOrViewConversation = ({
  conversationId,
  startTime,
  className,
  disabled = false,
  gracePeriod = 5 * 60, // seconds
}: JoinOrViewConversationProps): JSX.Element => {
  const [canJoin, setCanJoin] = useState(false)

  useEffect(() => {
    const diff = getSecondsFromNow(startTime)
    if (diff < gracePeriod) {
      setCanJoin(true)
      return
    }

    const timeoutId = setTimeout(() => {
      setCanJoin(true)
    }, (diff - gracePeriod) * 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [startTime, gracePeriod])

  if (canJoin) {
    return (
      <JoinCall
        conversationId={conversationId}
        disabled={disabled}
        className={className}
      />
    )
  }

  return (
    <ViewConversationDetails
      conversationId={conversationId}
      disabled={disabled}
      className={className}
    />
  )
}

export default JoinOrViewConversation
