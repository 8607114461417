import React from 'react'
import { createUseStyles } from 'react-jss'

import CardContainer from '../../../elemets/CardContainer'
import SuggestedQuestions from './SuggestedQuestions'
import CardTitle from '../../../elemets/CardTitle'
import { buttonStyles, responsive } from '../../../theme/theme2'
import colors from '../../../theme/theme2'
import Divider from '../../../elemets/Divider'
import { isMobile } from '../../../utils/common'

const useStyles = createUseStyles({
  addToNotes: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: '#8a8a8a',
  },
  content: {
    maxHeight: isMobile() ? 250 : 300,
    overflowY: 'auto',
  },
  chevron: {
    width: 24,
    height: 24,
    color: colors.primary,
    ...buttonStyles.roundButton,
  },
  customDivider: {
    width: '70%',
  },
  customDividerText: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: colors.primary,
  },
  ...responsive,
})

const SuggestedQuestionCard = ({ show, conversationId, reload }) => {
  const classes = useStyles()

  return (
    show && (
      <CardContainer
        header={
          <div className="d-flex justify-content-between align-items-center">
            <CardTitle title="Recommended Questions" />
            <div>
              <span className={classes.addToNotes}>
                <i className="fa fa-plus-circle mr-2" />
                Add to Notes
              </span>
            </div>
          </div>
        }
        headerDivider={true}
        customHeaderDivider={<Divider className="mb-0 mt-1" />}
        className="pb-4"
      >
        <div className={classes.content}>
          <SuggestedQuestions conversationId={conversationId} reload={reload} />
        </div>
      </CardContainer>
    )
  )
}

export default SuggestedQuestionCard
