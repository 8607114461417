import { defaultConfig, LogLevel } from '@/config/Config'

interface Logger {
  debug: (message?: any, ...optionalParams: any[]) => void
  log: (message?: any, ...optionalParams: any[]) => void
  info: (message?: any, ...optionalParams: any[]) => void
  warn: (message?: any, ...optionalParams: any[]) => void
  error: (message?: any, ...optionalParams: any[]) => void
}

/* eslint-disable no-console */
const createLogger = (level: LogLevel): Logger => {
  return {
    debug: (message?: any, ...optionalParams: any[]) => {
      if (
        level === 'debug' ||
        level === 'info' ||
        level === 'warn' ||
        level === 'error'
      ) {
        console.debug('[DEBUG]: ', message, ...optionalParams)
      }
    },
    log: (message?: any, ...optionalParams: any[]) => {
      if (level === 'debug' || level === 'info') {
        console.log('[LOG]: ', message, ...optionalParams)
      }
    },
    info: (message?: any, ...optionalParams: any[]) => {
      if (level === 'info') {
        // eslint-disable-next-line no-console
        console.info('[INFO]: ', message, ...optionalParams)
      }
    },
    warn: (message?: any, ...optionalParams: any[]) => {
      if (level === 'info' || level === 'warn') {
        // eslint-disable-next-line no-console
        console.warn('[WARN]: ', message, ...optionalParams)
      }
    },
    // eslint-disable-next-line no-console
    error: console.error, // always log errors
  }
}

export const log = createLogger(defaultConfig.logLevel)
