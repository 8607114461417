import { useMemo, useRef } from 'react'
// import { withScope, captureException } from '@sentry/react'
// import ApiError from '../api/errors/ApiError'
import { useNotifications } from '../notifications'

export type Options = {
  id?: number
  subject?: string
  created?: Date
  message?: string
  timeout?: number
  level?: 'info' | 'success' | 'warning' | 'error'
}

// export const sendToSentry = (err: Error, options: Options = {}): void => {
//   const { message } = options || {}
//   withScope((scope) => {
//     if (message) {
//       scope.setExtras({ message })
//     }
//
//     if (err instanceof ApiError) {
//       const { req, res } = err
//       const authHeader = req.headers.get('authorization')
//       const token =
//         typeof authHeader === 'string'
//           ? `${authHeader.substring(0, 13)}...`
//           : ''
//       scope.setExtras({
//         req: {
//           url: req.url,
//           headers: Object.fromEntries(req.headers.entries()),
//           token,
//         },
//         res: {
//           code: res.status,
//           headers: Object.fromEntries(res.headers.entries()),
//         },
//       })
//     }
//
//     captureException(err)
//   })
// }

const useErrorHandler = (): ((
  err: Error,
  showNotification?: boolean,
  options?: Options,
) => void) => {
  const [, { pushNotification }] = useNotifications()
  const errors = useRef(new Set())

  const reportError = useMemo(
    () =>
      (err: Error, showNotifiation = false, options: Options = {}) => {
        if (errors.current.has(err)) {
          return
        }

        errors.current.add(err)

        // sendToSentry(err, options)

        if (showNotifiation) {
          const {
            subject = 'An error has occurred.',
            created = new Date(),
            message = err.message,
            timeout = 30,
            level = 'error',
          } = options || {}

          pushNotification({
            subject,
            created,
            message,
            timeout,
            level,
          })
        }
      },
    [pushNotification],
  )

  return reportError
}

export default useErrorHandler
