import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import moment from 'moment'
import compose from 'just-compose'
import { withRouter } from 'react-router'

import colors from '../../../theme/theme2'
import { withErrorHandler } from '../../../errors'
import ActionButton from '../../../elemets/ActionButton'
import CustomSelect from '../../../elemets/CustomSelect'
import { useApiSubmit } from '../../../api'
import { ArrowDownIcon, ArrowUpIcon } from '../../Icons'
import BannerContainer, { BannerDescription } from './BannerContainer'
import { Col, Row } from 'react-bootstrap'
import { withNotifications } from '../../../notifications'
import ReScheduleModal from '../../Modals/Conversation/ReScheduleModal'

const useStyles = createUseStyles({
  title: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  subtitle: {
    fontWeight: '500',
    fontSize: 13,
  },
  notes: {
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: 14,
    color: colors.white,
    margin: 0,
  },
  text: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: colors.white,
  },
  icon: {
    fontSize: 20,
  },
  select: {
    width: 220,
    '@media (max-width: 768px)': {
      margin: 'auto',
    },
    '@media (max-width: 576px)': {
      width: '100%',
    },
  },
  buttons: {
    paddingTop: 21,
  },
  showMore: {
    position: 'absolute',
    padding: 10,
    borderRadius: 4,
    boxShadow: '0 6px 16px 0 rgba(153, 155, 168, 0.3)',
    backgroundColor: 'white',
    color: 'black',
    zIndex: 100,
  },
})

const RescheduleRequestBanner = ({
  banner,
  reload,
  handleError,
  pushNotification,
}) => {
  const classes = useStyles()
  const [show, setShow] = useState(true)
  const [formState, setFormState] = useState({
    date: '',
  })
  const [showMore, setShowMore] = useState(false)
  const [showRescheduleModal, setShowRescheduleModal] = useState(false)

  const {
    submitted,
    submit: rescheduleConversation,
    err,
    submitting,
  } = useApiSubmit('rescheduleConversation')

  useEffect(() => {
    if (submitted && !submitting && !err) {
      pushNotification({
        level: 'success',
        subject: 'Conversation Rescheduled.',
        timeout: 10,
      })
      reload()
      setShow(false)
    } else if (!submitting && err) {
      handleError(err, true, {
        message: 'Unable to reschedule this conversation.',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, submitting, err])

  // Memo
  const options = useMemo(() => {
    let value = []
    if (banner?.metadata?.suggestedDates) {
      value = banner.metadata.suggestedDates.map(date => ({
        value: date,
        label: moment(date).format('dddd, MMM DD - h:mm A'),
      }))
    }
    return value
  }, [banner])

  // Handlers
  const handleSelect = useCallback((key, value) => {
    setFormState(f => ({
      ...f,
      [key]: value,
    }))
  }, [])

  const handleAccept = useCallback(() => {
    const params = {
      conversationId: banner.metadata.conversationId,
    }
    const body = {
      scheduled: moment(formState.date.value).set({ seconds: 0 }).utc(),
      bannerId: banner.id,
    }
    rescheduleConversation({ params, body })
  }, [banner, formState.date, rescheduleConversation])

  const header = (
    <BannerDescription title={banner.metadata.title} icon={'fas fa-comments'} />
  )

  const description = (
    <>
      <p className={classes.notes}>
        {banner.metadata.profile.firstName +
          ' ' +
          banner.metadata.profile.lastName}{' '}
        wants to reschedule a Lattitude conversation with you about{' '}
        {banner.metadata.skillName || ''}
      </p>
      {banner.metadata.notes !== '' && (
        <>
          <span
            className={`${classes.notes} d-none d-sm-block`}
            onClick={() => {
              setShowMore(s => !s)
            }}
          >
            Read Message {showMore ? <ArrowUpIcon /> : <ArrowDownIcon />}{' '}
          </span>
          {showMore && (
            <div className={classes.showMore}>{banner.metadata.notes}</div>
          )}
        </>
      )}
    </>
  )

  const buttons = (
    <Row>
      <Col
        xs={12}
        xl={5}
        className={`justify-content-center justify-content-md-start`}
      >
        <CustomSelect
          options={options}
          name="date"
          value={formState.date}
          onSelect={handleSelect}
          className={classes.select}
          title="View Suggested Times"
          titleClassName={classes.text}
          menuWidth={200}
        />
      </Col>
      <Col className={`align-self-center ${classes.buttons}`}>
        <div
          className={`d-flex pb-2 justify-content-center justify-content-md-start`}
        >
          <ActionButton
            height="26px"
            width="80px"
            text={'ACCEPT'}
            onClick={handleAccept}
            type="LIGHT_RED"
            className="text-nowrap"
            loading={submitting}
            disabled={!formState.date.value}
          />
          <span className={`${classes.text} p-1`}>or</span>
          <ActionButton
            height="26px"
            text="PROPOSE NEW TIME"
            onClick={() => setShowRescheduleModal(true)}
            type="OUTLINE_WHITE"
            className="text-nowrap px-2"
          />
        </div>
      </Col>
    </Row>
  )
  const layout = (
    <>
      <Col xs={12} md={3} className={`align-self-center pt-3 pt-md-0`}>
        {header}
      </Col>
      <Col
        xs={12}
        md={4}
        className={`align-self-center text-center text-md-left px-0 py-3 py-md-0`}
      >
        {description}
      </Col>
      <Col className={`align-self-center text-left`}>{buttons}</Col>
    </>
  )

  return (
    <>
      {showRescheduleModal && (
        <ReScheduleModal
          show={showRescheduleModal}
          conversation={{
            id: banner.metadata.conversationId,
            skillId: banner.metadata.skillId,
            availabilityToken: banner.metadata.profile.token,
            participants: [{ profileId: banner.metadata.profile.id }],
          }}
          bannerId={banner.id}
          onClose={() => setShowRescheduleModal(false)}
          reloadUpcoming={reload}
        />
      )}
      {show && (
        <BannerContainer
          layout={layout}
          type={'STRONG_RED'}
          onClose={() => {
            setShow(false)
          }}
        />
      )}
    </>
  )
}

export default compose(
  withErrorHandler,
  withRouter,
  withNotifications,
)(RescheduleRequestBanner)
