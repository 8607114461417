import { getAccessToken } from './auth0'
import { invokeOperationWithAuth } from './api'

// this mechanism for invoking the api is considered legacy.
// use the react hooks and methods exposed by the './api' module.

const invokeMethod = invokeOperationWithAuth(getAccessToken)
const client = new Proxy(
  {},
  {
    get: function (target, prop, receiver) {
      if (prop in target) {
        return Reflect.get(target, prop, receiver)
      }
      return async (options = {}) =>
        invokeMethod(prop, { ...options, params: options })
    },
  },
)

if (typeof window !== 'undefined') window.lattusApi = client

export default client
