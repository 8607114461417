import React, { useState } from 'react'
import ActionButton from '../../../elemets/ActionButton'
import AvailabilityIcon from '../../Icons/Availability'
import ScheduleModal from '../../Modals/Conversation/ScheduleModal'
import { createUseStyles } from 'react-jss'
import withErrorHandler from '../../../errors/withErrorHandler'
import { withNotifications } from '../../../notifications'
import compose from 'just-compose'
import RequestAvailabilityModal from '../../Modals/Conversation/RequestAvailabilityModal'

const useStyles = createUseStyles({
  container: {
    '& .modal-dialog': {
      minWidth: 730,
    },
  },
  actionButton: {
    width: '122px!important',
    fontWeight: 'bold!important',
    fontSize: '12px!important',
    paddingLeft: '0!important',
    paddingRight: '0!important',
  },
})

const ScheduleButton = ({ profileTopics, match, reloadUpcoming }) => {
  const classes = useStyles()
  const [requestedAvailability, setRequestedAvailability] = useState(false)
  const [showReschedule, setShowReschedule] = useState(false)
  const [showRequest, setShowRequest] = useState(false)

  return (
    <div className={`${classes.container}`}>
      {showReschedule && (
        <ScheduleModal
          show={showReschedule}
          profileTopics={profileTopics}
          match={match}
          onClose={() => setShowReschedule(false)}
          reloadUpcoming={reloadUpcoming}
        />
      )}
      {showRequest && (
        <RequestAvailabilityModal
          show={showRequest}
          match={match}
          setRequestedAvailability={setRequestedAvailability}
          onClose={() => setShowRequest(false)}
        />
      )}

      {match?.availabilities > 0 ? (
        <ActionButton
          className={`${classes.actionButton}`}
          type={'STRONG_GREEN'}
          text={
            <>
              <AvailabilityIcon />
              &nbsp;&nbsp;
              <span className={classes.iconText}>Schedule Now</span>
            </>
          }
          onClick={() => setShowReschedule(true)}
        />
      ) : (
        <ActionButton
          className={`${classes.actionButton}`}
          type={'LIGHT_GREEN'}
          text={
            <>
              <span className={classes.iconText}>
                {requestedAvailability
                  ? 'Request Sent'
                  : 'Request Availability'}
              </span>
            </>
          }
          disabled={requestedAvailability}
          onClick={() => setShowRequest(true)}
        />
      )}
    </div>
  )
}

export default compose(withErrorHandler, withNotifications)(ScheduleButton)
