import React from 'react'

const ComingSoon = () => {
  return (
    <div className="container">
      <h1>Coming Soon</h1>
      <p>
        This page is coming soon. Please check back periodically to see our new
        updates.
      </p>
    </div>
  )
}

export default ComingSoon
