import React from 'react'
import { createUseStyles } from 'react-jss'
import TextareaAutosize from 'react-autosize-textarea'

import colors from '../theme/theme2'
import { isMobile } from '../utils/common'

const CustomTextArea = ({
  value,
  onChange,
  onKeyPress = () => {},
  onClick = () => {},
  editMode = true,
  enabled = false,
  placeholder,
  name,
  maxLength = 200,
  className = '',
  label = '',
}) => {
  const useStyles = createUseStyles({
    input: {
      width: '100%',
      padding: '8px 16px',
      border: `1px solid ${colors.grey1}`,
      borderRadius: 4,
      color: colors.greyParagraph,
      fontFamily: 'Lato',
      fontSize: 14,
      resize: 'none',
      '&:read-only': {
        color: colors.greyParagraph,
        cursor: 'default',
        border: '1px solid transparent',
        backgroundColor: 'transparent',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    disableMobile: {
      '&:read-only': {
        color: [colors.greyParagraph, '!important'],
      },
    },
    inputCounter: {
      fontFamily: 'Lato',
      fontSize: 12,
      color: '#8a8a8a',
      paddingLeft: 17,
    },
    container: {
      width: '100%',
    },
    red: {
      color: colors.red,
    },
    autoInput: {
      border: 'none',
    },
  })
  const classes = useStyles()

  return (
    <div className={classes.container} onClick={onClick}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={'d-flex'}>
        <TextareaAutosize
          id={name}
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          className={`
            ${classes.input} ${className}
            ${isMobile() ? classes.disableMobile : ''}
            ${enabled ? classes.autoInput : ''}`}
          name={name}
          readOnly={enabled ? false : !editMode}
          placeholder={placeholder}
        />
      </div>

      {editMode && (
        <div className={`ml-2`}>
          <span
            className={`${classes.inputCounter} ${
              value.length > maxLength ? classes.red : ''
            }`}
          >
            {value.length} / {maxLength}
          </span>
        </div>
      )}
    </div>
  )
}

export default CustomTextArea
