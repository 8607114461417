import React from 'react'
import TextField from '../TextField'

type GoalFieldProps = {
  value?: string
  onChange: (value: string) => void
}

const GoalField = ({ value, onChange }: GoalFieldProps): JSX.Element => (
  <TextField
    label="Conversation goal"
    labelId="goal"
    value={value}
    onChange={onChange}
    maxLength={500}
  />
)

export default GoalField
