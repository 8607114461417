import React from 'react'
import { createUseStyles } from 'react-jss'
import colors from '../theme/theme2'

const CircleImage = ({ size = 40, src, className, id = '' }) => {
  const useStyles = createUseStyles({
    circle: {
      height: size,
      width: size,
      borderRadius: size / 2,
      backgroundColor: colors.primary100,
      color: colors.primary,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Lato',
      fontSize: 14,
      fontWeight: 500,
      objectFit: 'cover',
    },
  })
  const classes = useStyles()

  return (
    <div id={id} className={`${classes.circle} ${className}`}>
      <img
        className={classes.circle}
        src={
          src ||
          'https://assets.golattitude.com/Lattitude_Logo_FullColor_Icon.png'
        }
        alt="Group Logo"
        onError={e => {
          e.target.src =
            'https://assets.golattitude.com/Lattitude_Logo_FullColor_Icon.png'
        }}
      />
    </div>
  )
}

export default CircleImage
