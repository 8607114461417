import React, { ReactNode } from 'react'
import { useAuth0 } from '../auth0'

type IsAuthenticatedProps = {
  children: ReactNode
}

const IsAuthenticated = ({
  children,
}: IsAuthenticatedProps): JSX.Element | null => {
  const { isAuthenticated } = useAuth0()
  if (!isAuthenticated) return null
  return <>{children}</>
}

export default IsAuthenticated
